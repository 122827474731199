
const pillarsDialogDefaults = {
    name: '',
    workspace: null,
    coordinates: {lat: '', lng: ''},
    distance: 500,
    shape: null,
    editMode: false,
    loading: false,
    loaded: false,
    quarters: [],
    allQuarters: [],
}

const defaultState = {
  loading: false,
  loadingError: false,
  loaded: true,
  reactArray: [], //массив элементов для отображения в реахте
  drawingEnable: false,
  selectedNode: null, //выбранная нода в реахте
  pillarDialog: {...pillarsDialogDefaults}
}

export function changePillarsAttr(payload) {
  return {
    type: 'PILLARS_SET_ATTR',
    payload: payload,
  }
}

export function changePillarsDialogAttr(payload) {
  return {
    type: 'PILLARS_DIALOG_SET_ATTR',
    payload: payload,
  }
}

export function refreshPillarsTree() {
  return {
    type: 'REFRESH_PILLARS_TREE',
  }
}

export function closePillarDialog() {
  return {
    type: 'CLOSE_PILLAR_DIALOG',
  }
}

export function pillarsReducer(state = defaultState, action) {
  switch(action.type) {
    case 'PILLARS_SET_ATTR':
      return {...state, ...action.payload}
    case 'PILLARS_DIALOG_SET_ATTR':
      return {...state, pillarDialog: {...state.pillarDialog, ...action.payload}}
    case 'CLOSE_PILLAR_DIALOG':
      return {...state, pillarDialog: {...pillarsDialogDefaults}}
    case 'REFRESH_PILLARS_TREE':
      return {...state}
    default:
      return state;
  }
}
