import L from "leaflet";
import {getMap1} from "../GlobalObjects";
import {loadingString} from "../Common/Strings_RU";

let popup = null;
let interactive = null;

export function getMapPopup() {
  return popup;
}

function deleteInteractive() {
  if (interactive)
    Object.keys(interactive).map((key) => { //delete all events by recreating node
      const el = popup.getElement().querySelector(`#${key}`)
      if (el)
        el.parentNode.replaceChild(el.cloneNode(true), el)
    })
  interactive = null;
}

function eventHandler(event, f) {
  event.preventDefault()
  event.stopPropagation()
  f(event)
}

function addInteractive(popup, user_interactive) {
  interactive = user_interactive;
  if (interactive) {
    Object.entries(interactive).map(([key, value]) => {
      const el = popup.getElement().querySelector('#' + key)
      if (el)
        el.addEventListener(value.type, (e) => eventHandler(e, value.f))
        //el.addEventListener(value.type, value.f)
      return null;
    })
  }
}

export function closeMapPopup() {
  const map = getMap1()
  deleteInteractive()
  popup = null;
  interactive = null;
  map.closePopup()
}

export function changeMapPopup(html, user_interactive = null) {
  deleteInteractive()
  if (popup) {
    popup.setContent(html)
    addInteractive(popup, user_interactive)
  }
}

export function showLoadingPopup(point) {
  popup = showMapPopup(point, `<div>${loadingString}</div>`)
  return popup;
}

export function showMapPopup(point, html, user_interactive = null) {
  const map = getMap1()
  popup = L.popup({maxWidth: 'auto', closeOnClick: false, autoClose: false})
    .setLatLng(point)
    .setContent(html)
    .openOn(map);
  deleteInteractive()
  addInteractive(popup, user_interactive)
  popup.on('remove', () => {
    if (popup)
      closeMapPopup()
  })
  return popup;
}
