import {ReactComponent as LockIcon} from '../Common/svg/lock.svg';

export function LockedIcon(props) {

  let lockIconBottom = props.lockIconBottom || 0;
  let lockIconRight = props.lockIconRight || 0;
  let style = props.style;

  const divStyle = {
    ...style,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  }

  const lockStyle = {
    position: 'absolute',
    right: lockIconRight,
    bottom: lockIconBottom,
    fill: 'grey',
    stroke: 'grey',
  }

  return(
    <div style={divStyle} is={'there'}>
        {props.children}
      {props.locked && <LockIcon style={lockStyle}/>}
    </div>
  )
}
