//Version
export const appVersion = '2.11.2';

//MAP
export const mapDivId = 'elz_map_manager_div';

let map = undefined;

export function getMap1() { // возвращает объект карты (L.map)
	return map;
}

export function setMap1(mapa) {
	map = mapa;
}

let drawLayerGroup = null;
export function setDrawLayerGroup(group) { // устанавливает группу слоев для редактора рисования
	drawLayerGroup = group;
}

export function getDrawLayerGroup() { // возвращает группу слоев редактора рисования
	return drawLayerGroup;
}
