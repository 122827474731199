import { authAPI } from "../../api/api";
import TokenService from "../../api/token.service";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const UPDATE_TOKEN = "UPDATE_TOKEN";
const SET_AUTH_ATTR = "SET_AUTH_ATTR";

const initialState = {
  token: { access: false, refresh: false },
  checkConfCodeSuccess: false,
  checkCodeToggler: false,
  SMSConfirmation: false,
  userAgreementCheck: true,
  confCodeSuccess: false,
  sendConfCodeSuccess: false,
};

// редьюсер для работы с данными авторизации
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TOKEN: {
      return { ...state, token: { ...state.token, ...action.token } };
    }
    case SET_AUTH_ATTR: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const updateToken = (token) => ({ type: UPDATE_TOKEN, token });
export const setAuthAttr = (payload) => ({ type: SET_AUTH_ATTR, payload });

export const loginFormHandlerThunk = (values) => {
  return async (dispatch) => {
    try {
      let loginResult = await authAPI.login(values.login, values.password);
      if (loginResult.data.token && loginResult.data.refresh) {
        TokenService.setUser(loginResult.data)
        dispatch(updateToken({ refresh: true }));
      }
    } catch (e) {
      handleErrors(dispatch, e)
    }
  };
};

export const newRegistrationFormThunk = (data) => {
  return async (dispatch) => {
    try {
      let registrationResult = await authAPI.registerOrganization(data);
      dispatch(setSnack('success', 'данные отправлены'))
      dispatch(setAuthAttr({SMSConfirmation: true}))
    } catch (e) {
      console.error(e);
      if (e.response.status === 429) {
        dispatch(setSnack('error', 'Вы уже были зарегистрированы ранее. Попробуйте совершить вход через ' +
          'отправку sms с кодом на ваш номер', 5000))
      } else {
        handleErrors(dispatch, e)
      }
    }
  };
};

export const smsCodeCheckThunk = (username, code) => {
  return async (dispatch) => {
    try {
      let codeCheckResult = await authAPI.smsCodeCheck({username: username, confirmation_code: code});
      if (codeCheckResult.data.token && codeCheckResult.data.refresh) {
        TokenService.setUser(codeCheckResult.data);
        dispatch(updateToken({ refresh: true }));
        dispatch(setSnack('success', 'Код подтвержден'))
      }
      dispatch(setAuthAttr({checkConfCodeSuccess: true}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  };
};

export const sendConfCodeThunk = (username) => {
  return async (dispatch) => {
    try {
      let sendCodeResult = await authAPI.sendConfCode({username: username});
      if (sendCodeResult.status === 200) {
        dispatch(setSnack('success', 'sms с кодом было отправлено на ваш телефон'))
        dispatch(setAuthAttr({sendConfCodeSuccess: true, checkCodeToggler: true}))
      }
    } catch (e) {
      handleErrors(dispatch, e)
    }
  };
};
