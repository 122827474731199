import React from 'react';
import {IconButton, Stack, Typography, useTheme} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useDispatch, useSelector} from "react-redux";
import {hideMapDialog, showMapDialog} from "../../../../../Main/Map/Common/Dialog/DialogReducer";
import {deletePhotoThunk} from "../../../../../../redux/reducers/stockpilesReducer";
import {toolbarTextStyle} from "../../../../stockpilePageStyles";
import {toolbarIconButtonStyle, toolbarIconStyle} from "../../../toolbarStyles";
const DeletePhotoButton = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const images = useSelector(state => state.stockpilesReducer.selectedStockpile.images)
  const buttons = {'Да': {color: theme.palette.warning.main}, 'Нет': {color: theme.palette.primary.main}}
  const deletePhotoButtonHandler = () => {
    dispatch(showMapDialog('warning', 'Удаление фотографии', 'Вы уверены что хотите удалить ' +
      'выбранную фотографию, без возможности восстановления?', buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        dispatch(deletePhotoThunk(selectedPhoto.id, images))
      }
    }))
  }

  return (
    <IconButton title={'Удалить фото'}
                sx={toolbarIconButtonStyle}
                disableRipple
                size={'small'}
                onClick={deletePhotoButtonHandler}>
      <DeleteForeverIcon style={{...toolbarIconStyle, color: 'darkred'}}/>
    </IconButton>
  );
};

export default DeletePhotoButton;