import React from 'react';
import {useSelector} from "react-redux";

const Plot = ({setViewedFigure, viewedFigure, viewedPoint, selectedFigure, coordCorrection}) => {
  const plotPx = useSelector(state => state.forestUseReportReducer.plotPx)
  const selectedPlotPointIndex = useSelector(state => state.forestUseReportReducer.selectedPlotPointIndex)
  return (
    <>
      <polygon
        i={'plotPolygon_none'}
        onMouseLeave={() => setViewedFigure(null)}
        points={plotPx.map((point) =>
          `${coordCorrection(point.x)}, ${coordCorrection(point.y)}`).join(' ')}
        fill={"#f44336"}
        strokeOpacity={1}
        fillOpacity={(viewedFigure === 'plotPolygon' || selectedFigure === 'plotPolygon') ? 0.2 : 0}
        stroke={'#f44336'}
        strokeWidth={selectedFigure === 'plotPolygon' ? 4 : 2}
      />
      {selectedFigure === 'plotPolygon' && plotPx.map((point, i) =>
        <circle
          i={`plotPoint_${i}`}
          key={i}
          cx={coordCorrection(point.x)}
          cy={coordCorrection(point.y)}
          r={(viewedPoint.index === i && viewedPoint.type === 'plotPoint') || selectedPlotPointIndex === i ? 7 : 5}
          fill={selectedPlotPointIndex !== i ? "#f44336" : '#b71c1c'}
          opacity={1}
        />
      )}
    </>
  );
};

export default Plot;