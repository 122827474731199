import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {planetableSvgReducer, setPlanetableSvgAttr} from "../../../../redux/reducers/planetableSvgReducer";
import {planetableSvgConfig} from "../svgConfig";
import {coordCorrection} from "../../../../svgComponentsHelpersFunctions/coordCorrection";

const Quarters = () => {
  const dispatch = useDispatch()
  const planetableSvgState = useSelector(state => state.planetableSvgReducer)
  const {quarter_editing_outline_index, quarter_editing_point_index, viewedSvgFigure, viewedSvgPoint,
    zoom, mode, quartersSvg, scale, selectePlanetable} = planetableSvgState
  const {quarter_editing_outline_fill, quarter_outline_fill, quarter_outline_stroke, quarter_editing_outline_opacity,
    quarter_outline_opacity, quarter_point_radius, quarter_viewed_point_radius} = planetableSvgConfig
  return (
    <>
      {/*Рендер кварталов*/}
      {quartersSvg.map((quarter, index) => (
        <React.Fragment key={`quarter_${index}`}>
          <polygon
            i={`quarter_${index}`}
            onMouseLeave={() => mode === 'view' &&
              dispatch(setPlanetableSvgAttr({viewedSvgFigure: {type: null, index: null, planetableObj: null}}))}
            points={quarter.map((point) =>
              `${coordCorrection(point.x, zoom, scale)}, ${coordCorrection(point.y, zoom, scale)}`).join(' ')}
            fill={quarter_editing_outline_index === index
              ? quarter_editing_outline_fill
              : quarter_outline_fill}
            stroke={quarter_outline_stroke}
            opacity={(quarter_editing_outline_index === index ||
              (viewedSvgFigure.type === 'quarter' && viewedSvgFigure.index === index))
              ? quarter_editing_outline_opacity
              : quarter_outline_opacity}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default Quarters;