import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {InputAdornment, Tooltip, Stack, Link, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UserAgreementCheckbox from "../UserAgreementCheckbox/UserAgreementCheckbox";
import {useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router";
import {urlGenerator} from "../../../utils/urlGenerator";


const RegistrationOrgFormsComponent = ({registrationForm, fieldsProps, errors}) => {
  const navigate = useNavigate()
  const userAgreementCheck = useSelector(state => state.loginReducer.userAgreementCheck)


  return (
    <Stack
      key={'regForm'}
      component="form"
      onSubmit={registrationForm.handleSubmit}
      spacing={1}
      sx={{mt: 1}}
    >
      {Object.keys(fieldsProps).map(fieldName => (
        <Stack key={fieldName} direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
          <TextField
            sx={{width: 350, mb: 0.5,}}
            key={fieldName}
            size={'small'}
            required={fieldsProps[fieldName].name !== 'Отчество'}
            fullWidth
            id={fieldName}
            error={(!!errors[fieldName] && fieldName !== 'patronymic')}
            label={fieldsProps[fieldName].name}
            name={fieldName}
            type={fieldsProps[fieldName].type}
            autoFocus={fieldName === 'last_name'}
            onChange={registrationForm.handleChange}
            onBlur={registrationForm.handleBlur}
            inputProps={{maxLength: fieldsProps[fieldName].max_length, height: 30}}
            InputProps={{
              startAdornment: fieldName === 'phone' && <InputAdornment position="start">+7</InputAdornment>,
            }}
          />
          {fieldName === 'organization_inn' && !errors[fieldName] &&
            <Tooltip title={'Нам нужен ИНН вашей компании, чтобы убедиться, что учетная запись нового ' +
              'пользователя уникальна в системе'}>
              <InfoOutlinedIcon sx={{color: 'primary.light', fontWeight: 300, ml: 1}}/>
            </Tooltip>
          }
          {!!errors[fieldName] && fieldName !== 'patronymic' &&
            <Tooltip title={errors[fieldName]}>
              <ErrorOutlineOutlinedIcon sx={{color: 'red', fontWeight: 300, ml: 1, marginTop: '7px'}}/>
            </Tooltip>
          }
        </Stack>
      ))}
      <Button
        type="submit"
        fullWidth
        disabled={!userAgreementCheck}
        variant="contained"
        sx={{ mt: 1, width: 350}}
      >
        Зарегистрироваться
      </Button>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <UserAgreementCheckbox />
      </Box>
      <Typography onClick={() => navigate(urlGenerator().login)}>
        <Link sx={{cursor: 'pointer'}}>{'Вернуться на страницу входа'}</Link>
      </Typography>
    </Stack>
  );
};

export default RegistrationOrgFormsComponent;