import React from 'react';
import CloseButton from "./CloseButton/CloseButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Head = ({name}) => {
  return (
    <Stack direction={'row'} className={'right-panel-header'}>
      <Typography sx={{fontSize: 18, fontWeight: 'bold', flex: 1}}>{name}</Typography>
      <CloseButton/>
    </Stack>
  )
}

export default Head;
