import React from 'react';
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import FigureInfoTable from "./InfoTable/FigureInfoTable";

const FigureInfo = ({currentFigure, selectedSvgFigure}) => {
  if (currentFigure?.planetableObj) {
    switch (currentFigure.type) {
      case 'quarter':
        return (
          <FigureInfoTable editMode={!!selectedSvgFigure.type} figureType={'Квартал'}
                           planetableObj={currentFigure?.planetableObj}/>
        )
    }
  }
  return '';
}

const CurrentFigureInfoBlock = () => {
  const planetableState = useSelector(state => state.planetableSvgReducer)
  const {selectedSvgFigure, viewedSvgFigure} = planetableState;
  const currentFigure = selectedSvgFigure.type ? selectedSvgFigure : viewedSvgFigure;

  return (
    <>
      {(currentFigure.type) &&
        <Box sx={{
          position: 'absolute', left: 5, bottom: 5,
          display: 'flex', justifyContent: 'center', alignItems: 'center',
          backgroundColor: 'rgba(255,255,255,0.8)', borderRadius: 3, opacity: 0.8, overflow: 'hidden',
        }}>
          <FigureInfo currentFigure={currentFigure} selectedSvgFigure={selectedSvgFigure}/>
        </Box>
      }
    </>

  )
}

export default CurrentFigureInfoBlock;
