/*
Режим рисования перетаскиванием.
В этом режиме отключается перемещение карты (сохраняется возможность перемещать стрелками).
Рисование производится перетаскиванием мыши при зажатой левой кнопке или щелчком на карте.
*/
import {getMap1} from "../../GlobalObjects";
import {setDrawMode} from "../../../LeftPanel/Context/Projects/projectsReducer";
import {
  addElementToPencilData,
  deletElementFromPencilData,
  setDrawingMode,
  setPencilSelectionData
} from "../../../Overlay/Measuring/measuringReducer";
import {lineSVG, polygonSVG} from "../svg/Svgs";
import '../css/leaflet_markers.css';
import {calculateAreanAndDistance} from "../MiscFunction";
import {closeMapPopup, showMapPopup} from "../../MapPopup/mapPopup";
import {getPencilDrawingShapePopup, prepareToOpenPopup} from "../../MapPopup/popupFunctions";
import {geoJSONdefaultPrecision} from "../Defaults";
import {getCuttingAreas} from "../../../LeftPanel/Context/CAs/CuttingsAreas";
import intersect from "@turf/intersect";
import {
  caSelectionBorderColor,
  resetAllCAsSelection
} from "../../../LeftPanel/Context/CAs/Selections/caSelectionsCommon";
import {caSelectionSetItems} from "../../../LeftPanel/Context/CAs/Selections/caSelectionsReducer";
import {resetAllDrawing} from "./drawing";

let prevPoint = null; //предыдущая точка при рисовании перетаскиванием
let pencilPolyline = null; //нарисованная линия
let pencilMarkers = {start: null, end: null} //маркеры в режиме паузы
let update = false; //признак обновления таймера расчета площади/длины: false - таймер свободен, true - нет
let mouseDown = false; //зажата левая кнопка (для предотвращения нежелательных ивентов)

/**
 * Дистанции в метрах в зависимости от зума, через которые ставятся точки
 * @type {number[]}
 */
const zoomDistance = [
  200000, // 0
  200000, // 1
  100000, // 2
  50000, // 3
  30000, // 4
  20000, // 5
  10000, // 6
  5000, // 7
  3000, // 8
  2000, // 9
  1000, //10
  900, // 11
  500, // 12
  200, // 13
  100, // 14
  100, // 15
  40, // 16
  30, // 17
  30, // 18
  10, // 19
  10, // 20
]

/**
 *  Обработчик ивента нажатия кнопки закрытия панели (выход из режима рисования)
 */
export function resetAll() {
  stopPencilDrawing(true)
}

/**
 * Вычисление площади и периметра
 */
function calculateData() {
  if (!update) {
    const [distance, area] = calculateAreanAndDistance(pencilPolyline.getLatLngs())
    update = true;
    setTimeout(() => {
      window.elz_dispatch(setPencilSelectionData({
        distance: (distance / 1000).toFixed(2),
        area: (area / 10000).toFixed(2)
      }))
      update = false;
    }, 300)
  }
}

/**
 * Удалить поледнюю вершину.
 */
export function removeLastVertex() {
  if (pencilPolyline) {
    const latlngs = pencilPolyline.getLatLngs()
    latlngs.pop()
    pencilPolyline.setLatLngs(latlngs)
    if (latlngs.length === 0) {
      const dispatch = window.elz_dispatch;
      removeMarkers()
      dispatch(setPencilSelectionData({}))
    } else {
      if (pencilMarkers.end) {
        pencilMarkers.end.setLatLng(latlngs[latlngs.length - 1])
        calculateData()
      }
    }
  }
}

/**
 * Создает маркер начальный или конечный. В зависимости от options.type
 * @param latlng - координаты
 * @param options - параметры маркера
 * @returns {L.Marker}
 */
function createMarker(latlng, options) {
  const icon = options.type === 'end' ? lineSVG() : polygonSVG()
  let html = `<div class="leaflet_marker_button">${icon}</div>`;
  const marker = L.marker(latlng,
    {
      icon: L.divIcon({
        html: html,
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        className: "no-back-no-border",
      })
    }
  )
  marker.on('click', createShape)
  marker.on('mousedown', markerDown)
  marker.elz_type = options.type;
  return marker
}

/**
 * Отключает передачу ивента mousedown дальше
 * @param event
 */
function markerDown(event) {
  L.DomEvent.stopPropagation(event)
}

/**
 * Ищет индекс шейпа в массиве measuringReducer.pencilSelection.data
 * @param shape шейп
 * @returns {number}
 */
function searchShapeIndex(shape) {
  const data = window.store.getState()["measuringReducer"].pencilSelection.data;
  let ind = 0;
  while (ind < data.length) {
    const ele = data[ind]
    if (ele === shape)
      return ind;
    ind++;
  }
  return -1;
}

/**
 * Удаляет созданый шейп с карты и из measuringReducer.pencilSelection.data;
 * @param shape
 */
function deleteShape(shape) {
  const ind = searchShapeIndex(shape)
  if (ind >= 0) {
    const dispatch = window.elz_dispatch;
    dispatch(deletElementFromPencilData(ind))
    const map = getMap1()
    map.removeLayer(shape)
  }
}

/**
 * Обработчик клика по шейпу. Показывает попу.
 * @param event
 */
function shapeClickEventHandler(event) {
  if (!prepareToOpenPopup(event))
    return;
  const target = event.sourceTarget;
  const interactives = {}
  if (target.elz_type === 'Polygon') {
    interactives.pensil_drawing_select_ca_buttons = {
      type: 'click', f: () => {
        closeMapPopup();
        const dispatch = window.elz_dispatch;
        const selectionPoly = target.toGeoJSON(geoJSONdefaultPrecision)
        const cas = getCuttingAreas().layerArray;
        const resultArr = []
        const map = getMap1()
        cas.forEach(layer => {
          if (map.hasLayer(layer)) {
            const geoL = layer.toGeoJSON(geoJSONdefaultPrecision)
            const ti = intersect(selectionPoly, geoL)
            if (ti) {
              resultArr.push(layer)
            }
          }
        })
        if (resultArr.length) {
          resetAllCAsSelection()
          resultArr.map(layer => {
            layer.elz_oldopts = {...layer.options}
            layer.setStyle({color: caSelectionBorderColor})
          })
          dispatch(caSelectionSetItems(resultArr))
        }
        deleteShape(target)
      }
    }
  }

  interactives.pensil_drawing_delete_shape_button = {
    type: 'click', f: () => {
      closeMapPopup();
      deleteShape(target)
    }
  }
  showMapPopup(event.latlng, getPencilDrawingShapePopup(event.sourceTarget, interactives), interactives)
}

/**
 * Создание шейпа из нарисованой полилинии
 * @param event - ивент, содержит слой polyline в sourceTarge
 */
function createShape(event) {
  L.DomEvent.stopPropagation(event)
  const map = getMap1()
  const options = {
    color: '#c96705',
  }
  let ele;
  const type = event.sourceTarget.elz_type;
  if (type === 'start') { // polygon
    const coords = pencilPolyline.getLatLngs()
    coords.push(coords[0])
    ele = L.polygon(coords, options).addTo(map)
    ele.elz_type = 'Polygon';
  } else {
    ele = L.polyline(pencilPolyline.getLatLngs(), options).addTo(map)
    ele.elz_type = 'Line';
  }
  ele.on('click', shapeClickEventHandler)
  const dispatch = window.elz_dispatch;
  dispatch(addElementToPencilData(ele))
  stopPencilDrawing()
}

/**
 * Удаляет маркеры
 */
function removeMarkers() {
  const map = getMap1()
  if (pencilMarkers.start) {
    pencilMarkers.start.off('click', createShape)
    pencilMarkers.start.off('mousedown', markerDown)
    map.removeLayer(pencilMarkers.start)
  }
  if (pencilMarkers.end) {
    pencilMarkers.end.off('click', createShape)
    pencilMarkers.end.off('mousedown', markerDown)
    map.removeLayer(pencilMarkers.end)
  }
  pencilMarkers = {start: null, end: null}
}

/**
 * Обработчик ивента перемещения мыши. Используется для рисования перетаскиванием.
 * @param event
 */
function onMouseMove(event) {
  const state = window.store.getState()["measuringReducer"].pencilSelection;
  const map = getMap1()
  if (state.paintMode && !state.pencilDrawingPaused) {
    if (prevPoint) {
      const distance = prevPoint.distanceTo(event.latlng)
      if (distance > zoomDistance[map.getZoom()]) {
        pencilPolyline.addLatLng(event.latlng)
        prevPoint = event.latlng;
        calculateData()
      }
    } else {
      pencilPolyline.addLatLng(event.latlng)
      prevPoint = event.latlng;
    }
  }
  event.originalEvent.preventDefault() //Avoid page scrolling in mobile device
}

/**
 * Обработчик ивета mouseup карты. Вызывает паузу рисования.
 */
function onMouseUp(event) {
  if (mouseDown) { //предотвращает ивенты начатые извне
    L.DomEvent.stopPropagation(event)
    const state = window.store.getState()["measuringReducer"].pencilSelection;
    pencilPolyline.addLatLng(event.latlng)
    prevPoint = pencilPolyline.getLatLngs()[0]
    if (!state.pencilDrawingPaused)
      pausePencilDrawing()
    mouseDown = false;
  }
}

/**
 * Обработчик ивента mousedown. Начало/продолжение рисования перетаскиванием.
 * @param event
 */
function onMouseDown(event) {
  //Предотращает баг с перетаскивание контейнера карты в хроме
  event.originalEvent.stopPropagation()
  event.originalEvent.preventDefault()
  if (!mouseDown)
    if (event.originalEvent.button === 0 || event.originalEvent.type === 'touchstart') {
      mouseDown = true;
      const state = window.store.getState()["measuringReducer"].pencilSelection;
      const dispatch = window.elz_dispatch;
      const map = getMap1()
      if (state.paintMode) {
        dispatch(setPencilSelectionData({pencilDrawingPaused: false}))
      } else {
        pencilPolyline = L.polyline([]).addTo(map)
        dispatch(setDrawMode(null)) //disable drawing
        dispatch(setDrawingMode(null)) //disable measuring
        dispatch(setPencilSelectionData({paintMode: !state.paintMode}))
      }
    }
}

/**
 * Остановка рисования, при уходе мыши с карты
 * @param event
 */
function onMouseLeave(event) {
  if (mouseDown) {
    pausePencilDrawing()
  }
}

/**
 * Обработчик touchstop.
 * Костыль. Лифлет не отправляет touchend, а шлет click
 * @param event
 */
function onTouchClick(event) {
  pausePencilDrawing()
}

/**
 * Выход из режима рисования или сброс шейпа
 * @param enabled {boolean} - true - сброс нарисованного шейпа, false - завершить рисование
 */
export function stopPencilDrawing(enabled = false) {
  const dispatch = window.elz_dispatch;
  const map = getMap1()
  if (!enabled) {
    map.dragging.enable()
    map.removeEventListener('mousemove', onMouseMove)
    map.removeEventListener('mouseup', onMouseUp)
    map.removeEventListener('mousedown', onMouseDown)
    map.removeEventListener('mouseout', onMouseLeave)
    map.removeEventListener('touchstart', onMouseDown, false)
    map.removeEventListener('touchmove', onMouseMove, false)
    map.getContainer().removeEventListener('touchend', onTouchClick, false)
  }
  if (pencilPolyline)
    map.removeLayer(pencilPolyline)
  removeMarkers()
  prevPoint = null;
  update = false;
  dispatch(setPencilSelectionData({
    enable: enabled,
    paintMode: false,
    pencilDrawingPaused: false,
    distance: 0,
    area: 0
  }))
}

/**
 * Включение режима рисования. Открывает панель.
 */
export function startPencilDrawing() {
  const state = window.store.getState()["measuringReducer"].pencilSelection;
  const dispatch = window.elz_dispatch;
  const map = getMap1()
  resetAllDrawing()
  if (state.enable) { //off
    stopPencilDrawing()
  } else { //on
    map.addEventListener('mousedown', onMouseDown)
    map.addEventListener('mouseup', onMouseUp)
    map.addEventListener('mousemove', onMouseMove)
    map.addEventListener('mouseout', onMouseLeave)
    map.addEventListener('touchstart', onMouseDown, false)
    map.addEventListener('touchmove', onMouseMove, false)
    map.getContainer().addEventListener('touchend', onTouchClick, false)
    map.dragging.disable()
    dispatch(setDrawMode(null))
    dispatch(setPencilSelectionData({enable: true}))
  }
}

/**
 * Пауза режима рисования перетаскиванием. Создает маркеры создания шейпа.
 */
function pausePencilDrawing() {
  const map = getMap1()
  const markerOptions = {
    weight: 2,
    fillColor: 'white',
    fillOpacity: 1,
    radius: 8,
  }
  const startMarkerOptions = {
    ...markerOptions,
    color: 'darkblue',
    type: 'start',
  }
  const endMarkerOptions = {
    ...markerOptions,
    color: 'darkgreen',
    type: 'end',
  }
  mouseDown = false;
  const dispatch = window.elz_dispatch;
  let latlngs = [];
  if (pencilPolyline) {
    latlngs = pencilPolyline.getLatLngs()
    calculateData()
  }
  if (latlngs.length) {
    removeMarkers()
    pencilMarkers.start = createMarker(latlngs[0], startMarkerOptions).addTo(map)
    pencilMarkers.end = createMarker(latlngs[latlngs.length - 1], endMarkerOptions).addTo(map)
  }
  dispatch(setPencilSelectionData({pencilDrawingPaused: true}))
}
