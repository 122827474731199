import React from 'react';
import {Table, TableBody, TableCell, TableRow, Typography, Stack} from "@mui/material";
import {useSelector} from "react-redux";
import {tableCellClasses} from "@mui/material/TableCell";
import {rightBarTitleStyle} from "../../rightbarStyles";

const TimberData = () => {
  const stockpilePhotoState = useSelector(state => state.stockpilePhotoReducer)
  const {timber_selected_index, timber_viewed_index, selectedPhoto} = stockpilePhotoState
  const timber = selectedPhoto?.timbers_px_include[timber_viewed_index]

  if (!timber) return ''

  return (
    <Stack direction={'column'} alignItems={'center'} spacing={1} justifyContent={'center'}>
      <Typography sx={rightBarTitleStyle}>Бревно</Typography>
      <Table size={"small"} sx={{p: 0, [`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
        <TableBody>
          <TableRow sx={{height: '40px'}}>
            <TableCell style={{ width: '50%'}}>
              Диаметр (см)
            </TableCell>
            <TableCell style={{ width: '50%'}}>
              {timber.d_cm}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export default TimberData;