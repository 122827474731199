import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {tableTextStyle, titleStyle} from "../../organizationPageStyles";
import {getTariff} from "../../../Main/Map/Common/tariffs";
import {tableCellClasses} from "@mui/material/TableCell";

const AccountsTable = () => {
  const tariff = getTariff()
  const accountsTableColumns = ["Роли", "Доступно", "Активно"]

  return (
    <TableContainer sx={{overflow: 'auto', maxWidth: '400px', position: 'relative'}}>
      <Table size="small"
             sx={{
               [`& .${tableCellClasses.root}`]: {
                 borderBottom: "none"
               }
             }}
      >
        <TableHead>
          <TableRow>
            {accountsTableColumns.map((columnName, index) => (
              <TableCell align={columnName === 'Роли' ? 'left' : 'center'}
                         key={index}
                         sx={{...tableTextStyle, color: 'black'}}>
                {columnName === 'РОЛИ'
                  ? <Typography sx={titleStyle}>{columnName}</Typography>
                  : columnName
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align={'left'} sx={tableTextStyle}>Редактирование</TableCell>
            <TableCell align={'center'} sx={tableTextStyle}>
              {tariff.count_editor}
            </TableCell>
            <TableCell align={'center'} sx={tableTextStyle}>
              {tariff.current_limit.count_editor}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align={'left'} sx={tableTextStyle}>Просмотр</TableCell>
            <TableCell align={'center'} sx={tableTextStyle}>
              {tariff.count_user}
            </TableCell>
            <TableCell align={'center'} sx={tableTextStyle}>
              {tariff.current_limit.count_user}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AccountsTable;
