export const blockTitle = {fontSize: '16', fontWeight: 'bold'}

export const fullPhotoIconStyle = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(0%, -50%)',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '16px',

};

export const iconRight = {
  ...fullPhotoIconStyle,
  right: '16px',
};

export const iconLeft = {
  ...fullPhotoIconStyle,
  left: '16px',
};

export const thumbnailPhoto = {
  borderRadius: 5,
  height: '6vh',
}

export const numberOnthumbnailPhoto = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '16px'
}

export const deletePhotoButtonOnThumbnail = {
  position: 'absolute',
  top: '0',
  right: '0',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const tableContainerStyle = {
  marginTop: 0,
  marginLeft: '0',
  marginBottom: '1vh',
  overflow: 'auto',
}

export const tableStyle = {
  marginTop: 0,
  width: '100%',
}

export const headerCellStyle = {
  padding: '5px 2px 0px 2px',
  paddingTop: 0,
  width: '50%',
}

export const cellStyle = {
  padding: '5px 2px 5px 2px',
  width: '50%',
}

export const headerCellTextStyle = {
  color: '#AAAAAA',
}
