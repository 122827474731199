import React, {useState} from 'react';
import {Box, IconButton, ListItemButton, Typography, useTheme} from "@mui/material";
import {stockpileTreeItemTextStyle, treeListItemStyle} from "../../../../../../leftBarStyles";
import Stack from "@mui/material/Stack";
import IconWoodUncolorized from "../../../../../../../../../svg/IconWoodUncolorized";
import StockpileContextMenu from "../stockpileContextMenu/StockpileContextMenu";
import {
  getStockpileThunk,
  patchStockpileThunk,
  setStockpilesAttr
} from "../../../../../../../../../redux/reducers/stockpilesReducer";
import {disableModeAndUndoChanges, setPhotoAttr} from "../../../../../../../../../redux/reducers/stockpilePhotoReducer";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const StockpileItem = ({stockpile, plot, iAmEditor}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const isUpdateStockpile = useSelector(state => state.stockpilesReducer.isUpdateStockpile)
  const [stockpileName, setStockpileName] = useState(stockpile.name)

  const listItemHandleClick = (e) => {
    e.stopPropagation()
    dispatch(setPhotoAttr({selectedPhoto: null,}))
    dispatch(disableModeAndUndoChanges())
    dispatch(getStockpileThunk(stockpile.id))
  }

  const onChangeInputHandler = (e) => {
    setStockpileName(() => e.target.value)
  }

  const updateStockpileHandler = (e) => {
    e.stopPropagation()
    dispatch(patchStockpileThunk(stockpile.id, plot.id, {name: stockpileName}))
  }

  const undoButtonHandler = (e) => {
    e.stopPropagation()
    setStockpileName(stockpile.name)
    dispatch(setStockpilesAttr({isUpdateStockpile: {status: false, stockpileId: null}}))
  }

  return (
    <ListItemButton style={{...treeListItemStyle, width: '100%', paddingLeft: 24}}>
      {isUpdateStockpile.status && isUpdateStockpile.stockpileId === stockpile.id
        ? (
          <Stack direction={'row'}
                 sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Stack direction={'row'} onClick={listItemHandleClick}>
              <TextField
                sx={{pr: 5}}
                fullWidth
                autoFocus
                value={stockpileName}
                inputProps={{maxLength: 30,}}
                variant={'standard'}
                size={'small'}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  (e.code === 'Enter' || e.code === 'NumpadEnter') && updateStockpileHandler(e)
                  e.code === 'Escape' && undoButtonHandler(e)
                }}
                onChange={onChangeInputHandler}
              />
            </Stack>
            <IconButton
              sx={{p:0}}
              color={'primary'}
              title={'Создать штабель'}
              onClick={updateStockpileHandler}
            >
              <DoneIcon sx={{color: 'primary.main'}} title={'Сохранить'}/>
            </IconButton>
            <IconButton
              sx={{p:0}}
              title={'Отменить'}
              color={'primary'}
              onClick={(e) => undoButtonHandler(e)}
            >
              <CloseIcon sx={{color: '#A44646'}}/>
            </IconButton>
          </Stack>
        )
        : (
          <Stack direction={'row'}
                 sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                 onClick={listItemHandleClick}
          >
            <Stack direction={'row'}>
              <IconButton
                disableRipple
                sx={{width: 33, color: 'primary.dark', p: 0, pr: 1.5,}}>
                <IconWoodUncolorized/>
              </IconButton>
              <Typography
                style={{
                  ...stockpileTreeItemTextStyle,
                  color: selectedStockpile?.id === stockpile.id && theme.palette.primary.main
                }}>
                {stockpile.name}
              </Typography>
            </Stack>
            <Box onClick={e => e.stopPropagation()}>
              {!plot.is_closed && iAmEditor &&
                <StockpileContextMenu stockpile={stockpile} plot={plot}/>
              }
            </Box>
          </Stack>
        )
      }
    </ListItemButton>
  );
};

export default StockpileItem;