import React from 'react';
import {textStyle, titleStyle} from "../../organizationPageStyles";
import Typography from "@mui/material/Typography";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@mui/material";
import {useSelector} from "react-redux";
import {formatAmountValue} from "../../../../utils/formatAmountValue";

const PaymentHistoryTableColumns = ["Дата", "Сумма", "Статус", "Способ оплаты"]




const PaymentHistory = () => {
  const theme = useTheme()
  const paymentStatuses = {
    pending: {label: 'В обработке', color: '#808080'},
    succeeded: {label: 'Зачислен', color: theme.palette.success.dark},
    canceled: {label: 'Отменен', color: theme.palette.warning.main}
  }

  const payment_history = useSelector(state => state.organizationInfoReducer.organizationData.payment_history)

  return (
    <Box sx={{m: 1}}>
      <Typography sx={titleStyle}>История платежей</Typography>
      <TableContainer sx={{pb: 1}}>
        <Table size={"small"} sx={{width: '30vw'}}>
          <TableHead>
            <TableRow>
              {PaymentHistoryTableColumns.map(columnName => (
                <TableCell align={'center'} key={columnName} sx={textStyle}>{columnName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {payment_history.map((row, i) => (
              <TableRow key={i}>
                <TableCell align={'center'} sx={textStyle} id={'created_at'}>{row.created_at}</TableCell>
                <TableCell align={'center'} sx={textStyle} id={'amount_value'}>{formatAmountValue(row.amount_value)}</TableCell>
                <TableCell align={'center'} sx={{...textStyle, color: paymentStatuses[row.status].color}} id={'status'}>
                  {paymentStatuses[row.status].label}
                </TableCell>
                <TableCell align={'center'} sx={textStyle} id={'method'}>{row.method}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaymentHistory;