import {TimeInterval} from "./TimeInterval";
import {Production} from "./Production";
import Grid from "@mui/material/Grid";
import {setTransportMonitoringInfo} from "../caPropertiesReducer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {ArrowRight} from "@mui/icons-material";
import {Alert, Box, Button, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getSpeciesThunk, tmDataIsEmpty} from "./TransportMonitoringDataCommon";
import {tableContainerStyle} from "../CAPropertiesWindow";
import {cannotIDo} from "../../../../../Map/Common/tariffs";

const stackTextStyle = {
  userSelect: 'none',
}

export function TransportMonitoringData({id}) {
  const state = useSelector(state => state.caPropertiesReducer.tm)
  const dispatch = useDispatch()

  function reloadingTM() {
    getSpeciesThunk(id, state.type)
  }

  const alertStyle = {
    marginLeft: '24px',
  }

  if (state.loadingError === 404) { //нет данных
    return (
      <>
        <Alert severity={'info'} style={alertStyle}>Нет данных.</Alert>
      </>
    )
  }

  if (cannotIDo.useLoggingComplexMonitoringModule() || state.loadingError === 403) { //нет доступа
    return (
      <>
        <Alert severity={'warning'} style={alertStyle}>Нет доступа.</Alert>
      </>
    )
  }

  if (state.loadingError) {
    return (
      <>
        <Alert severity={'error'} style={alertStyle}>
          <div>Ошибка загрузки данных.</div>
          <Button onClick={reloadingTM}>Повторить</Button></Alert>
      </>
    )
  }

  return (
    <>
      {state.show && !state.loadingError && !tmDataIsEmpty() &&
        <Box style={tableContainerStyle}>
          <Grid container spacing={1} pt={1} pr={1} pb={1}>
              <Grid xs={12} item>
                <Typography style={stackTextStyle}>Временной интервал</Typography>
                <TimeInterval id={id} state={state}/>
              </Grid>
            <Grid xs={12} item>
              <Stack
                direction={'row'}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  dispatch(setTransportMonitoringInfo({productionShow: !state.productionShow}))
                }}>
                {state.productionShow ? <ArrowDropDownIcon/> : <ArrowRight/>}
                <Typography style={stackTextStyle}>Производство</Typography>
              </Stack>
              {state.productionShow && <Production id={id} state={state}/>}
            </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}
