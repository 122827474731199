//import React from 'react';
import {IconButton, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FiltersPopover from "../FiltersByColumns/FiltersPopover";
import {useDispatch, useSelector} from "react-redux";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {setOpertaionalReportAttr} from "../../../../../redux/reducers/operationalReportReducer";
import FilterIcon from "./FilterIcon";
import {tableHeadCell} from "../../../OperationalReportStyles";
import {useState} from "react";

const TableHeadComponent = ({columns, dataType, iAmEditor}) => {
  const dispatch = useDispatch()
  const tableFilters = dataType === 'harvestingData'
    ? useSelector(state => state.operationalReportReducer.harvestingTableFilters)
    : useSelector(state => state.operationalReportReducer.removalTableFilters)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const isHaveFilters = Object.keys(tableFilters).length || null
  const filterPopoverToggler = (event, columnName) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setSelectedFilter(anchorEl ? null : columnName)
  }

  const open = Boolean(anchorEl);
  //const id = open ? 'simple-popper' : undefined;

  const resetFiltersHandler = () => {
    dataType === 'harvestingData' && dispatch(setOpertaionalReportAttr({harvestingTableFilters: {}}))
    dataType === 'removalData' && dispatch(setOpertaionalReportAttr({removalTableFilters: {}}))
  }

  const showMachinesModalHandler = () => {
    dispatch(setOpertaionalReportAttr({showMachinesModal: {status: true, dataType: dataType}}))
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell key={column.name} style={{width: `auto`}}>
            <div style={tableHeadCell}>
              {column.label}
              {column.name === 'volume' && <Typography>(м&sup3;)</Typography>}
              <IconButton size={'small'} title={'Фильтр'} onClick={(e) => filterPopoverToggler(e, column.name)}>
                <FilterIcon columnName={column.name} filterType={column.filterType} tableFilters={tableFilters}/>
              </IconButton>
              {selectedFilter === column.name &&
                <FiltersPopover open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} dataType={dataType}
                                filterPopoverToggler={filterPopoverToggler} column={column}/>
              }
              {column.name === 'machine' && iAmEditor &&
                <IconButton size={'small'} title={'Редактировать список техники'} onClick={showMachinesModalHandler}>
                  <AddIcon sx={{color: 'primary.main'}}/>
                </IconButton>
              }
            </div>
          </TableCell>
        ))}
        {isHaveFilters
          ?
          <TableCell colSpan={2}>
            <IconButton title={'Сбросить фильтры'} onClick={resetFiltersHandler}>
              <CancelOutlinedIcon sx={{color: 'primary.main'}}/>
            </IconButton>
          </TableCell>
          :
          <TableCell colSpan={2}></TableCell>
        }
      </TableRow>
    </TableHead>
  )
}

export default TableHeadComponent;
