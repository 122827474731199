import React from 'react';
import {ListItemIcon, MenuItem} from "@mui/material";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";
import {urlGenerator} from "../../../../utils/urlGenerator";

const NewOrganizationPageMenuItem = ({handleClose}) => {
  const navigate = useNavigate()
  return (
    <>
      <MenuItem onClick={() => {
        handleClose()
        navigate(urlGenerator().newOrganizationPage)
      }}>
        <ListItemIcon>
          <CorporateFareIcon fontSize="small" />
        </ListItemIcon>
        <Typography>Личный кабинет</Typography>
      </MenuItem>
    </>
  );
};

export default NewOrganizationPageMenuItem;