import React, {useEffect, useState} from 'react';
import {IconButton, TableCell, TableRow, TextField} from "@mui/material";


const FigureInfoTableRow = ({rowName, rowData, onChangeInput, propName, isInputEnabled}) => {


  return (
    <TableRow>
      <TableCell>{rowName}</TableCell>
      {isInputEnabled
        ? <TableCell>
            <TextField size={'small'}
                       sx={{width: 90}}
                       value={rowData || ''}
                       inputProps={{style: {padding: 0}, maxLength: 20}}
                       onChange={(e) => onChangeInput(e, propName)}
            />
        </TableCell>
        : <TableCell>{rowData || '-'}</TableCell>
      }
    </TableRow>
  )
}

export default FigureInfoTableRow;