import {Box} from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";

export function LeftDrawerPanel(props) { // левая панель (не кнопки)
  const buttonRef = document.getElementById(`lb_button_${props.index}`)

  if (!buttonRef)
    return null;
  
  return (
    <Box
      id={'left_drawer_panel'}
      style={{
        overflow: 'hidden',
        backgroundColor: 'white',
        border: '1px solid ' + window.elz_theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0',
        boxSizing: 'border-box',
      }}
    >
      <Stack style={{margin: '0 1vh 1vh 1vh', flex: '1 1 0', boxSizing: 'border-box'}}>
        {props.children}
        <Box
          style={{
            position: 'absolute',
            top: buttonRef.offsetTop + 1, //начало на пихель ниже бордюра
            left: 0,
            backgroundColor: 'white',
            zIndex: 901,
            width: 1,
            height: buttonRef.getBoundingClientRect().height - 2, //вычитаем бордюр
          }}
        >
        </Box>
      </Stack>
    </Box>
  )
}
