import {useDispatch} from "react-redux";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import {
  addString,
  cancelText,
  minMaxZoomErrorString,
  miscUserLayerDialogHeaderString,
  moreParametersString,
  nameIsEmptyError,
  nameString,
  saveString,
  typeString,
  urlIsEmptyErrorString,
  urlString,
  userLayerGetFeatureInfoEnable,
  userLayerWithNameExists,
  zoom20ErrorString,
  zoomMaxString,
  zoomMinString,
} from "../../../../Map/Common/Strings_RU";
import {
  hideAddUserLayerDialog,
  setUserLayerName,
  setUserLayerType,
  setUserLayerURL,
  showHideUserLayersMoreParameters,
  userLayersSetParams,
} from "./userLayersReducer";
import {FTextField} from "../../Projects/FTextFiled";
import {isValidUrl} from "../../../../Map/Common/MiscFunction";
import {
  addUserMiscLayer,
  createUserLayerOnServer,
  createUserMiscLayer,
  patchUserLayerOnServer,
  searchUserLayerIndexByName,
  userBlindMiscPanel,
  userMiscPanel
} from "./UserLayers";
import {getMap1} from "../../../../Map/GlobalObjects";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {setSettingWasChanged} from "../../../../Map/Common/userSettings";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from "@mui/material/Typography";

const layersTypes = ['WMS', 'XYZ'];

export function UserLayerDialog(props) { //Диалог узерских слоев

  const state = props.state;
  const dispatch = useDispatch()
  const blind_left = props.blind;

  if (!state.show)
    return null;

  function checkDataForErrors() { // Проверка на ошибки ввода
    let ind = searchUserLayerIndexByName(state.name)
    if (state.mode === 'edit')
      ind = -1;
    if (!state.name) {
      return nameIsEmptyError;
    }
    if (ind !== -1) {
      return userLayerWithNameExists;
    }
    if (!state.url) {
      return urlIsEmptyErrorString;
    }
    if (!isValidUrl(state.url)) {
      return urlIsEmptyErrorString;
    }
    const zmi = parseInt(state.params.zoomMin)
    const zma = parseInt(state.params.zoomMax)
    if (isNaN(zmi) || isNaN(zma) || zmi > 20 || zma > 20 || zmi < 0 || zma < 0)
      return zoom20ErrorString;
    if (zmi > zma)
      return minMaxZoomErrorString;
    /*if (state.type === 'WMS') {

    }*/
    return null;
  }

  function handleSave() {
    const error = checkDataForErrors()
    if (!error) {
      const map = getMap1()
      if (state.mode === 'add') { //adding
        const node = addUserMiscLayer(null, state.type, state.name, state.url, state.params)
        createUserMiscLayer(node, blind_left ? userMiscPanel : userBlindMiscPanel)
        createUserLayerOnServer(node, dispatch)
      } else { //editing
        const node = state.currentLayer;
        node.type = state.type;
        node.name = state.name;
        node.url = state.url;
        node.params = {
          ...state.params,
          zoomMin: parseInt(state.params.zoomMin),
          zoomMax: parseInt(state.params.zoomMax)
        }

        if (node.layer) {
          map.elz_zoom.removeLayer(node.layer)
          createUserMiscLayer(node, userMiscPanel)
        }
        if (node.blind_layer) {
          map.elz_zoom.removeLayer(node.blind_layer)
          createUserMiscLayer(node, userBlindMiscPanel)
        }
        patchUserLayerOnServer(node, dispatch)
      }
      setSettingWasChanged()
      dispatch(hideAddUserLayerDialog())
      if (props.onClose)
        props.onClose()
    } else
      dispatch(setSnack('error', error))
  }

  let ind = 0;

  return (
    <Dialog open={state.show} fullWidth={true}>
      <DialogTitle>
        {miscUserLayerDialogHeaderString}
      </DialogTitle>
      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <FormControl style={{marginTop: '1vh'}}>
            <InputLabel id="user-layer-tyle-chosser-label" variant="standard">{typeString}</InputLabel>
            <Select
              labelId={'user-layer-tyle-chosser-label'}
              variant={'standard'}
              value={state.type}
              label={typeString}
              onChange={(e) => {
                dispatch(setUserLayerType(e.target.value))
              }}
            >
              {layersTypes.map(item => {
                return (<MenuItem key={ind++} value={item}>{item}</MenuItem>)
              })
              }
            </Select>
          </FormControl>
          <FTextField
            error={state.errors.indexOf('name') !== -1}
            nofocus
            value={state.name}
            label={nameString}
            _endEditing={(value) => {
              dispatch(setUserLayerName(value))
            }}
          >
          </FTextField>
          <FTextField
            error={state.errors.indexOf('url') !== -1}
            nofocus
            value={state.url}
            label={urlString}
            _endEditing={(value) => {
              dispatch(setUserLayerURL(value))
            }}
            _cancelEdit={() => {
              dispatch(setUserLayerURL(state.url))
            }}
          >
          </FTextField>
          <Stack direction={'row'} spacing={1}>
            <TextField
              size={'small'}
              style={{flex: 0.5}}
              value={state.params.zoomMin}
              label={zoomMinString}
              onChange={e => dispatch(userLayersSetParams({...state.params, zoomMin: e.target.value}))}
            />
            <TextField
              size={'small'}
              style={{flex: 0.5}}
              value={state.params.zoomMax}
              label={zoomMaxString}
              onChange={e => dispatch(userLayersSetParams({...state.params, zoomMax: e.target.value}))}
            />
          </Stack>
          {state.type === 'WMS' &&
            <>
              <Stack
                direction={'row'}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  dispatch(showHideUserLayersMoreParameters())
                }}
              >
                {state.moreVisible
                  ?
                  <ArrowDropDownIcon fontSize={'small'}/>
                  :
                  <ArrowRightIcon/>
                }
                <Typography>{moreParametersString}</Typography>
              </Stack>
              {state.moreVisible &&
                <Stack direction={'column'} spacing={0.5} id={'here'} style={{marginTop: 0}}>
                  <FormControlLabel
                    control={<Switch size={'small'} checked={state.params.enableGetFeatureInfo || false}/>}
                    label={userLayerGetFeatureInfoEnable} style={{paddingTop: '1vh'}}
                    onChange={() => {
                      dispatch(userLayersSetParams({
                        ...state.params,
                        enableGetFeatureInfo: !state.params.enableGetFeatureInfo
                      }))
                    }}
                  />
                </Stack>
              }
            </>
          }
        </Stack>
      </DialogContent>
      <Divider orientation={'horizontal'} flexItem/>
      <DialogActions>
        <Divider orientation={'horizontal'} flexItem/>
        <Button variant={'outlined'} color={'primary'} size={'small'} onClick={handleSave}>
          {state.mode === 'edit' ? saveString : addString}
        </Button>
        <Button
          variant={'outlined'} color={'warning'} size={'small'}
          onClick={() => dispatch(hideAddUserLayerDialog())}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
