import React from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import {useSelector} from "react-redux";

const ViewedRulerTable = () => {
  const ruler_length = useSelector(state => state.stockpilePhotoReducer.ruler_length)
  return (
    <Table size={"small"} sx={{p: 0, [`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
      <TableBody>
        <TableRow sx={{height: '40px'}}>
          <TableCell style={{ width: '50%'}}>
            Длина (м)
          </TableCell>
          <TableCell style={{ width: '50%'}}>
            {ruler_length}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ViewedRulerTable;