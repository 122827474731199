import Box from "@mui/material/Box";
import {overlayButtonStyle} from "../Common/Styles";
import {IconButton, Switch, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setBlindAction, setBlindEnabled, setBlindLeft} from "./BlindReducer";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FlipIcon from '@mui/icons-material/Flip';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {baseLayersWindowRefresh} from "../../Overlay/BaseLayers/BaseLayersReducer";
import {addBlindControl, getSideToSideControl, removeBlindControl, resetAllBlindLayers} from "./SideBySide";
import {useEffect} from "react";
import {addAllShowedBaseLayersToMap} from "./BlindBaseLayersCommon";
import {blindCloseString, blindLayersCompareString, leftPanelString, rightPanelString} from "../Common/Strings_RU";
import {getBlindControlPosition} from "../Common/libs/leaflet-side-by-side";
import {setSettingWasChanged} from "../Common/userSettings";
import {addAllShowedBlindMiscLayersToMap} from "./BlindMiscLayersCommon";
import {addAllPerimetersBlindLayers} from "../../LeftPanel/Context/Projects/ProjectsCommon";
import {addAllRoadsBlindLayers} from "../../LeftPanel/Context/Roads/roads";
import {addAllPillarsBlindLayers, removeAllPillarsBlindLayers} from "../../LeftPanel/Context/Infrastructure/Pillars/pillars";

export function BlindButton() {
  const state = useSelector(state => state.blindReducer)
  const dispatch = useDispatch()
  const theme = useTheme()

  function SwitchTheme(theme) {
    return createTheme({
      components: {
        MuiSwitch: {
          styleOverrides: {
            switchBase: {
              color: theme.palette.success.main,
            },
            track: {
              backgroundColor: theme.palette.success.main,
              ".Mui-checked.Mui-checked + &": {
                backgroundColor: theme.palette.success.main,
              }
            }
          }
        }
      }
    })
  }

  function onOffBlind() {
    setSettingWasChanged()
    dispatch(setBlindAction(state.enabled ? 'hideBlind' : 'showBlind'))
    dispatch(baseLayersWindowRefresh())
  }

  useEffect(() => {
    if (state.action) {
      if (state.action === 'showBlind') {
        addAllShowedBaseLayersToMap()
        addAllShowedBlindMiscLayersToMap()
        addAllPerimetersBlindLayers()
        addAllRoadsBlindLayers()
        addAllPillarsBlindLayers()
        addBlindControl(getBlindControlPosition())
        dispatch(setBlindEnabled(true))
      } else if ((state.action === 'hideBlind')) {
        resetAllBlindLayers()
        removeBlindControl()
        removeAllPillarsBlindLayers()
        dispatch(setBlindEnabled(false))
      }
      dispatch(setBlindAction())
    }
  })

  if (!state.enabled) {
    return (
      <Box style={overlayButtonStyle} className={'leftRightToggler'}>
        <IconButton style={{padding: 5}} onClick={onOffBlind} title={blindLayersCompareString}>
          <FlipIcon fontSize={'small'}/>
        </IconButton>
      </Box>
    )
  }

  return (
    <Box style={overlayButtonStyle} className={'leftRightToggler'}>
      <Stack direction={'row'} alignItems={'center'}>
        <IconButton
          title={blindCloseString}
          style={{padding: 5}}
          onClick={onOffBlind}>
          <CloseOutlinedIcon fontSize={'small'}/>
        </IconButton>
        <Stack flexDirection={'row'} style={{paddingTop: '1px', cursor: 'pointer'}}
                onClick={() => {
                  dispatch(setBlindLeft(!state.left))
                  getSideToSideControl().addIndicator()
                }}
        >
          <Typography>{leftPanelString}</Typography>
          <ThemeProvider theme={SwitchTheme(theme)}>
            <Switch size={'small'} color={'success'} checked={!state.left}/>
          </ThemeProvider>
          <Typography style={{marginRight: '10px'}}>{rightPanelString}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
