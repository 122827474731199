import React from 'react';
import {FormControlLabel, Radio, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {syncWith1CSetPeriodThunk} from "../../../../../redux/reducers/organizationInfoReducer";

const Sync1CSettings = () => {
  const dispatch = useDispatch()
  const {auto_sync_1c, id} = useSelector(state => state.organizationInfoReducer.organizationData)

  const changeAutoSync1Cmode = (period) => {
    dispatch(syncWith1CSetPeriodThunk(id, period))
  }

  return (
    <Stack>
      <Typography fontWeight={500}>
        Частота автоматической синхронизации данных между Delyana и модулем 1С.Delyana
      </Typography>
      <FormControlLabel value={'WEEK'} control={<Radio/>}
                        checked={auto_sync_1c === 'DAY'}
                        onChange={() => changeAutoSync1Cmode('DAY')}
                        label={<Typography>{"1 раз в сутки"}</Typography>}/>
      <FormControlLabel value={'DAY'} control={<Radio/>}
                        checked={auto_sync_1c === 'WEEK'}
                        onChange={() => changeAutoSync1Cmode('WEEK')}
                        label={<Typography>{"1 раз в неделю"}</Typography>}/>
      <FormControlLabel value={'OFF'} control={<Radio/>}
                        checked={auto_sync_1c === 'OFF'}
                        onChange={() => changeAutoSync1Cmode('OFF')}
                        label={<Typography>{"Никогда"}</Typography>}/>
    </Stack>
  );
};

export default Sync1CSettings;