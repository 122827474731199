import {useEffect} from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getPlotsStockpilesThunk} from "../../../redux/reducers/stockpilesReducer";
import PlotsGrouping from "./PlotsGrouping/PlotsGrouping";
import PlotsStockpilesTree from "./PlotsStockpilesTree/PlotsStockpilesTree";
import PlotsList from "./PlotsStockpilesTree/PlotsStockpilesTreeItem/PlotsList/PlotsList";
import {leftbarContainer} from "./leftBarStyles";

const Leftbar = ({iAmEditor}) => {
    const dispatch = useDispatch()
    const plotsStockpiles = useSelector(state => state.stockpilesReducer.plotsStockpiles)
    const plotsFilters = useSelector(state => state.stockpilesReducer.plotsFilters)
    const selectedFilters = Object.keys(plotsFilters).filter(filter => plotsFilters[filter].status)

    useEffect(() => {
        dispatch(getPlotsStockpilesThunk())
    }, [])

    return (
        <Box sx={leftbarContainer}>
            <Stack sx={{height: '96%', overflowY: 'auto'}} alignItems={'start'}>
                <Typography sx={{mb: 1, fontWeight: 500}}>Деляны</Typography>
                <PlotsGrouping/>
                {selectedFilters.length
                    ? <PlotsStockpilesTree itemsList={plotsStockpiles} fields={selectedFilters} iAmEditor={iAmEditor}/>
                    : <PlotsList plotsList={plotsStockpiles} iAmEditor={iAmEditor}/>
                }
            </Stack>
        </Box>
    );
}

export default Leftbar;
