import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import {patchPlotsStockpileThunk, setStockpilesAttr,} from "../../../../../../../redux/reducers/stockpilesReducer";
import {disableModeAndUndoChanges} from "../../../../../../../redux/reducers/stockpilePhotoReducer";
import {setSnack} from "../../../../../../Main/Map/Common/Dialog/Snack/snackReducer";
import {useDispatch} from "react-redux";
import {hideMapDialog, showMapDialog} from "../../../../../../Main/Map/Common/Dialog/DialogReducer";
import {useTheme} from "@mui/material";

const ButtonsBlock = ({
  plot,
  isCreateStockpile,
  selectedPhoto,
  onOpen,
  setOnOpen,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const buttons = {
    'Да': { color: theme.palette.warning.main },
    'Нет': { color: theme.palette.primary.main },
  };

  const changeLockStatusButtonHandler = (e) => {
    e.stopPropagation();

    // Определите, какое слово использовать в зависимости от значения is_closed.
    const conditionWord = plot.is_closed ? "открыть" : "закрыть";

    dispatch(
      showMapDialog(
        "warning",
        "Управление деляной",
        "Вы уверены, что хотите " + conditionWord + " для редактирования?",
        buttons,
        (e) => {
          dispatch(hideMapDialog());
          if (e === "Да") {
            dispatch(
              patchPlotsStockpileThunk(plot.id, { is_closed: !plot.is_closed })
            )
            selectedPhoto && dispatch(disableModeAndUndoChanges(selectedPhoto))
          }
        }
      )
    );
  };

  const onCreateStockpileHandler = (e, plot) => {
    e.stopPropagation();
    !onOpen && setOnOpen(true);
    if (plot.stockpiles.length < 20) {
      !isCreateStockpile.status &&
        dispatch(
          setStockpilesAttr({
            isCreateStockpile: { status: true, plotId: plot.id },
          })
        );
    } else {
      dispatch(
        setSnack("error", "Максимальное количество штабелей на деляне - 20 шт.")
      );
    }
  };

  return (
    <Stack flexDirection={"row"}>
      {!plot.is_closed && (
        <IconButton
          sx={{ p: 0, pl: 1 }}
          disableRipple
          disabled={isCreateStockpile.status}
          title={"Создать новый штабель"}
          onClick={(e) => onCreateStockpileHandler(e, plot)}
        >
          <AddIcon
            sx={{ color: !isCreateStockpile.status && "primary.main" }}
          />
        </IconButton>
      )}
      {!plot.is_closed ? (
        <IconButton
          sx={{ p: 0, pl: 1 }}
          disableRipple
          title={"Заблокировать деляну"}
          onClick={changeLockStatusButtonHandler}
        >
          <LockOpenIcon sx={{ color: "primary.main" }} />
        </IconButton>
      ) : (
        <IconButton
          sx={{ p: 0, pl: 1 }}
          disableRipple
          title={"Разблокировать деляну"}
          onClick={changeLockStatusButtonHandler}
        >
          <LockIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default ButtonsBlock;
