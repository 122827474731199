import {List} from "@mui/material";
import PlotItem from "./PlotItem/PlotItem";

const PlotsList = ({plotsList, iAmEditor}) => {
  return (
    <List disablePadding style={{width: '100%'}}>
      {plotsList.map((plot, i) => (
        <PlotItem plot={plot}  key={i} iAmEditor={iAmEditor}/>
      ))}
    </List>
  )
}

export default PlotsList;
