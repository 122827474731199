import React from 'react';
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";

const FooterTextItem = ({text, onClickHandler, style}) => {
  return (
    <Grid item >
      <Typography sx={style}
                  onClick={() => onClickHandler()}>
        {text}
      </Typography>
    </Grid>
  );
};

export default FooterTextItem;