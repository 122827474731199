import {IconButton, Menu} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LogoutMenuItem from "./LogoutMenuItem/LogoutMenuItem";
import TutorialMenuItem from "./TutorialMenuItem/TutorialMenuItem";
import AboutPageMenuItem from "./AboutPageMenuItem/AboutPageMenuItem";
import PlanetablePageMenuItem from "./PlanetablePageMenuItem/PlanetablePageMenuItem";
import NewOrganizationPageMenuItem from "./NewOrganizationPageMenuItem/NewOrganizationPageMenuItem";
import {useSelector} from "react-redux";
import StockpilesMenuItem from "./StockpilesMenuItem/StockpilesMenuItem";
import OperationalReport from "./OperationalReport/OperationalReport";
import {cannotIDo} from "../../Main/Map/Common/tariffs";
import WhatsNew from "./WhatsNew/WhatsNew";
import {useState} from "react";

function HeaderMenuButton() {
  const isMobileDevice = useSelector(state => state.userReducer.isMobileDevice)
  //const lightTariff = !cannotIDo.notLightTariffActions()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton color="inherit" title={'Меню'}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
      >
        <PersonIcon/>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
          {!isMobileDevice && <NewOrganizationPageMenuItem handleClose={handleClose}/>}
          {!isMobileDevice && !cannotIDo.usePlanetabletsModule() &&
            <PlanetablePageMenuItem handleClose={handleClose}/>
          }
          {!isMobileDevice && !cannotIDo.useStockpilesModule() &&
            <StockpilesMenuItem handleClose={handleClose}/>
          }
          {!isMobileDevice && !cannotIDo.useORModule() &&
            <OperationalReport handleClose={handleClose}/>
          }
          <TutorialMenuItem handleClose={handleClose}/>
          <WhatsNew handleClose={handleClose} />
          <AboutPageMenuItem handleClose={handleClose}/>
          <LogoutMenuItem handleClose={handleClose}/>
      </Menu>
    </>
  )
}

export default HeaderMenuButton;
