import { Box, Divider, Stack } from "@mui/material";
import Photos from "../RPCommonElements/Photos/Photos";
import { useSelector } from "react-redux";
import {
  insertViolationPhotosThunk,
} from "../../../../redux/reducers/rightPanelReducer";
import ViolationProperties from "./ViolationProperties";
import ViolationDescription from "./ViolationDescription";
import { deleteViolationPhotoThunk } from "../../../../redux/reducers/rightPanelReducer";
import LastChange from "../RPCommonElements/LastChange/LastChange";
import {cannotIDo} from "../../Map/Common/tariffs";

/** Правая панель для нарушения. */
const RPViolationData = ({ violationId }) => {
  const shapeData = useSelector((state) => state.rightPanelReducer.shapeData);
  const { images } = shapeData || {};
  const iAmEditor = !cannotIDo.editorAction()

  if (!shapeData) return null;

  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
        <Stack spacing={1} sx={{ pt: 1, pb: 1 }}>
          <ViolationProperties
            status={shapeData.status}
            priority={shapeData.priority}
            vioilationId={violationId}
            iAmEditor={iAmEditor}
          />
          <ViolationDescription
            violationId={violationId}
            description={shapeData.description}
            iAmEditor={iAmEditor}
          />
          <Divider orientation={"horizontal"} />
          <Photos
            name={shapeData.name}
            photos={images || []}
            objectId={violationId}
            savePhotosThunk={insertViolationPhotosThunk}
            deletePhotoThunk={deleteViolationPhotoThunk}
          />
          <Divider orientation={"horizontal"}/>
            <LastChange objectData={shapeData}/>
        </Stack>
    </Box>
  )
}
export default RPViolationData;
