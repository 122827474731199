import React from 'react';
import {InputAdornment, TableCell, Typography, useTheme} from "@mui/material";
import TextField from "@mui/material/TextField";

const FigureParameterTextField = ({rowData, onChangeAutocompleteHandler}) => {
  const theme = useTheme()
  return (
      <TextField
        fullWidth
        type={'number'}
        disabled={rowData.label === 'Объем'}
        value={rowData.value ? rowData.value : ''}
        inputProps={{style: {padding: 0, color: rowData.isStateData && theme.palette.primary.light}, ...rowData.inputProps}}
        variant={'standard'}
        size={'small'}
        onChange={(e) => onChangeAutocompleteHandler(rowData.label, e.target.value)}
      />
  );
};

export default FigureParameterTextField;