import React from 'react';
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import LocationPlotField from "./AutocompletePlotField/LocationPlotField";

/* Компонент содержит связанные поля mui autocomplete Регион -> Лесничество -> уч. Лесничество -> Урочище. Вынесен в Common т.к.
используется в приложении более одного раза. Объект dataObj должен содержать поля region, forestry, district_forestry, unique_location. */
const PlotLocationFields = ({dataObj, setDataObj, count, disabled}) => {
  const references = useSelector(state => state.referencesReducer.references)
  const referenceLoading = useSelector((state) => state.referencesReducer.referenceLoading);
  const referencePlotFields = {
    regions: {
      label: 'Регион',
      reference: 'regions',
      loading: referenceLoading,
      options: references.regions,
      value: dataObj.region,
      onChangeFunc: (_event, item) => {
        setDataObj((prev) => {
          return {...prev, region: item, forestry: null, district_forestry: null, unique_location: null,}
        })
      },
      disabled: false
    },
    forestry: {
      label: 'Лесничество',
      reference: 'forestry',
      referenceData: dataObj?.region?.id,
      loading: referenceLoading,
      options: references.forestry,
      value: dataObj.forestry,
      onChangeFunc: (_event, item) => {
        setDataObj((prev) => {
          return {...prev, forestry: item, district_forestry: null, unique_location: null,}
        })
      },
      disabled: !dataObj.region
    },
    district_forestry: {
      label: 'Участковое лесничество',
      reference: 'districtForestry',
      referenceData: dataObj?.forestry?.id,
      loading: referenceLoading,
      options: references.districtForestry,
      value: dataObj.district_forestry,
      onChangeFunc: (_event, item) => {
        setDataObj((prev) => {
          return {...prev, district_forestry: item}
        })
      },
      disabled: !dataObj.forestry
    },
    unique_locations: {
      label: 'Урочище',
      reference: 'uniqueLocations',
      referenceData: dataObj?.forestry?.id,
      loading: referenceLoading,
      options: references.uniqueLocations,
      value: dataObj.unique_location,
      onChangeFunc: (_event, item) => {
        setDataObj((prev) => {
          return {...prev, unique_location: item,}
        })
      },
      disabled: !dataObj.forestry
    },
  }

  return (
    <Grid spacing={3} container>
      {Object.keys(referencePlotFields).slice(0, count).map(field => (
        <Grid key={field} xs={4} md={4} lg={4} item>
          <LocationPlotField
            label={referencePlotFields[field].label}
            reference={referencePlotFields[field].reference}
            referenceData={referencePlotFields[field].referenceData}
            referenceLoading={referencePlotFields[field].loading}
            value={referencePlotFields[field].value}
            options={referencePlotFields[field].options}
            disabled={referencePlotFields[field].disabled || disabled}
            onChangeFunc={referencePlotFields[field].onChangeFunc}
          />
        </Grid>
      ))
      }
    </Grid>
  );
};

export default PlotLocationFields;