import React, {useEffect, useState} from 'react';
import {IconButton, InputAdornment, Link, TableCell, TableRow, TextField} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useDispatch, useSelector} from "react-redux";
import {sendConfCodeThunk} from "../../../../../redux/reducers/loginReducer";

const PasswordDataRow = ({label, field, errorMessage, onChangeHandler, maxLength = 50, type = 'text',
                         eyeIcon, sendCodeButton}) => {
  const dispatch = useDispatch()
  const username = useSelector(state => state.userReducer.userInfo.username)
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const sendSmsCodeHandler = () => {dispatch(sendConfCodeThunk(username))}

  return (
    <TableRow  sx={{display: 'flex', alignItems: 'center'}}>
      <TableCell sx={{minWidth: 110}}>{label}</TableCell>
      <TableCell sx={{pl: 0}}>
        <TextField
          sx={{width: 200}}
          type={showPassword ? 'text' : type}
          autoComplete={'off'}
          fullWidth
          size={'small'}
          inputProps={{style: {padding: 4}, maxLength: maxLength}}
          error={!!errorMessage}
          helperText={errorMessage}
          InputProps={{
            endAdornment: (
              eyeIcon &&
              <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityIcon fontSize={"small"}/> : <VisibilityOffIcon fontSize={"small"}/>}
                  </IconButton>
              </InputAdornment>
            )
          }}
          onChange={(e) => onChangeHandler(prev => e.target.value)}
        />
      </TableCell>
      {sendCodeButton &&
        <TableCell>
          <Link sx={{cursor: 'pointer'}} onClick={sendSmsCodeHandler}>{'Получить код'}</Link>
        </TableCell>
      }
    </TableRow>
  )
};

export default PasswordDataRow;