import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, List, ListItem, Stack, Typography, useTheme} from "@mui/material";
import {hideMapDialog, showMapDialog,} from "../../../../Map/Common/Dialog/DialogReducer";
import {WarehouseItem} from "./WarehouseItem";
import {setTargetWarehouse} from "./actionCreators";
import {Loading} from "../../../../Map/Common/Loading";
import {deleteWarehouse, loadWarehouses, updateWarehouseCoordinates} from "./WarehouseCommon";
import {setPlotDataAttr} from "../../../../../../redux/reducers/plotDataReducer";
import {showCoordinatesEditor} from "../../../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";
import {flyToPolygon} from "../../../../Map/Common/fly";

/**
 * Компонент отображения содержимого модуля складов в левой панели.
 */
export const Warehouses = ({}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.warehousesReducer)
  const buttons = {
    'Да': {color: theme.palette.warning.main},
    'Нет': {color: theme.palette.primary.main},
  }

  if (state.loadingError) {
    return (
      <Stack gap={2}>
        <Alert severity={'error'}>
          Произошла ошибка при загрузке складов. Нажмите кнопку ниже, чтобы попробовать еще раз.
        </Alert>
        <Button
          variant={'outlined'}
          onClick={() => {
            loadWarehouses()
          }}
        >
          Повторить
        </Button>
      </Stack>
    )
  }

  if (state.loading) {
    return (
      <ListItem>
        <Loading style={{width: '100%'}}/>
      </ListItem>
    )
  }

  function handleAction(type, warehouse) {
    switch (type) {
      case 'click':
        loadRightPanelData('Warehouse', warehouse, warehouse.properties.id, false)
        dispatch(setTargetWarehouse(warehouse))
        flyToPolygon(warehouse.layer.getLatLngs())
        break;
      case 'info':
        dispatch(setPlotDataAttr({showUpdateWarehouseModal: true, selectedWarehouse: warehouse}))
        break;
      case 'coordinates':
        dispatch(showCoordinatesEditor(
          warehouse.layer.getLatLngs()[0], 'Polygon', (latlngs) => {
            updateWarehouseCoordinates(warehouse, latlngs)
          }
        ))
        break;
      case 'delete':
        dispatch(
          showMapDialog(
            "warning",
            "Удаление склада",
            "Вы уверены, что хотите удалить склад без возможности восстановления?",
            buttons,
            (e) => {
              dispatch(hideMapDialog())
              if (e === "Да") {
                deleteWarehouse(warehouse)
              }
            }
          )
        )
        break;
    }
  }

  return (
    <List
      sx={{
        overflowY: 'auto',
        position: 'absolute',
        inset: 0,
      }}
    >
      {state.warehouses.length > 0
        ?
        <>
          {state.warehouses.map((item) => {
              return (
                <WarehouseItem
                  key={item.properties.id}
                  warehouse={item}
                  isSelected={state.targetWarehouse === item}
                  handleAction={handleAction}
                />)
            }
          )}
        </>
        :
          <ListItem>
            <Typography>Нет складов для отображения</Typography>
          </ListItem>
      }
    </List>
  )
}
