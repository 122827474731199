import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {urlModify} from "../../../../utils/ulrModify";
import Outline from "./SvgElements/Outline";
import {addPointOnLine, setPhotoAttr, updateSvgData} from "../../../../redux/reducers/stockpilePhotoReducer";
import Timbers from "./SvgElements/Timbers";
import Ruler from "./SvgElements/Ruler";
import {getTypeAndIndexSvgElement} from "./imageSvgFunctions/getTypeAndIndexSvgElement";
import {keyDown} from "./imageSvgFunctions/keyDown";
import {getSvgCoord} from "../../../../svgComponentsHelpersFunctions/getSvgCoord";
import {coordCorrection} from "../../../../svgComponentsHelpersFunctions/coordCorrection";
import {scrollZoom} from "./imageSvgFunctions/scrollZoom";
import {setStartDragImagePoint} from "./imageSvgFunctions/setStartImagePoint";
import {endAllDragEvents} from "./imageSvgFunctions/endAllDragEvents";
import {outlineModeClickHandler} from "./imageSvgFunctions/outlineModeClickHandler";
import {dragHandler} from "./imageSvgFunctions/dragHandler";
import {viewModeClickHandler} from "./imageSvgFunctions/viewModeClickHandler";
import {checkPointOnPolygonLine} from "../../../../svgComponentsHelpersFunctions/checkPointOnPolygonLine";
import NearestOutlinePoints from "./SvgElements/NearestOutlinePoints";
import {rulerModeClickHandler} from "./imageSvgFunctions/rulerModeClickHandler";
import CuttingLine from "./SvgElements/CuttingLine";
import {stockpileSvgConfig} from "./svgConfig";
import {cropOutlinesByRulerWidth} from "./imageSvgFunctions/cropOutlineByRulerWidth";
import {cropTimbersByRulerWidth} from "./imageSvgFunctions/cropTimbersByRulerWidth";

const ImageSvg = ({containerSize, iAmEditor}) => {
  let dispatch = useDispatch()
  const svg = useRef(null)
  const [dragMode, setDragMode] = useState(null) // image, outlinePoint, rulerPoint
  const [dragStartingPoint, setDragStartingPoint] = useState({
    scrollLeft: null, scrollTop: null,
    clientX: null, clientY: null,
  })
  const [nearestOnlinePoints, setNearestOnlinePoints] = useState(null)
  const [currentPosition, setCurrentPosition] = useState({x: null, y: null})
  const [cursorType, setCursorType] = useState('crosshair')
  const isClosed = useSelector(state => state.stockpilesReducer.selectedPlot?.is_closed)
  const stockpilePhotoState = useSelector(state => state.stockpilePhotoReducer)
  const {
    selectedPhoto, zoom, outlines, scale, timbers_px_include, layers, ruler, mode, outline_selected_index,
    outline_selected_point_index, outline_viewed_point_index, ruler_viewed_point_index, creationMode, cuttingMode,
    cutPoints
  } = stockpilePhotoState
  const selectedFigure = outline_selected_index !== null ? selectedPhoto.figures[outline_selected_index] : null
  const {custom_cursor} = stockpileSvgConfig
  const svgNodeId = 'stockpileSvg'
  const stockpileSvgContainerNodeId = 'stockpile-svg-container'
  const img = new Image();
  const image = urlModify(selectedPhoto?.image);

  img.src = image;


  // эффект для пересчета размеров svg и scale при изменении размера контейнера
  useEffect(() => {
    const widthScale = containerSize.width / selectedPhoto.image_width
    const heightScale = containerSize.height / selectedPhoto.image_height
    dispatch(setPhotoAttr({scale: Math.min(widthScale, heightScale)}))
  }, [containerSize]);

  // эффект для обновления  элементов svg при изменении selectedPhoto
  useEffect(() => {
    dispatch(updateSvgData(selectedPhoto))
  }, [selectedPhoto]);

  // эффект для обновления курсора
  useEffect(() => {
    if (dragMode) {
      setCursorType('grabbing');
    } else if (outline_viewed_point_index !== null || ruler_viewed_point_index !== null) {
      setCursorType('grab');
    } else if (creationMode || nearestOnlinePoints || cuttingMode) {
      setCursorType(custom_cursor)
    } else {
      setCursorType('crosshair')
    }
  }, [dragMode, creationMode, nearestOnlinePoints, outline_viewed_point_index, ruler_viewed_point_index,
    cuttingMode]);


  // обработчик нажатия ЛКМ. отвечает за начало события drag
  const onMouseDownHandler = (e) => {
    if (isClosed || !iAmEditor) return;
    const {svgElementName, svgElementIndex} = getTypeAndIndexSvgElement(e)
    switch (mode) {
      case 'outline':
        if (svgElementName === 'outlinePoint' && !cuttingMode) {
          setDragMode('outlinePoint')
          dispatch(setPhotoAttr({outline_selected_point_index: svgElementIndex}))
          return
        }
        if (nearestOnlinePoints && selectedFigure && !cuttingMode) {
          dispatch(addPointOnLine(currentPosition, nearestOnlinePoints[1]))
          setDragMode('outlinePoint')
        }
        break
      case 'ruler':
        if (svgElementName === 'rulerPoint') {
          setDragMode('rulerPoint')
          dispatch(setPhotoAttr({ruler_selected_point_index: svgElementIndex}))
        }
        break
    }
  }

  // обработчик перемещения курсора по фото. отвечает за сохранение текущей позиции курсора в локальном стейте, за
  // определение нахождения точки на линии фигуры, за drag точек
  function onMouseMoveHanlder(e) {
    setCurrentPosition(getSvgCoord(e, zoom, scale, svgNodeId))
    if (dragMode) {
      dragHandler(e, dragMode, dragStartingPoint, stockpileSvgContainerNodeId, dispatch,
        zoom, scale, svgNodeId)
    }
    if (isClosed) return;
    if (outline_selected_index !== null) {
      setNearestOnlinePoints(checkPointOnPolygonLine(outlines[outline_selected_index], currentPosition, 15))
    }
  }

  // обработчик mouse up ЛКМ. отвечает за end drag, и за события click в разных режимах
  function onMouseUpHandler(e) {
    if (isClosed || !iAmEditor) return;
    if (dragMode) {
      endAllDragEvents(setDragMode, setDragStartingPoint)
      if (dragMode === 'rulerPoint') {
        cropOutlinesByRulerWidth(outlines, ruler, dispatch, selectedPhoto)
        cropTimbersByRulerWidth(ruler, dispatch, selectedPhoto)
      }
      return
    }
    const {svgElementName, svgElementIndex} = getTypeAndIndexSvgElement(e)
    switch (mode) {
      case 'view':
        viewModeClickHandler(svgElementName, svgElementIndex, stockpilePhotoState, dispatch)
        break
      case 'outline':
        outlineModeClickHandler(currentPosition, svgElementName, svgElementIndex, stockpilePhotoState, dispatch)
        break
      case 'ruler':
        rulerModeClickHandler(currentPosition, svgElementName, svgElementIndex, dispatch,
          creationMode, ruler, outlines, selectedPhoto)
        break
    }
  }

  return (
    <>
      <svg ref={svg}
           style={{
             cursor: cursorType,
             width: coordCorrection(selectedPhoto.image_width, zoom, scale),
             height: coordCorrection(selectedPhoto.image_height, zoom, scale),
           }}
           tabIndex="0"
           id={svgNodeId}
           onMouseMove={(e) => onMouseMoveHanlder(e)}
           onMouseDown={(e) => {
             e.button === 0 && onMouseDownHandler(e)
             e.button === 1 && setStartDragImagePoint(e, setDragMode, setDragStartingPoint, stockpileSvgContainerNodeId)
           }}
           onMouseUp={(e) => {
             e.button === 0 && onMouseUpHandler(e)
             e.button === 1 && endAllDragEvents(setDragMode, setDragStartingPoint)
           }}
           onMouseLeave={() => {
             endAllDragEvents(setDragMode, setDragStartingPoint)
           }}
           onKeyDown={(e) => keyDown(e, outline_selected_index, outline_selected_point_index, dispatch)}
           onWheel={(e) => {
             scrollZoom(e, dispatch, zoom, stockpileSvgContainerNodeId)
           }}
      >
        <image width={'100%'} href={image}/>

        {timbers_px_include && layers.timbers &&
          <Timbers/>
        }
        {nearestOnlinePoints && selectedFigure && !cuttingMode &&
          <NearestOutlinePoints nearestOnlinePoints={nearestOnlinePoints}/>
        }
        {outlines.length && layers.outline && outlines.map((outline, i) => (
          <Outline key={i} outline={outline} index={i} dragMode={dragMode}/>
        ))}
        {ruler && layers.ruler &&
          <Ruler dragMode={dragMode}/>
        }
        {(cuttingMode && cutPoints) &&
          <CuttingLine/>
        }
      </svg>
    </>
  );
};

export default ImageSvg;