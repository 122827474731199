import React, {useEffect, useState} from 'react';
import {IconButton, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import FigureInfoTableRow from "./FigureInfoTableRow/FigureInfoTableRow";
import {useDispatch, useSelector} from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const FigureInfoTable = ({figureType, editMode, planetableObj}) => {
  const dispatch = useDispatch()
  const selectedSvgFigure = useSelector(state => state.planetableSvgReducer.selectedSvgFigure)
  const [isInputEnabled, setIsInputEnabled] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    setData(planetableObj)
  }, [])

  const onChangeInput = (e, propName) => {
    setData(prev => {
      return {...prev, [propName]: e.target.value}
    })
  }

  const saveDataButtonHandler = () => {}

  return (
    <Table size={"small"} sx={{p: 0, [`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
      <TableBody>
        <TableRow>
          <TableCell>Тип объекта: </TableCell>
          <TableCell>{figureType}</TableCell>
          {editMode &&
            <TableCell sx={{width: 20}}>
              {isInputEnabled ?
                <IconButton sx={{p: 0}}
                            onClick={saveDataButtonHandler}
                >
                  <SaveIcon sx={{fontSize: 20}}/>
                </IconButton>
                :
                <IconButton sx={{p: 0}}
                            disabled={figureType === 'Общая область'}
                            onClick={() => setIsInputEnabled(prev => !prev)}
                >
                  <EditOutlinedIcon sx={{fontSize: 20}}/>
                </IconButton>
              }
            </TableCell>
          }
        </TableRow>
        {figureType === 'Квартал' &&
          <>
            <FigureInfoTableRow rowName={'Номер'} rowData={data?.number} onChangeInput={onChangeInput}
                                propName={'number'} isInputEnabled={isInputEnabled} editMode={editMode}/>
          </>
        }
        {figureType === 'Столб' &&
          <>
            <FigureInfoTableRow rowName={'Имя'} rowData={data?.name} onChangeInput={onChangeInput}
                                propName={'name'} isInputEnabled={isInputEnabled}/>
          </>
        }
        {figureType === 'Выдел' &&
          <>
            <FigureInfoTableRow rowName={'Номер квартала'} rowData={data?.quarter_number} onChangeInput={onChangeInput}
                                propName={'quarter_number'} isInputEnabled={isInputEnabled}/>
            <FigureInfoTableRow rowName={'Номер выдела'} rowData={data?.number} onChangeInput={onChangeInput}
                                propName={'number'} isInputEnabled={isInputEnabled}/>
          </>
        }
      </TableBody>
    </Table>
  );
};

export default FigureInfoTable;