// завершение всех событий drag'n'drop
export const endAllDragEvents = (setDragMode,  setDragStartingPoint) => {
  setDragMode(null)
  setDragStartingPoint(
    {
      scrollLeft: null,
      scrollTop: null,
      clientX: null,
      clientY: null,}
  )
}