import React from 'react';
import PlotsStockpilesTreeItem from "./PlotsStockpilesTreeItem/PlotsStockpilesTreeItem";
import {useSelector} from "react-redux";

export const getFieldName = (item, field) => {
  let name = null
  switch (field) {
    case 'owner':
      name = item[field].organization_name || "Владелец (нет)"
      break
    case 'rental_contract':
      name = item[field]?.name || "Рабочая область (нет)"
      break
    case 'declaration':
      name = item[field]?.name || "Декларация (нет)"
      break
    case 'status':
      name = item[field] || "Статус (нет)"
      break
  }
  if (!name) name = 'Значение не задано'
  return name
}

export function PlotsStockpilesTree({itemsList, fields, iAmEditor}) {
  const plotsFilters = useSelector(state => state.stockpilesReducer.plotsFilters)
  const selectedFilters = Object.keys(plotsFilters).filter(filter => plotsFilters[filter].status)
  if (!fields.length) return ''
  const [field, ...restFields] = fields;
  // Получаем уникальные значения для текущего поля
  const uniqueValues = [...new Set(itemsList.map((item) => getFieldName(item, field)))];
  const step = selectedFilters?.length - restFields.length

  return (
    <PlotsStockpilesTreeItem plotsList={itemsList} uniqueValues={uniqueValues} field={field} restFields={restFields}
                         step={step} iAmEditor={iAmEditor}/>
  );
}

export default PlotsStockpilesTree;