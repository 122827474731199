import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setPlanetableSvgAttr} from "../../../redux/reducers/planetableSvgReducer";
import {endAllDragEvents, scrollZoom, setStartDragImagePoint,} from "./PlanetableSvgFunctions/dragAndZoomImage";
import {keydown} from "./PlanetableSvgFunctions/keydownHandler";
import {
  dragLayerByKeys,
  dragLayerByMouse,
  rotateLayer,
  scaleLayer,
} from "./PlanetableSvgFunctions/editFigureFunctions";
import Quarters from "./SvgElements/Quarters";
import LayerFromMap from "./SvgElements/LayerFromMap";
import WASDRFButtonsBlock from "../../Common/WASDRFButtonsBlock/WASDRFButtonsBlock";
import {coordCorrection} from "../../../svgComponentsHelpersFunctions/coordCorrection";
import {dragToScrollImage} from "../../../svgComponentsHelpersFunctions/dragToScrollImage";
import {getTypeAndIndexSvgElement} from "../../../svgComponentsHelpersFunctions/getTypeAndIndexSvgElement";
import {planetablesSvg} from "../PlanetabesPageSyles";

const PlanetableSvg = ({containerSize}) => {
  const dispatch = useDispatch()
  const planetableState = useSelector(state => state.planetableSvgReducer)
  const {
    selectedPlanetable, layerFromMap, selectedSvgFigure, currentlyPressedButton, mode, zoom, scale} = planetableState
  const [dragMode, setDragMode] = useState(null) //image, layer
  const [cursorType, setCursorType] = useState('crosshair')
  const [dragStartingPoint, setDragStartingPoint] = useState({scrollLeft: null, scrollTop: null,
    clientX: null, clientY: null,})
  const planetableUrl = process.env.REACT_APP_DOMAIN_NAME + selectedPlanetable.image
  const svgId = 'planetableSvg'
  const planetableSvgContainerNodeId = 'svg-container'
  const planetableSvg = document.getElementById(svgId)

  // при изменении размера контейнера пересчитываем масштаб. containerSize меняется в родительском компоненте через resizeObserver
  useEffect(() => {
    const widthScale = containerSize.width / selectedPlanetable.width
    const heightScale = containerSize.height / selectedPlanetable.height
    dispatch(setPlanetableSvgAttr(
      {scale: Math.min(widthScale, heightScale),}))
  }, [containerSize]);

  //при изменении selectedPlanetable обновляем quartersSvg
  useEffect(() => {
    dispatch(setPlanetableSvgAttr({quartersSvg: selectedPlanetable.quarters_px.map(quarter =>
        quarter.coordinates_pxl)}))
  }, [selectedPlanetable])

  //обработчик левого onMouseDown
  const startTracking = (e) => {
    const {svgElementName} = getTypeAndIndexSvgElement(e)
    if (svgElementName === 'quarterFromMap') {
      setDragMode('layer')
    }
  }

  //обработчик onMouseMove
  const tracking = (e) => {
    const {svgElementName, svgElementIndex} = getTypeAndIndexSvgElement(e)
    switch (svgElementName) {
      case 'quarterFromMap':
        if (dragMode !== 'layer') {
          setCursorType('grab')
        }
        if (dragMode === 'layer') {
          setCursorType('grabbing')
        }
        break
      case 'quarter':
        setCursorType('pointer')
        dispatch(setPlanetableSvgAttr({viewedSvgFigure:
            {type: 'quarter', index: svgElementIndex, planetableObj: selectedPlanetable.quarters_px[svgElementIndex]}}))
        break
      default:
        setCursorType('default')
    }

    // в зависимости от dragMode выполняем нужный drag
    switch (dragMode) {
      case 'image':
        dragToScrollImage(e, dragStartingPoint, planetableSvgContainerNodeId)
        break
      case 'layer':
        dragLayerByMouse(e, setDragStartingPoint, dragStartingPoint, zoom, planetableSvg, dispatch, scale)
    }
  }

  // действия при onMouseUp
  const click = () => {
    // если состоялось событие drag то завершаем его
    if (dragMode !== null) {
      endAllDragEvents(setDragMode, setDragStartingPoint, setCursorType)
    }
  }

  // поворот фигуры
  const rotateButtonsHandler = (direction) => {
    rotateLayer(layerFromMap, direction, dispatch, 0.25)
  }


  const arrowButtonsHandler = (direction) => {
    direction === 'up' && dragLayerByKeys(0, -1, dispatch)
    direction === 'down' && dragLayerByKeys(0, 1, dispatch)
    direction === 'left' && dragLayerByKeys(-1, 0, dispatch)
    direction === 'right' && dragLayerByKeys(1, 0, dispatch)

  }

  const scaleButtonsHandler = (e, operator) => {
    let scalePercent = operator === '+' ? 0.5 : -0.5
    scaleLayer(layerFromMap, e, dispatch, scalePercent)
  }

  return (
    <>
        <svg
          style={{...planetablesSvg, cursor: cursorType}}
          id={svgId}
          tabIndex="0"
          width={coordCorrection(selectedPlanetable.width, zoom, scale)}
          height={coordCorrection(selectedPlanetable.height, zoom, scale)}
          onMouseMove={(e) => tracking(e)}
          onMouseDown={(e) => {
            e.button === 0 && startTracking(e)
            if (e.button === 1) {
              setStartDragImagePoint(e, setDragMode, setDragStartingPoint)
            }
          }}
          onMouseUp={(e) => {
            e.button === 0 && click(e)
            e.button === 1 && endAllDragEvents(setDragMode, setDragStartingPoint, setCursorType)
          }}
          onMouseLeave={() => {
            endAllDragEvents(setDragMode, setDragStartingPoint, setCursorType)
          }}
          onWheel={(e) => {scrollZoom(e, dispatch, zoom)}}
          onKeyDown={(e) => keydown(e, selectedSvgFigure, mode, layerFromMap, dispatch)}
          onKeyUp={() => dispatch(setPlanetableSvgAttr({currentlyPressedButton: null}))}
        >
          <image height={'100%'} id={'image'} href={planetableUrl}/>
          {mode !== 'layer' &&
            <Quarters/>
          }
          <LayerFromMap/>
        </svg>
      {mode === 'layer' &&
        <WASDRFButtonsBlock svgElement={planetableSvg} rotateButtonsHandler={rotateButtonsHandler}
                          arrowButtonsHandler={arrowButtonsHandler} currentlyPressedButton={currentlyPressedButton}
                          scaleButtonsHandler={scaleButtonsHandler} leftPos={0} topPos={0}/>
      }
    </>
  )
}

export default PlanetableSvg;
