import L from 'leaflet';
import '../../Map/Common/libs/leaflet-side-by-side/index';
import {resetBlindBaseLayers} from "./BlindBaseLayersCommon";
import {resetBlindMiscLayers} from "./BlindMiscLayersCommon";
import {getUserSettings} from "../Common/userSettings";
import {getMap1} from "../GlobalObjects";
import {setBlindEnabled} from "./BlindReducer";
import {resetUserMiscBlindLayers} from "../../LeftPanel/Context/MiscLayers/UserLayers/UserLayers";
import {setBlindControlPosition} from "../Common/libs/leaflet-side-by-side";
import {removeAllRentBlindLayers} from "../../LeftPanel/Context/Projects/ProjectsCommon";
import {removeAllRoadsBlindLayers} from "../../LeftPanel/Context/Roads/roads";

const leftPanes = ['leaflet-base-pane', 'leaflet-bottom-pane', 'leaflet-sentinel-pane', 'leaflet-bigarenda-pane',
  'leaflet-user_misc-pane', 'leaflet-misc-pane', 'leaflet-top-pane', 'leaflet-perimeters-pane',
  'leaflet-misc_fires-pane', 'leaflet-roads-pane', 'leaflet-pillars-pane'];
const rightPanes = ['leaflet-blind_base-pane', 'leaflet-blind_sentinel-pane', 'leaflet-blind_user_misc-pane',
  'leaflet-blind_misc-pane', 'leaflet-blind_bigarenda-pane', 'leaflet-blind_perimeters-pane',
  'leaflet-blind_fires-pane', 'leaflet-blind_roads-pane', 'leaflet-blind_pillars-pane'];

let blindControl = null;

export function resetAllBlindLayers() {
  resetBlindBaseLayers()
  resetBlindMiscLayers()
  resetUserMiscBlindLayers()
  removeAllRentBlindLayers()
  removeAllRoadsBlindLayers()
}

export function getSideToSideControl() {
  return blindControl;
}

export function addBlindControl(position = 0.75) {
  blindControl = L.control.sideBySide(leftPanes, rightPanes, {controlPosition: position})
  blindControl.addTo(getMap1())
}

export function removeBlindControl() {
  if (blindControl) {
    blindControl.remove()
    getMap1().removeControl(blindControl)
  }
  blindControl = null;
}

export function initBlind(dispatch) {
  const settings = getUserSettings()
  if (!settings.blind)
    settings.blind = {enabled: true, left: true}
  if (settings.blind.position) {
    setBlindControlPosition(settings.blind.position)
  }
  if (settings.blind.enabled) {
    addBlindControl(settings.blind.position)
  }
  dispatch(setBlindEnabled(settings.blind.enabled))
}
