import React from 'react';
import {TableBody, TableCell, TableRow} from "@mui/material";
import {Loading} from "../../../../Main/Map/Common/Loading";

const TableLoading = ({tableColumns}) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell
          style={{textAlign: 'center', verticalAlign: 'middle'}}
          colSpan={tableColumns.length + 1}
        >
          <Loading size={18}/>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TableLoading;