import {IconButton, Menu, MenuItem} from "@mui/material";
import {DownloadOutlined} from "@mui/icons-material";
import {treeItemButtonIconSize, treeItemButtonStyle} from "../../../Map/Common/Styles";
import {functionalIsNotRelized, saveAsString} from "../../../Map/Common/Strings_RU";
import {useState} from "react";
import {saveGPXTrackFile} from "../../../Map/Common/Exports/gpx";
import {useDispatch} from "react-redux";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {saveGEOJsonFeatureCollection} from "../../../Map/Common/Exports/geojson";

export function DownloadFileButton(props) {
  const node = props.node;
  const [state, setState] = useState({anchor: null})
  const dispatch = useDispatch()

  function handleDownloads(e) {
    setState({...state, anchor: null})
    switch (e) {
      case 'GPX':
        saveGPXTrackFile(node)
        break
      case 'KML':
        dispatch(setSnack('warning', functionalIsNotRelized))
        break;
      case 'GEOJSON':
        saveGEOJsonFeatureCollection(node)
        break;
    }
  }

  return (
    <>
      <IconButton
        title={saveAsString}
        disableRipple
        style={treeItemButtonStyle}
        onClick={(e) => {
          if (state.anchor)
            setState({...state, anchor: null})
          else
            setState({...state, anchor: e.target})
        }}
      >
        <DownloadOutlined style={treeItemButtonIconSize}/>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={state.anchor}
        open={Boolean(state.anchor)}
        onClose={() => setState({anchor: null})}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/*<MenuItem disabled={true} style={{opacity: 1, paddingTop: 0, paddingBottom: 0}}><Typography style={{fontWeight: 600}}>Выберите формат</Typography></MenuItem>
        <Divider/>*/}
        <MenuItem onClick={() => handleDownloads('GPX')}>Треки GPX</MenuItem>
{/*        <MenuItem onClick={() => handleDownloads('KML')}>Google KML</MenuItem>*/}
        <MenuItem onClick={() => handleDownloads('GEOJSON')}>GeoJson</MenuItem>
      </Menu>
    </>
  )
}
