import React from 'react';
import {Stack, Typography} from "@mui/material";
import {textStyle} from "../../organizationPageStyles";
import {useSelector} from "react-redux";

const NextPayDate = () => {
  const paid_to = useSelector(state => state.organizationInfoReducer.organizationData.paid_to)

  return (
    <Stack sx={{ml: 2, mb: 2}} direction={'row'} spacing={2}>
      <Typography sx={{...textStyle, fontSize: '0.875rem'}}>{`Дата следующего платежа: `}</Typography>
      <Typography sx={{fontSize: '0.875rem'}} color={'primary'} fontWeight={700}>{paid_to}</Typography>
    </Stack>
  );
};

export default NextPayDate;