import {Alert, Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {setPlanetablesAttr} from "../../../redux/reducers/planetablesReducer";
import GroupingMenu from "./GroupingList/GroupingList";
import {PlanetablesTree} from "./PlanetablesTree/PlanetablesTree";
import Typography from "@mui/material/Typography";
import {Loading} from "../../Main/Map/Common/Loading";

const PlanetablesGrouping = ({iAmEditor}) => {
  const dispatch = useDispatch()
  const allPlanetables = useSelector(state => state.planetablesReducer.allPlanetables)
  const allPlanetablesLoading = useSelector(state => state.planetablesReducer.allPlanetablesLoading)
  const allPlanetablesError = useSelector(state => state.planetablesReducer.allPlanetablesError)
  const selectedGroups = useSelector(state => state.planetablesGroupingReducer.selectedGroups)
  const selectedGroupsList = Object.keys(selectedGroups).filter(item => selectedGroups[item])

  return (
      <Stack spacing={1} sx={{display: 'flex', alignItems: 'start', height: '100%', boxSizing: 'border-box', p: 1.5}}>
        <Button disabled={!iAmEditor} id={'uploadPlanetable'} size={'small'} variant="outlined" fullWidth onClick={() =>
          dispatch(setPlanetablesAttr({showUploadPlanetableModal: {status: true, planetable: null,
              viewMode: false}}))}
        >
          {'Загрузить планшет'}
        </Button>
        {allPlanetablesLoading
          ?
            <Loading style={{width: '100%'}}/>
          :
          <>
            <GroupingMenu selectedGroupslist={selectedGroupsList}/>
            {selectedGroupsList.length && allPlanetables.length
              ?
                <Box sx={{flex: 1, width: '100%', position: 'relative', overflow: 'hidden'}} id={'planetablesList'}>
                  <Box sx={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto'}}>
                    <PlanetablesTree itemsList={allPlanetables} fields={selectedGroupsList}
                                     fieldsListLength={selectedGroupsList.length}/>
                  </Box>
                </Box>
              :
                <Alert severity={!allPlanetablesError ? 'info' : 'error'} sx={{display: 'flex', justifyContent: 'center'}}>
                  {!selectedGroupsList.length &&(
                    <Typography alignItems={'center'}>
                      Для просмотра планшетов выберите нужный фильтр
                    </Typography>
                  )}
                  {!allPlanetables.length && allPlanetablesError &&(
                    <Typography alignItems={'center'}>
                      При загрузке планшетов произошла ошибка. Проверьте подключение к интернету или попробуйте позже
                    </Typography>
                  )}
                  {!allPlanetables.length && !allPlanetablesError && (
                    <Typography alignItems={'center'}>
                      У вас еще нет загруженных планшетов. Для загрузки нажмите кнопку "Загрузить планшет"
                    </Typography>
                  )}
                </Alert>
            }
          </>
        }
      </Stack>
  )
}

export default PlanetablesGrouping;
