import React from "react";
import Dialog from "@mui/material/Dialog";
import {Button, Typography} from "@mui/material";
import {mbannerButtonText, mbannerText} from "../Strings_RU";

export function MobileBanner(props) {
  return (
    <Dialog open={props.open}>
      <div style={{margin: '1vh'}}>
        <Typography variant={'body1'}>{mbannerText}</Typography>
      </div>
      <Button
        variant={'contained'}
        style={{margin: '1vh'}}
        onClick={props.callback}
      >
        <Typography variant={'subtitle2'}>{mbannerButtonText}</Typography>
      </Button>
    </Dialog>
  )
}
