import React from 'react';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {setRightPanelAttr} from "../../../../../redux/reducers/rightPanelReducer";

const Status = ({status, readOnly}) => {
  const dispatch = useDispatch()
  const showCADataDialogWindow = useSelector(state => state.rightPanelReducer.showCADataDialogWindow)
  const using_1C = useSelector(state => state.organizationInfoReducer.organizationData?.using_1C)

  const statusButtonClickHandler = () => {
    dispatch(setRightPanelAttr({showCADataDialogWindow: true}))
  }

  return (
    <>
      {!using_1C && !readOnly
      ?
        <Button size={'small'} sx={{mr: 2}}
                variant={!showCADataDialogWindow ?'outlined' : 'contained'}
                onClick={statusButtonClickHandler}>
          {status}
        </Button>
      :
        <>
          <Typography sx={{fontWeight: 'bold'}}>Статус</Typography>
          <Typography>{status}</Typography>
        </>
      }

    </>
  )
}

export default Status;
