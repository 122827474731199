const namePattern = /^[а-яА-ЯёЁa-zA-Z\s-]+$/;
const phonePattern = /^9\d{9}$/;
const emailPattern = /^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}$/;


export const isEmpty = (value) => !value || value.trim() === "";

export const validateField = (label, text, isRequired = false) => {
  if (isRequired && isEmpty(text)) {
    return 'Поле не может быть пустым.';
  } else {
    if (isEmpty(text)) return ''

    switch (label) {
      case 'first_name':
      case 'last_name':
      case 'patronymic':
      case 'post':
        return validateName(text)
      case 'phone':
        return validatePhone(text)
      case 'email':
        return validateEmail(text)
      default:
        return '';
    }
  }
}

const validateName = (name) => {
  if (!namePattern.test(name)) {
    return "Неверный формат. Поле может содержать русские или латинские буквы, знаки пробела и тире";
  }
  return '';
};

const validatePhone = (phone) => {
  if (!phonePattern.test(phone)) {
    return "Неверный формат. Номер телефона должен содержать 10 цифр, первой цифрой должна быть цифра '9'";
  }
  return '';
};

const validateEmail = (email) => {
  if (!emailPattern.test(email)) {
    return "Неверный формат адреса электронной почты";
  }
  return '';
};

export {
  validateName,
  validatePhone,
  validateEmail,
};