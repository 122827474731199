
const defaultState = {
  enabled: true,
  left: true, //left-right switch
  action: null,
  actionParams: null,
}

export function setBlindEnabled(enabled) {
  return {
    type: 'MAP_SET_BLIND_ENABLED',
    enabled: enabled,
  }
}

export function setBlindLeft(left) {
  return {
    type: 'MAP_SET_BLIND_LEFT',
    left: left,
  }
}

export function blindRefresh() {
  return {
    type: 'MAP_BLIND_REFRESH',
  }
}

export function setBlindAction(action = null, params = null) {
  return {
    type: 'MAP_BLIND_ADD_ACTION',
    action: action,
    params: params,
  }
}

export function blindReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_SET_BLIND_ENABLED':
      return {...state, enabled: action.enabled, left: !action.enabled?true:state.left}
    case 'MAP_SET_BLIND_LEFT':
      return {...state, left: action.left}
    case 'MAP_BLIND_ADD_ACTION':
      return {...state, action: action.action, actionParams: action.params}
    case 'MAP_BLIND_REFRESH':
      return {...state}
    default:
      return state;
  }
}
