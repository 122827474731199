// Action Types
const SET_LEFT_DRAWER_CUR_TAB = 'LD_SET_CURTAB';
const SET_LEFT_DRAWER_ATTR = 'SET_LEFT_DRAWER_ATTR';

// Initial State
const defaultState = {
  curTab: '',
}

// Reducer
export function leftDrawerReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LEFT_DRAWER_CUR_TAB:
      return {
        ...state,
        curTab: action.tab,
        curObj: action.obj,
      };
    case SET_LEFT_DRAWER_ATTR:
      return {...state, ...action.payload}
    default:
      return state;
  }
}

// Action Creators
export const setLeftDrawerCurTab = (tab, obj) => ({type: SET_LEFT_DRAWER_CUR_TAB, tab, obj,})
//export const setLeftDrawerAttr = (payload) => ({type: SET_LEFT_DRAWER_ATTR, payload});
