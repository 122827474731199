import Box from "@mui/material/Box";
import {Collapse,} from "@mui/material";
import WorkSpacesTable from "./WorkSpacesTable/WorkSpacesTable";
import {blockContainerStyle} from "../organizationPageStyles";
import {BottomBlockTitle} from "../BottomBlock/BottomBlockTitle";

const Workspaces = ({openedBlock, setOpenedBlock}) => {
  return (
    <Box sx={blockContainerStyle} id={'workspaces'}>
      <BottomBlockTitle openedBlock={openedBlock} setOpenedBlock={setOpenedBlock} blockName={'Workspaces'}
                          title={'РАБОЧИЕ ОБЛАСТИ'}/>
      <Collapse in={openedBlock === 'Workspaces'} timeout="auto" unmountOnExit>
        <WorkSpacesTable/>
      </Collapse>
    </Box>
  )
}

export default Workspaces;
