import {FormControl, InputLabel, MenuItem, Select, Stack, Typography,} from "@mui/material";
import {blockTitle} from "../rightPanelStyles";
import {useDispatch} from "react-redux";
import {insertViolationPriorityThunk, insertViolationStatusThunk,} from "../../../../redux/reducers/rightPanelReducer";
import {useState} from "react";

/**
 * Свойства нарушения в правой панели.
 * @param {string} status - текущий статус нарушения.
 * @param {string} priority - приоритет нарушения.
 * @param vioilationId - id нарушения
 * @param iAmEditor - true, если узер редактор и выше
 */
const ViolationProperties = ({status, priority, vioilationId, iAmEditor}) => {
  const dispatch = useDispatch()

  const VIOLATION_OPTIONS = {
    status: ["Новое", "В процессе", "Закрыто"],
    priority: ["Низкий", "Средний", "Высокий"],
  };

  const [formData, setFormData] = useState({
    status: status,
    priority: priority,
  });

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    })

    dispatch(
      field === "status"
        ? insertViolationStatusThunk(value, vioilationId)
        : insertViolationPriorityThunk(value, vioilationId)
    )
  }

  return (
    <>
      <Typography style={blockTitle}>Характеристики</Typography>
      <Stack spacing={1}>
        <FormControl size="small">
          {iAmEditor
            ?
            <>
              <InputLabel size="small">Статус</InputLabel>
              <Select
                value={formData.status}
                label="Статус"
                onChange={(e) => handleChange("status", e.target.value)}
              >
                {VIOLATION_OPTIONS.status.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </>
            :
            <Typography>Тип: {formData.status}</Typography>
          }
        </FormControl>
        <FormControl size="small">
          {iAmEditor
            ?
            <>
              <InputLabel size="small">Приоритет</InputLabel>
              <Select
                value={formData.priority}
                label="Приоритет"
                onChange={(e) => handleChange("priority", e.target.value)}
              >
                {VIOLATION_OPTIONS.priority.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </>
            :
            <Typography>Приоритет: {formData.priority}</Typography>
          }
        </FormControl>
      </Stack>
    </>
  )
}

export default ViolationProperties;
