import React, {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {CircularProgress, DialogContent, DialogContentText} from "@mui/material";
import {otherDataAPI} from "../../../../../../api/api";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {djangoErrorsParse} from "../../../../../../utils/djangoErrorsParse";
import {commonNetwokError} from "../../../../Map/Common/Strings_RU";
import {useDispatch, useSelector} from "react-redux";
import {setFireNotification} from "./workspaceReducer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

/**
 * Функция для создания динамического списка людей с соответствующими флажками оповещения (смс, email).
 *
 * @param {boolean} disabled - указывает, включено ли оповещение, чтобы редактировать настройки.
 * @return {JSX.Element} Компонент динамического списка.
 */
const DynamicList = ({disabled}) => {
    const dispatch = useDispatch()
    const firesData = useSelector((state) => state.workspaceReducer.firesData);
    const defaultFlags = {sms: false, email: false};
    const [employees, setEmployees] = useState([])
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [loadingIndex, setLoadingIndex] = useState(null);

    /** Загружает пользователей в выпадающий список для выбора.*/
    function loadUsers(index) {
        // Устанавливаем индекс загружаемого автокомплита.
        setLoadingIndex(index);
        otherDataAPI.organization.getOrganizationUsers().then(res => {
            setLoadingIndex(null);
            setEmployees(res.data);
        }).catch(err => {
            console.error(err);
            setLoadingIndex(null);
            dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : commonNetwokError))
        });
    }

    /** Добавляет новый слот для добавления пользователя в оповещение. */
    const handleAddRow = () => {
        dispatch(setFireNotification({...firesData, people: [...firesData.people, {employee: {}, flags: defaultFlags}]}))
    };

    /** Показывает диалог-предупреждение об удалении пользователя из списка. */
    const showDeleteItemDialog = (index) => {
        setDeleteIndex(index);
        setOpenDialog(true);
    };

    /** Удаляет слот для добавления пользователя в оповещение. */
    const handleRemoveRow = () => {
        const newPeople = [...firesData.people];
        newPeople.splice(deleteIndex, 1);
        dispatch(setFireNotification({...firesData, people: newPeople}));
        handleCloseDialog();
    };

    /** Закрывает диалоговое окно удаления. */
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDeleteIndex(null);
    };

    /** Добавляет нового пользователя в слот. */
    const handleSelectEmployee = (index, employee) => {
        const newPeople = firesData.people.map((item, i) => i === index ? {...item, employee: employee} : item);
        dispatch(setFireNotification({...firesData, people: newPeople}))
    };

    /** Управляет способами оповещения (смс, email) для пользователя. */
    const handleFlagChange = (index, flag) => {
        const updatedPeople = firesData.people.map((item, i) => {
            if (i === index) {
                // Проверяем, существует ли объект flags, если нет, создаем его.
                const flags = item.flags ? {...item.flags, [flag]: !item.flags[flag]} : {[flag]: true};
                return {...item, flags};
            }
            return item;
        });
        dispatch(setFireNotification({...firesData, people: updatedPeople}))
    };

    return (<Box sx={{maxHeight: '300px', overflow: 'auto', marginBottom: '10px',}}>
        {firesData?.people.map((person, index) => (<Box sx={{paddingTop: '5px'}} key={index} display="flex" alignItems="center" gap={8} pb={1}>
            <Autocomplete
                disabled={disabled}
                options={employees}
                isOptionEqualToValue={(option, value) => option.id === value.id || (Object.keys(value).length === 0 && value.constructor === Object)}
                getOptionLabel={(option) => {
                    // Проверяем, есть ли фамилия, имя и отчество
                    let label = option.last_name || ''; // Базовый случай, только фамилия
                    if (option.first_name) { // Если есть имя, добавляем инициал
                        label += ` ${option.first_name[0]}.`;
                    }
                    if (option.first_name && option.patronymic) { // Если имя и отчество, то добавляем всё.
                        label += `${option.patronymic[0]}.`;
                    }
                    return label;
                }}
                renderInput={(params) => (<TextField
                    {...params}
                    label="Сотрудник"
                    onFocus={() => loadUsers(index)} // Передаем индекс выбранного инпута
                    InputProps={{
                        ...params.InputProps, endAdornment: (<>
                            {loadingIndex === index ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </>),
                    }}
                />)}
                value={person.employee || null}
                size={'small'}
                onChange={(_, newValue) => handleSelectEmployee(index, newValue)}
                style={{width: 300}}
            />

            <FormControlLabel
                disabled={disabled}
                control={<Checkbox checked={person.flags?.sms || false}
                                   onChange={() => handleFlagChange(index, 'sms')}/>}
                label="SMS"
            />
            <FormControlLabel
                disabled={disabled}
                control={<Checkbox checked={person.flags?.email || false}
                                   onChange={() => handleFlagChange(index, 'email')}/>}
                label="Email"
            />
            <IconButton
                disabled={disabled}
                onClick={() => showDeleteItemDialog(index)}
            >
                <DeleteOutlineOutlinedIcon color={disabled?'disabled':'warning'} />
            </IconButton>
        </Box>))}

        <Button
            disabled={disabled}
            onClick={handleAddRow}
            style={{marginBottom: '20px'}}>Добавить сотрудника</Button>

        <Dialog open={openDialog} onClose={() => handleCloseDialog}>
            <DialogTitle>{"Внимание"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Удалить этого сотрудника?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Отмена</Button>
                <Button onClick={handleRemoveRow} autoFocus>Удалить</Button>
            </DialogActions>
        </Dialog>
    </Box>);
};

export default DynamicList;
