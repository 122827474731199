import {ImageList} from "@mui/material";
import StockpileImage from "./StockpileImage/StockpileImage";
import DropzoneUploadPhoto from "./DropzoneUploadPhoto/DropzoneUploadPhoto";
import {useState, useEffect} from "react";

export const StockpilesPhotosList = ({images, iAmEditor}) => {
  const [imagesList, setImagesList] = useState([])
  const [sortMode, setSortMode] = useState(false)

  useEffect(() => {
    setImagesList(images)
  }, [images])

  return (
    <ImageList
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      {iAmEditor && <DropzoneUploadPhoto/>}
      {imagesList.map((image, i) => {
        const img = new Image();
        img.src = image;
        return (
          <StockpileImage key={i} image={image} imagesList={imagesList} setImagesList={setImagesList} index={i}
                          sortMode={sortMode} setSortMode={setSortMode} iAmEditor={iAmEditor}/>
        )
      })}
    < /ImageList>
  )
}

export default StockpilesPhotosList;
