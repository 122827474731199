import React, {memo} from 'react';
import TextField from "@mui/material/TextField";


export const ReportFields = memo(({reportParameters, setReportParameters}) => {

  const reportFields = {
    owner: {
      name: "Арендатор",
      value: reportParameters.owner,
    },
    region: {
      name: "Регион",
      value: reportParameters.region,
    },
    forestry: {
      name: 'Лесничество',
      value: reportParameters.forestry,
    },
    district_forestry: {
      name: 'Участковое лесничество',
      value: reportParameters.forestry,
    },
    unique_location: {
      name: 'Урочище (дача)',
      value: reportParameters.unique_location,
    },
    quarter_number: {
      name: 'Квартал',
      value: reportParameters.quarter_number,
    },
    allotments: {
      name: 'Выдел',
      value: reportParameters.allotments,
    },
    d_plot_number: {
      name: 'Лесосека',
      value: reportParameters.d_plot_number,
    },
    d_plot_square: {
      name: 'Площадь',
      value: reportParameters.d_plot_square,
    }
  }
  return (
    <>
      {Object.keys(reportFields).map(field => (
        <TextField
          sx={{ mb: 1, }}
          size={'small'}
          required
          fullWidth
          value={reportParameters[field] || ''}
          label={reportFields[field].name}
          type="text"
          id="field"
          key={field}
          inputProps={{maxLength: 100}}
          onChange={(e) => {
            setReportParameters({
              ...reportParameters,
              [field]: e.target.value
            })
          }}
        />
      ))}
    </>
  );
})

