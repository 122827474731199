import React from 'react';
import {IconButton, Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import {toolbarIconButtonStyle, toolbarIconStyle} from "../../toolbarStyles";
import {saveCuttingDataThunk, setPhotoAttr, updateSvgData} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {getChangedFigures} from "../../../Image/ImageSvg/imageSvgFunctions/getChangedFigures";
import {getNewOutlines} from "../../../Image/ImageSvg/imageSvgFunctions/getNewOutlines";
import {cutArea} from "../../../Image/ImageSvg/imageSvgFunctions/cutArea";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";


const CutFigureButtons = () => {
  const dispatch = useDispatch()
  const cuttingMode = useSelector(state => state.stockpilePhotoReducer.cuttingMode)
  const cutPoints = useSelector(state => state.stockpilePhotoReducer.cutPoints)
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const outlines = useSelector(state => state.stockpilePhotoReducer.outlines)
  const figureParameters = useSelector(state => state.stockpilePhotoReducer.figureParameters)

  const saveCuttingData = () => {
    let figuresIdToDelete = getChangedFigures(outlines, selectedPhoto.figures)
    let newOutlines = getNewOutlines(outlines, selectedPhoto.figures)
    let newFigures = newOutlines.map(outline => ({
      ...figureParameters,
      image: selectedPhoto.id,
      points: outline,
      species: figureParameters.species?.uuid || null,
      variety: figureParameters.variety?.uuid || null,
      name: `Область_${figureParameters.species?.name || ''}_${figureParameters.length || ''}`,
    }))
    newOutlines.length && dispatch(saveCuttingDataThunk(figuresIdToDelete, newFigures))
  }

  const undoButtonHandler = () => {
    dispatch(setPhotoAttr({cuttingMode: false, cutPoints: []}))
    dispatch(updateSvgData(selectedPhoto))
  }

  const cutButtonHandler = () => {
    cutPoints.length === 2 && dispatch(setPhotoAttr({outlines: cutArea(outlines, cutPoints), cutPoints: []}))
  }

  return (
    <Stack direction={'row'}>
      <IconButton
        title={'Сохранить'}
        sx={{...toolbarIconButtonStyle, color: cuttingMode && 'primary.main'}}
        onClick={saveCuttingData}
      >
        <SaveIcon sx={toolbarIconStyle}/>
      </IconButton>
      <IconButton
        title={'Разрез'}
        onClick={cutButtonHandler}
        disabled={cutPoints.length < 2}
        sx={{...toolbarIconButtonStyle, color: cuttingMode && 'primary.main'}}
      >
        <DoneIcon sx={toolbarIconButtonStyle}/>
      </IconButton>
      <IconButton
        title={'Отмена'}
        sx={{...toolbarIconButtonStyle, color: cuttingMode && 'darkred'}}
        onClick={undoButtonHandler}
      >
        <CloseIcon sx={{...toolbarIconStyle, color: '#A44646'}}/>
      </IconButton>
    </Stack>
  );
};

export default CutFigureButtons;