import MoreVertIcon from "@mui/icons-material/MoreVert";
import {IconButton, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {setPlotDataAttr} from "../../../../../../redux/reducers/plotDataReducer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {treeItemButtonIconSize} from "../../../../Map/Common/Styles";
import Typography from "@mui/material/Typography";
import {deleteFromListString, editString} from "../../../../Map/Common/Strings_RU";
import {useDispatch} from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {caSearchLayerInSelection, removeCALayerFromSelection} from "./caSelectionsCommon";

export function CASelectionContextMenu(props) { //контекстное меню для выбранной деляны
  const layer = props.layer;
  const [menuAnchor, setMenuAnchor] = useState(null)
  const menuOpen = Boolean(menuAnchor)
  const dispatch = useDispatch()

  function deleteCAHandler() {
    const ind = caSearchLayerInSelection(layer)
    if (ind !== -1)
      removeCALayerFromSelection(ind, dispatch)
  }

  return (
    <>
      <IconButton
        disableRipple
        style={{padding: 0}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setMenuAnchor(e.currentTarget)
        }}
      >
        <MoreVertIcon fontSize={'small'}/>
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setMenuAnchor(null)
            dispatch(setPlotDataAttr({showUpdatePlotModal: true, selectedPlot: layer.elz_properties}))
          }}
        >
          <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{editString}</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            deleteCAHandler()
          }}
        >
          <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{deleteFromListString}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
