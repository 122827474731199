
const defaultState = {
  orientation: 'Landscape',
  pageSize: 'A4',
  margin: 10,
  showShapes: false,
  showLabels: false,
  showStratums : false,
}

export function setPrintingOrientation(data) {
  return {
    type: 'MAP_PRINTING_SET_ORIENTATION',
    data: data,
  }
}

export function setPrintingPageSize(data) {
  return {
    type: 'MAP_PRINTING_SET_PAGE_SIZE',
    data: data,
  }
}

export function setPrintingMargin(data) {
  return {
    type: 'MAP_PRINTING_SET_MARGIN',
    data: data,
  }
}

export function setPrintingShowShapes(data) {
  return {
    type: 'MAP_PRINTING_SET_SHOW_SHAPES',
    data: data,
  }
}

export function setPrintingShowLabels(data) {
  return {
    type: 'MAP_PRINTING_SET_SHOW_LABELS',
    data: data,
  }
}

export function setPrintingShowStratums(data) {
  return {
    type: 'MAP_PRINTING_SET_SHOW_STATUMS',
    data: data,
  }
}

export function printingReducer(state = defaultState, action) {
  switch(action.type) {
    case 'MAP_PRINTING_SET_ORIENTATION':
      return {...state, orientation: action.data}
    case 'MAP_PRINTING_SET_PAGE_SIZE':
      return {...state, pageSize: action.data}
    case 'MAP_PRINTING_SET_MARGIN':
      return {...state, margin: action.data}
    case 'MAP_PRINTING_SET_SHOW_SHAPES':
      return {...state, showShapes: action.data}
    case 'MAP_PRINTING_SET_SHOW_LABELS':
      return {...state, showLabels: action.data}
    case 'MAP_PRINTING_SET_SHOW_STATUMS':
      return {...state, showStratums: action.data}
    default:
      return state;
  }
}
