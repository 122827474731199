import {Autocomplete, Checkbox, Chip, TextField} from "@mui/material";
import {useState} from "react";
import {
  emptyString,
  loadingString, quartersLoadingErrorString,
  quartersNumbersSquenceFailedString,
} from "../../../../Map/Common/Strings_RU";
import {useDispatch} from "react-redux";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {
  convertSequenceToArray,
} from "../../../../Map/Common/MiscFunction";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {otherDataAPI} from "../../../../../../api/api";

export function WSQuartersPAutocomplete({label, callback, quarter}) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const dispatch = useDispatch()
  const {districtForestry, numbers: value} = quarter;

  const onOpenHandler = () => {
    setOpen(true)
    if (districtForestry && options) {
      setLoading(true)
      otherDataAPI.references.getAltDistrictForestryWithQuartersList(districtForestry.id).then(res => {
        const arr = [...res.data.x_quarters]
        //FIXME некоторые кварталы нумберы, некоторые - стринги. переводим всё в стринги, иначе не сравнить
        arr.forEach(obj => {obj.number = obj.number.toString()})
        //FIXME сортируем т.к. прилетает несортированное
        arr.sort((a, b) => a.number.localeCompare(b.number))
        setOptions(arr)
        setLoading(false)
      }).catch(err => {
          console.error(err)
          setOptions([])
          setLoading(false)
          dispatch(setSnack('error', quartersLoadingErrorString))
      })
    }
  }

  const onKeyDownHandler = (e) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      if (e.target.value.length > 0) {
        const result = convertSequenceToArray(e.target.value)
        if (!result.length) {
          dispatch(setSnack('error', quartersNumbersSquenceFailedString))
        } else {
          setOpen(false)
          const arr = [...quarter.numbers]
          result.forEach(num => { //по всему введенному массиву
            const number = num.toString()
            const ind = quarter.numbers.findIndex(item => item.number === number)
            if (ind === -1) { //если такого квартала еще нет
              const a = options.find(item => item.number === number)
              if (a)
                arr.push(a)
            }
          })
          arr.sort((a,b) => a.number.localeCompare(b))
          //TODO Сортировать arr?
          callback(arr)
        }
      }
    }
  }

  return (
    <Autocomplete
      disableCloseOnSelect
      disableListWrap={true}
      disabled={!districtForestry}
      size={'small'}
      multiple
      onChange={(event, newValue) => callback(newValue)}
      onReset={() => {
        callback(null)
      }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={onOpenHandler}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => String(option?.number) || ''}
      options={options}
      value={value}
      loading={loading}
      loadingText={loadingString}
      noOptionsText={emptyString}
      renderTags={(value, getTagProps) => {
        return value.map((item, index) => (
          <Chip
            key={index}
            label={item.number || ''}
            {...getTagProps({ index })}
          />
        ))
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={value.some(v => v?.id === option?.id)}
          />
          {option?.number || ''}
        </li>
      )}
      renderInput={(params) =>
        (<TextField
          onKeyDown={onKeyDownHandler}
          style={{flexWrap: 'nowrap'}}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />)
      }
    />
  )
}
