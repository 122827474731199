import React from 'react';
import {TableCell, TableRow} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const MachineDataTableRow = ({machine, selectEditMachine, deleteMachine}) => {

  return (
    <TableRow sx={{  "&:hover": {backgroundColor: '#bdbdbd'}}}>
      <TableCell>{machine.name}</TableCell>
      <TableCell>{machine.number}</TableCell>
      <TableCell sx={{display: 'flex'}}>
        <IconButton onClick={() => selectEditMachine(machine)}>
          <EditOutlinedIcon size={'small'}/>
        </IconButton>
        <IconButton onClick={() => deleteMachine(machine.id)}>
          <DeleteOutlineOutlinedIcon size={'small'}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default MachineDataTableRow;