import L from 'leaflet';
import './layout.css';
import './range.css';
import {setSettingWasChanged} from "../../userSettings";

let mapWasDragEnabled;
let mapWasTapEnabled
let controlPosition = 0.75;

export function getBlindControlPosition() {
  return controlPosition;
}

export function setBlindControlPosition(position) {
  controlPosition = position;
}

function getRangeEvent (rangeInput) {
  return 'oninput' in rangeInput ? 'input' : 'change'
}

function cancelMapDrag () {
  mapWasDragEnabled = this._map.dragging.enabled()
  mapWasTapEnabled = this._map.tap && this._map.tap.enabled()
  this._map.dragging.disable()
  this._map.tap && this._map.tap.disable()
}

function uncancelMapDrag (e) {
  this._refocusOnMap(e)
  if (mapWasDragEnabled) {
    this._map.dragging.enable()
  }
  if (mapWasTapEnabled) {
    this._map.tap.enable()
  }
  setSettingWasChanged()
}

function noop () {}

L.Control.SideBySide = L.Control.extend({
  options: {
    thumbSize: 30,
    padding: 0,
  },

  _indicator: null,
  _indicatorTimeout: null,

  "initialize": function (leftPanes, rightPanes, options) {
    this.leftPanes = leftPanes;
    this.rightPanes =rightPanes;
    L.setOptions(this, options)
    if (options.controlPosition)
      controlPosition = options.controlPosition;
  },

  getPosition: function () {
    let rangeValue = this._range.value
    let offset = (0.5 - rangeValue) * (2 * this.options.padding + this.options.thumbSize)
    return this._map.getSize().x * rangeValue + offset
  },

  setPosition: noop,

  includes: L.Evented.prototype || L.Mixin.Events,

  addTo: function (map) {
    this.remove()
    this._map = map;
    let container = L.DomUtil.create('div', 'leaflet-sbs', map._controlContainer)
    this._container = container;
    this._divider = L.DomUtil.create('div', 'leaflet-sbs-divider', container)
    let range =  L.DomUtil.create('input', 'leaflet-sbs-range', container)
    this._range = range;
    range.type = 'range';
    range.min = 0;
    range.max = 1;
    range.step = 'any';
    range.value = controlPosition;
    range.style.paddingLeft = this.options.padding + 'px';
    range.style.paddingRight = range.style.paddingLeft;
    this.addIndicator()
    this._addEvents()
    this._updateClip()
    return this;
  },

  /**
   * Удаляет индикатор и останавливает таймер
   */
  _removeIndicator() {
    this._stopIndicatorTimeout()
    if (this._indicator) {
      L.DomUtil.remove(this._indicator)
      this._indicator = null;
    }
  },

  /**
   * Добавляет индикатор и запускает таймер
   */
  addIndicator() {
    this._removeIndicator()
    const container = L.DomUtil.create('div', 'leaflet-sbs', this._map._controlContainer)
    this._indicator = L.DomUtil.create('div', 'leflet-sbs-indicator-red', container)
    this._updateClip()
    this._startIndicatorTimeout()
  },

  _startIndicatorTimeout() {
    this._indicatorTimeout = setTimeout(() => {
      this._removeIndicator()
    }, 3000)
  },

  _stopIndicatorTimeout() {
    if (this._indicatorTimeout) {
      clearTimeout(this._indicatorTimeout)
    }
  },

  remove: function () {
    if (!this._map) {
      return this;
    }
    this._removeIndicator()
    this.leftPanes.map(pane => {
      document.getElementsByClassName(pane)[0].style.clip = '';
    })
    this.rightPanes.map(pane => {
      document.getElementsByClassName(pane)[0].style.clip = '';
    })
    this._removeEvents()
    L.DomUtil.remove(this._container)
    this._map = null;
    return this;
  },

  _updateClip: function () {
    let map = this._map;
    let nw = map.containerPointToLayerPoint([0, 0])
    let se = map.containerPointToLayerPoint(map.getSize())
    let clipX = nw.x + this.getPosition()
    let dividerX = this.getPosition()

    this._divider.style.left = dividerX + 'px';
    const left = window.store.getState().blindReducer.left;

    if (this._indicator)
      if (left) {
        this._indicator.style.left = 0 + 'px';
        this._indicator.style.width = (dividerX - 2) + 'px';
      } else {
        this._indicator.style.left = (dividerX + 2) + 'px';
        this._indicator.style.width = (this._container.clientWidth - dividerX -2) + 'px';
      }
    this.fire('dividermove', {x: dividerX})
    let clipLeft = 'rect(' + [nw.y, clipX, se.y, nw.x].join('px,') + 'px)';
    let clipRight = 'rect(' + [nw.y, se.x, se.y, clipX].join('px,') + 'px)';

    controlPosition = this._range.value;
    this.rightPanes.map(pane => {
      if (document.getElementsByClassName(pane)[0])
        document.getElementsByClassName(pane)[0].style.clip = clipRight;
    })

    this.leftPanes.map(pane => {
      if (document.getElementsByClassName(pane)[0]) {
        document.getElementsByClassName(pane)[0].style.clip = clipLeft;
      }
    })
  },

  _stopPropagation: function (e) {
    L.DomEvent.stopPropagation(e);
  },

  _addEvents: function () {
    let range = this._range;
    let map = this._map;
    if (!map || !range) return;
    map.on("move", this._updateClip, this);
    L.DomEvent.on(range, "click", this._stopPropagation , this)
    L.DomEvent.on(range, getRangeEvent(range), this._updateClip, this);
    L.DomEvent.on(range, "touchstart", cancelMapDrag, this);
    L.DomEvent.on(range, "touchend", uncancelMapDrag, this);
    L.DomEvent.on(range, "mousedown", cancelMapDrag, this);
    L.DomEvent.on(range, "mouseup", uncancelMapDrag, this);
  },

  _removeEvents: function () {
    let range = this._range;
    let map = this._map;
    if (range) {
      L.DomEvent.off(range, "click", this._stopPropagation, this)
      L.DomEvent.off(range, getRangeEvent(range), this._updateClip, this);
      L.DomEvent.off(range, "touchstart", cancelMapDrag, this);
      L.DomEvent.off(range, "touchend", uncancelMapDrag, this);
      L.DomEvent.off(range, "mousedown", cancelMapDrag, this);
      L.DomEvent.off(range, "mouseup", uncancelMapDrag, this);
    }
    if (map) {
      map.off("move", this._updateClip, this);
    }
  },
})

L.control.sideBySide = function (leftPanes, rightPanes, options) {
  return new L.Control.SideBySide(leftPanes, rightPanes, options)
}
