import {SET_TARGET_VIOLATION, SET_VIOLATION_DATA} from "./actionTypes";

/**
 * @param {object} violations - список нарушений.
 * @param {object} targetViolation - отдельное нарушение, на которое нажал пользователь.
 */
const defaultState = {
  violations: [],
  targetViolation: null,
  loading: false,
  loadingError: false,
  group: null,
}

export const violationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_TARGET_VIOLATION:
      return { ...state, targetViolation: action.violation }
    case SET_VIOLATION_DATA:
      return {...state, ...action.data}

    default:
      return state;
  }
}
