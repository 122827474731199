import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setPlotDataAttr, uploadGpxFileThunk} from "../../../../redux/reducers/plotDataReducer";
import ModalPage from "../../../Common/ModalPage";
import UploadGpxForm from "./UploadGPXForm/UploadGpxForm";
import SelectObjectType from "./SelectObjectType/SelectObjectType";
import Grid from "@mui/material/Grid";
import {checkFileType} from "../../../../utils/checkFileType";
import {setSnack} from "../../Map/Common/Dialog/Snack/snackReducer";

const UploadGpxModal = () => {
  const selectedCollection = useSelector(state => state.plotDataReducer.selectedCollection)
  const [gpxFile, setGpxFile] = useState(null)
  const [figureType, setFigureType] = useState('')
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setPlotDataAttr({showUploadGpxModal: false}))
  }
  const actionButtonHandler = () => {
    if (checkFileType(gpxFile?.name, 'gpx')) {
      dispatch(uploadGpxFileThunk(gpxFile, figureType, selectedCollection))
    } else {
     dispatch(setSnack('error', 'Неверный тип файла'))
    }
  }

  return (
    <ModalPage
      title={`Загрузить Gpx координаты на карту`}
      handleClose={handleClose}
      actionButtonHandler={actionButtonHandler}
      actionButtonDisabled={!figureType || !gpxFile && true}
      actionButtonName={'Отправить'}
    >
      <Grid container spacing={2} sx={{pt: 2, pb: 2}}>
        <Grid xs={12} item>
          <UploadGpxForm gpxFile={gpxFile} setGpxFile={setGpxFile}/>
        </Grid>
        <Grid xs={12} item>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <SelectObjectType figureType={figureType} setFigureType={setFigureType}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPage>
  )
}

export default UploadGpxModal;
