import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";
import {compileShortName} from "../../../../Main/Map/Common/MiscFunction";
import {dispatch} from "../../../../Common/misc_functions";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";

export function EmployeesDialogTab({workspace}) {
  const selectedItems = useSelector(state => state.organizationPageReducer.selectedItems)
  const organizationUsers = useSelector(state => state.organizationPageReducer.employeesData) //все узеры
  const saving = useSelector((state) => state.organizationPageReducer.dialogSaving)
  const iAMOwner = !cannotIDo.ownerAction()
  const dialogChanged = useSelector(state => state.organizationPageReducer.changesInDialog)
  const myID = getTariff().userID;
  const [allChecked, setAllChecked] = useState(null)

  useEffect(() => {
    if (!selectedItems && !saving) {
      dispatch(changeOrgPageAttrs({selectedItems: [...workspace.users]}))
    }
    if (selectedItems)
      setAllChecked(getAllChecked())
  }, [workspace.users, selectedItems])

  if (!selectedItems || allChecked === null)
    return null;

  function selectAllHandler() {
    let arr;
    if (!allChecked) { //add all
      arr = organizationUsers.filter(user => {
        if (iAMOwner || !user.is_admin) {
          return true;
        } else {
          return user.id === myID || selectedItems.find(u => u.id === user.id);
        }
      })
    } else { //remove all
      arr = selectedItems.filter(user => !iAMOwner && user.is_admin && user.id !== myID)
    }
    dispatch(changeOrgPageAttrs({selectedItems: arr}))
    setAllChecked(!allChecked)
    if (!dialogChanged) {
      dispatch(changeOrgPageAttrs({changesInDialog: 0}))
    }
  }

  function getAllChecked() {
    return organizationUsers.every(orgUser => {
      if (iAMOwner || !orgUser.is_admin || orgUser.id === myID) {
        return selectedItems.find(u => u.id === orgUser.id)
      } else {
        return true;
      }
    })
  }

  /**
   * Изменение прав узера
   * @param user - объект узера из organizationUsers
   */
  function changeState(user) {
    const wUInd = selectedItems.findIndex(u => u.id === user.id)
    if (wUInd !== -1) {
      selectedItems.splice(wUInd, 1)
    } else {
      selectedItems.push(user)
    }
    dispatch(changeOrgPageAttrs({selectedItems: [...selectedItems], changesInDialog: 0}))
    setAllChecked(getAllChecked())
  }

  let ind = 0;
  return (
    <Table style={{padding: 0}}>
      <TableHead>
        <TableRow>
          <TableCell width={'40%'}>
            <label>
              Сотрудник
            </label>
          </TableCell>
          <TableCell>
            <label>
              <Checkbox
                checked={allChecked}
                onChange={selectAllHandler}
              />
              Доступ
            </label>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {organizationUsers.map(user =>
          <TableRow key={ind++} className="hoverTableRow">
            <TableCell>
              {compileShortName(user.first_name, user.last_name, user.patronymic)}
              <span style={{fontSize: '90%', color: 'grey', textTransform: 'lowercase'}}>, {user.post}</span>
            </TableCell>
            <TableCell>
              <Checkbox
                disabled={saving || (!iAMOwner && user.is_admin && user.id !== myID)}
                checked={selectedItems.findIndex(u => user.id === u.id) !== -1}
                onChange={() => changeState(user)}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
