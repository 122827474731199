import {Alert, Box, Button, Divider, Typography} from "@mui/material";
import {repeatString} from "../Main/Map/Common/Strings_RU";

export function ErrorAlert({text, onClick, style = {}, divider = false}) {

  return (
    <Box sx={{...style, marginBottom: '2vh'}}>
      <Alert severity={'error'}>
        <Typography variant={'body2'}>{text}</Typography>
      </Alert>
      <Button variant={'outlined'} onClick={onClick} size={'small'} style={{marginTop: '1vh'}}>
        {repeatString}
      </Button>
      {divider && <Divider sx={{marginTop: '1vh'}}/>}
    </Box>
  )
}
