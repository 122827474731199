import React, {useState, useEffect} from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {setPhotoAttr} from "../../../../../../redux/reducers/stockpilePhotoReducer";

const SelectedRulerTable = () => {
  const dispatch = useDispatch()
  const ruler_length = useSelector(state => state.stockpilePhotoReducer.ruler_length)

  const onChangeRulerLengthHandler = (value) => {
    if (+value <= 50) {dispatch(setPhotoAttr({ruler_length: +value}))}
  }

  return (
    <Table size={"small"} sx={{p: 0, [`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
      <TableBody>
        <TableRow sx={{height: '40px'}}>
          <TableCell style={{ width: '50%'}}>
            Длина (м)
          </TableCell>
          <TableCell style={{ width: '50%'}}>
            <TextField
              fullWidth
              type={'number'}
              value={ruler_length || ''}
              inputProps={{style: {padding: 0}, max: 50}}
              variant={'standard'}
              size={'small'}
              onChange={(e) => onChangeRulerLengthHandler(e.target.value)}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default SelectedRulerTable;