
const defaultState = {
  open: false,
  type: null,
  header: null,
  message: null,
  callback: null,
  buttons: null,
}

export function showMapDialog(dia_type = 'info', header = '', message ='', buttons, callback = null) {
  return {
    type: 'MAP_DIALOG_SHOW',
    dia_type: dia_type,
    header: header,
    message: message,
    callback: callback,
    buttons: buttons,
  }
}

export function hideMapDialog() {
  return {
    type: 'MAP_DIALOG_HIDE',
  }
}

export function dialogReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_DIALOG_SHOW':
      return {...state, open: true, type: action.dia_type, header: action.header, message: action.message,
        callback: action.callback, buttons: action.buttons}
    case 'MAP_DIALOG_HIDE':
      return {...state, open: false, header: null, message: null, callback: null}
    default:
      return state;
  }
}
