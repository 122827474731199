
const defaultState = {
  show: false,
  currentLayer: null,
  mode: 'add',
  type: 'WMS',
  name: '',
  url: '',
  params: {zoomMin: 0, zoomMax: 20, enableGetFeatureInfo: false, featuresInfoMap: []},
  moreVisible: false,
  errors: [],
}

export function showAddUserLayerDialog() { // показать диалог узерских слоев
  return {
    type: 'MAP_USER_MISC_LAYERS_SHOW_ADD_DIALOG',
  }
}

export function hideAddUserLayerDialog() { // скруть идалог узерских слоев
  return {
    type: 'MAP_USER_MISC_LAYERS_HIDE_ADD_DIALOG',
  }
}

export function setUserLayerName(name) { // установить имя
  return {
    type: 'MAP_USER_MISC_LAYERS_SET_NAME',
    name: name,
  }
}

export function setUserLayerType(layerType) { // установить тип
  return {
    type: 'MAP_USER_MISC_LAYERS_SET_TYPE',
    layerType: layerType,
  }
}

export function setUserLayerURL(url) { // установить url
  return {
    type: 'MAP_USER_MISC_LAYERS_SET_URL',
    url: url,
  }
}

export function setUserLayersEditLayer(layer) { // открыть диалог со слоем
  return{
    type: 'MAP_USER_MISC_LAYERS_SET_CURRENT_EDIT_LAYER',
    layer: layer,
  }
}

export function showHideUserLayersMoreParameters() { // свернуть/развернуть доп. параметры
  return {
    type: 'MAP_USER_LAYERS_SHOW_HIDE_MORE',
  }
}

export function userLayersSetParams(params) { // установка параметров слоя (params)
  return {
    type: 'MAP_USER_LAYERS_SET_PARAMS',
    params: params,
  }
}

export function userLayersReducer(state = defaultState, action) { // редусер окна узерских слоёв
  switch (action.type) {
    case 'MAP_USER_MISC_LAYERS_SHOW_ADD_DIALOG':
      return {...state, currentLayer: null, mode: 'add', show: true}
    case 'MAP_USER_MISC_LAYERS_HIDE_ADD_DIALOG':
      return {...defaultState}
    case 'MAP_USER_MISC_LAYERS_SET_NAME':
      return {...state, name: action.name}
    case 'MAP_USER_MISC_LAYERS_SET_TYPE':
      return {...state, type: action.layerType}
    case 'MAP_USER_MISC_LAYERS_SET_URL':
      return {...state, url: action.url}
    case 'MAP_USER_MISC_LAYERS_SET_ERRORS':
      return {...state, errors: action.errors}
    case 'MAP_USER_MISC_LAYERS_SET_CURRENT_EDIT_LAYER':
      return {...defaultState, show: true, mode: 'edit', currentLayer: action.layer, name: action.layer.name, url: action.layer.url, type: action.layer.type, params: action.layer.params}
    case 'MAP_REFRESH_USER_LAYERS':
      return {...state}
    case 'MAP_USER_LAYERS_SHOW_HIDE_MORE':
      return {...state, moreVisible: !state.moreVisible}
    case 'MAP_USER_LAYERS_SET_PARAMS':
      return {...state, params: {...action.params}}
    default:
      return state;
  }
}
