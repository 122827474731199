import {forwardRef, useEffect, useRef, useState} from 'react';
import {Divider, IconButton, Modal, Stack, TextField, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {closeCoordinatesEditor} from "./coordinatesEditorReducer";
import {
  coordinatesEditorString,
  coordinatesFitToBoundString,
  coordinatesIdenticalPointsDetectedString,
  coordinatesLessThen1String,
  coordinatesLessThen2String, coordinatesLessThen3String,
  errorInDataString, fileProcessingErrorString,
  latitudeString,
  longitudeString, replaceAllCoordinatesWarningString,
  saveString,
} from "../../Strings_RU";
import {checkForFloat} from "../../MiscFunction";
import {setSnack} from "../Snack/snackReducer";
import ModalPage from "../../../../../Common/ModalPage";
import {modalWindowStyle} from "../../../../../Common/Styles";
import L from 'leaflet';
import Typography from "@mui/material/Typography";
import {LoadFileButton} from "./LoadFileButton";
import {detectIdenticalLatLngs} from "../../geoFunctions";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import {treeItemButtonStyle} from "../../Styles";
import {dataAPI} from "../../../../../../api/api";
import {djangoErrorsParse} from "../../../../../../utils/djangoErrorsParse";
import {ViewportList} from "react-viewport-list";
import {getMap1} from "../../../GlobalObjects";
import {DownloadCoordinatesButton} from "./DownloadCoordinatesButton";
import {saveLatLngsAsGpxFile} from "../../Exports/gpx";
import {formatDateFromObjToStr} from "../../DateTimeFunctions";
import {saveLatLngsAsGeoJsonFile} from "../../Exports/geojson";
import {cannotIDo} from "../../tariffs";
import {dispatch} from "../../../../../Common/misc_functions";

const mapPreviewDivID = 'map_preview_div_id';
let previewMap = null;
let latlngs = null; //все поля

const empty = {lat: '', lng: '', errorLat: false, errorLng: false}
const markerStyle = {
  color: 'black',
  weight: 1,
  fillColor: 'white',
  fillOpacity: 1,
  radius: 4,
  bubblingMouseEvents: false,
  interactive: false,
}
const tooltipStyle = {
  color: 'black',
  opacity: 1,
  permanent: true,
  className: 'preview_tooltip_container',
  interactive: false,
}
const lineStyle = {
  weight: 3,
  interactive: false,
}
const panelStyle = {
  marginTop: '0.1vh',
  marginBottom: '0.5vh',
}
const MAX_ITEMS = 100;
let focusPocus = {index: -1, lat: true}

function createPreviewShapes(state) {
  function createMarkers(arr, group) { //TODO очень сильно тормозит при большом количестве точек
    let ind = 0;
    arr.map(ll => {
      const marker = L.circleMarker(ll, markerStyle)
      marker.elz_index = ind;
      marker.bindTooltip(`${ll.number}`, tooltipStyle)
      //marker.on('click', markerClick)
      group.addLayer(marker)
      ind++;
    })
  }
  const arr = []

  latlngs.map(ll => {
    if (checkForFloat(ll.lat) && checkForFloat(ll.lng))
      arr.push({lat: parseFloat(ll.lat), lng: parseFloat(ll.lng), number: ll.number})
  })

  let group = previewMap.elz_group;
  if (group)
    previewMap.removeLayer(group)
  group = L.featureGroup()
  previewMap.elz_group = group;
  previewMap.addLayer(group)

  if (!arr.length)
    return;

  let layer = null;
  switch (state.type) {
    case 'Line':
      if (arr.length > 1) {
        layer = L.polyline(arr, lineStyle)
        layer.elz_fromcoordinates = true;
        group.addLayer(layer)
      }
      break;
    case 'Polygon':
      if (arr.length > 1) {
        layer = L.polygon(arr, lineStyle)
        group.addLayer(layer)
      }
    break;
  }
  if (latlngs.length < MAX_ITEMS) //TODO очень сильно тормозит при большом количестве точек. Может кластер?
    createMarkers(arr, group)
  previewMap.fitBounds(group.getBounds(), {animate: false})
}

function renumberLatLngs() {
  let ind = 1;
  latlngs.forEach(ll => {
    ll.number = ind;
    ind++;
    return ll;
  })
}

function LatLngFieldsRow(props) {
  const curItem = props.item;
  const index = curItem.number - 1;
  const [refr, refreshSelf] = useState(false)
  const last = index === latlngs.length-1;

  function deleteItem() {
    if (index !== latlngs.length - 1)
      latlngs.splice(index, 1)
    else
      latlngs[index] = {...empty, number: latlngs.length}
    if (!latlngs.length)
      latlngs.push({...empty, number: latlngs.length})
    renumberLatLngs()
    props.refresh()
    createPreviewShapes(props.state)
  }

  function addEmptyIfNeeded(errorLat, errorLng, goNext) {
    if (!errorLat && !errorLng) {
      if (props.state.type !== 'Marker' && last) {
        latlngs.push({...empty, number: latlngs.length+1})
        if (goNext)
          focusPocus = {index: latlngs.length-1, lat: true}
        props.refresh()
        return true;
      }
    }
    return false;
  }

  function latBlurHandle(goNext = false) {
    const errorLng = Boolean(!checkForFloat(curItem.lng))
    const errorLat = Boolean(!checkForFloat(curItem.lat))
    if (!goNext)
      focusPocus = {index: -1}
    else
      if (!errorLat)
        focusPocus = {index: index, lat: false}

    if (curItem.lat !== curItem.old_lat) {
      if (last && curItem.lat.length === 0)
        curItem.errorLat = false;
      else
        curItem.errorLat = errorLat;
      createPreviewShapes(props.state)
      if (addEmptyIfNeeded(errorLat, errorLng, goNext))
        return;
    }
    refreshSelf(!refr)
  }

  function lngBlurHandle(goNext = false) {
    const errorLng = Boolean(!checkForFloat(curItem.lng))
    const errorLat = Boolean(!checkForFloat(curItem.lat))
    if (!goNext)
      focusPocus = {index: -1}
    else
      if (!errorLng) {
        focusPocus = {index: index+1, lat: true}
        props.refresh()
      }

    if (curItem.lng !== curItem.old_lng) {
      if (last && curItem.lng.length === 0)
        curItem.errorLng = false;
      else
        curItem.errorLng = errorLng;
      createPreviewShapes(props.state)
      if (addEmptyIfNeeded(errorLat, errorLng, goNext))
        return;
    }
    refreshSelf(!refr)
  }

  return (
    <Stack direction={'row'} spacing={1} key={index+1} style={{paddingTop: '1vh', flex: 1}} alignItems={'center'}>
      <Typography> {curItem.number}.</Typography>
      <TextField
        value={curItem.lat}
        size={'small'}
        variant={'outlined'}
        error={curItem.errorLat}
        label={latitudeString}
        inputRef={(input) => (index === focusPocus.index && focusPocus.lat) && input?.focus()}
        onFocus={() => {
          curItem.old_lat = curItem.lat;
        }}
        onChange={(e) => {
          curItem.lat = e.target.value.trim();
          refreshSelf(!refr)
        }}
        onBlur={() => latBlurHandle()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || (!e.shiftKey && e.key === 'Tab')) {
            e.stopPropagation()
            e.preventDefault()
            latBlurHandle(true)
          }
        }}
      />
      <TextField
        value={curItem.lng}
        size={'small'}
        variant={'outlined'}
        error={curItem.errorLng}
        label={longitudeString}
        inputRef={(input) => (index === focusPocus.index && !focusPocus.lat) && input?.focus()}
        onFocus={() => {
          curItem.old_lng = curItem.lng;
        }}
        onChange={(e) => {
          curItem.lng = e.target.value.trim();
          refreshSelf(!refr)
        }}
        onBlur={() => lngBlurHandle()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || (!e.shiftKey && e.key === 'Tab')) {
            e.stopPropagation()
            e.preventDefault()
            lngBlurHandle(true)
          }
        }}
      />
      <IconButton style={{margin: 0}} tabIndex={-1} onClick={deleteItem} color={'warning'}>
        <DeleteOutlineOutlinedIcon fontSize={'small'}/>
      </IconButton>
    </Stack>
  )
}

/**
 * Очищает всю каку и закрывает диалог.
 */
export function clearCoordinatesDialogInfo() {
  if (previewMap) {
    previewMap.eachLayer(layer => {
      layer.off('click')
      layer.off()
      previewMap.removeLayer(layer)
    })
    delete previewMap.elz_group;
    previewMap.off()
    previewMap.remove()
    previewMap = null;
  }
  latlngs = null;
  focusPocus = {index: -1, lat: true}
  dispatch(closeCoordinatesEditor())
}

function Window(props) {
  const dispatch = useDispatch()
  const state = props.state;
  const [refresh, setRefresh] = useState(false)
  const theme = useTheme()
  const ref = useRef(null)
  const iAmReadOnly = cannotIDo.editorAction()

  let nameSuffix = '';
  if (state.name)
    nameSuffix = ` (${state.name})`;

  useEffect(() => {
    if (!previewMap) {
      previewMap = L.map(mapPreviewDivID, {
        center: getMap1().getCenter(),
        zoom: getMap1().getZoom(),
        zoomControl: false,
        zoomSnap: 0.25,
        zoomDelta: 0.25,
        wheelPxPerZoomLevel: 120,
      })
      L.tileLayer('').addTo(previewMap) //FIXME пустой слой для непонятного бага (пустая картa в некоторых случаях)
      createPreviewShapes(state)
    }
  })

  function saveCooordinates() {
    let error = false;
    let ind = 0;
    let count = latlngs.length - 1;
    const arr = []
    if (state.type === 'Marker')
      count = 1;
    while (ind < count) {
      const ll = latlngs[ind]
      if (isNaN(checkForFloat(ll.lat)) || isNaN(checkForFloat(ll.lng)))
        error = true;
      else
        arr.push(L.latLng(parseFloat(ll.lat), parseFloat(ll.lng)))
      ind++;
    }
    if (error) {
      dispatch(setSnack('error', errorInDataString))
      return;
    }

    switch (state.type) {
      case 'Marker':
        if (arr.length !== 1) {
          dispatch(setSnack('error', coordinatesLessThen1String))
          return
        }
        break;
      case 'Line':
        if (arr.length < 2) {
          dispatch(setSnack('error', coordinatesLessThen2String))
          return
        }
        break;
      case 'Polygon':
        if (arr.length < 3) {
          dispatch(setSnack('error', coordinatesLessThen3String))
          return
        }
        break;
    }

    const iden = detectIdenticalLatLngs(latlngs)
    if (iden.length > 0) {
      dispatch(setSnack('error', coordinatesIdenticalPointsDetectedString.replace('___1___', iden[0] + 1).replace('___2___', iden[1] + 1)))
      return;
    }
    //TODO походу не работает !!!
    /*if (latlngs.length > 2)
      if (!checkLatLngsArraySelfIntersections(arr)) {
        dispatch(setSnack('error', selfIntersectionWarningString))
        return;
      }*/
    state.callback(arr)
  }

  function gpxLoaded(data) {
    dataAPI.miscGis.testGPXFile(data).then(res => {
      if (state.type !== 'Marker') {
        latlngs = res.data.map(coo => {
          return {lat: coo[1], lng: coo[0]}
        })
        latlngs.push({...empty, number: latlngs.length+1})
        renumberLatLngs()
      } else {
        latlngs = [{lat: res.data[0][1], lng: res.data[0][0], number: 1}]
      }
      createPreviewShapes(state)
      setRefresh(!refresh)
    }).catch(err => {
      console.error(err)
      dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : fileProcessingErrorString))
    })
  }

  function saveCoordinatesToFile(type) {
    const name = state.name || `Координаты от ${formatDateFromObjToStr(new Date())}`;
    const coords = structuredClone(latlngs)
    if (state.type !== 'Marker') { //удаляем последнюю (пустую) строчку, если это не маркер
      coords.splice(latlngs.length - 1, 1)
    }
    if (type === 0) {
      saveLatLngsAsGpxFile(coords, state.type, name)
    } else {
      saveLatLngsAsGeoJsonFile(coords, state.type, name)
    }
  }

  let ind = 0;
  let confirm = latlngs.length > 1 && checkForFloat(latlngs[0].lat) && checkForFloat(latlngs[0].lng)

  return (
    <ModalPage
      title={`${coordinatesEditorString} ${nameSuffix}`}
      handleClose={clearCoordinatesDialogInfo}
      actionButtonName={saveString}
      actionButtonHandler={saveCooordinates}
      actionButtonRemove={iAmReadOnly}
      customStyle={{...modalWindowStyle, minWidth: 'max-content'}}
    >
      <Stack direction={'row'} style={{position: 'relative', margin: '1vh', height: '60vh'}}>
        <Stack direction={'column'}>
          <Stack direction={'row'} style={panelStyle}>
            <IconButton
              style={{...treeItemButtonStyle, marginTop: 0}}
              title={coordinatesFitToBoundString}
              onClick={() => {
                if (previewMap.elz_group)
                  previewMap.fitBounds(previewMap.elz_group.getBounds())
              }}
            >
              <FullscreenOutlinedIcon fontSize={'small'} style={{color: theme.palette.primary.main}}/>
            </IconButton>
          </Stack>
          <Divider orientation={'horizontal'}/>
          <div id={mapPreviewDivID} style={{minWidth: '256px', border: 0}}/>
        </Stack>
        <Divider orientation={'vertical'}/>
        <Stack direction={'column'} style={{paddingLeft: '1vh'}}>
          <Stack direction={'row'} style={panelStyle} spacing={1}>
            {!iAmReadOnly &&
              <LoadFileButton callback={gpxLoaded} confirmDialog={confirm}
                            confirmString={replaceAllCoordinatesWarningString}/>
            }
            <DownloadCoordinatesButton callback={saveCoordinatesToFile} />
          </Stack>
          <Divider style={{marginLeft: '-1vh'}}/>
          <div style={{overflowY: 'auto', overflowAnchor: 'none', flex: 1, paddingBottom: '5px'}} ref={ref}>
            <ViewportList items={latlngs}>
            {(item) => {
              return <LatLngFieldsRow item={item} key={ind++} state={state} refresh={() => setRefresh(!refresh)}/>
            }}
            </ViewportList>
          </div>
        </Stack>
      </Stack>
    </ModalPage>
  )
}

export function CoordinatesEditor() {
  const state = useSelector(state => state.coordinatesEditorReducer)
  const ModalWindow = forwardRef(() => <Window state={state}/>)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (state.latlngs && !latlngs) {
      if (state.latlngs.length === 0) {
        latlngs = [{...empty, number: 1}]
      } else {
        latlngs = state.latlngs.map(ll => {
          return {lat: ll.lat.toString(), lng: ll.lng.toString()}
        })
        if (state.type !== 'Marker')
          latlngs.push({...empty})
        renumberLatLngs()
      }
      setRefresh(!refresh)
    }
  })

  if (!state.latlngs || !latlngs)
    return null;

  return (
    <Modal open={true}>
      <ModalWindow/>
    </Modal>
  )
}
