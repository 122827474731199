import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FirstTabAccessLevel from "./FirstTabAccessLevel";
import SecondTabAccessPlots from "./SecondTabAccessPlots";
import ThirdTabAccessRoads from "./ThirdTabAccessRoads";
import {useDispatch, useSelector} from "react-redux";
import {CustomTabPanel} from "../../../../../../CustomTabPanel";
import {FourthTabAccessModules} from "./FourthTabAccessModules";
import {cannotIDo} from "../../../../../../../Main/Map/Common/tariffs";
import {FiveTabUserPassword} from "./FiveTabUserPassword";
import {changeOrgPageAttrs} from "../../../../../../organizationPageReducer";
import {treeButtonDialog} from "../../../../../../org_page_api";
import {saveActionSelectText, saveString3} from "../../../../../../../Main/Map/Common/Strings_RU";
import {setSnack} from "../../../../../../../Main/Map/Common/Dialog/Snack/snackReducer";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs({user, handleSave}) {
  const dispatch = useDispatch()
  const curTab = useSelector(state => state.organizationPageReducer.curTab)

  const handleChange = (event, newValue) => {

    const changes = window.store.getState().organizationPageReducer.changesInDialog !== null;
    const saving = window.store.getState().organizationPageReducer.dialogSaving;
    if (saving) {
      dispatch(setSnack('warning', 'Пожалуйста, дождитесь сохранения на текущей вкладке.'))
      return;
    }
    if (changes) {
      treeButtonDialog(saveString3, saveActionSelectText, (value) => {
        if (value === 'Сохранить') {
          handleSave()
        } else {
          if (value === 'Не сохранять') {
            let old;
            switch (curTab) {
              case 0:
                dispatch(changeOrgPageAttrs({curTab: newValue, allWorkspaces: null, changesInDialog: null}))
                break;
              case 1:
                old = window.store.getState().organizationPageReducer.oldCas;
                dispatch(changeOrgPageAttrs({
                  curTab: newValue,
                  changesInDialog: null,
                  allCas: structuredClone(old),
                }))
                break;
              case 2:
                old = window.store.getState().organizationPageReducer.oldRoads;
                dispatch(changeOrgPageAttrs({
                  curTab: newValue,
                  changesInDialog: null,
                  allRoads: structuredClone(old),
                }))
                break;
              case 3:
                old = window.store.getState().organizationPageReducer.oldModules;
                dispatch(changeOrgPageAttrs({
                  curTab: newValue,
                  changesInDialog: null,
                  allModules: structuredClone(old),
                }))
                break;
            }
          }
        }
      })
    } else {
      dispatch(changeOrgPageAttrs({curTab: newValue}))
    }
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={curTab} onChange={handleChange}>
          <Tab label="Доступ к договорам" {...a11yProps(0)} />
          <Tab label="Доступ к делянам" {...a11yProps(1)} />
          <Tab label="Доступ к дорогам" {...a11yProps(2)} />
          <Tab label="Доступ к модулям" {...a11yProps(3)}
               disabled={!user.is_editor || !!cannotIDo.lightTariffActions()}/>
          <Tab label="Установка пароля" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={curTab} index={0}>
        <FirstTabAccessLevel user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={curTab} index={1}>
        <SecondTabAccessPlots user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={curTab} index={2}>
        <ThirdTabAccessRoads user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={curTab} index={3}>
        <FourthTabAccessModules user={user}/>
      </CustomTabPanel>
      <CustomTabPanel value={curTab} index={4}>
        <FiveTabUserPassword user={user}/>
      </CustomTabPanel>
    </Box>
  )
}
