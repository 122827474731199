import {IconButton, TableCell, TableRow} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {tableRowStyle} from "../EmployeesTable";
import {useDispatch} from "react-redux";
import {getTariff} from "../../../../Main/Map/Common/tariffs";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";

const CreateUserRow = ({numColumns}) => {
  const tariff = getTariff()
  const userIsAdminOrOwner = tariff.is_admin || tariff.is_owner
  const dispatch = useDispatch()

  const createUserModeToggler = () => {
    dispatch(changeOrgPageAttrs({createUserMode: true}))
  }

  return (
    <TableRow
      sx={{...tableRowStyle, "&:hover": {backgroundColor: 'none'}}}
    >
      <TableCell colSpan={numColumns + 2} align="left">{''}</TableCell>
      <TableCell align="left">
        {userIsAdminOrOwner &&
          <IconButton
            onClick={createUserModeToggler}>
            <AddIcon sx={{color: 'primary.dark'}}/>
          </IconButton>
        }
      </TableCell>
    </TableRow>
  )
}

export default CreateUserRow;
