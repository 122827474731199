import {Alert, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {hideSnack, setSnack} from "./snackReducer";
import {commonNetwokError} from "../../Strings_RU";

//export const defaultSnack = {open: false, message: '', severity: 'success'}

export function setNetworkErrorSnack(response, dispatch) {
  if (response?.data?.detail)
    dispatch(setSnack('error', response.data.detail))
  else
    dispatch(setSnack('error', commonNetwokError))
}

export function FSnack() {
  const state = useSelector(state => state.snackReducer)
  const dispatch = useDispatch()

  if (!state.open)
    return null;

  function onClose() {
    dispatch(hideSnack())
  }

  return (
    <Snackbar open={true} autoHideDuration={state.timeout} onClose={onClose}>
      <Alert onClose={onClose} severity={state.snackType} sx={{ width: '100%' }}>
        {state.message}
      </Alert>
    </Snackbar>
  )
}
