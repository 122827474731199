const tableHeight = '71vh'

export const filtersPopover = {
  width: '200px',
  height: '250px',
  backgroundColor: "rgb(255, 255, 255)",
  border: "1px solid lightgrey",
  borderRadius: 2,
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
  padding: '0 0',
  zIndex: 100000,
};

export const ORIcon = {
  color: 'primary.main', fontSize: 15
}

export const tabTitle = { borderBottom: 1, borderColor: 'divider', height: '8%' }

export const mainContainer = {height: '92%'}

export const tableContainer = {overflowY: 'auto', minHeight: tableHeight}

export const customTabPanel = {height: tableHeight, display: 'flex', flexDirection: 'column'}
export const customTabPanelContainer = {height: tableHeight,}

export const tableHeadCell = {display: 'flex', gap: '4px', alignItems: 'center'}

export const filtersPopoverContainer = {width: '100%', height: '80%', overflowY: 'auto'}

export const filterDateContainer = {width: '100%', display: 'flex', justifyContent: 'center', pt: 2}