import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";


export const cropOutlinesByRulerWidth = (outlines, ruler, dispatch, selectedPhoto) => {
  const selectedPhotoCopy = JSON.parse(JSON.stringify(selectedPhoto));
  let newOutlines = []
  outlines.forEach((outline, index) => {
    let selectedFigure = selectedPhotoCopy.figures[index]
    let newOutline = selectedFigure.points.map(point => {
      point.x = point.x < ruler[0].x ? ruler[0].x : point.x
      point.x = point.x > ruler[1].x ? ruler[1].x : point.x
      return point
    })
    newOutlines.push(newOutline)
  })
  dispatch(setPhotoAttr({outlines: newOutlines}))
}