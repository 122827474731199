import React  from 'react';
import {Autocomplete, Checkbox, TextField} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {setSnack} from "../../../Main/Map/Common/Dialog/Snack/snackReducer";
import {useDispatch} from "react-redux";

const isNumber = (str) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const getIntervalNumbers = (str) => {
  const parts = str.split('-')
  if (parts.length === 2 && isNumber(parts[0]) && isNumber(parts[1])) {
    return {firstNum: Number(parts[0]), secondNum: Number(parts[1])};
  }
  return {firstNum: null, secondNum: null}
};

const QuartersByDistrictForestryAutocomplete = ({quarters, setQuartersList, quartersList, selectedAltDistrictForestry,
                                                  selectedAltForestry }) => {
  const dispatch = useDispatch()
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const maxNumQuarters = 30
  const onChangeHandler = (item) => {
    if (item.length <= maxNumQuarters) {
      setQuartersList(item)
    } else {
      dispatch(setSnack('error', `На планшет можно добавить не более ${maxNumQuarters} кварталов`))
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      let value = event.target.value
      let {firstNum, secondNum} = getIntervalNumbers(value)
      if (firstNum < secondNum) {
        let quartersSlice = quarters.filter(item => item >= firstNum && item <= secondNum);
        if (quartersSlice.length <= maxNumQuarters) {
          setQuartersList(prev => ([...new Set([...prev, ...quartersSlice])]))
        } else {
          dispatch(setSnack('error', `На планшет можно добавить не более ${maxNumQuarters} кварталов`))
        }
      }
    }
  }

  return (
    <>
      <Autocomplete
        multiple
        id={'users'}
        disableClearable
        disableCloseOnSelect
        disabled={!selectedAltDistrictForestry || !selectedAltForestry}
        options={quarters || []}
        getOptionLabel={(option) => String(option.number)}
        value={quartersList}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={!!quartersList.find(quarter => quarter.id === option.id)}
            />
            {option.number}
          </li>
        )}
        renderInput={(params) => (<TextField {...params} size={'small'} label={'Кварталы'}/>)}
        onChange={(_event, item) => {onChangeHandler(item)}}
        onKeyDown={handleKeyDown}
      />
    </>
  )
}

export default QuartersByDistrictForestryAutocomplete;
