import workSpaceImage from '../introTourImages/workSpace.png'
import folderImage from '../introTourImages/folderImage.png'
import lineImage from '../introTourImages/lineImage.png'
import polygonImage from '../introTourImages/polygonImage.png'
import createPlotButtonImage from '../introTourImages/createPlotButton.png'
import createPlotModalImage from '../introTourImages/createPlotModal.png'
import plotExampleImage from '../introTourImages/plotExample.png'
import plotsFiltersImage from '../introTourImages/plotsFilters.png'
import plotsListImage from '../introTourImages/plotsList.png'
import uploadGpxImage from '../introTourImages/uploadGpx.png'
import filtersExampleImage from '../introTourImages/filtersExample.png'
import addUserToOrgImage from '../introTourImages/addUserToOrg.png'
import uploadPlanetableImage from '../introTourImages/uploadPlanetable.png'
import planetablesListImage from '../introTourImages/planetablesList.png'
import planetableSvgImage from '../introTourImages/planetableSvg.png'
import addQuartersFromMap from '../introTourImages/addQuartersFromMap.png'
import editQuarterLayerPosition from '../introTourImages/editQuarterLayerPosition.png'
import saveQuarters from '../introTourImages/saveQuarters.png'
import addQuartersModal from '../introTourImages/addQuartersModal.png'
import employeesListImage from '../introTourImages/EmployeesList.png'
import planetablesGroupedImage from '../introTourImages/planetablesGrouped.png'

export const MapIntroTourSteps = [
  {
    target: '#cp_map_container',
    content: 'Интерактивная карта Delyana - предоставляет инструменты для создания, редактирования, анализа и ' +
      'отображения геоданных',
    title: 'Карта',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#layersButton',
    content: 'Кнопка "Базовые слои" открывает меню для переключения базовых слоев карты (OSM, Google, ArcGIS, Sentinel)',
    title: 'Базовые слои',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '.leftRightToggler',
    content: 'Кнопка "Режим сравнения слоев" включает "шторку" и  позволяет выбрать слои для левой ' +
      'и правой части карты',
    title: 'Режим сравнения слоев',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#measuringButton',
    content: 'Кнопка "Измерения" открывает меню с инструментами для измерения площади и расстояния',
    title: 'Измерения',
    placement: 'left',
    disableBeacon: true
  },
  {
    target: '#zoomControl',
    content: 'Кнопки "+" и "-" изменяют масштаб карты',
    title: 'Изменение масштаба',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#GPSButton',
    content: 'Кнопка "Мое местоположение" перемещает фокус на карте на ваше местоположение',
    title: 'Мое местоположение',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#projects',
    content: 'Раздел "Проекты" содержит в себе функционал для создания на карте пользовательских объектов ' +
      'также загрузки маршрутов в формате gpx',
    title: 'Проекты',
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#forestLayers',
    content: 'Раздел "Слои" позволяет показывать на карте или скрывать слои содержащие данные для лесопользования, ' +
      'такие как Лесничества, Участковые Лесничества, Квартальную сеть, Договоры аренды',
    title: "Слои лесопользования",
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#plots',
    content: 'Раздел "Деляны" содержит информацию о созданных ранее на карте делянах и дорогах. Содержит функционал ' +
      'редактирования и удаления созданных ранее делян и дорог',
    title: 'Деляны',
    placement: 'right',
    disableBeacon: true
  },
  // {
  //   target: '#plotFilters',
  //   content: 'Раздел "Фильтры" содержит функционал для фильтрации ранее созданных делян по различным категориям, ' +
  //     'таким как Рабочая область, Регион, Лесничество, Участковое лесничество, Статус',
  //   title: 'Фильтры',
  //   placement: 'right',
  //   disableBeacon: true
  // },
  {
    target: '#print',
    content: 'Раздел "Печать" содержит функционал для вывода на печать выбранных участков карты',
    title: 'Печать',
    placement: 'right',
    disableBeacon: true
  },
]

export const projectsIntroTourSteps = [
  {
    target: '#projectsBlock',
    content: 'Данный раздел содержит каталог с объектами созданными на карте (полигонами, линиями, метками). ' +
      'А также панель с кнопками для создания, редактирования и удаления этих объектов и загрузки маршрутов в формате gpx. ' +
      'Сохраненные полигоны, линии и маркеры могут быть преобразованы в объекты лесной инфраструктуры - ' +
      'деляны, дороги и квартальные столбы',
    title: 'Проекты',
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#prj_top_panel',
    content: 'В данном блоке находятся кнопки для работы с пользовательскими объектами создаваемыми на карте. ' +
      'Для активации кнопок создания объектов необходимо выбрать рабочую область или папку, куда эти объекты будут сохранены',
    title: "Панель управления объектами",
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#createWorkspaceButton',
    content: 'Кнопка позволяет создать новую рабочую область (папку верхнего уровня в каталоге с объектами)',
    title: 'Новая рабочая область',
    image: workSpaceImage,
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#createNewFolderButton',
    content: 'Кнопка позволяет создать новую папку в рабочей области, в которую также можно сохранить создаваемые ' +
      'вами на карте объекты (полигоны, линии, маркеры)',
    title: 'Новая папка',
    image: folderImage,
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#uploadGPXFileButton',
    content: 'Кнопка открывает окно для загрузки вашего gpx-файла с записанным маршрутом. Gpx-трек может быть сохранен' +
      ' в виде линии, полигона или точек',
    title: 'Загрузка GPX-файла',
    image: uploadGpxImage,
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#draw_line_button',
    content: 'Кнопка активирует режим создания линий на карте. Сохранить линию на карте можно кликом по любой из вершин линии',
    title: 'Режим рисования линии',
    image: lineImage,
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#draw_polygon_button',
    content: 'Кнопка активирует режим создания полигонов на карте. Полигон сохраняется автоматически при замыкании ' +
      'контура фигуры. Точка полигона автоматически "прилипает" к линии соседнего полигона. Этот эффект можно убрать ' +
      'зажав клавишу "Alt"',
    title: 'Режим рисования полигона',
    image: polygonImage,
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#draw_marker_button',
    content: 'Кнопка активирует режим создания маркеров на карте',
    title: 'Создание маркера',
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#delete_last_point',
    content: 'Кнопка удаляет последнюю точку создаваемой линии или полигона',
    title: "Удаление последней точки",
    placement: 'right',
    disableBeacon: true
  },
  ]

export const plotsIntroTourSteps = [
  {
    target: '#plotsBlock',
    content: 'Данный раздел содержит список созданных на карте делян',
    title: 'Создание делян',
    placement: 'right',
    image: plotsListImage,
    disableBeacon: true
  },
  {
    target: '#plotsBlock',
    content: 'Для создания деляны нужно выбрать на карте сохраненный полигон, и в открывшемся окне нажать кнопку ' +
      '"Создать деляну"',
    title: 'Создание делян',
    placement: 'right',
    image: createPlotButtonImage,
    disableBeacon: true
  },
  {
    target: '#plotsBlock',
    content: 'В открывшемся окне создания деляны, заполните необходимые поля и нажмите кнопку "Сохранить"',
    title: 'Создание делян',
    placement: 'right',
    image: createPlotModalImage,
    disableBeacon: true
  },
  {
    target: '#plotsBlock',
    content: 'Созданная вами деляна отобразится на карте и появится в данном блоке',
    title: 'Создание делян',
    placement: 'right',
    image: plotExampleImage,
    disableBeacon: true
  },
  {
    target: '#plotsBlock',
    content: 'Варианты отображения делян можно настроить с помощью группировки',
    title: 'Создание делян',
    placement: 'right',
    image: plotsFiltersImage,
    disableBeacon: true
  },
]

export const filtersIntroTourSteps = [
  {
    target: '#filtersBlock',
    content: 'Данный раздел содержит настройки для фильтрации отображаемых на карте делян',
    title: 'Фильтры',
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#filtersBlock',
    content: 'Пример: при установке таких фильтров, на карте будут отображаться только отведенные деляны Рудиковского ' +
      'участкового лесничества',
    title: 'Фильтры',
    placement: 'right',
    image: filtersExampleImage,
    disableBeacon: true
  },
  ]

export const printIntroTourSteps = [
  {
    target: '#printBlock',
    content: 'Данный блок содержит функционал для настройки и вывода на печать нужного участка карты. ' +
      'В настройках можно задать ориентацию страницы, формат печати и поля',
    title: 'Печать',
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#showShapesSwitch',
    content: 'Переключатель "Показать фигуры", позволяет скрыть или показать на карте сохраненные фигуры - ' +
      'полигоны, линии, маркеры',
    title: 'Печать',
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#showCasLabelsSwitch',
    content: 'Переключатель "Подписать деляны", добавляет к делянам на карте подпись в формате "Номер квартала"/"Номер деляны"',
    title: 'Печать',
    placement: 'right',
    disableBeacon: true
  },
]

export const organizationPageIntroTourSteps = [
  {
    target: '#organizationPageIntroTour',
    content: 'Страница "Личный кабинет" содержит всю актуальную информацию о вашей организации а также функционал ' +
      'для, оплаты тарифа, смены тарифа, работы со списком сотрудников, работы со списком рабочих областей, отправки' +
      ' запроса на подключение модуля 1С',
    title: 'Личный кабинет',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#currentTariff',
    content: 'Данный блок содержит информацию о текущем тарифном плане: название тарифа, срок действия тарифа, количество сотрудников ' +
      'доступных в соответствии с условиями тарифа, количество активных сотрудников и их роли',
    title: 'Информация о тарифе',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#personalInfo',
    content: 'Данный блок содержит информацию о вашей учетной записи: ФИО, должность, название вашей организации',
    title: 'Учетная запись',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#authorizationData',
    content: 'Данный блок содержит информацию о ваших авторизационных данных: номер телефона и email указанные при ' +
      'авторизации, а также кнопку "изменить пароль", которая открывает окно с формой для изменения пароля (через смс, ' +
      'или через ввод старого пароля)',
    title: 'Авторизационные данные',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#workspaces',
    content: 'Данный блок содержит список рабочих областей созданных в организации а также функционал для их удаления и ' +
      'редактирования (если у вас есть права администратора)',
    title: 'Рабочие области',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#employees',
    content: 'Данный блок содержит список сотрудников добавленных в организацию и их учетные данные, а также функционал ' +
      'для добавления, редактирования и удаления сотрудников (если у вас есть права администратора)',
    title: 'Сотрудники',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#personalAccount',
    content: 'Данный блок содержит информацию о вашей организации: название организации, лицевой счет, ИНН, дату ' +
      'следующего платежа, историю последних платежей, а также кнопки для перехода в окно оплаты картой и в ' +
      'окно формирования счета на оплату в формате pdf (если у вас есть права администратора)',
    title: 'Сотрудники',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#tariffs',
    content: 'Данный блок содержит информацию о доступных тарифах с описанием их функционала, а также кнопки для ' +
      'перехода в окно смены тарифа (если у вас есть права администратора)',
    title: 'Сотрудники',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#expansionModules',
    content: 'Данный блок содержит кнопки для отправки запроса на подключение модуля 1С к вашей организации',
    title: 'Сотрудники',
    placement: 'bottom',
    disableBeacon: true
  },
]

export const employeesListPageIntroTourSteps = [
  {
    target: '#employeesList',
    content: 'Данный блок содержит список сотрудников добавленных в вашу организацию и функционал для добавления ' +
      'новых сотрудников',
    title: 'Cписок сотрудников',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#addEmployeeButton',
    content: 'Для добавления нового сотрудника нажмите кнопку "Добавить сотрудника"',
    title: 'Cписок сотрудников',
    placement: 'right',
    disableBeacon: true
  },
  {
    target: '#addEmployeeButton',
    content: 'В открывшемся окне заполните поля с номером телефона нового сотрудника, его ФИО и должность.' +
      'В организацию может быть добавлен только сотрудник зарегистрированный в приложении Delyana.',
    title: 'Cписок сотрудников',
    placement: 'right',
    image: addUserToOrgImage,
    disableBeacon: true
  },
  {
    target: '#addEmployeeButton',
    content: 'После добавления сотрудника он появится в списке сотрудников и вы сможете добавить его в вашу рабочую' +
      ' область, и он получит возможность просмотра делян и дорог вашей организации',
    title: 'Cписок сотрудников',
    placement: 'right',
    image: employeesListImage,
    disableBeacon: true
  },
]

export const planetableIntroTourSteps = [
  {
    target: '#planetables',
    content: 'На данной странице вы можете загрузить отсканированное изображение планшета и отобразить его ' +
      'на карте',
    title: 'Редактор планшетов',
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '#uploadPlanetable',
    content: 'Кнопка "Загрузить планшет" открывает окно для добавления вашего планшета в приложение. В открывшемся окне необходимо ' +
      'добавить файл с планшетом в формате png или jpeg, нажав на кнопку "Загрузить планшет". После добавления файла ' +
      'заполните необходимые поля и нажмите кнопку "Сохранить"',
    title: 'Загрузить планшет',
    placement: 'right',
    image: uploadPlanetableImage,
    disableBeacon: true
  },
  {
    target: '#planetablesList',
    content: 'Загруженный планшет появится в списке планшетов в этом блоке',
    title: 'Список планшетов',
    placement: 'right',
    image: planetablesListImage,
    disableBeacon: true
  },
  {
    target: '#planetablesFilter',
    content: 'Кнопка "Группировка" позволяет сгруппировать планшеты по различным категориям данных планшета',
    title: 'Список планшетов',
    placement: 'right',
    image: planetablesGroupedImage,
    disableBeacon: true
  },
  {
    target: '#svg-container',
    content: 'После выбора планшета его изображение появится в этом блоке. Уменьшить или увеличить изображение можно ' +
      'колесиком мыши или в блоке управления масштабом в панели сверху. Зажав среднюю клавишу мыши вы можете перемещать фокус ' +
      'относительно планшета',
    title: 'Работа с планшетом',
    placement: 'bottom',
    image: planetableSvgImage,
    disableBeacon: true
  },
  {
    target: '#toolbar',
    content: 'Для загрузки планшета на карту необходимо добавить кварталы на планшет. Для этого нажмите на кнопку' +
      '"Загрузить кварталы"',
    placement: 'bottom',
    title: 'Работа с планшетом',
    image: addQuartersFromMap,
    disableBeacon: true
  },
  {
    target: '#svg-container',
    content: 'Далее, в открывшемся окне, необходимо выбрать номера кварталов которые вы хотите загрузить на планшет и ' +
      'нажать кнопку "Сохранить". Слой с выбранными кварталами появится на планшете',
    placement: 'bottom',
    title: 'Работа с планшетом',
    image: addQuartersModal,
    disableBeacon: true
  },
  {
    target: '#svg-container',
    content: 'Далее необходимо наложить слой планшетов поверх квартальной сетки на изображении. Слой можно перенести ' +
      'с помощью зажатой левой клавиши мыши либо клавишами "WASD" на клавиатуре. Клавишами "QE" можно повернуть ' +
      'слой кварталов по часовой или против часовой стрелки.',
    placement: 'bottom',
    title: 'Работа с планшетом',
    image: editQuarterLayerPosition,
    disableBeacon: true
  },
  {
    target: '#svg-container',
    content: 'После перемещения слоя в нужное место нажмите иконку дискеты для сохранения кварталов на планшете и они ' +
      'появятся на карте',
    placement: 'bottom',
    title: 'Работа с планшетом',
    image: saveQuarters,
    disableBeacon: true
  },

]