import React from 'react';
import {TableCell, TableRow, TextField} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import {isEmpty} from "../../../../validations/validations";

const CreateMachineTableRow = ({addNewMachineData, newMachine, createMachine}) => {
  let requiredFieldIsEmpty = isEmpty(newMachine?.name)


  return (
    <TableRow sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "solid 1px lightgrey", pb: 2}}}>
      <TableCell>
        <TextField size={'small'}
                   required={true}
                   variant={'standard'}
                   label={'Марка'}
                   value={newMachine.name}
                   inputProps={{maxLength: 50}}
                   onChange={(e) => addNewMachineData(e, 'name')}/>
      </TableCell>
      <TableCell>
        <TextField size={'small'}
                   variant={'standard'}
                   label={'Госномер'}
                   inputProps={{maxLength: 10}}
                   value={newMachine.number}
                   onChange={(e) => addNewMachineData(e, 'number')}/>
      </TableCell>
      <TableCell>
        <IconButton onClick={createMachine} disabled={requiredFieldIsEmpty}>
          <SaveIcon size={'small'}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CreateMachineTableRow;