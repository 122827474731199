export function findPolygonCenter(points) {
  let centerX = 0;
  let centerY = 0;

  // Суммируем координаты вершин
  for (let i = 0; i < points.length; i++) {
    centerX += points[i].x;
    centerY += points[i].y;
  }

  // Делим сумму координат на количество вершин
  centerX /= points.length;
  centerY /= points.length;

  return { x: centerX, y: centerY };
}

export const rotate = (points, direction, centerPoint, value) => {
  const degree = direction === 'left' ? -value : value
  return points.map((point) => {
    let rad = degree * (Math.PI / 180)
    return {
      ...point,
      x: centerPoint.x + (point.x - centerPoint.x) * Math.cos(rad) - (point.y - centerPoint.y) * Math.sin(rad),
      y: centerPoint.y + (point.x - centerPoint.x) * Math.sin(rad) + (point.y - centerPoint.y) * Math.cos(rad)
    }
  })
}

export const polygonShift = (points, direction, value) => {
  switch (direction) {
    case 'up':
      return points.map(point => {return {x: point.x, y: point.y - value}})
    case 'down':
      return points.map(point => {return {x: point.x, y: point.y + value}})
    case 'right':
      return points.map(point => {return {x: point.x + value, y: point.y}})
    case 'left':
      return points.map(point => {return {x: point.x - value, y: point.y}})
  }
}