import React from 'react';
import {Button, Typography, Stack, Box} from "@mui/material";
import {expirationDateButtonStyle, expirationDateTextContainerStyle} from "../headerStyles";
import {useNavigate} from "react-router";
import {urlGenerator} from "../../../utils/urlGenerator";
import {useSelector} from "react-redux";

const getDaysCount = (paid_to) => {
  let days = null
  let daysText = ''
  const paidDate = new Date(paid_to.split('.').reverse().join('-'));
  const currentDate = new Date()
  const timeDifference = paidDate.getTime() - currentDate.getTime();
  days = Math.ceil(timeDifference / (1000 * 3600 * 24));
  switch (days) {
    case 0:
      daysText = 'дней'
      break
    case 1:
      daysText = 'день'
      break
    case 2:
      daysText = 'дня'
      break
    case 3:
      daysText = 'дня'
      break
    case 4:
      daysText = 'дня'
      break
    case 5:
      daysText = 'дней'
  }
  return {days, daysText}
}

const ExpirationButton = () => {
    const navigate = useNavigate()
    const paid_to = useSelector(state => state.organizationInfoReducer.organizationData.paid_to)
    let days = null
    let daysText = ''
    if (paid_to) {
      let daysData = getDaysCount(paid_to)
      days = daysData.days
      daysText = daysData.daysText
    }

    const paynmentButtonHandler = () => {
      navigate(urlGenerator().newOrganizationPage, {state: {blockName: 'PersonalAccount'}})
    }
    if (days === null || days > 5) return ''

    return (
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{mr: 1}}>
        <Box sx={expirationDateTextContainerStyle}>
          {days >= 0
            ? (
              <Typography
                sx={{color: 'black'}}>{`Срок действия тарифа истекает через ${days} ` + daysText}
              </Typography>
            )
            : (
              <Typography
                sx={{color: 'black'}}>{`Срок действия тарифа истек`}
              </Typography>
            )
          }
        </Box>
        <Button variant={'contained'} size={'small'}
                sx={expirationDateButtonStyle}
                onClick={paynmentButtonHandler}
        >Оплатить</Button>
      </Stack>
    );
  }
;

export default ExpirationButton;