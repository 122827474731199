
const defaultState = {
  inputValue: '',
  menuType: 'empty', //empty, search, coordinates, complete
  results: {
    cas: {name: 'Деляны', enabled: true, data: []},
    kv: {name: 'Кварталы', enabled: true, data: []},
    geo: {name: 'Геокодер', enabled: true, data: []},
  },
}

export function setMapSearchAttrs(data) {
  return {
    type: 'MAP_SEARCH_SET_ATTRS',
    data: data,
  }
}

export function resetMapSearchPanel() {
  return {
    type: 'MAP_SEARCH_RESET',
  }
}

export function mapSearchReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_SEARCH_SET_ATTRS':
      return {...state, ...action.data}
    case 'MAP_SEARCH_RESET':
      return {...defaultState}
    default:
      return state;
  }
}
