import {compareArrays} from "../Common/MiscFunction";
import {getMap1} from "../GlobalObjects";

export class Zoom {
  constructor(map) {
    this.map = getMap1();
    this.layers = [];
    map.on('zoomend', () => {
      const zoom = map.getZoom();
      this.layers.map(lay => {
        if (zoom < lay.minZoom || zoom > lay.maxZoom) {
          if (lay.layer.constructor === Array) {
            lay.layer.map(l => {
              if (this.map.hasLayer(l))
                map.removeLayer(l)
              return null;
            })
          } else if (this.map.hasLayer(lay.layer))
            map.removeLayer(lay.layer)
        } else {
          if (lay.layer.constructor === Array) {
            lay.layer.map(l => {
              if (!this.map.hasLayer(l))
                map.addLayer(l)
              return null;
            })
          } else if (!this.map.hasLayer(lay.layer))
            map.addLayer(lay.layer)
        }
        return null;
      })
    })
  }

  addLayer(layer, minZoom = 0, maxZoom = 20) {
    const zoom = this.map.getZoom()
    this.layers.push({layer: layer, minZoom: minZoom, maxZoom: maxZoom})
    if (layer.constructor === Array) {
      layer.map(l => {
        if (zoom >= minZoom && zoom <= maxZoom && !this.map.hasLayer(l))
          this.map.addLayer(l)
        return null;
      })
    } else {
      if (zoom >= minZoom && zoom <= maxZoom && !this.map.hasLayer(layer))
        this.map.addLayer(layer)
    }
  }

  removeLayer(layer) {
    let ind = 0;
    for (ind; ind < this.layers.length; ind++) {
      if (layer.constructor === Array) {
        if (compareArrays(layer, this.layers[ind].layer)) {
          this.layers.splice(ind, 1)
          layer.map(l => {
            this.map.removeLayer(l)
            return null;
          })
          break;
        }
      } else if (this.layers[ind].layer === layer) {
        this.layers.splice(ind, 1)
        this.map.removeLayer(layer)
        break;
      }
    }
  }

  deleteAllLayers() {
    this.layers.map(layer => {
      this.removeLayer(layer)
    })
  }
}
