import React, {useState} from 'react';
import ModalPage from "../../../Common/ModalPage";
import {Alert, Box, Link, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import PasswordDataRow from "../ChangePasswordDialog/PasswordDataRow/PasswordDataRow";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {sendConfCodeThunk} from "../../../../redux/reducers/loginReducer";
import {useDispatch, useSelector} from "react-redux";
import {setNewPasswordWithCodeThunk, setNewPasswordWithOldPasswordThunk} from "../../../../redux/reducers/userReducer";
import {setSnack} from "../../../Main/Map/Common/Dialog/Snack/snackReducer";

const CreatePasswordDialog = ({openDialog, setOpenDialog}) => {
  const dispatch = useDispatch()
  const username = useSelector(state => state.userReducer.userInfo.username)
  const password_is_set = useSelector(state => state.userReducer.userInfo.password_is_set)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [confCode, setConfCode] = useState('')
  const [seconds, setSeconds] = useState(60)
  const [errors, setErrors] = useState({})


  const validation = () => {
    let errorsObj = {}
    const passwordRegex = "[0-9a-zA-Z!@#$%^&*_]{8,}"
    if (newPassword !== repeatNewPassword) {
      errorsObj.newPassword = 'Пароли не совпадают'
      errorsObj.RepeatPassword = 'Пароли не совпадают'
    }
    if (!confCode && !oldPassword) errorsObj.confCode = 'Поле является обязательным'
    if (!newPassword) errorsObj.newPassword = 'Поле является обязательным'
    if (!repeatNewPassword) errorsObj.RepeatPassword = 'Поле является обязательным'
    if (!newPassword.match(passwordRegex))
      errorsObj.newPassword = 'Неверный формат пароля'
    if (!repeatNewPassword.match(passwordRegex))
      errorsObj.RepeatPassword = 'Неверный формат пароля'

    setErrors(prev => {
      return {...errorsObj}
    })
    return !Object.keys(errorsObj).length
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const savePasswordHandler = () => {
    let isChangePassWithCode = !oldPassword && confCode
    let isChangePassWithOldPass = oldPassword && !confCode
    if (isChangePassWithCode) {
      validation() && dispatch(setNewPasswordWithCodeThunk({password: newPassword, confirmation_code: confCode},
        setOpenDialog))
      return;
    } else if (isChangePassWithOldPass) {
      validation() && dispatch(setNewPasswordWithOldPasswordThunk(
        {old_password: oldPassword, new_password: newPassword}, setOpenDialog))
      return;
    }
    dispatch(setSnack('info', 'Для подтверждения смены пароля нужно отправить код подтверждения из ' +
      'sms или старый пароль'))
  }

  return (
    <ModalPage
      title={`Сохранить пароль`}
      handleClose={handleClose}
      actionButtonRemove={true}
    >
      <Alert sx={{width: 430}} severity={'info'}>Пароль должен состоять из восьми или более символов латинского алфавита,
        цифр и спецсимволов. Вы можете сменить пароль через ввод старого пароля, либо отправить код подтверждения из sms</Alert>
      <TableContainer sx={{pt: 2, pb: 2}}>
        <Table size={"small"} sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none",},  width: '25vw'}}>
          <TableBody>
            <>
              <PasswordDataRow label={'Код из sms'} field={'checkCode'} errorMessage={errors.confCode}
                               maxLength={4} onChangeHandler={setConfCode} type={'tel'} seconds={seconds}
                               setSeconds={setSeconds} sendCodeButton={true}/>
            </>
            {password_is_set &&
                <PasswordDataRow label={'Старый пароль'} field={'oldPassword'}
                                 onChangeHandler={setOldPassword} eyeIcon={true} type={'password'}/>
            }
              <PasswordDataRow label={'Новый пароль'} field={'newPassword'} errorMessage={errors.newPassword}
                               onChangeHandler={setNewPassword} eyeIcon={true} type={'password'}/>
              <PasswordDataRow label={'Повтор пароля'} field={'RepeatPassword'} errorMessage={errors.RepeatPassword}
                               onChangeHandler={setRepeatNewPassword} eyeIcon={true} type={'password'}/>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={'row'} spacing={1} sx={{display: 'flex', justifyContent: 'end'}}>
        <Button size={'small'} variant={'outlined'}
                onClick={() => {
                  validation() && savePasswordHandler()
                }}
        >Сохранить</Button>
      </Stack>
    </ModalPage>
  );
};

export default CreatePasswordDialog;