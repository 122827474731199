import React from 'react';
import {IconButton} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {useDispatch, useSelector} from "react-redux";
import {toolbarIconButtonStyle, toolbarIconStyle} from "../../toolbarStyles";
import {patchChangeRulerDataThunk} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {cropOutlinesByRulerWidth} from "../../../Image/ImageSvg/imageSvgFunctions/cropOutlineByRulerWidth";
import {cropTimbersByRulerWidth} from "../../../Image/ImageSvg/imageSvgFunctions/cropTimbersByRulerWidth";


const SaveRulerButton = () => {
  const dispatch = useDispatch()
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const outlines = useSelector(state => state.stockpilePhotoReducer.outlines)
  const ruler = useSelector(state => state.stockpilePhotoReducer.ruler)

  const saveRulerHandler = () => {
    cropOutlinesByRulerWidth(outlines, ruler, dispatch, selectedPhoto)
    cropTimbersByRulerWidth(ruler, dispatch, selectedPhoto)
    dispatch(patchChangeRulerDataThunk(selectedPhoto, ruler))
  }

  return (
    <>
      <IconButton
        disabled={ruler.length < 2}
        sx={toolbarIconButtonStyle}
        title={'Сохранить ширину замера'}
        onClick={saveRulerHandler}
      >
        <SaveIcon sx={toolbarIconStyle}/>
      </IconButton>
    </>
  );
};

export default SaveRulerButton;