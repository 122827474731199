import React, {memo} from 'react';
import {coordCorrection} from "../../../../../svgComponentsHelpersFunctions/coordCorrection";
import {useSelector} from "react-redux";

const NearestOutlinePoints = memo(({nearestOnlinePoints}) => {
  const outlines = useSelector(state => state.stockpilePhotoReducer.outlines)
  const outline_selected_index = useSelector(state => state.stockpilePhotoReducer.outline_selected_index)
  const zoom = useSelector(state => state.stockpilePhotoReducer.zoom)
  const scale = useSelector(state => state.stockpilePhotoReducer.scale)
  let point1 = null
  let point2 = null
  const selectedOutline = outlines[outline_selected_index]
  if (nearestOnlinePoints) {
    point1 = selectedOutline[nearestOnlinePoints[0]]
    point2 = selectedOutline[nearestOnlinePoints[1]]
  }

  return (
    <>
      {(point1 && point2) &&
        <line x1={`${coordCorrection(point1.x, zoom, scale)}`} y1={`${coordCorrection(point1.y, zoom, scale)}`}
              x2={`${coordCorrection(point2.x, zoom, scale)}`} y2={`${coordCorrection(point2.y, zoom, scale)}`}
              stroke="red"
              strokeWidth={'3'}
        />
      }
    </>

  )
})

export default NearestOutlinePoints;
