import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, List, ListItem, Stack, Typography, useTheme} from "@mui/material";
import {v4 as uuid} from "uuid";
import ViolationItem from "./ViolationItem";
import {hideMapDialog, showMapDialog,} from "../../../../Map/Common/Dialog/DialogReducer";
import {setTargetViolation} from "./actionCreators";
import {Loading} from "../../../../Map/Common/Loading";
import {deleteViolation, loadViolations} from "./ViolationCommon";
import {flyToPoint} from "../../../../Map/Common/fly";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";

/**
 * Компонент отображения содержимого модуля нарушений в левой панели.
 */
const Violations = ({}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.violationsReducer)
  const violations = state.violations;
  const targetViolation = state.targetViolation;
  const buttons = {
    'Да': {color: theme.palette.warning.main},
    'Нет': {color: theme.palette.primary.main},
  }

  const handleSelect = (violation) => {
    dispatch(setTargetViolation(violation))
    flyToPoint(violation.layer.getLatLng())
    loadRightPanelData('Violation', violation, violation.id, false)
  }

  const onItemDeleteClick = (itemId) => {
    dispatch(
      showMapDialog(
        "warning",
        "Удаление нарушения",
        "Вы уверены, что хотите удалить нарушение без возможности восстановления?",
        buttons,
        (e) => {
          dispatch(hideMapDialog())
          if (e === "Да") {
            deleteViolation(itemId)
          }
        }
      )
    )
  }

  if (state.loading)
    return <Loading style={{marginTop: '1vh'}}/>

  if (state.loadingError)
    return (
      <Stack gap={2}>
        <Alert severity={'error'}>
          Произошла ошибка при загрузке нарушений. Нажмите кнопку ниже, чтобы попробовать еще раз.
        </Alert>
        <Button
          fullWidth
          variant={'outlined'}
          onClick={() => {
            loadViolations()
          }}
        >
          Повторить
        </Button>
      </Stack>
    )

  return (
    <List
      sx={{
        overflowY: 'auto',
        position: 'absolute',
        inset: 0,
      }}
    >
      {violations.length > 0 ?
        violations.map((item) => (
          <ListItem key={uuid()} style={{padding: "6px 0"}}>
            <ViolationItem
              key={item.id}
              violation={item}
              onItemClick={handleSelect}
              onItemDeleteClick={onItemDeleteClick}
              isSelected={targetViolation?.id === item.id}
            />
          </ListItem>
        ))
        :
        <ListItem>
          <Typography>Нарушения отсутствуют</Typography>
        </ListItem>
      }
    </List>
  )
}

export default Violations;
