import {PillarsTree} from "./PillarsTree";
import {Loading} from "../../../../Map/Common/Loading";
import {useSelector} from "react-redux";
import {Alert, Button, Divider, Stack} from "@mui/material";
import {loadPillars} from "./pillars";
import {cannotIDo} from "../../../../Map/Common/tariffs";
import {dispatch} from "../../../../../Common/misc_functions";
import {changePillarsDialogAttr} from "./pillarsReducer";

/**
 * Компонента столбов для левой панели
 * @returns {JSX.Element}
 */
export function Pillars() {
  const state = useSelector(state => state.pillarsReducer)
  const readOnly = !!cannotIDo.editPillars()

  if (state.loading)
    return <Loading />

  if (state.loadingError) {
    return (
    <Stack gap={2}>
      <Alert severity={'error'} style={{marginTop: '1vh'}}>
        Произошла ошибка при загрузке столбов. Нажмите кнопку ниже, чтобы попробовать еще раз.
      </Alert>
      <Button
        fullWidth
        variant={'outlined'}
        onClick={loadPillars}
      >
        Повторить
      </Button>
    </Stack>
    )
  }

  function handlePillarCreateDialog() {
    let workspace = state.selectedNode;
    if (workspace.elz_parent_node)
      workspace = workspace.elz_parent_node;

    dispatch(changePillarsDialogAttr({
      workspace: workspace,
    }))
  }

  return (
    <Stack flexDirection={'column'} id={'lalal'} sx={{height: '100%'}}>
      {!readOnly &&
        <>
          <div>
            <Button size={'small'} disabled={!state.selectedNode} onClick={handlePillarCreateDialog}>Создать столб</Button>
          </div>
          <Divider />
        </>
      }
      <div style={{flex: 1, position: 'relative'}}>
        <PillarsTree />
      </div>
    </Stack>
  )
}
