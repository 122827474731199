import Box from "@mui/material/Box";
import PropTypes from "prop-types";

export function CustomTabPanel(props) {
  const {children, value, index, disabled, ...other} = props;
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{p: 3, pt: 0}}>
            {children}
          </Box>
        )}
      </div>
    </>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
