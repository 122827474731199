import {caSelectionSetItems} from "./caSelectionsReducer";

export const caSelectionBorderColor = 'yellow';

export function caSearchLayerInSelection(layer) { // найти индекс слоя
  const items = window.store.getState().caSelectionsReducer.items;
  let ind = 0;
  while(ind < items.length) {
    if (items[ind] === layer)
      return ind;
    ind++;
  }
  return -1;
}

export function addCALayerToSelection(layer, dispatch) { // добавить слой в выделения
  const items = [...window.store.getState().caSelectionsReducer.items]
  items.push(layer)
  layer.elz_oldopts = {...layer.options}
  layer.setStyle({color: caSelectionBorderColor})
  if (dispatch)
    dispatch(caSelectionSetItems(items))
}

export function removeCALayerFromSelection(index, dispatch) { // удалить слой из выделения
  const items = [...window.store.getState().caSelectionsReducer.items]
  if (index > -1) {
    const layer = items[index]
    layer.setStyle(layer.elz_oldopts)
    delete layer.elz_oldopts;
    items.splice(index ,1)
    if (dispatch)
      dispatch(caSelectionSetItems(items))
  }
}

export function resetAllCAsSelection() { // очистить выделения
  const dispatch = window.elz_dispatch;
  const items = [...window.store.getState().caSelectionsReducer.items]
  items.map(layer => {
    layer.setStyle(layer.elz_oldopts)
    delete layer.elz_oldopts;
  })
  dispatch(caSelectionSetItems([]))
}
