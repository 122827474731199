
const stateDefault = {
  visible: false,
}

export function showBaseLayersWindows(visible) {
  return {
    type: 'MAP_BASE_LAYERS_SHOW_WINDOW',
    visible: visible,
  }
}

export function baseLayersWindowRefresh() {
  return {
    type: 'MAP_BASE_LAYERS_WINDOW_REFRESH',
  }
}

export function baseLayersReducer(state = stateDefault, action) {
  switch (action.type) {
    case 'MAP_BASE_LAYERS_SHOW_WINDOW':
      return {...state, visible: action.visible}
    case 'MAP_BASE_LAYERS_WINDOW_REFRESH':
      return {...state}
    case 'MAP_HIDE_ALL_OVERLAY_WINDOW':
      return {...state, visible: false}
    default:
      return state;
  }
}
