import {IconButton, Stack} from "@mui/material";
import {WSPAutocomplete} from "./WSPAutocomplete";
import {
  caRegionString,
  deleteDistrictForestryString,
  deleteDistrictForestryText,
  districtChangeWarningString,
  districtForestryAlreadyAdded,
  districtForestryChangeWarningString,
  districtForestryName,
  forestryChangeWarningString,
  forestryString,
  quartersNumbersClearString,
  quartersString,
  warningString
} from "../../../../Map/Common/Strings_RU";
import {otherDataAPI} from "../../../../../../api/api";
import React from "react";
import {hideMapDialog, showMapDialog} from "../../../../Map/Common/Dialog/DialogReducer";
import {yesNoWarningButtons} from "../../../../Map/Common/Dialog/MapDialog";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {useDispatch, useSelector} from "react-redux";
import {WSQuartersPAutocomplete} from "./WSQuartersPAutocomplete";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {newQuarterTemplate, nullRentItem, setWorkspaceRentData} from "./workspaceReducer";
import Box from "@mui/material/Box";

function QuartersForm({forestry, quarter, callback}) {
  const dispatch = useDispatch()

  function setDForestry(dForestry) {
    callback('dforestry', quarter, dForestry)
  }

  function setQuartersNode(numbers) {
    callback('quarters', quarter, numbers)
  }

  function deleteForestry() {
    const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
    dispatch(showMapDialog('warning', deleteDistrictForestryString, deleteDistrictForestryText.replace('__1__', quarter.districtForestry.name), buttons, (value) => {
      dispatch(hideMapDialog())
      if (value === 'Да') {
        callback('delete', quarter, null)
      }
    }))
  }

  return (
    <Stack direction={'row'} style={{width: '100%'}} spacing={1}>
      <WSPAutocomplete
        disabled={!forestry}
        style={{flex: 0.3}}
        label={districtForestryName}
        value={quarter.districtForestry || null}
        dataAPIFunction={otherDataAPI.references.getAlternativeDistrictForestryByAltForestryId}
        dataAPIFunctionParams={forestry?.id}
        callback={setDForestry}
      />
      <Stack direction={'row'} style={{flex: 0.7}} spacing={1}>
        <Box style={{flex: '1 0 0%'}}>
          <WSQuartersPAutocomplete
            quarter={quarter}
            label={quartersString}
            callback={setQuartersNode}
          />
        </Box>
        {quarter.districtForestry
          &&
          <IconButton
            disableRipple
            size={'small'}
            color={'warning'}
            onClick={deleteForestry}
            style={{padding: 0}}
          >
            <DeleteOutlineOutlinedIcon fontSize={'small'}/>
          </IconButton>
        }
      </Stack>
    </Stack>
  )
}

export function WSRentBordersTab() {
  const state = useSelector(state => state.workspaceReducer)
  const dispatch = useDispatch()
  const rentData = state.rentData;
  function getDForestryQuarterNodeIndex(id) {
    let ind = 0;
    while (ind < rentData.quarters.length) {
      const item = rentData.quarters[ind]
      if (item.districtForestry?.id === id)
        return ind;
      ind++;
    }
    return -1;
  }

  function handleRegion(newRegion) {
    if (rentData.region?.id !== newRegion?.id) {
      let needDialog = rentData.forestry;
      if (needDialog) {
        dispatch(showMapDialog('warning', warningString, districtChangeWarningString, yesNoWarningButtons, (value) => {
          dispatch(hideMapDialog())
          if (value === 'Да') {
            if (newRegion)
              dispatch(setWorkspaceRentData({
                ...rentData,
                region: newRegion,
                forestry: null,
                quarters: [{...newQuarterTemplate}]
              }))
            else
              dispatch(setWorkspaceRentData(structuredClone(nullRentItem)))
          }
        }))
      } else {
        if (newRegion)
          dispatch(setWorkspaceRentData({
            ...rentData,
            region: newRegion,
            forestry: null,
            quarters: [{...newQuarterTemplate}]
          }))
        else
          dispatch(setWorkspaceRentData(structuredClone(nullRentItem)))
      }
    }
  }

  function handleForestry(newForestry) {
    if (rentData.forestry?.id !== newForestry?.id) {
      let needDialog = false;
      rentData.quarters.map(qua => {
        if (qua.districtForestry)
          needDialog = true;
      })
      if (needDialog) {
        dispatch(showMapDialog('warning', warningString, forestryChangeWarningString, yesNoWarningButtons, (value) => {
          dispatch(hideMapDialog())
          if (value === 'Да') {
            if (newForestry)
              dispatch(setWorkspaceRentData({...rentData, forestry: newForestry, quarters: [{...newQuarterTemplate}]}))
            else
              dispatch(setWorkspaceRentData({...rentData, forestry: null, quarters: [{...newQuarterTemplate}]}))
          }
        }))
      } else {
        if (newForestry)
          dispatch(setWorkspaceRentData({...rentData, forestry: newForestry, quarters: [{...newQuarterTemplate}]}))
        else
          dispatch(setWorkspaceRentData({...rentData, forestry: null, quarters: [{...newQuarterTemplate}]}))
      }
    }
  }

  function setQuartersNodeData(type, node, eleData) {
    const dat = [...rentData.quarters]
    const ind = rentData.quarters.findIndex(ele => ele === node) //индекс текущей ноды в data.quarters
    let async = false; //от эпилептических припадков реакта
    const datNode = dat[ind] //Нода со старым значением
    switch (type) {
      case 'dforestry':
        if (datNode.districtForestry?.id === eleData?.id)
          return;
        const index = getDForestryQuarterNodeIndex(eleData?.id) //есть ли нода с таким же id, возвращает индекс
        if (eleData && index !== -1) {
          dispatch(setSnack('error', districtForestryAlreadyAdded.replace('__1__', eleData.name)))
        } else {
          //Нужен ли диалог с вопросом
          const needDialog = datNode.districtForestry && datNode.numbers.length > 0;
          if (needDialog) {
            async = true;
            dispatch(showMapDialog('warning', warningString, districtForestryChangeWarningString, yesNoWarningButtons, (value) => {
              dispatch(hideMapDialog())
              if (value === 'Да') {
                if (eleData) {
                  datNode.districtForestry = {id: eleData.id, name: eleData.name}
                } else
                  datNode.districtForestry = null;
                datNode.numbers = []
                dispatch(setWorkspaceRentData({...rentData, quarters: dat}))
              }
            }))
          } else {
            if (eleData) {
              datNode.districtForestry = {id: eleData.id, name: eleData.name}
            } else
              datNode.districtForestry = null;
            datNode.numbers = []
            const isNew = ind === dat.length - 1;
            if (isNew) { //new item
              dat.push({...newQuarterTemplate})
            }
          }
          //
        }
        break;
      case 'quarters':
        if (datNode.numbers.length && !eleData.length) {
          async = true;
          dispatch(showMapDialog('warning', warningString, quartersNumbersClearString, yesNoWarningButtons,
            (value) => {
            dispatch(hideMapDialog())
            if (value === 'Да') {
              datNode.numbers = [];
              dispatch(setWorkspaceRentData({...rentData, quarters: dat}))
            }
          }))
        } else {
          datNode.numbers = eleData;
          datNode.numbers.sort((a, b) => a - b)
        }
        break;
      default:
        dat.splice(ind, 1)
    }
    if (!async) //от эпилептических припадков реакта
      dispatch(setWorkspaceRentData({...rentData, quarters: dat}))
  }

  let ind = 0;

  return (
    <Stack direction={'column'} style={{marginTop: '1vh', marginBottom: '2vh'}} spacing={2}>
      <Stack style={{width: '100%'}} direction={'row'} spacing={1}>
        <WSPAutocomplete
          disabled={state.loading || !state.loaded || state.loadingError !== null}
          style={{flex: 0.5}}
          label={caRegionString}
          value={rentData.region}
          dataAPIFunction={otherDataAPI.references.getRegions}
          dataAPIFunctionParams={''}
          callback={handleRegion}
        />
        <WSPAutocomplete
          disabled={!rentData.region?.id}
          style={{flex: 0.5}}
          label={forestryString}
          value={rentData.forestry}
          dataAPIFunction={otherDataAPI.references.getAlternativeForestriesByRegionId}
          dataAPIFunctionParams={rentData.region?.id}
          callback={handleForestry}
        />
      </Stack>
        {rentData.quarters.map(quarter =>
          <QuartersForm
            key={ind++}
            forestry={rentData.forestry}
            quarter={quarter}
            callback={setQuartersNodeData}
          />
        )}
    </Stack>
  )
}
