import React from 'react';
import Box from "@mui/material/Box";
import UploadPlanetableForm from "./uploadPlanetableForm/UploadPlanetableForm";
import LocationAutocomplete from "../LoadLayerModal/LocationAutocomplete/LocationAutocomplete";
import {Divider, InputAdornment, TextField} from "@mui/material";
import PlotLocationFields from "../../Common/RegionLocationFields/PlotLocationFields";
import Stack from "@mui/material/Stack";

const UploadPlanetable = ({currentPlanetable, planetableData, setPlanetableData}) => {
  const {rental_contract, image, name, magnetic_declination, scale} = planetableData

  const scaleInputHandler = (e) => {
    let value = e.target.value
    value.length < 6 && setPlanetableData(prev => {return {...prev, scale: value}})
  }

  const planetableNameInputHandler = (e) => {
    setPlanetableData(prev => {return {...prev, name: e.target.value}})
  }

  return (
    <>
      <UploadPlanetableForm image={image} setPlanetableData={setPlanetableData} currentPlanetable={currentPlanetable}/>
      <LocationAutocomplete rentalContract={rental_contract} setPlanetableData={setPlanetableData}/>
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Справочники
      </Divider>
      <Box sx={{mb: 2}}>
        <PlotLocationFields setDataObj={setPlanetableData} dataObj={planetableData} count={3}
                            currentPlanetable={currentPlanetable}/>
      </Box>
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Данные
      </Divider>
      <Stack sx={{mb: 2}} spacing={2}>
        <TextField
          fullWidth
          size={'small'}
          label="Имя планшета"
          type={'text'}
          value={name || ''}
          onChange={planetableNameInputHandler}
          inputProps={{maxLength: 50}}
        />
        <TextField
          fullWidth
          size={'small'}
          type={'number'}
          label="Масштаб"
          value={scale || ''}
          onChange={scaleInputHandler}
          inputProps={{maxLength: 5}}
          InputProps={{startAdornment: <InputAdornment position="start">1: </InputAdornment>}}
        />
      </Stack>
    </>
  );
};

export default UploadPlanetable;