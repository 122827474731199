import {errorOnDataRecieved, getCAInfoError} from "../../../Map/Common/Strings_RU";
import {getCAPopup, getCASumPopup, prepareToOpenPopup} from "../../../Map/MapPopup/popupFunctions";
import {dataAPI} from "../../../../../api/api";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {djangoErrorsParse} from "../../../../../utils/djangoErrorsParse";
import {
  changeMapPopup,
  closeMapPopup,
  getMapPopup,
  showLoadingPopup,
  showMapPopup
} from "../../../Map/MapPopup/mapPopup";
import {resetAllCAsSelection} from "./Selections/caSelectionsCommon";
import {cannotIDo} from "../../../Map/Common/tariffs";

/** Отображает асинхроннно попу для деляны. */
export function showCAPopup(event, interactive, layer) {
  if (!prepareToOpenPopup(event.originalEvent))
    return;

  const popupObject = showLoadingPopup(event.latlng)

  dataAPI.plots.getShortInfo(layer.elz_properties.id).then((res) => {
    const props = res.data.properties;
    if (layer.elz_properties.isForeign) props.isForeign = true;
    layer.elz_properties = props;
    if (popupObject === getMapPopup()) {
      if (props) {
        const html = getCAPopup(layer, interactive)
        changeMapPopup(html, interactive)
      } else {
        changeMapPopup(`<div class="popup_error">${getCAInfoError}</div>`)
      }
    }
  }).catch(err => {
    console.error(err)
    if (popupObject === getMapPopup())
      changeMapPopup(`<div class="popup_error">${getCAInfoError}</div>`)
  })
}

/** Отображает асинхронный popup для сводного отчета. */
export function showSumCAPopup(event, propsArray) {
  if (!prepareToOpenPopup(event))
    return;

  const interactives = {
    ca_popup_close_and_reset_selection: {
      type: 'click',
      f: () => {
        closeMapPopup()
        resetAllCAsSelection()
      }
    }
  }
  let allCas = '';
  const ids = []
  propsArray.map(props => {
    ids.push(props.id)
    let castr;

    if (props.quarter_number)
      castr = props.quarter_number.toString()
    else
      castr = 'Нет';
    castr += '/';
    if (props.number)
      castr += props.number.toString()
    else
      castr += 'БН'
    if (castr) {
      castr += ', ';
      allCas += castr;
    }
  })

  if (allCas) {
    allCas = allCas.slice(0, -1)
    allCas = allCas.slice(0, -1)
  }

  if (!cannotIDo.fullViewAction()) {
    const popupObject = showLoadingPopup(event.latlng)

    const json = {x_plots_id: ids}

    dataAPI.plots.getShortInfoMultiply(json).then(res => {
      const html = getCASumPopup({properties_1c: res.data, square: res.data.square}, allCas)
      if (popupObject === getMapPopup())
        changeMapPopup(html, interactives)
    }).catch(err => {
      console.error(err)
      if (popupObject === getMapPopup())
        closeMapPopup()
      const dispatch = window.elz_dispatch;
      dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : errorOnDataRecieved))
    })
  } else {
    const html = getCASumPopup(null, allCas, true)
    showMapPopup(event.latlng, html, interactives)
  }
}
