import React from "react";

function IconWood() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            version="1.1"
            viewBox="0 0 63.852 49.825"
        >
            <g transform="translate(53.093 24.157)">
                <g transform="matrix(.26458 0 0 .26458 -54.249 -32.328)">
                    <g>
                        <path
                            fill="#616161"
                            fillOpacity="1"
                            d="M88.3 160.9a25.7 25.7 0 01-25.7 25.7 25.7 25.7 0 01-25.7-25.7 25.7 25.7 0 0125.7-25.7 25.7 25.7 0 0125.7 25.7"
                            className="s0"
                        ></path>
                        <path
                            fill="#616161"
                            fillOpacity="1"
                            d="M62.6 219.1c-14.2 0-28.5-5.2-39.7-15.6-11.4-10.6-18-25-18.5-40.6-.5-15.6 5-30.4 15.6-41.8 11-11.8 26.5-18.5 42.6-18.5h.8l-.1 10c-13.6-.2-26.7 5.4-36 15.4-8.8 9.4-13.4 21.7-12.9 34.6.5 12.9 5.9 24.8 15.3 33.6s21.7 13.4 34.6 12.9c12.9-.5 24.8-5.9 33.6-15.3 9.3-9.9 13.9-23.4 12.8-37l10-.8c1.4 16.4-4.2 32.6-15.4 44.6-11.5 12.3-27.1 18.5-42.7 18.5z"
                            className="s1"
                        ></path>
                    </g>
                    <g>
                        <path
                            fill="#616161"
                            fillOpacity="1"
                            d="M150.4 89a25.7 25.7 0 01-25.7 25.7A25.7 25.7 0 0199 89a25.7 25.7 0 0125.7-25.7A25.7 25.7 0 01150.4 89"
                            className="s0"
                        ></path>
                        <path
                            fill="#616161"
                            fillOpacity="1"
                            d="M123.7 147.3c-16.4-.3-32-7.5-42.8-19.8-10.3-11.7-15.3-26.7-14.3-42.2 1-15.5 8-29.7 19.7-40 24.2-21.2 61-18.7 82.2 5.4 10.8 12.3 15.9 28.8 14.1 45.1l-9.9-1.1c1.6-13.5-2.7-27.1-11.6-37.3-17.5-20-48.1-22-68.1-4.5-9.8 8.3-15.6 20.1-16.5 33-.8 12.9 3.4 25.3 11.9 35 9 10.2 21.9 16.2 35.5 16.4z"
                            className="s1"
                        ></path>
                    </g>
                    <g>
                        <g>
                            <path
                                fill="#616161"
                                fillOpacity="1"
                                d="M213.1 161a25.7 25.7 0 01-25.7 25.7 25.7 25.7 0 01-25.7-25.7 25.7 25.7 0 0125.7-25.7 25.7 25.7 0 0125.7 25.7"
                                className="s0"
                            ></path>
                        </g>
                        <path
                            fill="#616161"
                            fillOpacity="1"
                            d="M187.4 219.2c-32.1 0-58.3-26.1-58.3-58.3 0-32.2 26.1-58.3 58.3-58.3 32.2 0 58.3 26.1 58.3 58.3 0 32.2-26.2 58.3-58.3 58.3zm0-106.5c-26.6 0-48.3 21.6-48.3 48.3 0 26.6 21.6 48.3 48.3 48.3 26.6 0 48.3-21.6 48.3-48.3-.1-26.6-21.7-48.3-48.3-48.3z"
                            className="s1"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default IconWood;
