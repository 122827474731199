import {useEffect, useState} from 'react';
import ModalPage from "../../Common/ModalPage";
import {
  getQuartersFromMapThunk,
  setPlanetableSvgAttr
} from "../../../redux/reducers/planetableSvgReducer";
import {useDispatch, useSelector} from "react-redux";
import Stack from "@mui/material/Stack";
import LocationPlotField from "../../Common/RegionLocationFields/AutocompletePlotField/LocationPlotField";
import QuartersByDistrictForestryAutocomplete
  from "./quartersByDistirctForestryAutocomplete/quartersByDistrictForestryAutocomplete";
import {getAltDistrictForestryWithQuartersListThunk} from "../../../redux/reducers/planetablesReducer";
import {setSnack} from "../../Main/Map/Common/Dialog/Snack/snackReducer";

export const customModalWindowStyle = {
  position: "absolute",
  top: "40px",
  left: "10px",
  backgroundColor: "rgb(255, 255, 255)",
  border: "0px solid lightgrey",
  borderRadius: 8,
  boxShadow: 24,
  padding: 14,
  maxHeight: '90vh',
  width: '28vw',
  fontFamily: 'roboto'
};

const LoadLayerModal = () => {
  const dispatch = useDispatch()
  const referenceLoading = useSelector(state => state.referencesReducer.referenceLoading)
  const altForestry = useSelector(state => state.referencesReducer.references.altForestry)
  const altDistrictForestry = useSelector(state => state.referencesReducer.references.altDistrictForestry)
  const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)
  const AltDistrictForestryWithQuartersList = useSelector(state => state.planetablesReducer.AltDistrictForestryWithQuartersList)
  const zoom = useSelector(state => state.planetableSvgReducer.zoom)
  const scale = useSelector(state => state.planetableSvgReducer.scale)
  const [selectedAltForestry, setSelectedAltForestry] = useState(null)
  const [selectedAltDistrictForestry, setSelectedAltDistrictForestry] = useState(null)
  const [quartersList, setQuartersList] = useState([])

  //если в плашете есть альтернативные лесничество и уч. лесничество то грузим их в локальный стейт
  useEffect(() => {
    const findedAltForestry = selectedPlanetable?.alter_data.alter_forestry
    const findedAltDistrForestry = selectedPlanetable?.alter_data.alter_district_forestry
    if (findedAltForestry){
      setSelectedAltForestry(findedAltForestry)
    }
    if (findedAltDistrForestry) {
      setSelectedAltDistrictForestry(findedAltDistrForestry)
    }
  }, [])

  //если выбрано альтернативное уч. лесничество то грузим список кварталов
  useEffect(() => {
    selectedAltDistrictForestry
      && dispatch(getAltDistrictForestryWithQuartersListThunk(selectedAltDistrictForestry.id))
  }, [selectedAltDistrictForestry])

  const fields = [
    {
      options: altForestry,
      label: "Лесничество",
      referenceLoading: referenceLoading,
      onChangeFunc: (_event, item) => {setSelectedAltForestry(() => item)},
      reference: 'altForestry',
      referenceData: selectedPlanetable?.region.id,
      disabled: false,
      value: selectedAltForestry
    },
    {
      options: altDistrictForestry,
      label: "Участковое лесничество",
      referenceLoading: referenceLoading,
      onChangeFunc: (_event, item) => {setSelectedAltDistrictForestry(() => item)},
      reference: 'altDistrictForestry',
      referenceData: selectedAltForestry?.id,
      disabled: !selectedAltForestry,
      value: selectedAltDistrictForestry
    },
  ]

  const actionButtonHandler = () => {
    const planetableSvg = document.getElementById('planetableSvg')
    planetableSvg.focus()
    if (quartersList.length <= 30) {
      dispatch(getQuartersFromMapThunk({
        region: selectedPlanetable.region.id,
        x_forestry: selectedAltForestry.id,
        x_district_forestry: selectedAltDistrictForestry.id,
        ids: quartersList.map(quarter => quarter.id)
      }, selectedPlanetable, zoom, scale))
    } else {
      dispatch(setSnack('error', 'Максимальное количество кварталов 30шт'))
    }
  }

  const handleCloseModal = () => {
    dispatch(setPlanetableSvgAttr({showLoadLayerModal: false}))
  }

  return (
    <ModalPage
      title={'Загрузить кварталы с карты'}
      handleClose={handleCloseModal}
      actionButtonHandler={actionButtonHandler}
      actionButtonName={'Сохранить'}
      actionButtonDisabled={!selectedAltDistrictForestry || !selectedAltForestry || !quartersList.length}
      customStyle={customModalWindowStyle}
    >
      <Stack spacing={2} py={2}>
        {fields.map(field => (
          <LocationPlotField key={field.reference} options={field.options} label={field.label}
                             referenceLoading={field.referenceLoading} onChangeFunc={field.onChangeFunc}
                             reference={field.reference} referenceData={field.referenceData} disabled={field.disabled}
                             value={field.value}/>
        ))}
        <QuartersByDistrictForestryAutocomplete quarters={AltDistrictForestryWithQuartersList?.x_quarters || []}
                                                setQuartersList={setQuartersList} quartersList={quartersList}
                                                selectedAltForestry={selectedAltForestry}
                                                selectedAltDistrictForestry={selectedAltDistrictForestry}
        />
      </Stack>
    </ModalPage>
  )
}

export default LoadLayerModal;
