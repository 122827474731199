import {useSelector} from "react-redux";
import StockpilesPhotosList from "./StockpilePhotosList/StockpilePhotosList";
import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Loading} from "../../Main/Map/Common/Loading";

const BottomBlock = ({iAmEditor}) => {
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const stockpileDataLoading = useSelector(state => state.stockpilesReducer.stockpileDataLoading)

  if (stockpileDataLoading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <Loading text={"Загрузка фото..."}/>
      </Box>
    )
  }

  return (
    <>
      {selectedStockpile && (
        <Box>
          <StockpilesPhotosList images={selectedStockpile.images} iAmEditor={iAmEditor}/>
        </Box>
      )}
      {!selectedStockpile && (
        <Stack direction={'row'} spacing={1}
               sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
          <>
            <InfoOutlinedIcon/>
            <Typography>Штабель не выбран</Typography>
          </>
        </Stack>
      )}
    </>
  )
}

export default BottomBlock;
