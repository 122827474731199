import {createRef, forwardRef} from 'react';
import {IconButton, ImageList, Modal, Stack, Typography} from "@mui/material";
import PhotosListItem from "./PhotosListItem/PhotosListItem";
import {blockTitle} from "../../rightPanelStyles";
import {useDispatch, useSelector} from "react-redux";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import FullPhotoModal from "./FullPhotoModal/FullPhotoModal";
import {cannotIDo} from "../../../Map/Common/tariffs";

const Photos = ({photos, objectId, savePhotosThunk, deletePhotoThunk, name}) => {
  const dispatch = useDispatch()
  const fullPhotoModalStatus = useSelector(state => state.rightPanelReducer.fullPhotoModal.status)
  const TransitionFullPhotoModal = forwardRef(() => <FullPhotoModal photos={photos} name={name}/>)
  const fileRef = createRef()
  const iAmReadOnly = !!cannotIDo.editorAction()

  const uploadPhotosHandler = (event) => {
    const fileUploaded = event.target.files
    dispatch(savePhotosThunk(fileUploaded, objectId))
  }

  const loadFileHandler = () => {
    fileRef.current.click()
  }

  return (
    <>
      <Stack direction={'row'} spacing={2} sx={{pt: .5, pb: .5}} alignItems={'center'}>
        <Typography style={blockTitle}>Фотографии</Typography>
        {!iAmReadOnly &&
          <>
            <input hidden accept="*/*" type="file" ref={fileRef} onChange={uploadPhotosHandler} multiple />
            <IconButton onClick={loadFileHandler}>
              <UploadOutlinedIcon style={{color: 'primary.main'}} fontSize={'small'}/>
            </IconButton>
          </>
        }
      </Stack>
      {photos.length ?
        <ImageList
          sx={{
            maxWidth: '100%',
          }}
          gap={8}
          rowHeight={80}
        >
          {photos.map((photo, i) => {
            const img = new Image();
            img.src = photo;
            return (
              <PhotosListItem key={photo.id} photo={photo} index={i} objectId={objectId}
                              deletePhotoThunk={deletePhotoThunk} readOnly={iAmReadOnly}/>
            )
          })}
        < /ImageList>
        :
          <Typography>Нет фотографий.</Typography>
      }
      <Modal open={fullPhotoModalStatus}>
        <TransitionFullPhotoModal/>
      </Modal>
    </>
  )
}

export default Photos;
