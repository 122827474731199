import {useLayoutEffect, useState} from 'react';
import ModalPage from "../../../Common/ModalPage";
import {useDispatch, useSelector} from "react-redux";
import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextPlotField from "./TextPlotField/TextPlotField";
import {CreatePlotThunk, setPlotDataAttr, updatePlotThunk} from "../../../../redux/reducers/plotDataReducer";
import PlotLocationFields from "../../../Common/RegionLocationFields/PlotLocationFields";
import {getNodeWorkspace} from "../Context/Projects/ProjectsCommon";
import {cuttingsAreasTemplate} from "../Context/CAs/CuttingsAreas";
import {geoJSONdefaultPrecision} from "../../Map/Common/Defaults";


// окно создания/редактирования деляны
const CreateUpdatePlotModal = ({title}) => {
  const selectedPlot = useSelector((state) => state.plotDataReducer.selectedPlot);
  const selectedProjectTreeNode = useSelector((state) => state.plotDataReducer.selectedProjectTreeNode);
  const organizationData = useSelector(state => state.organizationInfoReducer.organizationData)
  //const plotStatuses = ['Отведена', 'Задекларирована', 'В разработке', 'Разработана', 'Закрыта', 'В архиве']
  const plotStatuses = Object.keys(cuttingsAreasTemplate.styles).map(name => name)
  const [errors, setErrors] = useState({})
  const [plotObj, setPlotObj] = useState(
    {
      region: null,
      forestry: null,
      district_forestry: null,
      unique_location: null,
      quarter_number: null,
      allotments_numbers: null,
      number: null,
      name: null,
      square: null,
      status: 'Отведена',
    }
  )
  useLayoutEffect( () => {
    if (selectedPlot) {
      setPlotObj(
        {
          region: selectedPlot.region,
          forestry: selectedPlot.forestry,
          district_forestry: selectedPlot.district_forestry,
          unique_location: selectedPlot.unique_location,
          quarter_number: selectedPlot.quarter_number,
          allotments_numbers: selectedPlot.allotments_numbers,
          number: selectedPlot.number,
          name: selectedPlot.name,
          square: selectedPlot.square,
          status: selectedPlot.status,
        }
      )
    }
  }, [])

  const otherPlotFields = {
    quarter_number: {
      textHandler: setPlotObj,
      label: "Номер квартала",
      maxLength: 10,
      value: plotObj.quarter_number,
      field: 'quarter_number',
      type: 'number',
      errorMessage: errors.quarter_number,
      required: true,
    },
    allotments_numbers: {
      textHandler: setPlotObj,
      label: "Номера выделов",
      maxLength: 100,
      value: plotObj.allotments_numbers,
      field: 'allotments_numbers',
      type: 'text'
    },
    number: {
      textHandler: setPlotObj,
      label: "Номер деляны",
      maxLength: 25,
      value: plotObj.number,
      field: 'number',
      type: 'number',
      errorMessage: errors.number
    },
    name: {
      textHandler: setPlotObj,
      label: "Имя деляны",
      maxLength: 150,
      value: plotObj.name,
      field: 'name',
      type: 'text'
    },
    square: {
      textHandler: setPlotObj,
      label: "Площадь деляны",
      maxLength: 50,
      value: plotObj.square,
      field: 'square',
      type: 'number'
    },
  }

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setPlotDataAttr({showCreatePlotModal: false, showUpdatePlotModal: false, selectedPlot: null}))
  }
  const actionButtonHandler = () => {
    let requiredFields = Object.keys(otherPlotFields).filter(field => otherPlotFields[field].required)
      if (!selectedPlot) {
        validation(requiredFields) &&
        dispatch(CreatePlotThunk(
          {
            region: plotObj.region?.id,
            forestry: plotObj.forestry?.id,
            district_forestry: plotObj.district_forestry?.id,
            unique_location: plotObj.unique_location?.id,
            quarter_number: plotObj?.quarter_number,
            allotments_numbers: plotObj?.allotments_numbers,
            number: plotObj?.number,
            name: plotObj?.name,
            square: plotObj?.square,
            status: plotObj?.status,
            geometry: selectedProjectTreeNode.layer.toGeoJSON(geoJSONdefaultPrecision).geometry,
            rental_contract: getNodeWorkspace(selectedProjectTreeNode)['id'],
          }, selectedProjectTreeNode
        ))
      } else {
        validation(requiredFields) &&
        dispatch(updatePlotThunk(
          {
            region: plotObj.region?.id || null,
            forestry: plotObj.forestry?.id || null,
            district_forestry: plotObj.district_forestry?.id || null,
            unique_location: plotObj.unique_location?.id || null,
            quarter_number: plotObj?.quarter_number || null,
            allotments_numbers: plotObj?.allotments_numbers || null,
            number: plotObj?.number || null,
            name: plotObj?.name || null,
            square: plotObj?.square || null,
            status: plotObj?.status || null,
          }, selectedPlot.id
        ))
      }
  }

  const validation = (requiredFields) => {
    let errorsObj = {}
    requiredFields.forEach(field => {
      if (!plotObj[field]) {
        errorsObj[field] = 'Обязательное поле'
      }})
    if (!plotObj.status)  {errorsObj.status = 'Обязательное поле'}
    setErrors({...errorsObj})
    return !Object.keys(errorsObj).length
  }
  return (
    <ModalPage
      title={title}
      wrapTitle
      handleClose={handleClose}
      actionButtonHandler={actionButtonHandler}
      actionButtonName={'Сохранить'}
    >
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Справочники
      </Divider>
        <PlotLocationFields setDataObj={setPlotObj} dataObj={plotObj}/>
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Параметры деляны
      </Divider>
      <Grid spacing={3} sx={{mb: 3}} container>
        {Object.keys(otherPlotFields).map(field => (
          <Grid key={field} xs={4} md={4} lg={4} item>
            <TextPlotField textHandler={otherPlotFields[field].textHandler}
                           label={otherPlotFields[field].label}
                           type={otherPlotFields[field].type}
                           maxLength={otherPlotFields[field].maxLength}
                           value={otherPlotFields[field].value}
                           field={field}
                           required={otherPlotFields[field].required}
                           errorMessage={otherPlotFields[field].errorMessage}
            />
          </Grid>
        ))}
        <Grid xs={4} md={4} lg={4} item>
          <FormControl fullWidth size={'small'}
                       error={!!errors.status}
                       required={true}>
            <InputLabel>Статус деляны</InputLabel>
            <Select
              label="Статус деляны"
              value={plotObj.status || 'Отведена'}
              disabled={organizationData.using_1C}
              onChange={(e) => setPlotObj((prev) => {
                return {...prev, status: e.target.value}
              })}
              variant={'outlined'}
            >
              {plotStatuses.map((status, i) => (
                <MenuItem key={i} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </ModalPage>
  )
}

export default CreateUpdatePlotModal;
