import React, {memo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {stockpileSvgConfig} from "../svgConfig";
import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {coordCorrection} from "../../../../../svgComponentsHelpersFunctions/coordCorrection";

const Ruler = memo(({dragMode}) => {
  let dispatch = useDispatch()
  const zoom = useSelector(state => state.stockpilePhotoReducer.zoom)
  const scale = useSelector(state => state.stockpilePhotoReducer.scale)
  const ruler = useSelector(state => state.stockpilePhotoReducer.ruler)
  const ruler_viewed_index = useSelector(state => state.stockpilePhotoReducer.ruler_viewed_index)
  const ruler_viewed_point_index = useSelector(state => state.stockpilePhotoReducer.ruler_viewed_point_index)
  const ruler_selected_point_index = useSelector(state => state.stockpilePhotoReducer.ruler_selected_point_index)
  const ruler_selected_index = useSelector(state => state.stockpilePhotoReducer.ruler_selected_index)
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const mode = useSelector(state => state.stockpilePhotoReducer.mode)

  const {ruler_fill, selected_ruler_fill, ruler_point_fill, ruler_selected_point_fill, ruler_stroke_width,
    ruler_point_radius} = stockpileSvgConfig
  const isRulerSelected = ruler_selected_index !== null

  const mouseLeaveRulerHandler = () => {
    (!isRulerSelected && mode === 'view') && dispatch(setPhotoAttr({ruler_viewed_index: null}))
  }

  const mouseEnterRulerHandler = () => {
    (!isRulerSelected && mode === 'view') && dispatch(setPhotoAttr({ruler_viewed_index: 0}))
  }

  const mouseLeavePointHandler = () => {
    dragMode !== 'rulerPoint' && dispatch(setPhotoAttr({ruler_viewed_point_index: null}))
  }

  const mouseEnterPointHandler = (index) => {
    dragMode !== 'rulerPoint' &&  dispatch(setPhotoAttr({ruler_viewed_point_index: index}))
  }

  return (
    <>
      {ruler?.length > 0 &&
        <polyline
          onMouseLeave={mouseLeaveRulerHandler}
          onMouseEnter={mouseEnterRulerHandler}
          i={`ruler_0`}
          points={ruler.map(
            (point, i) =>
              `${(coordCorrection(point.x, zoom, scale))}, ${(coordCorrection(point.y, zoom, scale))}`).join(' ')}
          fill="none"
          stroke={ruler_viewed_index !== null || isRulerSelected ? selected_ruler_fill : ruler_fill}
          strokeWidth={ruler_stroke_width}
          markerStart="url(#arrow)"
          markerEnd="url(#arrow)"
        />
      }
      {ruler.length > 0 && ruler.map((point, i) => (
        <line
          key={i}
          x1={coordCorrection(point.x, zoom, scale)}
          y1={coordCorrection(0, zoom, scale)}
          x2={coordCorrection(point.x, zoom, scale)}
          y2={coordCorrection(selectedPhoto.image_height, zoom, scale)}
          stroke={ruler_point_fill}
          strokeDasharray="8 8"
          strokeWidth={3}
        />
      ))}
      {mode === 'ruler' && isRulerSelected && ruler.map((rulerPoint, index) => (
        <circle
          key={`rulerPoint_${index}`}
          i={`rulerPoint_${index}`}
          onMouseLeave={mouseLeavePointHandler}
          onMouseEnter={() => mouseEnterPointHandler(index)}
          cx={coordCorrection(rulerPoint.x, zoom, scale)}
          cy={coordCorrection(rulerPoint.y, zoom, scale)}
          r={ruler_viewed_point_index !== index ? ruler_point_radius  : ruler_point_radius + 2}
          fill={ruler_selected_point_index !== index ? ruler_point_fill : ruler_selected_point_fill}
          opacity={1}
        />
        ))
      }
    </>
  );
})

export default Ruler;