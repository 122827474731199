
const mapStateCP = {
  drawLayers: null, //for measuring
  selectionLayers: null, //for areas
  curBaseLayerL: null,
  curBaseLayerType: null,
  caSwitchStatus: true,
  spLayer: null,
  pillarsLayer: null,
  pillarsEnable: false,
  spEnable: false,
  infoPanelType: null,
  spdata: null,
  blind: {show: false, showLeftPanel: true, tablets2Layer: null, quarters2Layer: null}, //показывать шторку, топ панель (false - шторка), группы слоев
  hps: {HPS: null, STP: null, showHPS: false, showSTP: false, ca: null, prevca: null}, //инфа по деляне для делянового какошка
  fireCAsReport: false,
  showCAsReport: false,
  infoClick: {request: null, show: false, data: null},
  cuttingTask: {layer: null},
  shippingID: null,
  logout: false,
  tariffExpired: false,
  userData: {loading: false, isLoading: false, error: null},
}

export function initMapLayers(drawLayers, selectionLayers) {
  return {
    type: "MAPCP_INIT_MAP_LAYERS",
    drawLayers: drawLayers,
    selectionLayers: selectionLayers,
  }
}

export function refreshMap() {
  return {
    type: "MAPCP_REFRESH",
  }
}

/*export function fireCAsReport(fire) {
  return {
    type: "MAPCP_FIRE_CAS_REPORT",
    fire: fire,
  };
}

export function showCAsReport(show) {
  return {
    type: "MAPCP_SHOW_CAS_REPORT",
    show: show,
  };
}*/

export function setTariffExpired(expired) {
  return {
    type: 'MAPCP_SET_TARIFF_EXPIRED',
    expired: expired,
  }
}

export function elzMapReducerCP(state = mapStateCP, action) { // самый главный ресудер карты
  switch (action.type) {
    case "MAPCP_SET_BASE_LAYER":
      if (state.curBaseLayerL === action.layer) return state;
      return {...state, first: false, curBaseLayerL: action.layer, curBaseLayerType: action.ltype};
    /*case "MAPCP_SET_QUARTERS_LAYER":
      return {...state, quartersLayer:  action.layer};*/
    /*case "MAPCP_SET_TABLETS_LAYER":
      return {...state, tabletsLayer: action.layer};*/
    case "MAPCP_SET_TABLETS2_LAYERS":
      return {...state, blind: {...state.blind, tablets2Layer: action.layer, quarters2Layer: action.qlayers}};
    case "MAPCP_SET_SP_LAYER":
      return {...state, spLayer: action.layer};
    /*case "MAPCP_TOGGLE_TABLETS_LAYER":
      return {...state, tabletsEnabled: !state.tabletsEnabled};*/
    case "MAPCP_TOGGLE_SP_LAYER":
      return {...state, spEnable: !state.spEnable};
    case "MAPCP_INIT_MAP_LAYERS":
      return {
        ...state,
        map: action.map,
        drawLayers: action.drawLayers,
        selectionLayers: action.selectionLayers
      };
    case "MAPCP_REFRESH":
      return {...state};
    case "MAPCP_SET_DATE_FIRST":
      return {...state, datefirst: action.date};
    case "MAPCP_SET_DATE_LAST":
      return {...state, datelast: action.date};
    case "MAPCP_SET_INFO_PANEL_TYPE":
      if (state.infoPanelType === action.ptype) return state;
      return {...state, infoPanelType: action.ptype};
    case "MAPCP_SHOW_SP_INFO":
      if (state.data === action.data) return state;
      return {...state, spdata: action.data};
    case 'MAPCP_SET_CA':
      return {...state, hps: {...state.hps, ca: action.layer, HPS: null}}
    case 'MAPCP_SHOW_CA_PANE':
      return {...state, hps: {...state.hps, ca: action.layer, prevca: state.hps.ca, showHPS: false, HPS: null}}
    case 'MAPCP_SET_HPSHPS':
      return {...state, hps: {...state.hps, HPS: action.hps}}
    case 'MAPCP_TOGGLE_CA_HPS':
      return {...state, hps: {...state.hps, showHPS: !state.hps.showHPS}}
    case "MAPCP_SET_PILLARS_LAYER":
      if (state.pillarsLayer === action.layer) return state;
      return {...state, pillarsLayer: action.layer};
    case "MAPCP_TOGGLE_PILLARS_LAYER":
      if (state.pillarsEnable === action.pillarsEnable) return state;
      return {...state, pillarsEnable: !state.pillarsEnable};
    case 'MAPCP_FIRE_CAS_REPORT':
      return {...state, fireCAsReport: action.fire}
    case 'MAPCP_SHOW_CAS_REPORT':
      return {...state, showCAsReport: action.show}
    case 'MAPCP_INFO_CLICK_LOADING':
      return {...state, infoClick: {...state.infoClick, request: action.request, show: true}}
    case 'MAPCP_INFO_CLICK_SHOW':
      if (action.show)
        return {...state, infoClick: {...state.infoClick, show: true}}
      else
        return {...state, infoClick: {...state.infoClick, show: false, request: null, position: null, data: null}}
    case 'MAPCP_INFO_CLICK_DATA':
      return {...state, infoClick: {...state.infoClick, data: action.data}}
    case 'MAPCP_TOGGLE_BLIND_SHOW_STATUS':
      return {...state, blind: {...state.blind, show: !state.blind.show}}
    case 'MAPCP_TOGGLE_LEFT_RIGHT_PANEL_STATUS':
      return {...state, blind: {...state.blind, showLeftPanel: !state.blind.showLeftPanel}}
    case 'TOGGLE_CA_SWITCH':
      return {...state, caSwitchStatus: action.status}
    case 'SET_CUTTING_TASK_LAYER':
      return {...state, cuttingTask: {...state.cuttingTask, layer: action.layer}}
    case 'MACP_RESET_ALL':
      return {...mapStateCP}
    case 'MAPCP_SET_SHIPPING_ID':
      return {...state, shippingID: action.id}
    case 'MAPCP_SET_LOGOUT_STATUS':
      return {...state, logout: action.status}
    case 'MAPCP_SET_TARIFF_EXPIRED':
      return {...state, tariffExpired: action.expired}
    case 'MAPCP_SET_COMMON_ERROR':
      return {...state, userData: {...state.userData, error: action.error}}
    case 'MAPCP_RESET_COMMON_ERROR':
      return {...state, userData: {...mapStateCP.userData}}
    case 'MAPCP_COMMON_DATA_LOADING_COMPLETE':
      return {...state, userData: {loading: false, isLoading: true, error: null}}
    case 'MAPCP_START_LOADING_USER_DATA':
      return {...state, userData: {loading: true, isLoading: false, error: null}}
    case 'MAPCP_OPEN_CREATE_PILLAR_DIALOG':
      return {...state, pillarDialog: {data: action.data, editMode: action.editMode}}
    case 'MAPCP_CLOSE_PILLAR_DIALOG':
      return {...state, pillarDialog: {data: null, editMode: false, loading: false, loaded: false, quarters: [], allQuarters: []}}
    case 'MAPCP_SET_PILLAR_DIALOG_DATA':
      return {...state, pillarDialog: {...state.pillarDialog, data: {...state.pillarDialog.data, ...action.data}}}
    case 'MAPCP_SET_PILLAR_DIALOG_QUARTERS':
      return {...state, pillarDialog: {...state.pillarDialog, data: {...state.pillarDialog.data, quarters: action.data}}}
    case 'MAPCP_SET_PILLAR_DIALOG_LATLNG':
      return {...state, pillarDialog: {...state.pillarDialog, data: {...state.pillarDialog.data, coordinates: action.latlng}}}
    case 'MAPCP_SET_PILLAR_DIALOG_WORKSPACE':
      return {...state, pillarDialog: {...state.pillarDialog, data: {...state.pillarDialog.data, workspace: action.workspace}}}
    case 'MAPCP_SET_PILLAR_DIALOG_DISTANCE':
      return {...state, pillarDialog: {...state.pillarDialog, data: {...state.pillarDialog.data, distance: action.distance}}}
    default:
      return state;
  }
}

