import React, {useEffect, memo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {stockpileSvgConfig} from "../svgConfig";
import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {coordCorrection} from "../../../../../svgComponentsHelpersFunctions/coordCorrection";

const Outline = memo(({outline, index, dragMode}) => {
  const dispatch = useDispatch()
  const zoom = useSelector(state => state.stockpilePhotoReducer.zoom)
  const scale = useSelector(state => state.stockpilePhotoReducer.scale)
  const mode = useSelector(state => state.stockpilePhotoReducer.mode)
  const outline_selected_index = useSelector(state => state.stockpilePhotoReducer.outline_selected_index)
  const outline_selected_point_index = useSelector(state => state.stockpilePhotoReducer.outline_selected_point_index)
  const outline_viewed_index = useSelector(state => state.stockpilePhotoReducer.outline_viewed_index)
  const outline_viewed_point_index = useSelector(state => state.stockpilePhotoReducer.outline_viewed_point_index)
  const cuttingMode = useSelector(state => state.stockpilePhotoReducer.cuttingMode)

  const {outline_fill, outline_stroke, outline_opacity, editing_outline_opacity, outline_point_fill,
    outline_selected_point_fill, outline_point_radius} = stockpileSvgConfig

  const mouseLeavePolygonHandler = () => {
    (outline_selected_index === null && mode === 'view' && !cuttingMode)
    && dispatch(setPhotoAttr({outline_viewed_index: null}))
  }

  const mouseEnterPolygonHandler = () => {
    (outline_selected_index === null && mode === 'view' && !cuttingMode)
    && dispatch(setPhotoAttr({outline_viewed_index: index}))
  }

  const mouseLeavePointHandler = () => {
    (dragMode !== 'outlinePoint' && !cuttingMode) && dispatch(setPhotoAttr({outline_viewed_point_index: null}))
  }

  const mouseEnterPointHandler = (index) => {
    (dragMode !== 'outlinePoint' && !cuttingMode) && dispatch(setPhotoAttr({outline_viewed_point_index: index}))
  }

  return (
    <>
      <polygon
        onMouseLeave={mouseLeavePolygonHandler}
        onMouseEnter={mouseEnterPolygonHandler}
        i={`outline_${index}`}
        points={outline.map((point) => `${coordCorrection(point.x, zoom, scale)}, 
        ${coordCorrection(point.y, zoom, scale)}`).join(' ')}
        fill={outline_fill}
        stroke={outline_stroke}
        opacity={(outline_selected_index === index || outline_viewed_index === index)
          ? editing_outline_opacity
          : outline_opacity }
      />
      {mode === 'outline' && outline_selected_index === index &&
        outline.map((point, pointIndex) => (
        <circle
          onMouseLeave={mouseLeavePointHandler}
          onMouseEnter={() => mouseEnterPointHandler(pointIndex)}
          key={`outlinePoint_${pointIndex}`}
          i={`outlinePoint_${pointIndex}`}
          cx={coordCorrection(point.x, zoom, scale)}
          cy={coordCorrection(point.y, zoom, scale)}
          r={outline_viewed_point_index === pointIndex ? outline_point_radius + 2 : outline_point_radius}
          fill={outline_selected_point_index !== pointIndex ? outline_point_fill : outline_selected_point_fill}
          opacity={1}
        />
      ))}
    </>
  );
})

export default Outline;