import React from 'react';
import {Divider, Stack} from "@mui/material";
import {useSelector} from "react-redux";
import SelectModeButtons from "./ToolbarButtons/SelectModeButtons/SelectModeButtons";
import EditFigureButtons from "./ToolbarButtons/EditFigureButtons/EditFigureButtons";
import EditRulerButtons from "./ToolbarButtons/EditRulerButtons/EditRulerButtons";
import EditTimbersButtons from "./ToolbarButtons/EditTimbersButtons/EditTimbersButtons";
import LayerCheckboxes from "./ToolbarButtons/LayerCheckboxes/LayerCheckboxes";
import ZoomSlider from "./ToolbarButtons/ZoomSlider/ZoomSlider";
import PhotoButtons from "./ToolbarButtons/PhotoButtons/PhotoButtons";
import {toolbarContainer, toolbarDividerStyle} from "./toolbarStyles";


const Toolbar = () => {
  const mode = useSelector(state => state.stockpilePhotoReducer.mode)
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)


  if (!selectedPhoto) return ''
  return (
      <Stack spacing={1} direction={'row'} sx={toolbarContainer}>
        <SelectModeButtons/>
        <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
        {mode === 'outline' &&
          <>
            <EditFigureButtons/>
            <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
          </>
        }
        {mode === 'ruler' &&
          <>
            <EditRulerButtons/>
          <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
          </>
        }
        {/*{mode === 'timbers' &&*/}
        {/*  <>*/}
        {/*    <EditTimbersButtons/>*/}
        {/*    <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>*/}
        {/*  </>*/}
        {/*}*/}
        <PhotoButtons/>
        <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
        <ZoomSlider/>
        <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
        <LayerCheckboxes/>
        <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
      </Stack>
  )
};

export default Toolbar;