import {useDispatch} from "react-redux";
import {Stack, TextField, Typography} from "@mui/material";
import {blockTitle} from "../rightPanelStyles";
import Button from "@mui/material/Button";
import {insertViolationDescriptionThunk} from "../../../../redux/reducers/rightPanelReducer";
import {useEffect, useState} from "react";

const ViolationDescription = ({violationId, description, iAmEditor}) => {
  const dispatch = useDispatch()
  const [commentText, setCommentText] = useState(description)

  useEffect(() => {
    setCommentText(description)
  }, [description])

  const changeCommentHandler = (e) => {
    setCommentText(e.target.value)
  }

  const saveCommentHandler = () => {
    dispatch(insertViolationDescriptionThunk(commentText, violationId))
  }

  return (
    <>
      <Typography style={blockTitle}>Описание нарушения</Typography>
      {iAmEditor
        ?
        <>
          <TextField
            size={"small"}
            multiline
            value={commentText}
            maxRows={4}
            inputProps={{maxLength: 255}}
            onChange={changeCommentHandler}
          />
          <Stack direction={"row"} spacing={2} sx={{pt: 0.5, pb: 0.5}}>
            <Button
              size={"small"}
              variant={"contained"}
              onClick={saveCommentHandler}
            >
              Сохранить
            </Button>
          </Stack>
        </>
        :
        <Typography>{commentText}</Typography>
      }
    </>
  )
}

export default ViolationDescription;
