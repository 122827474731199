import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import {tableTextStyle} from "../../../rightbarStyles";
import {rightBarText} from "../../../../stockpilePageStyles";


const ViewedFigureDataTable = ({viewedFigure}) => {

  const figureParams = {
    volume: {value: viewedFigure?.volume, label: 'Объем'},
    species: {value: viewedFigure?.species, label: 'Порода'},
    length: {value: viewedFigure?.length, label: 'Длина (м)'},
    variety: {value: viewedFigure?.variety, label: 'Сортимент'},
    rate: {value: viewedFigure?.rate, label: 'Коэффициент'},
  }

  return (
    <TableContainer sx={{width: '18vw'}}>
      <Table size={"small"} sx={{p: 0, [`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
        <TableBody>
          {Object.keys(figureParams).map((key, i) => (
            <TableRow key={i} sx={{height: '40px'}}>
              <TableCell style={{width: '20%'}}>
                <Typography sx={rightBarText}>{figureParams[key].label}</Typography>
              </TableCell>
              <TableCell style={{width: '80%'}}>
                <Typography sx={rightBarText}>{figureParams[key].value?.name || figureParams[key].value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewedFigureDataTable;