import {Box, Button, Divider, Stack} from "@mui/material";
import Characteristics from "../RPCommonElements/Characteristics/Characteristics";
import Comment from "../RPCommonElements/Comment/Comment";
import Photos from "../RPCommonElements/Photos/Photos";
import LastChange from "../RPCommonElements/LastChange/LastChange";
import {useSelector} from "react-redux";
import {
  deleteRoadPhotoThunk,
  saveRoadCommentThunk,
  saveRoadPhotosThunk
} from "../../../../redux/reducers/rightPanelReducer";
import RoadStatus from "./Status/RoadStatus";
import {cannotIDo} from "../../Map/Common/tariffs";
import React from "react";
import {dispatch} from "../../../Common/misc_functions";
import {
  showCoordinatesEditor
} from "../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {flyToPolygon} from "../../Map/Common/fly";
import {saveRoadOnServer, searchRoadByID} from "../../LeftPanel/Context/Roads/roads";
import {clearCoordinatesDialogInfo} from "../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";

const RpRoadData = () => {
  const roadData = useSelector(state => state.rightPanelReducer.roadData)
  const {comment: commentObj, photos, id: roadDataId, geometry} = roadData || {};
  const iAmReadOnly = !!cannotIDo.editorAction()

  function showCoordinatesEditorWindow() {
    const layer = searchRoadByID(roadDataId)
    if (layer) {
      dispatch(showCoordinatesEditor(
        layer.getLatLngs(),
        'Line',
        (latlngs) => {
          layer.setLatLngs(latlngs)
          if (layer.elz_blind_layer)
            layer.elz_blind_layer.setLatLngs(latlngs)
          saveRoadOnServer(layer)
          flyToPolygon(layer.getLatLngs(), true)
          clearCoordinatesDialogInfo()
        },
        layer.elz_properties.name,
      ))
    }
  }

  return (
    <Box sx={{height: '100%', overflowY: 'auto'}}>
      <Stack spacing={1} sx={{pt: 1, pb: 1}}>
        <Button size={'small'} variant={'outlined'} fullWidth onClick={showCoordinatesEditorWindow}>
          Координаты
        </Button>
        <Divider />
        <RoadStatus readOnly={iAmReadOnly}/>
        <Characteristics geometry={geometry}/>
        <Divider orientation={"horizontal"}/>
        <Comment commentObj={commentObj} objectId={roadDataId} saveCommentThunk={saveRoadCommentThunk}/>
        <Divider orientation={"horizontal"}/>
        <Photos photos={photos || []} objectId={roadDataId} savePhotosThunk={saveRoadPhotosThunk}
                deletePhotoThunk={deleteRoadPhotoThunk} name={name}/>
        <Divider orientation={"horizontal"}/>
        <LastChange objectData={roadData}/>
      </Stack>
    </Box>
  )
}

export default RpRoadData;
