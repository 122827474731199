import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {CustomTabPanel} from "../../../CustomTabPanel";
import {EmployeesDialogTab} from "./EmployeesTable";
import {CASPermissionsTable} from "./CASPermissionsTable";
import {useDispatch, useSelector} from "react-redux";
import {saveActionSelectText, saveString3} from "../../../../Main/Map/Common/Strings_RU";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";
import {treeButtonDialog} from "../../../org_page_api";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function BasicTabs({workspace, handleSave}) {
  const changesInDialog = useSelector(state => state.organizationPageReducer.changesInDialog)
  const dialogSaving = useSelector(state => state.organizationPageReducer.dialogSaving)
  const curTab = useSelector(state => state.organizationPageReducer.curTab)
  const dispatch = useDispatch()

  function tabChange(event, newValue) {
    if (dialogSaving) {
      dispatch(setSnack('warning', 'Пожалуйста, дождитесь сохранения на текущей вкладке.'))
      return;
    }
    if (changesInDialog !== null) {
      treeButtonDialog(saveString3, saveActionSelectText, (value) => {
        if (value === 'Сохранить') {
          handleSave()
        } else {
          if (value === 'Не сохранять') {
            if (curTab === 0) { //revert workspaces
              dispatch(changeOrgPageAttrs({
                selectedItems: [...workspace.users],
                dialogSaving: false,
                dialogSavingError: false,
                changesInDialog: null,
                curTab: newValue,
              }))
            } else {
              if (curTab === 1) {//revert cas permissions
                const oldEmployeesPermissions = window.store.getState().organizationPageReducer.oldData;
                dispatch(changeOrgPageAttrs({
                  dialogSaving: false,
                  dialogSavingError: false,
                  changesInDialog: null,
                  employeesCasPermissions: structuredClone(oldEmployeesPermissions),
                  //curTab: newValue,
                }))
              }
            }
          }
        }
      })
    } else {
      dispatch(changeOrgPageAttrs({curTab: newValue}))
    }
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={curTab} onChange={tabChange}>
          <Tab label="Сотрудники" {...a11yProps(0)} />
          <Tab label="Доступ к делянам" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={curTab} index={0}>
        <EmployeesDialogTab workspace={workspace}/>
      </CustomTabPanel>
      <CustomTabPanel value={curTab} index={1}>
        <CASPermissionsTable workspace={workspace}/>
      </CustomTabPanel>
    </Box>
  )
}
