import {dataAPI} from "../../../../../api/api";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {HttpStatusCode} from "axios";

const SET_EDIT_VALUE = "PROJECTS_SET_EDIT_VALUE";
const REFRESH_PANEL = "PROJECTS_REFRESH_PANEL";
const SET_DRAW_MODE = "PROJECTS_SET_DRAW_MODE";
const SET_SHOW_MAP_DRAWING_PANEL = "PROJECTS_SET_SHOW_MAP_DRAWING_PANEL";
const SET_PROJECTS_ATTR = "SET_PROJECTS_ATTR";
const SET_LOADING = "SET_LOADING";

const defaultState = {
  eemode: null,
  editValue: "",
  drawMode: null,
  currentCollection: null,
  showMapDrawingPanel: true,
  selectedNode: null,
  selectedObjectId: null,
  isLoading: false,
}

// Reducer
export function projectsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_EDIT_VALUE:
      return { ...state, editValue: action.value };
    case REFRESH_PANEL:
      return { ...state };
    case SET_DRAW_MODE:
      return {
        ...state,
        drawMode: action.mode,
        currentCollection: action.collection,
      };
    case SET_SHOW_MAP_DRAWING_PANEL:
      return { ...state, showMapDrawingPanel: action.show };
    case SET_PROJECTS_ATTR:
      return { ...state, ...action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.state };
    default:
      return state;
  }
}

// Action Creators
/*export const setProjectsAttr = (payload) => ({
  type: SET_PROJECTS_ATTR,
  payload,
})*/

export const refreshProjects = () => ({ type: REFRESH_PANEL })

export const setDrawMode = (mode, collection) => ({
  type: SET_DRAW_MODE,
  mode,
  collection,
})

export const setShowMapDrawingPanel = (show) => ({
  type: SET_SHOW_MAP_DRAWING_PANEL,
  show,
})

// Управляет индикацией загрузки.
export const setLoading = (state) => ({ type: SET_LOADING, state })

/** 
 * Асинхронный метод для загрузки данных для Wialon с сервера.
 * @param {Object} data - Данные для отправки, включая даты начала и окончания.
 * @param {string} data.start_date - Дата начала периода (в формате "гггг-мм-дд").
 * @param {string} data.end_date - Дата окончания периода (в формате "гггг-мм-дд").
 * @param {string} type - Тип объекта (дороги, деляны).
 */
export const getWialonThunk = (data, type) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      let result;

      switch(type) {
        case "roads":
          result = await dataAPI.projects.getWialonRoadsData(data);
          break;
        case "plots":
          result = await dataAPI.projects.getWialonPlotsData(data);
          break;
        case "warehouses":
          result = await dataAPI.projects.getWialonWarehousesData(data);
          break;
      }

      // Обработка успешного ответа.
      const blob = new Blob([result.data], { type: "application/xml" });
      const url = URL.createObjectURL(blob);
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "_");
      const link = document.createElement("a");
      link.href = url;
      link.download = `export_${type}_${formattedDate}.kml`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error.response.status === HttpStatusCode.NotFound) {
        dispatch(setSnack("warning", "Нет данных на выбранном периоде."));
      } else {
        handleErrors(dispatch, error);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
}
