import React from 'react';
import {useSelector} from "react-redux";
import {tableCellClasses} from "@mui/material/TableCell";
import {Table, TableBody, TableContainer, TableCell, TableRow, Typography} from "@mui/material";
import {rightBarText} from "../../stockpilePageStyles";

const StockpileData = ({selectedPhoto}) => {
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  if (!selectedStockpile) return ''

  let sumVolumes = selectedStockpile.images.map(image => image.image_volume).reduce((accumulator, currentValue) =>
    accumulator + currentValue, 0).toFixed(2)
  let sumTimbers = selectedStockpile.images.map(image => image.timbers_count).reduce((accumulator, currentValue) =>
    accumulator + currentValue, 0)

  const stockpileDataTableRowsData = [
    {label: 'Объем по штабелю ', value: sumVolumes, },
    {label: 'Бревен в штабеле (шт)', value: sumTimbers, },
    {label: 'Количество фото (шт)', value: selectedStockpile.images.length, },
    {label: 'Добавил фото', value: (selectedStockpile.images.find(image => image.id === selectedPhoto?.id ) || {}).created_by || "-"},
    {label: 'Дата добавления', value: (selectedStockpile.images.find(image => image.id === selectedPhoto?.id ) || {}).created_at || "-"},
  ]

  return (
    <TableContainer sx={{width: '100%', height: '100%'}}>
      <Table size={"small"} sx={{p: 0, [`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
        <TableBody>
          {stockpileDataTableRowsData.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{display: 'flex'}}>
                <Typography sx={rightBarText}>
                  {row.label}
                </Typography>
                {row.label === 'Объем по штабелю ' && <Typography sx={rightBarText}>(м&sup3;)</Typography>}
              </TableCell>
              <TableCell>
                <Typography sx={{...rightBarText, fontWeight: 700}}>{row.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StockpileData;