import {ReactComponent as CrwonSVG} from '../../../../../Main/Map/Common/svg/crown.svg';
import {Stack} from "@mui/material";

export function OwnerCrownIcon() {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      <CrwonSVG />
      <span style={{lineHeight: '20px', paddingTop: '3px'}}>Владелец</span>
    </Stack>
  )
}
