import {Divider, IconButton, Stack, Typography, useTheme} from "@mui/material";
import {ReactComponent as LineIcon} from '../../Map/Common/svg/line.svg';
import {ReactComponent as PolygonIcon} from '../../Map/Common/svg/polygon.svg';
import CloseIcon from '@mui/icons-material/Close';
import {getMap1} from "../../Map/GlobalObjects";
import {useDispatch, useSelector} from "react-redux";
import {clearMeasuringPanel, setAreaAndDistance, setDrawingMode} from "./measuringReducer";
import {removeAllMeasuringFromDRGroup, resetAllDrawing} from "../../Map/Common/Drawing/drawing";
import {closeString, measuringAreaString, measuringDistanceString} from "../../Map/Common/Strings_RU";

const defaultShapeColor = '#aaaa00';

export const measuringDrawStyle = {
  tooltips: false,
  continueDrawing: false,
  templineStyle: {color: defaultShapeColor},
  hintlineStyle: {color: defaultShapeColor},
  pathOptions: {color: defaultShapeColor},
}

export function MeasuringPanel(props) {
  const theme = useTheme()
  const state = useSelector(state => state.measuringReducer)
  const dispatch = useDispatch()

  function getAreaAndDistance(layer, type = 0) {
    let latlngs = layer.getLatLngs();
    if (layer.pm._shape === 'Polygon') {
      if (type === 0)
        latlngs = layer.getLatLngs()[0];
    }
    let area = 0;
    if (window.store.getState().measuringReducer.drawMode !== 'Line')
      area = L.GeometryUtil.geodesicArea(latlngs);

    let distance = 0;
    let prevPoint = null;
    latlngs.map(point => {
      if (prevPoint)
        distance += prevPoint.distanceTo(point)
      prevPoint = point;
    })
    return {area: (area / 10000).toFixed(3), distance: (distance / 1000).toFixed(3)}
  }

  function pmCreateHandler(e) {
    const map = getMap1()
    map.pm.disableDraw()
    e.layer.options.isMeasuring = true;
    const dimentions = getAreaAndDistance(e.layer)
    dispatch(setAreaAndDistance(dimentions.area, dimentions.distance))
    dispatch(setDrawingMode(null))
  }

  function pmVertexAdded(e) {
    if (e.layer.getLatLngs().length > 1) {
      const dimentions = getAreaAndDistance(e.layer, 1)
      dispatch(setAreaAndDistance(dimentions.area, dimentions.distance))
    }
  }

  function handleDrawButton(shapeType) {
    const map = getMap1()
    map.pm.disableDraw()
    map.off('pm:create')
    removeAllMeasuringFromDRGroup()
    resetAllDrawing(dispatch)
    if (state.drawMode) {
      dispatch(setDrawingMode(null))
    } else {
      switch (shapeType) {
        case 'Line':
          map.on('pm:create', pmCreateHandler)
          map.once('pm:drawstart', ({workingLayer}) => {
            workingLayer.on('pm:vertexadded', pmVertexAdded);
          })
          map.pm.enableDraw('Line', measuringDrawStyle)
          break;
        case 'Polygon':
          map.on('pm:create', pmCreateHandler)
          map.once('pm:drawstart', ({workingLayer}) => {
            workingLayer.on('pm:vertexadded', pmVertexAdded);
          })
          map.pm.enableDraw('Polygon', measuringDrawStyle)
          break;
      }
      dispatch(clearMeasuringPanel())
      dispatch(setDrawingMode(shapeType))
    }
  }

  const lineButtonEnable = !state.drawMode || state.drawMode !== 'Polygon';
  const polyButtonEnabe = !state.drawMode || state.drawMode !== 'Line';

  return (
    <Stack flexDirection={'column'} style={{padding: 5, minWidth: 200}}>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Stack style={{margin: 0}} flexDirection={'row'}>
          <IconButton
            title={measuringDistanceString}
            onClick={() => handleDrawButton('Line')}
            disabled={!lineButtonEnable}
            id={'draw_line_button'}
            style={{padding: 0}}
          >
            <LineIcon style={{fill: lineButtonEnable ? theme.palette.primary.main : theme.palette.action.disabled}}
                      fontSize={'small'}/>
          </IconButton>
          <IconButton
            title={measuringAreaString}
            onClick={() => handleDrawButton('Polygon')}
            disabled={!polyButtonEnabe}
            id={'draw_polygon_button'}
            style={{padding: 0}}
          >
            <PolygonIcon style={{stroke: polyButtonEnabe ? theme.palette.primary.main : theme.palette.action.disabled}}
                         fontSize={'small'}/>
          </IconButton>
        </Stack>
        <IconButton onClick={props._onClose} style={{marginTop: '-1vh'}} disableRipple title={closeString}>
          <CloseIcon fontSize={'small'}/>
        </IconButton>
      </Stack>
      <Divider flexItem style={{margin: '1vh 0 1vh 0'}}/>
      <Stack flexDirection={'column'} style={{paddingLeft: '1vh'}}>
        <Stack flexDirection={'row'}>
          <Typography variant={'subtitle2'}>{`Длина: ${state.distance}`} км.</Typography>
        </Stack>
        <Stack flexDirection={'row'}>
          <Typography variant={'subtitle2'}>{`Площадь: ${state.area}`} га.</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
