import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useState} from "react";
import {Divider, IconButton, Menu, MenuItem} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {treeItemButtonIconSize, treeItemButtonStyle} from "../../../Map/Common/Styles";
import {coordinatesString, deleteString, renameString} from "../../../Map/Common/Strings_RU";
import {deleteRoadFromWorkspaceWithDialog, roadCoordinatesDialog} from "./roads";
import {refreshRoadsTree} from "./roadsReducer";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

export function RoadContextMenu({layer}) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const menuOpen = Boolean(menuAnchor)
  const dispatch = useDispatch()

  function startRenameRoad(road) {
    road.elz_rename = true;
    dispatch(refreshRoadsTree())
  }

  // noinspection JSValidateTypes
  return (
    <>
      <IconButton
        disableRipple
        size={'small'}
        style={{...treeItemButtonStyle, padding: 0}}
        onClick={(e) => {
          e.stopPropagation()
          setMenuAnchor(e.currentTarget)
        }}
      >
        <MoreVertIcon fontSize={'small'} style={treeItemButtonIconSize}/>
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setMenuAnchor(null)
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            startRenameRoad(layer)
          }}
        >
          <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{renameString}</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            roadCoordinatesDialog(layer)
          }}
        >
          <RoomOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{coordinatesString}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setMenuAnchor(null)
            deleteRoadFromWorkspaceWithDialog(layer)
          }}
        >
          <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{deleteString}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
