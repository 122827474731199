import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import {toolbarIconButtonStyle} from "../../../toolbarStyles";
import {useSelector} from "react-redux";
import {urlModify} from "../../../../../../utils/ulrModify";
import {saveImageLocally} from "../../../../../../utils/saveImageLocally";
const DownloadPhotoButton = () => {
  const imageURL = useSelector(state => state.stockpilePhotoReducer.selectedPhoto.image)
  let formattedPhotoUrl = urlModify(imageURL)
  return (
    <IconButton title={'Скачать фото'}
                sx={toolbarIconButtonStyle}
                disableRipple
                size={'small'}
                onClick={() => saveImageLocally(formattedPhotoUrl)}
    >
      <DownloadIcon/>
    </IconButton>
  );
};

export default DownloadPhotoButton;
