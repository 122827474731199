import Draggable from "react-draggable";
import {bodyStyle, closeButtonStyle, headerStyle, headerTextStyle, paneStyle} from "../Map/Common/Styles";
import {Box, Button, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const OverlayDraggablePane = (props) => {
  const headerText = props.headerText;
  const closeAction = props.closeAction;
  const propStyle = props.style || {}

  return (
    <Draggable handle={'#overlay_pane_header'} bounds={{top: 0, left:0}}>
      <Box sx={{...paneStyle, ...propStyle}} >
        <Box id='overlay_pane_header' style={headerStyle}>
          <Typography style={headerTextStyle} className={'draggable'}>{headerText}</Typography>
          <Button style={closeButtonStyle}
                  color="secondary" size="small"
                  onClick={closeAction}
                  onTouchStart={closeAction}
          >
            <CloseIcon sx={{height: '14px'}}/>
          </Button>
        </Box>
        <Box style={bodyStyle}
        >
          {props.children}
        </Box>
      </Box>
    </Draggable>
  )
}
