import {getCuttingAreas} from "./CuttingsAreas";
import L from "leaflet";
import {getPlotStockpilePopup, prepareToOpenPopup} from "../../../Map/MapPopup/popupFunctions";
import {changeMapPopup, showLoadingPopup} from "../../../Map/MapPopup/mapPopup";
import {otherDataAPI} from "../../../../../api/api";
import {cannotIDo} from "../../../Map/Common/tariffs";
import {urlGenerator} from "../../../../../utils/urlGenerator";
import {getStockpileThunk, setStockpilesAttr} from "../../../../../redux/reducers/stockpilesReducer";
import {disableModeAndUndoChanges, setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";

const stockpileStyle = {
  color: 'darkred',
  fillColor: 'orange',
  opacity: 0.5,
  fillOpacity: 1,
  weight: 1,
  pane: 'cas_stockpiles',
}

/**
 * Обработчик клика по штебелю
 * @param event {{latlng, target}}
 */
function stockpileClickHandler(event) {
  if (!prepareToOpenPopup(event))
    return;

  showLoadingPopup(event.latlng);

  const layer = event.target;

  otherDataAPI.stockpiles.getStockpilePopupInfo(layer.options.id).then(res => {
      const interactive = {}
      if (!cannotIDo.useStockpilesModule()) {
        interactive.ca_popup_full_info_button = {
          type: 'click',
          f: () => {
            const dispatch = window.elz_dispatch;
            dispatch(setStockpilesAttr({selectedPlot: layer.options.ca_id}))
            dispatch(setPhotoAttr({selectedPhoto: null}))
            dispatch(disableModeAndUndoChanges())
            dispatch(getStockpileThunk(layer.options.id))
            window.elz_navigate(urlGenerator().stockpiles)
          }
        }
      }

      const html = getPlotStockpilePopup(res.data, interactive)
      changeMapPopup(html, interactive)
    }
  ).catch(err => {
    const dispatch = window.elz_dispatch;
    handleErrors(dispatch, err)
  })
}

function createStockpileInCA(ca, feature, group) {
  const coordinates = feature.geometry.coordinates[0].map(co => [co[1], co[0]])
  const lay = L.polygon(coordinates, stockpileStyle)
  lay.options.id = feature.id; //необходимо для открытия модуля штаблей
  lay.options.ca_id = ca.options.id; //необходимо для открытия модуля штаблей
  lay.on('click', stockpileClickHandler)
  ca.elz_stockpiles.push(lay)
  group.addLayer(lay)
}

/**
 * Создает штабеля для деляны.
 * Добавляет их в cuttingsAreas.stockpilesGroup.
 * Добавляет их в layer.elz_stockpiles.
 * @param ca {{elz_stockpiles: Array, options: object, elz_properties: object}} - слой деляны
 * @param stockpiles {Array} - массив фичей штабелей с бекенда
 */
export function createCaStockpiles(ca, stockpiles) {
  const group = getCuttingAreas().stockpilesGroup;
  ca.elz_stockpiles = []
  //Добавляем штабли токо для неархивных делян
  //При создании деляны из полигона stockpiles === undefined
  if (ca.elz_properties.status !== "В архиве" && stockpiles) {
    stockpiles.filter(data => data.geometry !== null).forEach(stockpile => {
      createStockpileInCA(ca, stockpile, group)
    })
  }
}

/**
 * Убирает все штабеля деляны с карты
 * @param ca {{elz_stockpiles: L.LayerGroup}} - слой деляны
 */
export function removeCaStockPilesFromMap(ca) {
  const group = getCuttingAreas().stockpilesGroup;
  ca.elz_stockpiles.forEach(stockpileLayer => {
    group.removeLayer(stockpileLayer)
  })
}

/**
 * Добавляет все штабеля деляны на карту
 * @param ca {{elz_stockpiles: L.LayerGroup}} - слой деляны
 */
export function addCaStockPilesToMap(ca) {
  const group = getCuttingAreas().stockpilesGroup;
  ca.elz_stockpiles.forEach(stockpileLayer => {
    group.addLayer(stockpileLayer)
  })
}

export function searchCALayerByID(id) {
  const cas = getCuttingAreas()
  if (cas?.layerArray) {
    return cas.layerArray.find(layer => layer.elz_properties.id === id)
  }
  return undefined;
}

/**
 * Создает штабель из feature с бекенда и добавляет его в деляну (для создания из редактора)
 */
export function createCAStockpile(caID, feature) {
  const caLayer = searchCALayerByID(caID)
  if (caLayer) {
    createStockpileInCA(caLayer, feature, getCuttingAreas().stockpilesGroup)
  }
}

export function deleteCAStockpile(caID, stockpileID) {
  const caLayer = searchCALayerByID(caID)
  const cas = getCuttingAreas()
  if (caLayer) {
    const ind = caLayer.elz_stockpiles.findIndex(stockpile => stockpile.options.id === stockpileID)
    if (ind !== -1) {
      const stock = caLayer.elz_stockpiles[ind]
      cas.stockpilesGroup.removeLayer(stock)
      caLayer.elz_stockpiles.splice(ind ,1)
    }
  }
}
