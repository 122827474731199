import React, {useEffect, useState} from 'react';
import {modalWindowStyle} from "../../Common/Styles";
import ModalPage from "../../Common/ModalPage";
import {useDispatch, useSelector} from "react-redux";
import {
  getBillThunk,
  sendPaymentSumThunk,
  setPaymentAttr
} from "../../../redux/reducers/paymentReducer";
import {blockContainerStyle, textStyle} from "../organizationPageStyles";
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography, Stack, Checkbox, Link} from "@mui/material";


const getNextMonthDate = (startDate) => {
  const nextMonthDate = new Date(startDate);
  nextMonthDate.setMonth(startDate.getMonth() + 1);
  const day = String(nextMonthDate.getDate()).padStart(2, '0');
  const month = String(nextMonthDate.getMonth() + 1).padStart(2, '0'); // Месяцы в JavaScript нумеруются с 0
  const year = nextMonthDate.getFullYear();
  return `${day}.${month}.${year}`;
}

const getNextYearDate = (startDate) => {
  const nextYearDate = new Date(startDate);
  nextYearDate.setFullYear(startDate.getFullYear() + 1);
  const day = String(nextYearDate.getDate()).padStart(2, '0');
  const month = String(nextYearDate.getMonth() + 1).padStart(2, '0'); // Месяцы в JavaScript нумеруются с 0
  const year = nextYearDate.getFullYear();
  return `${day}.${month}.${year}`;
}

function createDateFromFormat(dateString) {
  const parts = dateString.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Месяцы в объекте Date начинаются с 0 (январь - 0, февраль - 1 и т.д.)
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
}

//модальное
const PaymentWindow = () => {
  const dispatch = useDispatch()
  const organizationData = useSelector(state => state.organizationInfoReducer.organizationData)
  const showPaymentWindow = useSelector(state => state.paymentReducer.showPaymentWindow)
  const {tariff: currentTariff, is_active, paid_to} = organizationData
  const [paymentRule, setPaymentRule] = useState({period: null, sum: null})
  const [agreementCheckbox, setAgreementCheckbox] = useState(false)
  const [bill, setBill] = useState(null)
  const [billIsLoading, setBillIsLoading] = useState(false)
  const startDate = is_active ? createDateFromFormat(paid_to) : new Date();
  const nextYearDate = getNextYearDate(startDate)
  const nextMonthDate = getNextMonthDate(startDate)

  // получаем пдф счета и открываем его в новой вкладке браузера
  useEffect(() => {
    if (bill) {
      const pdfBlob = new Blob([bill], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
      setBill(null)
      handleCloseModal()
    }
  }, [bill])

  const paymentRulesRadioGroupHandler = (period, price) => {
    setPaymentRule({period: period, sum: price})
  }

  const handleCloseModal = () => {
    dispatch(setPaymentAttr({showPaymentWindow: {status: false, type: 'none'}}))
  }

  const paymentSumConfirmation = () => {
    showPaymentWindow.type === 'card' && dispatch(sendPaymentSumThunk(paymentRule.sum, paymentRule.period))
    showPaymentWindow.type === 'bill' && dispatch(getBillThunk(paymentRule.period, setBill, setBillIsLoading))
  }

  const CheckboxHandler = () => {
    setAgreementCheckbox(prev => !prev)
  }

  const offerClickHandler = (e) => {
    e.preventDefault()
    window.open('https://www.delyana.ru/offer', '_blank')
  }

  return (
    <ModalPage
      title={showPaymentWindow.type === 'card' ? "Внести оплату" : "Получить счет на оплату"}
      handleClose={handleCloseModal}
      actionButtonHandler={paymentSumConfirmation}
      actionButtonName={showPaymentWindow.type === 'card' ? 'Перейти к оплате' : 'Получить счет'}
      actionButtonDisabled={!paymentRule.sum || billIsLoading || !agreementCheckbox}
      customStyle={{...modalWindowStyle}}
    >
      <Stack spacing={2} sx={{mt: 2, mb: 2}}>
        <Typography sx={{fontWeight: 700}}>{`Ваш тариф "${currentTariff.name}". Выберите вариант оплаты:`}</Typography>

        <Box sx={{...blockContainerStyle}}>
          <FormControl>
            <RadioGroup>
              <FormControlLabel value={currentTariff.price_per_month} control={<Radio/>}
                                checked={paymentRule.period === 'month'}
                                onChange={() =>
                                  paymentRulesRadioGroupHandler('month', currentTariff.price_per_month)}
                                label={<Typography>{"Ежемесячная оплата в размере "
                                  + currentTariff.price_per_month + " руб."}</Typography>}/>
              <FormControlLabel value={currentTariff.price_per_year} control={<Radio/>}
                                checked={paymentRule.period === 'year'}
                                onChange={() =>
                                  paymentRulesRadioGroupHandler('year', currentTariff.price_per_year)}
                                label={<Typography>{"Оплата 1 раз в год в размере "
                                  + currentTariff.price_per_year + " руб."}</Typography>}/>
            </RadioGroup>
          </FormControl>
        </Box>
        <FormControlLabel sx={{cursor: 'pointer',  mt: 1 }} color={'primary'}
                          control={<Checkbox size={'small'}
                                             sx={{p: 0, pr: 1}}
                                             checked={agreementCheckbox}
                                             onChange={CheckboxHandler}/>}
                          label={<Typography>
                            Я подтверждаю, что ознакомился и согласен с условиями
                            <Link sx={{cursor: 'pointer'}}
                                  onClick={offerClickHandler}
                            >{' договора-оферты'}</Link>
                          </Typography>}/>
        {paymentRule?.period === 'year' && showPaymentWindow.type === 'card' &&
          <Box sx={{mt: 2, mb: 2}}>
            <Typography>
              {`После оплаты тариф будет действовать до: ${nextYearDate}`}
            </Typography>
          </Box>
        }
        {paymentRule?.period === 'month' && showPaymentWindow.type === 'card' &&
          <Box sx={{mt: 2, mb: 2}}>
            <Typography>
              {`После оплаты тариф будет действовать до: ${nextMonthDate}`}
            </Typography>
          </Box>
        }
      </Stack>
    </ModalPage>
  );
};

export default PaymentWindow;