import {AppBar, Button, Dialog, IconButton, Toolbar, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BasicTabs from "./Tabs/BasicTabs";
import {useDispatch, useSelector} from "react-redux";
import {
  modulesSave,
  treeButtonDialog,
  updateUserCAsStatuses,
  updateUserRoadsAccess,
  updateUserWorkspaces
} from "../../../../../org_page_api";
import {changeOrgPageAttrs, dialogsDefaultState} from "../../../../../organizationPageReducer";
import {saveActionSelectText, saveString3} from "../../../../../../Main/Map/Common/Strings_RU";

export const UserAccessDialog = ({user}) => {
  const dispatch = useDispatch()
  const curTab = useSelector(state => state.organizationPageReducer.curTab)
  const hideSaveButton = curTab === 4;

  function handleDialogClose() {
    const changed = window.store.getState().organizationPageReducer.changesInDialog !== null;
    if (!changed) {
      dispatch(changeOrgPageAttrs({...dialogsDefaultState}))
    } else {
      treeButtonDialog(saveString3, saveActionSelectText, (value) => {
        if (value === 'Не сохранять') {
          dispatch(changeOrgPageAttrs({...dialogsDefaultState}))
        } else {
          if (value === 'Сохранить') {
            handleSave()
          }
        }
      })
    }
  }

  function handleSave() {
    const changed = window.store.getState().organizationPageReducer.changesInDialog !== null;
    if (changed) {
      switch (curTab) {
        case 0:
          updateUserWorkspaces(user)
          break;
        case 1:
          updateUserCAsStatuses(user)
          break;
        case 2:
          updateUserRoadsAccess(user)
          break;
        case 3:
          modulesSave(user.id)
          break;
      }
    }
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={handleDialogClose}
    >
      <AppBar sx={{position: 'relative'}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDialogClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Редактирование прав доступа для {
            user.last_name +
            (user.first_name ? ` ${user.first_name.charAt(0)}.` : '') +
            (user.patronymic ? `${user.patronymic.charAt(0)}.` : '') +
            ` (${user.phone})`
          }
          </Typography>
          {!hideSaveButton &&
            <Button autoFocus color="inherit" onClick={handleSave}>
              Сохранить
            </Button>
          }
        </Toolbar>
      </AppBar>
      <BasicTabs user={user} handleSave={handleSave}/>
    </Dialog>
  )
}
