import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Loading} from "../../../../../../../Main/Map/Common/Loading";
import {handleErrors} from "../../../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {changeOrgPageAttrs} from "../../../../../../organizationPageReducer";
import {otherDataAPI} from "../../../../../../../../api/api";
import {ErrorAlert} from "../../../../../../../Common/ErrorAlert";
import {loadingErrorWithRepeatText} from "../../../../../../../Main/Map/Common/Strings_RU";

const headers = [
  "",
  "Договор аренды",
  "Отведена",
  "Задекларирована",
  "В разработке",
  "Разработана",
  "Идёт вывозка",
  "Закрыта",
  "В архиве"
];

const headerKeys = [
  "",
  "number",
  "show_reserved_plots",
  "show_declared_plots",
  "show_development_plots",
  "show_developed_plots",
  "show_removal_plots",
  "show_closed_plots",
  "show_archive_plots"
];

const HeaderCell = ({header, onHeaderChange, allChecked}) => {
  return (
    <TableCell>
      {header === "Договор аренды" || header === "" ? header : (
        <label>
          <Checkbox
            checked={allChecked}
            onChange={() => onHeaderChange(header)}
          />
          {header}
        </label>
      )}
    </TableCell>
  );
};

const SecondTabAccessPlots = ({user}) => {
  const dispatch = useDispatch()
  const casList = useSelector(state => state.organizationPageReducer.allCas)
  const itemsLoading = useSelector(state => state.organizationPageReducer.itemsLoading)
  const itemsLoadingError = useSelector(state => state.organizationPageReducer.itemsLoadingError)

  useEffect(() => {
    if (!casList && !itemsLoading && !itemsLoadingError) {
      dispatch(changeOrgPageAttrs({itemsLoading: true}))
      otherDataAPI.organizationUsers.getAllAllowedRentalContractPlots(user.id)
        .then(res => {
          dispatch(changeOrgPageAttrs({
            allCas: res.data["rental_contracts_plots"],
            oldCas: structuredClone(res.data["rental_contracts_plots"]),
            itemsLoading: false,
            itemsLoadingError: false,
          }))
        })
        .catch(err => {
          handleErrors(dispatch, err)
          dispatch(changeOrgPageAttrs({itemsLoading: false, itemsLoadingError: true, allCas: null}))
        })
    }
  }, [itemsLoading, itemsLoadingError])

  function handleRowCheckboxChange(rowIndex) {
    const row = casList[rowIndex];
    const allChecked = !headerKeys.slice(2).every(key => row[key])
    const updatedData = casList.map((row, i) =>
      i === rowIndex
        ? headerKeys.slice(2).reduce((acc, key) => {
          acc[key] = allChecked;
          return acc;
        }, {...row})
        : row
    )
    dispatch(changeOrgPageAttrs({changesInDialog: 1, allCas: [...updatedData]}))
  }

  function handleHeaderChange(header) {
    const key = headerKeys[headers.indexOf(header)]
    const allChecked = !casList.every(row => row[key])
    const updatedData = casList.map(row => ({
      ...row,
      [key]: allChecked
    }))
    dispatch(changeOrgPageAttrs({changesInDialog: 1, allCas: [...updatedData]}))
  }

  function handleCheckboxChange(rowIndex, columnIndex) {
    const key = headerKeys[columnIndex]
    const updatedData = casList.map((row, i) => i === rowIndex ? {
      ...row,
      [key]: !row[key]
    } : row)
    dispatch(changeOrgPageAttrs({changesInDialog: 1, allCas: [...updatedData]}))
  }

  function isRowChecked(row) {
    return headerKeys.slice(2).every(key => row[key])
  }

  if (itemsLoading) {
    return <Loading/>
  }

  if (itemsLoadingError) {
    return (
      <ErrorAlert style={{mt: 2}} text={loadingErrorWithRepeatText} onClick={() => {
        dispatch(changeOrgPageAttrs({itemsLoading: false, itemsLoadingError: false, allCas: null}))
      }}
      />
    )
  }

  return (
    <>
      {casList?.length > 0
        ?
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <HeaderCell
                  key={index}
                  header={header}
                  onHeaderChange={handleHeaderChange}
                  allChecked={
                    index === 0
                      ? false
                      : casList.length > 0 &&
                      casList.every(row => row[headerKeys[index]])
                  }
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {casList.map((row, rowIndex) =>
              <TableRow key={rowIndex} className="hoverTableRow">
                <TableCell>
                  <Checkbox
                    checked={isRowChecked(row)}
                    onChange={() => handleRowCheckboxChange(rowIndex)}
                  />
                </TableCell>
                {headerKeys.slice(1).map((key, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {cellIndex === 0
                      ?
                      row[key]
                      :
                      <Checkbox
                        checked={row[key]}
                        onChange={() => handleCheckboxChange(rowIndex, cellIndex + 1)}
                      />
                    }
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
        :
        <Alert severity={'info'} sx={{mt: 2}}>Договоры аренды отсутствуют</Alert>
      }
    </>
  )
}

export default SecondTabAccessPlots;
