
const defaultState = {
  open: false,
  header: null,
  callback: null,
}

/*export function showEnterMapDialog(header = '', callback = null) {
  return {
    type: 'MAP_ENTER_DIALOG_SHOW',
    header: header,
    callback: callback,
  }
}*/

/*export function hideEnterDialog() {
  return {
    type: 'MAP_ENTER_DIALOG_HIDE',
  }
}*/

export function enterDialogReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_ENTER_DIALOG_SHOW':
      return {...state, open: true, header: action.header, callback: action.callback}
    case 'MAP_ENTER_DIALOG_HIDE':
      return {...state, open: false, header: null, callback: null}
    default:
      return state;
  }
}
