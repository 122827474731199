export const dialogsDefaultState = { //полноэкранные диалоги
  wpDialogWorkspace: null, //открыт диалог воркспейса
  changesInDialog: null, //индекс вкладки, на которой были внесены изменения, null - нет изменений
  dialogSaving: false, //идет сохранение
  dialogSavingError: false, //ошибка при сохранении
  selectedItems: null,
  allWorkspaces: null,
  allCas: null,
  allRoads: null,
  employeesCasPermissions: null, //права пользователей на деляны
  oldData: null, //old data (workspace dialog)
  oldCas: null, //old cas (employees dialog)
  oldRoads: null, //old roads (employees dialog)
  oldModules: null, //old modules (employees dialog)
  userWrokspacesLoading: null,
  userWrokspacesLoadingError: null,
  editEmployee: null,
  curTab: 0, //текущая вкладка диалога
  itemsLoading: false,
  itemsLoadingError: false,
  allModules: null,
}

export const defaulUserData = {
  first_name: '',
  last_name: '',
  patronymic: '',
  phone: '',
  email: '',
  post: '',
  is_admin: false,
  is_1c_user: false,
  is_owner: false,
  is_editor: false,
  is_full_access_user: false,
  only_mobile: false,
  deny_access: false
}

export const defaultState = {
  ...dialogsDefaultState,
  openedBlock: 'none',
  hideSaveButton: false,
  globalChanged: false, //есть несохраненные изменения в плашках. НЕ ИСПОЛЬЗУЕТСЯ
  //инфа об измениях для выхода (неоднозначное решение, тут нужно смотреть-думать-делать-смотреть)
  meChanged: {
    workspaces: [],
    cas: [],
    global: false,
  },

  //общая инфа по организации
  organizationData: null,
  organizationDataLoading: false,
  organizationDataLoadingError: false,

  //сотрудники
  employeesData: null,
  employeesDataLoading: false,
  employeesDataLoadingError: false,
  createUserMode: false,
  editUser: null, //копия пользователя для редактирования
  userData: {...defaulUserData}, //данные для формы создания/редактирования пользователя

  //воркспейсы
  workspacesData: null,
  workspacesDataLoading: false,
  workspacesDataLoadingError: false,
  createWorkspaceMode: false,
  selectedWorkspace: null,

  //sentinel
  sentinelUsers: {
    data: null,
    loading: false,
    loadingError: false,
    allCheked: false,
  },
}

export function changeOrgPageAttrs(data) {
  return {
    type: 'SET_ORG_PAGE_ATTRIBUTE',
    data: data,
  }
}

export function resetDialogData() {
  return {
    type: 'ORG_PAGE_RESET_DIALOG_DATA',
  }
}

export function organizationPageReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_ORG_PAGE_ATTRIBUTE':
      return {...state, ...action.data}
    case 'ORG_PAGE_RESET_DIALOG_DATA':
      return {...state, ...dialogsDefaultState}
    default:
      return state;
  }
}
