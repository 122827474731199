import {getBaseLayers} from "../../Overlay/BaseLayers/BaseLayersCommon";
import {otherDataAPI} from "../../../../api/api";
import {getMiscLayers} from "../../LeftPanel/Context/MiscLayers/MiscLayersCommon";
import {getUserMiscLayers} from "../../LeftPanel/Context/MiscLayers/UserLayers/UserLayers";
import {getBlindBaseLayers} from "../Blind/BlindBaseLayersCommon";
import {getBlindMiscLayers} from "../Blind/BlindMiscLayersCommon";
import {getBlindControlPosition} from "./libs/leaflet-side-by-side";
import {cannotIDo} from "./tariffs";

//Добавление настройки:
//1. Добавить настройку в defaultUserSettings
//2. Добавить проверку наличия настройки в setUserSettings
//3. Добавить обработку создания настройки в saveUserSettings
//Настройки, которых нет в defaultUserSettings удаляются при первом сохранении!

const defaultUserSettings = { // Применяется, если у узера нет настроек
  baseLayers: {
    osm: {opacity: 1, show: true, sentinel: null}
  },
  miscLayers: {},
  userMiscLayers: [],
  blind: {enabled: true, left: true, position: 0.75, baseLayers: {}, miscLayers: {}, userLayers: {}}
}

let interval = null;
let wasBeenChanges = false;
let userSettings = null;

export function setSettingWasChanged() {
  wasBeenChanges = true;
}

export function getUserSettings() {
  return userSettings;
}

export function setUserSettings(settings) {
  let mySettings = {...settings}
  Object.keys(mySettings).map(item => { //Удаляет "старые" настройки
    if (defaultUserSettings[item] === undefined)
      delete mySettings[item];
  })
  if (Object.keys(mySettings).length === 0) //first start
    mySettings = defaultUserSettings;
  if (!mySettings.baseLayers)
    mySettings.baseLayers = {...defaultUserSettings.baseLayers}
  if (!mySettings.miscLayers)
    mySettings.miscLayers = {}
  if (!mySettings.userMiscLayers)
    mySettings.userMiscLayers = []
  if (!mySettings.blind)
    mySettings.blind = {...defaultUserSettings.blind}
  if (!mySettings.blind.baseLayers)
    mySettings.blind.baseLayers = {...defaultUserSettings.blind.baseLayers}
  if (!mySettings.blind.miscLayers)
    mySettings.blind.miscLayers = {...defaultUserSettings.blind.miscLayers}
  if (!mySettings.blind.userLayers)
    mySettings.blind.userLayers = {...defaultUserSettings.blind.userLayers}
  userSettings = mySettings;
}

function saveUserSettings() {
  if (wasBeenChanges) {
    //Common layers
    const baseLayers = getBaseLayers()
    Object.entries(baseLayers).map(([key, value]) => {
      userSettings.baseLayers[key] = {show: value.show, opacity: value.opacity}
    })
    const miscLayers = getMiscLayers()
    Object.entries(miscLayers).map(([key, value]) => {
      userSettings.miscLayers[key] = {show: value.show}
      if (value.style === 'light' || value.style === 'dark')
        userSettings.miscLayers[key].style = value.style;
    })
    const userLayers = getUserMiscLayers()
    const arr = []
    userLayers.data.map(node => {
      if (node.id)
        arr.push({id: node.id, name: node.name, show: node.show, show_blind: node.blind_show})
    })
    userSettings.userMiscLayers = arr;

    //Blind layers
    const blind = window.store.getState().blindReducer;
    let position = getBlindControlPosition()

    userSettings.blind = {enabled: blind.enabled, position: position}
    const blindBaseLayers = getBlindBaseLayers()
    if (!userSettings.blind.baseLayers)
      userSettings.blind.baseLayers = {}
    Object.entries(blindBaseLayers).map(([key, value]) => {
      userSettings.blind.baseLayers[key] = {show: value.show, opacity: value.opacity}
    })
    const blindMiscLayers = getBlindMiscLayers()
    Object.entries(blindMiscLayers).map(([key, value]) => {
      if (!userSettings.blind.miscLayers)
        userSettings.blind.miscLayers = {}
      userSettings.blind.miscLayers[key] = {show: value.show}
      if (value.style === 'light' || value.style === 'dark')
        userSettings.blind.miscLayers[key].style = value.style;
    })

    //saving
    otherDataAPI.user.saveUserSettings(userSettings).then(res => {
      if (res.status === 200) {
        wasBeenChanges = false;
      }
    }).catch(err => {
      console.error(err.message)
    })
    wasBeenChanges = false;
  }
}

export function initSettingsTimer() {
  interval = setInterval(saveUserSettings, 15000) //120000
}

export function destroySettingsTimer() {
  clearInterval(interval)
}
