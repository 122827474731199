import {TableCell, TableRow, TableHead, Stack, IconButton} from "@mui/material";
import {SortingIcon} from "../../../../Common/SortingIcon/SortingIcon";

function SortingHeader({label, fieldName, sortingOrder, sortingChange}) {

  return (
    <Stack direction={'row'} gap={0.2} alignItems={'center'} >
      {label}
      <IconButton
        title={'Сортировка'}
        disableRipple
        sx={{p: 0}}
        onClick={() => {
          if (sortingChange)
            sortingChange(fieldName, sortingOrder)
        }}
      >
        <SortingIcon mode={sortingOrder} />
      </IconButton>
    </Stack>
  )
}

function TableHeadComponent({fieldData, roleData, sortingChange}) {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">{'№'}</TableCell>
        {fieldData.map((column, i) => (
            <TableCell sx={{minWidth: 100}} key={i} align="left">
                <SortingHeader label={column.label} fieldName={column.fieldName}
                               sortingOrder={column.order} sortingChange={sortingChange}/>
            </TableCell>
        ))}
        <TableCell sx={{minWidth: 120}}  align="left">
          <SortingHeader label={roleData.label} sortingOrder={roleData.order}
                         fieldName={roleData.fieldName} sortingChange={sortingChange}/>
        </TableCell>
        <TableCell align="left">&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeadComponent;
