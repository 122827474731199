import {formatDateFromObjToStr} from "../Main/Map/Common/DateTimeFunctions";
import {setSnack} from "../Main/Map/Common/Dialog/Snack/snackReducer";

export const getValueFromRowByColumn = (columnName, rowObject, rowIndex) => {
  switch (columnName) {
    case 'rowNumber':
      return rowIndex + 1
    case 'volume':
    case 'date':
      return rowObject
    case 'plot':
    case 'machine':
    case 'specie':
    case 'point_from':
    case 'point_to':
      return rowObject?.name || ''
    case 'operator':
      return `${rowObject.last_name} ${rowObject.first_name ? rowObject.first_name[0] + '.': ''} 
      ${rowObject.patronymic ? rowObject.patronymic[0] + '.': ''}`
    default:
      return ''
  }
}

export const formatHarvestingData = (data) => {
  let formattedData = {...data}
  formattedData.date = data.date ? formatDateFromObjToStr(data.date) : formatDateFromObjToStr(new Date())
  formattedData.machine = data.machine?.id || null
  formattedData.plot = data.plot?.id || null
  formattedData.operator = data.operator?.id || null
  formattedData.specie = data.specie?.id || null
  formattedData.volume = data.volume
  return formattedData
}
export const formatRemovalData = (data) => {
  let formattedData = {...data}
  formattedData.date = data.date ? formatDateFromObjToStr(data.date) : formatDateFromObjToStr(new Date())
  formattedData.machine = data.machine?.id || null
  formattedData.type_from = data.point_from?.type || null
  formattedData.type_to = data.point_to?.type || null
  formattedData.id_from = data.point_from?.id || null
  formattedData.id_to = data.point_to?.id || null
  formattedData.operator = data.operator?.id || null
  formattedData.specie = data.specie?.id || null
  formattedData.volume = data.volume

  return formattedData
}

export const validateHarvestingData = (data) => {
  return !(!data.plot || !data.machine);

}

export const validateRemovalData = (data) => {
  return !(!data.point_from || !data.point_to || !data.machine);

}

export const createParamsString = (paramsObj) => {
  let resultStr = ''
  let objectKeys = Object.keys(paramsObj)
  objectKeys.forEach((key, i) => {
    resultStr += `${key}=`
    paramsObj[key].forEach((item, j) => {
      j !== paramsObj[key].length - 1
        ? resultStr += `${item},`
        : resultStr += item
    })
    if (key === 'plots') resultStr += `${key}=`
    i !== objectKeys.length - 1
      ? resultStr += '&'
      : resultStr += ''
  })
  return resultStr
}

export function updateOffset(path, number) {
  if (!path) return null

  const url = new URL(path);
  const params = url.searchParams;
  const currentOffset = parseInt(params.get('offset') || 0, 10);
  const newOffset = currentOffset + number;
  params.set('offset', newOffset);
  return url.toString();
}