export function checkPointOnPolygonLine(polygon, point, tolerance) {
  for (let i = 0; i < polygon.length; i++) {
    let p1 = polygon[i];
    let p2 = polygon[(i + 1) % polygon.length];

    let distanceToSegment = getDistanceToSegment(point, p1, p2);

    if (distanceToSegment <= tolerance) {
      return [i, (i + 1) % polygon.length];
    }
  }

  return null;
}

function getDistanceToSegment(point, p1, p2) {
  let segmentLengthSquared = Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2);
  if (segmentLengthSquared === 0) {
    return Math.sqrt(Math.pow(point.x - p1.x, 2) + Math.pow(point.y - p1.y, 2));
  }

  let t = ((point.x - p1.x) * (p2.x - p1.x) + (point.y - p1.y) * (p2.y - p1.y)) / segmentLengthSquared;
  t = Math.max(0, Math.min(1, t));

  let closestX = p1.x + t * (p2.x - p1.x);
  let closestY = p1.y + t * (p2.y - p1.y);

  return Math.sqrt(Math.pow(point.x - closestX, 2) + Math.pow(point.y - closestY, 2));
}