import {getMap1} from "../GlobalObjects";
import L from 'leaflet';
import 'leaflet-arrowheads';

let arrow_layer = null;

export function createRedArrowByPoint(latlng, vShift = 0) {
  const lay = L.marker(latlng)
  createRedArrow(lay, true, vShift)
}

export function createRedArrowByPolygon(latlngs) {
  const lay = L.polygon(latlngs)
  createRedArrow(lay, false)
}

export function createRedArrow(layer, isPoint = false, vShift = 0) {
  deleteRedArrow()
  const map = getMap1()
  let point;
  if (isPoint) {
    point = layer.getLatLng()
  } else {
    point = layer.getBounds().getCenter()
    point.lat = layer.getBounds().getNorth()
  }
  let endPointPX = map.latLngToLayerPoint(point)
  if (isPoint)
    endPointPX.y -= 24 +vShift;
  else
    endPointPX.y -= 4;
  const startPointPX = {...endPointPX}
  startPointPX.y -= 40;
  const startPoint = map.layerPointToLatLng(startPointPX)
  const endPoint = map.layerPointToLatLng(endPointPX)
  arrow_layer = L.polyline([startPoint, endPoint], {color: 'red'})
  arrow_layer['arrowheads']({fill: true, yawn: 35, size: '15px'})
  map.addLayer(arrow_layer)
  map.once('zoomend', deleteRedArrow)
  map.once('dragend', deleteRedArrow)
}

export function deleteRedArrow() {
  if (arrow_layer) {
    const map = getMap1()
    map.off('zoomend', deleteRedArrow)
    map.off('dragend', deleteRedArrow)
    map.removeLayer(arrow_layer)
  }
}
