import {ListItemIcon, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';

function WhatsNew({handleClose}) {
  return (
    <>
      <MenuItem onClick={() => {
        window.open('https://delyana-update.kaiten.site/', '_blank')
        handleClose()
      }}>
        <ListItemIcon>
          <VolumeUpOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <Typography>Новый функционал</Typography>
      </MenuItem>
    </>
  )
}

export default WhatsNew;
