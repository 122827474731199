import {getDrawLayerGroup, getMap1} from "../../GlobalObjects";
import {setDrawMode} from "../../../LeftPanel/Context/Projects/projectsReducer";
import {setDrawingMode} from "../../../Overlay/Measuring/measuringReducer";
import {setSelectionDrawingMode} from "../../../LeftPanel/Context/CAs/Selections/caSelectionsReducer";
import {stopPencilDrawing} from "./pencil_drawing";
import {changeRoadsAttr} from "../../../LeftPanel/Context/Roads/roadsReducer";

export function removeAllMeasuringFromDRGroup() { //remove measuring from the map
  const drawLayerGroup = getDrawLayerGroup()
  drawLayerGroup.eachLayer(layer => {
    if (layer.options.isMeasuring) {
      layer.off('click')
      layer.off('pm:create')
      layer.off()
      drawLayerGroup.removeLayer(layer)
    }
  })
}

export function cancelDrawing() { //cancel projects drawing
  const map = getMap1()
  map.pm.disableDraw()
  map.off('pm:create')
  map.off('pm:drawstart')
  map.off('pm:vertexadded')
  map.off('pm:drawend')
  window.elz_dispatch(setDrawMode(null))
}

export function resetAllDrawing() { //reset all drawing on map include reducers' calls
  const dispatch = window.elz_dispatch;
  //projects
  cancelDrawing()
  //measuring
  dispatch(setDrawingMode(null))
  //CAs selection
  dispatch(setSelectionDrawingMode(false))
  //Pencil drawing
  stopPencilDrawing()
  //Road drawing
  dispatch(changeRoadsAttr({drawingEnable: false}))
}
