import React from 'react';
import {useNavigate} from "react-router";
import {ListItemIcon, MenuItem} from "@mui/material";
import {urlGenerator} from "../../../../utils/urlGenerator";
import Typography from "@mui/material/Typography";
import StraightenIcon from "@mui/icons-material/Straighten";
import {cannotIDo} from "../../../Main/Map/Common/tariffs";

const StockpilesMenuItem = ({handleClose}) => {
  const navigate = useNavigate()
  return (
    <>
      <MenuItem disabled={!!cannotIDo.useStockpilesModule()} onClick={() => {
        navigate(urlGenerator().stockpiles)
        handleClose()
      }}>
        <ListItemIcon>
          <StraightenIcon fontSize={'small'}/>
        </ListItemIcon>
        <Typography>
          Замер штабелей<span style={{border: 'solid 2px lightgrey', fontSize: 12, borderRadius: 10,
          padding: '1px 4px', marginLeft: '1px'}}>beta</span>
        </Typography>
      </MenuItem>
    </>
  )
};

export default StockpilesMenuItem;