import L from 'leaflet';
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import {getDrawLayerGroup, getMap1} from "../../../Map/GlobalObjects";
import {useDispatch, useSelector} from "react-redux";
import {showMapDialog} from "../../../Map/Common/Dialog/DialogReducer";
import {createBaseLayer, getBaseLayers} from "../../../Overlay/BaseLayers/BaseLayersCommon";
import {getMiscLayer, getMiscLayers} from "../MiscLayers/MiscLayersCommon";
import {
  addRemoveCAsStratums,
  cloneCAPolygon,
  getCuttingAreas,
  removeCAsLabels,
  showCAsLabels
} from "../CAs/CuttingsAreas";
import {cloneRoadPolyline, getRoads} from "../Roads/roads";
import {cloneShape, getProjects, rentBorderLayerOpts, showHideAllShapes,} from "../Projects/ProjectsCommon";
import CloseIcon from '@mui/icons-material/Close';
import {setLeftDrawerCurTab} from '../../LeftDrawerReducer';
import {
  setPrintingMargin,
  setPrintingOrientation,
  setPrintingPageSize,
  setPrintingShowLabels,
  setPrintingShowShapes,
  setPrintingShowStratums
} from "./printingReducer";
import {
  errorString,
  millimetersShortString,
  pageMarginStrings,
  pageOrientationLandscapeString,
  pageOrientationPortraitString,
  pageOrientationString,
  pageShowCasLabelsStratums,
  pageShowCasLabelsString,
  pageShowShapesString,
  pageSizeString,
  printingNotAllLayerErrorString,
  printingString,
} from "../../../Map/Common/Strings_RU";
import Box from "@mui/material/Box";
import {getLeftDrawerButtons} from "../../LeftDrawer";
import {createUserMiscLayer, getUserMiscLayers} from "../MiscLayers/UserLayers/UserLayers";
import {clonePillarMarker, getPillars} from "../Infrastructure/Pillars/pillars";

let tilesLayersCount = 0;
let printingMap = null;
let ownerDiv = null;
let printingReady = false;
let errors = [];

export const pageSizesStd = {
  //A5: {width: 148, height: 210},
  A4: {width: 210, height: 297},
  A3: {width: 297, height: 420},
  A2: {width: 420, height: 594},
  A1: {width: 594, height: 841},
  A0: {width: 841, height: 1189},
}

const printingMapDiv = 'printing_map_div';

export function PrintMap(props) {
  const arch_filters = useSelector(state => state.filterReducer.filters["archived"])
  //const printIntroTour = useSelector(state => state.introToursReducer.printIntroTour)
  const state = useSelector(state => state.printingReducer)
  const dispatch = useDispatch()

  function printIfReady() {
    if (errors.length === 0) {
      if (printingReady && tilesLayersCount === 0) {
        window.print() //модалка!
        afterPrinting()
        printingReady = false;
      }
    } else {
      const buttons = {'Да': {}, 'Нет': {}}
      dispatch(showMapDialog('warning', errorString, printingNotAllLayerErrorString, buttons, (value) => {
        if (value === 'Да')
          window.print() //модалка!
        afterPrinting()
        printingReady = false;
      }))
    }
  }

  function afterPrinting() {
    tilesLayersCount = 0;
    errors = []
    if (printingMap) {
      printingMap.eachLayer(lay => {
        lay.off("load")
        lay.off("error")
        lay.off()
        printingMap.removeLayer(lay)
      })
      printingMap.off()
      printingMap.remove()
      printingMap = null;
    } else
      console.error('error: no printing map found')
    while (ownerDiv.lastElementChild) {
      ownerDiv.removeChild(ownerDiv.lastElementChild);
    }
    document.getElementById('root').style.display = 'block';
  }

  function buttonClickHandler() {

    const bitrixSate = window.store.getState().bitrixReducer;
    if (bitrixSate.widgetOpened) {
      bitrixSate.widget.close()
    }

    document.getElementById('root').style.display = 'none';

    ownerDiv = document.getElementById('printing_root')

    const printStyleSheet = document.createElement('style');
    printStyleSheet.title = 'map_printing_page_stylesheet';
    printStyleSheet.setAttribute('type', 'text/css');
    printStyleSheet.className = "leaflet-browser-print-css";
    let dimentions = structuredClone(pageSizesStd[state.pageSize]);

    switch (state.orientation) {
      case "Landscape":
        dimentions = {width: dimentions['height'], height: dimentions['width']}
        printStyleSheet.innerText += `@media print { @page { size : ${state.pageSize} ${state.orientation.toLowerCase()}; margin: ${state.margin}mm;}}`;
        break;
      case "Portrait":
        printStyleSheet.innerText += `@media print { @page { size : ${state.pageSize} ${state.orientation.toLowerCase()}; margin: ${state.margin}mm;}}`;
        break;
    }

    const mapDiv = document.createElement('div')
    mapDiv.id = printingMapDiv;
    mapDiv.style.height = dimentions.height - state.margin * 2 + 'mm';
    mapDiv.style.width = dimentions.width - state.margin * 2 + 'mm';
    mapDiv.style.position = 'fixed';
    mapDiv.appendChild(printStyleSheet)
    const map = getMap1()

    ownerDiv.appendChild(mapDiv)
    printingMap = L.map(printingMapDiv, {
      center: map.getCenter(),
      zoomSnap: 0,
      zoomControl: false,
      doubleClickZoom: false,
      fadeAnimation: false,
      dragging: false,
      scrollWheelZoom: false,
    })

    printingMap.setMaxBounds(map.getBounds());
    printingMap.fitBounds(map.getBounds(), {padding: [0,0]})

    //baseLayers
    printingMap.createPane('base')
    printingMap.createPane('sentinel')
    printingMap.createPane('userlayers')
    printingMap.createPane('misc')
    printingMap.createPane('roads')
    printingMap.createPane('cas')
    printingMap.createPane('pillars')
    printingMap.createPane('shapes')
    printingMap.createPane('perimeters')

    //Base layers
    Object.values(getBaseLayers()).map(value => {
      value.show && tilesLayersCount++;
    })
    Object.entries(getBaseLayers()).map(([key, value]) => {
      if (value.show) {
        const lay = createBaseLayer(key, false, false)
        printingMap.addLayer(lay)
        lay.on("load", () => {
          tilesLayersCount--;
          printIfReady()
        })
      }
    })

    //MiscLayers
    Object.values(getMiscLayers()).map(value => {
      if (value.show) {
        tilesLayersCount++;
      }
    })

    Object.entries(getMiscLayers()).map(([key, value]) => {
      if (value.show) {
        const gr = getMiscLayer(key, true, 'misc')
        gr.eachLayer(lay => {
          lay.on("load", () => {
            tilesLayersCount--;
            printIfReady()
          })
          lay.on('error', function () {
            errors.push(value.name)
            tilesLayersCount--;
            printIfReady()
          })
          printingMap.addLayer(lay)
        })
      }
    })

    //User Layers
    getUserMiscLayers().data.map(node => {
      if (node.layer)
        tilesLayersCount++;
    })
    getUserMiscLayers().data.map(node => {
      if (node.layer) {
        const lay = createUserMiscLayer(node, 'userlayers', false)
        lay.on("load", () => {
          tilesLayersCount--;
          printIfReady()
        })
        lay.on('error', function () {
          errors.push(node.name)
          tilesLayersCount--;
          printIfReady()
        });
        printingMap.addLayer(lay)
      }
    })

    //CAs
    const casGroup = L.layerGroup()
    casGroup.addTo(printingMap)
    if (getCuttingAreas().group)
      getCuttingAreas().group.eachLayer(ca => {
        const lay = cloneCAPolygon(ca, 'cas')
        casGroup.addLayer(lay)
      })
    if (getCuttingAreas()["arch_group"] && arch_filters)
      getCuttingAreas()["arch_group"].eachLayer(ca => {
        const lay = cloneCAPolygon(ca, 'cas')
        casGroup.addLayer(lay)
      })
    if (state.showLabels)
      showCAsLabels(casGroup, printingMap, state.showStratums)

    //Roads
    const roadGroup = L.layerGroup()
    roadGroup.addTo(printingMap)
    const roads = getRoads()
    Object.values(roads.roadsByWorkspaces).map(wroads => {
      wroads.data.map(road => {
        if (!road.elz_hide) {
          const lay = cloneRoadPolyline(road, 'roads')
          roadGroup.addLayer(lay)
        }
      })
    })

    //Pillars
    const pillarsGroup = L.layerGroup()
    pillarsGroup.addTo(printingMap)
    const pillars = getPillars();
    Object.values(pillars.pillarsByWorkspaces).map(wpillars => {
      wpillars.group.eachLayer(pillar => {
        if (!pillar.elz_hide) {
          const lay = clonePillarMarker(pillar)
          pillarsGroup.addLayer(lay)
        }
      })
    })

    //Shapes
    const shapesGroup = L.layerGroup()
    shapesGroup.addTo(printingMap)
    if (getProjects() && state.showShapes) {
      const layers = getDrawLayerGroup()
      layers.eachLayer(shape => {
        const lay = cloneShape(shape)
        shapesGroup.addLayer(lay)
      })
    }

    //Rent borders
    const rentBorderGroup = L.layerGroup()
    rentBorderGroup.addTo(printingMap)
    getProjects().data.forEach(workspace => {
      if (workspace.perimeter?.layers) {
        workspace.perimeter.layers.eachLayer(layer => {
          const lay = L.polyline(layer.getLatLngs(), rentBorderLayerOpts)
          rentBorderGroup.addLayer(lay)
        })
      }
    })

    printingReady = true;
    printIfReady()
  }

  return (
    <Box id={'printBlock'}>
    <Stack flexDirection={'row'} justifyContent="space-between" alignItems="center">
      <Stack direction={'row'} alignItems={'center'}>
        <Typography align={'center'}>{getLeftDrawerButtons()[props.index].name}</Typography>
        {/*<IconButton
          sx={{...pageTitleStyle}}
          onClick={() => {
            dispatch(setIntroTourAttr({...printIntroTour, status: true}, 'printIntroTour'))}}
          title={'Описание функционала'}>
          <ErrorOutlineOutlinedIcon sx={{fontSize: 20}}/>
        </IconButton>*/}
      </Stack>
      <IconButton
        onClick={() => {
          dispatch(setLeftDrawerCurTab(null))
        }}>
        <CloseIcon />
      </IconButton>
    </Stack>
    <Stack direction={ 'column'} spacing={2}>
      <Stack spacing={2} direction={'row'}>
        <FormControl variant="standard" style={{flex: 0.5}}>
          <InputLabel id="map-print-orientation-select-label">{pageOrientationString}</InputLabel>
          <Select
            labelId={"map-print-orientation-select-label"}
            value={state.orientation}
            onChange={(e) => {
              dispatch(setPrintingOrientation(e.target.value))
            }}
            variant={'standard'}
          >
            <MenuItem value="Portrait">{pageOrientationPortraitString}</MenuItem>
            <MenuItem value="Landscape">{pageOrientationLandscapeString}</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" style={{flex: 0.5}}>
          <InputLabel id="map-print-page-select-label">{pageSizeString}</InputLabel>
          <Select
            labelId={"map-print-page-select-label"}
            value={state.pageSize}
            onChange={(e) => {
              dispatch(setPrintingPageSize(e.target.value))
            }}
            variant={'standard'}
          >
            {Object.keys(pageSizesStd).map(key => <MenuItem value={key} key={key}>{key}</MenuItem>)}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <FormControl variant="standard" style={{flex: 0.5}}>
          <InputLabel id="map-print-margin-select-label">{pageMarginStrings}</InputLabel>
          <Select
            labelId={"map-print-margin-select-label"}
            value={state.margin}
            onChange={(e) => {
              dispatch(setPrintingMargin(e.target.value))
            }}
            variant={'standard'}
          >
            {[0, 5, 10, 15, 20, 25, 30].map(item => {
              return (
                <MenuItem value={item} key={item}>{item+millimetersShortString}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <div style={{flex: 0.5}}/>
      </Stack>
      <FormControlLabel
        id={'showShapesSwitch'}
        style={{marginTop: '1vh'}}
        label={pageShowShapesString}
        control={<Switch
          checked={state.showShapes}
          onChange={() => {
            showHideAllShapes(!state.showShapes)
            dispatch(setPrintingShowShapes(!state.showShapes))
          }}
        />}
      />
      <FormControlLabel
        id={'showCasLabelsSwitch'}
        style={{marginTop: 0}}
        label={pageShowCasLabelsString}
        control={<Switch
          checked={state.showLabels}
          onChange={() => {
            if (state.showLabels) {
              removeCAsLabels(getCuttingAreas().group, getMap1())
              dispatch(setPrintingShowStratums(false))
            }
            else
              showCAsLabels(getCuttingAreas().group, getMap1())
            dispatch(setPrintingShowLabels(!state.showLabels))
          }}
        />}
      />
      {state.showLabels &&
        <FormControlLabel
          style={{marginTop: 0}}
          label={pageShowCasLabelsStratums}
          control={<Switch
            checked={state.showStratums}
            onChange={() => {
              addRemoveCAsStratums(getCuttingAreas().group, !state.showStratums)
              dispatch(setPrintingShowStratums(!state.showStratums))
            }}
          />}
        />
      }
      <Button onClick={buttonClickHandler} variant={'outlined'}>
        {printingString}
      </Button>
      {/*<IntroTour tourData={printIntroTour} tourName={'printIntroTour'}/>*/}
    </Stack>
    </Box>
  )
}
