import {
  getAllNodesListFromContainer,
  isContainerNode
} from "../../../LeftPanel/Context/Projects/ProjectsCommon";
import {saveStringAsFile} from "./gpx";
import {geoJSONdefaultPrecision} from "../Defaults";
import {setSnack} from "../Dialog/Snack/snackReducer";

function addStylesToFeatures(shape, js) {
  //TODO Добавить стили. В RFC7946 ничего про стили нет! Данные самопридуманные.
  js.properties.name = shape.name;
  if (shape.layer.options.weight)
    js.properties.weight = shape.layer.elz_properties.weight;
  if (shape.type === 'Marker') {
    js.properties.markerColor = shape.layer.elz_properties.markerColor;
    js.properties.markerSymbol = shape.layer.elz_properties.markerSymbol;
  } else
    js.properties.color = shape.layer.elz_properties.color;
  return js;
}

export function saveGEOJsonFeatureCollection(node, addStyles = true) {
  let json = {
    type: 'FeatureCollection',
    features: [],
  };
  if (isContainerNode(node)) {
    let arra = [];
    getAllNodesListFromContainer(node, arra)
    json.features = arra.map(shape => {
      const js = shape.layer.toGeoJSON(geoJSONdefaultPrecision);
      //FIXME Не выполняется правило правой руки для полигонов
      if (addStyles)
        addStylesToFeatures(shape, js)
      json.features.push(js)
    })
  } else {
    const js = node.layer.toGeoJSON(geoJSONdefaultPrecision);
    if (addStyles)
      addStylesToFeatures(node, js)
    json.features.push(js)
  }
  saveStringAsFile(JSON.stringify(json), 'application/json', node.name + '.json')
}

export function saveLatLngsAsGeoJsonFile(latlngs, type, name) {
  let layer = null;
  try {
    switch (type) {
      case 'Marker':
        layer = L.circleMarker(latlngs[0])
        break;
      case 'Line':
        layer = L.polyline(latlngs)
        break;
      case 'Polygon':
        layer = L.polygon(latlngs)
        break;
    }
    const node = {
      name: name,
      layer: layer,
      type: type,
    }
    saveGEOJsonFeatureCollection(node, false)
  } catch (err) {
    console.error(err)
    const dispatch = window.elz_dispatch;
    dispatch(setSnack('error', 'Ошибка при создании GeoJSON. Проверьте координаты.'))
  }
}
