import Stack from "@mui/material/Stack";
import {ZoomComponent} from "../Map/Zoom/ZoomComponent";
import {Copyright} from "./Copyright";

export function BottomPanel() {

  const panelStyle = {
    position: 'absolute',
    bottom: '1vh',
    left: '1vh',
    width: '100%',
  }

  return (
    <Stack
      alignItems={'start'}
      style={panelStyle}>
      <ZoomComponent/>
      <Copyright/>
    </Stack>
  )
}
