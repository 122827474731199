import React from 'react';

const NewQuarterPoint = ({coordCorrection, pointPosition}) => {
  return (
    <circle
      cx={coordCorrection(pointPosition.x)}
      cy={coordCorrection(pointPosition.y)}
      r={6}
      fill={"#ffeb3b"}
      opacity={1}
    />
  );
};

export default NewQuarterPoint;