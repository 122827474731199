import {otherDataAPI} from "../../api/api";
import {djangoErrorsParse} from "../../utils/djangoErrorsParse";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {coordCorrection} from "../../svgComponentsHelpersFunctions/coordCorrection";
import {replacePlanetable} from "./planetablesReducer";
import {updatePlanetabletsLayerData} from "../../components/Main/Map/Common/tariffs";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_PLANETABLE_SVG_ATTR = 'SET_PLANETABLE_SVG_ATTR'
const RESET_SVG_FIGURES_STATE = 'RESET_SVG_FIGURES_STATE'
const DRAG_LAYER = 'DRAG_LAYER'
const SET_NEW_QUARTERS_LIST = 'SET_NEW_QUARTERS_LIST'

let initialState = {

  selectedPlanetable: null,
  mode: "view", //modes: view, layer
  zoom: 100,
  scale: 1,

  showLoadLayerModal: false,

  quartersSvg: [],
  changedQuartersList: [],

  layerFromMap: {type: 'none', layerObjects: [], layerRotationAngle: 0},
  layerOperationsLoading: false,


  selectedSvgFigure: {type: null, index: null, planetableObj: null},
  viewedSvgFigure: {type: null, index: null, planetableObj: null},
  enabledLayers: {quarters: true, allotments: true, pillars: true, area: false},


  currentlyPressedButton: null
}

// редьюсер для работы с svg на странице планшетов
export const planetableSvgReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANETABLE_SVG_ATTR: {
      return {...state, ...action.payload};
    }
    case RESET_SVG_FIGURES_STATE: {
      return {...state,
        quartersSvg: state?.selectedPlanetable?.quarters_px.map(quarter => quarter.coordinates_pxl) || [],
        layerFromMap: {type: 'none', layerObjects: [], layerRotationAngle: 0},
        mode: 'view',
      }
      }
    case DRAG_LAYER: {
      return {...state, layerFromMap: {
        ...state.layerFromMap,
        layerObjects: state.layerFromMap.layerObjects.map(obj => {
          return {
            ...obj,
            coordinates_pxl: obj.coordinates_pxl.map(point => {
              return {...point, x: point.x + action.dx, y: point.y + action.dy}
            })}})}
      }
    }
    //quarters actions
    case SET_NEW_QUARTERS_LIST: {
      return {
        ...state, selectedPlanetable: {
          ...state.selectedPlanetable,
          quarters_px: [...action.payload]
        }
      }
    }
    default:
      return state
  }
}

// common planetable action creators
export const setPlanetableSvgAttr = (payload) => ({type: SET_PLANETABLE_SVG_ATTR, payload});
export const resetSvgFiguresState = () => ({type: RESET_SVG_FIGURES_STATE});

// layers action creators
export const dragLayerAction = (dx, dy) => ({type: DRAG_LAYER, dx, dy});

// quarters action creators
export const setNewQuartersList = (payload) => ({type: SET_NEW_QUARTERS_LIST, payload});


//quarters layer thunks
export const getQuartersFromMapThunk = (data, selectedPlanetable, zoom, scale) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.planetable.getQuartersFromMap(data, selectedPlanetable.id)
      let quarters = res.data.map((quarter) => ({
        ...quarter,
        coordinates_pxl: quarter.coordinates_pxl.map(point => ({
          x: coordCorrection(point.x, 100,),
          y: coordCorrection(point.y, 100,),
          x_m: point.x_m,
          y_m: point.y_m,
        }))
      }))
      dispatch(setPlanetableSvgAttr({layerFromMap:
          {type: 'quarters', layerObjects: quarters, layerRotationAngle: 0}, showLoadLayerModal: false, mode: 'layer'}))
      dispatch(setSnack('success', 'Кварталы загружены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const saveQuartersLayerThunk = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: true}))
      let res = await otherDataAPI.planetable.saveQuartersLayer(data)
      updatePlanetabletsLayerData(dispatch)
      dispatch(setNewQuartersList(res.data))
      dispatch(setPlanetableSvgAttr({layerFromMap: {type: 'none', layerObjects: []}}))
      dispatch(setPlanetableSvgAttr({mode: 'view'}))
      dispatch(resetSvgFiguresState())
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: false}))
      dispatch(setSnack('success', 'Кварталы планшета сохранены и добавлены на карту'))
    } catch (e) {
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const patchQuartersLayerThunk = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: true}))
      let res = await otherDataAPI.planetable.patchQuartersList(data)
      updatePlanetabletsLayerData(dispatch)
      dispatch(setNewQuartersList(res.data))
      dispatch(resetSvgFiguresState())
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: false}))
      dispatch(setSnack('success', 'Кварталы планшета изменены и добавлены на карту'))
    } catch (e) {
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const deleteQuartersLayerThunk = (selectedPlanetable, openModal) => {
  return async (dispatch) => {
    try {
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: true}))
      let res = await otherDataAPI.planetable.deleteQuartersLayer(selectedPlanetable.id)
      updatePlanetabletsLayerData(dispatch)
      dispatch(setPlanetableSvgAttr({selectedPlanetable: {...selectedPlanetable, quarters_px: []}}))
      dispatch(replacePlanetable(selectedPlanetable))
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: false}))
      dispatch(setSnack('success', 'Кварталы удалены'))
      if (openModal) {
        dispatch(setPlanetableSvgAttr({showLoadLayerModal: true}))
      }
    } catch (e) {
      dispatch(setPlanetableSvgAttr({layerOperationsLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}
