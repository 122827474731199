import {ReactComponent as Icon} from "../../Main/Map/Common/svg/sorting.svg";
import {useTheme} from "@mui/material";

export function SortingIcon({mode = null, style ={}}) {
  const theme=useTheme()

  const iconStyle = {
    height: '16px',
    width: '16px',
    fill: mode === null?theme.palette.text.secondary:
      mode === false?theme.palette.warning.main:
        theme.palette.primary.main,
    ...style,
  }

  return (
    <Icon style={iconStyle} />
  )
}
