import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FigureData from "./FigureData/FigureData";
import RulerData from "./RulerData/RulerData";
import TimberData from "./TimberData/TimberData";
import {Box} from "@mui/material";

const ObjectData = () => {
  const stockpilePhotoState = useSelector(state => state.stockpilePhotoReducer)
  const {outline_selected_index, outline_viewed_index, ruler_selected_index, ruler_viewed_index,
    timber_viewed_index} = stockpilePhotoState

  return (
    <Box sx={{width: '100%', height: '100%', overflow: 'auto'}}>
      {(outline_viewed_index !== null || outline_selected_index !== null) &&
        <FigureData/>
      }
      {(ruler_selected_index !== null || ruler_viewed_index !== null) &&
        <RulerData/>
      }
      {timber_viewed_index !== null &&
        <TimberData/>
      }
    </Box>
  );
};

export default ObjectData;