import React, {useEffect, useState} from 'react';
import {Collapse, ListItem, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {treeItemButtonIconSize} from "../../../../../../Main/Map/Common/Styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RectangleIcon from "@mui/icons-material/Rectangle";
import StockpilesList from "./StockpilesList/StockpilesList";
import {setStockpilesAttr} from "../../../../../../../redux/reducers/stockpilesReducer";
import {useDispatch, useSelector} from "react-redux";
import {plotsTreePaddingLeft, stockpileTreeItemTextStyle, treeListItemStyle} from "../../../../leftBarStyles";
import {cuttingsAreasTemplate} from "../../../../../../Main/LeftPanel/Context/CAs/CuttingsAreas";
import ButtonsBlock from "../ButtonsBlock/ButtonsBlock";

const plotIconStyles = cuttingsAreasTemplate.styles

const PlotItem = ({plot, iAmEditor}) => {
    const dispatch = useDispatch()
    //const theme = useTheme()
    const [onOpen, setOnOpen] = useState(false)
    const selectedPlot = useSelector(state => state.stockpilesReducer.selectedPlot)
    const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
    const plotsFilters = useSelector(state => state.stockpilesReducer.plotsFilters)
    const isCreateStockpile = useSelector(state => state.stockpilesReducer.isCreateStockpile)
    const selectedFilters = Object.keys(plotsFilters).filter(filter => plotsFilters[filter].status)

    const listItemHandleClick = (e, plot) => {
        e.stopPropagation()
        setOnOpen(prev => !prev)
        if (selectedPlot?.id !== plot.id) {
            dispatch(setStockpilesAttr({selectedPlot: plot}))
        } else {
            dispatch(setStockpilesAttr({selectedPlot: null}))
        }
    }

    useEffect(() => {
        // При переходе на компонент, как DeepLink, открывает нужную деляну.
        if (selectedPlot != null && plot.id === selectedPlot) {
            setOnOpen(true)
        }
    }, [])

    return (<ListItem id={'plotItem'}
                      style={{
                          ...treeListItemStyle, paddingRight: '8px', paddingLeft: selectedFilters.length && plotsTreePaddingLeft
                      }}
        >
            <Stack flexDirection={'row'} alignItems={'center'} sx={{width: '100%'}} justifyContent={'space-between'}
                   onClick={(e) => listItemHandleClick(e, plot)}>
                <Stack flexDirection={'row'}>
                    {onOpen ? <ArrowDropDownIcon style={treeItemButtonIconSize} visibility={!plot.stockpiles.length ? 'hidden' : undefined}/> :
                        <ArrowRightIcon style={treeItemButtonIconSize} visibility={!plot.stockpiles.length ? 'hidden' : undefined}/>}
                    <RectangleIcon
                        fontSize={'small'}
                        style={{
                            strokeWidth: 2,
                            paddingRight: '5px',
                            stroke: plotIconStyles[plot.status].color || 'green',
                            fill: plotIconStyles[plot.status].fillColor,
                            fillOpacity: plotIconStyles[plot.status].fillOpacity,
                        }}
                    />
                    <Typography
                        style={{...stockpileTreeItemTextStyle}}>
                        {plot.name}
                    </Typography>
                </Stack>
                {iAmEditor &&
                  <ButtonsBlock plot={plot} isCreateStockpile={isCreateStockpile} selectedPhoto={selectedPhoto}
                                onOpen={onOpen} setOnOpen={setOnOpen} iAmEditor={iAmEditor}/>
                }
            </Stack>
            <Collapse in={onOpen} timeout="auto" unmountOnExit sx={{width: '100%'}}>
                <StockpilesList plot={plot} iAmEditor={iAmEditor}/>
            </Collapse>
        </ListItem>);
};

export default PlotItem;
