import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import {Divider, IconButton, Stack, Typography, useTheme} from "@mui/material";
import {overlayButtonStyle} from "../Map/Common/Styles";
import {
  cancelPencilDrawing,
  pencilSelectionString,
  projectsRemoveLastButtonString
} from "../Map/Common/Strings_RU";
import React from "react";
import Box from "@mui/material/Box";
import {removeLastVertex, resetAll, startPencilDrawing} from "../Map/Common/Drawing/pencil_drawing";
import {useSelector} from "react-redux";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import './css/pencil_drawing.css';

export function PencilButton() {
  const state = useSelector(state => state.measuringReducer.pencilSelection)
  const theme = useTheme()

  return (
    <Box style={overlayButtonStyle}>
      <Stack direction={'row'}>
        <IconButton
          style={{padding: 5}}
          title={pencilSelectionString}
          onClick={startPencilDrawing}
        >
          <DrawOutlinedIcon fontSize={'small'} />
        </IconButton>
        {state.enable &&
          <>
            <IconButton
              style={{padding: 5}}
              title={projectsRemoveLastButtonString}
              onClick={removeLastVertex}
            >
              <ReplyIcon fontSize={'small'} style={{color: theme.palette.primary.main}} />
            </IconButton>
            <IconButton
              style={{padding: 5}}
              title={cancelPencilDrawing}
              onClick={resetAll}
            >
              <DeleteOutlinedIcon fontSize={'small'} style={{color: theme.palette.primary.main}} />
            </IconButton>
          </>
        }
      </Stack>
      {state.enable &&
        <>
          <Divider />
          <Stack direction={'column'} className={'pencil_info_panel'}>
            <Box>
              <Typography variant={'subtitle1'} className={'pencil_info_string'}>{`Длина: ${state.distance} км.`}</Typography>
            </Box>
            <Box>
              <Typography variant={'subtitle1'} className={'pencil_info_string'}>{`Площадь: ${state.area} га.`}</Typography>
            </Box>
          </Stack>
        </>
      }
    </Box>
  )
}
