const defaultState = {
  panelVisible: false,
  area: 0,
  distance: 0,
  drawMode: null,
  shape: null,
  pencilSelection: {
    enable: false,
    paintMode: false,
    pencilDrawingPaused: false,
    distance: 0,
    area: 0,
    data: [],
  }
}

export function clearMeasuringPanel() {
  return {
    type: 'MAP_CLEAR_MEASURING_PANEL'
  }
}

export function resetMeasuringPanel() {
  return {
    type: 'MAP_RESET_MEASURING_PANEL',
  }
}

export function showHidePanel(show) {
  return {
    type: 'MAP_SHOW_HIDE_MEASURING_PANEL',
    show: show,
  }
}

export function setDrawingMode(mode) {
  return {
    type: 'MAP_MEASURING_SETDRAWING_MODE',
    mode: mode,
  }
}

export function setAreaAndDistance(area, distance) {
  return {
    type: 'MAP_MEASURING_SET_AREA_AND_DISTANCE',
    area: area,
    distance: distance,
  }
}

export function setPencilSelectionData(data) {
  return {
    type: 'MAP_MEASURING_SET_PENCIL_SELECTION_DATA',
    data: data,
  }
}

export function addElementToPencilData(element) {
  return {
    type: 'MAP_MEASURING_ADD_ELEMENT_TO_PENCIL_DATA',
    element: element,
  }
}

export function deletElementFromPencilData(index) {
  return {
    type: 'MAP_MEASURING_DELETE_ELEMENT_FROM_PENCIL_DATA',
    index: index,
  }
}

export function measuringReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_SHOW_HIDE_MEASURING_PANEL':
      return {...state, panelVisible: action.show}
    case 'MAP_MEASURING_SETDRAWING_MODE':
      return {...state, drawMode: action.mode}
    case 'MAP_MEASURING_SET_AREA_AND_DISTANCE':
      return {...state, area: action.area, distance: action.distance}
    case 'MAP_RESET_MEASURING_PANEL':
      return {...defaultState}
    case 'MAP_CLEAR_MEASURING_PANEL':
      return {...state, area: 0, distance: 0, drawMode: null, shape: null}
    case 'MAP_MEASURING_SET_PENCIL_SELECTION_DATA':
      return {...state, pencilSelection: {...state.pencilSelection, ...action.data}}
    case 'MAP_MEASURING_ADD_ELEMENT_TO_PENCIL_DATA':
      return {...state, pencilSelection: {...state.pencilSelection, data: [...state.pencilSelection.data, action.element]}}
    case 'MAP_MEASURING_DELETE_ELEMENT_FROM_PENCIL_DATA':
      return {...state, pencilSelection: {...state.pencilSelection, data: [...state.pencilSelection.data.slice(0, action.index), ...state.pencilSelection.data.slice(action.index + 1)]}}
    default:
      return state;
  }
}
