import React from 'react';
import Box from "@mui/material/Box";
import {Divider, InputAdornment, TextField} from "@mui/material";
import PlotLocationFields from "../../Common/RegionLocationFields/PlotLocationFields";
import Stack from "@mui/material/Stack";

const EditPlanetable = ({currentPlanetable, planetableData, setPlanetableData}) => {
  const {name, magnetic_declination, scale} = planetableData
  return (
    <>
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Справочники
      </Divider>
      <Box sx={{mb: 2}}>
        <PlotLocationFields setDataObj={setPlanetableData} dataObj={planetableData} count={3}
                            currentPlanetable={currentPlanetable}/>
      </Box>
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Данные
      </Divider>
      <Stack sx={{mb: 2}} spacing={2}>
        <TextField
          fullWidth
          size={'small'}
          label="Имя планшета"
          value={name}
          onChange={(e) => setPlanetableData(prev => {return {...prev, name: e.target.value}})}
          inputProps={{maxLength: 50}}
        />
        <TextField
          fullWidth
          size={'small'}
          type={'number'}
          label="Масштаб"
          value={scale}
          onChange={(e) => setPlanetableData(prev => {return {...prev, scale: e.target.value}})}
          inputProps={{maxLength: 10}}
          InputProps={{
            startAdornment: <InputAdornment position="start">1: </InputAdornment>,
          }}
        />
      </Stack>
    </>
  );
};

export default EditPlanetable;