import React from 'react';
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {blockTitle} from "../../rightPanelStyles";
import {useSelector} from "react-redux";

const GeoData = () => {
  const plotData = useSelector(state => state.rightPanelReducer.plotData)
  const {region, forestry, district_forestry, quarter_number} = plotData || {}

  const tableRows = [
    {name: 'Регион', value: region?.name || ''},
    {name: 'Лесничество', value: forestry?.name || ''},
    {name: 'Уч. лесничество', value: district_forestry?.name || ''},
    {name: 'Квартал', value: quarter_number || ''},
  ]

  return (
    <div>
      <Typography style={blockTitle}>Географическая справка</Typography>
      <Table size={'small'}>
        <TableBody>
          {tableRows.map(row => (
            <TableRow key={row.name}>
              <TableCell style={{padding: '5px 0 0 0'}}>{row.name}</TableCell>
              <TableCell style={{padding: '5px 0 0 0'}}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default GeoData;
