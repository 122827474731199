import React from 'react';
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import NewQuarterOperationButtons from "./NewQuarterOperationButtons/NewQuarterOperationButtons";
import NewPlotOperationButtons from "./NewPlotOperationButtons/NewPlotOperationButtons";
import SelectedFigureOperationButtons from "./SelectedFigureOperationButtons/SelectedFigureOperationButtons";


const ReportImageOperationButtons = ({selectedFigure}) => {
  const drawMode = useSelector(state => state.forestUseReportReducer.drawMode)
  const ButtonsBlock = () => {
    switch (drawMode) {
      case 'drawQuarter':
        return <NewQuarterOperationButtons/>
      case 'drawPlot':
        return <NewPlotOperationButtons/>
    }
    if (selectedFigure) {
      return <SelectedFigureOperationButtons selectedFigure={selectedFigure}/>
    }
  }

  return (
    <Box sx={{height: 30, display: 'flex', justifyContent: 'center'}}>
      <ButtonsBlock/>
    </Box>

  );
};

export default ReportImageOperationButtons;