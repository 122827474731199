import {useDispatch, useSelector} from "react-redux";
import {OverlayDraggablePane} from "../../../../Overlay/OvelayDraggablePane";
import {
  caProperiesDialogSet1CShow,
  caProperiesDialogSetCommonShow,
  resetCAPropsWindow,
  setTransportMonitoringInfo
} from "./caPropertiesReducer";
import {
  allotmentsString,
  ca1CInfoString,
  caCommonInfo,
  caCreatorString,
  caDeclarationVolumeString,
  caExportPercentString,
  caExportVolumeString,
  caHarvestVolumeString,
  caInfoString,
  caLastTeamString,
  caMeasurementVolumeString,
  caNameString,
  caNo1Cdata,
  caNumberString,
  caOwnerName,
  caPlanVolumeString,
  caQuarterNumberString,
  caRegionString,
  caResidualVolumeString,
  caSquareString,
  caStatusString,
  caValuePercentString,
  caValueString,
  caWorkEndString,
  caWorkStartString,
  cubicMeterString,
  districtForestryName,
  forestryString,
  hectareShortString,
  maxiFleetInfoString,
  meterCubicHectareString,
  parameterString,
  valueString
} from "../../../../Map/Common/Strings_RU";
import {Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {ArrowRight} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {TransportMonitoringData} from "./TransportMonitoringData/TransportMonitoringData";
import {Loading} from "../../../../Map/Common/Loading";
import {deleteTMLayers, getSpeciesThunk, getTMData} from "./TransportMonitoringData/TransportMonitoringDataCommon";
import {useEffect} from "react";

const propertiesCommonMap = {
  name: {name: caNameString, suffix: ''},
  ownerName: {name: caOwnerName, suffix: ''},
  region: {name: caRegionString, suffix: ''},
  forestry: {name: forestryString, suffix: ''},
  district_forestry: {name: districtForestryName, suffix: ''},
  quarter_number: {name: caQuarterNumberString, suffix: ''},
  allotments_numbers: {name: allotmentsString, suffix: ''},
  number: {name: caNumberString, suffix: ''},
  status: {name: caStatusString, suffix: ''},
  square: {name: caSquareString, suffix: hectareShortString},
}

const properties1CMap = {
  'ПланВырубки': {name: caPlanVolumeString, suffix: ''},
  'НачалоРазработки': {name: caWorkStartString, suffix: ''},
  'ОбъемПоДекларации': {name: caDeclarationVolumeString, suffix: cubicMeterString},
  'ОбъемПоЗаготовке': {name: caHarvestVolumeString, suffix: cubicMeterString},
  'ОбъемПоЗамерам': {name: caMeasurementVolumeString, suffix: cubicMeterString},
  'ОбъемПоВывозке': {name: caExportVolumeString, suffix: cubicMeterString},
  'ОбъемОстаток': {name: caResidualVolumeString, suffix: cubicMeterString},
  'ОкончаниеРазработки': {name: caWorkEndString, suffix: ''},
  'Отвел': {name: caCreatorString, suffix: ''},
  'Показатели': {name: caValueString, suffix: meterCubicHectareString},
  'ПоказателиПроц': {name: caValuePercentString, suffix: '%'},
  'ПоследняяБригада': {name: caLastTeamString, suffix: ''},
  'ПроцентВывозки': {name: caExportPercentString, suffix: '%'},
}

const stackStyle = {
  cursor: 'pointer',
}

const stackTextStyle = {
  userSelect: 'none',
}

const tableStyle = {
  marginTop: 0,
  width: '100%',
}

const headerCellStyle = {
  padding: '5px 2px 0px 2px',
  paddingTop: 0,
  width: '50%',
}

const cellStyle = {
  padding: '5px 2px 5px 2px',
  width: '50%',
}

const headerCellTextStyle = {
  color: '#AAAAAA',
  fontStyle: 'italic',
}

export const tableContainerStyle = {
  marginTop: 0,
  marginLeft: '24px',
  marginBottom: '1vh',
  overflowY: 'auto',
}

export function CAPropertiesWindow() { //окно подробных свойств деляны
  const state = useSelector(state => state.caPropertiesReducer)
  const dispatch = useDispatch()
  const theme = useTheme()
  const dataTM = getTMData()

  useEffect(() => {
      if (!dataTM?.data && !state.tm.loading && state.tm.loadingError === 0 && state.curCALayer) {
        getSpeciesThunk(state.curCALayer.elz_properties.id)
      }
  }, [state])

  if (!state.show)
    return null;

  function closeButtonHandler() { //закрыть окно
    deleteTMLayers()
    dispatch(resetCAPropsWindow())
  }

  function getCommonPropasTable() { //таблица общих свойств
    const arr = []
    let ind = 0;
    const props = state.curCALayer.elz_properties;
    Object.keys(propertiesCommonMap).map((key) => {
      let propsKey = props[key];
      if (propsKey !== undefined) {
        if (propsKey) {
          if (propsKey?.name)
            propsKey = props[key].name;
          arr.push(
            <TableRow key={ind++}>
              <TableCell style={cellStyle}>{propertiesCommonMap[key].name}</TableCell>
              <TableCell style={cellStyle}>{propsKey} {propertiesCommonMap[key].suffix}</TableCell>
            </TableRow>
          )
        }
      }
    })

    if (arr.length)
      return (
        <div style={tableContainerStyle}>
          <Table style={tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>
                  <div style={headerCellTextStyle}>{parameterString}</div>
                </TableCell>
                <TableCell style={headerCellStyle}>
                  <div style={headerCellTextStyle}>{valueString}</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arr}
            </TableBody>
          </Table>
        </div>
      )
    else
      return <></>;
  }

  function get1CPropasTable() { //таблица данных properties_1c
    const arr = []
    let ind = 0;
    const props = state.curCALayer.elz_properties['properties_1c'];
    if (props)
      Object.keys(properties1CMap).map(key => {
        if (props[key] !== undefined && props[key] !== null) {
          let data = props[key]
          if (data === undefined || data === null)
            data = 0;
          arr.push(
            <TableRow key={ind++}>
              <TableCell style={cellStyle}>{properties1CMap[key].name}</TableCell>
              <TableCell style={cellStyle}>{data} {properties1CMap[key].suffix}</TableCell>
            </TableRow>
          )
        }
      })

    if (arr.length)
      return (
        <div style={tableContainerStyle}>
          <Table style={tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>
                  <div style={headerCellTextStyle}>{parameterString}</div>
                </TableCell>
                <TableCell style={headerCellStyle}>
                  <div style={headerCellTextStyle}>{valueString}</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arr}
            </TableBody>
          </Table>
        </div>
      )
    else
      return (
        <Box style={tableContainerStyle}><Typography color={theme.palette.warning.main}>{caNo1Cdata}</Typography></Box>
      )
  }

  return (
    <OverlayDraggablePane
      headerText={caInfoString}
      closeAction={closeButtonHandler}
    >
      <Stack direction={'column'} spacing={1} style={{paddingRight: '16px'}} >
        <Stack
          direction={'row'}
          style={{cursor: 'pointer'}}
          onClick={() => {
            dispatch(setTransportMonitoringInfo({show: !state.tm.show}))
          }}
        >
          {state.tm.show ? <ArrowDropDownIcon/> : <ArrowRight/>}
          <Typography style={stackTextStyle}>{maxiFleetInfoString}</Typography>
          {state.tm.loading && <Loading text={''}/>}
        </Stack>
        {state.tm.show && <TransportMonitoringData id={state.curCALayer?.elz_properties?.id} />}
        <Stack
          direction={'row'}
          style={stackStyle}
          onClick={() => {
            dispatch(caProperiesDialogSetCommonShow(!state.commonShow))
          }}>
          {state.commonShow ?
            <ArrowDropDownIcon/>
            :
            <ArrowRight/>
          }
          <Typography style={stackTextStyle}>{caCommonInfo}</Typography>
        </Stack>
        {state.commonShow && getCommonPropasTable()}
        <Stack
          direction={'row'}
          style={{cursor: 'pointer'}}
          onClick={() => {
            dispatch(caProperiesDialogSet1CShow(!state.system1cShow))
          }
          }>
          {state.system1cShow ?
            <ArrowDropDownIcon/>
            :
            <ArrowRight/>
          }
          <Typography style={stackTextStyle}>{ca1CInfoString}</Typography>
        </Stack>
        {state.system1cShow && get1CPropasTable()}
      </Stack>
    </OverlayDraggablePane>
  )
}
