import {mbannerGoToPCString, mbannerLinkText, warnind2String} from "./Strings_RU";
import {Typography, useTheme} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useState} from "react";
import {VideoModal} from "../../../Common/VideoModal";

export function MobileBanner2() {
  const [videoShow, setVideoShow] = useState(false)
  //const url = 'https://www.youtube.com/watch?v=tVjNQ7bH7Iw';
  const url = 'http://www.youtube.com/embed/tVjNQ7bH7Iw'
  const theme = useTheme()

  const style = {
    position: 'relative',
    //maxHeight: '20%',
    backgroundColor: 'white',
    padding: '5px',
    marginLeft: '6px',
    marginRight: '6px',
  }

  const linkStyle = {
    fontSize: '80%',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  }

  function closeVideoHandle() {
    setVideoShow(false)
  }

  return (
    <>
      <Stack direction={'column'} style={style}>
        <Typography style={{fontSize: '90%', fontWeight: 600}}>{warnind2String}</Typography>
        <Typography style={{fontSize: '80%',}}>{mbannerGoToPCString}</Typography>
        <Typography style={linkStyle} onClick={() => setVideoShow(true)}>{mbannerLinkText}</Typography>
      </Stack>
      {videoShow &&
        <VideoModal url={url} closeCallback={closeVideoHandle}/>
      }
    </>
  )
}
