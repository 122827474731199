import {otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {djangoErrorsParse} from "../../utils/djangoErrorsParse";
import {getOrganizationDataThunk, getOrganizationUsersThunk, setOrgInfoAttr} from "./organizationInfoReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_PAYMENT_ATTR = 'SET_PAYMENT_ATTR'

const initialState = {
  sendPaymentSumError: {status: false, message: ''},
  selectedTariff: null,
  selectedTariffLimit: null,
  showPaymentWindow: {status: false, type: 'none'},  // types: 'none', 'card', 'bill'
}

// редьюсер формы оплаты
export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_ATTR: {
      return {...state, ...action.payload}
    }
    default: return state
  }
}

export const setPaymentAttr = (payload) =>  ({type: SET_PAYMENT_ATTR, payload})

export const sendPaymentSumThunk = (sum, payment_period) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.payment.sendPaymentSum({amount_value: sum, payment_period: payment_period})
      window.open(res.data.confirmation_url, "_self")
    } catch (e) {
      console.error(e)
      dispatch(setSnack('error', e.response?.data ? djangoErrorsParse(e.response.data)
        : 'При отправке суммы платежа произошла ошибка. Попробуйте позже'))
    }
  }
}

export const getBillThunk = (period, setBill, setBillIsLoading) => {
  return async (dispatch) => {
    try {
      setBillIsLoading(true)
      let res = await otherDataAPI.tariff.getBill({period: period})
      setBillIsLoading(false)
      setBill(res.data)
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const changeTariffThunk = (tariffId, organizationIsActive, handleCloseChangeTariffWindow, changed_users = [],
                                  deleted_users = []) => {
  return async (dispatch, getState) => {
    try {
      let res = await otherDataAPI.tariff.changeTariff({new_tariff: tariffId, changed_users, deleted_users})
      dispatch(getOrganizationDataThunk())
      dispatch(getOrganizationUsersThunk())
      dispatch(setSnack('success', `Тариф изменен на "${res.data.tariff.name}"`))
      handleCloseChangeTariffWindow()
      if (!organizationIsActive) {
        dispatch(setPaymentAttr({showPaymentWindow: {status: true, type: 'card'}}))
      }
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const getSelectedTariffRulesThunk = (selectedTariffId) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.tariff.getNewTariffRules(selectedTariffId)
      dispatch(setPaymentAttr({selectedTariffLimit: res.data}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
