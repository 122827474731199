import {getMap1} from "../GlobalObjects";
import {createRedArrowByPoint, createRedArrowByPolygon} from "./redArrow";

/**
 * Перелет по координатам точки. Карта центрируется по точке.
 * @param latlng {L.LatLng} - координаты точки
 * @param arrow {boolean} - создать красную стрелку
 * @param vShift {number} - доп. смещение по вертикали
 */
export function flyToPoint(latlng, arrow = true, vShift = 0) {
  getMap1().setView(latlng)
  if (arrow)
    createRedArrowByPoint(latlng, vShift)
}

/**
 * Перелет по координатам полигона. Карта центрируется по центральной точке границ полигона.
 * @param latlngs {L.LatLng} - координаты полигона
 * @param arrow {boolean} - создать красную стрелку над слоем
 * @param zoomTo - переместить карту на слой с масштабированием
 */
export function flyToPolygon(latlngs, arrow = true, zoomTo = false) {
  const poly = L.polygon(latlngs)
  const map = getMap1()

  function drawArraoOnZoomEnd() {
    createRedArrowByPolygon(latlngs)
    map.off('zoomend', drawArraoOnZoomEnd)
  }

  if (!zoomTo) {
    map.setView(poly.getBounds().getCenter())
    if (arrow)
      createRedArrowByPolygon(latlngs)
  } else {
    map.fitBounds(poly.getBounds())
    const zoom = map.getZoom()
    if (zoom > 0) {
      map.setZoom(zoom - 1, {animate: false})
    }
    if (arrow)
      map.on('zoomend', drawArraoOnZoomEnd)
  }
}

/**
 * Перелет по координатам мультиполигона. Не создает красной стрелки.
 * @param multipolygon - GeoJson feature multipolygon
 */
export function flyToMultiPolygon(multipolygon) {
  const bounds = L.geoJSON(multipolygon).getBounds()
  getMap1().fitBounds(bounds)
}

/**
 * Перелет по слою лифлета с указанием типа.
 * Если тип "Marker" - перелет по точке, иначе по полигону.
 * @param layer - слой имеющий getLatLng() или getLatLngs()
 * @param type {string} - тип
 * @param arrow - создать красную стрелку
 */
export function flyToShape(layer, type, arrow = true) {
  if (type === 'Marker') {
    flyToPoint(layer.getLatLng(), arrow)
  } else {
    flyToPolygon(layer.getLatLngs(), arrow)
  }
}
