import {useSelector} from "react-redux";
import {DialogActions, DialogContent,  DialogTitle, useTheme} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import * as React from "react";
import {FTextField} from "../../../LeftPanel/Context/Projects/FTextFiled";
import {useState} from "react";
import {cancelText, okText} from "../Strings_RU";

export function EnterDialog() {
  const [value, setValue] = useState('')
  const [error, setError] = useState(null)
  const state = useSelector(state => state.enterDialogReducer)
  const theme = useTheme()

  if (!state.open)
    return null;

  function closeDialogHandler(result, value) {
    setError(null)
    setValue('')
    if (result === 'cancel')
      state.callback('cancel', null)
    else
      state.callback(result, value)
  }

  function endEditing(value) {
    setValue(value)
    checkAndClose(value)
  }

  function cancelEditing() {
    closeDialogHandler('cancel')
  }

  function checkAndClose(value) {
    if (!value)
      setError('name')
    else
      closeDialogHandler('ok', value)
  }

  return (
    <Dialog open={state.open}>
      <DialogTitle style={{backgroundColor: theme.palette.primary.main, padding: '0.5vh 0.5vh 0.5vh 1vh'}}>
        {state.header}
      </DialogTitle>
      <DialogContent style={{marginTop: '1.5vh', paddingBottom: '1vh'}}>
        <FTextField style={{paddingTop: '0.3vh'}} _endEditing={endEditing} _cancelEdit={cancelEditing} variant={'outlined'} value={''} error={error !== null}/>
      </DialogContent>
      <DialogActions>
        <Button id="" variant={"outlined"} color={'primary'} onClick={() => checkAndClose()}>{okText}</Button>
        <Button variant={"outlined"} color={'warning'} onClick={cancelEditing}>{cancelText}</Button>
      </DialogActions>
    </Dialog>
  )
}
