import {useEffect} from 'react';
import {TitleCommonBlock} from "../Common/TitleCommonBlock";
import Leftbar from "./Leftbar/Leftbar";
import ImageContainer from "./Image/ImageContainer";
import BottomBlock from "./BottomBlock/BottomBlock";
import Rightbar from "./Rightbar/Rightbar";
import {Stack, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "./Toolbar/Toolbar";
import {
  bottomBlockContainerStyle,
  centerBlockContainerStyle,
  imageContainerStyle,
  leftbarContainerStyle,
  mainContentBlockContainer,
  rightbarContainerStyle,
  stockpilePageContainerStyle,
  toolbarContainerStyle
} from "./stockpilePageStyles";
import {hideMapDialog, showMapDialog} from "../Main/Map/Common/Dialog/DialogReducer";
import {
  deletePhotoFromSelectedStockpile,
  deletePhotoThunk,
  replacePhotoInSelectedStockpile,
  setPhotosToSelectedStockpile
} from "../../redux/reducers/stockpilesReducer";
import {useDispatch, useSelector} from "react-redux";
import {setPhotoAttr} from "../../redux/reducers/stockpilePhotoReducer";
import ClosedStockpileToolbar from "./ClosedStockpileToolbar/ClosedStockpileToolbar";
import {setSnack} from "../Main/Map/Common/Dialog/Snack/snackReducer";
import {cannotIDo} from "../Main/Map/Common/tariffs";
import {urlGenerator} from "../../utils/urlGenerator";

const Stockpiles = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const plotsStockpiles = useSelector(state => state.stockpilesReducer.plotsStockpiles)
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const selectedPlot = plotsStockpiles?.find(plot => plot.id === selectedStockpile?.xplot)
  const images = useSelector(state => state.stockpilesReducer.selectedStockpile?.images)
  const buttons = {'Да': {color: theme.palette.warning.main}, 'Нет': {color: theme.palette.primary.main}}
  const stockpilePhotosMessages = useSelector(state => state.websocketReducer.stockpilePhotosMessages)
  const iAmEditor = !cannotIDo.writeStockpilesModule() && !cannotIDo.editorAction()

  function addWebsocketPhoto(lastPhoto) {
    let isPhotoFromSelectedStockpile = selectedStockpile.id === lastPhoto["photodata"];
    let isPhotoUploaded = selectedStockpile.images.find((image) => image.id === lastPhoto.id)
    if (lastPhoto.error) {
      dispatch(setSnack('error', lastPhoto.error))
    } else if (isPhotoFromSelectedStockpile) {
      if (isPhotoUploaded) {
        dispatch(replacePhotoInSelectedStockpile(lastPhoto))
        selectedPhoto?.id === lastPhoto.id && dispatch(setPhotoAttr({selectedPhoto: lastPhoto}))
      } else {
        dispatch(setPhotosToSelectedStockpile([lastPhoto]))
      }
    }
  }

  function deleteWebsocketPhoto(lastPhoto) {
    let isPhotoFromSelectedStockpile = selectedStockpile.images.some(image => image.id === lastPhoto["id_img"])
    if (lastPhoto.error) {
      dispatch(setSnack('error', lastPhoto.error))
    } else if (isPhotoFromSelectedStockpile) {
      dispatch(deletePhotoFromSelectedStockpile(lastPhoto["id_img"]))
      selectedPhoto?.id === lastPhoto["id_img"] && dispatch(setPhotoAttr({selectedPhoto: null}))
    }}

  //обработка получения данных фото штабеля через websocket
  useEffect(() => {
    let newPhotoData = stockpilePhotosMessages[stockpilePhotosMessages.length - 1]
    let lastPhoto = newPhotoData?.data
    if (selectedStockpile && lastPhoto){
      switch(newPhotoData.code) {
        case 200:
          addWebsocketPhoto(lastPhoto)
          break;
        case 204:
          deleteWebsocketPhoto(lastPhoto)
      }
    }
  }, [stockpilePhotosMessages])

  const keyDownHandler = (e) => {
    switch (e.code) {
      case 'Delete': {
        selectedPhoto &&
        dispatch(showMapDialog('warning', 'Удаление фотографии', 'Вы уверены что хотите удалить ' +
          'выбранную фотографию, без возможности восстановления?', buttons, (e) => {
          dispatch(hideMapDialog())
          if (e === 'Да') {
            dispatch(deletePhotoThunk(selectedPhoto.id, images))
          }
        }))
      }
    }
  }

  function goToBack(navigate) {
    navigate(urlGenerator().main)
  }

  if (cannotIDo.useStockpilesModule())
    return null;


  return (
    <>
      <TitleCommonBlock title={'Замер штабелей'} closeButtonHandler={goToBack}/>
      <Box sx={stockpilePageContainerStyle} onKeyDown={keyDownHandler}>
        <Stack direction={'row'} sx={mainContentBlockContainer}>
          <Box sx={leftbarContainerStyle}>
            <Leftbar iAmEditor={iAmEditor}/>
          </Box>
          <Box sx={centerBlockContainerStyle}>
            <Box sx={toolbarContainerStyle}>
              {(selectedPlot?.is_closed || !iAmEditor)
                ? <ClosedStockpileToolbar iAmEditor={iAmEditor}/>
                : <Toolbar iAmEditor={iAmEditor}/>
              }
            </Box>
            <Box sx={imageContainerStyle}>
              <ImageContainer iAmEditor={iAmEditor}/>
            </Box>
          </Box>
          <Box sx={rightbarContainerStyle}>
            <Rightbar />
          </Box>
        </Stack>
        <Box sx={bottomBlockContainerStyle}>
          <BottomBlock iAmEditor={iAmEditor}/>
        </Box>
      </Box>
    </>
  )
}

export default Stockpiles;
