import {
  CircularProgress,
  ClickAwayListener,
  debounce,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField
} from "@mui/material";
import {useCallback, useEffect, useRef, useState} from "react";
import {getMap1} from "../../Map/GlobalObjects";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {setSnack} from "../../Map/Common/Dialog/Snack/snackReducer";
import {useDispatch, useSelector} from "react-redux";
import {resetMapSearchPanel, setMapSearchAttrs} from "./mapSearchReducer";
import {createRedArrowByPoint} from "../../Map/Common/redArrow";
import {dataAPI} from "../../../../api/api";
import {flyToPoint, flyToPolygon} from "../../Map/Common/fly";
import {parseFloatString} from "../../Map/Common/MiscFunction";
import {parseCoordsFromString, validateLatLng} from "../../Map/Common/geoFunctions";
import {errorString} from "../../Map/Common/Strings_RU";

const controllers = {
  cas: null,
  kv: null,
  geo: null,
}

export function SearchItemsMenu({data, menuAnchor, showMenu, itemClick, menuType, latlng}) {
  const ref = useRef()
  let ind = 0;

  useEffect(() => {
    if (menuAnchor) {
      const observer = new ResizeObserver(() => {
        const pos = getAnchorPosition()
        ref.current["style"].left = pos.x + 'px';
        ref.current["style"].top = pos.y + 'px';
      })
      observer.observe(window.document.body)
      return () => {
        observer.unobserve(window.document.body)
      }
    }
  })

  if (!menuAnchor)
    return null;

  function getAnchorPosition() {
    const rect = menuAnchor.getBoundingClientRect()
    return {
      x: menuAnchor.offsetLeft - rect.width/2, //rect.width/2 поправка на translate
      y: menuAnchor.offsetTop + rect.height,
    }
  }

  const style = {
    minWidth: '100px',
    maxWidth: '80%',
    maxHeight: '60%',
    overflowY: 'auto',
    position: 'absolute',
    padding: 0,
    boxSizing: 'border-box',
    pointerEvents: 'all',
  }

  let nullItemText, secondNullText;
  switch (menuType) {
    case 'empty':
      nullItemText = 'Введите минимум 3 символа';
      break;
    case 'notfound':
      nullItemText = 'Ничего не найдено';
      break;
    case 'search':
      nullItemText = 'Идет поиск';
      break;
    case 'complete':
      nullItemText = 'Поиск завершен';
      break;
    case 'coordinates':
      nullItemText = `Широта: ${isNaN(latlng.lat) ? errorString : latlng.lat}`;
      secondNullText = `Долгота: ${isNaN(latlng.lng) ? errorString : latlng.lng}`;
      break;
    default:
      nullItemText = 'Ошибка.';
  }

  function closeMenu(event) {
    if (event.target.id !== 'map_search_panel_input')
      showMenu(false, event)
  }

  function getMenuItems(type) {
    const list = data[type].data;
    if (list.length === 0)
      return null;

    const arr = [
      <MenuItem
        key={ind++}
        disabled={true}
      >
        {data[type].name}
      </MenuItem>
    ]

    data[type].data.forEach(item => {
      arr.push(
      <MenuItem
        key={ind++}
        id={`search_item_${type}-${item.id}`}
        style={{whiteSpace: 'normal'}}
        onClick={itemClick}
        disabled={item.disabled}
      >
        {item.name} {item.description}
      </MenuItem>
      )
    })
    return arr;
  }

  return (
    <ClickAwayListener
      onClickAway={closeMenu}
      disableReactTree
      mouseEvent={'onMouseDown'}
      touchEvent={'onTouchStart'}
    >
      <Paper
        ref={ref}
        elevation={4}
        style={style}
      >
        <MenuItem
          divider={true}
          id={'search_item_0'}
          onClick={itemClick}
          disabled={menuType !== 'coordinates'}
        >
          {nullItemText}
          {secondNullText && <><br/>{secondNullText}</>}
          {menuType === 'search' && <CircularProgress size={20} style={{marginLeft: '10px'}}/>}
        </MenuItem>
         {Object.keys(data).map((type) => {
            return getMenuItems(type)
          })
         }
      </Paper>
    </ClickAwayListener>
  )
}

export const MapSearchPanel = () => {
  const state = useSelector(state => state.mapSearchReducer)
  const [prevString, setPrevString] = useState('')
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [latlng, setLatLng] = useState({lat: '', lng: ''})
  const dispatch = useDispatch()
  const boxRef = useRef()
  const minRequestChars = 2;

  function setMenuType(type) {
    dispatch(setMapSearchAttrs({
      ...state,
      menuType: type,
    }))
  }

  function setResult(type, data) {
    const state = window.store.getState().mapSearchReducer;
    controllers[type] = null;
    let complete = true;
    Object.values(controllers).forEach(value => {
      if (value)
        complete = false;
    })

    /*let isResults = data.length !== 0;
    if (complete) {
      Object.values(state.results).forEach(value => {
        if (value.data.length !== 0)
          isResults = true;
      })
    }*/
    //console.log(isResults)

    dispatch(setMapSearchAttrs({
      ...state,
      menuType: /*!isResults?'notfound':*/complete?'complete':'search',
      results: {...state.results, [type]: {...state.results[type], data: data}}
    }))
  }

  let loadingData = useCallback(
    debounce((text) => {
      const searchStringURI = encodeURI(text.trim()).replace('/', '%2F')

      //CAS
      if (controllers.cas) {
        controllers.cas.abort()
      }
      controllers.cas = new AbortController()
      dataAPI.searching.cas(searchStringURI, controllers.cas.signal).then(res => {
        setResult('cas', res.data.data)
      }).catch(() => {
        setResult('cas', [])
      })

      //KV
      if (controllers.kv) {
        controllers.kv.abort()
      }
      controllers.kv = new AbortController()
      dataAPI.searching.kv(searchStringURI, controllers.kv.signal).then(res => {
        setResult('kv', res.data.data)
      }).catch(() => {
        setResult('kv', [])
      })

      //Geocoder
      if (controllers.geo) {
        controllers.geo.abort()
      }
      controllers.geo = new AbortController()
      dataAPI.searching.geo(searchStringURI, controllers.geo.signal).then(res => {
        setResult('geo', res.data.data)
      }).catch(() => {
        setResult('geo', [])
      })
    }, 1200), []
  )

  useEffect(() => {
    if (state.inputValue.trim().length > minRequestChars) {
      const text = state.inputValue.trim()
      const textParsed = parseFloatString(text);
      if (!textParsed) {
        const latlng = parseCoordsFromString(text)
        if (!isNaN(latlng.lng) && !isNaN(latlng.lat)) { //если введено два числа - это стопудов поиск по координатам
          if (state.menuType !== 'coordinates')
            setMenuType('coordinates')
          setLatLng({lat: latlng.lat, lng: latlng.lng})
          //сбрасываем результаты поиска, чтобы большое меню не закрывало место перемещения:
          //dispatch(setMapSearchAttrs({...state, options: []}))
        } else {
          if (prevString !== text) {
            if (state.menuType !== 'search')
              setMenuType('search')
            loadingData(text)
            setPrevString(text)
          }
        }
      } else { //если введено только число - это поиск по координатам
        if (state.menuType !== 'coordinates')
          setMenuType('coordinates')
        // noinspection JSCheckFunctionSignatures
        setLatLng({lat: textParsed, lng: ''})
      }
    } else {
      if (state.menuType !== 'empty')
        setMenuType('empty')
    }
  }, [state.inputValue])

  function clearText(event) {
    event.stopPropagation()
    event.preventDefault()
    setMenuType('empty')
    dispatch(resetMapSearchPanel())
  }

  function showMenu(show) {
    if (show) {
      if (!menuAnchor)
        setMenuAnchor(boxRef.current)
    } else {
      setMenuAnchor(null)
    }
  }

  function itemClick(event) {
    showMenu(false)
    const id = event.target.id;

    if (id === 'search_item_0') {
      goToCoordinatesString()
    } else {
      const [type, objectID] = id.replace('search_item_', '').split('-')
      const dispatch = window.elz_dispatch;
      switch (type) {
        case 'cas':
          dataAPI.plots.getShortInfo(objectID).then(res => {
            const tmp = L.geoJSON(res.data).getLayers()[0]
            flyToPolygon(tmp.getLatLngs())
          }).catch(err => {
            console.error(err)
            dispatch(setSnack('error', 'Произошла ошибка при получении координат.'))
          })
          break;
        case 'kv':
          dataAPI.searching.getCoordinates('kv', objectID).then(res => {
            getMap1().setView([res.data.data.lat, res.data.data.lng])
            createRedArrowByPoint(L.latLng([res.data.data.lat, res.data.data.lng]))
          }).catch(err => {
            console.error(err)
            dispatch(setSnack('error', 'Произошла ошибка при получении координат.'))
          })
          break;
        case 'geo':
          dataAPI.searching.getCoordinates('geo', objectID).then(res => {
            getMap1().setView([res.data.data.lat, res.data.data.lng])
            createRedArrowByPoint(L.latLng([res.data.data.lat, res.data.data.lng]))
          }).catch(err => {
            console.error(err)
            dispatch(setSnack('error', 'Произошла ошибка при получении координат.'))
          })
      }
    }
  }

  function goToCoordinatesString() {
    let error = false;
    if (isNaN(latlng.lat) || isNaN(latlng.lng)) {
      error = true;
    }

    const validates = validateLatLng(latlng)

    if (isNaN(validates.lat) || isNaN(validates.lng))
      error = true;
    if (error) {
      dispatch(setSnack('warning', "Неверные координаты"))
    } else {
      flyToPoint(L.latLng(latlng.lat, latlng.lng))
      showMenu(false)
    }
  }

  const inputPanelStyle = {
    position: 'absolute',
    minWidth: '30%',
    pointerEvents: 'all',
    left: '50%',
    top: '1vh',
    transform: 'translateX(-50%)',
  }

  return (
    <>
      <div style={inputPanelStyle} ref={boxRef}>
        <TextField
          fullWidth
          autoComplete={'off'}
          size="small"
          value={state.inputValue}
          onChange={(event) => {
            dispatch(setMapSearchAttrs({...state, inputValue: event.target.value}))
            if (!menuAnchor)
              showMenu(true)
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (state.menuType === 'coordinates')
                goToCoordinatesString()
            }
          }}
          onClick={() => {
            if (!menuAnchor)
              showMenu(true)
          }}
          placeholder={'Поиск...'}
          InputProps={{
            id: 'map_search_panel_input',
            style: {
              height: 30,
              backgroundColor: 'white',
              fontSize: '90%',
            },
            endAdornment:
              <InputAdornment position="end">
                <IconButton size={'small'} onMouseDown={clearText}>
                  <CloseOutlinedIcon fontSize={'small'}/>
                </IconButton>
              </InputAdornment>
          }}
        />
      </div>
      <SearchItemsMenu
        data={state.results}
        showMenu={showMenu}
        menuAnchor={menuAnchor}
        menuType={state.menuType}
        itemClick={itemClick}
        latlng={latlng}
      />
    </>
  )
}
