import React, {memo} from 'react';
import {useSelector} from "react-redux";
import {coordCorrection} from "../../../../../svgComponentsHelpersFunctions/coordCorrection";
import {stockpileSvgConfig} from "../svgConfig";

const CuttingLine = memo(() => {
  const cutPoints = useSelector(state => state.stockpilePhotoReducer.cutPoints)
  const scale = useSelector(state => state.stockpilePhotoReducer.scale)
  const zoom = useSelector(state => state.stockpilePhotoReducer.zoom)

  const {cut_point_fill, cut_point_radius} = stockpileSvgConfig

  return (
    <>
      {cutPoints?.length === 2 &&
        <line x1={coordCorrection(cutPoints[0].x, zoom, scale)} y1={coordCorrection(cutPoints[0].y, zoom, scale)}
              x2={coordCorrection(cutPoints[1].x, zoom, scale)} y2={coordCorrection(cutPoints[1].y, zoom, scale)}
              stroke="red"
              strokeDasharray="4 4"
              strokeWidth={3}
        />
      }
      {cutPoints.map((point, i) => (
        <circle
          key={i}
          cx={coordCorrection(point.x, zoom, scale)}
          cy={coordCorrection(point.y, zoom, scale)}
          r={cut_point_radius}
          fill={cut_point_fill}
          opacity={1}
        />
      ))}
    </>
  );
})

export default CuttingLine;