import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const SelectObjectType = ({figureType, setFigureType}) => {
  return (
    <FormControl fullWidth size={'small'}>
      <InputLabel>Тип объекта</InputLabel>
      <Select
        label="Тип объекта"
        value={figureType}
        onChange={(e) => setFigureType(e.target.value)}
      >
        <MenuItem value={'Polygon'}>Полигон</MenuItem>
        <MenuItem value={'MultiLineString'}>Линия</MenuItem>
        <MenuItem value={'MultiPoint'}>Точки</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectObjectType;