import React, {memo} from 'react';
import {useSelector} from "react-redux";
import {stockpileSvgConfig} from "../svgConfig";
import {coordCorrection} from "../../../../../svgComponentsHelpersFunctions/coordCorrection";
//import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";

const Timbers = memo(() => {
  const timbers_px_include = useSelector(state => state.stockpilePhotoReducer.timbers_px_include)
  const timber_viewed_index = useSelector(state => state.stockpilePhotoReducer.timber_viewed_index)
  const timber_selected_index = useSelector(state => state.stockpilePhotoReducer.timber_selected_index)
  const zoom = useSelector(state => state.stockpilePhotoReducer.zoom)
  const scale = useSelector(state => state.stockpilePhotoReducer.scale)
  useSelector(state => state.stockpilePhotoReducer.mode)

  const {timber_fill, timbers_opacity, timber_viewed_fill, timber_selected_fill} = stockpileSvgConfig
  const currentTimberFill = (index) => {
    if (index === timber_selected_index) return timber_selected_fill
    if (index === timber_viewed_index) return timber_viewed_fill
    return timber_fill
  }
  // const mouseLeaveTimberHandler = () => {
  //   mode === 'view' && dispatch(setPhotoAttr({timber_viewed_index: null}))
  // }
  //
  // const mouseEnterTimberHandler = (index) => {
  //   mode === 'view' &&  dispatch(setPhotoAttr({timber_viewed_index: index}))
  // }

  return (
    <>
      {timbers_px_include?.map((timber, index) => {
        const fill = currentTimberFill(index)
        return (
        <circle
          // onMouseLeave={mouseLeaveTimberHandler}
          // onMouseEnter={() => mouseEnterTimberHandler(index)}
          key={index}
          i={'includeTimber_' + index}
          cx={coordCorrection(timber.x, zoom, scale)}
          cy={coordCorrection(timber.y, zoom, scale)}
          r={coordCorrection(timber.r, zoom, scale)}
          fill={fill}
          opacity={timbers_opacity}
        />
      )}
      )}
    </>
  );
})

export default Timbers;
