import React from 'react';
import {TextField, Stack} from "@mui/material";

const FilterVolume = ({startVolume, setStartVolume, endVolume, setEndVolume}) => {
  return (
    <Stack spacing={2} sx={{p: 2}}>
      <TextField size={'small'}
                 variant={'standard'}
                 label={'Объем от'}
                 type={'number'}
                 value={startVolume || ''}
                 inputProps={{maxLength: 10}}
                 onChange={(e) => setStartVolume(e.target.value)}
      />
      <TextField size={'small'}
                 variant={'standard'}
                 label={'Объем до'}
                 type={'number'}
                 value={endVolume || ''}
                 inputProps={{maxLength: 10}}
                 onChange={(e) => setEndVolume(e.target.value)}
      />
    </Stack>
  );
};

export default FilterVolume;