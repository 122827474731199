export const emptyDeclarationState = {
  dialogOpen: false, //dialog is open
  workspaces: null, //list of all workspaces
  workspace: null, //current workspace
  workspaceError: false,
  name: '', //current name
  nameError: false,
  file: null, //current file data
  fileError: false,
  loading: false, //loading data progressed
  loadingError: false,
}

const defaultState = {
  declatationXML: {
    ...emptyDeclarationState,
  }
}

export function setDeclarationXMLAttr(payload) {
  return {
    type: 'DECLARATION_XML_SET_ATTR',
    payload: payload,
  }
}

export function cuttingAreasReducer(state = defaultState, action) {
  switch (action.type) {
    case 'DECLARATION_XML_SET_ATTR':
      return {...state, declatationXML: {...state.declatationXML, ...action.payload}}
    default:
      return state;
  }
}
