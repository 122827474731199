import {IconButton, Stack} from "@mui/material";
import {useRef, useState} from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import './user_password_field.css';

export function UserPasswordField({value, setValue, style={}}) {
  const [showPassword, setShowPassword] = useState(false)
  const ref = useRef()

  const inputStyle = {
    border: 0,
    outline: 'none',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.4375em',
  }

  const divStyle = {
    border: '1px solid grey',
    padding: '8px',
    marginRight: '5px',
  }

  const iconStyle = {
    marginRight: '0.5vh',
    padding: 0,
  }

  return (
      <Stack direction={'row'} alignItems={'center'} style={divStyle}>
        <input
          style={{...inputStyle, ...style}}
          ref={ref}
          value={value}
          type={showPassword?'text':'password'}
          autoComplete={'new-password'}
          onChange={event => {
            setValue(event.target.value)
          }}
        />
        <IconButton
          style={iconStyle}
          onClick={() => setShowPassword(!showPassword)}
          edge="end"
          tabIndex={-1}
        >
          {!showPassword ? <VisibilityOffOutlinedIcon fontSize={'small'}/> :
            <VisibilityOutlinedIcon fontSize={'small'}/>}
        </IconButton>
      </Stack>
  )
}
