import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {IconButton, Stack, Tab, Tabs, Typography} from "@mui/material";
import Violations from "./Violations/Violations";
import {setLeftDrawerCurTab} from "../../LeftDrawerReducer";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {Warehouses} from "./Warehouses/Warehouses";
import {Pillars} from "./Pillars/Pillars";
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import {setInfrastructureCurTab} from "./infractructureReducer";

/**
 * Компонент отображения содержимого модуля инфраструктуры в левой панели.
 */
export function Infrastructure() {
  const dispatch = useDispatch();
  const curTab = useSelector(state => state.infrastructureReducer.curTab)

  const handleChange = (event, newValue) => {
    dispatch(setInfrastructureCurTab(newValue))
  }

  return (
    <Stack flexDirection={'column'} sx={{height: '100%'}}>
      <Stack
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography align={"center"}>
          Инфраструктура
        </Typography>
        <IconButton
          onClick={() => {
            dispatch(setLeftDrawerCurTab(null));
          }}
        >
          <CloseIcon/>
        </IconButton>
      </Stack>
      <Tabs value={curTab} onChange={handleChange}>
        <Tab icon={<ReportProblemOutlinedIcon/>} label="Нарушения" value='violations' disableRipple/>
        <Tab icon={<WarehouseIcon/>} label="Склады" value='warehouses' disableRipple/>
        <Tab icon={<SignpostOutlinedIcon/>} label="Столбы" value='pillars' disableRipple/>
      </Tabs>
      <Box sx={{flex: 1, position: 'relative', padding: '1vh'}}>
        {curTab === 'violations' && <Violations />}
        {curTab === 'warehouses' && <Warehouses />}
        {curTab === 'pillars' && <Pillars/>}
      </Box>
    </Stack>
  )
}

