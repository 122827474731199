import React from 'react';
import {ZoomIn, ZoomOut} from "@mui/icons-material";
import {Slider} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useDispatch, useSelector} from "react-redux";
import {planetableSvgReducer, setPlanetableSvgAttr} from "../../../../redux/reducers/planetableSvgReducer";

const ZoomSlider = () => {
  const dispatch = useDispatch()
  const zoom = useSelector(state => state.planetableSvgReducer.zoom)

  const handleChange = (e, newValue) => {
    dispatch(setPlanetableSvgAttr({zoom: newValue}))
  }
  return (
    <>
      <Stack  spacing={2} direction="row"  alignItems="center">
        <ZoomOut sx={{color: 'grey'}} />
        <Slider  size={'small'} sx={{width: 70 }} min={100} max={1000} aria-label="Zoom" value={zoom} onChange={handleChange} />
        <ZoomIn sx={{color: 'grey'}} />
      </Stack>
    </>
  );
};

export default ZoomSlider;