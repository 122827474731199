import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";

export const scrollZoom = (e, dispatch, zoom, stockpileSvgContainerNodeId) => {
  const ele = document.getElementById(stockpileSvgContainerNodeId)
  const rect = ele.getBoundingClientRect()
  // Расчет позиции мыши относительно блока
  const mouseX = e.clientX - rect.left;
  const mouseY = e.clientY - rect.top;
  // Расчет начала масштабирования на основе положения мыши
  const offsetX = mouseX / rect.width;
  const offsetY = mouseY / rect.height;
  // Рачет изменения по offsetX и offsetY
  const zoomOriginX = offsetX * 100
  const zoomOriginY = offsetY * 100
  if (e.nativeEvent.deltaY > 0 && zoom > 50) {
    ele.scrollTop -= zoomOriginY
    ele.scrollLeft -= zoomOriginX
    dispatch(setPhotoAttr({zoom: zoom - 10}))
  } else if (e.nativeEvent.deltaY < 0) {
    ele.scrollTop += zoomOriginY
    ele.scrollLeft += zoomOriginX
    dispatch(setPhotoAttr({zoom: zoom + 10}))
  }
}