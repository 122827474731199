import React from 'react';
import {IconButton} from "@mui/material";
import {iconLeft, iconLeftCenter, iconRight, iconRightCenter} from "../../../../../../Stockpiles/stockpilePageStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const SortedButtons = ({sortMode, imagesList, selectedPhotoIndex, swapItemsInList, undoButtonHandler,
                         saveNewOrderButtonHandler}) => {

  return (
    <>
      {selectedPhotoIndex < imagesList.length - 1 &&
        <IconButton style={iconRight} title={'Переместить вправо (D)'} onClick={() => swapItemsInList('right')}>
          <ChevronRightIcon/>
        </IconButton>
      }
      {selectedPhotoIndex > 0 &&
        <IconButton style={iconLeft} title={'Переместить влево (A)'} onClick={() => swapItemsInList('left')}>
          <ChevronLeftIcon/>
        </IconButton>
      }
      {sortMode &&
        <>
          <IconButton style={iconLeftCenter} title={'Сохранить перемещение (Enter)'} onClick={saveNewOrderButtonHandler}>
            <DoneIcon/>
          </IconButton>
          <IconButton style={iconRightCenter} title={'Отменить перемещение (Escape)'} onClick={undoButtonHandler}>
            <CloseIcon/>
          </IconButton>
        </>
      }
    </>
  );
};

export default SortedButtons;