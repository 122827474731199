import React, {useEffect} from 'react';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ru from "date-fns/locale/ru";
import {TextField} from "@mui/material";


const ORDatePicker = ({columnName, setRowData, rowData}) => {

  const setDate = (date) => {
    const currentDate = new Date();
    if (date <= currentDate) {
      setRowData(prev => ({...prev, [columnName]: date}));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <DatePicker
        label="Текущая дата"
        size='small'
        value={rowData.date}
        onChange={(date) => setDate(date)}
        renderInput={(params) => <TextField size="small" {...params} style={{maxWidth: '200px'}}/>}
      />
    </LocalizationProvider>
  );
};

export default ORDatePicker;