import Grid from "@mui/material/Grid";
import {useState} from "react";
import {Filter} from "./Filter";
import {List, ListItem, Typography} from "@mui/material";
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import {cubicMeterString} from "../../../../../Map/Common/Strings_RU";
import {getMap1} from "../../../../../Map/GlobalObjects";
import {getSpeciesThunk, getTMData} from "./TransportMonitoringDataCommon";

export const Production = ({state}) => {
  const [grouping, setGroupingValue] = useState('volumes_by_species')
  /**
   * Данные
   * @type {{date, stems, waste}}
   */
  const dataTM = getTMData()
  const [selectAll, setSelectAll] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const map = getMap1()

  if (!dataTM?.stems)
    return null;

  const handleSelectAllChange = () => {
    dataTM.stems.forEach(group => {
      if (selectAll)
        removeGroup(group)
      else
        addGroup(group)
    })
    setSelectAll(!selectAll)
  }

  function removeGroup(group) {
    group.checked = false;
    map.removeLayer(group.layers)
    setRefresh(!refresh)
  }

  function addGroup(group) {
    group.checked = true;
    map.addLayer(group.layers)
    setRefresh(!refresh)
  }

  function handleSelectChange(event) {
    const itemName = event.target.value;
    const group = dataTM.stems.find(group => group.name === itemName)
    if (group) {
      if (group.checked)
        removeGroup(group)
      else
        addGroup(group)
    }
  }

  function changeGrouping(grouping) {
    setGroupingValue(grouping)
    getSpeciesThunk(state.id, grouping, state.selected[0], state.selected[1])
  }

  let totalVolume = 0;
  dataTM.stems.forEach(dat => {
    if (dat.checked)
      totalVolume += parseFloat(dat.volume || 0)
  })

  let ind = 0;
  return (
    <>
      <Grid pt={1} pl={1}>
        <Grid item xs={12}>
          <Filter onChange={changeGrouping} filter={grouping} disabled={state.loading}/>
        </Grid>
        <Grid item xs={12}>
          <List>
            {dataTM?.stems && dataTM?.stems.length > 0
              ?
              dataTM.stems.map((item) => (
                <ListItem key={ind++} style={{
                  padding: "6px 0",
                  display: "flex",
                  alignItems: "center"
                }}>
                    <input
                      type="checkbox"
                      checked={item.checked}
                      value={item.name}
                      onChange={handleSelectChange}
                    />
                    <ScatterPlotIcon
                      size={"small"}
                      style={{color: item.color}}
                    />
                    <Typography style={{marginLeft: '3px', flex: 1}}>{item.name}</Typography>
                    <div>
                      <Typography>{item.volume || 0} {cubicMeterString}</Typography>
                    </div>
                </ListItem>
              ))
              :
                <ListItem>
                  <Typography>Данные отсутствуют</Typography>
                </ListItem>
            }
            <hr/>
            <ListItem style={{
              padding: "6px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <div style={{display: "flex", alignItems: "center"}}>
                <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                <Typography sx={{ml: 1}}>{selectAll ? 'Снять выделение' : 'Выделить всё'}</Typography>
              </div>
              <div>
                <Typography>{totalVolume.toFixed(2)} {cubicMeterString}</Typography>
              </div>
            </ListItem>
            <ListItem style={{
              padding: "6px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <div style={{display: "flex", alignItems: "center"}}>
                <Typography>Отходы</Typography>
              </div>
              <div>
                <Typography>{dataTM?.waste?.volume} {cubicMeterString}</Typography>
              </div>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  )
}
