import {dataAPI} from "../../../../../api/api";
import {getMap1} from "../../GlobalObjects";

L.TileLayer.WMS_Headers = L.TileLayer.WMS.extend({
  "createTile"(coords, done) {
    let url = this.getTileUrl(coords)
    console.log(url)
    const img = document.createElement('img')
    dataAPI.tiles.getWMSTile(url).then(res => {
      const urlCreator = window.URL || window.webkitURL;
      img.src = urlCreator.createObjectURL(new Blob([res.data]))
      if (this._map === getMap1()) {
        done(null, img)
      } else {
        setTimeout(function () { //таймаут для печати
          done(null, img)
        }, 100)
      }
    }).catch((err) => {
      if (err?.response?.status === 403 && err?.response?.data) {
        err.response.data.text().then(res => {
          const obj = JSON.parse(res)
          switch (obj.error) {
            case 'SENTINEL_IS_OFF_USER':
              img.src = 'https://delyana.ru/img/sentinel_user_access.png';
              break;
            case 'SENTINEL_IS_OFF_ORGANIZATION':
              img.src = 'https://delyana.ru/img/sentinel_org_access.png';
              break;
            case 'LIMIT_DAILY_ORG':
              img.src = 'https://delyana.ru/img/sentinel_org_limit_access.png';
              break;
            case 'LIMIT_DAILY_USER':
              img.src = 'https://delyana.ru/img/sentinel_user_limit_access.png';
              break;
            case 'NEED_MORE_MONEY':
              img.src = 'https://delyana.ru/img/sentinel_need_money.png';
              break;
            default:
              img.src = 'https://delyana.ru/img/sentinel_common_error.png';
          }
        })
      } else { //если ошибка не с бекенда
        if (this._map.getZoom() > 6)
          img.src = 'https://delyana.ru/img/sentinel_common_error.png';
      }
      if (this._map.getZoom() > 6) {
        img.style.border = '1px solid #AAAAAA14';
      } else {
        img.src = "https://delyana.ru/img/transparent_tile.png";
      }
      if (this._map === getMap1()) {
        done(null, img)
      } else {
        setTimeout(function () {
          done(null, img)
        }, 100)
      }
    }) //end of catch
    return img;
  }
})

L.TileLayer.Headers = L.TileLayer.extend({
  "createTile"(coords, done) {
    let url = this.getTileUrl(coords)
    const img = document.createElement('img')
    dataAPI.tiles.getWMSTile(url)
      .then(res => {
        const urlCreator = window.URL || window.webkitURL;
        img.src = urlCreator.createObjectURL(new Blob([res.data]))
        if (this._map === getMap1()) {
          done(null, img)
        } else {
          setTimeout(function () { //таймаут для печати
            done(null, img)
          }, 100)
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403 && err?.response?.data) {
          err.response.data.text().then(res => {
            const obj = JSON.parse(res)
            switch (obj.error) {
              case 'SENTINEL_IS_OFF_USER':
                img.src = 'https://delyana.ru/img/sentinel_user_access.png';
                break;
              case 'SENTINEL_IS_OFF_ORGANIZATION':
                img.src = 'https://delyana.ru/img/sentinel_org_access.png';
                break;
              case 'LIMIT_DAILY_ORG':
                img.src = 'https://delyana.ru/img/sentinel_org_limit_access.png';
                break;
              case 'LIMIT_DAILY_USER':
                img.src = 'https://delyana.ru/img/sentinel_user_limit_access.png';
                break;
              case 'NEED_MORE_MONEY':
                img.src = 'https://delyana.ru/img/sentinel_need_money.png';
                break;
              default:
                img.src = 'https://delyana.ru/img/sentinel_common_error.png';
            }
          })
        } else { //если ошибка не с бекенда
          console.error(err)
          if (!this._map || this._map.getZoom() > 6) {
            img.src = 'https://delyana.ru/img/sentinel_common_error.png';
          }
        }
        if (this._map || this._map.getZoom() > 6) {
          img.style.border = '1px solid #AAAAAA14';
        } else {
          img.src = "https://delyana.ru/img/transparent_tile.png";
        }
        if (this._map === getMap1()) {
          done(null, img)
        } else {
          setTimeout(function () {
            done(null, img)
          }, 100)
        }
      }) //end of catch
    return img;
  },
})

//L.tileLayer.wms_headers = (url, options) => new L.TileLayer.WMS_Headers(url, options)
L.tileLayer.headers = (url, options) => new L.TileLayer.Headers(url, options)
