import React from 'react';
import {IconButton, ListItem, ListItemButton, Stack, Typography, useTheme} from "@mui/material";
import {
  fTextFieldStyle,
  projectsTreeListItemStyle,
  treeItemButtonIconSize,
  treeItemButtonStyle,
  workspaceTypographyStyle
} from "../../../../Map/Common/Styles";
import {refreshProjects} from "../projectsReducer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {noNameString, saveChangeString} from "../../../../Map/Common/Strings_RU";
import {FTextField} from "../FTextFiled";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {ProjectsItemContextMenu} from "../ProjectsItemContextMenu";
import {useDispatch} from "react-redux";
import {getProjects} from "../ProjectsCommon";
import {cannotIDo} from "../../../../Map/Common/tariffs";

const WorkspaceTreeItem = ({node, collapseNode, eyeClickHandler, endEditing,
                           cancelEdit, manuallySaving}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const projects = getProjects()
  const notSaved = node.edit === 0 && (node.new || node.changed)

  return (
    <ListItem
      disableGutters
      disablePadding
    >
      <ListItemButton
        disableRipple
        style={projectsTreeListItemStyle}
        selected = {node === projects.selectedNode}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          style={{
            width: '100%',
            userSelect: 'none',
          }}
        >
          <IconButton disableRipple style={treeItemButtonStyle} onClick={() => {
            collapseNode(node);
            dispatch(refreshProjects())
          }}>
            {node.show ? <ArrowDropDownIcon style={treeItemButtonIconSize}/> :
              <ArrowRightIcon style={treeItemButtonIconSize}/>}
          </IconButton>
          <IconButton disableRipple style={treeItemButtonStyle} onClick={() => {
            collapseNode(node);
            dispatch(refreshProjects())
          }}>
            {node.isMobile
              ? <SendToMobileOutlinedIcon style={{...treeItemButtonIconSize, fill: theme.palette.primary.main}}/>
              : <PeopleAltOutlinedIcon fontSize={'small'}
                                       style={{...treeItemButtonIconSize, fill: theme.palette.primary.main}}/>
            }
          </IconButton>
          <IconButton disableRipple style={treeItemButtonStyle} onClick={() => {
            eyeClickHandler(node);
            dispatch(refreshProjects())
          }}>
            {node.visible
              ?
              <VisibilityOutlinedIcon color={'primary'} fontSize={'small'} style={{...treeItemButtonIconSize, fill: null}}/>
              :
              <VisibilityOffOutlinedIcon fontSize={'small'} style={treeItemButtonIconSize}/>
            }
          </IconButton>
          {!node.edit ?
            <Typography
              style={{
                ...workspaceTypographyStyle,
                color: notSaved?theme.palette.error.dark:theme.palette.text.primary,
              }}
              onClick={() => {
                collapseNode(node);
                dispatch(refreshProjects())
              }}>{node.name ? node.name : noNameString}
            </Typography> :
            <FTextField
              _dispatch={dispatch}
              _endEditing={endEditing}
              _cancelEdit={(e) => {
                cancelEdit(e)
                dispatch(refreshProjects())
              }}
              value={node.name}
              style={fTextFieldStyle}
            />
          }
          {notSaved &&
            <IconButton
              title={saveChangeString}
              disableRipple
              style={{padding: 0}}
              onClick={() => manuallySaving(node)}
            >
              <SaveOutlinedIcon fontSize={'small'}/>
            </IconButton>
          }
          {!cannotIDo.adminAction() && <ProjectsItemContextMenu node={node}/>}
        </Stack>
      </ListItemButton>
    </ListItem>
  )
}

export default WorkspaceTreeItem;
