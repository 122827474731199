const getIntersectionPoint = (line1, line2) => {
  const [p1, p2] = line1;
  const [p3, p4] = line2;

  const det = (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);
  if (det === 0) return null; // линии параллельны

  const t = ((p1.x - p3.x) * (p3.y - p4.y) - (p1.y - p3.y) * (p3.x - p4.x)) / det;
  const u = -((p1.x - p2.x) * (p1.y - p3.y) - (p1.y - p2.y) * (p1.x - p3.x)) / det;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    return {
      x: p1.x + t * (p2.x - p1.x),
      y: p1.y + t * (p2.y - p1.y)
    };
  }

  return null;
};

export const cutArea = (outlines, line) => {
  let newOutlinesList = [];
  outlines.forEach(outline => {
    let firstOutline = [];
    let secondOutline = [];
    for (let i = 0; i < outline.length; i++) {
      const point = outline[i];
      const nextPoint = outline[(i + 1) % outline.length]; // следующая точка, циклически

      let positionByLine = (point.x - line[0].x) * (line[1].y - line[0].y) - (point.y - line[0].y) * (line[1].x - line[0].x);
      if (positionByLine > 0) firstOutline.push(point);
      if (positionByLine < 0) secondOutline.push(point);

      const intersection = getIntersectionPoint(line, [point, nextPoint]);
      if (intersection) {
        firstOutline.push(intersection);
        secondOutline.push(intersection);
      }
    }

    if (firstOutline.length) newOutlinesList.push(firstOutline);
    if (secondOutline.length) newOutlinesList.push(secondOutline);
  });

  return newOutlinesList;
};