import Box from "@mui/material/Box";
import {bottomBlockStyle, buttonBlockTitleStyle, titleStyle} from "../organizationPageStyles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Typography} from "@mui/material";

export const BottomBlockTitle = ({openedBlock, setOpenedBlock, blockName, title}) => {

  const titleClickHandler = () => {
    if (openedBlock !== blockName) {
      setOpenedBlock(blockName)
    } else {
      setOpenedBlock(null)
    }
  }

  return (
    <Box sx={bottomBlockStyle} onClick={titleClickHandler}>
      {openedBlock !== blockName
        ? <ArrowRightIcon sx={{...titleStyle, fontSize: 30}}/>
        : <ArrowDropDownIcon sx={{...titleStyle, fontSize: 30}}/>
      }
      <Typography
        sx={{
          ...buttonBlockTitleStyle,
          color: openedBlock === blockName ? 'primary.dark' : '#808080'
        }}>
        {title}
      </Typography>
    </Box>
  )
}
