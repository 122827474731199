import {otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_USER_INFO_ATTR = "SET_USER_INFO_ATTR";
const PATCH_USER_INFO = "PATCH_USER_INFO";

const initialState = {
    userInfo: null,
    userInfoLoading: false,
    userIsAdminOrOwner: false,
}

// редьюсер для работы с данными текущего пользователя
export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_INFO_ATTR: {
            return { ...state, ...action.payload };
        }
        case PATCH_USER_INFO: {
            return { ...state, userInfo: {...state.userInfo, ...action.payload} };
        }
        default:
            return state;
    }
};

export const setUserDataAttr = (payload) => ({ type: SET_USER_INFO_ATTR, payload });
export const patchUserInfo = (payload) => ({ type: PATCH_USER_INFO, payload });

export const getUserInfoThunk = () => {
    return async (dispatch) => {
        try {
            dispatch(setUserDataAttr({userInfoLoading: true}))
            let res = await otherDataAPI.user.getUserInfo();
            let userInfo = res.data
            dispatch(setUserDataAttr({
                userInfo: userInfo,
                userIsAdminOrOwner: userInfo?.is_admin || userInfo?.is_owner,
            }))
            dispatch(setUserDataAttr({userInfoLoading: false}))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    };
};

/*export const saveFullNameThunk = (data) => {
    return async (dispatch) => {
        try {
            let res = await otherDataAPI.user.saveFullName(data);
            dispatch(patchUserInfo(res.data))
            dispatch(setSnack('success', savingSuccess))
        } catch (e) {
            handleErrors(dispatch, e)
        }
    };
};*/

export const setNewPasswordWithCodeThunk = (data, setOpenDialog) => {
    return async (dispatch) => {
        try {
            await otherDataAPI.user.setNewPasswordWithCode(data);
            dispatch(setSnack('success', 'Пароль сохранен'))
            dispatch(patchUserInfo({password_is_set: true}))
            setOpenDialog(false)
        } catch (e) {
            handleErrors(dispatch, e)
        }
    };
};

export const setNewPasswordWithOldPasswordThunk = (data, setOpenDialog) => {
    return async (dispatch) => {
        try {
            await otherDataAPI.user.setNewPasswordWithOldPassword(data);
            dispatch(setSnack('success', 'Пароль сохранен'))
            setOpenDialog(false)
        } catch (e) {
            handleErrors(dispatch, e)
        }
    };
};
