
export const logoContainer = {width: '14vh', ml: '10px', mt: 1, cursor: "pointer"}

export const logoHeight = 26

export const headerIconStyle = {color: 'white', fontWeight: 500}

export const expirationDateTextContainerStyle = {backgroundColor: 'white', borderRadius: "3px", padding: '3px 16px 3px 13px'}

export const expirationDateButtonStyle = {position: 'relative', left: -4, backgroundColor: '#30D366'}

