import React, {useEffect, useState, memo} from 'react';
import ModalPage from "../../Common/ModalPage";
import {tableCellClasses} from "@mui/material/TableCell";
import {Table, TableBody, TableContainer, TableRow, TableCell, Stack, Box, TextField, TableHead} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {modalWindowStyle} from "../../Common/Styles";
import SaveIcon from "@mui/icons-material/Save";
import {hideMapDialog, showMapDialog} from "../../Main/Map/Common/Dialog/DialogReducer";
import {useDispatch, useSelector} from "react-redux";
import MachineDataTableRow from "./MachineDataTableRow/MachineDataTableRow";
import EditMachineTableRow from "./EditMachineTableRow/EditMachineTableRow";
import {
  createMachineThunk, deleteMachineThunk,
  getMachinesThunk, setOpertaionalReportAttr,
  updateMachineThunk
} from "../../../redux/reducers/operationalReportReducer";
import CreateMachineTableRow from "./CreateMachineTableRow/CreateMachineTableRow";


const MachinesModal = () => {
  const dispatch = useDispatch()
  const allMachines = useSelector(state => state.operationalReportReducer.machines || [])
  const {status, dataType} = useSelector(state => state.operationalReportReducer.showMachinesModal)
  const deleteDialogButtons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
  const [newMachine, setNewMachine] = useState({name: '', number: ''})
  const [editMachine, setEditMachine] = useState(null)
  const is_for_harvesting = dataType === 'harvestingData'
  const filteredMachinesList = is_for_harvesting
    ? allMachines.filter(machine => machine.is_for_harvesting)
    : allMachines.filter(machine => !machine.is_for_harvesting)

  useEffect(() => {
    dispatch(getMachinesThunk())
  }, [])

  const handleClose = () => {
    dispatch(setOpertaionalReportAttr({showMachinesModal: {status: false, dataType: null}}))
  }

  const deleteMachine = (machineId) => {
    dispatch(showMapDialog('warning', "Удаление машины",
      "Вы уверены что хотите удалить выбранную машину?", deleteDialogButtons,
      (e) => {
      dispatch(hideMapDialog())
        if (e === 'Да') {
          dispatch(deleteMachineThunk(machineId))
        }
      }))
  }

  const createMachine = () => {
    dispatch(createMachineThunk({...newMachine, is_for_harvesting: is_for_harvesting}, setNewMachine))
  }

  const updateMachine = () => {
    dispatch(updateMachineThunk(editMachine, setEditMachine))
  }

  const addNewMachineData = (e, field) => {
    setNewMachine(prev => ({...prev, [field]: e.target.value}))
  }

  const changeEditMachineData = (e, field) => {
    setEditMachine(prev => ({...prev, [field]: e.target.value}))
  }

  const selectEditMachine = (machine) => {
    setEditMachine(machine)
  }

  return (
    <ModalPage
      title={`Список машин`}
      handleClose={handleClose}
      actionButtonRemove={true}
      customStyle={{...modalWindowStyle, height: '45vh'}}
    >
      <Box sx={{height: '100%', py: 2}}>
        <TableContainer sx={{width: '100%', height: '85%', overflowY: 'auto', }}>
          <Table size={"small"} stickyHeader
                 sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none", pt: 0, pb: 0}}}>
            <TableHead >
              <CreateMachineTableRow addNewMachineData={addNewMachineData} newMachine={newMachine}
                                     createMachine={createMachine}/>
            </TableHead>
            <TableBody>
              {filteredMachinesList.map((machine, i) => (
                editMachine?.id !== machine.id
                ? (
                    <MachineDataTableRow key={i} machine={machine} selectEditMachine={selectEditMachine}
                                         deleteMachine={deleteMachine}/>
                  )
                : (
                  <EditMachineTableRow key={i} editMachine={editMachine}
                                       updateMachine={updateMachine} changeEditMachineData={changeEditMachineData}
                                       selectEditMachine={selectEditMachine}/>
                  )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ModalPage>
  );
}

export default MachinesModal;