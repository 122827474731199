import React from 'react';
import {useSelector} from "react-redux";
import {Stack, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import {rightBarTitleStyle} from "../../rightbarStyles";
import ViewedRulerTable from "./ViewedRulerTable/ViewedRulerTable";
import SelectedRulerTable from "./SelectedRulerTable/SelectedRulerTable";

const RulerData = () => {
  const stockpilePhotoState = useSelector(state => state.stockpilePhotoReducer)
  const {selectedPhoto, ruler_selected_index, ruler_viewed_index} = stockpilePhotoState
  const isViewedRuler = ruler_viewed_index !== null
  const isSelectedRuler = ruler_selected_index !== null

  return (
    <Stack direction={'column'} alignItems={'center'} spacing={1} justifyContent={'center'}>
      <Typography sx={rightBarTitleStyle}>Ширина замера</Typography>
      {isViewedRuler &&
        <ViewedRulerTable/>
      }
      {isSelectedRuler &&
        <SelectedRulerTable/>
      }
    </Stack>
  );
};

export default RulerData;