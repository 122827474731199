import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {List, ListItem, ListItemIcon, Typography} from "@mui/material";
import {setFiltersData} from "../../../../../../redux/reducers/operationalReportReducer";
import DoneIcon from "@mui/icons-material/Done";
import {getValueFromRowByColumn} from "../../../../ORhelperFunctions";

const FilterList = ({filterData, columnName, selectedItems, handleItemClick}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClose = () => {
    setAnchorEl(null);
  }
  let getValue = (item) => getValueFromRowByColumn(columnName, item)

  return (
    <List dense>
      {filterData.map(item => (
        <ListItem key={item.id} onClick={() => handleItemClick(item)} sx={{cursor: 'pointer',
            '&:hover': {backgroundColor: '#e0e0e0'}}}>
          {selectedItems.find(selectedItem => selectedItem.id === item.id) &&
            <DoneIcon sx={{fontSize: 16}}/>
          }
          <Typography sx={{fontSize: 14}}>{getValue(item)}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default FilterList;