const defaultState = {
  loading: false,
  loaded: false,
  script: null,
  widget: null,
  widgetOpened: false,
}

export function setBitrixState(data) {
  return {
    type: 'BITRIX_SET_STATE',
    data: data,
  }
}

export function bitrixReducer(state = defaultState, action) {
  switch (action.type) {
    case 'BITRIX_SET_STATE':
      return {...state, ...action.data}
    default:
      return state;
  }
}
