import Button from "@mui/material/Button";
import {Stack} from "@mui/material";
import {setPaymentAttr} from "../../../../redux/reducers/paymentReducer";
import {useDispatch} from "react-redux";

const PaymentButtons = () => {
  const dispatch = useDispatch()
  const openPaymentWindowHandler = (type) => {
    dispatch(setPaymentAttr({showPaymentWindow: {status: true, type: type}}))
  }

  return (
    <Stack direction={'row'} spacing={2} sx={{ml: 2, mb: 2}}>
      <Button variant={'contained'} size={'small'} onClick={() => openPaymentWindowHandler('card')}>
        Оплатить картой
      </Button>
      <Button variant={'contained'} size={'small'} onClick={() => openPaymentWindowHandler('bill')}>
        Сформировать счет
      </Button>
    </Stack>
  );
};

export default PaymentButtons;
