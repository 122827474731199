import {IconButton, TableCell, TableRow} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useDispatch} from "react-redux";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";
import {createWorkspaceString} from "../../../../Main/Map/Common/Strings_RU";
import {cannotIDo} from "../../../../Main/Map/Common/tariffs";

const CreateWorkspaceRow = ({WorkSpacesTableColumns}) => {
  const userIsAdminOrOwner = !cannotIDo.adminAction()
  const dispatch = useDispatch()

  const createWorkspaceModeToggler = () => {
    dispatch(changeOrgPageAttrs({createWorkspaceMode: true, selectedWorkspace: null}))
  }

  return (
    <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
      {WorkSpacesTableColumns.map((column) =>
        <TableCell key={column.name} align="left">
          {column.name === 'buttons' && userIsAdminOrOwner &&
            <IconButton
              title={createWorkspaceString}
              onClick={createWorkspaceModeToggler}>
              <AddIcon sx={{color: 'primary.dark'}}/>
            </IconButton>
          }
        </TableCell>
      )}
    </TableRow>
  )
}

export default CreateWorkspaceRow;
