import React from 'react';
import {Typography} from "@mui/material";
import {blockTitle} from "../../rightPanelStyles";
import Button from "@mui/material/Button";
import {il1ReportString} from "../../../Map/Common/Strings_RU";
import {createIL1Area} from "../../../LeftPanel/Context/CAs/Reports_IL1";
import {useDispatch, useSelector} from "react-redux";

const Reports = () => {
  const dispatch = useDispatch()
  const plotLayer = useSelector(state => state.rightPanelReducer.plotLayer)

  const il1ButtonHandler = () => {
    createIL1Area(plotLayer, dispatch)
  }
  return (
    <>
      <Typography style={blockTitle}>Отчетность</Typography>
      <Button size={'small'} variant={'outlined'} onClick={il1ButtonHandler}>{il1ReportString}</Button>
    </>
  )
}

export default Reports;
