import React, {useEffect, useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import ModalPage from "../../../Common/ModalPage";
import {tableCellClasses} from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PasswordDataRow from "./PasswordDataRow/PasswordDataRow";


const ChangePasswordDialog = ({openDialog, setOpenDialog}) => {
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [confCode, setConfCode] = useState('')
  const [seconds, setSeconds] = useState(100)
  const [errors, setErrors] = useState({})


  const validation = () => {
    let errorsObj  = {}
    const passwordRegex = "[0-9a-zA-Z!@#$%^&*_]{8,}"
    if (newPassword !== repeatNewPassword) {
      errorsObj.newPassword = 'Пароли не совпадают'
      errorsObj.RepeatPassword = 'Пароли не совпадают'
    }
    if (!confCode) errorsObj.confCode = 'Поле является обязательным'
    if (!newPassword) errorsObj.newPassword = 'Поле является обязательным'
    if (!repeatNewPassword) errorsObj.RepeatPassword = 'Поле является обязательным'
    if (!newPassword.match(passwordRegex))
      errorsObj.newPassword = 'Неверный формат пароля'
    if (!repeatNewPassword.match(passwordRegex))
      errorsObj.RepeatPassword = 'Неверный формат пароля'

    setErrors(prev => {return {...errorsObj}})
    return !Object.keys(errorsObj).length
  }
const handleClose = () => {
  setOpenDialog(false)
}
  const actionButtonHandler = () => {
  }

      return (
        <ModalPage
          title={`Изменить пароль`}
          handleClose={handleClose}
          actionButtonHandler={actionButtonHandler}
          actionButtonRemove={true}
        >
          <TableContainer sx={{pt: 2, pb: 2}}>
            <Table size={"small"} sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none",}}}>
              <TableBody>

                <PasswordDataRow label={'Старый пароль'} field={'checkCode'} errorMessage={errors.confCode}
                                 onChangeHandler={setConfCode} maxLength={50} eyeIcon={true}/>
                <PasswordDataRow label={'Новый пароль'} field={'newPassword'} errorMessage={errors.newPassword}
                                 onChangeHandler={setNewPassword} maxLength={50} eyeIcon={true}/>
                <PasswordDataRow label={'Повтор пароля'} field={'RepeatPassword'} errorMessage={errors.RepeatPassword}
                                 onChangeHandler={setRepeatNewPassword} maxLength={50} eyeIcon={true}/>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{pl: 0}}>
                    <Stack direction={'row'} spacing={1} sx={{display: 'flex', justifyContent: 'end'}}>
                      <Button size={'small'} variant={'outlined'}
                              onClick={() => {
                                validation() && actionButtonHandler()
                              }}
                      >Сохранить</Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </ModalPage>
      );
};

export default ChangePasswordDialog;