import {useEffect} from "react";
import {AppBar, IconButton,} from "@mui/material";
import TokenService from "../../api/token.service";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as DelyanaLogoWhite} from "../../svg/Delyana_logo_white.svg";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router";
import {urlGenerator} from "../../utils/urlGenerator";
import Stack from "@mui/material/Stack";
import {getUserInfoThunk} from "../../redux/reducers/userReducer";
import {
  getOrganizationDataThunk, syncWith1CThunk,
} from "../../redux/reducers/organizationInfoReducer";
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import ExpirationButton from "./ExpirationButton/ExpirationButton";
import {headerIconStyle, logoContainer, logoHeight} from "./headerStyles";
import {cannotIDo} from "../Main/Map/Common/tariffs";
import HeaderMenuButton from "./MenuButton/HeaderMenuButton";

function Header({backgroundColor}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector((state) => state.loginReducer.token)
  const userInfo = useSelector(state => state.userReducer.userInfo)
  const organizationData = useSelector(state => state.organizationInfoReducer.organizationData)

  // загрузка информации о пользователе и организации вынесена в header как общая информация для всех страниц приложения
  useEffect(() => {
    if (token.refresh) {
      if (!userInfo) {
        dispatch(getUserInfoThunk())
      }
      if (!organizationData) {
        dispatch(getOrganizationDataThunk())
      }
    }
  }, [token])

  const syncWith1CHandler = () => {
    dispatch(syncWith1CThunk())
  }

  const logoIconClickHandler = () => {
    TokenService.getLocalAccessToken() &&
    navigate(urlGenerator().main)
    window.location.reload()
  }

  return (
    <Box id={'app_header'} style={{backgroundColor: backgroundColor, boxSizing: 'border-box'}}>
      <AppBar position={"static"}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{width: '100%'}}>
          <Box sx={logoContainer}>
            <DelyanaLogoWhite height={logoHeight} onClick={logoIconClickHandler}/>
          </Box>
          {token.refresh &&
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{marginRight: 1}}>
              {organizationData?.paid_to &&
                <ExpirationButton/>
              }
              {!cannotIDo.use1CSyncModule() &&
                <IconButton title={'Синхронизировать с 1С'} onClick={syncWith1CHandler}>
                  <SyncOutlinedIcon sx={headerIconStyle}/>
                </IconButton>
              }
              <HeaderMenuButton/>
            </Stack>}
        </Stack>
      </AppBar>
    </Box>
  )
}

export default Header;
