import React from 'react';
import Characteristics from "../RPCommonElements/Characteristics/Characteristics";
import {Box, Button, Divider, Stack} from "@mui/material";
import Comment from "../RPCommonElements/Comment/Comment";
import Photos from "../RPCommonElements/Photos/Photos";
import LastChange from "../RPCommonElements/LastChange/LastChange";
import {
  deleteShapePhotoThunk,
  saveShapeCommentThunk,
  saveShapePhotosThunk
} from "../../../../redux/reducers/rightPanelReducer";
import {useDispatch, useSelector} from "react-redux";
import {showCoordinatesEditor} from "../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {geoJSONdefaultPrecision} from "../../Map/Common/Defaults";
import {dataAPI} from "../../../../api/api";
import {clearCoordinatesDialogInfo} from "../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import {setSnack} from "../../Map/Common/Dialog/Snack/snackReducer";
import {flyToShape} from "../../Map/Common/fly";
import {handleErrors} from "../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {coordinatesChangeSuccess} from "../../Map/Common/Strings_RU";

function RPShapeData() {
  const shapeData = useSelector(state => state.rightPanelReducer.shapeData)
  const node = useSelector(state => state.rightPanelReducer.node)
  const {comment: commentObj, photos, id: shapeDataId, geometry} = shapeData || {};
  const dispatch = useDispatch()

  if (!shapeData) return ''

  function showCoordinatesEditorWindow() {
    let latlngs;
    if (node.type === 'Polygon')
      latlngs = node.layer.getLatLngs()[0]
    else
    if (node.type === 'Line')
      latlngs = node.layer.getLatLngs()
    else
      latlngs = [node.layer.getLatLng()]
    dispatch(showCoordinatesEditor(
      latlngs,
      node.type,
      (latlngs) => {
        const json = {
          geometry: {
            type: node.layer.toGeoJSON(geoJSONdefaultPrecision).geometry.type,
            coordinates: [[]],
          },
        }
        latlngs.map((coo) => {
          json.geometry.coordinates[0].push([coo.lng, coo.lat]);
        })
        if (node.type === 'Polygon')
          json.geometry.coordinates[0].push([latlngs[0].lng, latlngs[0].lat]);
        else
        if (node.type === 'Marker')
          json.geometry.coordinates = json.geometry.coordinates[0][0]
        else
          json.geometry.coordinates = json.geometry.coordinates[0]
        dataAPI.projects.shapes.patch(node.id, json).then(() => {
          if (node.type === 'Marker') {
            node.layer.setLatLng(latlngs[0])
          }
          else
            node.layer.setLatLngs(latlngs)
          clearCoordinatesDialogInfo()
          dispatch(setSnack('success', coordinatesChangeSuccess))
          flyToShape(node.layer, node.type, false)
        }).catch(err => {
          handleErrors(dispatch, err)
        })
      },
      node.name,
    ))
  }

  return (
    <Box sx={{height: '100%', overflowY: 'auto'}}>
      <Stack spacing={1} sx={{pt: 1, pb: 1}}>
        <Button size={'small'} variant={'outlined'} fullWidth onClick={showCoordinatesEditorWindow}>
          Координаты
        </Button>
        <Characteristics geometry={geometry}/>
        <Divider orientation={"horizontal"}/>
        <Comment commentObj={commentObj} objectId={shapeDataId} saveCommentThunk={saveShapeCommentThunk}/>
        <Divider orientation={"horizontal"}/>
        <Photos photos={photos || []} objectId={shapeDataId} savePhotosThunk={saveShapePhotosThunk}
                deletePhotoThunk={deleteShapePhotoThunk}/>
        <Divider orientation={"horizontal"}/>
        <LastChange objectData={shapeData}/>
      </Stack>
    </Box>
  )
}

export default RPShapeData;
