import React, {useEffect, useRef} from 'react';
import {Autocomplete, TextField} from "@mui/material";


const ORAutocomplete = ({options, setRowData, rowData, columnName}) => {

  const setSelectedData = (e, value) => {
    e.stopPropagation()
    setRowData(prev => ({...prev, [columnName]: value}));
  };

  return (
    <Autocomplete
      id={`${columnName}-autocomplete`}
      disablePortal
      size={"small"}
      options={options}
      value={rowData[columnName] || null}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} variant="standard" />}
      onChange={(e, value) => setSelectedData(e, value)}
    />
  );
};

export default ORAutocomplete;