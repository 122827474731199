import React from 'react';
import {Table, TableRow, TableCell, TableBody, Typography} from "@mui/material";
import {blockTitle} from "../../rightPanelStyles";

const Characteristics = ({geometry}) => {

  return (
    <>
      <Typography style={blockTitle}>Характеристики</Typography>
      <Table size={'small'} sx={{p: 0, '& .MuiTableCell-root': {borderBottom: 'none', paddingLeft: '0'}}}>
        <TableBody>
          {geometry?.type === 'Polygon' &&
            <>
              <TableRow>
                <TableCell>Периметр</TableCell>
                <TableCell>{geometry?.perimeter || '-'} м</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Площадь</TableCell>
                <TableCell>{geometry?.square || '-'} га</TableCell>
              </TableRow>
            </>
          }
          {(geometry?.type === 'LineString' || geometry?.type === 'MultiLineString') &&
            <>
              <TableRow>
                <TableCell>Длина</TableCell>
                <TableCell>{geometry?.length || '-'} м</TableCell>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
    </>
  );
};

export default Characteristics;