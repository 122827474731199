import {Alert, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {UserPasswordField} from "../../../../../../../Common/UserPasswordField/UserPasswordField";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {useDispatch} from "react-redux";
import {setSnack} from "../../../../../../../Main/Map/Common/Dialog/Snack/snackReducer";
import {UserPasswordInfoDialog} from "./UserPasswordInfoDialog";
import {Loading} from "../../../../../../../Main/Map/Common/Loading";
import {otherDataAPI} from "../../../../../../../../api/api";
import {handleErrors} from "../../../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";

export function FiveTabUserPassword({user}) {
  const [firstPassword, setFirstPassword] = useState('')
  const [firstPasswordError, setFirstPasswordError] = useState(true)
  const [secondPassword, setSecondPassword] = useState('')
  const [secondPasswordError, setSecondPasswordError] = useState(true)
  const [sucessDialogOpenData, setSuccessDialogOpenData] = useState(null)
  const [userInfoLoading, setUserInfoLoading] = useState(false)
  const [userInfoLoadingError, setUserInfoLoadingError] = useState(false)
  const [userData, setUserData] = useState(null)
  const [saving, setSaving] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userData && !userInfoLoadingError && !userInfoLoading) {
      setUserInfoLoading(true)
      otherDataAPI.organizationUsers.getUserChangeInfo(user.id).then(res => {
        if (res.data["last_change"]) {
          setUserData(res.data["last_change"])
          setUserInfoLoading(false)
        } else
          setUserData({date: ' - ', time: ' - ', admin: ' - '})
      }).catch(() => {
        setUserInfoLoadingError(true)
        setUserInfoLoading(false)
      })
    }
  }, [userInfoLoading])

  if (userInfoLoadingError)
    return (
      <Stack gap={2}>
        <Alert severity={'error'}>Ошибка загрузки. Нажмите кнопку ниже, чтобы попробовать еще раз.</Alert>
        <Button
          variant={'contained'}
          style={{width: 'fit-content'}}
          onClick={() => {
            setUserData(null)
            setUserInfoLoadingError(false)
            setUserInfoLoading(false)
          }}
        >
          Повторить
        </Button>
      </Stack>
    )

  if (!userData)
    return <Loading/>

  function validatePassword(value) {
    const passwordRegex = "[0-9a-zA-Z!@#$%^&*_]{8,}";
    return value.match(passwordRegex)
  }

  function buttonValidation() {
    let text = '';
    if (!firstPassword || !secondPassword)
      text = 'Пароль не может быть пустым';
    else if (!validatePassword(firstPassword) || !validatePassword(secondPassword))
      text = 'Пароль не соответствует требованиям';
    else if (firstPassword !== secondPassword)
      text = 'Пароли не совпадают';

    if (text) {
      dispatch(setSnack('error', text))
    }
    return !text;
  }

  function savePassword() {
    setSaving(true)
    otherDataAPI.organizationUsers.setUserPasswordByAdmin(user.id, {new_password: firstPassword}).then(res => {
      setSuccessDialogOpenData(res.data.message)
      setUserData(res.data["last_change"])
      setSaving(false)
    }).catch(err => {
      handleErrors(dispatch, err)
      setSaving(false)
    })
  }

  function closeSuccessDialog() {
    setSuccessDialogOpenData(null)
  }

  const labelStyle = {
    color: '#555555',
    fontSize: '0.9rem',
  }

  return (
    <>
      <Stack direction={'column'} gap={2}>
        <Typography style={{fontWeight: 600}}>Последние изменения</Typography>
        <Stack style={{marginLeft: '0.5vw'}} gap={1}>
          <Typography>{`Дата: ${userData.date}`}</Typography>
          <Typography>{`Время UTC: ${userData.time}`}</Typography>
          <Typography>{`Автор изменений: ${userData.admin}`}</Typography>
        </Stack>
        <Typography style={{fontWeight: 600, marginTop: '2vh'}}>Изменение пароля</Typography>
        <Stack style={{marginLeft: '0.5vw'}} gap={1}>
          <div>
            <Alert severity={'info'} style={{maxWidth: '500px', marginBottom: '1vh'}}>
              Пароль должен состоять из восьми или более символов латинского алфавита, цифр и спецсимволов.
            </Alert>
            <Typography style={labelStyle}>Введите новый пароль</Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <UserPasswordField
                value={firstPassword}
                setValue={(value) => {
                  setFirstPassword(value)
                  setFirstPasswordError(!validatePassword(value))
                }}
              />
              {firstPasswordError ?
                <ClearOutlinedIcon color={'warning'} fontSize={'small'}/> :
                <CheckOutlinedIcon color={'primary'} fontSize={'small'}/>
              }
            </Stack>
          </div>
          <div>
            <Typography style={labelStyle}>Повторите новый пароль</Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <UserPasswordField
                setValue={(value) => {
                  setSecondPassword(value)
                  setSecondPasswordError(!validatePassword(value))
                }}
              />
              {secondPasswordError ?
                <ClearOutlinedIcon color={'warning'} fontSize={'small'}/> :
                <CheckOutlinedIcon color={'primary'} fontSize={'small'}/>
              }
            </Stack>
          </div>
          <Button
            disabled={saving}
            variant={'contained'}
            type={'button'}
            style={{maxWidth: '150px'}}
            onClick={() => {
              if (buttonValidation()) {
                savePassword()
              }
            }}
            tabIndex={-1}
          >
            {saving?'Сохранение...':'Подтвердить'}
          </Button>
        </Stack>
      </Stack>
      {sucessDialogOpenData && <UserPasswordInfoDialog user={user} data={sucessDialogOpenData} onClose={closeSuccessDialog}/>}
    </>
  )
}
