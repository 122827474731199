import React from 'react';
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox, Link} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setAuthAttr} from "../../../redux/reducers/loginReducer";

const UserAgreementCheckbox = () => {
  const dispatch = useDispatch()
  const userAgreementCheck = useSelector(state => state.loginReducer.userAgreementCheck)

  const agreementClickHandler = (e) => {
    e.preventDefault()
    window.open('https://www.delyana.ru/agreement', '_blank')
  }
  const offerClickHandler = (e) => {
    e.preventDefault()
    window.open('https://www.delyana.ru/offer', '_blank')
  }

  const CheckboxHandler = () => {
    dispatch(setAuthAttr({userAgreementCheck: !userAgreementCheck}))
  }
  return (
    <FormControlLabel sx={{cursor: 'pointer',  mt: 1 }} color={'primary'}
                      control={<Checkbox size={'small'} checked={userAgreementCheck} onChange={CheckboxHandler}/>}
                      label={<Box sx={{display: 'flex'}}>
                        <Typography sx={{fontSize: 12, width: 350}}>{'Я принимаю условия '}
                          <Link sx={{cursor: 'pointer'}}
                                onClick={agreementClickHandler}
                          >{'пользовательского соглашения '}</Link>
                          и
                          <Link sx={{cursor: 'pointer'}}
                                onClick={offerClickHandler}
                          >{' договора-оферты'}</Link>
                        </Typography>

                      </Box>
                      }/>
  );
};

export default UserAgreementCheckbox;