import {IconButton, InputAdornment, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {tableRowStyle} from "../../EmployeesTable";
import {useDispatch, useSelector} from "react-redux";
import {RolesList} from "./RolesList/RolesList";
import {validateField} from "../../../../../../validations/validations";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {changeOrgPageAttrs, defaulUserData} from "../../../../organizationPageReducer";
import {useState} from "react";
import {FTextField} from "../../../../../Main/LeftPanel/Context/Projects/FTextFiled";
import {createUser, updateUser} from "../../../../org_page_api";

const UserForm = ({fieldData, mode}) => {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.organizationPageReducer.userData)
  const [validationErrors, setValidationErrors] = useState({})

  if (!userData && mode === 'edit')
    return null;

  function validation() {
    let errors = {}
    Object.keys(userData).forEach((item) => {
      let field = fieldData.find(field => item === field.fieldName)
      if (field) {
        if (field.required) {
          errors[field.fieldName] = validateField(field.fieldName, userData[item], true)
        } else {
          errors[field.fieldName] = validateField(field.fieldName, userData[item])
        }
      }
    })
    if (Object.values(errors).every(value => value === '')) {
      return true
    } else {
      setValidationErrors(errors)
      return false
    }
  }

  function createUserHandler() {
    if (validation()) {
      createUser(userData)
    }
  }

  function updateUserHandler() {
    if (validation()) {
      updateUser(userData)
    }
  }

  function undoEditUser() {
    dispatch(changeOrgPageAttrs({createUserMode: false, userData: defaulUserData}))
  }

  return (
    <TableRow
      sx={{...tableRowStyle, "&:hover": {backgroundColor: 'none'},}}
    >
      <TableCell align="left">{''}</TableCell>
      {fieldData.map((field, i) => {
        let {fieldName, label, max_length} = field;
        let errorText = validationErrors[fieldName]

        return (
          <TableCell key={i} align="left">
            <FTextField
              nofocus={i > 0}
              variant={'standard'}
              label={label}
              error={!!errorText}
              value={userData[fieldName] || ''}
              inputProps={{maxLength: max_length}}
              InputProps={{
                startAdornment: fieldName === 'phone' && <InputAdornment position="start">+7</InputAdornment>,
                endAdornment: !!errorText &&
                  <Tooltip title={<Typography sx={{fontSize: 12}}>{errorText}</Typography>}>
                    <ErrorOutlineOutlinedIcon fontSize={"small"} sx={{color: 'red', cursor: 'pointer'}}/>
                  </Tooltip>,
              }}
              //onChange={(e) => setUserData(prev => ({...prev, [fieldName]: e.target.value}))}
              _endEditing={(value) => {
                const data = {...userData}
                data[fieldName] = value;
                dispatch(changeOrgPageAttrs({userData: data}))
              }}
            />
          </TableCell>
        )
      })}
      <TableCell align="left">
        <RolesList userData={userData}/>
      </TableCell>
      <TableCell align="left">
        <IconButton
          title={'Сохранить'}
          onClick={() => {
            if (mode === 'create') {
              createUserHandler()
            } else {
              updateUserHandler()
            }
          }}
          color={'primary'}
        >
          <DoneIcon />
        </IconButton>
        <IconButton onClick={undoEditUser} title={'Отменить'} color={'error'}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default UserForm;
