import React from 'react';
import {useSelector} from "react-redux";
import {planetableSvgConfig} from "../svgConfig";
import {coordCorrection} from "../../../../svgComponentsHelpersFunctions/coordCorrection";

const LayerFromMap = () => {
  const planetableSvgState = useSelector(state => state.planetableSvgReducer)
  const {quarter_editing_outline_index, quarter_editing_point_index, viewedSvgFigure, layerFromMap, scale,
    zoom,} = planetableSvgState
  const {quarter_editing_outline_fill, quarter_outline_fill, quarter_outline_stroke, quarter_editing_outline_opacity,
    quarter_outline_opacity, quarter_point_radius, quarter_outline_stroke_width} = planetableSvgConfig
  return (
    <>
      {/*Рендер слоя кварталов с карты*/}
      {layerFromMap.layerObjects.map((quarter, index) => (
          <polygon
            key={index}
            i={`quarterFromMap_${index}`}
            points={quarter.coordinates_pxl.map((point) =>
              `${coordCorrection(point.x, zoom, scale)}, ${coordCorrection(point.y, zoom, scale)}`).join(' ')}
            fill={quarter_outline_fill}
            stroke={quarter_outline_stroke}
            strokeWidth={quarter_outline_stroke_width}
            opacity={quarter_editing_outline_opacity}
          />
      ))}
    </>
  );
};

export default LayerFromMap;