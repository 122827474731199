import L from "leaflet";
import {markersIconsList} from "../../../../Map/Common/svg/Svgs";
import {getMap1} from "../../../../Map/GlobalObjects";
import "leaflet-arrowheads";
import {setViolationData} from "./actionCreators";
import {otherDataAPI} from "../../../../../../api/api";
import {resetRightPanelData} from "../../../../../../redux/reducers/rightPanelReducer";
import {deleteRedArrow} from "../../../../Map/Common/redArrow";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";

/** Создаёт стилизованную SVG иконку нарушения. */
export function svgWarningMarker() {
  let iconName = "WARNING_ICON";
  let viewBox = markersIconsList[iconName].viewBox;
  if (!viewBox) viewBox = "0 96 960 960";
  const svg =
    `<div style="cursor: pointer; pointer-events: none"><svg class="map_svg_marker" ` +
    `xmlns="http://www.w3.org/2000/svg" height="24" width="24" ` +
    `preserveAspectRatio="none" viewBox="${viewBox}" fill="#D10D0D">` +
    markersIconsList[iconName].content +
    "</svg></div>";
  return L.divIcon({
    html: svg,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    className: "no-back-no-border",
  });
}
/**
 * Создает маркер нарушения по feature
 * @param violation - feature
 * @returns L.Marker
 */
function createMarker(violation) {
  const marker = L.marker([violation["geom"].coordinates[1], violation["geom"].coordinates[0]], {
    icon: svgWarningMarker(),
    //bubblingMouseEvents: true,
    interactive: false,
  })
  violation.layer = marker;
  return marker;
}

/**
 * Начальная загрузка нарушений
 */
export function loadViolations() {
  (async function load() {
    const dispatch = window.elz_dispatch;
    dispatch(setViolationData({loading: true}))
    otherDataAPI.violations.getAll().then(res => {
      const map = getMap1()
      let group = window.store.getState()["violationsReducer"].group;
      if (group) {
        removeAllViolations()
      } else {
        group = L["markerClusterGroup"]({
          id: "violations_layer_group",
          maxClusterRadius: 50,
          disableClusteringAtZoom: 13,
        })
        map.addLayer(group)
      }
      const markers = res.data.map((violation) => createMarker(violation))
      group.addLayers(markers)
      dispatch(setViolationData({
        violations: res.data,
        group: group,
        loadingError: null,
        loading: false
      }))
    }).catch(err => {
      dispatch(setViolationData({loading: false, loadingError: true}))
      console.error(err)
    })
  })()
}

/**
 * Удаляет все нарушения
 */
export function removeAllViolations() {
  const group = window.store.getState().violationsReducer.group;
  if (group)
    group.clearLayers()
}

/**
 * Удаляет нарушение по id
 * @param id
 */
export function deleteViolation(id) {
  const dispatch = window.elz_dispatch;
  otherDataAPI.violations.deleteById(id).then(() => {
    const state = window.store.getState().violationsReducer;
    const violations = state.violations;
    const index = violations.findIndex(item => item.id === id)
    if (index !== -1) {
      state.group.removeLayer(violations[index].layer)
      const arr = [...violations]
      arr.splice(index ,1)
      deleteRedArrow()
      const element = window.store.getState().rightPanelReducer["selectedElement"]
      if (element && element.id === id)
        dispatch(resetRightPanelData())
      dispatch(setViolationData({violations: arr}))
    }
  }).catch(err => {
    console.error(err)
    dispatch(setSnack('error', 'Произошла ошибка при удалении нарушения. Попробуйте позже.'))
  })
}
