import React, {useLayoutEffect, useState} from 'react';
import {Box, Popper, Stack, ClickAwayListener} from "@mui/material";
import {filtersPopover, filtersPopoverContainer} from "../../../OperationalReportStyles";
import FilterList from "./FilterList/FilterList";
import {
  getMachinesThunk,
  getORPlots, getStoresThunk,
  removeFilter,
  setFiltersData
} from "../../../../../redux/reducers/operationalReportReducer";
import {useDispatch, useSelector} from "react-redux";
import FilterDate from "./FilterDate/FilterDate";
import FilterVolume from "./FilterVolume/FilterVolume";
import ButtonsBlock from "./ButtonsBlock/ButtonsBlock";
import {formatDateFromObjToStr, formatDateFromStrToObj} from "../../../../Main/Map/Common/DateTimeFunctions";
import {getReferenceThunk} from "../../../../../redux/reducers/referencesReducer";
import {getOrganizationUsersThunk} from "../../../../../redux/reducers/organizationInfoReducer";

const FiltersPopover = ({open, anchorEl, filterPopoverToggler, column, dataType}) => {
  const dispatch = useDispatch()
  const tableFilters = dataType === 'harvestingData'
    ? useSelector(state => state.operationalReportReducer.harvestingTableFilters)
    : useSelector(state => state.operationalReportReducer.removalTableFilters)
  const machines = useSelector(state => state.operationalReportReducer.machines) || [];
  const species = useSelector(state => state.referencesReducer.references.species) || [];
  const plots = useSelector(state => state.operationalReportReducer.plots) || [];
  const stores = useSelector(state => state.operationalReportReducer.stores) || [];
  const organizationUsers = useSelector(state => state.organizationInfoReducer.organizationUsers) || []
  const [selectedItems, setSelectedItems] = useState([])  // [{id: id, type?: type}, ...]
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startVolume, setStartVolume] = useState(null);
  const [endVolume, setEndVolume] = useState(null);
  const {name: columnName, filterType} = column
  const filterData = getFilterData(columnName) || []

  function getFilterData(columnName) {
    switch (columnName) {
      case 'machine':
        if (dataType === 'harvestingData') return machines.filter(machine => machine.is_for_harvesting)
        if (dataType === 'removalData') return machines.filter(machine => !machine.is_for_harvesting)
        break
      case 'specie':
        return species
      case 'plot':
        return plots.filter(plot => plot.name)
      case 'operator':
        return organizationUsers
      case 'point_from':
      case 'point_to':
        let formattedPlots = plots.filter(plot => plot.name).map(plot => ({...plot, type: 'plot'}))
        let formattedStores = stores.map(store => ({...store, type: 'store'}))
        return [...formattedPlots, ...formattedStores]
    }
  }

  // эффект, который подгружает нужные данные при открытии окна фильтра в зависимости от столбца
  useLayoutEffect(() => {
    switch (columnName) {
      case 'machine':
        !machines.length && dispatch(getMachinesThunk())
        break
      case 'specie':
        !species.length && dispatch(getReferenceThunk('species'));
        break
      case 'plot':
        !plots.length && dispatch(getORPlots())
        break
      case 'operator':
        !organizationUsers.length && dispatch(getOrganizationUsersThunk())
        break
      case 'point_from':
      case 'point_to':
        !stores.length && dispatch(getStoresThunk())
        !plots.length && dispatch(getORPlots())
    }
  }, [])

  // обработчик подгружающий выделенные ранее элементы фильтров из стора во внутренний стейт
  useLayoutEffect(() => {
    switch (filterType) {
      case 'list':
      case 'points':
        tableFilters[columnName] && setSelectedItems(filterData.filter(item => tableFilters[columnName].includes(item.id)))
        break
      case 'dates':
        tableFilters?.date_range_after && setStartDate(formatDateFromStrToObj(tableFilters.date_range_after[0]))
        tableFilters?.date_range_before && setEndDate(formatDateFromStrToObj(tableFilters.date_range_before[0]))
        break
      case 'range':
        tableFilters?.volume__gt && setStartVolume(tableFilters.volume__gt)
        tableFilters?.volume__lt && setEndVolume(tableFilters.volume__lt)
    }
  }, [])


  const undoClickHandler = (e) => {
    setSelectedItems([])
    filterPopoverToggler(e);
  };

  // в этой функции в стор добавляются выбранные фильтры
  const applyClickHandler = (e) => {
    switch (filterType) {
      case 'list':
        if (selectedItems.length) {
          dispatch(setFiltersData({[columnName]: selectedItems.map(item => item.id)}, dataType))
        } else {
          dispatch(removeFilter(columnName, dataType))
        }
        break
      case 'points':
        if (selectedItems.length) {
          let storesPoints = selectedItems.filter(point => point.type === 'store')
          let plotsPoints = selectedItems.filter(point => point.type === 'plot')
          let storesFiledName = columnName === 'point_from' ? 'store_from_id' : 'store_to_id'
          let plotsFiledName = columnName === 'point_from' ? 'plot_from_id' : 'plot_to_id'
          let pointsFilters = {}
          if (storesPoints.length) {
            pointsFilters[storesFiledName] = storesPoints.map(point => point.id)
          }
          if (plotsPoints.length) {
            pointsFilters[plotsFiledName] = plotsPoints.map(point => point.id)
          }
          dispatch(setFiltersData(pointsFilters, dataType))
        } else {
          dispatch(removeFilter(columnName, dataType))
        }
        break
      case 'dates':
        let formattedStartDate = startDate ? formatDateFromObjToStr(startDate) : null;
        let formattedEndDate = endDate ? formatDateFromObjToStr(endDate) : null;
        let dateFilters = {}
        if (formattedStartDate) {
          dateFilters.date_range_after = [formattedStartDate]
        }
        if (formattedEndDate) {
          dateFilters.date_range_before = [formattedEndDate]
        }
        dispatch(setFiltersData(dateFilters, dataType))
        break
      case 'range':
        let volumeFilters = {}
        if (startVolume) {
          volumeFilters.volume__gt = [startVolume]
        }
        if (endVolume) {
          volumeFilters.volume__lt = [endVolume]
        }
        dispatch(setFiltersData(volumeFilters, dataType))
    }
    filterPopoverToggler(e);
  }

  const handleFilterItemClick = (item) => {
    selectedItems.find(selectedItem => selectedItem.id === item.id)
      ? setSelectedItems(prev => prev.filter(selectedItem => selectedItem.id !== item.id))
      : setSelectedItems(prev => [...prev, item])
  }
  return (
    <ClickAwayListener onClickAway={undoClickHandler}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        sx={filtersPopover}
        disablePortal
      >
        <Stack sx={{height: '100%'}}>
          <Box sx={filtersPopoverContainer}>
            {(filterType === 'list' || filterType === 'points') &&
              <FilterList filterData={filterData} columnName={columnName}
                          selectedItems={selectedItems} handleItemClick={handleFilterItemClick}/>
            }
            {filterType === 'dates' &&
              <FilterDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
            }
            {filterType === 'range' &&
              <FilterVolume startVolume={startVolume} setStartVolume={setStartVolume}
                            endVolume={endVolume} setEndVolume={setEndVolume}/>
            }
          </Box>
          <ButtonsBlock undoClickHandler={undoClickHandler} applyClickHandler={applyClickHandler}/>
        </Stack>
      </Popper>
    </ClickAwayListener>
  );
};

export default FiltersPopover;