import {getMiscLayer, resetMiscLayers} from "../../LeftPanel/Context/MiscLayers/MiscLayersCommon";
import {getMap1} from "../GlobalObjects";

const layers = {
  lesnich: {
    name: 'Лесничества',
    l: null,
    show: false,
    pane: 'blind_misc',
    style: 'dark',
    group: true,
    styles: {
      light: 'lesnich',
      dark: 'lesnich_dark',
    },
    type: 'tiles_wms',
    minZoom: 8,
    maxZoom: 20,
    feas: false,
  },
  lesnich_u: {
    name: 'Уч. лесничества',
    l: null,
    show: false,
    pane: 'blind_misc',
    style: 'dark',
    group: true,
    styles: {
      light: 'lesnich_u',
      dark: 'lesnich_u_dark',
    },
    type: 'tiles_wms',
    minZoom: 8,
    maxZoom: 20,
    feas: false,
  },
  kv: {
    name: 'Квартальная сеть',
    l: null,
    show: false,
    pane: 'blind_misc',
    type: 'tiles_wms',
    style: 'dark',
    group: true,
    styles: {
      light: 'kv',
      dark: 'kv_dark',
    },
    minZoom: 10,
    maxZoom: 20,
    feas: false,
  },
  russia_rent: {
    name: 'Договоры аренды',
    l: null,
    show: false,
    all: null,
    pane: 'blind_bigarenda',
    type: 'tiles_wms',
    style: 'russia_rent_colorid',
    minZoom: 0,
    maxZoom: 20,
    feas: true,
    opacity: 0.7,
  },
  firepoints_current: {
    name: 'Пожары',
    l: null,
    all: null,
    arch: false,
    pane: 'blind_fires',
    show: false,
    type: 'tiles_fires',
    style: '',
    minZoom: 0,
    maxZoom: 20,
    feas: true,
    opacity: 0.7,
  },
  planetablets: {
    name: 'Планшеты',
    l: null,
    all: null,
    pane: 'blind_misc',
    show: false,
    type: 'planetablets',
    style: 'russia_rent_colorid',
    minZoom: 12,
    maxZoom: 20,
    feas: false,
    opacity: 1,
  },
}

export function getBlindMiscLayers() {
  return layers;
}

export function resetBlindMiscLayers() {
  resetMiscLayers(getBlindMiscLayers())
}

export function addAllShowedBlindMiscLayersToMap() {
  const map = getMap1()
  Object.entries(layers).map(([key, value]) => {
    if (value.show) {
      value.l = getMiscLayer(key, false)
      map.elz_zoom.addLayer(value.l, value.minZoom, value.maxZoom)
    }
  })
}
