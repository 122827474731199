import {getUserSettings} from "../../../Map/Common/userSettings";
import {getMap1} from "../../../Map/GlobalObjects";
import {getBlindMiscLayers} from "../../../Map/Blind/BlindMiscLayersCommon";
import L from "leaflet";
import {cannotIDo, getTariff} from "../../../Map/Common/tariffs";

const layers = {
  lesnich: {
    name: 'Лесничества',
    l: null,
    pane: 'misc',
    type: 'tiles_wms',
    style: 'dark',
    group: true,
    styles: {
      light: 'lesnich',
      dark: 'lesnich_dark',
    },
    minZoom: 8,
    maxZoom: 20,
    show: false,
    feas: false,
  },
  lesnich_u: {
    name: 'Уч. лесничества',
    l: null,
    pane: 'misc',
    type: 'tiles_wms',
    style: 'dark',
    group: true,
    styles: {
      light: 'lesnich_u',
      dark: 'lesnich_u_dark',
    },
    minZoom: 8,
    maxZoom: 20,
    show: false,
    feas: false,
  },
  kv: {
    name: 'Квартальная сеть',
    l: null,
    pane: 'misc',
    show: false,
    type: 'tiles_wms',
    style: 'dark',
    group: true,
    styles: {
      light: 'kv',
      dark: 'kv_dark',
    },
    minZoom: 10,
    maxZoom: 20,
    feas: false,
  },
  russia_rent: {
    name: 'Договоры аренды',
    l: null,
    all: null,
    pane: 'bigarenda',
    show: false,
    type: 'tiles_wms',
    style: 'russia_rent_colorid',
    minZoom: 0,
    maxZoom: 20,
    feas: true,
    opacity: 0.7,
  },
  firepoints_current: {
    name: 'Пожары',
    l: null,
    all: null,
    arch: false,
    pane: 'misc_fires',
    show: false,
    type: 'tiles_fires',
    style: '',
    minZoom: 0,
    maxZoom: 20,
    feas: true,
    opacity: 0.7,
  },
  planetablets: {
    name: 'Планшеты',
    l: null,
    all: null,
    pane: 'misc',
    show: false,
    type: 'planetablets',
    style: 'russia_rent_colorid',
    minZoom: 12,
    maxZoom: 20,
    feas: false,
    opacity: 1,
  },
}
let first = true;

export const mlOptsTemplate = {
  format: 'image/png',
  transparent: true,
  version: '1.3.0',
  maxZoom: 20,
  noWrap: true,
  maxBounds: "[-90, -180],[90, 180]]",
  opacity: 1,
  pane: 'misc',
}

export function getMiscLayers() { //возвращает объект всяких слоёв
  return layers;
}

export function initMiscLayers(is_blind) { // загрузка всяких слоев и впихивание их в карту (is_blind - в шторку)
  const layers = is_blind ? getBlindMiscLayers() : getMiscLayers()
  const settings = is_blind ? getUserSettings().blind.miscLayers : getUserSettings().miscLayers;
  if (settings)
    Object.entries(settings).map(([key, value]) => {
      if (layers[key]) {
        const curLayer = layers[key]
        const map = getMap1()
        const isis = key !== 'planetablets' || getTariff().planetable.authkey;
        if (cannotIDo.viewMiscLayer(key) === 0 && isis) {
          curLayer.show = value.show;
          if (value.style)
            curLayer.style = value.style;
          if (value.show) {
            if (!is_blind || getUserSettings().blind.enabled) {
              curLayer.l = getMiscLayer(key, !is_blind);
              map.elz_zoom.addLayer(curLayer.l, curLayer.minZoom, curLayer.maxZoom)
            }
          }
        }
      }
    })
}

export function resetMiscLayers(layers) { // удаление всех всяких слоев с карты (layer - объект слов обычных или шторки)
  const map = getMap1()
  Object.values(layers).map(data => {
    if (data.l)
      map.elz_zoom.removeLayer(data.l)
    data.l = null;
    //data.show = false;
  })
  first = true;
}

//создание всякого слоя, index - ключ поля обьекта,  notForBlind - шторка или нет, pane - в какую панель пихать
export function getMiscLayer(index, notForBlind = true, pane = null) {
  const layers = notForBlind ? getMiscLayers() : getBlindMiscLayers()
  const curLay = layers[index];
  let mlOpts = {...mlOptsTemplate}
  if (curLay.group)
    mlOpts.layers = curLay.styles[curLay.style]
  else
    mlOpts.layers = index;
  if (!pane)
    mlOpts.pane = curLay.pane;
  else
    mlOpts.pane = pane;
  mlOpts.opacity = curLay.opacity ? curLay.opacity : 1;
  const gr = L.layerGroup()
  let lay = null;

  switch (curLay.type) {
    case 'tiles_wms':
      const url_geo = process.env.REACT_APP_GEOSERVER_URL + 'tiles/geoserver/wms?';
      mlOpts.tiled = true; //chashing
      mlOpts.noWrap = true;
      mlOpts.bounds = [[-90, -180], [90, 180]]
      lay = L.tileLayer.wms(url_geo, mlOpts)
      gr.addLayer(lay)
      return gr;
    case 'tiles_fires':
      const url_fires = process.env.REACT_APP_GEOSERVER_URL + 'tiles/geoserver/wms?';
      mlOpts.tiled = false; //no wms-t
      mlOpts.noWrap = true;
      mlOpts.bounds = [[-90, -180], [90, 180]]
      mlOpts.layers = 'firepoints_arch,' + mlOpts.layers;
      lay = L.tileLayer.wms(url_fires, mlOpts)
      gr.addLayer(lay)
      return gr;
    case 'planetablets':
      const url = process.env.REACT_APP_GEOSERVER_PLANS_URL;
      const planet = getTariff().planetable;
      mlOpts.tiled = false; //no wms-t
      if (planet.authkey && !getTariff().expired) {
        mlOpts.layers = planet.layer;
        mlOpts.authkey = planet.authkey;
        lay = L.tileLayer.wms(url, mlOpts)
        lay.setParams({fake: (new Date()).getTime()}, false) //for browser cache trouble (not work?)
        gr.addLayer(lay)
      }
      return gr;
    default:
      return null;
  }
}
