import React, {useEffect, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {
  setForestUseReportAttr,
  setPlotOutlinePoint,
  setQuarterOutlinePoint,
} from "../../../../redux/reducers/forestUseReportReducer";
import './reportImage.css'
import {
  dragPlotPolygon,
  dragPoint,
  dragQuarterPolygon,
  getCoord,
  keydown,
  setCurrentPoint,
  setViewedFigureAndCursorType
} from "../svgFunctions/svgFunctions";
import ReportImageOperationButtons from "./ReportImageOperationButtons/ReportImageOperationButtons";
import Quarter from "./reportImageSvgElements/Quarter";
import Plot from "./reportImageSvgElements/Plot";
import NewPlot from "./reportImageSvgElements/NewPlot";
import NewQuarter from "./reportImageSvgElements/NewQuarter";
import NewQuarterPoint from "./reportImageSvgElements/NewQuarterPoint";
import NewPlotPoint from "./reportImageSvgElements/newPlotPoint";

/*export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}*/

const ReportImage = ({image, containerWidth, containerHeight}) => {
  const dispatch = useDispatch()
  const forestUseReportState = useSelector(state => state.forestUseReportReducer)
  const {quarterPx, quarterNumber, quarterCenterPoint, plotPx, drawMode, selectedQuarterPointIndex,
    selectedPlotPointIndex, newQuarterPx, newPlotPx} = forestUseReportState
  const [startDragPosition, setStartDragPosition] = useState({x: 0, y: 0})
  const [dragMode, setDragMode] = useState('view') //variants: 'view', 'dragQuarter', 'dragPlot',
  const [selectedFigure, setSelectedFigure] = useState(null)
  const [viewedFigure, setViewedFigure] = useState(null)
  const [viewedPoint, setViewedPoint] = useState({index: null, type: null})
  const [currentPointPosition, setCurrentPointPosition] = useState({x: null, y: null})
  const [cursorType, setCursorType] = useState('crosshair')
  const scale = Math.min(containerWidth / image.width, containerHeight / image.height) || 1
  const svgRef = useRef(null)
  //эффект убирает выделение фигуры при включении режима создания пользовательской фигуры
  useEffect(() => {
    drawMode !== 'view' && setSelectedFigure(null)
  }, [drawMode])

  const coordCorrection = (coord) => {
    return coord * scale
  }

  let startTracking = (e) => {
    const figureData = e.target.getAttribute('i')?.split('_')
    if (figureData && drawMode === 'view') {
      const [figureType, figureIndex] = figureData
      switch (figureType) {
        case 'plotPolygon':
          if (selectedFigure === 'plotPolygon') {
            setCurrentPoint(e, setStartDragPosition)
            setDragMode('dragPlotPolygon')
          }
          break
        case 'quarterPolygon':
          if (selectedFigure === 'quarterPolygon') {
            setCurrentPoint(e, setStartDragPosition)
            setDragMode('dragQuarterPolygon')
          }
          break
        case 'quarterPoint':
          setDragMode('dragQuarterPoint')
          setCurrentPoint(e, setStartDragPosition)
          dispatch(setForestUseReportAttr({selectedQuarterPointIndex: +figureIndex}))
          break
        case 'plotPoint':
          setDragMode('dragPlotPoint')
          setCurrentPoint(e, setStartDragPosition)
          dispatch(setForestUseReportAttr({selectedPlotPointIndex: +figureIndex}))
          break
      }
    }
  }

  let tracking = (e) => {
    switch (drawMode) {
      case 'view':
        const figureData = e.target.getAttribute('i')?.split('_')
        if (figureData && dragMode === 'view') {
          setViewedFigureAndCursorType(figureData, setCursorType, setViewedFigure, setViewedPoint, selectedFigure,
            selectedQuarterPointIndex, selectedPlotPointIndex)
        } else {
          setCursorType('crosshair')
          setViewedFigure(null)
          setViewedPoint({index: null, type: null})
        }

        switch (dragMode) {
          case 'dragPlotPolygon':
            setCursorType('grabbing')
            dragPlotPolygon(e, startDragPosition, setStartDragPosition, plotPx, dispatch)
            break
          case 'dragQuarterPolygon':
            setCursorType('grabbing')
            dragQuarterPolygon(e, startDragPosition, setStartDragPosition, quarterPx, quarterNumber, quarterCenterPoint,
              dispatch)
            break
          case 'dragPlotPoint':
            setCursorType('grabbing')
            dragPoint(e, startDragPosition, setStartDragPosition, plotPx, selectedPlotPointIndex, dispatch,
              'plotPoint')
            break
          case 'dragQuarterPoint':
            setCursorType('grabbing')
            dragPoint(e, startDragPosition, setStartDragPosition, quarterPx, selectedQuarterPointIndex, dispatch,
              'quarterPoint')
            break
        }
        break

      case 'drawQuarter':
      case 'drawPlot':
        const point = getCoord(e, svgRef, image)
        setCurrentPointPosition(point)
    }

  }

  let click = (e) => {
    const figureData = e.target.getAttribute('i')?.split('_')
    switch (drawMode) {
      case 'view':
        if (figureData) {
          const [figureType] = figureData
          switch (figureType) {
            case 'quarterPolygon':
            case 'plotPolygon':
              dragMode === 'view' && setSelectedFigure(figureType)
              break
          }
        } else {dragMode !== 'dragPlotPoint' && dragMode !== 'dragQuarterPoint' && setSelectedFigure(null)}
        break
      case 'drawPlot':
        dispatch(setPlotOutlinePoint(getCoord(e, svgRef, image)))
        break
      case 'drawQuarter':
        dispatch(setQuarterOutlinePoint(getCoord(e, svgRef, image)))
    }
    setDragMode('view')
  }

  const onMouseLeaveHandler = () => {
    setDragMode('view')
    setCurrentPointPosition({x: null, y: null})
  }


  return (
    <Box id={'photoModalPage'} sx={{
      overflow: 'hidden',
      width: image.width * scale
    }}>
      <ReportImageOperationButtons selectedFigure={selectedFigure}/>
      <svg
        style={{cursor: cursorType, outline: 'none'}}
        width={image.width * scale}
        height={image.height * scale}
        ref={svgRef}
        tabIndex="0"
        id={'reportSvg'}
        onMouseMove={(e) => {
          tracking(e)
        }}
        onMouseDown={(e) => {
          startTracking(e)
        }}
        onMouseUp={(e) => {
          click(e)
        }}
        onMouseLeave={() => {
          onMouseLeaveHandler()
        }}
        onKeyDown={(e) => {
          dispatch(setForestUseReportAttr({currentlyPressedButton: e.code}))
          keydown(e, quarterPx, quarterCenterPoint, plotPx, dispatch,
            drawMode, selectedFigure, newQuarterPx, newPlotPx)
        }}
        onKeyUp={() => dispatch(setForestUseReportAttr({currentlyPressedButton: null}))}
      >

        <image width={'100%'} href={image.src}/>
        {/* аттрибут i для фигур svg используется в коде в качестве идентификатора фигуры. Имеет такую форму -
              "figureType_figureIndex", напр. `quarterPolygon_4`, `plotPoint_8`. Через
               e.target.getAttribute('i').split('_'), получаем нужные данные фигуры*/}
        {/*svg figures*/}
        {quarterPx && (
          <Quarter
            setViewedFigure={setViewedFigure} viewedFigure={viewedFigure}
            viewedPoint={viewedPoint} selectedFigure={selectedFigure} coordCorrection={coordCorrection}
          />
        )}
        {plotPx && (
          <Plot
            setViewedFigure={setViewedFigure} viewedFigure={viewedFigure}
            viewedPoint={viewedPoint} selectedFigure={selectedFigure} coordCorrection={coordCorrection}
          />
        )}
        {drawMode === 'drawPlot' &&
          <>
            <NewPlot coordCorrection={coordCorrection}/>
            <NewPlotPoint coordCorrection={coordCorrection} pointPosition={currentPointPosition}/>
          </>

        }
        {drawMode === 'drawQuarter' &&
          <>
            <NewQuarter coordCorrection={coordCorrection}/>
            <NewQuarterPoint coordCorrection={coordCorrection} pointPosition={currentPointPosition}/>
          </>
        }
      </svg>
    </Box>
  );
};

export default ReportImage;
