import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, ListItemIcon, Menu, MenuItem, Typography} from "@mui/material";
import {grouppingString} from "../../../Main/Map/Common/Strings_RU";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import {setStockpilesAttr} from "../../../../redux/reducers/stockpilesReducer";

const PlotsGrouping = () => {
  const dispatch = useDispatch()
  const plotsFilters = useSelector(state => state.stockpilesReducer.plotsFilters)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleItemClick = (key) => {
    const status = plotsFilters[key].status
    const name = plotsFilters[key].name
    dispatch(setStockpilesAttr({plotsFilters: {
      ...plotsFilters, [key]: {status: !status, name: name}
    }}))
  }
  return (
    <>
      <Button
        color={'info'}
        style={{textTransform: 'none', paddingLeft: '0'}}
        size={'small'}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        <Typography>{grouppingString}</Typography>
        <ArrowDropDownIcon fontSize={'small'}/>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(plotsFilters).map(key => (
          <MenuItem key={plotsFilters[key].name}
                    onClick={() => handleItemClick(key)}
          >
            <ListItemIcon>
              {plotsFilters[key].status &&
                <CheckIcon/>
              }
            </ListItemIcon>
            {plotsFilters[key].name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PlotsGrouping;