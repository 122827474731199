export const planetablesGroupingContainer = {overflow: 'hidden', position: 'relative', boxSizing: 'border-box'}

export const planetablesMainBlockContainer = {overflow: 'hidden', height: '100%'}

export const planetablesToolbarContainer = {display: 'flex', alignItems: 'center',
  p:1, width: '100%', boxSizing: 'border-box'}

export const planetablesSvgContainer = {width: '100%', height: '100%', overflow: 'hidden', textAlign: 'center'}

export const planetablesSvg = {display: 'flex', justifyContent: 'center', overflow: 'hidden',
  outline: 'none'}

export const toolbarButton = {minWidth: '180px', whiteSpace: 'no-wrap'}

export const planetablesTreelistItemStyle = {
  display: 'flex', flexDirection: 'column', justifyContent: 'start',
  alignItems: 'flex-start', width: '100%', padding: '3px 0px 3px 0px', margin: 0,
  cursor: 'pointer', userSelect: 'none',
}

export const planetablesTextColor = '#545454'

export const planetablesTreeLeftPaddingStep = 36

export const treeItemTitleText = {fontWeight: '600', padding: 0,}
export const treeItemPlanetableText = { fontStyle: 'italic', fontWeight: '400', margin: 0,}
