import React from 'react';
import {ListItemIcon, MenuItem} from "@mui/material";
import {urlGenerator} from "../../../../utils/urlGenerator";
//import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";
import DashboardIcon from '@mui/icons-material/Dashboard';
import {cannotIDo} from "../../../Main/Map/Common/tariffs";

const PlanetablePageMenuItem = ({handleClose}) => {
  const navigate = useNavigate()

  return (
    <>
      <MenuItem
        disabled={!!cannotIDo.usePlanetabletsModule()}
        onClick={() => {
          navigate(urlGenerator().planetables)
          handleClose()
      }}>
        <ListItemIcon>
          <DashboardIcon fontSize="small" />
        </ListItemIcon>
        <Typography>Редактор планшетов</Typography>
      </MenuItem>
    </>
  )
}

export default PlanetablePageMenuItem;
