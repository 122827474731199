import {Box, Button, Divider, MenuItem, Select, Slider, Switch, TextField, Typography} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {getMap1} from "../../Map/GlobalObjects";
import {createBaseLayer, getBaseLayers, getSentinelUrl} from "./BaseLayersCommon";
import {getSentinelDate} from "../../Map/Common/DateTimeFunctions";
import {useState} from "react";
import {OverlayDraggablePane} from "../OvelayDraggablePane";
import {setSettingWasChanged} from "../../Map/Common/userSettings";
import {useDispatch, useSelector} from "react-redux";
import {showBaseLayersWindows} from "./BaseLayersReducer";
import Stack from "@mui/material/Stack";
import {getBlindBaseLayers} from "../../Map/Blind/BlindBaseLayersCommon";
import {cannotIDo} from "../../Map/Common/tariffs";
import {setSnack} from "../../Map/Common/Dialog/Snack/snackReducer";
import {
  applyString,
  baseLayersTitleString,
  sentinelLayerForbiddenString,
  transparentString
} from "../../Map/Common/Strings_RU";
import './css.css';

export function BaseLayersChooser() {
  const map = getMap1()
  const [refresh, setRefresh] = useState(false)
  const state = useSelector(state => state.baseLayersReducer)
  const dispatch = useDispatch()
  const leftBlind = useSelector(state => state.blindReducer.left)
  const sentinelDisabled = cannotIDo.useSentinelLayer() !== 0;

  let layers = null;

  if (!state.visible)
    return null;

  if (leftBlind)
    layers = getBaseLayers()
  else
    layers = getBlindBaseLayers()

  function toggleLayer(index) {
    if (index === 'sentinel' && sentinelDisabled) {
      dispatch(setSnack('warning', sentinelLayerForbiddenString))
    } else {
      const da = layers[index];
      if (da.l) {
        map.removeLayer(da.l)
        da.l = null;
        da.show = false;
      } else {
        const lay = createBaseLayer(index, true, !leftBlind)
        map.addLayer(lay)
        da.l = lay;
        da.show = true;
      }
      setSettingWasChanged()
      setRefresh(!refresh)
    }
  }

  function changeLayerOpacity(dal, value) {
    if (dal.l) {
      dal.l.setOpacity(value)
      dal.opacity = value;
      setRefresh(!refresh)
    } else {
      console.error(`No layer was created: "${dal.name}"`)
    }
    setSettingWasChanged()
  }

  function sentinelFiltersApply() {
    //const map = getMap()
    const layers = leftBlind?getBaseLayers():getBlindBaseLayers()
    const time1 = getSentinelDate(layers.sentinel.time1),
      time2 = getSentinelDate(layers.sentinel.time2),
      cc = layers.sentinel.cc,
      layer = layers.sentinel.channel;
    //layers.sentinel.url = process.env.REACT_APP_BASE_URL + `api/v1/gis/santinel_px/wmts?REQUEST=GetTile&LAYER=${layer}&TILEMATRIXSET=PopularWebMercator256&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&TIME=${time1}/${time2}&MAXCC=${cc}`;
    layers.sentinel.url = getSentinelUrl(layer, time1, time2, cc)
    if (layers.sentinel.l) {
      map.removeLayer(layers.sentinel.l)
    }
    layers.sentinel.l = leftBlind ? createBaseLayer('sentinel') : createBaseLayer('sentinel', true, true)
    map.addLayer(layers.sentinel.l)

  }

  function sentinelChannelChange(layer) {
    const time1 = getSentinelDate(layers.sentinel.time1),
      time2 = getSentinelDate(layers.sentinel.time2),
      cc = layers.sentinel.cc;
    //layers.sentinel.url = process.env.REACT_APP_BASE_URL + `api/v1/gis/santinel_px/wms?REQUEST=GetTile&RESOLUTION=10&LAYERS=${layer}&TILEMATRIXSET=PopularWebMercator256&gamma=1&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&TIME=${time1}/${time2}&MAXCC=${cc}`;
    //layers.sentinel.url = process.env.REACT_APP_BASE_URL + `api/v1/gis/santinel_px/wmts?REQUEST=GetTile&LAYER=${layer}&bbox=bbox-epsg-3857&TILEMATRIXSET=PopularWebMercator256&&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&TIME=${time1}/${time2}&MAXCC=${cc}`;
    layers.sentinel.url = getSentinelUrl(layer, time1, time2, cc)
    map.removeLayer(layers.sentinel.l)
    layers.sentinel.l = leftBlind ? createBaseLayer('sentinel') : createBaseLayer('sentinel', true, true)
    map.addLayer(layers.sentinel.l)
  }

  function closeButtonHandler() {
    dispatch(showBaseLayersWindows(false))
  }

  function openGogUslugi() {
    window.open('https://www.gosuslugi.ru/crt', "_blank");
  }

  return (
    <OverlayDraggablePane headerText={baseLayersTitleString} closeAction={closeButtonHandler}>
      {Object.entries(layers).map(([key, value]) => {
        return (
          <Stack key={key} flexDirection={'column'} style={{margin: '1vh'}}>
            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'start'}>
              <Switch size='small' onChange={() => toggleLayer(key)} checked={value.show}/>
              <Typography style={{cursor: 'pointer'}} onClick={() => toggleLayer(key)}>
                {value.name}
              </Typography>
              {key === 'eeco' &&
                <Typography className={'base-layer-link'} style={{fontSize: '80%'}} onClick={openGogUslugi}>
                  &nbsp;(требуются сертификаты минцифры)
                </Typography>
              }
              {key === 'sentinel' &&
                <Select style={{flex: 1}}
                        size='small'
                        variant='standard'
                        value={value.channel}
                        sx={{mx: 1}}
                        onChange={(e) => {
                          value.channel = e.target.value;
                          sentinelChannelChange(e.target.value);
                          setRefresh(!refresh)
                        }}
                        disabled={sentinelDisabled || !value.l}
                >
                  <MenuItem value='TRUE-COLOR-S2L2A'>TRUE-COLOR-S2L2A</MenuItem>
                  <MenuItem value='SENTINEL2L2A'>SENTINEL2L2A</MenuItem>
                  <MenuItem value='FALSE-COLOR'>FALSE-COLOR</MenuItem>
                  <MenuItem value='FALSE-COLOR-URBAN'>FALSE-COLOR-URBAN</MenuItem>
                  <MenuItem value='AGRICULTURE'>AGRICULTURE</MenuItem>
                  <MenuItem value='BATHYMETRIC'>BATHYMETRIC</MenuItem>
                  <MenuItem value='MOISTURE-INDEX'>MOISTURE-INDEX</MenuItem>
                  <MenuItem value='SWIR'>SWIR</MenuItem>
                  <MenuItem value='TRUE-COLOR-S2L2A-LAST'>TRUE-COLOR-S2L2A-LAST</MenuItem>
                  <MenuItem value='NATURAL-COLOR'>NATURAL-COLOR</MenuItem>
                </Select>
              }
            </Stack>
            <Stack flexDirection={'row'} sx={{pt: 1}}>
              <Typography>{transparentString}: </Typography>
              <Slider
                value={value.opacity}
                step={0.05}
                min={0}
                max={1}
                size='small'
                sx={{mx: 1}}
                onChange={(e, v) => changeLayerOpacity(value, v)} disabled={!value.l}
              />
            </Stack>
            {key === 'sentinel' &&
              <Stack>
                <Stack flexDirection={'row'}>
                  <Typography>Облачность: </Typography>
                  <Slider
                    color="warning"
                    value={value.cc}
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100}
                    size='small'
                    sx={{mx: 1}}
                    disabled={sentinelDisabled || !value.l}
                    onChange={(e, v) => {
                      value.cc = v;
                      setRefresh(!refresh)
                    }}
                  />
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{pt: 1}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru-RU'}>
                    <DatePicker
                      label="Начальная дата"
                      inputFormat="DD.MM.YYYY"
                      size='small'
                      value={value.time1}
                      onChange={(val) => {
                        value.time1 = val;
                        setRefresh(!refresh)
                      }}
                      renderInput={(params) => <TextField size="small" {...params} style={{maxWidth: '200px'}}/>}
                      disabled={sentinelDisabled || !value.l}
                    />
                    <Box style={{flex: 1, minWidth: '3px'}} />
                    <DatePicker
                      label="Конечная дата"
                      inputFormat="DD.MM.YYYY"
                      size='small'
                      value={value.time2}
                      onChange={(val) => {
                        value.time2 = val;
                        setRefresh(!refresh)
                      }}
                      renderInput={(params) => <TextField size="small" {...params} style={{maxWidth: '200px'}}/>}
                      disabled={sentinelDisabled || !value.l}
                    />
                  </LocalizationProvider>
                </Stack>
                <Button color={'success'} variant='outlined' size='small' disabled={sentinelDisabled || !value.show} sx={{mt: 1}}
                        onClick={() => sentinelFiltersApply()}
                >
                  {applyString}
                </Button>
              </Stack>
            }
            {key !== 'sentinel' && <Divider flexItem sx={{pt: '0.5vh'}} />}
          </Stack>
        )
      })
      }
    </OverlayDraggablePane>
  )
}
