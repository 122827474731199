import React from 'react';
import DeletePhotoButton from "./DeletePhotoButton/DeletePhotoButton";
import ToolbarBlockContainer from "../../ToolbarBlockContainer/ToolbarBlockContainer";
import RecognitionPhotoButton from "./RecognitionPhotoButton/RecognitionPhotoButton";
import FullScreenLoading from "../../../../Common/FullScreenLoading/FullScreenLoading";
import DownloadPhotoButton from "./DownloadPhotoButton/DownloadPhotoButton";

const PhotoButtons = () => {

  return (
    <ToolbarBlockContainer title={'Фото'}>
      <RecognitionPhotoButton/>
      <DeletePhotoButton/>
      <DownloadPhotoButton/>
    </ToolbarBlockContainer>
  );
};

export default PhotoButtons;
