import {getCuttingAreas} from './CuttingsAreas.js';
import {CAPropertyFilter} from "./CAPropertyFilter";
import {useDispatch, useSelector} from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CancelIcon from '@mui/icons-material/Cancel';
import {filterList, resetCAFilters} from '../../../Map/Filters/FiltersReducer'
import Box from "@mui/material/Box";
import {clearAllFiltersString} from "../../../Map/Common/Strings_RU";
import {insrastructureViewChangeData} from "../../../Overlay/InfrastructureView/infrascructureViewReduces";

export function FiltersControl() { // Вкладка фильтров делянов
  const dispatch = useDispatch()
  const cas = getCuttingAreas()

  useSelector(state => state.filterReducer)

  let ind = 0;
  return (
  <Box id={'filtersBlock'}>
    {cas.isLoading && !cas.loadingError && cas.layerArray.length > 0 &&
      <Stack sx={{
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      >
        {Object.keys(cas.filters).map(key => {
          return <CAPropertyFilter key={ind++} filter={key} name={cas.filtersNamesMap[key]}/>
        })}
        <Button variant="outlined"
                startIcon={<CancelIcon color={'error'}/>}
                sx={{mt: 3}}
                onClick={() => {
                  dispatch(resetCAFilters(structuredClone(filterList), true))
                  dispatch(insrastructureViewChangeData({cas: true}, 'main'))
                }}
        >
          {clearAllFiltersString}
        </Button>
      </Stack>
    }
  </Box>
)
}
