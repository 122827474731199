import React from 'react';
import {setRightPanelAttr} from "../../../../../redux/reducers/rightPanelReducer";
import {useDispatch} from "react-redux";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CloseButton = () => {
  const dispatch = useDispatch();
  const closeButtonHander = () => {
    dispatch(setRightPanelAttr({rightPanelVisible: false}))

  };
  return (
    <IconButton
      size={'small'}
      sx={{p: 0}}
      onClick={closeButtonHander}
    >
      <CloseIcon/>
    </IconButton>
  );
};

export default CloseButton;