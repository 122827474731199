import React from 'react';
import {TextField} from "@mui/material";
import {getValueFromRowByColumn} from "../../../ORhelperFunctions";

const ORTextField = ({column, setRowData, rowData}) => {
  const {name: columnName, inputProps} = column
  let value = columnName in rowData ? getValueFromRowByColumn(columnName, rowData[columnName]): ''

  const onChangeHandler = (e) => {
    if (Number(e.target.value) < inputProps.max) {
      setRowData(prev => ({...prev, [columnName]: e.target.value}))
    }
  }

  return (
    <TextField
      margin="dense"
      variant={'standard'}
      type={column.type}
      required
      fullWidth
      value={value}
      onChange={onChangeHandler}
      inputProps={{...inputProps}}
    />
  );
};

export default ORTextField;