import {initialState, setRightPanelAttr} from "../../../redux/reducers/rightPanelReducer";
import {otherDataAPI} from "../../../api/api";

export function loadRightPanelData(type, data, id = null, force = true) {
  function setError() {
    const dispatch = window.elz_dispatch;
    const panelVisible = window.store.getState()["rightPanelReducer"].rightPanelVisible;
    dispatch(setRightPanelAttr({
      ...initialState,
      rightPanelVisible: panelVisible,
      rightPanelDataLoading: false,
      rightPanelDataLoadingError: true
    }))
  }

  function setSelectedElement(name, type, id, data = {}) {
    const dispatch = window.elz_dispatch;
    const panelVisible = window.store.getState()["rightPanelReducer"].rightPanelVisible;
    dispatch(setRightPanelAttr({
      ...initialState,
      rightPanelVisible: panelVisible,
      rightPanelDataLoading: true,
      rightPanelDataLoadingError: false,
      node: data,
      selectedElement: {
        type: type,
        name: name,
        id: id,
      }
    }))
  }

  if (!force) {
    const element = window.store.getState().rightPanelReducer["selectedElement"]
    if (element) { //если на РП нет ничего, работает форсировано
      if (element.id === id && element.type === type)
        return;
    }
  }

  if (id) {
    const dispatch = window.elz_dispatch;
    switch (type) {
      case 'Polygon':
      case 'Marker':
      case 'Line':
        setSelectedElement(data.name, data.type, data.id, data)
        otherDataAPI.rightPanel.shapes.getShapeData(data.id).then(res => {
          dispatch(setRightPanelAttr(
            {shapeData: res.data, rightPanelDataLoading: false, rightPanelDataLoadingError: false})
          )
        }).catch(err => {
          console.error(err)
          setError()
        })
        break;
      case 'Plot':
        dispatch(setRightPanelAttr({
          ...initialState,
          rightPanelVisible: window.store.getState()["rightPanelReducer"].rightPanelVisible,
          rightPanelDataLoading: true,
          rightPanelDataLoadingError: false,
          plotLayer: data,
          selectedElement: {
            ...data.elz_properties,
            type: type,
          }
        }))
        otherDataAPI.rightPanel.plots.getPlotData(id).then(res => {
          dispatch(setRightPanelAttr(
            {plotData: res.data, rightPanelDataLoading: false, rightPanelDataLoadingError: false})
          )
        }).catch(err => {
          console.error(err)
          setError()
        })
        break;
      case 'Road':
        setSelectedElement(data.elz_properties.name, type, data.elz_properties.id)
        otherDataAPI.rightPanel.roads.getRoadData(id).then(res => {
          dispatch(setRightPanelAttr(
            {roadData: res.data, rightPanelDataLoading: false, rightPanelDataLoadingError: false})
          )
        }).catch(err => {
          console.error(err)
          setError()
        })
        break;
      case 'Pillar':
        setSelectedElement(data.elz_properties.name, type, data.elz_properties.id)
        otherDataAPI.rightPanel.pillars.getPillarData(id).then(res => {
          dispatch(setRightPanelAttr(
            {pillarData: res.data, rightPanelDataLoading: false, rightPanelDataLoadingError: false})
          )
        }).catch(err => {
          console.error(err)
          setError()
        })
        break;
      case 'Warehouse':
        setSelectedElement(data.properties.name, type, data.properties.id, data)
        otherDataAPI.rightPanel.warehouse.getById(id).then(res => {
          dispatch(setRightPanelAttr(
            {shapeData: res.data, rightPanelDataLoading: false, rightPanelDataLoadingError: false})
          )
        }).catch(err => {
          console.error(err)
          setError()
        })
        break;
      case 'Violation':
        setSelectedElement(data.name, type, data.id)
        otherDataAPI.rightPanel.violations.getById(id).then(res => {
          dispatch(setRightPanelAttr(
            {shapeData: res.data, rightPanelDataLoading: false, rightPanelDataLoadingError: false})
          )
        }).catch(err => {
          console.error(err)
          setError()
        })
        break;
    }
  }
}
