import React from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

const AboutMobile = ({mobileFunctions}) => {
  return (
    <Box sx={{px: 4}}>
      <Typography fontWeight={500}>С мобильным-приложением Delyana вы можете:</Typography>
      <ul>
        {mobileFunctions.map((item, i) => (
          <li style={{padding: '6px 0'}} key={i}>
            <Typography>{item.text}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default AboutMobile;