//Это замена для кривой ModalPage

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import {saveString} from "../Main/Map/Common/Strings_RU";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export function ModalDialog({
                              title,
                              onClose,
                              onSave,
                              saveButtonName = saveString,
                              saveButtonDisabled = false,
                              children
                            }) {

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick")
          return;
        onClose()
      }}>
      <DialogTitle style={{userSelect: 'none', padding: '10px'}}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Typography variant={'h6'} sx={{flex: 1}}>{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent style={{padding: '10px'}}>
        {children}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={'outlined'}
          size={'small'}
          disabled={saveButtonDisabled}
          onClick={onSave}
        >
          {saveButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
