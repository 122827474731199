
//export const mainContainer = {overflowY: 'auto', overflowX: 'hidden', height: '100%', maxHeight: '100%'}

export const blockContainerStyle = {
  backgroundColor: 'white',
  borderRadius: 3,
  boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.3)',
  padding: '8px',
  margin: '0px 5px 0px 5px',
}

export const topBlockStyle = {
  backgroundColor: 'white',
  borderRadius: 3,
  padding: '16px',
  margin: '0px 5px 0px 5px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  cursor: 'default',
  boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.3)',
}

export const bottomBlockStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {textDecoration: 'underline'},
  borderRadius: 3,
}

export const titleStyle = {
  fontSize: 18,
  fontWeight: 500,
  color: '#808080',
}

export const textStyle = {
  fontWeight: 500,
  color: '#808080',
}

export const buttonBlockTitleStyle = {
  fontSize: 18,
  fontWeight: 500,
  color: '#808080',
}

export const tableTextStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: '#808080',
}

export const unactiveTextStyle = {
  cursor: 'pointer',
  fontSize: 15,
  fontWeight: 700,
  position: 'relative',
  top: '1px',
  color: 'darkred',
  '&:hover': {
    textDecoration: 'underline', color: 'darkred'
  }
}