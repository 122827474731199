import L from 'leaflet';
import 'leaflet-draw';

export function calculateAreanAndDistance(latlngs) { //for pencil measuring
    const area = L["GeometryUtil"].geodesicArea(latlngs);
    let distance = 0;
    let prevPoint = null;
    latlngs.forEach(point => {
      if (prevPoint)
        distance += prevPoint.distanceTo(point)
      prevPoint = point;
    })
    return [distance, area]
}

/*export function updateMeas (layer) { //расчет площади и периметра и запись в соответсвующие поля (тык по деляне)
  if (layer.pm['_shape'] !== 'Marker') {
    let latlngs = layer.getLatLngs();
    let area;
    //if ( type === 1 ) {
      if ( layer.pm['_shape'] === 'Polygon' ) {
        latlngs = layer.getLatLngs()[0];
      }
    //}
    area = L["GeometryUtil"].geodesicArea(latlngs);
    let distance = 0;
    let prevPoint = null;
    latlngs.forEach( point => {
      if (prevPoint) 
        distance += prevPoint.distanceTo(point)
      prevPoint = point; 
    })
    layer.elz_area = area;
    layer.elz_distance = distance;
  } else {
    layer.elz_area = 0;
    layer.elz_distance = 0;
  }
}*/

export function getLayerLength(layer) { // расчет длины дороги
  const latlngs = layer.getLatLngs()
  let distance = 0;
  let prevPoint = null;
  latlngs.map( point => {
    if (prevPoint)
      distance += prevPoint.distanceTo(point)
    prevPoint = point;
  })
  return distance;
}

export function compareArrays(a, b) { //сравнивает два массива по содержимому независимо от порядка элементов
  let result = true;
  if (a.length !== b.length)
    return false;
  else
  if (!b)
    return false;
  else
    b.map(item => {
      if (a.indexOf(item) === -1)
        result = false;
    })
  return result;
}

export function isOdd(num) {
  return num % 2;
}

export function checkForFloat(value) { //проверка строки на похожесть на float
  if (!value)
    return NaN;
  try {
    const string = value.toString()
    const re = /^\d+(\.\d+)?$/
    const result = string.match(re)
    if (result)
      return parseFloat(string);
    else
      return NaN;
  } catch (e) {
    console.error(e)
    return NaN;
  }
}

export function isValidUrl(string) { // проверка строки на похожесть на url. только для http и https
  try {
    const url = new URL(string);
    return url.protocol === 'https:' || url.protocol === 'http:';
  } catch {
    return false;
  }
}

export function hideAllOverlayWindows() { //убирает все окна с оверлея (окно обычно одно)
  return {
    type: 'MAP_HIDE_ALL_OVERLAY_WINDOW',
  }
}

export function getDateFromLocalizedString(str, divider = '.') { //локальная строка даты в дату
  try {
    const splitted = str.split(divider)
    if (splitted.length >= 3)
      return new Date(+splitted[2], splitted[1] - 1, splitted[0])
    else
      return NaN;
  } catch {
    return NaN;
  }
}

export function convertSequenceToArray(string) { //Преобразует последовательность вида 1,2-6,8,15,77 в массив
  const result = [];
  const commaSections = string.split(',')
  commaSections.map(section => {
    const parsed = Number(section)
    if (isNaN(parsed)) {
      const arra = section.split('-')
      if (arra.length !== 2) return
      const first = Number(arra[0])
      const second = Number(arra[1])
      if (!first || !second) return; //0 или нецифры низзя
      if (first >= second) return;
      for (let item = first; item <= second; item++){
        result.push(item)
      }
    } else {
      if (parsed <= 0 ) return;
      result.push(parsed)
    }
  })
  result.sort((a, b) => a - b)
  return removeRepitedFromArray(result)
}

export function removeRepitedFromArray(array) { //удаляет повторения из массива
  return array.filter(function (item, pos) {
    return array.indexOf(item) === pos;
  })
}

/*export function returnOnlyArrayItems(array1, array2) { //left join для массивов
  const result = array1.filter(item => array2.indexOf(item) !== -1)
  return result.sort((a, b) => a - b)
}*/

export function parseParamsFromUrl(url, pane) { //возвращает объект с парамтрами геозапроса (для лефлета)
  const params = {}
  let upperCase = false;
  for (const [key, value] of url.searchParams) {
    const keyUppercase = key.toUpperCase()
    if (key === keyUppercase)
      upperCase = true;
    params[key.toLowerCase()] = value; //лифлет не воспринимает в верхнем регистре
  }
  params.uppercase = upperCase;
  params.pane = pane;
  Object.entries(params).map(([key, value]) => {
    if (!value)
      delete params[key];
  })
  return params;
}

export function elementIsInViewport(element) { //true если элемент на экране
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function parseFloatString(str) {
  const regexp = /^[-+]?\d+([.,]\d*)?$/
  if (!regexp.test(str))
    return NaN;
  const strW = str.replace(',','.')
  return parseFloat(strW)
}

export function compileShortName(first_name, last_name, patronymic) {
  let result = last_name;
  if (first_name) result += ' ' + first_name[0].toUpperCase()
  if (patronymic) result += ' ' + patronymic[0].toUpperCase()
  return result;
}
