import React, {useState} from 'react';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ru from "date-fns/locale/ru";
import {Box, Stack, TextField} from "@mui/material";
import {filterDateContainer} from "../../../../OperationalReportStyles";

const FilterDate = ({startDate, setStartDate, endDate, setEndDate}) => {

  return (
    <Box sx={filterDateContainer}>
      <Stack spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
          <DatePicker
            label="Начальная дата"
            size='small'
            value={startDate}
            onChange={(date) => setStartDate(date)}
            renderInput={(params) => <TextField size="small" {...params} style={{maxWidth: '200px'}}/>}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
          <DatePicker
            label="Конечная дата"
            size='small'
            value={endDate}
            onChange={(date) => setEndDate(date)}
            renderInput={(params) => <TextField size="small" {...params} style={{maxWidth: '200px'}}/>}
          />
        </LocalizationProvider>
      </Stack>
    </Box>
  );
};

export default FilterDate;