import React from 'react';
import Box from "@mui/material/Box";
import {Link, Typography} from "@mui/material";
import {urlGenerator} from "../../../utils/urlGenerator";
import {useNavigate} from "react-router";

const AboutWeb = ({webFunctions}) => {
  const navigate = useNavigate()
  return (
    <Box sx={{px: 4}}>
      <Typography fontWeight={500}>С веб-приложением Delyana вы можете:</Typography>
      <ul>
        {webFunctions.map((item, i) => (
          <li key={i} style={{padding: '6px 0'}}>
            <Typography>{item.text}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default AboutWeb;