import {
  deleteOutlinePoint, setImage,
} from "../../../../../redux/reducers/stockpilePhotoReducer";


export const keyDown = (e, outline_selected_index, outline_selected_point_index, dispatch ) => {
  switch (e.code) {
    case "Backspace":
      if (outline_selected_index !== null && outline_selected_point_index !== null) {
        dispatch(deleteOutlinePoint())
      }
      break
  }
}