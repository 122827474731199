import {
    SET_TARGET_WAREHOUSE,
    WAREHOUSES_RESET,
    WAREHOUSES_SET_DATA,
    WAREHOUSES_SET_GROUP,
    WAREHOUSES_SET_LOADING_STATE,
} from "./actionTypes";

export const setTargetWarehouse = (warehouse) => ({
    type: SET_TARGET_WAREHOUSE,
    warehouse,
});

// Обновляет данные в списке складов, после обновления из правой панели.
/*export const updateItemInList = (id, item) => ({
    type: UPDATE_ITEM_IN_LIST,
    data: {id: id, item: item}
})*/

/*export const clearWarehouses = () => ({type: CLEAR_WAREHOUSES})*/

/////////////////////////////////////////////////////////////////////////////

export function setWarehousesLoadingState(loading, loadingError = null) {
    return {
        type: WAREHOUSES_SET_LOADING_STATE,
        loading: loading,
        loadingError: loadingError,
    }
}

export function setWarehousesData(data) {
    return {
        type: WAREHOUSES_SET_DATA,
        data: data,
    }
}

export function setWarehousesGroup(group) {
    return {
        type: WAREHOUSES_SET_GROUP,
        group: group,
    }
}

export function resetWarehouses() {
    return {
        type: WAREHOUSES_RESET,
    }
}
