import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setForestUseReportAttr} from "../../../../../../redux/reducers/forestUseReportReducer";
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const NewPlotOperationButtons = () => {
  const dispatch = useDispatch()
  const drawMode = useSelector(state => state.forestUseReportReducer.drawMode)
  const newPlotPx = useSelector(state => state.forestUseReportReducer.newPlotPx)

  const saveButtonHandler = () => {
    dispatch(setForestUseReportAttr({newPlotPx: [], plotPx: newPlotPx, drawMode: 'view'}))
  }

  const undoButtonHandler = () => {
    dispatch(setForestUseReportAttr({newPlotPx: []}))
  }

  return (
    <>
      {drawMode === 'drawPlot' &&
        <Stack direction={'row'}>
          <IconButton
            color={'primary'}
            title={'Сохранить квартал'}
            disabled={newPlotPx.length < 3}
            onClick={saveButtonHandler}
          >
            <SaveIcon fontSize={'small'}/>
          </IconButton>
          <IconButton title={'Отменить'}
                      color={'primary'}
                      disabled={!newPlotPx.length}
                      onClick={undoButtonHandler}
          >
            <RestartAltIcon/>
          </IconButton>
        </Stack>
      }
    </>
  );
};

export default NewPlotOperationButtons;