

export const stockpileSvgConfig = {
  // figure svg config
  outline_fill: "#f44336", //300
  outline_stroke: "#f44336", //300
  outline_opacity: 0.3,
  editing_outline_opacity: 0.4,
  outline_point_fill: "#ffeb3b",
  outline_selected_point_fill: "#d50000", //A700
  outline_point_radius: 5,
  outline_viewed_point_radius: 7,

  // timbers svg config
  timber_fill: "#0d47a1",
  timber_viewed_fill: "#ffeb3b",
  timber_selected_fill: "#f57f17",
  timbers_opacity: 0.5,
  timber_editing_opacity: 0.7,

  //ruler svg config
  ruler_fill: '#ff9800',
  selected_ruler_fill: '#e65100',
  ruler_point_fill: "#ffeb3b",
  ruler_selected_point_fill: "#d50000",
  ruler_point_radius: 7,
  ruler_selected_point_radius: 9,
  ruler_stroke_width: 6,

  cut_point_fill: '#d50000',
  cut_point_radius: 6,
  custom_cursor: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' ' +
    'width=\'20\' height=\'20\'%3E%3Ccircle cx=\'10\' cy=\'10\' r=\'4\' fill=\'red\'/%3E%3C/svg%3E") 10 10, auto'
}
