import React, {useRef, useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import ImageSvg from "./ImageSvg/ImageSvg";
import {useSelector} from "react-redux";
import {Stack, Typography} from "@mui/material";
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';

const ImageContainer = ({iAmEditor}) => {
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const blockRef = useRef(null);
  const stockpileSvgContainerNodeId = 'stockpile-svg-container'
  const [containerSize, setContainerSize] = useState(document.getElementById(stockpileSvgContainerNodeId)?.getBoundingClientRect());

  /* используем ResizeObserver для отслеживания изменения размера блока при изменении масштаба страницы в браузере.
  Если размер изменяется, то меняем containerSize и передаем его новое значение в ImageSvg для пересчета коэф-та масштабирования scale*/
  useEffect(() => {
    function handleResize() {
      setContainerSize(document.getElementById(stockpileSvgContainerNodeId)?.getBoundingClientRect())
    }
    const resizeObserver = new ResizeObserver(handleResize);
    const currentBlockRef = blockRef.current;
    resizeObserver.observe(currentBlockRef);

    return () => {
      if (resizeObserver && currentBlockRef) {
        resizeObserver.unobserve(currentBlockRef);
      }
    };
  }, []);

  return (
    <Box id={stockpileSvgContainerNodeId} ref={blockRef}
         style={{width: '100%', height: '100%', overflow: 'hidden', textAlign: 'center', position: 'relative'}}
    >
      {selectedPhoto && containerSize
      ? <>
          <ImageSvg containerSize={containerSize} iAmEditor={iAmEditor}/>
        </>
      :
        <Stack direction={'row'} spacing={1}
               sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <HideImageOutlinedIcon/>
          <Typography>Фото штабеля не выбрано</Typography>
        </Stack>
      }
    </Box>
  );
};

export default ImageContainer;
