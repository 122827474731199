import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {InputAdornment, Link} from "@mui/material";
import Button from "@mui/material/Button";

const LoginByCode = ({smsConfirmationForm, phoneError}) => {
    return (
        <Box
            key={'smsForm'}
            component="form"
            onSubmit={smsConfirmationForm.handleSubmit}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Логин"
                name="login"
                autoComplete="email"
                autoFocus
                error={!!phoneError}
                helperText={phoneError}
                onChange={smsConfirmationForm.handleChange}
                onBlur={smsConfirmationForm.handleBlur}
                inputProps={{maxLength: 10}}
                InputProps={{
                    startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                }}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3}}
            >
                Получить код
            </Button>
        </Box>
    );
};

export default LoginByCode;
