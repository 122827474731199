
const defaultState = {
  enableAreaSelect: false,
  filters: {caForestry: [], caLocalForestry: []},
  searchResults: [],
  selectionAreaLayer: null,
  enableAreaSelection: false,
}

export function setCASearchResults(data) { //установить массив отобраных делян (при фильтрации и удалении)
  return {
    type: 'CP_SET_SEARCH_RESULTS',
    data: data,
  }
}

export function refreshSearchResults() { //обновление редусера
  return {
    type: 'MAP_SEARCH_RESULT_REFRESH',
  }
}

export function searchResultsReducer(state = defaultState, action) { // редусер списка делян
  switch (action.type) {
    case 'CP_SET_SEARCH_RESULTS':
      return {...state, searchResults: action.data}
    case 'MAP_SEARCH_RESULT_REFRESH':
      return {...state}
    default:
      return state
  }
}
