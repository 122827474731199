import React, {useEffect, useState} from 'react';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {textStyle} from "../../organizationPageStyles";
import {useDispatch, useSelector} from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {changeOrgDataThunk} from "../../../../redux/reducers/organizationInfoReducer";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const AccountTable = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userReducer.userInfo)
  const organization = useSelector(state => state.organizationInfoReducer.organizationData)
  const is_owner = userInfo?.is_owner
  const [editOrgName, setEditOrgName] = useState(false)
  const [orgName, setOrgName] = useState('')
  if (!organization) return ''

  useEffect(() => {
    organization && setOrgName(organization.name)
  }, [organization])

  const accountTableRows = [
    {name: 'Лицевой счет', value: `ЛС-${organization.id}`},
    {name: 'Наименование', value: organization.name},
    {name: 'ИНН', value: organization.inn},
  ]

  const changeOrgNameHandler = () => {
    dispatch(changeOrgDataThunk({name: orgName}, organization.id, setEditOrgName))
  }

  return (
    <TableContainer sx={{pb: 1}}>
      <Table size={"small"} sx={{width: 400}}>
        <TableBody>
          {accountTableRows.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={textStyle}>{row.name}</TableCell>
              {row.name !== 'Наименование' &&
                <TableCell sx={{color: 'black', fontWeight: 500, display: 'flex'}}>{row.value}</TableCell>
              }
              {row.name === 'Наименование' && !editOrgName &&
              <TableCell sx={{color: 'black', fontWeight: 500, display: 'flex'}}>{row.value}
                {is_owner &&
                  <IconButton disableRipple title={'Изменить название организации'} sx={{p: 0, ml: 1}}
                              onClick={() => setEditOrgName(true)}>
                    <EditIcon/>
                  </IconButton>
                }
              </TableCell>
              }
              {row.name === 'Наименование' && editOrgName  &&
                <Stack direction={'row'} alignItems={'center'}>
                  <TextField
                    fullWidth
                    size={'small'}
                    variant={'standard'}
                    autoFocus
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    inputProps={{maxLength: 50}}
                    inputProps={{
                      style: {
                        padding: 0
                      }
                    }}
                  />
                  <IconButton sx={{p: 0, ml: 1}} title={'Сохранить'} onClick={() => changeOrgNameHandler()}>
                    <DoneIcon sx={{color: 'primary.dark'}}/>
                  </IconButton>
                  <IconButton title={'Отменить'} sx={{p: 0, ml: 1}} onClick={() => setEditOrgName(false)}>
                    <CloseIcon sx={{color: '#A44646'}}/>
                  </IconButton>
                </Stack>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountTable;