import React, {useEffect, useState} from 'react';
import Joyride from 'react-joyride';
import {Button, Divider, IconButton, PaginationItem, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch} from "react-redux";
import {setIntroTourAttr} from "../../redux/reducers/IntroToursReducer";

export const tourTooltipStyle = {
  backgroundColor: "white",
  border: "0px solid lightgrey",
  borderRadius: 2,
  boxShadow: 6,
  width: 330,
  padding: '12px',
  fontFamily: 'roboto'
};

// модуль интерактивных туров по функционалу для новых пользователей на базе библиотеки react-joyride. Все данные для туров
// хранятся локально в introToursReducer. В перспективе их нужно вынести на бэкэнд
const IntroTour = ({tourData, tourName}) => {
  const dispatch = useDispatch()
  const {steps, status, } = tourData
  const changeTourStatus = (newStatus) => {dispatch(setIntroTourAttr({...tourData, status: newStatus}, tourName))}
  const [startTour, setStartTour] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  useEffect(() => {
    if (status) {setStartTour(true)} else {setStartTour(false)}
  }, [tourData])

  const joyrideCallback = (data) => {
  };

  const handlePaginationClick = (event, stepIndex) => {
    if (stepIndex - 1 >= 0 && stepIndex -1 < steps.length) {
      setActiveStepIndex(stepIndex - 1)}
  };

  const PaginationBlock = () => {
    return (
      <Pagination count={steps.length}
                  page={activeStepIndex + 1}
                  color={'primary'}
                  size={'small'}
                  hideNextButton
                  hidePrevButton
                  onChange={handlePaginationClick}
                  sx={{ whiteSpace: 'nowrap',
                    '& .MuiPaginationItem-root': {
                      fontSize: '0.8rem',
                      minWidth: '20px',
                      height: '20px',
                      lineHeight: '20px',
                      margin: '0 2px',
                    }}}>
        {steps.map((step, index) => (
          <PaginationItem key={step.target} disabled={activeStepIndex !== index}>
            {index + 1}
          </PaginationItem>
        ))}
      </Pagination>
    );
  };

  const CustomTooltipComponent = ({   continuous,
                                    index,
                                    step,
                                    backProps,
                                    closeProps,
                                    primaryProps,
                                    tooltipProps, }) => {
    return (
      <Stack spacing={1} {...tooltipProps} sx={tourTooltipStyle}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography fontWeight={'bold'}>{step.title}</Typography>
          <IconButton
            size={'small'}
            sx={{p:0}}
            onClick={() => changeTourStatus(false)}
          >
            <CloseIcon/>
          </IconButton>
        </Box>
        <Typography>{step.content}</Typography>
        {step?.image &&
          <img src={step.image} alt={''} style={{maxHeight: 300}}/>
        }
        <Divider orientation={"horizontal"}/>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          {<PaginationBlock/>}
        </Box>
        <Stack direction={'row'} sx={{display: 'flex', justifyContent: 'center'}}>
          <Button size={'small'} sx={{color: 'grey'}}
                  onClick={(event) => {handlePaginationClick(event, activeStepIndex)}}
          >Назад</Button>
          <Button size={'small'}
                  onClick={(event) => {handlePaginationClick(event, activeStepIndex + 2)}}
          >Далее</Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <Joyride
        id={'react-joyride'}
        steps={steps}
        run={startTour}
        tooltipComponent={CustomTooltipComponent}
        disableBeacon={true}
        disableOverlayClose={true}
        showProgress
        callback={joyrideCallback}
        continuous={true}
        stepIndex={activeStepIndex}
        spotlightPadding={2}
        styles={{
          options: {
            primaryColor: 'darkblue',
            overlayColor: 'rgba(0, 0, 0, 0)',
            zIndex: 9999
          },
          overlay: {
            height: '100%',
            width: '100%',
          },
          spotlight: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            boxShadow: '0px 0px 10px 10000px rgba(0, 0, 0, 0.7)',
          }
        }}
      />
    </>
      );
      };
      export default IntroTour;