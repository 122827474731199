import {
  SET_TARGET_WAREHOUSE,
  WAREHOUSES_SET_LOADING_STATE,
  WAREHOUSES_SET_DATA, WAREHOUSES_SET_GROUP, WAREHOUSES_RESET,
} from "./actionTypes";

/**
 * Редусер складов
 * @param {object} warehouses - список складов.
 * @param {object} targetWarehouses - отдельный склад, на который нажал пользователь.
 */
const defaultState = {
  warehouses: [],
  layersGroup: null,
  targetWarehouse: null,
  loading: false,
  loadingError: null,
}

export const warehousesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_TARGET_WAREHOUSE:
      return {...state, targetWarehouse: action.warehouse}
    case WAREHOUSES_SET_LOADING_STATE:
      return {...state, loading: action.loading, loadingError: action.loadingError}
    case WAREHOUSES_SET_DATA:
      return {...state, warehouses: action.data}
    case WAREHOUSES_SET_GROUP:
      return {...state, layersGroup: action.group}
    case WAREHOUSES_RESET:
      return {...defaultState}
    default:
      return state;
  }
}
