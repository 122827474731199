import {overlayButtonStyle} from "../Map/Common/Styles";
import {IconButton} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Stack from "@mui/material/Stack";
import {getMap1} from "../Map/GlobalObjects";
import {decreaseString, increaseString} from "../Map/Common/Strings_RU";

export function ZoomControl() {

  function zoomClick(up) {
    const map = getMap1()
    const zoom = map.getZoom()
    if (up) {
      if (zoom < 20)
        map.setZoom(zoom + 1)
    } else {
      if (zoom > 0)
        map.setZoom(zoom - 1)
    }
  }

  return (
    <Stack flexDirection={'column'} style={overlayButtonStyle}  id={'zoomControl'}>
      <IconButton disableRipple onClick={() => zoomClick(true)} style={{padding: 5}} title={increaseString}>
        <AddIcon fontSize={'small'}/>
      </IconButton>
      <IconButton disableRipple onClick={() => zoomClick(false)} style={{padding: 5}} title={decreaseString}>
        <RemoveIcon fontSize={'small'}/>
      </IconButton>
    </Stack>
  )
}
