import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Link} from "@mui/material";
import {sendConfCodeThunk} from "../../../redux/reducers/loginReducer";
import {useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";

const SendCheckCodeComponent = ({smsConfirmationForm, setSeconds, seconds, phone}) => {
  const dispatch = useDispatch()

  return (
    <Box
      key={'codeForm'}
      component="form"
      onSubmit={smsConfirmationForm.handleSubmit}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="code"
        helperText={'На ваш номер было отправлено sms-сообщение с кодом для подтверждения ' +
          'регистрации'}
        label="Код подтверждения"
        name="code"
        type="text"
        autoFocus
        inputProps={{maxLength: 10}}
        onChange={smsConfirmationForm.handleChange}
        onBlur={smsConfirmationForm.handleBlur}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{mt: 3}}
      >
        Отправить код
      </Button>
      <Link sx={{display: 'flex', justifyContent: 'end', mt: 3, cursor: !seconds && 'pointer', color: seconds && 'grey'}}
            onClick={() => {
              if (!seconds) {
                setSeconds(60)
                dispatch(sendConfCodeThunk(phone))
              }
            }}
      >
        <Typography>
        {!seconds ? `Нажмите для повторной отправки sms` : `Отправить повторно через ${seconds} сек.`}
        </Typography>
      </Link>


    </Box>
  );
};

export default SendCheckCodeComponent;
