import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import {IconButton, Menu, MenuItem, useTheme} from "@mui/material";
import {treeItemButtonStyle} from "../../Styles";
import {saveCooordinatesAs, saveGeoJSONString, saveGPXTrackString} from "../../Strings_RU";
import {useState} from "react";

export function DownloadCoordinatesButton({callback}) {
  const theme = useTheme()
  const [menuAnchor, setMenuAnchor] = useState(null)

  function save(e) {
    setMenuAnchor(null)
    callback(e.target.value)
  }

  return (
    <>
      <IconButton
        style={{...treeItemButtonStyle}}
        title={saveCooordinatesAs}
        onClick={(e) => setMenuAnchor(e.target)}
      >
        <DownloadOutlinedIcon fontSize={'small'} style={{color: theme.palette.primary.main}}/>
      </IconButton>
      <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
        <MenuItem value={0} onClick={save}>{saveGPXTrackString}</MenuItem>
        <MenuItem value={1} onClick={save}>{saveGeoJSONString}</MenuItem>
      </Menu>
    </>
  )
}
