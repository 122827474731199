import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Checkbox, Table, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import './TableRowStyles.css';
import {changeOrgPageAttrs} from "../../../../../../organizationPageReducer";

const FirstTabAccessLevel = ({user}) => {
  const dispatch = useDispatch()
  const workspaces = useSelector(state => state.organizationPageReducer.workspacesData)
  const tableData = useSelector(state => state.organizationPageReducer.allWorkspaces)
  const [allChecked, setAllChecked] = useState(workspaces.every(wp => wp.users.find(u => u.id === user.id)))

  useEffect(() => {
    const arr = workspaces.map(wp => {
      return {id: wp.id, name: wp.number, checked: wp.users.findIndex(u => u.id === user.id) !== -1}
    })
    dispatch(changeOrgPageAttrs({allWorkspaces: arr}))
  }, [workspaces])

  if (!tableData)
    return null;

  const handleHeaderChange = () => {
    const arr = tableData.map(wp => {
      return {...wp, checked: !allChecked}
    })
    setAllChecked(!allChecked)
    dispatch(changeOrgPageAttrs({allWorkspaces: arr, changesInDialog: 0}))
  }

  const handleCheckboxChange = (rowIndex) => {
    const wp = tableData[rowIndex]
    wp.checked = !wp.checked;
    setAllChecked(tableData.every(row => row.checked))
    dispatch(changeOrgPageAttrs({allWorkspaces: [...tableData], changesInDialog: 0}))
  }

  return (
    <>
      {tableData?.length > 0 ?
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '50%'}}>
                Рабочая область
              </TableCell>
              <TableCell sx={{width: '50%'}}>
                <label>
                  <Checkbox checked={allChecked} onChange={handleHeaderChange}/>
                  Доступ
                </label>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="hoverTableRow">
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={row.checked}
                    onChange={() => handleCheckboxChange(rowIndex)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        :
        <Alert sx={{mt: 2}} severity={'info'}>Договоры аренды отсутствуют</Alert>
      }
    </>
  )
}

export default FirstTabAccessLevel;
