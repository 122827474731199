import React from 'react';
import { Stack, Typography } from '@mui/material';
import {ToolbarBlockContainerStyle, toolbarTextStyle} from "../toolbarStyles";

const ToolbarBlockContainer = ({ title, children }) => {

  return (
    <Stack sx={ToolbarBlockContainerStyle}>
      <Typography sx={toolbarTextStyle}>{title}</Typography>
      <Stack spacing={1} direction="row" justifyContent={'center'} alignItems={'center'}>
        {children}
      </Stack>
    </Stack>
  )
}

export default ToolbarBlockContainer;
