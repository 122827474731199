import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {djangoErrorsParse} from "../../utils/djangoErrorsParse";
import {networkErrorString} from "../../components/Main/Map/Common/Strings_RU";

export const handleErrors = (dispatch, error) => {
  console.error(error)
  if (error?.response?.headers['content-type'] === 'text/html')
    dispatch(setSnack('error', networkErrorString))
  else
    dispatch(setSnack('error', error.response?.data ? djangoErrorsParse(error.response.data)
      : networkErrorString));
}

export function handleBlobError(error) {
  console.error(error)
  const dispatch = window.elz_dispatch;
  if (error?.response?.headers['content-type'] === 'text/html')
    dispatch(setSnack('error', networkErrorString))
  else {
    error.response.data.text().then(res => {
      try{
        const obj = JSON.parse(res)
        dispatch(setSnack('error', obj.error ? djangoErrorsParse(obj) : networkErrorString))
      } catch {}
    })
  }
}
