import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import {useSelector} from "react-redux";

export const yesNoWarningButtons = {
	'Да': {color: 'warning'},
	'Нет': {color: 'primary'},
}

export function MapDialog() {
	const state = useSelector(state => state.dialogReducer)
	const theme = useTheme()

	if (!state.open)
		return null;

	function getColor(color) {
		switch (color) {
			case 'error':
				return theme.palette.error.main;
			case 'warning':
				return theme.palette.warning.main;
			case 'info':
				return theme.palette.info.main;
			case 'success':
				return theme.palette.success.main;
			default:
				return theme.palette.primary.main;
		}
	}

	if (!state.message)
		return null;

	return (
		<Dialog open={state.open}>
			<DialogTitle style={{backgroundColor: getColor(state.type), color: '#EEEEEE', padding: '0.5vh 0.5vh 0.5vh 1vh'}}>
				{state.header}
			</DialogTitle>
			<DialogContent style={{padding: '1vh', borderBottom: 'solid 1px #333333'}}>
				<DialogContentText>
					{state.message}
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{padding: '0.5vh 1vh 0.5vh 0.5vh'}}>
				{Object.entries(state.buttons).map(([key, value]) => {
					let coloo = value.color;
					if (!coloo)
						coloo = theme.palette.primary.main;
					else
						if (coloo[0] !== '#')
							coloo = getColor(coloo)
					return <Button variant={"outlined"} style={{borderColor: coloo, color: coloo}} onClick={() => state.callback(key)} key={key}>{key}</Button>
				})
				}
			</DialogActions>
		</Dialog>
	)
}
