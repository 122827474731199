import React from 'react';
import {Slider} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {useDispatch, useSelector} from "react-redux";
import ToolbarBlockContainer from "../../ToolbarBlockContainer/ToolbarBlockContainer";
import {toolbarIconButtonStyle, toolbarIconStyle, toolbarZoomSliderStyle} from "../../toolbarStyles";

const ZoomSlider = () => {
  const dispatch = useDispatch()
  const zoom = useSelector(state => state.stockpilePhotoReducer.zoom)
  const changeZoomHander = (e) => {
    dispatch(setPhotoAttr({zoom: parseInt(e.target.value)}))
  }

  return (
    <ToolbarBlockContainer title={'Масштаб'}>
      <SearchIcon sx={{...toolbarIconStyle, color: 'primary.dark'}}/>
      <Slider
        key={'zoomSlider'}
        value={zoom}
        max={300}
        min={50}
        sx={toolbarZoomSliderStyle}
        size="small"
        aria-label="Small"
        onChange={changeZoomHander}
      />
    </ToolbarBlockContainer>

  );
};

export default ZoomSlider;