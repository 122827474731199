import {useEffect, useRef} from 'react';

/*
При первом вызове useInfiniteScroll мы только создаем useRef и возвращаем его, чтобы присвоить scrollRef нужному
DOM-элементу, useEffect не отрабатывает. Далее при изменении nextPath мы уже имеем ref, и вешаем слушатель
addEventListener на элемент и вызываем функцию handleScroll. В handleScroll, при достижении конца скролла через debouncer
вызывается коллбэк (на подгрузку данных в таблицу). Таким образом хук выполняет переданный коллбэк с необходимой задержкой
при достижении конца скролла привязанного рефа.
* */
export const useInfiniteScroll = (callback, nextPath) => {
  const scrollRef = useRef(null);
  let debounceTimer;

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        const element = scrollRef.current;
        if (element) {
          const {scrollTop, scrollHeight, clientHeight} = element;
          const delta = 2;
          if (scrollTop + clientHeight + delta >= scrollHeight) {
            if (nextPath) {
              callback();
            }
          }
        }
      }, 200);
    };

    const element = scrollRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
  }, [nextPath]);

  return scrollRef;
};