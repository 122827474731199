import PlanetablesTreeItem from "./PlanetablesTreeItem/PlanetablesTreeItem";
import {cannotIDo} from "../../../Main/Map/Common/tariffs";

export function PlanetablesTree({itemsList, fields, fieldsListLength}) {
  const [field, ...restFields] = fields;
  const readOnly = cannotIDo.writePlanetabletsModule()

  itemsList = itemsList.map(item => ({...item, rental_contract:
      {...item.rental_contract, name: item.rental_contract.number}}))

  // Получаем уникальные значения для текущего поля
  const uniqueValues = [...new Set(itemsList.map((items) => items[field].name))];
  const step = fieldsListLength - restFields.length
  return (
      <PlanetablesTreeItem itemsList={itemsList} uniqueValues={uniqueValues} field={field} restFields={restFields}
                           step={step} readOnly={readOnly}/>
  )
}
