import {treeItemButtonIconSize, treeItemButtonStyle} from "../../../../Map/Common/Styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {useDispatch} from "react-redux";
import {changeString, deleteString, renameString} from "../../../../Map/Common/Strings_RU";
import {deletePillarFromWorkspaceWithDialog} from "./pillars.js";
import EditNoteIcon from '@mui/icons-material/EditNote';
import {changePillarsDialogAttr, refreshPillarsTree} from "./pillarsReducer";

export function PillarContextMenu({layer}) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const menuOpen = Boolean(menuAnchor)
  const dispatch = useDispatch()

  function startRenamePillar(layer) {
    layer.elz_rename = true;
    dispatch(refreshPillarsTree())
  }

  return (
    <>
      <IconButton
        style={{...treeItemButtonStyle, padding: 0}}
        onClick={(e) => {
          e.stopPropagation()
          setMenuAnchor(e.currentTarget)
        }}
      >
        <MoreVertIcon fontSize={'small'} style={treeItemButtonIconSize}/>
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={(e) => {
          e.stopPropagation()
          setMenuAnchor(null)
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            startRenamePillar(layer)
          }}
        >
          <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{renameString}</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            dispatch(changePillarsDialogAttr({
              name: layer.elz_properties.name,
              coordinates: layer.getLatLng(),
              quarters: layer.elz_properties.x_quarters,
              workspace: layer.elz_parent_node,
              shape: layer,
              editMode: true,
            }, true))
          }}
        >
          <EditNoteIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{changeString}</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setMenuAnchor(null)
            deletePillarFromWorkspaceWithDialog(layer, dispatch)
          }}
        >
          <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>{deleteString}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
