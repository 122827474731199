import {treeItemButtonStyle} from "../../Styles";
import {projectsLoadGPXString, warningString} from "../../Strings_RU";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import {IconButton, useTheme} from "@mui/material";
import React from "react";
import {hideMapDialog, showMapDialog} from "../DialogReducer";
import {yesNoWarningButtons} from "../MapDialog";
import {useDispatch} from "react-redux";

export function LoadFileButton({callback, confirmDialog, confirmString}) {
  const fileRef = React.createRef()
  const dispatch = useDispatch()
  const theme = useTheme()
  /*const callback = props.callback;
  const style = props.style || {}
  const confirmDialog = props.confirmDialog;
  const confirmString = props.confirmStaring;*/

  function loadFileHandler() {
    if (confirmDialog) {
      dispatch(showMapDialog('warning', warningString, confirmString, yesNoWarningButtons, (value) => {
        dispatch(hideMapDialog())
        if (value === 'Да')
          fileRef.current.click()
      }))
    } else {
      fileRef.current.click()
    }
  }

  function handleInputChange(event) {
    const fileUploaded = event.target.files[0]
    const data = new FormData();
    data.append('gpx', fileUploaded)
    callback(data)
  }

  return (
    <>
      <input hidden accept="*/*" type="file" ref={fileRef} onChange={handleInputChange} />
      <IconButton
        style={{...treeItemButtonStyle}}
        title={projectsLoadGPXString}
        onClick={loadFileHandler}
      >
        <UploadOutlinedIcon style={{color: theme.palette.primary.main}} fontSize={'small'}/>
      </IconButton>
    </>
  )
}
