import {getMap1} from "../GlobalObjects";

const redrawLayersArray = []

export function redrawLayersIfNeeded() {
  let ind = 0;
  const map = getMap1()
  while (ind < redrawLayersArray.length) {
    const l = redrawLayersArray[ind]
    if (l instanceof L.LayerGroup){
      l.eachLayer(ll => {
        ll.redraw()
      })
    } else
      l.redraw()
    map.removeLayer(l)
    map.addLayer(l)
    redrawLayersArray.splice(ind, 1)
  }
}

export function addRedrawLayer(layer) {
  redrawLayersArray.push(layer)
}
