import React from 'react';
import {TableCell, TableRow} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

const CreateNewRow = ({setIsNewRow, tableColumns}) => {

  const createNewRow = () => {
    setIsNewRow(() => true)
  }
  return (
    <TableRow>
      {tableColumns.map(column => (
        <TableCell key={column.name}>{''}</TableCell>
      ))}
      <TableCell>
        <IconButton onClick={createNewRow} title={'Создать строку'}>
          <AddIcon sx={{color: 'primary.main'}}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CreateNewRow;