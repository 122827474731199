import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {overlayButtonStyle} from "../Map/Common/Styles";
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import {getMap1} from "../Map/GlobalObjects";
import {myLocationString} from "../Map/Common/Strings_RU";

export function GPSButton() {

  function click() {
    const map = getMap1()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
        const latit = position.coords.latitude;
        const longit = position.coords.longitude;
        map.setView([latit, longit], map.getZoom())
        },
      (error) => {
          console.error(error)
        },
        {timeout: 5000}
        )
    }
  }

  return (
    <Stack flexDirection={'column'} style={overlayButtonStyle} id={'GPSButton'}>
      <IconButton disableRipple onClick={() => click(true)} style={{padding: 5}} title={myLocationString}>
        <GpsFixedIcon fontSize={'small'}/>
      </IconButton>
    </Stack>
  )
}
