import {dragToScrollImage} from "../../../../../svgComponentsHelpersFunctions/dragToScrollImage";
import {changeOutlinePoint, changeRulerPoint} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {getSvgCoord} from "../../../../../svgComponentsHelpersFunctions/getSvgCoord";

export const dragHandler = (e, dragMode, dragStartingPoint, stockpileSvgContainerNodeId, dispatch,
                            zoom, scale, svgNodeId) => {
  switch (dragMode) {
    case 'image':
      dragToScrollImage(e, dragStartingPoint, stockpileSvgContainerNodeId)
      break
    case 'outlinePoint':
      dispatch(changeOutlinePoint(getSvgCoord(e, zoom, scale, svgNodeId)))
      break
    case 'rulerPoint':
      dispatch(changeRulerPoint(getSvgCoord(e, zoom, scale, svgNodeId)))
  }
}