import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ModalPage from "../../Common/ModalPage";
import Box from "@mui/material/Box";
import {
  patchPlanetableThunk,
  setPlanetablesAttr,
  uploadPlanetableThunk
} from "../../../redux/reducers/planetablesReducer";
import {modalWindowStyle} from "../../Common/Styles";
import UploadPlanetable from "./UploadPlanetable";
import EditPlanetable from "./EditPlanetable";
import ViewPlanetable from "./ViewPlanetable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {IconButton} from "@mui/material";
import {getOrganizationWorkspacesThunk} from "../../../redux/reducers/organizationInfoReducer";
import {checkFileType} from "../../../utils/checkFileType";
import {setSnack} from "../../Main/Map/Common/Dialog/Snack/snackReducer";

const UploadPlanetableModal = ({iAmEditor}) => {
  const dispatch = useDispatch()
  const planetablesReducerState = useSelector(state => state.planetablesReducer)
  const {planetableIsUploading, showUploadPlanetableModal, cacheRegion, cacheForestry, cacheDistrictForestry}
    = planetablesReducerState;
  const {planetable: currentPlanetable, viewMode} = showUploadPlanetableModal;
  const [runTour, setRunTour] = useState(false) //Странность от Юры. Я так понимаю, недоделано
  const [planetableData,
    setPlanetableData] = useState(
    {
      region: cacheRegion || null,
      forestry: cacheForestry || null,
      district_forestry: cacheDistrictForestry || null,
      rental_contract: null,
      image: null,
      scale: 25000,
      name: '',
      magnetic_declination: null,
      id: null
    }
  )
  const {rental_contract, district_forestry, image, scale, name,
    magnetic_declination, id} = planetableData

  useEffect(() => {
    currentPlanetable && setPlanetableData(
      {
        region: currentPlanetable.region,
        forestry: currentPlanetable.forestry,
        district_forestry: currentPlanetable.district_forestry,
        rental_contract: currentPlanetable.rental_contract,
        image: currentPlanetable.name,
        scale: currentPlanetable.scale,
        name: currentPlanetable.name,
        magnetic_declination: currentPlanetable.magnetic_declination,
        id: currentPlanetable.id
      }
    )

  }, [])

  useEffect(() => {dispatch(getOrganizationWorkspacesThunk())},
    [])

  const actionButtonHandler = () => {
      if (!currentPlanetable) {
        if (checkFileType(image.name, 'png') || checkFileType(image.name, 'jpg')
          || checkFileType(image.name, 'jpeg')){
          dispatch(uploadPlanetableThunk({rental_contract, district_forestry, image, scale, name, magnetic_declination}))
          dispatch(setPlanetablesAttr({
            cacheRegion: planetableData.region,
            cacheForestry: planetableData.forestry,
            cacheDistrictForestry: planetableData.district_forestry
          }))
        } else {
          dispatch(setSnack('error', 'Неверный тип файла. Загрузите файл с расширением png, jpg или jpeg'))
        }
      } else {
        dispatch(patchPlanetableThunk({name, magnetic_declination, scale, id,
          district_forestry: planetableData?.district_forestry?.id
        }))
      }
  }

  const handleCloseModal = () => {
    dispatch(setPlanetablesAttr({showUploadPlanetableModal: {status: false, planetable: null, viewMode: false}}))
  }

  return (
    <ModalPage
      title={!currentPlanetable ? 'Загрузить планшет' : currentPlanetable.name}
      handleClose={handleCloseModal}
      actionButtonHandler={actionButtonHandler}
      actionButtonName={'Сохранить'}
      actionButtonDisabled={planetableIsUploading}
      actionButtonRemove={viewMode}
      customStyle={{...modalWindowStyle, minWidth: 600,}}
      setRunTour={setRunTour}
    >
    <Box sx={{mt: 2}} >
      {!currentPlanetable &&
      <UploadPlanetable currentPlanetable={currentPlanetable} planetableData={planetableData}
                        setPlanetableData={setPlanetableData}/>
      }
      {currentPlanetable && !viewMode &&
      <EditPlanetable currentPlanetable={currentPlanetable} planetableData={planetableData}
                        setPlanetableData={setPlanetableData}/>
      }
      {currentPlanetable && viewMode &&
        <>
          <ViewPlanetable currentPlanetable={currentPlanetable} planetableData={planetableData}
                          setPlanetableData={setPlanetableData}/>
          {iAmEditor &&
            <IconButton title={'Редактировать планшет'}
                      onClick={() => dispatch(setPlanetablesAttr(
                        {showUploadPlanetableModal: {...showUploadPlanetableModal, viewMode: false}}))}>
              <EditOutlinedIcon/>
            </IconButton>
          }
        </>
      }
    </Box>
    </ModalPage>
  )
}

export default UploadPlanetableModal;
