import React from 'react';

const NewPlotPoint = ({coordCorrection, pointPosition}) => {
  return (
    <circle
      cx={coordCorrection(pointPosition.x)}
      cy={coordCorrection(pointPosition.y)}
      r={6}
      fill={"#f44336"}
      opacity={1}
    />
  );
};

export default NewPlotPoint;