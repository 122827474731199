import React from 'react';
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import {disableModeAndUndoChanges, setPhotoAttr,} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {useDispatch, useSelector} from "react-redux";
import ToolbarBlockContainer from "../../ToolbarBlockContainer/ToolbarBlockContainer";
import {layerCheckboxesText, toolbarLayersBlockCheckboxStyle, toolbarLayersFormStyle} from "../../toolbarStyles";


const forms = [
  {checkboxType: 'outline', label: 'Область'},
  {checkboxType: 'timbers', label: 'Бревна'},
  {checkboxType: 'ruler', label: 'Ширина замера'},
]
const LayerCheckboxes = () => {
  const dispatch = useDispatch()
  const layers = useSelector(state => state.stockpilePhotoReducer.layers)
  const mode = useSelector(state => state.stockpilePhotoReducer.mode)
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const layerCheckboxHandler = (checkboxType) => {
    if (checkboxType === mode) {
      dispatch(disableModeAndUndoChanges(selectedPhoto))
    }
    dispatch(setPhotoAttr({layers: {...layers, [checkboxType]: !layers[checkboxType]}}))
  }

  return (
    <ToolbarBlockContainer title={'Слои'}>
      {forms.map(form => (
        <FormControlLabel
          key={form.checkboxType}
          sx={toolbarLayersFormStyle}
          control={<Checkbox
            disabled={mode === form.checkboxType}
            sx={toolbarLayersBlockCheckboxStyle}
            size={'small'}
            checked={layers[form.checkboxType]}
            onChange={() => layerCheckboxHandler(form.checkboxType)}
          />}
          label={<Typography sx={layerCheckboxesText}>{form.label}</Typography>}/>
      ))}
    </ToolbarBlockContainer>
  );
};

export default LayerCheckboxes;