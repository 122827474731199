import React from 'react';

import {blockContainerStyle} from "../organizationPageStyles";
import {Box, Collapse} from "@mui/material";
import EmployeesTable from "../Employees/EmployeesTable/EmployeesTable";
import Connection1C from "./Connection1C/Connection1C";
import {BottomBlockTitle} from "../BottomBlock/BottomBlockTitle";

const ExpansionModules = ({openedBlock, setOpenedBlock}) => {
  return (
    <Box sx={{...blockContainerStyle}} id={'expansionModules'}>
    <BottomBlockTitle openedBlock={openedBlock} setOpenedBlock={setOpenedBlock} blockName={'ExpansionModules'}
                      title={'МОДУЛИ РАСШИРЕНИЯ ФУНКЦИОНАЛА'}/>
      <Collapse in={openedBlock === 'ExpansionModules'}
                timeout="auto"
                unmountOnExit>
        <Connection1C/>
      </Collapse>
    </Box>
  );
};

export default ExpansionModules;