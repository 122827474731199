import {Button} from "@mui/material";
import {DeclarationLoadingDialog} from "./DeclarationLoadingDialog";
import {useDispatch, useSelector} from "react-redux";
import {emptyDeclarationState, setDeclarationXMLAttr} from "../cuttingAreasReducer";

export function DeclarationLoadingButton() {
  const state = useSelector(state => state.cuttingAreasReducer.declatationXML)
  const dispatch = useDispatch()

  function openDialogHandler() {
    dispatch(setDeclarationXMLAttr({dialogOpen: true}))
  }

  function closeDialogHandler() {
    dispatch(setDeclarationXMLAttr({...emptyDeclarationState}))
  }

  return (
    <>
      <Button
        variant={'outlined'}
        size={'small'}
        sx={{mt: '1vh', mb: '1vh', width: 'fit-content'}}
        onClick={openDialogHandler}
      >
        Загрузить декларацию (xml)
      </Button>
      {state.dialogOpen && <DeclarationLoadingDialog state={state} onClose={closeDialogHandler}/>}
    </>
  )
}
