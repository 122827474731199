import L from 'leaflet';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, IconButton, ListItem, ListItemButton, Stack, Typography, useTheme} from "@mui/material";
import {
  editString,
  miscUserLayerAddingString,
  miscUserLayersHeaderString,
  miscUserLayersLoadingErrorString,
  miscUserLayersReloadButtonString,
  userMiscLayerAddingForbidden,
  userMiscLayerAddingForbiddenWithLite,
  userMiscLayersCountLimitString,
  userMiscLayersViewForbidden,
} from "../../../../Map/Common/Strings_RU";
import {
  defaultPaddingStep,
  miscLayersHeaderStyle,
  projectsTreeListItemStyle,
  treeItemButtonIconSize,
  treeItemButtonStyle
} from "../../../../Map/Common/Styles";
import AddIcon from '@mui/icons-material/Add';
import {UserLayerDialog} from "./UserLayerDialog";
import {showAddUserLayerDialog} from "./userLayersReducer";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import React from "react";
import {getMap1} from "../../../../Map/GlobalObjects";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {Loading} from "../../../../Map/Common/Loading";
import {blindRefresh} from "../../../../Map/Blind/BlindReducer";
import {getUserSettings, setSettingWasChanged} from "../../../../Map/Common/userSettings";
import {dataAPI} from "../../../../../../api/api";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {cannotIDo} from "../../../../Map/Common/tariffs";
import {LockedIcon} from "../../../../Map/Common/LockedIcon";
import {UserLayerContextMenu} from "./UserLayerContextMenu";
import {setNetworkErrorSnack} from "../../../../Map/Common/Dialog/Snack/FSnack";
import {parseParamsFromUrl} from "../../../../Map/Common/MiscFunction";

const layersTemplate = {
  loading: false,
  isLoading: false,
  error: null,
  data: [],
}

const layerTemplate = {
  name: '',
  type: null, //WMS|XYZ
  url: null,
  layer: null,
  show: false,
  blind_layer: null,
  blind_show: false,
  error: null,
  params: {}
}

let layers = structuredClone(layersTemplate);

export const userMiscPanel = 'user_misc';
export const userBlindMiscPanel = 'blind_user_misc';

export function getUserMiscLayers() { //Возвращает объект всяких слоев
  return layers;
}

export function addUserMiscLayer(id, type, name, url, params) { //create layer node, not add it in layers. It's NOT leaflet layer
  const node = structuredClone(layerTemplate)
  node.type = type;
  node.name = name;
  node.params = structuredClone(params)
  if (!node.params)
    node.params = {}
  node.url = url;
  if (id)
    node.id = id;
  layers.data.push(node)
  return node;
}

export function createUserMiscLayer(node, pane = userMiscPanel, addTo = true) { //creating leaflet layer and adding it to map and node. It's NOT layer node
  let lay = null;
  switch (node.type) {
    case 'WMS':
      const url = new URL(node.url)
      let href = url.protocol + '//' + url.host + url.pathname;
      const params = parseParamsFromUrl(url, pane)
      lay = L.tileLayer.wms(href, params)
      break;
    case 'XYZ':
      lay = L.tileLayer(node.url, {pane: pane})
      break;
  }
  if (lay && addTo) {
    if (pane === userMiscPanel) {
      node.layer = lay;
      node.show = true;
    } else {
      node.blind_layer = lay;
      node.blind_show = true;
    }
    //getMap1().addLayer(lay)
    let zmi = node.params.zoomMin || 0;
    let zma = node.params.zoomMax || 20;
    getMap1().elz_zoom.addLayer(lay, zmi, zma)
  }
  return lay;
}

export function removeUserLayerFromMap(node) { //remove main and blind layers
  const map = getMap1()
  let ind = layers.data.findIndex(item => item === node)
  if (ind !== -1) {
    if (node.layer)
      map.elz_zoom.removeLayer(node.layer)
    if (node.blind_layer)
      map.elz_zoom.removeLayer(node.blind_layer)
  }
  return ind;
}

export function resetAllUserLayers() { //удаляет все всякие слои с карты
  const map = getMap1()
  layers.data.map(node => {
    if (node.layer)
      map.removeLayer(node.layer)
    if (node.blind_layer)
      map.removeLayer(node.blind_layer)
  })
  layers = structuredClone(layersTemplate);
}

export function resetUserMiscBlindLayers() { // удалят все всякие слои шторки с карты
  const map = getMap1()
  layers.data.map(node => {
    if (node.blind_layer)
      map.removeLayer(node.blind_layer)
    node.blind_layer = null;
  })
}

export function recreateUserLayer(node) { // пересоздание всякого слоя (для обновления)
  removeUserLayerFromMap(node)
  if (node.layer)
    createUserMiscLayer(node, userMiscPanel)
  if (node.blind_layer)
    createUserMiscLayer(node, userBlindMiscPanel)
}

export function searchUserLayerIndexByName(name) { // поиск слоя по имени
  const data = layers.data;
  if (!data.length)
    return -1;
  return data.findIndex(item => item.name === name)
}

export function createUserLayerOnServer(node, dispatch) { //Creating user layer on server. NOT layer node
  const json = {type: node.type, name: node.name, url: node.url, params: node.params}
  dataAPI.userLayers.create(json).then(res => {
    node.id = res.data.id;
    node.error = null;
    dispatch(blindRefresh())
  }).catch(err => {
    console.error(err)
    node.error = err.message;
    setNetworkErrorSnack(err.response, dispatch)
    dispatch(blindRefresh())
  })
}

export function patchUserLayerOnServer(node, dispatch) { // изменение слоя на бекенде
  const json = {type: node.type, name: node.name, url: node.url, params: node.params}
  dataAPI.userLayers.patch(node.id, json).then((res) => {
    node.error = null;
    if (node.layer)
      recreateUserLayer(node)
    dispatch(blindRefresh())
    return res;
  }).catch(err => {
    console.error(err)
    node.error = err.message;
    setNetworkErrorSnack(err.response, dispatch)
    dispatch(blindRefresh())
    return err;
  })
}

export function initUserMiscLayers(dispatch) { // загрузка слоев
  if (!layers.isLoading && !layers.loading) {
    layers.loading = true;
    dataAPI.userLayers.getAll().then(res => {
      layers.loading = false;
      layers.isLoading = true;
      const settings = getUserSettings().userMiscLayers;
      res.data.map(fea => {
        if (fea.type && fea.name && fea.url) {
          const node = addUserMiscLayer(fea.id, fea.type, fea.name, fea.url, fea.params)
          const ind = settings.findIndex(item => item.id === fea.id)
          if (ind !== -1) {
            if (settings[ind]?.show)
              createUserMiscLayer(node)
            if (getUserSettings().blind.enabled && settings[ind]?.show_blind)
              createUserMiscLayer(node, userBlindMiscPanel)
          }
        }
      })
      dispatch(blindRefresh())
    }).catch(err => {
      console.error(err)
      layers.error = err.message;
      layers.loading = false;
      layers.isLoading = true;
      dispatch(blindRefresh())
    })
    dispatch(blindRefresh())
  }
}

export function UserLayers() { // компонента узерских слоев
  const dispatch = useDispatch()
  const blind_state = useSelector(state => state.blindReducer)
  const state = useSelector(state => state.userLayersReducer)
  const theme = useTheme()
  const readOnly = !!cannotIDo.editorAction()

  function showHideLayer(node) {
    if (cannotIDo.useUserLayers() === 0) {
      const layer = blind_state.left ? node.layer : node.blind_layer;
      if (!layer) {
        createUserMiscLayer(node, blind_state.left ? userMiscPanel : userBlindMiscPanel)
        node.show = blind_state.left;
      } else {
        getMap1().elz_zoom.removeLayer(layer)
        if (blind_state.left) {
          node.layer = null;
          node.show = false;
        } else {
          node.blind_layer = null;
          node.blind_show = false;
        }
      }
      setSettingWasChanged()
      dispatch(blindRefresh())
    } else
      dispatch(setSnack('warning', userMiscLayersViewForbidden))
  }

  function addButtonClick() {
    if (layers.data.length >= 5)
      dispatch(setSnack('warning', userMiscLayersCountLimitString))
    else
      switch (cannotIDo.addUserMiscLayer()) {
        case 0:
          dispatch(showAddUserLayerDialog())
          break;
        case 1:
          dispatch(setSnack('warning', userMiscLayerAddingForbiddenWithLite))
          break;
        default:
          dispatch(setSnack('warning', userMiscLayerAddingForbidden))
          break;
      }
  }

  let ind = 0;

  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'} style={{marginTop: '1vh'}}>
        <Typography style={miscLayersHeaderStyle}>{miscUserLayersHeaderString}</Typography>
        <IconButton
          title={miscUserLayerAddingString}
          style={treeItemButtonStyle}
          onClick={addButtonClick}
        >
          <LockedIcon locked={false} style={{height: '24px'}} lockIconRight={-13}>
            <AddIcon style={treeItemButtonIconSize}/>
          </LockedIcon>
        </IconButton>
      </Stack>
      {layers.loading && <Loading/>}
      {layers.error
        ?
        <Box sx={{mb: 1}}>
          <Alert severity={'error'} icon={false}>
            {miscUserLayersLoadingErrorString}
            <Button
              sx={{mt: 1}}
              size={'small'}
              fullWidth
              variant={'outlined'}
              onClick={() => {
                resetAllUserLayers()
                initUserMiscLayers(dispatch)
              }}
            >
              {miscUserLayersReloadButtonString}
            </Button>
          </Alert>
        </Box>
        :
        layers.data.map(node => {
          const layer = blind_state.left ? node.layer : node.blind_layer;
          return (
            <ListItem
              disableGutters
              disablePadding
              key={ind++}
            >
              <ListItemButton
                onClick={() => showHideLayer(node)}
                disableRipple
                disableGutters
                style={{
                  ...projectsTreeListItemStyle,
                  paddingLeft: defaultPaddingStep / 2,
                }}
              >
                <LockedIcon
                  lockIconBottom={4}
                  lockIconRight={2}
                  style={{color: layers.show ? theme.palette.primary.main : theme.palette.text.disabled, height: 20}}
                >
                  {!layer ? <VisibilityOffOutlinedIcon fontSize={'small'}/> :
                    <VisibilityOutlinedIcon color={'primary'} fontSize={'small'}/>}
                </LockedIcon>
                <Typography style={{
                  flex: 1,
                  color: node.error ? theme.palette.error.main : theme.palette.text.primary,
                  marginLeft: '8px',
                }}>{node.name}</Typography>
                {node.error &&
                  <IconButton
                    title={editString}
                    style={treeItemButtonStyle}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (!node.id)
                        createUserLayerOnServer(node, dispatch)
                      else
                        patchUserLayerOnServer(node, dispatch)
                    }}
                  >
                    <SaveOutlinedIcon style={treeItemButtonIconSize}/>
                  </IconButton>
                }
                {!readOnly && <UserLayerContextMenu node={node} disabled={cannotIDo.useUserLayers()}/>}
              </ListItemButton>
            </ListItem>
          )
        })
      }
      {state.show && <UserLayerDialog onClose={() => dispatch(blindRefresh())} blind={blind_state.left} state={state}/>}
    </>
  )
}
