import React from 'react';
import {IconButton, TableCell, TableRow} from "@mui/material";
import {getValueFromRowByColumn} from "../../../../ORhelperFunctions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const TotalRow = ({columns, allVolume}) => {
  return (
    <TableRow sx={{backgroundColor: '#e0e0e0'}}>
      {columns.map((column, columnIndex) => (
        <TableCell key={`${column.name}-${columnIndex}`} sx={{fontWeight: 'bold'}}>
          {column.name === 'date' && 'Итого'}
          {column.name === 'volume' && allVolume}
          {column.name !== 'date' && column.name !== 'volume' && ''}
        </TableCell>
      ))}
      <TableCell>{''}</TableCell>
    </TableRow>
  );
};

export default TotalRow;