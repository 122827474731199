const defaultState = {
  curTab: 'violations',
}

export function setInfrastructureCurTab(tabNUmber) {
  return {
    type: 'SET_CUR_TAB',
    payload: tabNUmber,
  }
}

export function infractructureReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CUR_TAB':
      return {...state, curTab: action.payload}
    default:
      return state;
  }
}
