import React from 'react';
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import {useDispatch, useSelector} from "react-redux";
import {setForestUseReportAttr} from "../../../../../../redux/reducers/forestUseReportReducer";
import {
  findPolygonCenter, polygonShift,
  rotate
} from "../../../../../../svgComponentsHelpersFunctions/svgComponentsHelpersFunctions";
import {keydown} from "../../../svgFunctions/svgFunctions";
import WASDButtonsBlock from "../../../../../Common/WASDButtonsBlock/WASDButtonsBlock";

const SelectedFigureOperationButtons = ({selectedFigure}) => {
  const dispatch = useDispatch()
  const quarterPx = useSelector(state => state.forestUseReportReducer.quarterPx)
  const plotPx = useSelector(state => state.forestUseReportReducer.plotPx)
  const currentlyPressedButton = useSelector(state => state.forestUseReportReducer.currentlyPressedButton)
  const points = selectedFigure === 'quarterPolygon' ? quarterPx : plotPx
  const centerPoint = findPolygonCenter(points)
  const svg = document.getElementById('reportSvg')
  const rotateButtonsHandler = (direction) => {
    switch (selectedFigure) {
      case 'quarterPolygon':
        dispatch(setForestUseReportAttr({quarterPx: rotate(points, direction, centerPoint, 0.5)}))
        break
      case 'plotPolygon':
        dispatch(setForestUseReportAttr({plotPx: rotate(points, direction, centerPoint, 0.3)}))
    }
  }

  const arrowButtonsHandler = (direction) => {
    switch (selectedFigure) {
      case 'quarterPolygon':
        dispatch(setForestUseReportAttr({quarterPx: polygonShift(quarterPx, direction, 3)}))
        break
      case 'plotPolygon':
        dispatch(setForestUseReportAttr({quarterPx: polygonShift(plotPx, direction, 3)}))
    }
  }

  return (
    <WASDButtonsBlock svgElement={svg} rotateButtonsHandler={rotateButtonsHandler}
                      arrowButtonsHandler={arrowButtonsHandler} currentlyPressedButton={currentlyPressedButton}
                      leftPos={98} topPos={45}
    />
  );
};

export default SelectedFigureOperationButtons;