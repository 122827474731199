// вспомогательная функция для реализации drag'n'drop. Сохраняет в стейт стартовую точку
export const setStartDragImagePoint = (e, setDragMode, setDragStartingPoint, stockpileSvgContainerNodeId) => {
  setDragMode('image')
  const ele = document.getElementById(stockpileSvgContainerNodeId)
  setDragStartingPoint({
    scrollLeft: ele.scrollLeft,
    scrollTop: ele.scrollTop,
    clientX: e.clientX,
    clientY: e.clientY,
  })
}