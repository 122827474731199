import React from 'react';
import {useSelector} from "react-redux";


const Quarter = ({setViewedFigure, viewedFigure, viewedPoint, selectedFigure, coordCorrection}) => {
  const quarterPx = useSelector(state => state.forestUseReportReducer.quarterPx)
  const quarterNumber = useSelector(state => state.forestUseReportReducer.quarterNumber)
  const quarterCenterPoint = useSelector(state => state.forestUseReportReducer.quarterCenterPoint)
  const selectedQuarterPointIndex = useSelector(state => state.forestUseReportReducer.selectedQuarterPointIndex)
  return (
    <>
      <polygon
        onMouseLeave={() => setViewedFigure(null)}
        i={`quarterPolygon_none`}
        points={quarterPx.map((point) =>
          `${coordCorrection(point.x)}, ${coordCorrection(point.y)}`).join(' ')}
        fill={"#ffeb3b"}
        strokeOpacity={1}
        fillOpacity={(viewedFigure === 'quarterPolygon' || selectedFigure === 'quarterPolygon') ? 0.2 : 0}
        stroke={'#ffeb3b'}
        strokeWidth={selectedFigure === 'quarterPolygon' ? 4 : 2}
      />
      {quarterCenterPoint.x &&
        <text
          style={{userSelect: 'none'}}
          i={`quarterText_none`}
          className={'svgText'}
          x={coordCorrection(quarterCenterPoint.x)}
          y={coordCorrection(quarterCenterPoint.y)}
          stroke={'yellow'}
          fontSize={20}
        >{quarterNumber}</text>
      }
      {selectedFigure === 'quarterPolygon' && quarterPx.map((point, i) =>
        <circle
          i={`quarterPoint_${i}`}
          key={i}
          cx={coordCorrection(point.x)}
          cy={coordCorrection(point.y)}
          r={(viewedPoint.index === i && viewedPoint.type === 'quarterPoint') || selectedQuarterPointIndex === i ? 7 : 5}
          fill={selectedQuarterPointIndex !== i ? "#ffeb3b" : '#f57f17'}
          opacity={1}
        />
      )}
    </>
  );
};

export default Quarter;