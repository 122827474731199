const defaultState = {
  latlngs: null, //Если не null, покажет диалог, допускается пусчтой массив [] для ввода координат с нуля
  callback: null,
  type: null,
  name: '',
}

export function showCoordinatesEditor(latlngs, type, callback, name = '') {
  return {
    type: 'MAP_SHOW_COORDINATES_EDITOR',
    latlngs: latlngs,
    callback: callback,
    shapeType: type,
    name: name,
  }
}

export function closeCoordinatesEditor() {
  return {
    type: 'MAP_CLOSE_COORDINATES_EDITOR',
  }
}

export function coordinatesEditorReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_SHOW_COORDINATES_EDITOR':
      return {...state, latlngs: action.latlngs, type: action.shapeType, callback: action.callback, name: action.name}
    case 'MAP_CLOSE_COORDINATES_EDITOR':
      return {...defaultState}
    default:
      return state;
  }
}
