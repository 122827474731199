import React from 'react';
import {useSelector} from "react-redux";
import LayerModeButtons from "./LayerButtons/LayerModeButtons";
import Box from "@mui/material/Box";
import {SvgOperationButtonsBlock} from "../../../Common/Styles";

const OperationButtons = () => {
  const mode = useSelector(state => state.planetableSvgReducer.mode)
  const container = document.getElementById('svg-container')?.getBoundingClientRect()

  const ButtonBlock = () => {
    switch (mode) {
      case 'layer':
        return <LayerModeButtons/>
    }
  }

  return (
    <>
      {container &&
        <Box style={{...SvgOperationButtonsBlock, left: '50%', top: '5px'}}>
            <ButtonBlock/>
        </Box>
      }
    </>
  )
}

export default OperationButtons;
