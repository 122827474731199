import {IconButton, Modal} from "@mui/material";
import React from "react";
import ReactPlayer from "react-player/youtube";
import CloseIcon from '@mui/icons-material/Close';

function Window(props) {
  const closeCallback = props.closeCallback;

  const closeButtonStyle = {
    position: 'absolute',
    right: '5px',
    top: '5px',
    color: 'white',
    fontSize: '5px',
    padding: 0,
    paddingRight: '10px',
  }

  return (
    <div style={{position: 'relative', height: '100%'}} id={'lalala'}>
        <IconButton
          style={closeButtonStyle}
          color={'white'}
          onClick={() => closeCallback()}
        >
          <CloseIcon/>
        </IconButton>
      <ReactPlayer
        url={props.url}
        playing
        width={'100%'}
        height={'100%'}
        controls
        config={{
          youtube: {
            origin: process.env.REACT_APP_DOMAIN_NAME,
          },
        }}
      />
    </div>
  )
}

export function VideoModal(props) {
  const url = props.url
  const ModalWindow = React.forwardRef(() => <Window url={url} closeCallback={props.closeCallback}/>)
  if (!url)
    return null;

  return (
    <Modal open={true} style={{zIndex: 40000}}>
      <ModalWindow/>
    </Modal>
  )
}
