import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import {
  addCAToMap,
  getCuttingAreas,
  putCAtoStructuredTree2,
  removeCAFromMap,
} from '../../LeftPanel/Context/CAs/CuttingsAreas.js'
import {stopFilter} from "./FiltersReducer.js";
import {setCASearchResults} from "../../LeftPanel/Context/CAs/SearchResultsReducer";

let gogogo = false; //TODO избавится от этого

export const Filters = () => {
  const stateF = useSelector(state => state.filterReducer)
  const dispatch = useDispatch()

  async function refreshFilter(filterName, filters) {
    let results = [];
    let resultTree2 = {data: {}, show: true, type: 'root'};
    const cas = getCuttingAreas()
    cas.layerArray.map(lay => {
      let propa = lay.elz_properties[filterName]
      if (propa?.name)
        propa = propa.name;
      const f_ind = lay.elz_filter.indexOf(filterName)
      if (filterName === 'all') { //"clear all filters" button pressed
        if (lay.elz_properties.status === 'В архиве')
          lay.elz_filter = ['status']
        else
          lay.elz_filter = []
      } else
      if (filters.indexOf(propa) !== -1 || !filters.length) {
        if (f_ind !== -1)
          lay.elz_filter.splice(f_ind, 1)
      } else {
        if (f_ind === -1)
          lay.elz_filter.push(filterName)
      }

      //show hide
      if (lay.elz_filter.length === 0) {
        results.push(lay)
        putCAtoStructuredTree2(lay, resultTree2, cas.structuredTree2)
        addCAToMap(lay)
      } else {
        removeCAFromMap(lay)
      }
      return null;
    })

    if (stateF.selectionLayers) {
      results = results.filter(value => stateF.selectionData.includes(value));
    }
    cas.structuredTree2 = resultTree2;
    dispatch(setCASearchResults(results))
    dispatch(stopFilter())
    gogogo = false;
  }

  useEffect(() => {
    const cas = getCuttingAreas()
    const filterName = stateF.filters.currentFilter;
    let filters = stateF.filters.ca[filterName];
    if (!filters)
      filters = []
    if (filterName === 'status' && filters.length === 0)
      filters = cas.defaultStatusFilters;
    if (stateF.fireFilter && cas.layerArray.length && !gogogo) {
      gogogo = true;
      refreshFilter(filterName, filters, stateF).then()
    }
  })
  return null;
}
