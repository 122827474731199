import L from "leaflet";
import "leaflet-arrowheads";
import {getMap1} from "../../../../../Map/GlobalObjects";
import {setTransportMonitoringInfo} from "../caPropertiesReducer";
import {otherDataAPI} from "../../../../../../../api/api";
import {handleErrors} from "../../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {cannotIDo} from "../../../../../Map/Common/tariffs";
import {getSpeciesColor} from "../../CuttingsAreas";

let dataTM = null;

/*export const typeColors = {
  "Пихта": "#7c148f",
  "Лиственница": "#754a19",
  "Кедр": "#d00000",
  "Осина": "#7ef77a",
  "Ель": "#8f147f",
  "Берёза": "#c7b915",
  "Сосна": "#ffb054",
  "Бук": "#8ebd8e",
  "Липа": "#fffe01",
  "Клен": "#01a06e",
  "Клён": "#01a06e",
  "Дуб": "#676767",
}*/

export function getTMData() {
  return dataTM;
}

export function tmDataIsEmpty() {
  return Object.keys(dataTM).length === 0;
}

/**
 * Преобразует ответ бекенда в слои.
 * Удаляет points из ответа.
 * Cоздает группы и маркеры.
 * Добавляет их на карту.
 * @param data {any} - res.data
 */
export function setTMData(data) {
  deleteTMLayers()
  const map = getMap1()
  const type = window.store.getState()["caPropertiesReducer"].tm.type;
  let ind = data.stems.length - 1;
  while (ind >= 0) {
    const group = data.stems[ind]
    let color = 'grey';
    if (type === 'volumes_by_species') {
      color = getSpeciesColor(group.name) || 'grey';
    } else if (type === 'volumes_by_species_and_product_name') {
      const spec = group.name.split(' - ')[1]
      if (spec) {
        color = getSpeciesColor(group.name) || 'grey';
      }
    }
    const layerGroup = L.layerGroup().addTo(map)
    group.layers = layerGroup;
    group.color = color;
    group.checked = true;
    group.points.forEach(point => {
      layerGroup.addLayer(
        L.circle(
          [point.latitude, point.longitude],
          {
            color: color,
            fillColor: color,
            fillOpacity: 1,
            weight: 1,
            radius: 1,
            pane: 'cas_points',
          }
        )
      )
    })
    delete group.points;
    ind--;
  }
}

export function deleteTMLayers() {
  const map = getMap1()
  if (dataTM?.stems) {
    Object.values(dataTM.stems).forEach(group => {
      map.removeLayer(group.layers)
    })
  }
  dataTM = {}
}

export function getSpeciesThunk(id, type = 'volumes_by_species', dateMin = NaN, dateMax = NaN) {
  async function load(type, dateMin, dateMax) {
    switch (type) {
      case "volumes_by_product_name":
        return await otherDataAPI.map.popup.plots.getVolumesByProductName(id, dateMin, dateMax)
      case "volumes_by_species":
        return await otherDataAPI.map.popup.plots.getVolumesBySpecies(id, dateMin, dateMax)
      case "volumes_by_species_and_product_name":
        return await otherDataAPI.map.popup.plots.getVolumesBySpeciesAndProductName(id, dateMin, dateMax)
    }
  }

  const dispatch = window.elz_dispatch;
  dispatch(setTransportMonitoringInfo({
    loading: true,
    loadingError: false,
    id: id,
    type: type,
  }))
  if (!cannotIDo.useLoggingComplexMonitoringModule()) {
    load(type, dateMin, dateMax)
      .then(res => {
        const oldId = window.store.getState()["caPropertiesReducer"].tm.id;
        //Запрос очень долгий, проверяем не переключился ли узер на другую деляну
        if (oldId === id) {
          const oldSelected = window.store.getState()["caPropertiesReducer"].tm.selected;
          setTMData(res.data)
          dataTM = res.data;
          let max = Date.parse(res.data.date.date_max)
          if (isNaN(max))
            max = 0;
          let min = Date.parse(res.data.date.date_min)
          if (isNaN(min)) {
            min = 0;
          }
          dispatch(setTransportMonitoringInfo({
            loading: false,
            loadingError: false,
            show: true,
            productionShow: true,
            date_min: min,
            date_max: max,
            selected: oldSelected[0] ? oldSelected : [min, max],
          }))
        }
      })
      .catch(err => {
        const status = err.response?.status || 400;
        if (status === 400) {
          handleErrors(window.elz_dispatch, err)
        }
        dispatch(setTransportMonitoringInfo({loading: false, loadingError: status, show: status !== 403}))
      })
  } else {
    dispatch(setTransportMonitoringInfo({loading: false, loadingError: 403}))
  }
}
