//export const GET_ALL_WAREHOUSES = "GET_ALL_WAREHOUSES";
//export const GET_WAREHOUSE = "GET_WAREHOUSE";
export const SET_TARGET_WAREHOUSE = "SET_TARGET_WAREHOUSE";
//export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";
export const UPDATE_ITEM_IN_LIST = "UPDATE_ITEM_IN_LIST";
//export const CLEAR_WAREHOUSES = "CLEAR_WAREHOUSES";

///////////////////////////////////////////////////////////////////////////

export const WAREHOUSES_SET_LOADING_STATE = "WAREHOUSES_SET_LOADING_STATE";
export const WAREHOUSES_SET_DATA = "WAREHOUSES_SET_DATA";
export const WAREHOUSES_SET_GROUP = "WAREHOUSES_SET_GROUP";
export const WAREHOUSES_RESET = 'WAREHOUSES_RESET';
