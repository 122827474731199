import React from 'react';
import {useSelector} from "react-redux";

const NewQuarter = ({coordCorrection}) => {
  const newQuarterPx = useSelector(state => state.forestUseReportReducer.newQuarterPx)

  return (
    <>
      <polygon
        i={'newQuarterPolygon_none'}
        points={newQuarterPx.map((point) =>
          `${coordCorrection(point.x)}, ${coordCorrection(point.y)}`).join(' ')}
        fill={"#ffeb3b"}
        strokeOpacity={1}
        fillOpacity={0.2}
        stroke={'#ffeb3b'}
        strokeWidth={4}
      />
      {newQuarterPx.map((point, i) =>
        <circle
          i={`newQuarterPoint_${i}`}
          key={i}
          cx={coordCorrection(point.x)}
          cy={coordCorrection(point.y)}
          r={6}
          fill={"#ffeb3b"}
          opacity={1}
        />
      )}
    </>
  );
};

export default NewQuarter;