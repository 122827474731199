import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate} from "react-router";
import {urlGenerator} from "../../utils/urlGenerator";
import {useDispatch, useSelector} from "react-redux";
import {
  loginFormHandlerThunk,
  sendConfCodeThunk, setAuthAttr, smsCodeCheckThunk
} from "../../redux/reducers/loginReducer";
import {useFormik} from "formik";
import {ReactComponent as DelyanaLogoMain} from "../../svg/Delyana_logo_main.svg";
import {useEffect, useState} from "react";
import {Link} from "@mui/material";
import LoginByPassword from "./LoginByPassword/LoginByPassword";
import LoginByCode from "./LoginByCode/LoginByCode";
import SendCheckCodeComponent from "../RegistrationForm/SendCheckCodeComponent/SendCheckCodeComponent";
import {CookiesAcceptModal} from "./CookiesAcceptModal/CookiesAcceptModal";
import Stack from "@mui/material/Stack";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginReducer.token);
  const checkCodeToggler = useSelector((state) => state.loginReducer.checkCodeToggler);
  const sendConfCodeSuccess = useSelector(state => state.loginReducer.sendConfCodeSuccess)
  const [phoneError, setPhoneError] = useState('')
  const [passError, setPassError] = useState('')
  const [loginByPasswordToggler, setLoginByPasswordToggler] = useState(false)
  const [seconds, setSeconds] = useState(60);
  const [phone, setPhone] = useState('');

  // Таймер для повторной отправки sms
  useEffect(() => {
    let timer = seconds
    if (sendConfCodeSuccess && seconds > 0) {
      let timerId = setInterval(() => {
        setSeconds(prev => prev - 1)
        timer--
        if (timer <= 0) {
          dispatch(setAuthAttr({sendConfCodeSuccess: false}))
          clearInterval(timerId)
        }
      }, 1000)
    }
  }, [sendConfCodeSuccess])

  // Редирект на главную если рефреш токен существует
  useEffect(() => {
    if (token.refresh) {
      navigate(urlGenerator().main);
    }
  }, [token])

  const validate = (values) => {
    const errors = {};
    if (!values.login) {
      errors.login = "Обязательное поле";
    } else if (values.login.length > 10 || isNaN(values.login)) {
      errors.login = "Номер телефона должен содержать только цифры"
    }
    if (values.password) {
      if (values.password === '') {
        errors.password = "Обязательное поле";
      } else if (values.password.length > 50) {
        errors.password = "Пароль не должен содержать больше 50 символов";
      }
    }
    errors.login ? setPhoneError(errors.login) : setPhoneError('')
    errors.password ? setPassError(errors.password) : setPassError('')
    return errors;
  };

  const passConfirmationForm = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      dispatch(loginFormHandlerThunk(values));
    },
  });

  const smsConfirmationForm = useFormik({
    initialValues: {
      login: "",
    },
    validate,
    onSubmit: async (values) => {
      setPhone(values.login)
      dispatch(sendConfCodeThunk(values.login))
    },
  });

  const sendCodeForm = useFormik({
    initialValues: {
      code: ''
    },
    onSubmit: async (values) => {
      dispatch(smsCodeCheckThunk(phone, values.code))
    },
  });
  return (
    <Stack sx={{height: '80vh', width: '100%', overflow: 'auto'}} spacing={2} alignItems={'center'}
           justifyContent={'center'}>
      <Box>
        <DelyanaLogoMain height={'8vh'}/>
      </Box>
      <Box>
          <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Avatar sx={{bgcolor: "primary.main"}}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Вход
              </Typography>
            </Box>
            {/*Если юзеру была отправлена sms, отрисовываем блок с полем для ввода sms если нет, то отрисовываем
            либо блок входа через код либо блок входа через пароль */}
            {checkCodeToggler ? (
              <SendCheckCodeComponent smsConfirmationForm={sendCodeForm} setSeconds={setSeconds}
                                      seconds={seconds} phone={phone}/>
            ) : (
              <>
                {loginByPasswordToggler && !checkCodeToggler ? (
                  <LoginByCode smsConfirmationForm={smsConfirmationForm} phoneError={phoneError}/>
                ) : (
                  <LoginByPassword passConfirmationForm={passConfirmationForm} phoneError={phoneError}
                                   passError={passError}/>
                )}
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Link sx={{mt: 3, cursor: 'pointer'}}
                        onClick={() => setLoginByPasswordToggler(prev => !prev)}
                  >{loginByPasswordToggler ? 'Войти с паролем' : 'Войти через sms-код'}</Link>
                  {/*<Link sx={{mt: 3, cursor: 'pointer'}}*/}
                  {/*      onClick={() => navigate(urlGenerator().signUp)}*/}
                  {/*>Зарегистрироваться</Link>*/}
                </Box>
                <CookiesAcceptModal/>
              </>
            )}
          </Container>
      </Box>
    </Stack>
  );
}

