import React, {useState}  from 'react';
import {TableBody} from "@mui/material";
import TotalRow from "./TotalRow/TotalRow";
import CreateNewRow from "./CreateNewRow/CreateNewRow";
import TableForms from "./TableForms/TableForms";
import TableRowComponent from "./TableRowComponent/TableRowComponent";

const TableBodyComponent = ({tableColumns, dataType, tableData, iAmEditor}) => {
  const results = tableData?.results.result || []
  const allVolume = tableData?.results.all_volume || ''
  const [editableRowIndex, setEditableRowIndex] = useState(null)
  const [isNewRow, setIsNewRow] = useState(false)
  const [copyRowIndex, setCopyRowIndex] = useState(null)

  return (
    <TableBody>
      <TotalRow columns={tableColumns} allVolume={allVolume}/>
      {!isNewRow
        ?
          <>{iAmEditor && <CreateNewRow setIsNewRow={setIsNewRow} tableColumns={tableColumns}/>}</>
        :
          <TableForms columns={tableColumns}
                      setIsNewRow={setIsNewRow} isNewRow={isNewRow} dataType={dataType}/>
        }
      {results.map((row, rowIndex) => {
        const isRow = rowIndex !== editableRowIndex && copyRowIndex !== rowIndex;
        const isEditableRow = rowIndex === editableRowIndex && copyRowIndex !== rowIndex;
        const isCopyRow = copyRowIndex === rowIndex;

        if (isRow) {
          return (<TableRowComponent key={row.id}
                                     tableColumns={tableColumns}
                                     row={row} dataType={dataType}
                                     rowIndex={rowIndex}
                                     setEditableRowIndex={setEditableRowIndex}
                                     setCopyRowIndex={setCopyRowIndex}
                                     iAmEditor={iAmEditor}/>)
        } else if (isEditableRow) {
          return (<TableForms key={row.id}
                              isNewRow={isNewRow}
                              columns={tableColumns}
                              setEditableRowIndex={setEditableRowIndex}
                              editableRowIndex={editableRowIndex}
                              dataType={dataType}
                              tableData={results}/>)
        } else if (isCopyRow) {
          return (
            <React.Fragment key={row.id}>
              <TableRowComponent tableColumns={tableColumns}
                                 row={row} dataType={dataType}
                                 rowIndex={rowIndex}
                                 setEditableRowIndex={setEditableRowIndex}/>
              <TableForms isCopyRow={true}
                          columns={tableColumns}
                          setEditableRowIndex={setCopyRowIndex}
                          editableRowIndex={copyRowIndex}
                          dataType={dataType}
                          tableData={results}/>
            </React.Fragment>)
        }})
      }
    </TableBody>
  )
}

export default TableBodyComponent;