import {otherDataAPI} from "../../api/api";
import update from 'immutability-helper'
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {replacePhotoInSelectedStockpile} from "./stockpilesReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_PHOTO_ATTR = 'SET_PHOTO_ATTR'
const ADD_OUTLINE_POINT = 'ADD_OUTLINE_POINT'
const CHANGE_OUTLINE_POINT = 'CHANGE_OUTLINE_POINT'
const DELETE_OUTLINE_POINT = 'DELETE_OUTLINE_POINT'
const ADD_POINT_ON_LINE = 'ADD_POINT_ON_LINE'
const ADD_OUTLINE = 'ADD_OUTLINE'
const SVG_UNDO_CHANGES = 'SVG_UNDO_CHANGES'
const UPDATE_SVG_DATA = 'UPDATE_SVG_DATA'
const DISABLE_MODE = 'DISABLE_MODE'
const CHANGE_RULER_POINT = 'CHANGE_RULER_POINT'
const ADD_CUT_POINT = 'ADD_CUT_POINT'
const REPLACE_FIGURE = 'REPLACE_FIGURE'

const initialState = {
  mode: "view", //ruler, outline, createOutline, timbers, cutOutline, view
  creationMode: null, // null, 'outline', 'ruler', 'timber'
  zoom: 100,
  scale: 1,
  selectedPhoto: null,

  layers: {
    outline: true,
    ruler: true,
    timbers: false,
  },

  //figure data
  outlines: [],
  outline_selected_index: null,
  outline_viewed_index: null,
  outline_selected_point_index: null,
  outline_viewed_point_index: null,
  figureParameters: {species: null, length: null, rate: null, variety: null,},
  cuttingMode: false,
  cutPoints: [],

  //ruler data
  ruler: [],
  ruler_length: 10,
  ruler_selected_index: null,
  ruler_viewed_index: null,
  ruler_selected_point_index: null,
  ruler_viewed_point_index: null,

  // timbers data
  timbers_px: [],
  timbers_px_include: [],
  timber_selected_index: null,
  timber_viewed_index: null,

  deletePhotoLoading: false,
  recognizePhotoLoading: false,
}

// редьюсер для работы с данными фото штабеля
export const stockpilePhotoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SVG_UNDO_CHANGES: {
      return {
        ...state,
        selectedPhoto: {...state.selectedPhoto}
      }
    }
    case UPDATE_SVG_DATA: {
      if (action.selectedPhoto) {
        const {figures, ruler, timbers_px, timbers_px_include, ruler_length} = action.selectedPhoto
        const outlines = figures.map(figure => figure.points)
        return {
          ...state,
          outlines,
          ruler,
          ruler_length,
          timbers_px,
          timbers_px_include,
        }
      }
      return state
    }
    case SET_PHOTO_ATTR: {
      return {...state, ...action.attr};
    }
    case ADD_OUTLINE_POINT: {
      const selectedOutlineIndex = state.outline_selected_index
      const selectedPointIndex = state.outline_selected_point_index
      const point = action.point
      let newPoints = JSON.parse(JSON.stringify(state.outlines[selectedOutlineIndex]))
      if (selectedPointIndex !== null) {
        newPoints.splice(selectedPointIndex + 1, 0, point)
      } else {
        newPoints = [...newPoints, point]
      }
      return {
        ...state,
        outlines: state.outlines.map((outline, i) => {
          if (i !== selectedOutlineIndex) {
            return outline
          } else {
            return newPoints
          }
        }),
        outline_selected_point_index: selectedPointIndex !== null ? selectedPointIndex + 1 : 0
      }
    }
    case ADD_POINT_ON_LINE: {
      const selectedOutlineIndex = state.outline_selected_index
      const {point, nextPointIndex} = action
      const outlineCopy = state.outlines.map((outline) => [...outline])

      const newPoints = outlineCopy[selectedOutlineIndex]
      newPoints.splice(nextPointIndex, 0, point)

      return {
        ...state,
        outlines: state.outlines.map((outline, i) => {
          if (i !== selectedOutlineIndex) {
            return outline
          } else {
            return newPoints
          }
        }),
        outline_selected_point_index: nextPointIndex
      }
    }
    case DELETE_OUTLINE_POINT: {
      const selectedPointIndex = state.outline_selected_point_index
      return {
        ...state,
        outlines: state.outlines.map((outline, i) => {
          if (state.outline_selected_index !== i) {
            return outline
          } else {
            return outline.filter((point, pointIndex) => pointIndex !== selectedPointIndex)
          }
        }),
        outline_selected_point_index: selectedPointIndex !== 0
          ? selectedPointIndex - 1
          : state.outlines[state.outline_selected_index].length - 2
      }
    }
    case CHANGE_OUTLINE_POINT: {
      return update(state, {
        outlines: {
          [state.outline_selected_index]: {
            [state.outline_selected_point_index]: {
              $set: action.payload,
            },
          },
        },
      })
    }
    case DISABLE_MODE: {
      return {
        ...state,
        mode: 'view',
        outline_selected_index: null,
        outline_viewed_index: null,
        outline_selected_point_index: null,
        outline_viewed_point_index: null,
        cutPoints: [],
        cuttingMode: false,
        creationMode: null,
        ruler_length: 10,
        ruler_selected_index: null,
        ruler_viewed_index: null,
        ruler_selected_point_index: null,
        ruler_viewed_point_index: null,
        timber_selected_index: null,
        timber_viewed_index: null,
      }
    }
    case ADD_OUTLINE: {
      const outline_selected_index = state.outlines.length
      return update(state, {
        outlines: {
          $push: [[]],
        },
        outline_selected_index: {
          $set: outline_selected_index,
        },
      })
    }
    case CHANGE_RULER_POINT: {
      return {
        ...state,
        ruler: state.ruler.map((point, i) => {
          if (state.ruler_selected_point_index !== i) {
            return point
          }
          return action.payload
        })
      }
    }
    case ADD_CUT_POINT: {
      if (state.cutPoints.length < 2) {
        return {
          ...state,
          cutPoints: [...state.cutPoints, action.point]
        }
      }
      return {
        ...state,
        cutPoints: [action.point]
      }
    }
    case REPLACE_FIGURE: {
      return {
        ...state,
        selectedPhoto: {
          ...state.selectedPhoto,
          figures: state.selectedPhoto.figures.map((figure) => {
            return (figure.id !== action.figure.id) ? figure : action.figure
          })
        }
      }
    }
    default:
      return state;
  }
}


export const setPhotoAttr = (attr) => ({type: SET_PHOTO_ATTR, attr});
export const addOutlinePoint = (point) => ({type: ADD_OUTLINE_POINT, point});
export const updateSvgData = (selectedPhoto) => ({type: UPDATE_SVG_DATA, selectedPhoto});
export const addPointOnLine = (point, nextPointIndex) => ({type: ADD_POINT_ON_LINE, point, nextPointIndex})
export const deleteOutlinePoint = () => ({type: DELETE_OUTLINE_POINT});
export const changeOutlinePoint = (payload) => ({type: CHANGE_OUTLINE_POINT, payload,})
export const changeRulerPoint = (payload) => ({type: CHANGE_RULER_POINT, payload,})
export const addCutPoint = (point) => ({type: ADD_CUT_POINT, point})
export const addOutline = () => ({type: ADD_OUTLINE})
export const undoSvgChanges = () => ({type: SVG_UNDO_CHANGES})
export const disableMode = () => ({type: DISABLE_MODE})
//export const replaceFigure = () => ({type: REPLACE_FIGURE})


export const disableModeAndUndoChanges = (selectedPhoto) => {
  return async (dispatch) => {
    dispatch(disableMode())
    dispatch(updateSvgData(selectedPhoto))
  }
}

export const setImage = (direction) => {
  return async (dispatch, getState) => {
    try {
      const selectedPhoto = getState().stockpilePhotoReducer.selectedPhoto
      const selectedStockpile = getState().stockpilesReducer.selectedStockpile
      const images = selectedStockpile.images
      const selectedPhotoIndex = images.findIndex((image) => selectedPhoto.id === image.id)
      let newImage = null;

      if (direction === 'prev' && selectedPhotoIndex > 0) {
        newImage = images[selectedPhotoIndex - 1];
      } else if (direction === 'next' && selectedPhotoIndex < images.length - 1) {
        newImage = images[selectedPhotoIndex + 1];
      }
      if (newImage) {
        dispatch(disableModeAndUndoChanges(newImage))
        dispatch(setPhotoAttr({selectedPhoto: newImage, zoom: 100}))
      }
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const saveFigureThunk = (
  figureParameters, outline, selectedPhoto
) => {
  let figureObj = {
    image: selectedPhoto.id,
    name: `Область_${figureParameters.species?.name || ''}_${figureParameters.length || ''}`,
    points: outline,
    rate: figureParameters.rate,
    species: figureParameters.species?.uuid || null,
    variety: figureParameters.variety?.uuid || null,
    length: figureParameters.length?.uuid || null,
  }
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.stockpilePhoto.saveFigure(figureObj)
      let newFiguresList = [...selectedPhoto.figures, res.data]
      let changedPhoto = {
        ...selectedPhoto,
        figures: newFiguresList,
        image_volume: newFiguresList.map(figure => figure.volume).reduce((acc, curVal) => acc + curVal, 0)
      }
      dispatch(setPhotoAttr({
        selectedPhoto: changedPhoto,
        creationMode: null
      }))
      dispatch(disableMode('outline'))
      dispatch(replacePhotoInSelectedStockpile(changedPhoto))
      dispatch(setSnack('success', "Область сохранена"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const patchFigureThunk = (selectedPhoto, figureObj) => {
  return async (dispatch) => {

    let newFigureObj = {
      ...figureObj,
      name: `Область_${figureObj.species?.name || ''}_${figureObj.length?.name || ''}`,
      species: figureObj.species?.uuid || null,
      variety: figureObj.variety?.uuid || null,
      length: figureObj.length?.uuid || null,
      rate: figureObj.rate || 0,
    }
    try {
      let res = await otherDataAPI.stockpilePhoto.patchFigure(newFigureObj)
      let newFiguresList = selectedPhoto.figures.map(figure => {
        if (figure.id !== newFigureObj.id) {
          return figure
        } else {
          return res.data
        }
      })
      let changedPhoto = {
        ...selectedPhoto,
        figures: newFiguresList,
        image_volume: newFiguresList.map(figure => figure.volume).reduce((acc, curVal) => acc + curVal, 0)
      }
      dispatch(setPhotoAttr({
        selectedPhoto: changedPhoto,
        creationMode: null,
      }))
      dispatch(disableMode('outline'))
      dispatch(replacePhotoInSelectedStockpile(changedPhoto))
      dispatch(setSnack('success', "Область обновлена"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
export const deleteFigureThunk = (figureId, selectedPhoto) => {
  return async (dispatch) => {
    try {
      await otherDataAPI.stockpilePhoto.deleteFigure(figureId)
      let changedPhoto = {...selectedPhoto, figures: selectedPhoto.figures.filter(figure => figure.id !== figureId)}
      dispatch(setPhotoAttr({
        selectedPhoto: changedPhoto,
      }))
      dispatch(disableMode('outline'))
      dispatch(replacePhotoInSelectedStockpile(changedPhoto))
      dispatch(setSnack('success', 'Область удалена'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const recognizePhotoThunk = (photoId) => {
  return async (dispatch) => {
    try {
      dispatch(setPhotoAttr({recognizePhotoLoading: true}))
      let res = await otherDataAPI.stockpilePhoto.recognizePhoto(photoId)
      dispatch(setPhotoAttr({recognizePhotoLoading: false, selectedPhoto: res.data}))
      dispatch(replacePhotoInSelectedStockpile(res.data))
      dispatch(setSnack('success', 'Данные фото обновлены'))
      console.timeEnd('end recognition')
    } catch (e) {
      dispatch(setPhotoAttr({recognizePhotoLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const patchChangeRulerDataThunk = (selectedPhoto, rulerData) => {
  return async (dispatch, getState) => {
    try {
      let outlines = getState().stockpilePhotoReducer.outlines
      let ruler_length = getState().stockpilePhotoReducer.ruler_length
      let timbers_px_include = getState().stockpilePhotoReducer.timbers_px_include
      let changeRulerData = {
        ruler: rulerData,
        ruler_length: ruler_length,
        figures: [...selectedPhoto.figures.map((figure, i) => ({
          ...figure,
          length: figure.length.uuid,
          points: outlines[i],
          species: figure.species ? figure.species.uuid : null,
          variety: figure.variety ? figure.variety.uuid : null,
        }))],
        timbers_px_include: timbers_px_include,
      }
      console.log(changeRulerData)
      let res = await otherDataAPI.stockpilePhoto.saveRuler(selectedPhoto.id, changeRulerData)
      dispatch(replacePhotoInSelectedStockpile(res.data))
      dispatch(setPhotoAttr({selectedPhoto: res.data}))
      dispatch(disableMode('ruler'))
      dispatch(setSnack('success', 'Данные линейки обновлены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const saveCuttingDataThunk = (figuresIdToDelete, newFigures) => {
  return async (dispatch, getState) => {
    try {
      let formattedNewFigures = newFigures.map(figure => ({...figure, length: figure.length?.uuid || null}))
      let res = await otherDataAPI.stockpilePhoto.cutFigure({
        id_figures_to_delete: figuresIdToDelete, new_figures: formattedNewFigures
      })
      let newSelectedPhoto = getState().stockpilePhotoReducer.selectedPhoto
      newSelectedPhoto.figures = newSelectedPhoto.figures.filter(figure => !figuresIdToDelete.includes(figure.id))
      newSelectedPhoto.figures = [...newSelectedPhoto.figures, ...res.data]
      dispatch(replacePhotoInSelectedStockpile(newSelectedPhoto))
      dispatch(setPhotoAttr({cuttingMode: false, mode: 'view'}))
      dispatch(disableMode('outline'))
      dispatch(setSnack('success', 'Данные области обновлены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
