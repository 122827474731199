import {setSnack} from "../components/Main/Map/Common/Dialog/Snack/snackReducer";

export function openFileFromBlob(blob) {
  const dispatch = window.elz_dispatch;
  const url = window.URL.createObjectURL(blob)
  const win = window.open(url, '_blank')
  if (!win) {
    dispatch(setSnack('error',
      'Для просмотра файла разрешите открытие всплывающих окон в браузере.'))
  } else {
    setTimeout(() => {
     window.URL.revokeObjectURL(url)
   }, 2000)
  }
}
