import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import Box from "@mui/material/Box";
import {getChildren, LeftDrawer} from "./LeftPanel/LeftDrawer";
import {ELZMapCP} from "./Map/ELZMapCP";
import {Modal, Stack} from "@mui/material";
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'react-reflex/styles.css'
import {useSelector} from "react-redux";
import {LeftDrawerPanel} from "./LeftPanel/LeftDrawerPanel";
import {Loading} from "./Map/Common/Loading";
import CreateUpdatePlotModal from "./LeftPanel/CreatePlotModal/CreateUpdatePlotModal";
import ForestUseReportModal from "./ForestUseReport/ForestUseReportModal";
import IntroTour from "../IntroTourCommon/IntroTour";
import {getMap1} from "./Map/GlobalObjects";
import {getSpecies, getTariff, setSpeciesData} from "./Map/Common/tariffs";
import {CreateUpdateWarehouseModal} from "./LeftPanel/Context/Infrastructure/Warehouses/CreateUpdateWarehouseModal";
import {forwardRef, useEffect, useState} from "react";
import {dataAPI} from "../../api/api";
import {dispatch} from "../Common/misc_functions";
import {handleErrors} from "../../redux/commonReducerFunctions/ThunkErrorsHandler";

let panelFlex = 0.3;
const Main = (props) => {
  const stateLeft = useSelector(state => state.leftDrawerReducer)
  const species = getSpecies()
  const [refresh, setRefresh] = useState(false)

  // Склады.
  const selectedWarehouse = useSelector((state) => state.plotDataReducer.selectedWarehouse);
  const showCreateWarehouseModal = useSelector((state) => state.plotDataReducer.showCreateWarehouseModal);
  const showUpdateWarehouseModal = useSelector((state) => state.plotDataReducer.showUpdateWarehouseModal);
  const TransitionCreateWarehouseModal = forwardRef(() => <CreateUpdateWarehouseModal title={'Создать склад'}/>)
  const TransitionUpdateWarehouseModal = forwardRef(() => <CreateUpdateWarehouseModal
    title={`Редактировать слад "${selectedWarehouse?.properties.name}"`}/>)

  // Деляны.
  const selectedPlot = useSelector((state) => state.plotDataReducer.selectedPlot);
  const showCreatePlotModal = useSelector((state) => state.plotDataReducer.showCreatePlotModal);
  const showUpdatePlotModal = useSelector((state) => state.plotDataReducer.showUpdatePlotModal);
  const TransitionCreatePlotModal = forwardRef(() => <CreateUpdatePlotModal title={'Создать деляну'}/>)
  const TransitionUpdatePlotModal = forwardRef(() => <CreateUpdatePlotModal
    title={`Редактировать деляну "${selectedPlot?.name}"`}/>)

  // Общее.
  const showForestUseReportModal = useSelector((state) => state.forestUseReportReducer.showForestUseReportModal);
  const mainPageIntroTour = useSelector((state) => state.introToursReducer.mainPageIntroTour);
  const TransitionForestUseReportModal = forwardRef(() => <ForestUseReportModal/>)
  const noBanner = props.nobanner;
  const setNoBanner = props.setNoBanner;
  const isMobileDevice = useSelector(state => state.userReducer.isMobileDevice)

  useEffect(() => {
    if (!species.data && (!species.loading || !species.loadingError)) {
      dataAPI.organization.getSpecies()
        .then(res => {
          const obj = {}
          res.data.forEach(specie => {
            obj[specie.name] = specie;
          })
          setSpeciesData({loading: false, loadingError: false, data: obj})
          setRefresh(!refresh)
        })
        .catch(err => {
          handleErrors(dispatch, err)
          setSpeciesData({loading: false, loadingError: true, data: null})
          setRefresh(!refresh)
        })
    }
  }, [species.data])

  if (!getTariff() || !species.data) {
    return (<Stack style={{height: '100%'}} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
      <Loading/>
    </Stack>)
  }

  return (
    <Box id='main_div' sx={{height: '100%', flex: '1 0 0', display: 'flex'}}>
      <Stack flexDirection={'column'} style={{flex: '1 0 0', display: 'flex'}}>
        {!isMobileDevice ? <Stack flexDirection={'row'} style={{flex: '1 0 0', display: 'flex'}}>
          <LeftDrawer/>
          {stateLeft.curTab ? <ReflexContainer orientation={'vertical'}>
            <ReflexElement
              flex={panelFlex}
              style={{overflow: 'hidden', display: 'flex'}}
              onResize={(e) => {
                panelFlex = e.component.props.flex;
              }}
            >
              <LeftDrawerPanel index={stateLeft.curTab}>
                {getChildren(stateLeft.curTab)}
              </LeftDrawerPanel>
            </ReflexElement>
            <ReflexSplitter
              className={'reflex-splitter-vertical'}
              onResize={() => {
                const map = getMap1()
                if (map) getMap1().invalidateSize()
              }}
            />
            <ReflexElement style={{overflow: 'hidden'}}>
              <ELZMapCP curTab={stateLeft.curTab} nobanner={noBanner} setNoBanner={setNoBanner}/>
            </ReflexElement>
          </ReflexContainer> : <>
            <ELZMapCP curTab={stateLeft.curTab} nobanner={noBanner} setNoBanner={setNoBanner}/>
          </>}
        </Stack> : <ELZMapCP curTab={stateLeft.curTab} nobanner={noBanner} setNoBanner={setNoBanner}/>}
      </Stack>
      <Modal open={showCreateWarehouseModal}>
        <TransitionCreateWarehouseModal/>
      </Modal>
      <Modal open={showUpdateWarehouseModal}>
        <TransitionUpdateWarehouseModal/>
      </Modal>
      <Modal open={showCreatePlotModal}>
        <TransitionCreatePlotModal/>
      </Modal>
      <Modal open={showUpdatePlotModal}>
        <TransitionUpdatePlotModal/>
      </Modal>
      <Modal open={showForestUseReportModal}>
        <TransitionForestUseReportModal/>
      </Modal>
      <IntroTour tourData={mainPageIntroTour} tourName={'mainPageIntroTour'}/>
    </Box>
  )
}
export default Main;
