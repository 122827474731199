import {ClickAwayListener, Typography} from "@mui/material";
import {copyrightLink, copyrightLink2} from "../Map/Common/Styles";
import {useState} from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const copyrights = {
  OpenStreetMap: {href: 'https://www.openstreetmap.org/copyright'},
  OpenData: {href: 'https://opendatacommons.org'},
  Leaflet: {href: 'https://raw.githubusercontent.com/Leaflet/Leaflet/main/LICENSE'},
  ArcGis: {href: 'https://www.arcgis.com'},
}

export function Copyright() { // Цопирайт в левом нижнем

  const [state, setState] = useState(false)
  let ind = 0;
  function CopyrightPopup() {
    return (
      <ClickAwayListener onClickAway={() => setState(false)}>
        <Box style={{
          position: 'absolute',
          bottom: 20,
          border: 'solid 1px black',
          borderRadius: '5px',
          backgroundColor: 'white',
          padding: '0.8vh'
        }}>
          {Object.entries(copyrights).map(([key, value]) =>
            <Stack flexDirection={'row'} alignItems={'stretch'} key={ind++}>
              <a href={value.href} target={'_blank'} style={copyrightLink}>
                <Typography style={{fontSize: '110%', whiteSpace: 'nowrap'}}>&copy; {key}</Typography>
              </a>
            </Stack>
          )
          }
        </Box>
      </ClickAwayListener>
    )
  }

  function onClick() {
    setState(true)
  }

  return (
    <>
      <Typography style={copyrightLink2} onClick={onClick}>&copy; Copyrights</Typography>
      {state && <CopyrightPopup/>}
    </>
  )
}
