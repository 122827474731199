import React from 'react';
import {ListItemIcon, MenuItem} from "@mui/material";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import {useNavigate} from "react-router";
import {urlGenerator} from "../../../../utils/urlGenerator";
import Typography from "@mui/material/Typography";

const OperationalReport = ({handleClose}) => {
  const navigate = useNavigate()
  const menuItemClickHandler = () => {
    navigate(urlGenerator().operationalReport)
    handleClose()
  }

  return (
    <div>
      <MenuItem onClick={menuItemClickHandler}>
        <ListItemIcon>
          <AssessmentOutlinedIcon/>
        </ListItemIcon>
        <Typography>
          Оперативный учет<span style={{border: 'solid 2px lightgrey', fontSize: 12, borderRadius: 10,
          padding: '1px 4px', marginLeft: '1px'}}>beta</span>
        </Typography>
      </MenuItem>
    </div>
  );
};

export default OperationalReport;