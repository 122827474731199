import {useEffect, useState} from 'react';
import {TableCell, TableRow} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";
import {createWorkspace} from "../../../org_page_api";
import {FTextField} from "../../../../Main/LeftPanel/Context/Projects/FTextFiled";

const WorkspaceForm = ({workspaceObj, WorkSpacesTableColumns}) => {
  const dispatch = useDispatch()
  const editWorkspaceMode = workspaceObj?.id
  const [workspaceName, setWorkspaceName] = useState('')
  const createWorkspaceMode = useSelector(state => state.organizationPageReducer.createWorkspaceMode)

  useEffect(() => {
    editWorkspaceMode && setWorkspaceName(workspaceObj.number)
  }, [])

  useEffect(() => {
    let textFieldElement = document.getElementById('workspaceNameInput')
    createWorkspaceMode && textFieldElement && textFieldElement.focus()
  }, [createWorkspaceMode])

  function undoEditWorkspace() {
    dispatch(changeOrgPageAttrs({createWorkspaceMode: false, selectedWorkspace: null}))
  }

  function createWorkspaceHandler(value) {
    !value
      ? undoEditWorkspace()
      : createWorkspace(value)
  }

  return (
    <TableRow sx={{"&:last-child td, &:last-child th": {border: 0,}}}>
      {WorkSpacesTableColumns.map((column) => {
          if (column.name !== 'number') {
            return (
              <TableCell key={column.name} align="left">

              </TableCell>
            )
          } else { //create workspace form
            return (
              <TableCell key={column.name} align="left">
                <FTextField
                  value={workspaceName}
                  _endEditing={(value) => {
                    createWorkspaceHandler(value)
                  }}
                  _cancelEdit={() => {
                    undoEditWorkspace()
                  }}
                />
              </TableCell>
            )
          }
        }
      )}
    </TableRow>
  )
}

export default WorkspaceForm;
