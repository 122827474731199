import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import {Loading} from "../../Map/Common/Loading";
import {useDispatch, useSelector} from "react-redux";
import {insertWarehouseDescriptionThunk,} from "../../../../redux/reducers/rightPanelReducer";
import LastChange from "../RPCommonElements/LastChange/LastChange";
import WarehouseProperties from "./WarehouseProperties";
import Comment from "../RPCommonElements/Comment/Comment";
import GeoData from "./GeoData";
import React from "react";
import {showCoordinatesEditor} from "../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {updateWarehouseCoordinates} from "../../LeftPanel/Context/Infrastructure/Warehouses/WarehouseCommon";

/** Правая панель для нарушения. */
const RPWarehouseData = () => {
    const shapeData = useSelector((state) => state.rightPanelReducer.shapeData)
    const rightPanelDataLoading = useSelector((state) => state.rightPanelReducer.rightPanelDataLoading)
    const node = useSelector((state) => state.rightPanelReducer.node)
    const dispatch = useDispatch()

    if (!shapeData) return null;

    function showCoordinatesEditorWindow() {
        const layer = node.layer;
        dispatch(showCoordinatesEditor(
          layer.getLatLngs()[0],
          'Polygon',
          (latlngs) => {
              updateWarehouseCoordinates(node, latlngs)
          },
          layer.elz_node.properties.name,
        ))
    }

    return (
        <Box sx={{height: "100%", overflowY: "auto"}}>
            {rightPanelDataLoading ? (
                <Loading/>
            ) : (
                <Stack spacing={1} sx={{pt: 1, pb: 1}}>
                    <Typography sx={{color: 'gray'}}>Склад: {shapeData["type_warehouse"] || "-"}</Typography>
                    <Button size={'small'} variant={'outlined'} fullWidth onClick={showCoordinatesEditorWindow}>
                        Координаты
                    </Button>
                    <WarehouseProperties shapeData={shapeData}/>
                    <Divider orientation={"horizontal"}/>
                    <Comment commentObj={shapeData?.comment} objectId={shapeData?.id} saveCommentThunk={insertWarehouseDescriptionThunk}/>
                    <Divider orientation={"horizontal"}/>
                    <LastChange objectData={shapeData}/>
                    <Divider orientation={"horizontal"}/>
                    <GeoData shapeData={shapeData}/>
                </Stack>
            )}
        </Box>
    )
}
export default RPWarehouseData;
