import {
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon, ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Box from "@mui/material/Box";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {useState} from "react";
import {cannotIDo} from "../../../../Map/Common/tariffs";

function ItemMenu({warehouse, handleMenuClick}) {
  const [menuAnchor, setMenuAnchor] = useState(null)

  function closeMenu(event) {
    event.stopPropagation()
    event.preventDefault()
    setMenuAnchor(null)
  }

  function menuClick(event, type) {
    event.stopPropagation()
    event.preventDefault()
    setMenuAnchor(null)
    handleMenuClick(type, warehouse)
  }

  return (
    <>
      <IconButton
        disableRipple
        sx={{padding: 0}}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setMenuAnchor(event.currentTarget)
        }}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      {menuAnchor &&
        <Menu
        open={true}
        anchorEl={menuAnchor}
        onClose={closeMenu}
      >
        <MenuItem onClick={(e) => menuClick(e, 'info')}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Информация
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => menuClick(e, 'coordinates')}>
          <ListItemIcon>
            <EditLocationOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Координаты
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => menuClick(e, 'delete')}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Удалить
          </ListItemText>
        </MenuItem>
      </Menu>
      }
    </>
  )
}

/**
 * Компонент, представляющий отдельный элемент списка складов.
 *
 * @component
 * @param {object} warehouse - Информация о складе.
 * @param {Function} handleAction - Функция, вызываемая при активации действия
 * @param {boolean} isSelected - Флаг, указывающий, выбран ли текущий элемент списка.
 */
export function WarehouseItem({warehouse, handleAction,  isSelected}) {
  const theme = useTheme()

  return (
    <ListItemButton
      disableRipple
      style={{backgroundColor: isSelected ? "#e0e0e0" : "transparent", cursor: "pointer"}}
      onClick={() => handleAction('click', warehouse)}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
        style={{
          width: "100%", userSelect: "none",
        }}
      >
        <Box display="flex" alignItems="center">
          <WarehouseIcon
            style={{
              color: theme.palette.primary.main, fontSize: 20, paddingRight: 10
            }}
          />
          <Box>
            <Typography
              style={{
                fontSize: '0.875rem', // Размер шрифта для типа склада
              }}
            >

              {warehouse.properties.name}
            </Typography>
            <Typography
              style={{
                color: '#616161', // Цвет текста типа
                fontSize: '0.75rem', // Меньший размер шрифта для имени склада
              }}
            >
              {warehouse.properties.type}
            </Typography>
          </Box>
        </Box>
        {Boolean(!cannotIDo.changeWarehouses()) && <ItemMenu warehouse={warehouse} handleMenuClick={handleAction}/>}
      </Stack>
    </ListItemButton>
  )
}
