import React from 'react';
import {Divider, Stack, Typography} from "@mui/material";
import {toolbarContainer, toolbarDividerStyle} from "../Toolbar/toolbarStyles";
import ZoomSlider from "../Toolbar/ToolbarButtons/ZoomSlider/ZoomSlider";
import LayerCheckboxes from "../Toolbar/ToolbarButtons/LayerCheckboxes/LayerCheckboxes";
import {useSelector} from "react-redux";

const ClosedStockpileToolbar = () => {
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)

  if (!selectedPhoto) return ''

  return (
    <Stack spacing={1} direction={'row'} sx={toolbarContainer}>
      <ZoomSlider/>
      <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
      <LayerCheckboxes/>
      <Divider orientation={'vertical'} sx={toolbarDividerStyle}/>
    </Stack>
  );
};

export default ClosedStockpileToolbar;