import {
  changeOutlinePoint,
  deleteQuarterOutlinePoint,
  setForestUseReportAttr
} from "../../../../redux/reducers/forestUseReportReducer";
import {
  findPolygonCenter,
  polygonShift,
  rotate
} from "../../../../svgComponentsHelpersFunctions/svgComponentsHelpersFunctions";

// получаем координаты точки на svg
export const getCoord = (e, svg, img) => {
  const modalPage = document.getElementById('photoModalPage')
  const modalPageRect = modalPage.getBoundingClientRect()
  const {x: svg_x, y: svg_y} = svg.current.getBoundingClientRect()
  const rel_x = e.pageX - modalPageRect.left
  const rel_y = e.pageY - modalPageRect.top
  const x = ((modalPageRect.x - svg_x + rel_x) * img.width) /
    parseFloat(svg.current.getAttribute('width'))
  const y = ((modalPageRect.y - svg_y + rel_y) * img.height) /
    parseFloat(svg.current.getAttribute('height'))
  return {x, y}
}

// сохраняем start drag point  в локальный стейт
export const setCurrentPoint = (e, setCurrentDragPosition) => {
  setCurrentDragPosition({
    x: e.clientX,
    y: e.clientY,
  })
}

export const dragPlotPolygon = (e, startDragPosition, setStartDragPosition, plotPx, dispatch) => {
  const dx = (e.clientX - startDragPosition.x) * 2
  const dy = (e.clientY - startDragPosition.y) * 2
  let newCoords = plotPx.map(point => {return {x: point.x + dx, y: point.y + dy}})
  dispatch(setForestUseReportAttr({plotPx: newCoords}))
  setCurrentPoint(e, setStartDragPosition)
}

export const dragQuarterPolygon = (e, startDragPosition, setStartDragPosition, quarterPx, quarterNumber,
                                   quarterCenterPoint, dispatch) => {
  const dx = (e.clientX - startDragPosition.x) * 2
  const dy = (e.clientY - startDragPosition.y) * 2
  let newCoords = quarterPx.map(point => {return {x: point.x + dx, y: point.y + dy}})
  let newCenterPoint = {x: quarterCenterPoint.x + dx, y: quarterCenterPoint.y + dy}
  dispatch(setForestUseReportAttr({quarterPx: newCoords, quarterCenterPoint: newCenterPoint}))
  setCurrentPoint(e, setStartDragPosition)
}

export const dragPoint = (e, currentDragPosition, setCurrentDragPosition, outline, pointIndex, dispatch, figureType) => {
  const dx = (e.clientX - currentDragPosition.x) * 2
  const dy = (e.clientY - currentDragPosition.y) * 2
  const currentPoint =  outline[pointIndex]
  const newCoords = {
    x: currentPoint.x + dx,
    y: currentPoint.y + dy
  }
  dispatch(changeOutlinePoint(pointIndex, figureType, newCoords))
  setCurrentPoint(e, setCurrentDragPosition)
}

export const keydown = (event, quarterPx, quarterCenterPoint, plotPx, dispatch, drawMode, selectedFigure,
                        newQuarterPx, newPlotPx) => {
  const rotateQuarterAngel = .3
  const rotatePlotAngel = .5
  const shiftValue = 3
  switch (event.code) {
    case "KeyE": {
      if (selectedFigure === 'quarterPolygon') {
        let newQuartersCoords = rotate(quarterPx, 'right', quarterCenterPoint, rotateQuarterAngel)
        dispatch(setForestUseReportAttr({quarterPx: newQuartersCoords}))
      }
      if (selectedFigure === 'plotPolygon') {
        let plotCenterPoint = findPolygonCenter(plotPx)
        let newPlotCoord = rotate(plotPx, 'right', plotCenterPoint, rotatePlotAngel)
        dispatch(setForestUseReportAttr({plotPx: newPlotCoord}))
      }
    }
      break
    case "KeyQ": {
      if (selectedFigure === 'quarterPolygon') {
        let newQuartersCoords = rotate(quarterPx, 'left', quarterCenterPoint, rotateQuarterAngel)
        dispatch(setForestUseReportAttr({quarterPx: newQuartersCoords}))
      }
      if (selectedFigure === 'plotPolygon') {
        let plotCenterPoint = findPolygonCenter(plotPx)
        let newPlotCoord = rotate(plotPx, 'left', plotCenterPoint, rotatePlotAngel)
        dispatch(setForestUseReportAttr({plotPx: newPlotCoord}))
      }
    }
    break
    case "KeyW": {
      if (selectedFigure === 'quarterPolygon') {
        let newQuartersCoords = polygonShift(quarterPx, 'up', shiftValue)
        let newCenterPoint = {x: quarterCenterPoint.x, y: quarterCenterPoint.y - shiftValue}
        dispatch(setForestUseReportAttr({quarterPx: newQuartersCoords, quarterCenterPoint: newCenterPoint}))
      }
      if (selectedFigure === 'plotPolygon') {
        let newQuartersCoords = polygonShift(plotPx, 'up', shiftValue)
        dispatch(setForestUseReportAttr({plotPx: newQuartersCoords}))
      }
    }
      break
    case "KeyS": {
      if (selectedFigure === 'quarterPolygon') {
        let newQuartersCoords = polygonShift(quarterPx, 'down', shiftValue)
        let newCenterPoint = {x: quarterCenterPoint.x, y: quarterCenterPoint.y + shiftValue}
        dispatch(setForestUseReportAttr({quarterPx: newQuartersCoords, quarterCenterPoint: newCenterPoint}))
      }
      if (selectedFigure === 'plotPolygon') {
        let newQuartersCoords = polygonShift(plotPx, 'down', 3)
        dispatch(setForestUseReportAttr({plotPx: newQuartersCoords}))
      }
    }
      break
    case "KeyA": {
      if (selectedFigure === 'quarterPolygon') {
        let newQuartersCoords = polygonShift(quarterPx, 'left', shiftValue)
        let newCenterPoint = {x: quarterCenterPoint.x - shiftValue, y: quarterCenterPoint.y}
        dispatch(setForestUseReportAttr({quarterPx: newQuartersCoords, quarterCenterPoint: newCenterPoint}))
      }
      if (selectedFigure === 'plotPolygon') {
        let newQuartersCoords = polygonShift(plotPx, 'left', shiftValue)
        dispatch(setForestUseReportAttr({plotPx: newQuartersCoords}))
      }
    }
      break
    case "KeyD": {
      if (selectedFigure === 'quarterPolygon') {
        let newQuartersCoords = polygonShift(quarterPx, 'right', shiftValue)
        let newCenterPoint = {x: quarterCenterPoint.x + shiftValue, y: quarterCenterPoint.y}
        dispatch(setForestUseReportAttr({quarterPx: newQuartersCoords, quarterCenterPoint: newCenterPoint}))
      }
      if (selectedFigure === 'plotPolygon') {
        let newQuartersCoords = polygonShift(plotPx, 'right', shiftValue)
        dispatch(setForestUseReportAttr({plotPx: newQuartersCoords}))
      }
    }
      break
    case "Backspace": {
      if (drawMode === 'drawQuarter') {
        dispatch(setForestUseReportAttr({newQuarterPx: newQuarterPx.slice(0, -1)}))
      }
      if (drawMode === 'drawPlot') {
        dispatch(setForestUseReportAttr({newPlotPx: newPlotPx.slice(0, -1)}))
      }
    }
  }
}

export const setViewedFigureAndCursorType = (figureData, setCursorType, setViewedFigure, setViewedPoint,
                                             selectedFigure, selectedQuarterPointIndex, selectedPlotPointIndex) => {
  const [figureType, figureIndex] = figureData
  switch (figureType) {
    case 'plotPolygon':
      setCursorType(selectedFigure === 'plotPolygon' ? 'grab' : 'pointer')
      setViewedFigure(figureType)
      setViewedPoint({index: null, type: null})
      break
    case 'quarterPolygon':
      setCursorType(selectedFigure === 'quarterPolygon' ? 'grab' : 'pointer')
      setViewedFigure(figureType)
      setViewedPoint({index: null, type: null})
      break
    case 'quarterPoint':
      setViewedPoint({index: +figureIndex, type: figureType})
      setCursorType(selectedQuarterPointIndex === +figureIndex ? 'grab' : 'pointer')
      break
    case 'plotPoint':
      setViewedPoint({index: +figureIndex, type: figureType})
      setCursorType(selectedPlotPointIndex === +figureIndex ? 'grab' : 'pointer')
      break
  }
}

