import {Alert, Button, Divider, IconButton, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {getProjects, initProjects, removeAllProjectDrawLayers} from "./ProjectsCommon";
import {ProjectsTree2} from "./ProjectsTree";
import {
  pillarsLoadingErrorString, pillarsReloadButtonString,
  projectsLoadingError,
  rebootString, tariffExpiredLong
} from "../../../Map/Common/Strings_RU";
import Box from "@mui/material/Box";
import {Loading} from "../../../Map/Common/Loading";
import CloseIcon from '@mui/icons-material/Close';
import {setLeftDrawerCurTab} from "../../LeftDrawerReducer";
import {getPillars, loadPillars, resetPillars} from "../Infrastructure/Pillars/pillars";
import {refreshProjects} from "./projectsReducer";
import {getLeftDrawerButtons} from "../../LeftDrawer";
import {cannotIDo} from "../../../Map/Common/tariffs";
import {WorkspaceSettingsDialog} from "./WorkspaceSettings/WorkspaceSettingsDialog";
import {ProjectsToolPanel} from "./ProjectsToolPanel";

export function Projects(props) {
  const state = useSelector(state => state.projectsReducer)
  const dispatch = useDispatch()
  const projects = getProjects()
  const tariffExpired = useSelector(state => state.elzMapReducerCP.tariffExpired)
  //const projectsIntroTour = useSelector(state => state.introToursReducer.projectsIntroTour)
  const pillars = getPillars()
  const readOnly = !!cannotIDo.editorAction()

  if (!projects)
    return null;

  return (
    <Box style={{display: 'flex', flexDirection: 'column', flex: '1 0 0'}} id={'projectsBlock'}>
      <Stack flexDirection={'row'} justifyContent="space-between" alignItems="center">
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography align={'center'}>{getLeftDrawerButtons()[props.index].name}</Typography>
{/*          <IconButton
            sx={{...pageTitleStyle}}
            onClick={() => {
              dispatch(setIntroTourAttr({...projectsIntroTour, status: true}, 'projectsIntroTour'))
            }}
            title={infoButtonTittleString}>
            <ErrorOutlineOutlinedIcon sx={{fontSize: 20}}/>
          </IconButton>*/}
        </Stack>
        <IconButton
          onClick={() => {
            removeAllProjectDrawLayers()
            dispatch(setLeftDrawerCurTab(null))
          }}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      {projects.loading ?
        <Loading/>
        :
        projects.error ?
          <Stack spacing={1}>
            <Alert severity={'error'} icon={false}>
                {projectsLoadingError}
            </Alert>
            <Button variant={'outlined'} onClick={() => initProjects()}>
              {rebootString}
            </Button>
          </Stack>
          :
          <>
            {pillars?.error &&
              <Box sx={{mb: 1}}>
                <Alert severity={'error'} icon={false}>
                  {pillarsLoadingErrorString}
                  <Button
                    sx={{mt: 1}}
                    size={'small'}
                    fullWidth
                    variant={'outlined'}
                    onClick={() => {
                      resetPillars()
                      dispatch(refreshProjects())
                      loadPillars().then()
                    }}
                  >
                    {pillarsReloadButtonString}
                  </Button>
                </Alert>
              </Box>
            }
            {tariffExpired ?
              <Alert icon={false} severity={"warning"}>
                {tariffExpiredLong}
              </Alert>
              :
              !readOnly &&
              <ProjectsToolPanel state={state} />
            }
            {projects &&
              <>
              <Divider />
              <Box id={'projects_tree'} style={{width: '100%', overflow: 'auto', flex: '1 0 0', paddingTop: '1vh'}}>
                <ProjectsTree2 readOnly={readOnly} />
              </Box>
              </>
            }
          </>
      }
      <WorkspaceSettingsDialog />
      {/*<IntroTour tourData={projectsIntroTour} tourName={'projectsIntroTour'}/>*/}
    </Box>
  )
}
