import React, {useState} from 'react';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import {blockTitle} from "../../rightPanelStyles";
import {Collapse, Typography} from "@mui/material";
import ShortInfo from "../ShortInfo/ShortInfo";
import {properties1CMap, propertiesMap} from "../../RPRoadData/roadMapData";
import {useSelector} from "react-redux";

const Data1C = () => {
  const properties_1c = useSelector(state => state.rightPanelReducer.plotData?.properties_1c) || null;
  const [open, setOpen] = useState(false);

  const open1CDataHandler = () => {
    setOpen(prev => !prev)
  }

  return (
    <>
      <Box sx={{display: 'flex', justifyContent: 'flex-start', cursor: 'pointer'}} onClick={open1CDataHandler}>
        <Typography style={blockTitle}>Данные из 1С</Typography>
        {open ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ShortInfo properties_1c={properties_1c} properties1CMap={properties1CMap}/>
      </Collapse>
    </>
  );
};

export default Data1C;
