import {selectAreaString} from "../Strings_RU";
import {treeItemButtonStyle} from "../Styles";
import {IconButton, useTheme} from "@mui/material";
import React from "react";
import {getDrawLayerGroup, getMap1} from "../../GlobalObjects";
import {resetAllDrawing} from "./drawing";

export function SelectOnMapButton(props) {
  const removeLayer = props.removeLayer; //Удалять слой после рисования
  const disabled = props.disabled || false;
  const startDrawing = props.onStart;
  const endDrawing = props.onEnd;
  const selectionType = props.selectionType; //Marker, CircleMarker, Circle, Line, Rectangle, Polygon, Text, Cut, CutCircle
  const icon = props.icon; //иконка на кнопке
  const shapeStyle = props.shapeStyle; //Стиль рисования
  const drawing = props.drawing;
  const theme = useTheme()
  const map =getMap1()

  function pmCreateHandler(e) {
    map.off('pm:create')
    const drawingGroup = getDrawLayerGroup()
    if (removeLayer)
      drawingGroup.removeLayer(e.layer)
    if (endDrawing) endDrawing({status: 'ok', layer: e.layer})
  }

  function clickHandler(event) {
    event.stopPropagation()
    const map = getMap1()
    resetAllDrawing()
    if (!drawing) { //start drawing
      map.pm.enableDraw(selectionType, shapeStyle)
      map.on('pm:create', pmCreateHandler)
      if (startDrawing) startDrawing()
    }
    else { //stop drawing
      if (endDrawing) endDrawing({status: 'cancel'})
    }
  }

  let color = theme.palette.text.disabled;
  if (!disabled)
    color = drawing?theme.palette.info.main:theme.palette.primary.main;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        disabled={disabled}
        disableRipple
        title={selectAreaString}
        style={{...treeItemButtonStyle, stroke: color}}
        onClick={clickHandler}
      >
        {icon}
      </IconButton>
    </div>
  )
}
