import {getMap1} from "../GlobalObjects";
import {createBaseLayer} from "../../Overlay/BaseLayers/BaseLayersCommon";

const baseLayersData = {
  osm: {
    name: "Карта OSM",
    type: 'tiles',
    show: false,
    l: null,
    opacity: 1,
    pane: "blind_base",
    feas: false,
    zIndex: 1,
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  eeco: {
    name: "ЕЭКО",
    type: 'tiles',
    show: false,
    l: null,
    opacity: 1,
    pane: "blind_base",
    feas: false,
    zIndex: 1,
    url: 'https://ngw.fppd.cgkipd.ru/tile/56/{z}/{x}/{y}.png',
  },
  google_schema: {
    name: "Карта Google",
    type: 'tiles',
    l: null,
    show: false,
    opacity: 1,
    pane: "blind_base",
    feas: false,
    zIndex: 2,
    url: 'https://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}&s=Ga',
  },
  google_sat: {
    name: "Спутник Google",
    type: 'tiles',
    l: null,
    show: false,
    opacity: 1,
    pane: "blind_base",
    feas: false,
    zIndex: 3,
    url: 'https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}',
  },
  arcgis_sat: {
    name: "Спутник ArcGis",
    type: 'tiles',
    l: null,
    show: true,
    opacity: 1,
    pane: "blind_base",
    feas: false,
    zIndex: 4,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  },
  sentinel: {
    name: "Спутник Sentinel",
    type: 'sentinel',
    l: null,
    show: false,
    opacity: 1,
    pane: "blind_sentinel",
    time1: undefined,
    time2: undefined,
    cc: 60.0,
    channel: 'TRUE-COLOR-S2L2A',
    feas: true,
    zIndex: 5,
    url: null,
  },
}

export function getBlindBaseLayers() { // возвращает базовые слои шторки
  return baseLayersData;
}

export function resetBlindBaseLayers() { // удалить все базовые слои шторки с карты
  const map = getMap1()
  Object.values(baseLayersData).map(data => {
    if (data.l)
      map.removeLayer(data.l)
    data.l = null;
  })
}

export function addAllShowedBaseLayersToMap() { // добавляет все слои с value.show на карту (для начальной загрузки, если шторка включена)
  const map = getMap1()
  Object.entries(baseLayersData).map(([key, value]) => {
    if (value.show) {
      value.l = createBaseLayer(key, true, true)
      map.addLayer(value.l)
    }
  })
}
