import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import {overlayButtonStyle} from "../../Map/Common/Styles";
import {MeasuringPanel} from "./MeasuringPanel";
import {useDispatch, useSelector} from "react-redux";
import {resetMeasuringPanel, showHidePanel} from "./measuringReducer";
import {removeAllMeasuringFromDRGroup} from "../../Map/Common/Drawing/drawing";
import {getMap1} from "../../Map/GlobalObjects";
import {measuringString} from "../../Map/Common/Strings_RU";

export function MeasuringButton() {
  const state = useSelector(state => state.measuringReducer)
  const dispatch = useDispatch()

  function buttonClickHandler() {
    dispatch(showHidePanel(true))
  }

  function onClose() {
    getMap1().pm.disableDraw()
    removeAllMeasuringFromDRGroup()
    dispatch(resetMeasuringPanel())
  }

  return (
    <Box style={overlayButtonStyle} id={'measuringButton'}>
      {!state.panelVisible ?
        <IconButton onClick={buttonClickHandler} style={{padding: 5}} title={measuringString}>
          <SquareFootIcon fontSize={'small'} />
        </IconButton> :
        <MeasuringPanel _onClose={onClose}/>
      }
    </Box>
  )
}
