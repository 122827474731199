import {useEffect, useRef, useState} from 'react';
import {Alert, Box} from "@mui/material";
import OperationButtons from "./OperationButtons/OperationButtons";
import CurrentFigureInfoBlock from "./CurrentFigureInfoBlock/CurrentFigureInfoBlock";
import PlanetableSvg from "./PlanetableSvg";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {planetablesSvgContainer} from "../PlanetabesPageSyles";
import {Loading} from "../../Main/Map/Common/Loading";

const PlanetableSvgContainer = () => {
  const selectedPlanetableLoading = useSelector(state => state.planetablesReducer.selectedPlanetableLoading)
  const selectedPlanetableError = useSelector(state => state.planetablesReducer.selectedPlanetableError)
  const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)
  const mode = useSelector(state => state.planetableSvgReducer.mode)
  const [containerSize, setContainerSize] = useState(null);
  const blockRef = useRef(null);
  const svgContainerId = 'svg-container'

  useEffect(() => {
    function handleResize() {
      setContainerSize(document.getElementById(svgContainerId)?.getBoundingClientRect())
    }
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(blockRef.current);
    return () => {
      if (resizeObserver && blockRef.current) {
        resizeObserver.unobserve(blockRef.current);
      }
    }
  }, []);

  return (
    <Box id='svg-container' ref={blockRef}
         style={planetablesSvgContainer}
         onContextMenu={(e) => e.preventDefault()}
    >
      {selectedPlanetable && containerSize
        ? (
          <>
            {mode !== 'view' &&
              <OperationButtons/>
            }
            <CurrentFigureInfoBlock/>
            {!selectedPlanetableLoading
              ? <PlanetableSvg containerSize={containerSize}/>
              : (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Loading text={'Загрузка планшета'} />
                </Box>
              )
            }
          </>
        ) : (
          <Alert severity={!selectedPlanetableError ? 'info' : 'error'}
                 sx={{display: 'flex', justifyContent: 'center'}}>
            {!selectedPlanetableError ? (
              <Typography alignItems={'center'}>
                Выберите планшет для отображения
              </Typography>
            ) : (
              <Typography alignItems={'center'}>
                При загрузке планшета произошла ошибка. Проверьте подключение к интернету или попробуйте позже
              </Typography>
            )}
          </Alert>
        )
      }
    </Box>
  )
}

export default PlanetableSvgContainer;
