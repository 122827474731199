import React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {InputAdornment, Link} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";

const LoginByPassword = ({ passConfirmationForm, phoneError, passError}) => {
    const navigate = useNavigate()
    return (
        <Box
            key={'passForm'}
            component="form"
            onSubmit={passConfirmationForm.handleSubmit}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Логин"
                name="login"
                autoComplete="email"
                autoFocus
                error={!!phoneError}
                helperText={phoneError}
                onChange={passConfirmationForm.handleChange}
                onBlur={passConfirmationForm.handleBlur}
                inputProps={{maxLength: 10}}
                InputProps={{
                    startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                error={!!passError}
                helperText={passError}
                name="password"
                label="Пароль"
                type="password"
                id="password"
                inputProps={{maxLength: 20}}
                autoComplete="current-password"
                onChange={passConfirmationForm.handleChange}
                onBlur={passConfirmationForm.handleBlur}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3}}
            >
                Войти
            </Button>
        </Box>
    );
};

export default LoginByPassword;
