import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import TokenService from "../../api/token.service";
import {authAPI} from "../../api/api";

export const WEBSOCKET_OPEN = 'WEBSOCKET_OPEN';
export const WEBSOCKET_CLOSE = 'WEBSOCKET_CLOSE';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET_MESSAGE';
export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';
export const WEBSOCKET_SEND = 'WEBSOCKET_SEND';

const initialState = {
  connected: false,
  stockpilePhotosMessages: []
};

// редьюсер для работы с вебсокет соединением
export const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEBSOCKET_OPEN:
      return { ...state, connected: true };
    case WEBSOCKET_CLOSE:
      return { ...state, connected: false };
    case WEBSOCKET_MESSAGE:
      return { ...state, stockpilePhotosMessages: [...state.stockpilePhotosMessages, JSON.parse(action.payload)] };
    default:
      return state;
  }
};

export const websocketOpen = () => ({ type: WEBSOCKET_OPEN });
export const websocketClose = () => ({ type: WEBSOCKET_CLOSE });
export const websocketMessage = (message) => ({ type: WEBSOCKET_MESSAGE, payload: message });
export const websocketConnect = () => ({ type: WEBSOCKET_CONNECT });
export const websocketDisconnect = () => ({ type: WEBSOCKET_DISCONNECT });
//export const websocketSendMessage = (message) => ({ type: WEBSOCKET_SEND, payload: message });

export const websocketMiddleware = store => next => action => {
  let socket = null;
  let websocket_url = process.env.REACT_APP_WEBSOCKETS_URL
  let url = websocket_url + `stockpile?token=${TokenService.getLocalAccessToken()}`

  switch (action.type) {
    case WEBSOCKET_CONNECT:
      /*if (socket) {
        socket.close();
      }*/
      socket = new WebSocket(url);
      socket.onopen = () => store.dispatch(websocketOpen());
      socket.onclose = () => store.dispatch(websocketClose());
      socket.onmessage = (event) => store.dispatch(websocketMessage(event.data));
      socket.onerror = () => {
        store.dispatch(getNewAccessTokenAndReconnect())
      }
      break;
    case WEBSOCKET_DISCONNECT:
      /*if (socket) {
        socket.close();
      }*/
      socket = null;
      break;
    /*case WEBSOCKET_SEND:
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(action.payload);
      }
      break;*/
    default:
      return next(action);
  }
};

const getNewAccessTokenAndReconnect = () => {
  return async (dispatch) => {
    try {
      const resAccess = await authAPI.getNewAccessToken()
      const accessToken = resAccess.data.token;
      TokenService.updateLocalAccessToken(accessToken);
      setTimeout(() => dispatch(websocketConnect()), 200)
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setSnack('error', 'Ошибка обновления токена доступа'))
      }
    }
  }
}
