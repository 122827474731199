import {ReactComponent as DelyanaLogoMain} from "../../svg/Delyana_logo_main.svg";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import {newRegistrationFormThunk, setAuthAttr, smsCodeCheckThunk} from "../../redux/reducers/loginReducer";
import {useDispatch, useSelector} from "react-redux";
import {urlGenerator} from "../../utils/urlGenerator";
import {useLocation, useNavigate} from "react-router";
import SendCheckCodeComponent from "./SendCheckCodeComponent/SendCheckCodeComponent";
import RegistrationOrgFormsComponent from "./RegistrationOrgFormsComponent/RegistrationOrgFormsComponent";
import {Stack} from "@mui/material";
import {domainName} from "../../api/api";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1e66a5',
      dark: '#020f3d'
    },
  },
})

const fieldsProps = {
  last_name: {name: "Фамилия", type: 'text', max_length: 50},
  first_name: {name: "Имя", type: 'text', max_length: 50},
  patronymic: {name: "Отчество", type: 'text', max_length: 50},
  phone: {name: "Телефон", type: 'tel', max_length: 10},
  email: {name: "Адрес эл. почты", type: 'email', max_length: 50},
  post: {name: "Должность", type: 'text', max_length: 50},
  organization_inn: {name: "ИНН", type: 'tel', max_length: 12},
}

export const RegistrationFormMain = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const url = domainName + location.pathname
  const loginState = useSelector(state => state.loginReducer)
  const checkConfCodeSuccess = useSelector(state => state.loginReducer.checkConfCodeSuccess)
  const SMSConfirmation = loginState.SMSConfirmation
  const sendConfCodeSuccess = useSelector(state => state.loginReducer.sendConfCodeSuccess)
  const [registrationOrgFormsErrors, setRegistrationOrgFormsErrors] = useState({})
  const [phone, setPhone] = useState('')
  const [seconds, setSeconds] = useState(60);
  let initialValues = {}
  for (let key in fieldsProps) {
    initialValues[key] = '';
  }

  const formattingString = (str) => {
    str = str.trim()
    return (str[0]) ? str[0].toUpperCase() + str.slice(1) : ''
  }

  const getUTMDataHandler = (url) => {
    const urlParse = new URL(url);
    const paramsObj = {};
    for (let [key, value] of urlParse.searchParams.entries()) {
      paramsObj[key] = value;
    }
    return paramsObj
  }

  // Эффект включающий таймер до повторной отправки смс при изменении тогглера sendConfCodeSuccess
  useEffect(() => {
    let timer = seconds;
    if (sendConfCodeSuccess && seconds > 0) {
      let timerId = setInterval(() => {
        setSeconds(prev => prev - 1);
        timer--;
        if (timer <= 0) {
          dispatch(setAuthAttr({sendConfCodeSuccess: false}));
          clearInterval(timerId);
        }
      }, 1000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [sendConfCodeSuccess]);

  if (checkConfCodeSuccess) {
    navigate(urlGenerator().main);

    setRegistrationOrgFormsErrors(initialValues)
  }

  const validate = (values) => {
    const validationRules = {
      last_name: [/^[А-ЯЁA-Z][А-ЯЁа-яёA-Za-z\s-]{0,48}$/, "Неверный формат фамилии. Ожидаемый формат: " +
      "только русские или латинские буквы"],
      first_name: [/^[А-ЯЁA-Z][А-ЯЁа-яёA-Za-z\s-]{0,48}$/, "Неверный формат имени. Ожидаемый формат: " +
      "только русские или латинские буквы"],
      patronymic: [/^[А-ЯЁA-Z][А-ЯЁа-яёA-Za-z\s-][а-яёA-Za-z]{0,48}$/, "Неверный формат отчества. Ожидаемый формат: " +
      "только русские или латинские буквы"],
      phone: [/^\d{10}$/, "Неверный формат телефона. Ожидаемый формат: номер мобильного телефона, только цифры, " +
      "10 символов"],
      email: [/^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}$/, "Неверный формат email"],
      post: [/^[А-ЯЁа-яёA-Za-z\s][а-яёA-Za-z\s]{0,48}$/, "Неверный формат должности. Ожидаемый формат: только русские буквы"],
      organization_inn: [/^\d{10}(\d{2})?$/, "Неверный формат ИНН компании. Ожидаемый формат: только цифры, 10 или 12 символов"],
    };
    let errors = {}
    Object.keys(values).forEach((key) => {
      const [pattern, errorMessage] = validationRules[key];
      if (!pattern.test(values[key]) && key !== 'patronymic') {
        errors[key] = errorMessage
      } else {
        errors[key] = ''
      }
    })
    setRegistrationOrgFormsErrors(errors)
    dispatch(setAuthAttr({sendConfCodeSuccess: true}))
    return errors
  };

  const registrationOrgForm
    = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      values.first_name = formattingString(values.first_name)
      values.last_name = formattingString(values.last_name)
      values.patronymic = formattingString(values.patronymic)
      let errors = validate(values)
      const allErrorsEmpty = Object.values(errors).every(value => value === '');
      let utm = getUTMDataHandler(url)
      allErrorsEmpty && dispatch(newRegistrationFormThunk({...values, ...utm}))
      setPhone(values.phone)
    },
  });
  const smsConfirmationForm = useFormik({
    initialValues: {
      code: ''
    },
    onSubmit: async (values) => {
      dispatch(smsCodeCheckThunk(phone, values.code))
    },
  });

  return (
    <Stack direction="column" sx={{  height: '100%', display: 'flex', flexDirection: 'column',
      justifyContent: 'center', alignItems: 'center'}} spacing={2}>
      <Box>
        <DelyanaLogoMain style={{height: '8vh'}}/>
      </Box>
      <Box sx={{height: '80%', overflowY: 'auto'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Avatar sx={{bgcolor: "primary.main"}}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Регистрация
          </Typography>
        </Box>
        <ThemeProvider theme={theme}>
          {/*Если тогглер SMSConfirmation true, то рендерим блок SendCheckCodeComponent если нет, то рендерим
          блок RegistrationOrgFormsComponent*/}
          <Box sx={{position: 'relative', left: 0}}>
            <Stack direction={'column'} sx={{alignItems: 'center', ml: 5}}>
              <Box>
                {SMSConfirmation ? (
                  <SendCheckCodeComponent smsConfirmationForm={smsConfirmationForm}
                                          setSeconds={setSeconds} seconds={seconds} phone={phone}/>
                ) : (
                  <RegistrationOrgFormsComponent registrationForm={registrationOrgForm}
                                                 fieldsProps={fieldsProps}
                                                 errors={registrationOrgFormsErrors}
                  />
                )}
              </Box>
            </Stack>
          </Box>
        </ThemeProvider>
      </Box>
    </Stack>
  )
}
