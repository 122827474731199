import React, {useState} from 'react';
import {Autocomplete, TextField} from "@mui/material";
import {getReferenceThunk} from "../../../../redux/reducers/referencesReducer";
import {useDispatch, useSelector} from "react-redux";

const LocationAutocomplete = ({rental_contract, setPlanetableData, currentPlanetable}) => {
  const rentalContracts = useSelector(state => state.organizationInfoReducer.organizationWorkspaces)
  return (
    <Autocomplete
      disablePortal
      size={"small"}
      options={rentalContracts || []}
      disabled={!!currentPlanetable}
      value={rental_contract}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id
      }
      getOptionLabel={(option) => option?.number || ''}
      renderInput={(params) => {
        return (
          <TextField{...params} label={'Рабочая область'}/>
        )
      }}
      onChange={(e, item) => setPlanetableData(prev => {return {...prev, rental_contract: item}})}
    />
  );
};

export default LocationAutocomplete;