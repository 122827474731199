import React from 'react';
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import NorthIcon from "@mui/icons-material/North";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import WestIcon from "@mui/icons-material/West";
import SouthIcon from "@mui/icons-material/South";
import EastIcon from "@mui/icons-material/East";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const IconButtonStyle = {border: 'solid 1px darkgrey', position: 'relative', padding: 8, borderRadius: 8, margin: 1}

const iconStyle = {fontSize: 16}

const IconButtonSpanStyle = {
  position: 'absolute',
  bottom: 7,
  left: 7,
  fontWeight: 'bold',
  fontSize: 12,
  transform: 'translate(-50%, 50%)',
}

// блок кнопок qweasd для корректировки положения svg-фигур поверх изображения
const WASDRFButtonsBlock = ({
                              svgElement, rotateButtonsHandler, arrowButtonsHandler,
                              currentlyPressedButton, scaleButtonsHandler
                            }) => {
  return (
    <Stack style={{
      borderRadius: 10, padding: 2,
      justifyContent: 'center', alignItems: 'center', marginBottom: 2, position: 'absolute', left: '5px', top: '5px',
      backgroundColor: 'rgba(255,255,255,0.8)', opacity: 0.8
    }}
           onClick={() => {
             svgElement.focus()
           }}
    >
      <Stack direction={'row'}>
        <IconButton
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyQ' && '#e57373'}}
          color={'primary'}
          title={'Повернуть влево'}
          onClick={() => rotateButtonsHandler('left')}
        >
          <RotateLeftIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        Q
        </span>
        </IconButton>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyW' && '#e57373'}}
          onClick={() => arrowButtonsHandler('up')}
        >
          <NorthIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        W
        </span>
        </IconButton>
        <IconButton
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyE' && '#e57373'}}
          color={'primary'}
          title={'Повернуть вправо'}
          onClick={() => rotateButtonsHandler('right')}
        >
          <RotateRightIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        E
        </span>
        </IconButton>
        <IconButton
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyR' && '#e57373'}}
          color={'primary'}
          title={'Повернуть вправо'}
          onClick={(e) => scaleButtonsHandler(e, '+')}
        >
          <AddIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        R
        </span>
        </IconButton>
      </Stack>
      <Stack direction={'row'} alignItems={'center'}>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyA' && '#e57373'}}
          onClick={() => arrowButtonsHandler('left')}
        >
          <WestIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        A
        </span>
        </IconButton>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyS' && '#e57373'}}
          onClick={() => arrowButtonsHandler('down')}
        >
          <SouthIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        S
        </span>
        </IconButton>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyD' && '#e57373'}}
          onClick={() => arrowButtonsHandler('right')}
        >
          <EastIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        D
        </span>
        </IconButton>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyF' && '#e57373'}}
          onClick={(e) => scaleButtonsHandler(e, '-')}
        >
          <RemoveIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        F
        </span>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default WASDRFButtonsBlock;
