//Редусер для блокирования доступа узера к интерфейсу

const defaultState = {
  blocked: false,
  message: null,
  type: 'login', //login: после нажатия кнопки , переход на логинпаге (залочен доступ); f5 - требуется обновление страницы пользователем (убран доступ к какому-то объекту)
}

export function blockUserForever(type, message) {
  return {
    type: 'APP_USER_BLOCK',
    pageType: type,
    message: message,
  }
}

export function userBlockReducer(state = defaultState, action) {
  switch(action.type) {
    case 'APP_USER_BLOCK':
      return {blocked: true, type: action.pageType, message: action.message}
    default:
      return state;
  }
}
