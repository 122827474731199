import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  patchQuartersLayerThunk,
  saveQuartersLayerThunk,
  setPlanetableSvgAttr
} from "../../../../../redux/reducers/planetableSvgReducer";
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const LayerModeButtons = () => {
  const dispatch = useDispatch()
  const layerFromMap = useSelector(state => state.planetableSvgReducer.layerFromMap)
  const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)

  const saveButtonHandler = () => {
    // сохраняем слой на бэкэнд в соответствии с типом
    layerFromMap.type === 'quarters' &&
    dispatch(saveQuartersLayerThunk(
      layerFromMap.layerObjects.map(item => {
        return {
          number: item.number,
          x_planetable: item.x_planetable,
          x_quarter: item.x_quarter,
          coordinates_pxl: item.coordinates_pxl,
          rotation_angle: layerFromMap.layerRotationAngle
        }
      })
    ))
  }

  const patchButtonHandler = () => {
    layerFromMap.type === 'quarters' &&
    dispatch(patchQuartersLayerThunk(
      layerFromMap.layerObjects.map(item => {
        return {
          number: item.number,
          x_planetable: item.x_planetable,
          x_quarter: item.x_quarter,
          coordinates_pxl: item.coordinates_pxl,
          rotation_angle: layerFromMap.layerRotationAngle
        }
      })
    ))
  }


  const undoButtonHandler = () => {
    dispatch(setPlanetableSvgAttr({layerFromMap: {type: 'none', layerObjects: []}}))
    dispatch(setPlanetableSvgAttr({mode: 'view'}))
  }

  return (
    <>
      {selectedPlanetable.quarters_px.length
        ? (
          <IconButton
            title={'Сохранить изменения'}
            color={'primary'}
            onClick={patchButtonHandler}
          >
            <SaveIcon fontSize={'small'}/>
          </IconButton>
        )
        : (
          <IconButton
            title={'Сохранить'}
            color={'primary'}
            onClick={saveButtonHandler}
          >
            <SaveIcon fontSize={'small'}/>
          </IconButton>
        )
      }
      <IconButton title={'Отменить'}
                  color={'primary'}
                  onClick={undoButtonHandler}
      >
        <RestartAltIcon/>
      </IconButton>
    </>
  );
};

export default LayerModeButtons;