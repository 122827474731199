import {IconButton, Menu, MenuItem} from "@mui/material";
import {treeItemButtonIconSize, treeItemButtonStyle} from "../../../../Map/Common/Styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  deleteString,
  deletingUserMiscLayerString,
  deletingUserMiscLayerText,
  editString, userMiscLayersDeleteErrorString
} from "../../../../Map/Common/Strings_RU";
import React, {useState} from "react";
import {hideMapDialog, showMapDialog} from "../../../../Map/Common/Dialog/DialogReducer";
import {dataAPI} from "../../../../../../api/api";
import {setSettingWasChanged} from "../../../../Map/Common/userSettings";
import {blindRefresh} from "../../../../Map/Blind/BlindReducer";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {useDispatch} from "react-redux";
import {getUserMiscLayers, removeUserLayerFromMap} from "./UserLayers";
import {setUserLayersEditLayer} from "./userLayersReducer";

export function UserLayerContextMenu(props) { //контекстное меню узерских слоев
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const dispatch = useDispatch()
  const node = props.node;

  function deleteUserLayer(node) {
    dispatch(showMapDialog(
      'warning',
      deletingUserMiscLayerString,
      deletingUserMiscLayerText,
      {'Да': {color: 'warning'}, 'Нет': {color: 'primary'}},
      (value) => {
        dispatch(hideMapDialog())
        if (value === 'Да') {
          if (node.id) {
            dataAPI.userLayers.delete(node.id).then(() => {
              const ind = removeUserLayerFromMap(node)
              if (ind !== -1)
                getUserMiscLayers().data.splice(ind, 1)
              setSettingWasChanged()
              dispatch(blindRefresh())
            }).catch(err => {
              console.error(err)
              setSnack('error', userMiscLayersDeleteErrorString)
            })
          } else {
            const ind = removeUserLayerFromMap(node)
            if (ind !== -1)
              getUserMiscLayers().data.splice(ind, 1)
          }
          setSettingWasChanged()
          dispatch(blindRefresh())
        }
      }
    ))
  }

  return (
  <>
    <IconButton
      disabled={props.disabled !== 0}
      style={{...treeItemButtonStyle, padding: 0}}
      onClick={(e) => {
        e.stopPropagation() //Что опять за хуйня с этим блятским реактом?!!!!
        setMenuAnchor(e.currentTarget)
      }}
    >
      <MoreVertIcon fontSize={'small'} style={treeItemButtonIconSize}/>
    </IconButton>
    <Menu
      anchorEl={menuAnchor}
      open={menuOpen}
      onClose={() => setMenuAnchor(null)}
      onClick={(e) => e.stopPropagation()}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      transformOrigin={{vertical: 'top', horizontal: 'left'}}
    >
      <MenuItem
        onClick={(e) => {
          setMenuAnchor(null)
          e.stopPropagation()
          dispatch(setUserLayersEditLayer(node))
        }}
      >
        <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
        <Typography>{editString}</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setMenuAnchor(null)
          deleteUserLayer(node)
        }}
      >
        <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
        <Typography>{deleteString}</Typography>
      </MenuItem>
    </Menu>
  </>
  )
}
