import {getMap1} from "../../../../Map/GlobalObjects";
import L from "leaflet";
import {
  resetWarehouses,
  setTargetWarehouse,
  setWarehousesData,
  setWarehousesGroup,
  setWarehousesLoadingState
} from "./actionCreators";
import {otherDataAPI} from "../../../../../../api/api";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {handleErrors} from "../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {hideShapeByNode} from "../../Projects/ProjectsCommon";
import {setPlotDataAttr} from "../../../../../../redux/reducers/plotDataReducer";
import {clearCoordinatesDialogInfo} from "../../../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import {closeMapPopup, showMapPopup} from "../../../../Map/MapPopup/mapPopup";
import {getWarehousePopup, prepareToOpenPopup} from "../../../../Map/MapPopup/popupFunctions";
import {getWarehouseDataThunk, resetRightPanelData} from "../../../../../../redux/reducers/rightPanelReducer";
import {cannotIDo} from "../../../../Map/Common/tariffs";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";

/**
 * Создает ноду и слой на карте из feature GeoJson
 * @param feature - Фича из GeoJson
 * @param group - группа слоев в которую будет добавлен leaflet слой
 * @returns {{properties: *, layer: *}} - штабельная нода
 */
export function createWarehouseFromFeature(feature, group) {
  const coords = feature.geometry.coordinates[0].map((ll) => [ll[1], ll[0]])
  const lay = L.polygon(coords, {color: 'brown', pane: 'warehouses'}).addTo(group)
  const node = {
    properties: structuredClone(feature.properties),
    layer: lay,
  }
  lay.elz_node = node; //TODO А надо?
  lay.on('click', warehouseClickHandler)
  return node;
}

/**
 * Обработчик клика по складу
 * @param event - leaflet ивент
 */
export function warehouseClickHandler(event) {
  if (!prepareToOpenPopup(event))
    return;
  const layer = event.target;
  const dispatch = window.elz_dispatch;
  const interactive = {}
  if (!cannotIDo.changeWarehouses()) {
    interactive.wh_edit_info_button = {
      type: 'click',
        f: () => {
        closeMapPopup()
        dispatch(setPlotDataAttr({showUpdateWarehouseModal: true, selectedWarehouse: layer.elz_node}))
      }
    }
/*    interactive.wh_edit_coordinates_button = {
      type: 'click',
        f: () => {
        closeMapPopup()
        dispatch(showCoordinatesEditor(
          layer.getLatLngs()[0],
          'Polygon',
          (latlngs) => {
            updateWarehouseCoordinates(layer.elz_node, latlngs)
          },
          layer.elz_node.properties.name,
        ))
      }
    }*/
  }
  const html = getWarehousePopup(layer, interactive)
  showMapPopup(event.latlng, html, interactive)
  loadRightPanelData('Warehouse', layer.elz_node, layer.elz_node.properties.id, false)
}

/**
 * Создает новый склад
 * @param data - данные склада
 * @param shape_node - нода шепа из которого создается
 */
export const createWarehouseOnServer = (data, shape_node) => {
  (async () => {
    const dispatch = window.elz_dispatch;
    try {
      const state = window.store.getState().warehousesReducer;
      const res = await otherDataAPI.warehouse.create(data)
      const node = createWarehouseFromFeature(res.data, state.layersGroup)
      hideShapeByNode(shape_node)
      dispatch(setWarehousesData([...state.warehouses, node]))
      dispatch(setPlotDataAttr({
        showCreateWarehouseModal: false,
        showUpdateWarehouseModal: false,
        selectedWarehouse: null
      }))
      loadRightPanelData('Warehouse', node, node.properties.id)
      dispatch(setSnack('success', 'Склад создан'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  })()
}

/**
 * Обновляет информацию о складе на сервере
 * @param node - нода склада, который обновляется
 * @param data - данные для обновления
 */
export const updateWarehouseInfo = (node, data) => {
  (async () => {
    const dispatch = window.elz_dispatch;
    try {
      const id = node.properties.id;
      if (id) {
        const res = await otherDataAPI.warehouse.update(node.properties.id, data)
        node.properties = structuredClone(res.data.properties)
        dispatch(getWarehouseDataThunk(node.properties.id))
        dispatch(setPlotDataAttr({showUpdateWarehouseModal: false}))
        const element = window.store.getState().rightPanelReducer["selectedElement"]
        if (element && element.id === node.properties.id)
          loadRightPanelData('Warehouse', node, node.properties.id)
        dispatch(setSnack('success', 'Данные склада обновлёны'))
      }
    } catch (e) {
      handleErrors(dispatch, e)
    }
  })()
}

/**
 * Обновляет координаты склада
 * @param node - нода склада, который обновляется
 * @param latlngs - новые координаты
 */
export function updateWarehouseCoordinates(node, latlngs) {
  clearCoordinatesDialogInfo()
  const json = {
    geometry: {
      type: "Polygon",
      coordinates: [[]]
    }
  }
  latlngs.forEach(coo => {
    json.geometry.coordinates[0].push([coo.lng, coo.lat])
  })
  json.geometry.coordinates[0].push([latlngs[0].lng, latlngs[0].lat])
  otherDataAPI.warehouse.update(node.properties.id, json).then(() => {
    const dispatch = window.elz_dispatch;
    node.layer.setLatLngs(latlngs)
    const element = window.store.getState().rightPanelReducer["selectedElement"]
    if (element && element.id === node.properties.id)
      loadRightPanelData('Warehouse', node, node.properties.id)
    dispatch(setSnack('success', 'Координаты штабеля обновлены'))
  }).catch(err => {
    const dispatch = window.elz_dispatch;
    handleErrors(dispatch, err)
  })
}

/** Удаляет все склады с карты */
export function deleteAllWarehouseLayersFromMap() {
  const group = window.store.getState().warehousesReducer.layersGroup;
  if (group) {
    group.eachLayer((layer) => {
      layer.off('click')
    })
    group.clearLayers()
  }
}

/**
 * Начальная загрузка складов
 */
export function loadWarehouses() {
  const dispatch = window.elz_dispatch;
  const state = window.store.getState().warehousesReducer;
  if (state.warehouses.length) {
    deleteAllWarehouseLayersFromMap()
    getMap1().removeLayer(state.layersGroup)
  }
  if (!state.loading) {
    dispatch(setWarehousesLoadingState(true, false))
    otherDataAPI.warehouse.getAll().then(res => {
      const whGroup = L.layerGroup()
      const arr = res.data.map(feature => createWarehouseFromFeature(feature, whGroup))
      getMap1().addLayer(whGroup)
      dispatch(setWarehousesGroup(whGroup))
      dispatch(setWarehousesData(arr))
      dispatch(setWarehousesLoadingState(false, null))
    }).catch(err => {
      console.error(err)
      dispatch(setWarehousesLoadingState(false, true))
    })
  }
}

/**
 * Удаляет слой склада с карты
 * @param node - нода склада
 */
function deleteWarehouseFromMap(node) {
  const group = window.store.getState().warehousesReducer.layersGroup;
  node.layer.off('click')
  group.removeLayer(node.layer)
}

/**
 * Полностью удаляет склад
 * @param node - нода склада
 */
export function deleteWarehouse(node) {
  const id = node.properties.id;
  const dispatch = window.elz_dispatch;
    if (id) {
      try {
        const state = window.store.getState().warehousesReducer;
        const ind = state.warehouses.findIndex((item) => item === node)
        if (ind) {
          otherDataAPI.warehouse.deleteById(id).then(() => {
            dispatch(setSnack("success", "Склад удален"))
            deleteWarehouseFromMap(node)
            const arr = [...state.warehouses]
            arr.splice(ind, 1)
            if (state.targetWarehouse === node)
              dispatch(setTargetWarehouse(null))
            dispatch(setWarehousesData(arr, state.layersGroup))
            const element = window.store.getState().rightPanelReducer["selectedElement"]
            if (element && element.id === id)
              dispatch(resetRightPanelData())
          })
        }
      } catch (e) {
        handleErrors(dispatch, e)
      }
  }
}

/**
 * Удаляет все склады с карты и редусера (для выхода)
 */
export function resetAllWarehouses() {
  deleteAllWarehouseLayersFromMap()
  window.elz_dispatch(resetWarehouses())
}
