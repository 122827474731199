export const djangoErrorsParse = (data) => {
    let message = ''
    let getValue = (data) => {
        let keys = Object.keys(data)
        keys.forEach(key => {
            if (Array.isArray(data[key])) {
                data[key].forEach(item => message = message + '* ' + item + '\n')
            } else if (    typeof data[key] === 'object' && data[key] !== null) {
                getValue(data[key])
            } else {message = message + '* ' + data[key] + '\n'}
        })
    }
    getValue(data)
    return message
}
