import React, {useEffect} from 'react';
import {Table, TableContainer} from "@mui/material";
import TableHeadComponent from "../TableComponents/TableHeadComponent/TableHeadComponent";
import {useDispatch, useSelector} from "react-redux";
import {
  getFilteredHarvestingDataThunk,
  getHarvestingDataThunk,
  getMoreHarvestingDataThunk
} from "../../../../redux/reducers/operationalReportReducer";
import TableLoading from "../TableComponents/TableLoading/TableLoading";
import {useInfiniteScroll} from "../../../../customHooks/operationalReport/useInfiniteScroll";
import TableBodyComponent from "../TableComponents/TableBodyComponent/TableBodyComponent";
import {tableContainer} from "../../OperationalReportStyles";

const harvestingTableColumns = [
  {name: 'rowNumber', label: '#', required: false, componentType: '', filterType: ''},
  {name: 'date', label: 'Дата', required: true, componentType: 'DatePicker', filterType: 'dates'},
  {name: 'plot', label: 'Деляна', required: true, componentType: 'Autocomplete', filterType: 'list'},
  {name: 'machine', label: 'Техника', required: true, componentType: 'Autocomplete', filterType: 'list'},
  {name: 'specie', label: 'Порода', required: false, componentType: 'Autocomplete', filterType: 'list'},
  {name: 'volume', label: `Объем `, required: true, componentType: 'TextField',
    inputProps: {max: 1000000, min: 0, step: 1}, type: 'number', filterType: 'range'},
  {name: 'operator', label: 'Оператор учета', required: false, componentType: 'Typography', filterType: 'list'},
]

const Harvesting = ({iAmEditor}) => {
  const dispatch = useDispatch();
  const operationalReportState = useSelector(state => state.operationalReportReducer)
  const {harvestingData, isHarvestingDataLoading, harvestingTableFilters} = operationalReportState

  //эффект отслеживает изменение фильтров и если они изменились то запрашивает отфильтрованные данные,
  // если фильтров нет то запрашивает все данные
  useEffect(() => {
    Object.keys(harvestingTableFilters).length
      ? dispatch(getFilteredHarvestingDataThunk())
      : dispatch(getHarvestingDataThunk())
  }, [harvestingTableFilters])


  /* используем кастомный хук useInfiniteScroll для подгрузки данных в таблицу при достижении конца скролла,
  переменную scrollRef добавляем к скроллируемому элементу как ref. */
  const scrollRef = useInfiniteScroll(() => {
    dispatch(getMoreHarvestingDataThunk());
  }, harvestingData?.next)

  return (
    <TableContainer sx={tableContainer} ref={scrollRef}>
      <Table size={'small'} stickyHeader>
        <TableHeadComponent columns={harvestingTableColumns} dataType={'harvestingData'} iAmEditor={iAmEditor}/>
        {isHarvestingDataLoading
          ?
            <TableLoading tableColumns={harvestingTableColumns}/>
          :
            <TableBodyComponent tableColumns={harvestingTableColumns} dataType={'harvestingData'}
                                tableData={harvestingData} iAmEditor={iAmEditor}
            />
        }
      </Table>
    </TableContainer>
  )
}

export default Harvesting;
