import React, {useState} from 'react';
import IconButton from "@mui/material/IconButton";
import {grouppingSettingsString, grouppingString} from "../../../Main/Map/Common/Strings_RU";
import SettingsIcon from "@mui/icons-material/Settings";
import {Button, ListItemIcon, Menu, MenuItem, Typography} from "@mui/material";
//import {cuttingsAreasTemplate} from "../../../Main/Map/Layers/CuttingsAreas";
import CheckIcon from "@mui/icons-material/Check";
import {planetablesGroupingReducer, setPlanetablesGroupingAttr} from "../../../../redux/reducers/planetablesGroupingReducer";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const filtersNamesMap = {
  'rental_contract': 'Рабочая область',
  'region': 'Регион',
  'forestry': 'Лесничество',
  'district_forestry': 'Участковое лесничество',
}

const GroupingMenu = ({selectedGroupslist}) => {
  const dispatch = useDispatch()
  const selectedGroups = useSelector(state => state.planetablesGroupingReducer.selectedGroups)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleItemClick = (item) => {
    if (selectedGroupslist.indexOf(item) > -1) {
      dispatch(setPlanetablesGroupingAttr(
        {selectedGroups: {...selectedGroups, [item]: false}}))
    } else {
      dispatch(setPlanetablesGroupingAttr(
        {selectedGroups: {...selectedGroups, [item]: true}}))
    }

  }

  return (
    <Box id={'planetablesFilter'}>
      <Button
        color={'info'}
        style={{textTransform: 'none', paddingLeft: '0'}}
        size={'small'}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        <Typography>{grouppingString}</Typography>
        <ArrowDropDownIcon fontSize={'small'}/>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(filtersNamesMap).map(item => (
          <MenuItem onClick={() => handleItemClick(item)} key={item}>
            <ListItemIcon>
              {selectedGroupslist.indexOf(item) > -1 &&
                <CheckIcon/>
              }
            </ListItemIcon>
            {filtersNamesMap[item]}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default GroupingMenu;