import {
  deleteQuarterPoint, setPlanetableSvgAttr
} from "../../../../redux/reducers/planetableSvgReducer";
import {dragLayerByKeys, rotateLayer, scaleLayer} from "./editFigureFunctions";

//обработчик нажатия клавиш
export const keydown = (e, selectedSvgFigure, mode, layerFromMap, dispatch) => {
  dispatch(setPlanetableSvgAttr({currentlyPressedButton: e.code}))
  const layerType = layerFromMap.type
  switch (e.code) {
    case "KeyQ":
      if (mode === 'layer') {
        rotateLayer(layerFromMap, 'left', dispatch, 0.25)
      }
      break
    case "KeyE":
      if (mode === 'layer') {
        rotateLayer(layerFromMap, 'left', dispatch, -0.25)
      }
      break
    case "KeyW":
      if (mode === 'layer') {
        dragLayerByKeys(0, -1, dispatch)
      }
      break
    case "KeyS":
      if (mode === 'layer') {
        dragLayerByKeys(0, +1, dispatch)
      }
      break
    case "KeyA":
      if (mode === 'layer') {
        dragLayerByKeys(-1, 0, dispatch)
      }
      break
    case "KeyD":
      if (mode === 'layer') {
        dragLayerByKeys(1, 0, dispatch)
      }
      break
    case "KeyR":
      if (mode === 'layer') {
        scaleLayer(layerFromMap, e, dispatch, 0.5)
      }
      break
    case "KeyF":
      if (mode === 'layer') {
        scaleLayer(layerFromMap, e, dispatch, -0.5)
      }
      break
  }
}