import {
  employeesListPageIntroTourSteps,
  filtersIntroTourSteps,
  MapIntroTourSteps, organizationPageIntroTourSteps, planetableIntroTourSteps,
  plotsIntroTourSteps, printIntroTourSteps,
  projectsIntroTourSteps
} from "../../utils/IntroTourSteps";

const SET_INTRO_TOUR_ATTR = 'SET_INTRO_TOUR_ATTR'

const initialState = {
  mainPageIntroTour: {
    steps: MapIntroTourSteps,
    status: false
  },
  projectsIntroTour: {
    steps: projectsIntroTourSteps,
    status: false
  },
  plotsIntroTour: {
    steps: plotsIntroTourSteps,
    status: false
  },
  filtersIntroTour: {
    steps: filtersIntroTourSteps,
    status: false
  },
  printIntroTour: {
    steps: printIntroTourSteps,
    status: false
  },
  organizationPageIntroTour: {
    steps: organizationPageIntroTourSteps,
    status: false
  },
  employeesListPageIntroTour: {
    steps: employeesListPageIntroTourSteps,
    status: false
  },
  planeTableIntroTour: {
    steps: planetableIntroTourSteps,
    status: false
  }
}

// редьюсер для всех форм интерактивных туров
export const introToursReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTRO_TOUR_ATTR: {
      return { ...state, [action.tour]: {...action.payload} };
    }
    default:
      return state;
  }
}

export const setIntroTourAttr = (payload, tour) => ({type: SET_INTRO_TOUR_ATTR, payload, tour});
