import React from "react";
import {Stack, Table, TableBody, TableCell, TableRow, Typography,} from "@mui/material";

import {blockTitle} from "../rightPanelStyles";

const GeoData = ({shapeData}) => {
    return (
        <>
            <Typography style={blockTitle}>Географическая справка</Typography>
            <Stack spacing={2}>
                <Table size={'small'} sx={{p: 0, '& .MuiTableCell-root': {borderBottom: 'none', paddingLeft: '0'}}}>
                    <TableBody>
                        <>
                            <TableRow>
                                <TableCell>Регион</TableCell>
                                <TableCell>{shapeData?.region?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Лесничество</TableCell>
                                <TableCell>{shapeData?.forestry?.name || "-"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Уч. Лесничество</TableCell>
                                <TableCell>{shapeData?.district_forestry?.name || "-"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Квартал</TableCell>
                                <TableCell>{shapeData?.quarter_number || "-"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Урочище</TableCell>
                                <TableCell>{shapeData?.unique_location?.name || "-"}</TableCell>
                            </TableRow>
                        </>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
};

export default GeoData;
