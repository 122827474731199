import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {deleteQuartersLayerThunk, setPlanetableSvgAttr,} from "../../../../redux/reducers/planetableSvgReducer";
import {useDispatch, useSelector} from "react-redux";
import {IconButton} from "@mui/material";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {setPlanetablesAttr} from "../../../../redux/reducers/planetablesReducer";
import {hideMapDialog, showMapDialog} from "../../../Main/Map/Common/Dialog/DialogReducer";
import {toolbarButton} from "../../PlanetabesPageSyles";

const ModesButtons = ({iAmEditor}) => {
  const dispatch = useDispatch()
  const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)
  const layerFromMap = useSelector(state => state.planetableSvgReducer.layerFromMap)
  const deleteDialogButtons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}

  const deleteLayerButtonHandler = () => {
    dispatch(showMapDialog('warning', "Удаление кварталов", "Вы уверены что хотите удалить " +
      "все кварталы с планшета без возможности восстановления?", deleteDialogButtons,
      (e) => {
        dispatch(hideMapDialog())
        if (e === 'Да') {
          dispatch(deleteQuartersLayerThunk(selectedPlanetable))
        }
      }))
  }

  const editLayerButtonHandler = () => {
    const planetableSvg = document.getElementById('planetableSvg')
    planetableSvg.focus()
    dispatch(setPlanetableSvgAttr({
      layerFromMap:
        {
          type: 'quarters',
          layerObjects: selectedPlanetable.quarters_px,
          layerRotationAngle: selectedPlanetable.quarters_px[0].rotation_angle
        },
      showLoadLayerModal: false, mode: 'layer'
    }))
  }

  const openLoadLayerModalButtonHandler = () => {
    if (selectedPlanetable.quarters_px.length) {
      dispatch(showMapDialog('warning', "Загрузить слой", "При загрузке нового слоя, текущий" +
        " сохраненный слой будет удален. Продолжить?", deleteDialogButtons,
        (e) => {
          dispatch(hideMapDialog())
          if (e === 'Да') {
            dispatch(deleteQuartersLayerThunk(selectedPlanetable, true))
          }
        }))
    } else {
      dispatch(setPlanetableSvgAttr({showLoadLayerModal: true}))
    }
  }

  return (
    <Stack direction={'row'} spacing={1} sx={{alignItems: 'center',}}>
      <Button
        size={'small'}
        sx={toolbarButton}
        variant="outlined"
        disabled={!iAmEditor || !selectedPlanetable || !!layerFromMap.layerObjects.length}
        onClick={() => openLoadLayerModalButtonHandler()}
      >
        {'Загрузить кварталы'}
      </Button>
      <Button
        size={'small'}
        sx={toolbarButton}
        variant="outlined"
        fullWidth
        disabled={!iAmEditor || !selectedPlanetable || !selectedPlanetable.quarters_px.length || !!layerFromMap.layerObjects.length}
        onClick={deleteLayerButtonHandler}
      >
        {'Удалить кварталы'}
      </Button>
      <Button
        size={'small'}
        sx={toolbarButton}
        variant="outlined"
        fullWidth
        disabled={!iAmEditor || !selectedPlanetable || !selectedPlanetable.quarters_px.length || !!layerFromMap.layerObjects.length}
        onClick={editLayerButtonHandler}
      >
        {'Редактировать'}
      </Button>
      <IconButton
        title={'Данные планшета'}
        disabled={!selectedPlanetable}
        onClick={() =>
          dispatch(setPlanetablesAttr({
            showUploadPlanetableModal:
              {status: true, planetable: selectedPlanetable, viewMode: true}
          }))}
      >
        <DescriptionOutlinedIcon/>
      </IconButton>
    </Stack>
  )
}

export default ModesButtons;
