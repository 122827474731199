import React from 'react';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {ORIcon} from "../../../OperationalReportStyles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const FilterIcon = ({columnName, filterType, tableFilters}) => {
  let isPointFromFilter = tableFilters?.store_from_id || tableFilters?.plot_from_id
  let isPointToFilter = tableFilters?.store_to_id || tableFilters?.plot_to_id
  let isDateFilter = tableFilters?.date_range_after || tableFilters?.date_range_before
  let isVolumeFilter = tableFilters?.volume__lt || tableFilters?.volume__gt

  switch (columnName) {
    case 'plot':
    case 'machine':
    case 'specie':
    case 'operator':
      if (tableFilters[columnName] && filterType === 'list') return <FilterAltIcon sx={ORIcon} />
      return <FilterAltOutlinedIcon sx={ORIcon} />
    case 'point_from':
      if (isPointFromFilter) return <FilterAltIcon sx={ORIcon} />
      return <FilterAltOutlinedIcon sx={ORIcon} />
    case 'point_to':
      if (isPointToFilter) return <FilterAltIcon sx={ORIcon} />
      return <FilterAltOutlinedIcon sx={ORIcon} />
    case 'volume':
      if (isVolumeFilter) return <FilterAltIcon sx={ORIcon} />
      return <FilterAltOutlinedIcon sx={ORIcon} />
    case 'date':
      if (isDateFilter) return <FilterAltIcon sx={ORIcon} />
      return <FilterAltOutlinedIcon sx={ORIcon} />
  }
};

export default FilterIcon;