import {
  SET_TARGET_VIOLATION,
  SET_VIOLATION_DATA,
} from "./actionTypes";

export const setTargetViolation = (violation) => ({
  type: SET_TARGET_VIOLATION,
  violation,
})

export function setViolationData(data) {
  return {
    type: SET_VIOLATION_DATA,
    data: data,
  }
}
