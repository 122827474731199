// модуль с функциями для работы со слоем загруженным с карты
import {dragLayerAction, setPlanetableSvgAttr} from "../../../../redux/reducers/planetableSvgReducer";
import {figureCenterPointCalc} from "../../../../svgComponentsHelpersFunctions/figureCenterPointCalc";
import {rotate} from "../../../../svgComponentsHelpersFunctions/svgComponentsHelpersFunctions";
import {scalePolygon} from "../../../../svgComponentsHelpersFunctions/scalePolygon";

export function rotateLayer(layerFromMap, direction, dispatch, angle) {
  const layerObjects = layerFromMap.layerObjects
  let allPoints = layerObjects.reduce((acc, obj) => [...acc, ...obj.coordinates_pxl], [])
  let centerLayerPoint = figureCenterPointCalc(allPoints)
  let newLayerObjects = layerObjects.map(obj => (
    {
      ...obj,
      coordinates_pxl: rotate(obj.coordinates_pxl, direction, centerLayerPoint, angle)
    }
  ))
  let newLayer = {
    ...layerFromMap,
    layerObjects: newLayerObjects,
    layerRotationAngle: layerFromMap.layerRotationAngle + angle
  }
  dispatch(setPlanetableSvgAttr({layerFromMap: newLayer}))
}

export function scaleLayer(layerFromMap, e, dispatch, percent) {
  const polygons = layerFromMap.layerObjects.map(obj => obj.coordinates_pxl)
  const newPolygons = polygons.map((polygon) =>
    scalePolygon(polygon, percent)
  )
  let newLayerObj = {
    ...layerFromMap,
    layerObjects: layerFromMap.layerObjects.map((obj, index) => {
      return {
        ...obj,
        coordinates_pxl: newPolygons[index]
      }
    })
  }
  dispatch(setPlanetableSvgAttr({layerFromMap: newLayerObj}))
}


export function dragLayerByMouse (e, setDragStartingPoint, dragStartingPoint, zoom, svg, dispatch, scale)  {
  let dx = dragStartingPoint.clientX ? (e.clientX - dragStartingPoint.clientX) / (zoom / 100) / scale : 0;
  let dy = dragStartingPoint.clientY ? (e.clientY - dragStartingPoint.clientY) / (zoom / 100) / scale : 0;
  setDragStartingPoint({
    scrollLeft: svg.scrollLeft,
    scrollTop: svg.scrollTop,
    clientX: e.clientX,
    clientY: e.clientY,
  })
  dispatch(dragLayerAction(dx, dy))
}

export function dragLayerByKeys (dx, dy, dispatch)  {
  dispatch(dragLayerAction(dx, dy))
}