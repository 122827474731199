import {useDispatch, useSelector} from "react-redux";
import {Button, Tooltip} from "@mui/material";
import React from "react";
import {
  addOutline,
  disableModeAndUndoChanges,
  setPhotoAttr,
  undoSvgChanges
} from "../../../../../redux/reducers/stockpilePhotoReducer";
import ToolbarBlockContainer from "../../ToolbarBlockContainer/ToolbarBlockContainer";
import {toolbarButtonStyle} from "../../toolbarStyles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function SelectModeButtons() {
  let dispatch = useDispatch()
  const mode = useSelector(state => state.stockpilePhotoReducer.mode)
  const creationMode = useSelector(state => state.stockpilePhotoReducer.creationMode)
  const layers = useSelector(state => state.stockpilePhotoReducer.layers)
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const outline_selected_index = useSelector(state => state.stockpilePhotoReducer.outline_selected_index)
  const selectedFigure = outline_selected_index ? selectedPhoto.figures[outline_selected_index] : null
  const ruler_selected_index = useSelector(state => state.stockpilePhotoReducer.ruler_selected_index)


  const createRulerButtonHandler = () => {
    if (mode === 'ruler') {
      dispatch(undoSvgChanges())
      dispatch(setPhotoAttr({ruler_selected_index: null, mode: 'view', creationMode: null}))
      return
    }
    if (mode === 'outline') {
      dispatch(disableModeAndUndoChanges(selectedPhoto))
      dispatch(setPhotoAttr(
        {selectedFigure: null, outline_selected_index: null, creationMode: 'ruler',
          mode: 'ruler', ruler: [], ruler_selected_index: 0}))
      return
    }
    if (mode !== 'ruler') {
      dispatch(setPhotoAttr({mode: 'ruler', creationMode: 'ruler', ruler: [], ruler_selected_index: 0}))
    }
  }

  const createOutlineButtonHandler = () => {
    if (mode === 'outline') {
      dispatch(disableModeAndUndoChanges(selectedPhoto))
      dispatch(setPhotoAttr({selectedFigure: null, outline_selected_index: null, creationMode: null, mode: 'view'}))
      return
    }
    if (mode === 'view') {
      dispatch(setPhotoAttr({mode: 'outline', creationMode: 'outline'}))
      dispatch(addOutline())
      return
    }
    if (mode === 'ruler') {
      dispatch(disableModeAndUndoChanges(selectedPhoto))
      dispatch(setPhotoAttr({ruler_selected_index: null, mode: 'outline', creationMode: 'outline'}))
      dispatch(addOutline())
    }
  }

  const isDisabledCreateRulerButton = !layers.ruler || (ruler_selected_index !== null && creationMode !== 'ruler')
  const isDisabledCreateOutlineButton = !layers.outline || selectedFigure || !selectedPhoto.ruler.length

  return (
    <ToolbarBlockContainer title={'Создать'}>
      <Button size={'small'}
              disabled={!!isDisabledCreateRulerButton}
              variant={(mode === 'ruler' && creationMode === 'ruler') ? 'contained' : 'outlined'}
              sx={{...toolbarButtonStyle, width: 150}}
              onClick={createRulerButtonHandler}
      >Ширина замера</Button>
      <Button size={'small'}
              disabled={!!isDisabledCreateOutlineButton}
              variant={(mode === 'outline' && creationMode === 'outline') ? 'contained' : 'outlined'}
              sx={{...toolbarButtonStyle, width: 100}}
              onClick={createOutlineButtonHandler}
      >Область</Button>
      {!selectedPhoto.ruler.length &&
        <Tooltip title={'Для создания области необходимо задать ширину замера'}>
          <InfoOutlinedIcon sx={{color: 'primary.light', fontWeight: 300, ml: 1}}/>
        </Tooltip>
      }
    </ToolbarBlockContainer>
  )
}

export default SelectModeButtons