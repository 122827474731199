import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {hideMapDialog, showMapDialog} from "../../../../../../../../Main/Map/Common/Dialog/DialogReducer";
import {
  deleteAllPhotosThunk,
  deleteStockpileThunk,
  setStockpilesAttr
} from "../../../../../../../../../redux/reducers/stockpilesReducer";
import {deletingStockpile} from "../../../../../../../../Main/Map/Common/Strings_RU";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ClearIcon from '@mui/icons-material/Clear';
import {treeItemButtonIconSize} from "../../../../../../../../Main/Map/Common/Styles";

const StockpileContextMenu = ({stockpile, plot}) => {
  useSelector(state => state.stockpilesReducer.isUpdateStockpile)
  const dispatch = useDispatch()
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}

  const deleteStockpileHandler = (e) => {
    e.stopPropagation()
    dispatch(showMapDialog('warning', deletingStockpile,
      `Вы уверены, что хотите удалить штабель "${stockpile.name}"? Эта операция необратима.`, buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        dispatch(deleteStockpileThunk(stockpile.id, plot.id))
      }
      setMenuAnchor(null)
    }))
  }

  const updateStockpileButtonHandler = (e) => {
    e.stopPropagation()
    dispatch(setStockpilesAttr({isUpdateStockpile: {status: true, stockpileId: stockpile.id}}))
  }

  const openMenuHandler = (e) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }

  const deleteAllPhotosHandler = (e) => {
    e.stopPropagation()
    dispatch(showMapDialog('warning', "Удалить все фото штабеля", "Вы уверены что " +
      "хотите удалить все фото выбранного штабеля без возможности восстановления?", buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        dispatch(deleteAllPhotosThunk(stockpile))
      }
      setMenuAnchor(null)
    }))
  }
  return (
    <>
      <IconButton
        style={{padding: 0}}
        onClick={openMenuHandler}
      >
        <MoreVertIcon fontSize={'small'}/>
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <MenuItem
          onClick={updateStockpileButtonHandler}
        >
          <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>Переименовать</Typography>
        </MenuItem>
        <MenuItem
          onClick={deleteAllPhotosHandler}
        >
          <ClearIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>Очистить</Typography>
        </MenuItem>
        <MenuItem
          onClick={deleteStockpileHandler}
        >
          <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>Удалить</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default StockpileContextMenu;
