import React from 'react';
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {getReferenceThunk} from "../../../../redux/reducers/referencesReducer";
import {useDispatch} from "react-redux";


const LocationPlotField = ({options, label, referenceLoading, onChangeFunc, reference, referenceData, disabled, value}) => {
  const dispatch = useDispatch()
  return (
    <Autocomplete
      disablePortal
      size={"small"}
      disabled={disabled}
      options={options}
      value={value || null}
      onOpen={() => dispatch(getReferenceThunk(reference, referenceData && referenceData))}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id
      }
      getOptionLabel={(option) => option?.name || ''}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {referenceLoading.status && referenceLoading.reference
                  === reference ? <CircularProgress sx={{color: 'primary.dark'}} size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
      onChange={onChangeFunc}
    />
  );
};

export default LocationPlotField;