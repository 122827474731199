import {useEffect} from "react";
import {jivoTittleString} from "../Strings_RU";
import '../css/bitrix.css';
import {useDispatch, useSelector} from "react-redux";
import {setBitrixState} from "./bitrixReducer";

export function BitrixButton() {
  const dispatch = useDispatch()
  const state = useSelector(state => state.bitrixReducer)

  function widgetEventsHandler(data) {
    switch (data.type) {
      case 'widgetOpen':
        dispatch(setBitrixState({widgetOpened: true}))
        break;
      case 'widgetClose':
        dispatch(setBitrixState({widgetOpened: false}))
        break;
    }
  }

  useEffect(() => {
    if (!state.loaded && !state.loading && !state.script) {

      window.addEventListener('onBitrixLiveChat', function (event) {
        const widget = event.detail.widget;

        widget.subscribe({
          type: window["BX"]["LiveChatWidget"]["SubscriptionType"].every,
          callback: widgetEventsHandler,
        })
        dispatch(setBitrixState({loading: false, loaded: true, widget: widget}))
      })

      const script = document.createElement('script')
      dispatch(setBitrixState({script: script, loading: true, loaded: false}))
      script.src = process.env.REACT_APP_BITRIX_WIDGET_URL + '?' + (Date.now() / 60000 | 0)
      script.async = true;
      document.body.appendChild(script)
    }
  }, [state.script])

  function clickHandler() {
    try {
      if (state.widgetOpened) {
        window["BXLiveChat"].close()
      } else {
        window["BXLiveChat"].open()
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (state.loading || !state.loaded || !state.script)
    return null;

  return (
    <div
      className={'bitrix_custom_widget'}
      id={'bitrix_custom_widget'}
      title={jivoTittleString}
      onClick={clickHandler}
    />
  )
}
