import {setSelectedFigure} from "./outlineModeClickHandler";
import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";

export const viewModeClickHandler = (svgElementName, svgElementIndex, stockpilePhotoState, dispatch) => {
  const {mode, selectedPhoto} = stockpilePhotoState

  switch (svgElementName) {
    case 'outline':

      const selectedFigure = selectedPhoto.figures[svgElementIndex]
      dispatch(setPhotoAttr({mode: 'outline'}))
      setSelectedFigure(dispatch, svgElementIndex, selectedFigure)
      break

    case 'ruler':
      dispatch(setPhotoAttr({mode: 'ruler', ruler_selected_index: svgElementIndex, ruler_viewed_index: null}))
      break
  }
}