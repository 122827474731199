import {useDispatch, useSelector} from "react-redux";
import {IconButton, Tooltip, Typography} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import React from "react";
import CutFigureButtons from "./CutFigureButtons";
import {
  addOutline,
  deleteFigureThunk,
  disableModeAndUndoChanges,
  patchFigureThunk,
  saveFigureThunk,
  setPhotoAttr
} from "../../../../../redux/reducers/stockpilePhotoReducer";
import SaveIcon from "@mui/icons-material/Save";
import {hideMapDialog, showMapDialog} from "../../../../Main/Map/Common/Dialog/DialogReducer";
import ToolbarBlockContainer from "../../ToolbarBlockContainer/ToolbarBlockContainer";
import {toolbarIconButtonStyle, toolbarIconStyle} from "../../toolbarStyles";

function EditFigureButtons() {
  let dispatch = useDispatch()
  const stockpilePhotoState = useSelector(state => state.stockpilePhotoReducer)
  const {cuttingMode, layers, selectedPhoto, outline_selected_index, figureParameters,
    mode, outlines} = stockpilePhotoState
  const selectedFigure = selectedPhoto.figures[outline_selected_index]
  const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
  const cutOutlineButtonHandler = () => {
    dispatch(setPhotoAttr({cuttingMode: !cuttingMode, cutPoints: []}))
  }

  const createOutlineHandler = () => {
    dispatch(addOutline())
    dispatch(setPhotoAttr({creationMode: 'outline'}))
  }

  const undoButtonHandler = () => {
    dispatch(disableModeAndUndoChanges(selectedPhoto))
  }

  const saveOutlineButtonHandler = () => {
    if (selectedFigure)  {
      dispatch(patchFigureThunk(selectedPhoto, {
        ...selectedFigure,
        ...figureParameters,
        points: outlines[outline_selected_index],
      }))
    } else {
      dispatch(saveFigureThunk(figureParameters, outlines[outline_selected_index], selectedPhoto))
    }
  }

  const deleteOutlineHandler = () => {
    dispatch(showMapDialog('warning', 'Удаление области', 'Вы уверены что хотите удалить ' +
      'выбранную область, без возможности восстановления?', buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        dispatch(deleteFigureThunk(selectedFigure.id, selectedPhoto))
      }
    }))
  }

  return (
    <ToolbarBlockContainer title={'Область'}>
          <IconButton
            sx={toolbarIconButtonStyle}
            disabled={!layers.outline || outline_selected_index === null || cuttingMode}
            title={'Сохранить область'}
            onClick={saveOutlineButtonHandler}>
            <SaveIcon sx={{...toolbarIconStyle}}/>
          </IconButton>
          <IconButton
            disabled={(cuttingMode || outline_selected_index === null)}
            sx={toolbarIconButtonStyle}
            title={'Отменить'}
            onClick={undoButtonHandler}
          >
            <RestartAltIcon sx={toolbarIconStyle}/>
          </IconButton>
          <IconButton
            sx={toolbarIconButtonStyle}
            title={'Удалить область'}
            disabled={!layers.outline || !selectedFigure}
            onClick={deleteOutlineHandler}
          >
            <DeleteForeverIcon sx={toolbarIconStyle}/>
          </IconButton>
          <IconButton
            sx={{...toolbarIconButtonStyle, color: cuttingMode && 'primary.dark'}}
            disabled={!layers.outline || !selectedFigure}
            onClick={cutOutlineButtonHandler}
          >
            <Tooltip title={<React.Fragment><Typography sx={{fontSize: 16}}>
              Задайте две точки по которым будет произведено разделение области
            </Typography></React.Fragment>}>
              <ContentCutIcon sx={toolbarIconStyle}/>
            </Tooltip>
          </IconButton>
          {cuttingMode &&
            <CutFigureButtons sx={toolbarIconStyle}/>
          }
    </ToolbarBlockContainer>

  )
}

export default EditFigureButtons
