import Box from "@mui/material/Box";
import {Typography, useTheme} from "@mui/material";
import Stack from "@mui/material/Stack";
import {textStyle, titleStyle, topBlockStyle} from "../organizationPageStyles";
import {useSelector} from "react-redux";
import {getRoleName, getTariff} from "../../Main/Map/Common/tariffs";

const PersonalInfo = () => {
  const userInfo = useSelector(state => state.userReducer.userInfo)
  //const orgName = useSelector(state => state.organizationInfoReducer.organizationData.name)
  const orgName = useSelector((state) => state.organizationPageReducer.organizationData.name)
  const theme = useTheme()
  const {first_name, last_name, patronymic, post} = userInfo || {}

  if (!userInfo) return ''

  return (
    <Box sx={{...topBlockStyle}} id={'personalInfo'}>
      <Stack direction={'row'} spacing={2}>
        <Typography sx={{...titleStyle}}>УЧЕТНАЯ ЗАПИСЬ</Typography>
        <Stack>
          <Typography sx={{...textStyle, color: 'black'}}>
            {`${last_name || ''} ${first_name || ''} ${patronymic || ''}
              `}
          </Typography>
          <Typography sx={{...textStyle, color: 'black'}}>{post}</Typography>
          <Typography sx={{...textStyle, color: 'black'}}>{orgName}</Typography>
          <Typography sx={{color: theme.palette.primary.main, fontWeight: 'bold'}}>{getRoleName(getTariff())}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default PersonalInfo;
