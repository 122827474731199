import {Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme} from "@mui/material";
import {
  cellStyle,
  headerCellStyle,
  headerCellTextStyle,
  tableContainerStyle,
  tableStyle
} from "../../rightPanelStyles";
import {
  caNo1Cdata,
  parameterString,
  valueString
} from "../../../Map/Common/Strings_RU";
import Box from "@mui/material/Box";

const ShortInfo = ({properties_1c, properties1CMap}) => {
  const theme = useTheme()

  function get1CPropasTable() { //таблица данных properties_1c
    const arr = []
    let ind = 0;
    if (properties_1c)
      Object.keys(properties1CMap).map(key => {
        if (properties_1c[key] !== undefined && properties_1c[key] !== null) {
          let data = properties_1c[key]
          arr.push(
            <TableRow key={ind++}>
              <TableCell style={cellStyle}>{properties1CMap[key].name}</TableCell>
              <TableCell style={cellStyle}>{data} {properties1CMap[key].suffix}</TableCell>
            </TableRow>
          )
        }
      })

    if (arr.length)
      return (
        <div style={tableContainerStyle}>
          <Table style={tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>
                  <div style={headerCellTextStyle}>{parameterString}</div>
                </TableCell>
                <TableCell style={headerCellStyle}>
                  <div style={headerCellTextStyle}>{valueString}</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arr}
            </TableBody>
          </Table>
        </div>
      )
    else
      return (
        <Box style={tableContainerStyle}><Typography color={theme.palette.warning.main}>{caNo1Cdata}</Typography></Box>
      )
  }

  return (
    <>
      {get1CPropasTable()}
    </>
  )
}

export default ShortInfo;
