import {FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';

export function Filter({onChange, filter, disabled}) {

  const handleGroupingChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <FormControl size="small" disabled={disabled}>
          <InputLabel>Группировать по</InputLabel>
          <Select
            variant="outlined"
            value={filter}
            label="Группировать по"
            onChange={handleGroupingChange}
          >
            <MenuItem value="volumes_by_species">Порода дерева</MenuItem>
            <MenuItem value="volumes_by_species_and_product_name">Имя продукта</MenuItem>
            <MenuItem value="volumes_by_product_name">Группа продукции</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
