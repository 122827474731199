import React from 'react';
import {getCuttingAreas} from './CuttingsAreas.js'
import {Autocomplete, Checkbox, Divider, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {fireFilter} from "../../../Map/Filters/FiltersReducer";
import Box from "@mui/material/Box";
import {allCAsRemoveFilters, allCAsString} from "../../../Map/Common/Strings_RU";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export function CAPropertyFilter(props) { // поле фильтра по свойствам деляны

  const cas = getCuttingAreas()
  const filterName = props.filter; // имя поля (фильтра)
  const name = props.name; // название
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filterReducer.filters.ca)
  const data = structuredClone(cas.filters[filterName])

  if (!data.length)
    return null;

  const allIsSelected = filters[filterName].length === cas.filters[filterName].length;

  if (filterName === 'status')
    if (allIsSelected)
      data.unshift(allCAsRemoveFilters)
    else
      data.unshift(allCAsString)
  let ind = 0;
  return (
    <Box style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
      <Box style={{marginTop: '1vh', width: '100%'}}>
        <Autocomplete
          multiple
          clearOnBlur
          clearOnEscape
          disableCloseOnSelect
          disablePortal
          fullWidth
          ChipProps={{color: 'primary', variant: 'outlined', style: {maxWidth: '120px'}}}
          size='small'
          id="cp-ca-status-filter"
          value={filters[filterName]}
          isOptionEqualToValue={(option, value) => option === value }
          options={data}
          onChange={(e, v) => {
            let arr = [...v]
            if (filterName === 'status') {
              let i = v.indexOf(allCAsString)
              if (i > -1)
                arr = structuredClone(cas.filters[filterName])
              else {
                i = v.indexOf(allCAsRemoveFilters)
                if (i !== -1)
                  arr = []
              }
            }
            dispatch(fireFilter(filterName, arr))
          }}
          renderOption={(props, option) => {
            let checked = filters[filterName].length > 0 && filters[filterName].indexOf(option) > -1;
            const isSelectAllOption = filterName === 'status' && (option === allCAsString || option === allCAsRemoveFilters);
            if (filterName === 'status' && isSelectAllOption)
              checked = option === allCAsRemoveFilters;
            return (
              <React.Fragment key={ind++}>
                <li {...props}>
                    <Checkbox
                      size={'small'}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={checked}
                    />
                  {option}
                </li>
                {isSelectAllOption && <Divider />}
              </React.Fragment>
            )
          }}
          renderInput={(params) => {
            return (
              <TextField {...params} label={name} placeholder={name} variant={'standard'}/>
            )
          }}
        >
        </Autocomplete>
      </Box>
    </Box>
  )
}
