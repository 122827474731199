import React, {useEffect} from 'react';
import {Table, TableContainer} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
  getFilteredRemovalDataThunk,
  getMoreRemovalDataThunk,
  getRemovalDataThunk
} from "../../../../redux/reducers/operationalReportReducer";
import TableHeadComponent from "../TableComponents/TableHeadComponent/TableHeadComponent";
import TableLoading from "../TableComponents/TableLoading/TableLoading";
import {useInfiniteScroll} from "../../../../customHooks/operationalReport/useInfiniteScroll";
import TableBodyComponent from "../TableComponents/TableBodyComponent/TableBodyComponent";
import {tableContainer} from "../../OperationalReportStyles";

const removalTableColumns = [
  {name: 'rowNumber', label: '#', required: false, componentType: '', filterType: ''},
  {name: 'date', label: 'Дата', required: true, componentType: 'DatePicker', filterType: 'dates'},
  {name: 'point_from', label: 'Пункт вывозки', required: true, componentType: 'Autocomplete', filterType: 'points'},
  {name: 'point_to', label: 'Пункт доставки', required: true, componentType: 'Autocomplete', filterType: 'points'},
  {name: 'machine', label: 'Техника', required: true, componentType: 'Autocomplete', filterType: 'list'},
  {name: 'specie', label: 'Порода', required: false, componentType: 'Autocomplete', filterType: 'list'},
  {name: 'volume', label: `Объем `, required: true, componentType: 'TextField',
    inputProps: {max: 1000000, min: 0, step: 1}, type: 'number', filterType: 'range'},
  {name: 'operator', label: 'Оператор учета', required: false, componentType: 'Typography', filterType: 'list'},
]

const Removal = () => {
  const dispatch = useDispatch();
  const operationalReportState = useSelector(state => state.operationalReportReducer)
  const {removalData, removalTableFilters, isRemovalDataLoading} = operationalReportState

  //эффект отслеживает изменение фильтров и если они изменились  то запрашивает отфильтрованные данные,
  // если фильтров нет то запрашивает все данные
  useEffect(() => {
    Object.keys(removalTableFilters).length
      ? dispatch(getFilteredRemovalDataThunk())
      : dispatch(getRemovalDataThunk())
  }, [removalTableFilters])

  /* используем кастомный хук useInfiniteScroll для подгрузки данных в таблицу при достижении конца скролла,
  переменную scrollRef добавляем к скроллируемому элементу, как ref. */
  const scrollRef = useInfiniteScroll(() => {
    dispatch(getMoreRemovalDataThunk());
  }, removalData?.next)

  return (
    <TableContainer sx={tableContainer} ref={scrollRef}>
      <Table size={'small'} stickyHeader>
        <TableHeadComponent columns={removalTableColumns} dataType={'removalData'} />
        {isRemovalDataLoading
          ? (
            <TableLoading tableColumns={removalTableColumns}/>
          )
          : (
            <TableBodyComponent tableColumns={removalTableColumns} dataType={'removalData'}
                                tableData={removalData}/>
          )
        }
      </Table>
    </TableContainer>
  )
}

export default Removal;
