import React from "react";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import {Tooltip, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {uploadPhotosThunk} from "../../../../../redux/reducers/stockpilesReducer";
import FullScreenLoading from "../../../../Common/FullScreenLoading/FullScreenLoading";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


function DropzoneUploadPhoto() {
  const dispatch = useDispatch()
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const savePhotosLoading = useSelector(state => state.stockpilesReducer.savePhotosLoading)
  const maxNumPhotos = 50
  let dropzoneStyle = {
    marginLeft: 1,
    marginRight: 1,
    border: '3px dashed #9e9e9e',
    borderRadius: 2,
    backgroundColor: '#fafafa',
    color: '#9e9e9e',
    cursor: 'pointer',
    height: '10vh',
    minWidth: '180px',
    maxWidth: '180px',
    display: 'flex',
    flexDirection: 'column',
    alighItems: 'center',
    justifyContent: 'center'
  }

  const handleDrop = acceptedFiles => {
    if (acceptedFiles.length + selectedStockpile.images.length > maxNumPhotos) {
      dispatch(setSnack('error', `В штабеле может быть не более ${maxNumPhotos} фотографий. 
      Сейчас загружено ${selectedStockpile.images.length}шт.`))
    } else {
      dispatch(uploadPhotosThunk(acceptedFiles, selectedStockpile.id))
    }
  }

  return (
    <>
      <Dropzone
        onDrop={handleDrop}
      >
        {({getRootProps, getInputProps}) => (
          <Tooltip title={<React.Fragment><Typography sx={{fontSize: 16}}>
            Загрузите фотографии вашего штабеля в приложение и они автоматически будут обработаны
            нашим нейросетевым алгоритмом. После загрузки, на каждой фотографии необходимо добавить ширину замера
            и данные древесины
          </Typography></React.Fragment>} sx={{fontSize: 18}}>
            <Box sx={dropzoneStyle} {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography
                sx={{fontSize: 16, fontWeight: 500, color: 'primary.dark', display: 'flex', justifyContent: 'center', p: 0}}>
                {`Загрузить фото`}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Dropzone>
      {savePhotosLoading && <FullScreenLoading/>}
    </>
  )
}

export default DropzoneUploadPhoto