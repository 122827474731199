import {blockContainerStyle} from "../organizationPageStyles";
import Box from "@mui/material/Box";
import {Collapse, Typography} from "@mui/material";
import AccountTable from "./AccountTable/AccountTable";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import PaymentButtons from "./PaymentButtons/PaymentButtons";
import NextPayDate from "./NextPayDate/NextPayDate";
import Grid from "@mui/material/Grid";
import {BottomBlockTitle} from "../BottomBlock/BottomBlockTitle";
import {cannotIDo} from "../../Main/Map/Common/tariffs";

const PersonalAccount = ({openedBlock, setOpenedBlock}) => {
  //const currentUser = useSelector(state => state.userReducer.userInfo)

  return (
    <Box sx={{...blockContainerStyle}} id={'personalAccount'} >
      <BottomBlockTitle openedBlock={openedBlock} setOpenedBlock={setOpenedBlock} blockName={'PersonalAccount'}
                        title={'АККАУНТ'}/>
      <Collapse in={openedBlock === 'PersonalAccount'}
                timeout="auto"
                unmountOnExit>
        <Grid container direction={'row'} sx={{pb: 2}}>
          <Grid item xs={6} sx={{borderRight: 'solid 3px lightgrey'}}>
            <AccountTable/>
            <NextPayDate/>
            {cannotIDo.useCorporateTariffAction()
              ?
                <PaymentButtons/>
              :
                <Typography sx={{ml: 2, color: '#555555'}}>Для выставления счета обратитесь в службу клиентской поддержки</Typography>
            }
          </Grid>
          <Grid item xs={6}>
            <PaymentHistory/>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default PersonalAccount;
