import React from 'react';
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {useDispatch, useSelector} from "react-redux";
import {setForestUseReportAttr} from "../../../../../../redux/reducers/forestUseReportReducer";
import {findPolygonCenter} from "../../../../../../svgComponentsHelpersFunctions/svgComponentsHelpersFunctions";

const NewQuarterOperationButtons = () => {
  const dispatch = useDispatch()
  const drawMode = useSelector(state => state.forestUseReportReducer.drawMode)
  const newQuarterPx = useSelector(state => state.forestUseReportReducer.newQuarterPx)

  const saveButtonHandler = () => {
    const newQuarterCenterPoint = findPolygonCenter(newQuarterPx)
    dispatch(setForestUseReportAttr({newQuarterPx: [], quarterPx: newQuarterPx,
      quarterCenterPoint: newQuarterCenterPoint, drawMode: 'view'}))
  }

  const undoButtonHandler = () => {
    dispatch(setForestUseReportAttr({newQuarterPx: []}))
  }

  return (
    <>
      {drawMode === 'drawQuarter' &&
        <Stack direction={'row'}>
          <IconButton
            color={'primary'}
            title={'Сохранить квартал'}
            disabled={newQuarterPx.length < 3}
            onClick={saveButtonHandler}
          >
            <SaveIcon fontSize={'small'}/>
          </IconButton>
          <IconButton title={'Отменить'}
                      color={'primary'}
            disabled={!newQuarterPx.length}
            onClick={undoButtonHandler}
          >
            <RestartAltIcon/>
          </IconButton>
        </Stack>
      }
    </>
  );
};

export default NewQuarterOperationButtons;