import {Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, useTheme} from "@mui/material";
import {overlayButtonStyle, panelButtonStyle} from "../Map/Common/Styles";
import {
  createByCoordinates,
  lineString,
  markerString,
  polygonString,
  projectsLoadGPXString,
  projectsRemoveLastButtonString
} from "../Map/Common/Strings_RU";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
import React, {useState} from "react";
import {
  createFeatureOnServer,
  createNewLine,
  createNewMarker,
  createNewPolygon,
  defaultShapeColor,
  drawStyle,
  getProjects,
  isShapeNode,
  newFeatureTemplate,
  shapeClickHandler,
  showAllNodes,
  svgMarker
} from "../LeftPanel/Context/Projects/ProjectsCommon";
import {setPlotDataAttr} from "../../../redux/reducers/plotDataReducer";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as PolygonIcon} from '../Map/Common/svg/polygon.svg';
import {ReactComponent as LineIcon} from '../Map/Common/svg/line.svg';
import {ReactComponent as MarkerIcon} from "../Map/Common/svg/marker.svg";
import {showCoordinatesEditor} from "../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {getDrawLayerGroup, getMap1} from "../Map/GlobalObjects";
import {clearCoordinatesDialogInfo} from "../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import {refreshProjects, setDrawMode} from "../LeftPanel/Context/Projects/projectsReducer";
import {cancelDrawing, resetAllDrawing} from "../Map/Common/Drawing/drawing";
import {setDrawingMode} from "./Measuring/measuringReducer";
import {flyToShape} from "../Map/Common/fly";

/**
 * Создает ноду и слой по завершению рисования
 * @param e - ивент geoman
 * @returns {object} - нода
 */
export function pmCreateHandler(e) {
  const shape = e.layer.pm['_shape'];
  const drawLayerGroup = getDrawLayerGroup()
  const projects = getProjects()
  let node = structuredClone(newFeatureTemplate)
  if (shape === 'Line') {
    node.name = `Линия №${e.layer._leaflet_id}`;
    node.type = 'Line';
  } else if (shape === 'Polygon') {
    node.name = `Полигон №${e.layer._leaflet_id}`;
    node.type = 'Polygon';
  } else if (shape === 'Marker') {
    node.name = `Маркер №${e.layer._leaflet_id}`;
    node.type = 'Marker';
  }
  if (node.type === 'Marker') {
    node.layer = createNewMarker(e.layer.getLatLng(), defaultShapeColor)
    node.layer.elz_properties = {markerColor: defaultShapeColor, markerSymbol: 'LOCATION_ICON'}
  } else {
    if (node.type === 'Polygon') {
      node.layer = createNewPolygon(e.layer.getLatLngs(), {})
    } else {
      node.layer = createNewLine(e.layer.getLatLngs(), {})
    }
  }
  node.layer.node = node;
  drawLayerGroup.removeLayer(e.layer)
  drawLayerGroup.addLayer(node.layer)
  node.layer.on('click', shapeClickHandler)
  let parent = window.store.getState()['projectsReducer'].currentCollection;
  if (!parent) //если создано из координат, а не рисованием
    parent = projects.selectedNode;
  if (isShapeNode(parent))
    parent = parent.parentNode;
  node.parentNode = parent;
  node.visible = true;
  parent.data.push(node)
  showAllNodes(node)
  cancelDrawing()
  if (!parent.new) {
    createFeatureOnServer(node)
  }
  return node;
}

export const MapDrawingPanel = () => {
  const state = useSelector(state => state.projectsReducer)
  const curTab = useSelector((state) => state.leftDrawerReducer.curTab)
  const projects = getProjects()
  const dispatch = useDispatch()
  const theme = useTheme()
  let selected = projects?.selectedNode;
  const [anchorEl, setAnchorEl] = useState(null)

  const panelStyle = {
    positions: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }

  if (curTab !== 'projects')
    return <div style={{flex: 1}}></div>;

  if (selected && isShapeNode(selected))
    selected = selected.parentNode;

  let drawb = selected && (selected.type === 'folder' || selected.type === 'workspace') && selected.id;
  let lineButtonEnable = false;
  let polyButtonEnabe = false;
  let markerButtonEnable = false;
  let undoButtonEnable = state.drawMode && (state.drawMode !== 'Marker')

  if (state.drawMode)
    switch (state.drawMode) {
      case 'Line':
        lineButtonEnable = true;
        break;
      case 'Polygon':
        polyButtonEnabe = true;
        break
      case 'Marker':
        markerButtonEnable = true;
    }
  else if (drawb) {
    lineButtonEnable = true;
    polyButtonEnabe = true;
    markerButtonEnable = true;
  }

  function loadGPXDialog() {
    let folder = projects.selectedNode;
    if (!(folder.type === 'folder' || folder.type === 'workspace'))
      folder = folder.parentNode;
    folder.show = true;
    dispatch(setPlotDataAttr({showUploadGpxModal: true, selectedCollection: folder}))
  }

  function removeLastVertex() {
    const map = getMap1()
    if (map.pm.Draw[state.drawMode]._markers.length > 1)
      map.pm.Draw[state.drawMode]['_removeLastVertex']()
    else
      handleDrawButton() //тип не нужен т.к. это всегда отмена рисования
    dispatch(refreshProjects())
  }

  function handleCoordinates(type) {
    dispatch(showCoordinatesEditor([], type, (res) => {
      let layer = null;
      const arr = res.map(coo => new L.LatLng(coo.lat, coo.lng))
      switch (type) {
        case 'Line':
          layer = L.polyline(arr)
          break;
        case 'Polygon':
          layer = L.polygon(arr)
          break;
        case 'Marker':
          layer = createNewMarker(arr[0], defaultShapeColor)
      }
      if (layer) {
        getDrawLayerGroup().addLayer(layer)
        pmCreateHandler({layer: layer})
        clearCoordinatesDialogInfo()
        flyToShape(layer, type)
      }
    }))
  }

  function showCoordsDialog(type) {
    type === 'Line' && handleCoordinates('Line')
    type === 'Polygon' && handleCoordinates('Polygon')
    type === 'Marker' && handleCoordinates('Marker')
  }

  function pmDrawEnd() {
    getMap1().off('pm:create')
    dispatch(setDrawMode(null))
  }

  function handleCoordButtonClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleCoordButtonClose() {
    setAnchorEl(null);
  }

  function handleDrawButton(type) {
    const map = getMap1()
    projects.selectedNode.show = true;
    resetAllDrawing(dispatch)
    if (state.drawMode) {
      cancelDrawing()
    } else {
      switch (type) {
        case 'Line':
          /*map.once('pm:drawstart', ({workingLayer}) => {
            workingLayer.on('pm:vertexadded', pmVertexAdded)
          })*/
          map.once('pm:drawend', pmDrawEnd)
          map.pm.enableDraw('Line', drawStyle)
          map.on('pm:create', pmCreateHandler)
          break;
        case 'Polygon':
          /*map.on('pm:drawstart', ({workingLayer}) => {
            workingLayer.on('pm:vertexadded', pmVertexAdded);
          })*/
          map.once('pm:create', pmCreateHandler)
          map.pm.enableDraw('Polygon', drawStyle)
          break;
        case 'Marker':
          map.pm.disableDraw()
          const options = {
            markerStyle: {
              opacity: 1,
              draggable: false,
              icon: svgMarker(defaultShapeColor, 'pointer', null),
            },
            tooltips: false,
            cursorMarker: true,
            snappable: true,
            snapDistance: 10,
            continueDrawing: true,
            markerEditable: false,
          }
          map.pm.enableDraw('Marker', options);
          map.on('pm:create', pmCreateHandler)
          break;
      }
      dispatch(setDrawingMode(null)) //disable measuring
      dispatch(setDrawMode(type, projects.selectedNode))
    }
  }

  return (
    <Box style={panelStyle}>
      <Stack flexDirection={'column'} style={{...overlayButtonStyle, width: '30px'}} gap={'10'}>
        <IconButton
          style={panelButtonStyle}
          title={projectsLoadGPXString}
          disabled={!drawb}
          onClick={loadGPXDialog}
        >
          <UploadOutlinedIcon style={{fill: drawb ? theme.palette.primary.main : theme.palette.action.disabled}}
                              fontSize={'small'}/>
        </IconButton>
        <IconButton
          style={panelButtonStyle}
          title={createByCoordinates}
          disabled={!drawb}
          onClick={handleCoordButtonClick}
        >
          <AddLocationAltOutlinedIcon style={{fill: drawb ? theme.palette.primary.main : theme.palette.action.disabled}}
                                      fontSize={'small'}/>
        </IconButton>
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleCoordButtonClose}>
          <MenuItem size={'small'} onClick={() => showCoordsDialog('Line')}>
            <ListItemIcon>
              <LineIcon fontSize={'small'} style={{fill: theme.palette.primary.main}}/>
            </ListItemIcon>
            <Typography>{lineString}</Typography>
          </MenuItem>
          <MenuItem size={'small'} onClick={() => showCoordsDialog('Polygon')}>
            <ListItemIcon>
              <PolygonIcon fontSize={'small'} style={{stroke: theme.palette.primary.main}}/>
            </ListItemIcon>
            <Typography>{polygonString}</Typography>
          </MenuItem>
          <MenuItem size={'small'} onClick={() => showCoordsDialog('Marker')}>
            <ListItemIcon>
              <MarkerIcon fontSize={'small'} style={{fill: theme.palette.primary.main}}/>
            </ListItemIcon>
            <Typography>{markerString}</Typography>
          </MenuItem>
        </Menu>
        <Divider/>
        <IconButton
          style={{...panelButtonStyle}}
          title={lineString}
          onClick={() => handleDrawButton('Line')}
          disabled={!lineButtonEnable}
          id={'draw_line_button'}
        >
          <LineIcon style={{
            fill: lineButtonEnable ? theme.palette.primary.main : theme.palette.action.disabled,
            minWidth: '24px'
          }}/>
        </IconButton>
        <IconButton
          style={panelButtonStyle}
          title={polygonString}
          onClick={() => handleDrawButton('Polygon')} disabled={!polyButtonEnabe}
          id={'draw_polygon_button'}>
          <PolygonIcon style={{
            stroke: polyButtonEnabe ? theme.palette.primary.main : theme.palette.action.disabled,
            minWidth: '24px'
          }} fontSize={'small'}/>
        </IconButton>
        <IconButton
          style={panelButtonStyle}
          title={markerString}
          onClick={() => handleDrawButton('Marker')} disabled={!markerButtonEnable}
          id={'draw_marker_button'}>
          <MarkerIcon style={{
            fill: markerButtonEnable ? theme.palette.primary.main : theme.palette.action.disabled,
            minWidth: '24px'
          }} fontSize={'small'}/>
        </IconButton>

        <IconButton
          style={panelButtonStyle}
          title={projectsRemoveLastButtonString}
          onClick={removeLastVertex}
          disabled={!undoButtonEnable}
        >
          <ReplyIcon style={{fill: undoButtonEnable ? theme.palette.primary.main : theme.palette.action.disabled}}
                     fontSize={'small'}/>
        </IconButton>
      </Stack>
    </Box>
  )
}
