import {BaseLayersButton} from "./BaseLayers/BaseLayersButton";
import Stack from "@mui/material/Stack";
import {ZoomControl} from "./ZoomControl";
import {GPSButton} from "./GPSButton";
import {BlindButton} from "../Map/Blind/BlindButton";
/*import {IconButton} from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {overlayButtonStyle} from "../Map/Common/Styles";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {setIntroTourAttr} from "../../../redux/reducers/IntroToursReducer";*/
import {PencilButton} from "./PencilButton";
import {MapDrawingPanel} from "./MapDrawingPanel";
import {cannotIDo} from "../Map/Common/tariffs";

export function TopLeftOverlayPane() {
  //const dispatch = useDispatch()
  //const mainPageIntroTour = useSelector((state) => state.introToursReducer.mainPageIntroTour)
  //const isMobileDevice = useSelector(state => state.userReducer.isMobileDevice)
  const readOnly = !!cannotIDo.editorAction()

  const panelStyle = {
    position: 'absolute',
    top: '1vh',
    left: '1vh',
  }

  return (
    <Stack direction={'column'} spacing={0.3} style={panelStyle} alignItems={'flex-start'}>
      <Stack direction={'row'} spacing={0.3}>
        <BaseLayersButton/>
        <BlindButton/>
        {/*{!isMobileDevice &&
          <Box style={{...overlayButtonStyle, width: '30px', padding: 0}}>
            <IconButton
              style={{height: '30px', width: '30px'}}
              onClick={() => {
                dispatch(setIntroTourAttr({...mainPageIntroTour, status: true}, 'mainPageIntroTour'))
              }}
              title={'Описание функционала'}
            >
              <ErrorOutlineOutlinedIcon fontSize={'small'}/>
            </IconButton>
          </Box>
        }*/}
      </Stack>
      <Stack direction={'row'} alignItems={'flex-start'} gap={0.3}>
        <Stack direction={'column'} gap={0.3}>
          <ZoomControl/>
          <GPSButton/>
        </Stack>
        <PencilButton/>
      </Stack>
      {!readOnly && <MapDrawingPanel/>}
    </Stack>
  )
}
