import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {tableRowStyle} from "../EmployeesTable";
import {useDispatch} from "react-redux";
import {hideMapDialog, showMapDialog} from "../../../../Main/Map/Common/Dialog/DialogReducer";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {OwnerCrownIcon} from "./UserForm/OwnerCrownIcon";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";
import {deleteUser} from "../../../org_page_api";

export function getRolesString(user) {
  const roles = [];
  if (user.is_owner) {
    roles.push('Владелец')
  }
  if (user.is_admin) {
    roles.push('Админ')
  }
  if (user.is_editor && !user.is_admin) {
    roles.push('Редактор')
  }
  if (user.is_full_access_user && !user.is_editor) {
    roles.push('Полный просмотр')
  }
  if (roles.length === 0 && !user.only_mobile)
    roles.push('Просмотр')
  if (user.only_mobile) {
    roles.push('Только GisApp Mobile')
  }
  if (user.deny_access) {
    roles.push('Нет доступа')
  }
  return roles.join(', ');
}

const UserRow = ({user, fieldData, index}) => {
  const dispatch = useDispatch()
  const deleteDialogButtons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
  const iAmOwner = !cannotIDo.ownerAction()
  const heIsMe = user.id === getTariff().userID;

  /** Обработчик открытия окна настроек пользователя. */
  function handleClickOpen() {
    dispatch(changeOrgPageAttrs({editEmployee: user}))
  }

  /** Обработчик нажатия на кнопку редактирования доступа к арендам, дорогам, делянам. */
  function selectUserHandler(user) {
    dispatch(changeOrgPageAttrs({
      userData: structuredClone(user),
      createUserMode: false,
    }))
  }

  function deleteButtonHandler() {
    dispatch(showMapDialog('warning', 'Удаление пользователя',
      "Вы уверены что хотите удалить выбранного пользователя? ",
      deleteDialogButtons,
      (e) => {
        dispatch(hideMapDialog())
        if (e === 'Да') {
          deleteUser(user.id)
        }
      }))
  }

  const iCanEdit = iAmOwner || !user.is_admin;

  return (
    <>
    <TableRow key={user.id} sx={tableRowStyle}>
      <TableCell align="left">{index + 1}</TableCell>
      {fieldData.map(fieldObj =>
        <TableCell key={fieldObj.fieldName} align="left">{user[fieldObj.fieldName]}</TableCell>
      )}
      <TableCell align="left">
        {!user.is_owner
          ?
          getRolesString(user)
          :
          <OwnerCrownIcon/>
        }
      </TableCell>
      <TableCell align="left">
        <Box sx={{display: 'flex'}}>
          <IconButton
            disabled={!iCanEdit}
            title={'Редактировать данные сотрудника'}
            onClick={() => selectUserHandler(user)}
            color={'primary'}
          >
            <EditIcon/>
          </IconButton>
          <IconButton
            disabled={!iCanEdit && !heIsMe}
            title={'Изменить настройки доступа'}
            onClick={() => handleClickOpen()}
            color={'primary'}
          >
            <AdminPanelSettingsIcon/>
          </IconButton>
          {!user.is_owner &&
            <IconButton
              disabled={!iCanEdit}
              title={'Удалить сотрудника из организации'}
              onClick={deleteButtonHandler}
              color={'warning'}
            >
              <DeleteOutlineOutlinedIcon/>
            </IconButton>
          }
        </Box>
      </TableCell>
    </TableRow>
    </>
  )
}

export default UserRow;
