import React from 'react';
import {TableCell, TableHead, TableRow} from "@mui/material";

const TableHeadComponent = ({WorkSpacesTableColumns}) => {
  return (
    <TableHead>
      <TableRow>
        {WorkSpacesTableColumns.map((column) => (
          <TableCell key={column.name} sx={{width: column.width}} align="left">{column.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadComponent;