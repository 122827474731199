import React from 'react';
import TextField from "@mui/material/TextField";

const TextPlotField = ({label, maxLength, value, field, textHandler, type, required, errorMessage}) => {
  return (
    <TextField type={type}
               variant={'outlined'}
               size={'small'}
               label={label}
               fullWidth
               required={required}
               inputProps={{ maxLength: maxLength}}
               autoComplete={'off'}
               value={value || ''}
               error={!!errorMessage}
               helperText={errorMessage}
               onChange={(e) => {
                 textHandler((prev) => {return {...prev, [field]: e.target.value}})
               }}
    />
  );
};

export default TextPlotField;