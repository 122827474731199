import Box from "@mui/material/Box";
import {Modal, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {topBlockStyle, titleStyle, textStyle} from "../organizationPageStyles";
import {useSelector} from "react-redux";
import ChangePasswordDialog from "./ChangePasswordDialog/ChangePasswordDialog";
import CreatePasswordDialog from "./CreatePasswordDialog/CreatePasswordDialog";
import {forwardRef, useState} from "react";

const AuthorizationData = () => {
  const userInfo = useSelector(state => state.userReducer.userInfo)
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false)
  const [openCreatePasswordDialog, setOpenCreatePasswordDialog] = useState(false)
  const TransitionChangePasswordDialog = forwardRef(() =>
    <ChangePasswordDialog openDialog={openChangePasswordDialog} setOpenDialog={setOpenChangePasswordDialog}/>)
  const TransitionCreatePasswordDialog = forwardRef(() =>
    <CreatePasswordDialog openDialog={openCreatePasswordDialog} setOpenDialog={setOpenCreatePasswordDialog}/>)

  if (!userInfo) return ''
  const {username, email, password_is_set} = userInfo
  return (
    <Box sx={topBlockStyle} id={'authorizationData'}>
      <Stack direction={'column'} spacing={2}>
        <Typography sx={titleStyle}>АВТОРИЗАЦИОННЫЕ ДАННЫЕ</Typography>
        <Stack spacing={1} sx={{mt: 1}}>
          <Stack direction={'row'}>
            <Typography sx={{...textStyle, width: 100}}>Email</Typography>
            <Typography sx={{...textStyle, color: 'black', textDecoration: 'underline'}}>{email}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography sx={{...textStyle, width: 100}}>Телефон</Typography>
            <Typography sx={{...textStyle, color: 'black'}}>{username}</Typography>
          </Stack>
          <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center'}}>
            <Typography sx={{...textStyle, width: 100}}>Пароль</Typography>
            {password_is_set
              ? <Button size={'small'} onClick={() => setOpenCreatePasswordDialog(true)}>
                Изменить пароль</Button>
              : <Button size={'small'} onClick={() => setOpenCreatePasswordDialog(true)}>
                Задать пароль</Button>
            }
            <Modal open={openChangePasswordDialog}>
              <TransitionChangePasswordDialog/>
            </Modal>
            <Modal open={openCreatePasswordDialog}>
              <TransitionCreatePasswordDialog/>
            </Modal>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default AuthorizationData;
