//NOTE: Доступна оптимизация: можно подсчитывать количество очков роли во время загрузки и сортировка будет намного быстрее
//С этим есть нюансы: узеры загружается в родителе этого компонента, не будет ли это проблемой?
import {Table, TableBody, TableContainer} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import TableHeadComponent from "./TableComponents/TableHeadComponent";
import UserRow from "./TableComponents/UserRow";
import UserForm from "./TableComponents/UserForm/UserForm";
import CreateUserRow from "./TableComponents/CreateUserRow";
import {compareRoles} from "../../../Main/Map/Common/tariffs";
import {useEffect} from "react";
import {getEmployees, getWorkspaces,} from "../../org_page_api";
import {Loading} from "../../../Main/Map/Common/Loading";
import {changeOrgPageAttrs} from "../../organizationPageReducer";
import {UserAccessDialog} from "./TableComponents/UserForm/UserAccessDialog/UserAccessDialog";
import {ErrorAlert} from "../../../Common/ErrorAlert";
import {loadingErrorWithRepeatText} from "../../../Main/Map/Common/Strings_RU";

export const tableRowStyle = {
  "&:last-child td, &:last-child th": {border: 0},
  "&:hover": {backgroundColor: '#e7e7e7'},
  cursor: 'default'
}

const fieldData = [ //роль обрабатывается отдельно, так повелось :(
  {fieldName: "last_name", label: "Фамилия", required: true, max_length: 50, order: null},
  {fieldName: "first_name", label: "Имя", required: true, max_length: 50, order: null},
  {fieldName: "patronymic", label: "Отчество", required: false, max_length: 50, order: null},
  {fieldName: 'phone', label: "Телефон", required: true, max_length: 10, order: null},
  {fieldName: 'email', label: "Email", required: false, max_length: 50, order: null},
  {fieldName: "post", label: "Должность", required: false, max_length: 50, order: null},
]
const roleData = {fieldName: 'role', label: "Роль", order: null}

/**
 * Таблица узеров в ЛК
 * @returns {JSX.Element|null}
 */
const EmployeesTable = () => {
  const employeesData = useSelector(state => state.organizationPageReducer.employeesData)
  const employeesLoading = useSelector(state => state.organizationPageReducer.employeesDataLoading)
  const employeesLoadingError = useSelector(state => state.organizationPageReducer.employeesDataLoadingError)
  const workspaces = useSelector(state => state.organizationPageReducer.workspacesData)
  const workspacesLoadingError = useSelector(state => state.organizationPageReducer.workspacesDataLoadingError)
  const workspaceLoading = useSelector(state => state.organizationPageReducer.workspacesDataLoading)
  const editEmployee = useSelector(state => state.organizationPageReducer.editEmployee)
  const createUserMode = useSelector(state => state.organizationPageReducer.createUserMode)
  const userData = useSelector(state => state.organizationPageReducer.userData)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!employeesData && !employeesLoading && !employeesLoadingError) {
      getEmployees()
    }
    if (!workspaces && !workspaceLoading && !employeesLoadingError) {
      getWorkspaces()
    }
  }, [employeesData, employeesLoading, employeesLoadingError, workspaces, workspaceLoading, workspacesLoadingError])

  if (employeesLoading || workspaceLoading)
    return <Loading/>

  if (workspacesLoadingError || employeesLoadingError) {
    return <ErrorAlert text={loadingErrorWithRepeatText} onClick={() => {
      let state = {}
      if (!workspaces) {
        state = {workspacesData: null, workspacesDataLoading: false, workspacesDataLoadingError: false}
      }
      if (!employeesData) {
        state = {...state, employeesData: null, employeesDataLoading: false, employeesDataLoadingError: false}
      }
      dispatch(changeOrgPageAttrs({...state}))
    }}
    />
  }

  if (!employeesData || !workspaces) {
    return null;
  }

  function sortingChange(fieldName, sortingOrder) {
    let curField = null;
    fieldData.forEach(field => {
      if (field.fieldName === fieldName)
        curField = field;
      field.order = null;
    })
    roleData.order = null;
    if (!curField)
      curField = roleData;
    curField.order = !sortingOrder;
    const arr = [...employeesData]
    arr.sort((u1, u2) => {
      if (fieldName !== 'role') {
        const res = u1[fieldName].localeCompare(u2[fieldName])
        if (curField.order === false) {
          return res * -1;
        }
        return res;
      } else {
        const res = compareRoles(u1, u2)
        if (roleData.order === true) { //fixme: так и непонял почему оно тут вверх ногами
          return res * -1;
        }
        return res;
      }
    })
    dispatch(changeOrgPageAttrs({employeesData: arr}))
  }

  return (
    <>
      {editEmployee && <UserAccessDialog user={editEmployee}/>}
      <TableContainer sx={{overflowX: 'auto'}}>
        <Table size={"small"}>
          <TableHeadComponent fieldData={fieldData} roleData={roleData} sortingChange={sortingChange}/>
          <TableBody>
            {employeesData.map((user, i) =>
              userData?.id === user?.id
                ?
                <UserForm key={i} fieldData={fieldData} mode={'edit'}/>
                :
                <UserRow key={i} user={user} fieldData={fieldData} index={i}/>
            )}
            {!createUserMode
              ?
              <CreateUserRow numColumns={fieldData.length}/>
              :
              <UserForm fieldData={fieldData} mode={'create'}/>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default EmployeesTable;
