import {cuttingsAreasTemplate} from "../../LeftPanel/Context/CAs/CuttingsAreas";

export const filterList = structuredClone(cuttingsAreasTemplate.filters)

export const defaultState ={
  filters: {
    ca: structuredClone(filterList),
    currentFilter: null,
    //archived: false,
  },
  fireFilter: false,
  selectionLayers: null,
  selectionData: [],
}

export function refresh() {
  return {
    type: "CP_REFRESH_FILTERS",
  }
}

export function fireFilter(where, filter) {
  return {
    type: "CP_FIRE_FILTER",
    filter: filter,
    where: where,
  }
}

export function stopFilter() {
  return {
    type: "CP_STOP_FILTER",
  }
}

export function resetCAFilters(filters, fire) {
  return {
    type: 'CP_RESET_FILTERS',
    filters: filters,
    fire: fire,
  }
}

export function filterReducer(state = defaultState, action) {
  switch (action.type) {
    case "CP_FIRE_FILTER":
      switch(action.where) {
        case 'organization_name':
          return {
            ...state,
            filters: {
              ...state.filters,
              ca: {...state.filters.ca, 'organization_name': action.filter},
              currentFilter: 'organization_name'
            },
            fireFilter: true
          }
        case 'rental_contract_number':
          return {
            ...state,
            filters: {
              ...state.filters,
              ca: {...state.filters.ca, 'rental_contract_number': action.filter},
              currentFilter: 'rental_contract_number'
            },
            fireFilter: true
          }
        case 'region':
          return {
            ...state,
            filters: {...state.filters, ca: {...state.filters.ca, 'region': action.filter}, currentFilter: 'region'},
            fireFilter: true
          }
        case 'forestry':
          return {
            ...state,
            filters: {
              ...state.filters,
              ca: {...state.filters.ca, 'forestry': action.filter},
              currentFilter: 'forestry'
            },
            fireFilter: true
          }
        case 'district_forestry':
          return {
            ...state,
            filters: {
              ...state.filters,
              ca: {...state.filters.ca, 'district_forestry': action.filter},
              currentFilter: 'district_forestry'
            },
            fireFilter: true
          }
        case 'declaration':
          return {
            ...state,
            filters: {
              ...state.filters,
              ca: {...state.filters.ca, 'declaration': action.filter},
              currentFilter: 'declaration'
            },
            fireFilter: true
          }
        case 'status':
          return {...state, filters: {...state.filters, ca: {...state.filters.ca, 'status': action.filter}, currentFilter: 'status' }, fireFilter: true}
        case 'monitoring_data':
          return {...state,
            filters: {
              ...state.filters,
              ca: {...state.filters.ca, 'monitoring_data': action.filter},
              currentFilter: 'monitoring_data'
            },
            fireFilter: true
          }
        case 'ownerName':
          return {...state, filters: {...state.filters, ca: {...state.filters.ca, 'ownerName': action.filter}, currentFilter: 'ownerName' }, fireFilter: true}
        default:
          return state;
      }
    case "CP_STOP_FILTER":
      return {...state, fireFilter: false, filters: {ca: {...state.filters.ca, currentFilter: null}}}
    case "CP_REFRESH_FILTERS":
      return {...state, filters: {...state.filters}, fireFilter: true }
    case "CP_RESET_FILTERS":
      return {...state, filters: {ca: action.filters, currentFilter: 'all'}, fireFilter: action.fire}
    default:
      return state;
  }
}
