import React from 'react';
import {TableCell, TableRow, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {isEmpty} from "../../../../validations/validations";

const EditMachineTableRow = ({editMachine, changeEditMachineData, selectEditMachine, updateMachine}) => {
  let requiredFieldIsEmpty = isEmpty(editMachine?.name) || isEmpty(editMachine?.number)
  return (
    <TableRow>
      <TableCell>
        <TextField size={'small'}
                   variant={'standard'}
                   label={'Марка'}
                   value={editMachine.name}
                   inputProps={{maxLength: 50}}
                   onChange={(e) => changeEditMachineData(e, 'name')}/>
      </TableCell>
      <TableCell>
        <TextField size={'small'}
                   variant={'standard'}
                   label={'Госномер'}
                   inputProps={{maxLength: 10}}
                   value={editMachine.number}
                   onChange={(e) => changeEditMachineData(e, 'number')}/>
      </TableCell>
      <TableCell sx={{display: 'flex'}}>
        <IconButton onClick={updateMachine} disabled={requiredFieldIsEmpty}>
          <DoneIcon sx={{color: !requiredFieldIsEmpty && 'primary.main'}} title={'Сохранить'} />
        </IconButton>
        <IconButton onClick={() => selectEditMachine(null)}>
          <CloseIcon sx={{color: '#A44646'}}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default EditMachineTableRow;