import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {IconButton, List, ListItem, ListItemButton, Stack, Typography, useTheme} from "@mui/material";
import {casSelected, casSelectionNote, cleanListString} from "../../../../Map/Common/Strings_RU";
import {
  projectsTreeListItemStyle,
  treeItemButtonIconSize,
  treeItemButtonStyle,
  workspaceTypographyStyle
} from "../../../../Map/Common/Styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import {caSelectionSetItems, caSetSelectionTreeShow, setSelectionDrawingMode} from "./caSelectionsReducer";
import {getCuttingAreas} from "../CuttingsAreas";
import RectangleIcon from "@mui/icons-material/Rectangle";
import {getMap1} from "../../../../Map/GlobalObjects";
import {CASelectionContextMenu} from "./caSelectionContextMenu";
import {SelectOnMapButton} from "../../../../Map/Common/Drawing/SelectOnMapButton";
import {measuringDrawStyle} from "../../../../Overlay/Measuring/MeasuringPanel";
import intersect from '@turf/intersect';
import {caSelectionBorderColor, resetAllCAsSelection} from "./caSelectionsCommon";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {ReactComponent as PolygonIcon} from '../../../../Map/Common/svg/polygon.svg';
import {geoJSONdefaultPrecision} from "../../../../Map/Common/Defaults";
import {flyToPolygon} from "../../../../Map/Common/fly";

//Выбранные деляны
export function CASelectionTree() { // Дерево выбранных делян
  const state = useSelector(state => state.caSelectionsReducer)
  const map = getMap1()
  const dispatch = useDispatch()
  const theme = useTheme()

  let ind = 0;

  function endDrawing(result) { // завершение рисования полигона
    if (result.status === 'ok') {
      const selectionPoly = result.layer.toGeoJSON(geoJSONdefaultPrecision)
      const cas = getCuttingAreas().layerArray;
      const resultArr = []
      cas.map(layer => {
        if (map.hasLayer(layer)) {
          const geoL = layer.toGeoJSON(geoJSONdefaultPrecision)
          const ti = intersect(selectionPoly, geoL)
          if (ti) {
            resultArr.push(layer)
          }
        }
      })
      if (resultArr.length) {
        resetAllCAsSelection()
        resultArr.map(layer => {
          layer.elz_oldopts = {...layer.options}
          layer.setStyle({color: caSelectionBorderColor})
        })
        dispatch(caSelectionSetItems(resultArr))
      }
    }
    dispatch(setSelectionDrawingMode(false))
  }

  const reactItems = [];
  state.items.map(layer => {
    reactItems.push(
      <ListItem
        key={ind++}
        disablePadding
        disableGutters
      >
        <ListItemButton
          disableGutters
          disableRipple
          style={{
            paddingLeft: '24px',
            paddingTop: 1,
            paddingBottom: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            flyToPolygon(layer.getLatLngs())
          }}
        >
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            style={{padding: 0, width: '100%'}}
            key={ind++}
          >
            <RectangleIcon
              fontSize={'small'}
              style={{
                stroke: layer.elz_oldopts.color ? layer.elz_oldopts.color : 'green',
                strokeWidth: 2,
                fill: layer.elz_oldopts.fillColor,
                fillOpacity: layer.elz_oldopts.fillOpacity,
                paddingRight: '5px',
              }}
            />
            <Typography
              variant={'subtitle1'}
              style={{
                fontStyle: 'italic',
                fontWeight: '400',
                margin: 0,
                flex: 1,
              }}>{layer.elz_properties.name}
            </Typography>
            <div onClick={(e) => e.stopPropagation()}> {/* Ебанутый на всю голову mui */}
              <CASelectionContextMenu layer={layer}/>
            </div>
          </Stack>
        </ListItemButton>
      </ListItem>
    )
  })

  return (
    <>
      <Stack direction={'row'} style={{...projectsTreeListItemStyle}}
                onClick={() => dispatch(caSetSelectionTreeShow(!state.show))}>
        {state.show
          ?
          <ArrowDropDownIcon style={treeItemButtonIconSize}/>
          :
          <ArrowRightIcon style={treeItemButtonIconSize}/>
        }
        <StarOutlineOutlinedIcon
          style={{...treeItemButtonIconSize, fill: theme.palette.warning.dark}}/>
        <Typography style={workspaceTypographyStyle}>{casSelected} ({state.items.length})</Typography>
        <div onClick={(e) => e.stopPropagation()}>
          <IconButton
            title={cleanListString}
            disableRipple
            style={{
              ...treeItemButtonStyle,
              color: reactItems.length ? theme.palette.warning.main : theme.palette.text.disabled
            }}
            onClick={() => resetAllCAsSelection()}>
            <HighlightOffIcon fontSize={'small'}/>
          </IconButton>
        </div>
        <SelectOnMapButton
          drawing={state.drawing}
          onStart={() => dispatch(setSelectionDrawingMode(true))}
          onEnd={endDrawing}
          removeLayer={true}
          selectionType={'Polygon'}
          icon={<PolygonIcon/>}
          shapeStyle={{...measuringDrawStyle, tooltips: false, allowSelfIntersection: false}}
        />
      </Stack>
      {state.show &&
        <List disablePadding style={{overflowY: 'auto', maxHeight: '30vh'}}>
          <>
            {reactItems}
            {!reactItems.length &&
              <Typography
                style={{
                  paddingLeft: '24px',
                  paddingRight: '24px',
                  fontStyle: 'italic',
                  fontSize: '80%',
                  lineHeight: '100%'
                }}
                color={'#999999'}>
                {casSelectionNote}
              </Typography>}
          </>
      </List>
      }
    </>
  )
}
