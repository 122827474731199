import React, {useState} from 'react';
import {Stack, Box, Typography, Button, useTheme, Switch, IconButton} from "@mui/material";
import {titleStyle} from "../../organizationPageStyles";
import {
  requestConnectionWith1CThunk,
  requestDisconnect1CThunk
} from "../../../../redux/reducers/organizationInfoReducer";
import {useDispatch, useSelector} from "react-redux";
import {hideMapDialog, showMapDialog} from "../../../Main/Map/Common/Dialog/DialogReducer";
import {confirmExitMessage, ExitDialogTitle} from "../../../Main/Map/Common/Strings_RU";
import {setExitStatus} from "../../../Main/Map/GlobalObjects";
import {setAuthAttr} from "../../../../redux/reducers/loginReducer";
import TokenService from "../../../../api/token.service";
import FormControlLabel from "@mui/material/FormControlLabel";
import {setForestUseReportAttr} from "../../../../redux/reducers/forestUseReportReducer";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Sync1CSettings from "./Sync1CSettings/Sync1CSettings";

const expansionModuleBlockStyle = {
  backgroundColor: '#FFFFFF80',
  width: 'auto',
  padding: '15px 30px',
  borderRadius: 3,
}

const Connection1C = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const using_1C = useSelector(state => state.organizationInfoReducer.organizationData?.using_1C)
  const buttons = {'Ок': {color: theme.palette.warning.main}, 'Отмена': {color: theme.palette.primary.main}}
  const [show1CSettings, setShow1CSettings] = useState(false)

  const connection1CDialog = (status) => {
    const connectDialogTitle = 'Запрос на подключение к модулю 1С.Delyana'
    const confirmConnection = 'Если вы хотите подключить модуль 1С.Delyana к вашему аккаунту, ' +
      'нажмите "Ок" и наш специалист свяжется с вами в течении одного рабочего дня'
    const disconnectDialogTitle = 'Запрос на отключение модуля 1С.Delyana'
    const confirmDisconnect = 'Если вы хотите отключить модуль 1С.Delyana, ' +
      'нажмите "Ок" и наш специалист свяжется с вами в течении одного рабочего дня'

    status
      ? dispatch(showMapDialog('warning', connectDialogTitle, confirmConnection, buttons, (e) => {
        dispatch(hideMapDialog())
        if (e === 'Ок') {
          dispatch(requestConnectionWith1CThunk())
        }
      }))
      : dispatch(showMapDialog('warning', disconnectDialogTitle, confirmDisconnect, buttons, (e) => {
        dispatch(hideMapDialog())
        if (e === 'Ок') {
          dispatch(requestDisconnect1CThunk())
        }
      }))
  }

  const show1CSettingsButtonHandler = () => {
    setShow1CSettings(prev => !prev)
  }

  return (
    <Box sx={{m: 2}}>
      <Stack direction={'column'} spacing={1} sx={expansionModuleBlockStyle} >
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography sx={{...titleStyle, color: 'black'}}>Модуль 1С.Delyana</Typography>
            {using_1C &&
              <IconButton onClick={show1CSettingsButtonHandler}>
                <SettingsOutlinedIcon sx={{color: show1CSettings && theme.palette.primary.main}}/>
              </IconButton>
            }
          </Stack>
          {!using_1C
            ? <Button variant={'contained'} onClick={() => connection1CDialog(true)}>Запросить подключение</Button>
            : <Button variant={'contained'} color={'error'} onClick={() => connection1CDialog(false)}>Запросить
              отключение</Button>
          }
        </Stack>
        {show1CSettings &&
          <Sync1CSettings/>
        }
      </Stack>
    </Box>
  );
};

export default Connection1C;