import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {Link, Modal, Stack, Typography} from "@mui/material";
import tokenService from "../../../api/token.service";
import Box from "@mui/material/Box";

export const cookiesAcceptModalStyle = {
  position: "absolute",
  bottom: "3%",
  right: "3%",
  backgroundColor: "rgb(255, 255, 255)",
  border: "0px solid lightgrey",
  borderRadius: 8,
  boxShadow: 24,
  padding: 14,
  maxWidth: '94%',
  width: 'auto',
  fontFamily: 'roboto',
  outline: 'none'
};

// окно о согласии с использованием cookies
export const CookiesAcceptModal = () => {
  const [showCookiesAlert, setShowCookiesAlert] = useState(false);


  useEffect(() => {
    !tokenService.getCookiesAccept() && toggleDrawer(true)
  }, [])
  const toggleDrawer = (open) => {
    setShowCookiesAlert(open);
  };

  // сохраняем согласие пользователя на использование куки в локал сторадж через tokenService.setCookiesAccept, и
  //выключаем окно с уведомлением.
  const acceptCookiesButtonHandler = () => {
    tokenService.setCookiesAccept('true')
    setShowCookiesAlert(false)
  }

  const List = () => (
    <Stack spacing={2} sx={{p: 2}}>
      <Typography >
        Мы используем "cookies", чтобы сайт работал правильно
      </Typography>
      <Stack direction={'row'} spacing={3} alignItems={'center'}>
        <Button
          style={{textTransform: 'none'}}
          variant={'contained'} onClick={() => acceptCookiesButtonHandler()}>
          Понятно
        </Button>
        <Link sx={{cursor: 'pointer', pl: 1, pr: 1}}
              onClick={() => {
                window.open('https://www.delyana.ru/agreement', '_blank')
                toggleDrawer(true)
              }}
        >{'Политика конфиденциальности'}</Link>
      </Stack>
    </Stack>
  );

  return (
    <>
      {showCookiesAlert &&
        <Modal open={showCookiesAlert}>
          <Box style={cookiesAcceptModalStyle}>
            <List/>
          </Box>
        </Modal>
      }
    </>
  )
}