import {Alert, FormControl, FormControlLabel, Stack, Switch} from "@mui/material";
import {useEffect, useState} from "react";
import {loadingErrorPressButtonText, monitoringLabelText} from "../../../../Map/Common/Strings_RU";
import {Loading} from "../../../../Map/Common/Loading";
import {ErrorAlert} from "../../../../../Common/ErrorAlert";
import {dataAPI} from "../../../../../../api/api";
import {useSelector} from "react-redux";
import {dispatch} from "../../../../../Common/misc_functions";
import {setTMData} from "./workspaceReducer";
import {cannotIDo} from "../../../../Map/Common/tariffs";

export function WSMonitoringTab({workspace}) {
  /**
   * @type {{loadingError: boolean, data: null|{object}, loading: boolean}}
   */
  const defaults = {loading: false, loadingError: false, data: null}
  const [state, setState] = useState({...defaults})
  const data = useSelector(state => state.workspaceReducer.tmData)
  const useTMModule = !cannotIDo.useLoggingComplexMonitoringModule()

  useEffect(() => {
    if (useTMModule && !data && !state.loading && !state.loadingError) {
      setState({...state, loading: true})
      dataAPI.workspaces.getTransportMonitoringMatching(workspace.id).then(res => {
        setState({...state, loading: false, loadingError: false})
        dispatch(setTMData({is_need_sync_transport_monitoring: res.data.is_need_sync_transport_monitoring}))
      })
        .catch(() => {
          setState({...state, loading: false, loadingError: true, data: null})
        })
    }
  }, [state])

  if (!useTMModule) {
    return (
      <Alert severity={"warning"} sx={{mb: "2vh"}}>
        У вас нет доступа к настройкам модуля мониторинга транспорта.
      </Alert>
    )
  }

  if (state.loadingError) {
    return (
      <ErrorAlert
        text={loadingErrorPressButtonText}
        divider
        onClick={() => {
          setState({...defaults})
        }}
      />
    )
  }

  if (!data || state.loading) {
    return <Loading/>
  }

  function changeTMMatching() {
    dispatch(setTMData({is_need_sync_transport_monitoring: !data.is_need_sync_transport_monitoring}))
  }

  return (
    <Stack>
      {useTMModule &&
        <FormControl sx={{marginBottom: '2vh'}}>
          <FormControlLabel
            control={<Switch color="primary" checked={data?.is_need_sync_transport_monitoring}
                             onChange={changeTMMatching}/>}
            label={monitoringLabelText}
          />
        </FormControl>
      }
    </Stack>
  )
}
