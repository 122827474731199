import React from 'react';
import {Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

//полноэкранная заглушка с лоадером посередине, блокирующая весь интерфейс
const FullScreenLoading = ({text = 'Запрос обрабатывается...'}) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress sx={{mr: 1, mt: 1}} size={40}/>
      <Typography sx={{color: 'white'}}>{text}</Typography>
    </Box>
  );
};

export default FullScreenLoading;