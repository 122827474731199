import {dataAPI, otherDataAPI} from "../../api/api";
import {djangoErrorsParse} from "../../utils/djangoErrorsParse";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const  SET_FEEDBACK_ATTR = 'SET_FEEDBACK_ATTR'

let initialState = {
  //feedback
  feedbackText: '',
  email: ''
}

// редьюсер для feedback forms, в текущем коде не используется, т.к. заменили feedback forms на jivo. Код оставили на случай смерти jivo
export const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEEDBACK_ATTR: {
      return {...state, ...action.payload};
    }
    default: return state
  }}

export const setFeedbackAttr = (payload) => ({type: SET_FEEDBACK_ATTR, payload});

/* отправляем данные на сервер, если успешно, то обнуляем данные feedback и уведомляем пользователя об успехе через setSnack,
если ошибка, то сохраняем промежуточные данные (сообщение и адрес почты) в стейт и возвращаем данные в формы, а
 пользователю сообщение об ошибке */
export const sendFeedbackMessageThunk = (data) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.feedback.sendFeedbackMessage(data)
      dispatch(setFeedbackAttr({
        feedbackText: '',
        email: ''
      }))
      dispatch(setSnack('success', 'Ваше сообщение принято. Мы направим вам ответ на указанный email.'))
    } catch (e) {
      dispatch(setFeedbackAttr({feedbackText: data.text, email: data.email}))
      handleErrors(dispatch, e)
    }
  }
}
