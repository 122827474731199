import {useEffect} from "react";
import Box from "@mui/material/Box";
import {TitleCommonBlock} from "../Common/TitleCommonBlock";
import CurrentTariff from "./CurrentTariff/CurrentTariff";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import Workspaces from "./Workspaces/Workspaces";
import Employees from "./Employees/Employees";
import PersonalAccount from "./PersonalAccount/PersonalAccount";
import {Stack} from "@mui/material";
import AuthorizationData from "./AuthorizationData/AuthorizationData";
import {useDispatch, useSelector} from "react-redux";
import ExpansionModules from "./ExpansionModules/ExpansionModules";
import {cannotIDo} from "../Main/Map/Common/tariffs";
import SentinelUsers from "./Sentinel/SentinelUsers";
import {getOrganizationData, OPExitHandler} from "./org_page_api";
import {changeOrgPageAttrs} from "./organizationPageReducer";
import {Loading} from "../Main/Map/Common/Loading";

const NewOrganizationPage = () => {
  const dispatch = useDispatch()
  const openedBlock = useSelector((state) => state.organizationPageReducer.openedBlock)
  const organizationData = useSelector((state) => state.organizationPageReducer.organizationData)
  const organizationDataLoading = useSelector((state) => state.organizationPageReducer.organizationDataLoading)
  const organizationDataLoadingError = useSelector((state) => state.organizationPageReducer.organizationDataLoadingError)
  const readOnly = !!cannotIDo.editorAction()
  const iAmAdmin = !cannotIDo.adminAction()

  useEffect(() => {
    if (organizationData === null && !organizationDataLoading && !organizationDataLoadingError) {
      getOrganizationData()
    }
  }, [organizationDataLoading, organizationDataLoadingError])

  function setOpenedBlock(block) {
    dispatch(changeOrgPageAttrs({openedBlock: block}))
  }

  return (
    <Box sx={{overflow: 'auto', maxHeight: '100%'}}>
      <TitleCommonBlock
        title={"Личный кабинет"}
        closeButtonHandler={OPExitHandler}
      />
      {organizationDataLoading
        ?
        <Loading/>
        :
        <Stack sx={{pl: 5, pr: 5, pt: 2, pb: 2}} gap={2}>
          {organizationData && (
            <>
              <Stack direction={'row'}>
                {!readOnly &&
                  <CurrentTariff/>
                }
                <Stack gap={2} sx={{flex: 1}}>
                  <PersonalInfo/>
                  <AuthorizationData/>
                </Stack>
              </Stack>
              {iAmAdmin &&
                <>
                  <Workspaces
                    openedBlock={openedBlock}
                    setOpenedBlock={setOpenedBlock}
                  />
                  <Employees
                    openedBlock={openedBlock}
                    setOpenedBlock={setOpenedBlock}
                  />
                  <SentinelUsers
                    openedBlock={openedBlock}
                    setOpenedBlock={setOpenedBlock}
                  />
                  <PersonalAccount
                    openedBlock={openedBlock}
                    setOpenedBlock={setOpenedBlock}
                  />
                  <ExpansionModules
                    openedBlock={openedBlock}
                    setOpenedBlock={setOpenedBlock}
                  />
                </>
              }
            </>
          )}
        </Stack>
      }
    </Box>
  )
}

export default NewOrganizationPage;
