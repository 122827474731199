import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {BasicTabs} from "./BasicTabs";
import {cannotIDo, getTariff} from "../../../../Main/Map/Common/tariffs";
import {useDispatch, useSelector} from "react-redux";
import {headerKeys} from "./CASPermissionsTable";
import {otherDataAPI} from "../../../../../api/api";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";
import {saveActionSelectText, saveString, saveString3, savingSuccess} from "../../../../Main/Map/Common/Strings_RU";
import {changeOrgPageAttrs, resetDialogData} from "../../../organizationPageReducer";
import {
  addWorkspaceCAsStatusesToMeChanged,
  addWorkspaceInMeChanged,
  casStatusesMap,
  changeUsersInWorkspace,
  removeWorkspaceInMeChanged,
  treeButtonDialog
} from "../../../org_page_api";

export function WorkspacePermissionsSettings() {
  const workspace = useSelector(state => state.organizationPageReducer.wpDialogWorkspace)
  const curTab = useSelector(state => state.organizationPageReducer.curTab)
  const employeesCas = useSelector(state => state.organizationPageReducer.employeesCasPermissions)
  const myID = getTariff().userID;
  const iAmOwner = !cannotIDo.ownerAction()
  const dispatch = useDispatch()

  function handleClose() {
    const changed = window.store.getState().organizationPageReducer.changesInDialog !== null;
    const saving = window.store.getState().organizationPageReducer.dialogSaving;
    if (saving) {
      dispatch(setSnack('warning', 'Пожалуйста, дождитесь сохранения на текущей вкладке.'))
      return;
    }
    if (changed) {
      treeButtonDialog(saveString3, saveActionSelectText, (value) => {
        if (value === 'Сохранить') {
          handleSave()
        }
        if (value === 'Не сохранять') {
          dispatch(resetDialogData())
        }
      })
    } else {
      dispatch(resetDialogData())
    }
  }

  function handleSave(callback) {
    const changed = window.store.getState().organizationPageReducer.changesInDialog !== null;
    if (changed) {
      if (curTab === 0) {//workspaces tab
        let arr = []
        const items = window.store.getState().organizationPageReducer.selectedItems;
        items.forEach(employee => {
          if (employee.id === myID || !employee.is_admin || iAmOwner) {
            arr.push(employee)
          }
        })
        dispatch(changeOrgPageAttrs({dialogSaving: true}))
        const before = !!workspace.users.find(user => user.id === myID)
        const after = !!items.find(user => user.id === myID)
        changeUsersInWorkspace(workspace.id, arr)
          .then(() => {
            dispatch(changeOrgPageAttrs({
              dialogSaving: false,
              changesInDialog: null,
              dialogSavingError: false,
              employeesCasPermissions: null,
              oldData: null,
            }))
            if (before !== after) {
              if (after) {
                addWorkspaceInMeChanged(workspace.id)
              } else {
                removeWorkspaceInMeChanged(workspace.id)
              }
            }
            dispatch(setSnack('success', savingSuccess))
            if (callback)
              callback(true)
          })
          .catch(err => {
            handleErrors(dispatch, err)
            dispatch(changeOrgPageAttrs({dialogSavingError: true, dialogSaving: false}))
            if (callback)
              callback(false)
          })
      } else {//cas statuses tab
        const arr = employeesCas.map(employee => {
          const data = {id: employee.id}
          headerKeys.slice(2).forEach(header => {
            data[header] = employee[header]
          })
          return data;
        })
        otherDataAPI.workspaces.setOrganizationCasStatusAccess(workspace.id, {users_plots: arr}).then(() => {
          const oldEmployeesCasPermissions = window.store.getState().organizationPageReducer.oldData;
          if (oldEmployeesCasPermissions) {
            const oldMe = oldEmployeesCasPermissions.find(me => me["id_user"] === myID)
            const newMe = employeesCas.find(me => me["id_user"] === myID)
            if (oldMe && newMe) {
              const arr = Object.keys(casStatusesMap).filter(key => oldMe[key] !== newMe[key])
              if (arr.length > 0) {
                addWorkspaceCAsStatusesToMeChanged(workspace.id)
              }
            }
          }

          dispatch(changeOrgPageAttrs({
            dialogSaving: false,
            dialogSavingError: false,
            changesInDialog: null,
            oldData: structuredClone(employeesCas),
          }))
          dispatch(setSnack('success', 'Права доступа к делянам обновлены.'))
        }).catch(err => {
          handleErrors(dispatch, err)
          dispatch(changeOrgPageAttrs({dialogSavingError: true, dialogSaving: false}))
          if (callback)
            callback(false)
        })
      }
    }
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={handleClose}
    >
      <AppBar sx={{position: 'relative'}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Редактирование прав доступа для "{workspace.number}"
          </Typography>
          <Button autoFocus color="inherit" onClick={() => handleSave()}>
            {saveString}
          </Button>
        </Toolbar>
      </AppBar>
      <BasicTabs
        workspace={workspace}
        handleSave={handleSave}
      />
    </Dialog>
  )
}
