export const planetableSvgConfig = {
  // quarter svg config
  quarter_outline_fill: "#e57373", //300
  quarter_outline_stroke: "#f44336", //500
  quarter_outline_stroke_width: 2,
  quarter_editing_outline_fill: "#d32f2f", //700
  quarter_outline_opacity: 0.4,
  quarter_editing_outline_opacity: 0.7,
  quarter_editing_point_index: '#b71c1c', //900
  quarter_point_radius: 5,
  quarter_viewed_point_radius: 7,
}