import React, {useLayoutEffect, useState} from 'react';
import ModalPage from "../../../../../Common/ModalPage";
import {useDispatch, useSelector} from "react-redux";
import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import PlotLocationFields from "../../../../../Common/RegionLocationFields/PlotLocationFields";
import {getNodeWorkspace} from "../../Projects/ProjectsCommon";
import {geoJSONdefaultPrecision} from "../../../../Map/Common/Defaults";
import TextPlotField from "../../../CreatePlotModal/TextPlotField/TextPlotField";
import {setPlotDataAttr} from "../../../../../../redux/reducers/plotDataReducer";
import {createWarehouseOnServer, updateWarehouseInfo} from "./WarehouseCommon";

/**
 * Создание/обновление информации по складу (модальное окно).
 *
 * @param {Object} title - заголовок модального окна.
 * @return {JSX.Element} компонент модальной страницы.
 */
export function CreateUpdateWarehouseModal({title}) {
  const dispatch = useDispatch()
  const selectedWarehouse = useSelector((state) => state.plotDataReducer.selectedWarehouse);
  const selectedProjectTreeNode = useSelector((state) => state.plotDataReducer.selectedProjectTreeNode);
  const [errors, setErrors] = useState({})
  const [wareHouseData, setWareHouseData] = useState(
    {
      region: null,
      forestry: null,
      district_forestry: null,
      name: null,
      type: 'Промежуточный',
      address: null
    }
  )

  const warehouseTypes = ['Промежуточный', 'Нижний']

  useLayoutEffect(() => {
    if (selectedWarehouse) {
      setWareHouseData(
        {
          region: selectedWarehouse.properties.region,
          forestry: selectedWarehouse.properties.forestry,
          district_forestry: selectedWarehouse.properties.district_forestry,
          name: selectedWarehouse.properties.name,
          type: selectedWarehouse.properties.type,
          unique_location: selectedWarehouse.properties.unique_location,
          address: selectedWarehouse.properties.address
        }
      )
    }
    return () => {
      // Очищает данные о выделе складе после закрытия компонента.
      dispatch(setPlotDataAttr({selectedWarehouse: null}))
    }
  }, [])

  /** Дополнительные поля для заполнения информацией о складе. */
  const otherWareHouseFields = {
    name: {
      textHandler: setWareHouseData,
      label: "Наименование",
      maxLength: 30,
      value: wareHouseData.name,
      field: 'name',
      type: 'text',
      errorMessage: errors.name,
      required: true,
    },
    address: {
      textHandler: setWareHouseData,
      label: "Адрес",
      maxLength: 100,
      value: wareHouseData.address,
      field: 'address',
      type: 'text',
      required: false,
    },
  }

  /** Закрывает диалоговое окно и сбрасывает все поля. */
  const handleClose = () => {
    dispatch(setPlotDataAttr({
      showCreateWarehouseModal: false,
      showUpdateWarehouseModal: false,
      selectedWarehouse: null
    }))
  }

  /** Обрабатывает нажатие на кнопку "Сохранить" при создании/редактировании. */
  const actionButtonHandler = () => {
    let requiredFields = Object.keys(otherWareHouseFields).filter(field => otherWareHouseFields[field].required)
    if (!selectedWarehouse) {
      validation(requiredFields) &&
      createWarehouseOnServer(
        {
          region: wareHouseData.region?.id,
          forestry: wareHouseData.forestry?.id,
          district_forestry: wareHouseData.district_forestry?.id,
          name: wareHouseData?.name,
          type: wareHouseData?.type,
          address: wareHouseData?.address,
          geometry: selectedProjectTreeNode.layer.toGeoJSON(geoJSONdefaultPrecision).geometry,
          rental_contract: getNodeWorkspace(selectedProjectTreeNode)['id'],
        }, selectedProjectTreeNode
      )
    } else {
      validation(requiredFields) &&
      updateWarehouseInfo(
        selectedWarehouse,
        {
          region: wareHouseData.region?.id || null,
          forestry: wareHouseData.forestry?.id || null,
          district_forestry: wareHouseData.district_forestry?.id || null,
          unique_location: wareHouseData.unique_location?.id || null,
          name: wareHouseData?.name || null,
          type: wareHouseData?.type || null,
          address: wareHouseData?.address || null,
        }
      )
    }
  }

  const validation = (requiredFields) => {
    let errorsObj = {}
    requiredFields.forEach(field => {
      if (!wareHouseData[field]) {
        errorsObj[field] = 'Обязательное поле'
      }
    })
    if (!wareHouseData.name) {
      errorsObj.name = 'Обязательное поле'
    }
    setErrors({...errorsObj})
    return !Object.keys(errorsObj).length
  }

  return (
    <ModalPage
      title={title}
      handleClose={handleClose}
      actionButtonHandler={actionButtonHandler}
      actionButtonName={'Сохранить'}
    >
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Справочники
      </Divider>
      <PlotLocationFields setDataObj={setWareHouseData} dataObj={wareHouseData}/>
      <Divider orientation={"horizontal"} sx={{mb: 2, mt: 2}}>
        Основная информация
      </Divider>

      <Grid container spacing={3} sx={{mb: 3, minWidth: 900}}> {}
        {Object.keys(otherWareHouseFields).map(field => (
          <Grid key={field} item xs={12}>
            <TextPlotField
              textHandler={otherWareHouseFields[field].textHandler}
              label={otherWareHouseFields[field].label}
              type={otherWareHouseFields[field].type}
              maxLength={otherWareHouseFields[field].maxLength}
              value={otherWareHouseFields[field].value}
              field={field}
              required={otherWareHouseFields[field].required}
              errorMessage={otherWareHouseFields[field].errorMessage}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel>Тип</InputLabel>
            <Select
              value={wareHouseData.type}
              label="Тип склада"
              onChange={(e) => setWareHouseData((prev) => ({
                ...prev,
                type: e.target.value
              }))}
            >
              {warehouseTypes.map((status, i) => (
                <MenuItem key={i} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </ModalPage>
  )
}
