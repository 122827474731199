import React, {useRef} from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";

const UploadPlanetableForm = ({image, setPlanetableData, currentPlanetable}) => {

  const hiddenFileInput = useRef(null)

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleInputChange = event => {
    const fileUploaded = event.target.files[0];
    setPlanetableData(prev => ({...prev, image: fileUploaded}))
  };


  const undoHandler = () => {
    setPlanetableData(prev => ({...prev, image: null}))
  }
  return (
    <Grid container spacing={2} sx={{pt: 1, pb: 2}}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          autoFocus
          label={'Имя файла'}
          value={image?.name || ''}
          disabled={true}
          variant={'outlined'}
          size={'small'}
        />
      </Grid>
      <Grid item xs={6}>
        {!image ? (
          <>
            <Button size={'small'}
                    sx={{height: 40}}
                    variant="outlined"
                    fullWidth
                    disabled={!!currentPlanetable}
                    onClick={handleClick}>
              {'Загрузить планшет'}
            </Button>
            <input hidden accept="*/*" type="file"
                   ref={hiddenFileInput}
                   onChange={handleInputChange}/>
          </>
        ) : (
            <Button size={'small'}
                    sx={{height: 40}}
                    variant="outlined"
                    color={'secondary'}
                    fullWidth
                    disabled={!!currentPlanetable}
                    onClick={undoHandler}>
              {'Отменить'}
            </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default UploadPlanetableForm;