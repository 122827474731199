import {otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_FOREST_USE_REPORT_ATTR = 'SET_FOREST_USE_REPORT_ATTR'
const SET_REPORT_DATA_PARAMETER = 'SET_REPORT_DATA_PARAMETER'
const SET_QUARTER_OUTLINE_POINT = 'SET_QUARTER_OUTLINE_POINT'
const SET_PLOT_OUTLINE_POINT = 'SET_PLOT_OUTLINE_POINT'
const DELETE_QUARTER_OUTLINE_POINT = 'DELETE_QUARTER_OUTLINE_POINT'
const RESET_FOREST_USE_REPORT_STATE = 'RESET_FOREST_USE_REPORT_STATE'
const CHANGE_IL1_OUTLINE_POINT = 'CHANGE_IL1_OUTLINE_POINT'


const initialState = {
  reportData: null,
  quarterPx: null,
  quarterNumber: null,
  quarterCenterPoint: null,
  plotPx: null,
  newQuarterPx: [],
  newPlotPx: [],
  selectedQuarterPointIndex: null,
  selectedPlotPointIndex: null,
  reportFormsValues: {
    owner: '',
    region: '',
    forestry: '',
    district_forestry: '',
    unique_location: '',
    quarter_number: '',
    allotments: '',
    d_plot_number: '',
    d_plot_square: ''
  },
  drawMode: 'view', // view, drawQuarter, drawPlot
  currentlyPressedButton: null,
  showForestUseReportModal: false,
  imageUrl: null,
  image: null,
  sendReportUseForestLoading: false,
}

// редьюсер окна отчета ИЛ-1
export const forestUseReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_FOREST_USE_REPORT_STATE: {
      return {...initialState, reportFormsValues: state.reportFormsValues}
    }
    case SET_FOREST_USE_REPORT_ATTR: {
      return {...state, ...action.payload};
    }
    case SET_REPORT_DATA_PARAMETER: {
      return {...state, reportData: {...state.reportData, ...action.payload}};
    }
    case SET_QUARTER_OUTLINE_POINT: {
      return {...state, newQuarterPx: [...state.newQuarterPx, action.point]};
    }
    case SET_PLOT_OUTLINE_POINT: {
      return {...state, newPlotPx: [...state.newPlotPx, action.point]};
    }
    case DELETE_QUARTER_OUTLINE_POINT: {
      return {...state, quartersOutlines: [...state.quartersOutlines.slice(0, state.quarterOutline.length - 1)]};
    }
    case CHANGE_IL1_OUTLINE_POINT: {
      if (action.figureType === 'plotPoint') {
        return {
          ...state, plotPx: state.plotPx.map((point, index) => {
              return (action.index === index) ? action.coords : point
            }
          )
        };
      } else if (action.figureType === 'quarterPoint') {
        return {
          ...state, quarterPx: state.quarterPx.map((point, index) => {
              return (action.index === index) ? action.coords : point
            }
          )
        };
      } else return state
    }
    default:
      return state
  }
}

export const resetForestUseReportState = () => ({type: RESET_FOREST_USE_REPORT_STATE});
export const setForestUseReportAttr = (payload) => ({type: SET_FOREST_USE_REPORT_ATTR, payload});
export const changeOutlinePoint = (index, figureType, coords) =>
  ({type: CHANGE_IL1_OUTLINE_POINT, index, figureType, coords});
export const setQuarterOutlinePoint = (point) => ({type: SET_QUARTER_OUTLINE_POINT, point});
export const setPlotOutlinePoint = (point) => ({type: SET_PLOT_OUTLINE_POINT, point});
export const deleteQuarterOutlinePoint = () => ({type: DELETE_QUARTER_OUTLINE_POINT});


export const getReportUseForestDataThunk = (data) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.reportUseForest.getReportUseForestData(data)
      const reportData = res.data
      const quarter = reportData.quarters_px.find(quarter => quarter.qtr_number === +reportData.quarter_number)
      if (quarter) {
        const {qtr_px, qtr_number, center_px,} = quarter
        dispatch(setForestUseReportAttr({
          reportData: reportData,
          quarterPx: qtr_px,
          quarterNumber: qtr_number,
          quarterCenterPoint: center_px,
          plotPx: reportData.d_plot_px,
          reportFormsValues: {
            owner: '',
            region: reportData.region || '',
            forestry: reportData.forestry || '',
            district_forestry: reportData.district_forestry || '',
            unique_location: reportData.unique_location || '',
            quarter_number: qtr_number, //reportData.quarters_px[0].qtr_number,
            allotments: reportData.allotments || '',
            d_plot_number: reportData.d_plot_number || '',
            d_plot_square: reportData.d_plot_square || '',
          },
        }))
        dispatch(setForestUseReportAttr({showForestUseReportModal: true}))
      } else {
        dispatch(setSnack('error',
          'Для формирования отчета укажите правильный номер квартала в характеристиках деляны.'))
      }
    } catch (e) {
      handleErrors(dispatch, e)
    }

  }
}

export const getReportUseForestImageThunk = (url) => {
  return async (dispatch) => {
    try {
      const res = await otherDataAPI.reportUseForest.getReportUseForestImage(url);
      const blob = res.data
      const imageUrl = URL.createObjectURL(blob)
      dispatch(setForestUseReportAttr({imageUrl: imageUrl}));
    } catch (e) {
      dispatch(setForestUseReportAttr({showForestUseReportModal: false}))
      handleErrors(dispatch, e)
    }
  };
};

export const createReportUseForestThunk = (reportData) => {
  return async (dispatch) => {
    try {
      dispatch(setForestUseReportAttr({sendReportUseForestLoading: true}))
      let res = await otherDataAPI.reportUseForest.sendReportUseForestData(reportData)
      let blob = new Blob([res.data], {type: "image/jpeg"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download",
        `уч.л.${reportData.district_forestry},кв.${reportData.quarter_number},дел.${reportData.d_plot_number},
        ${reportData.report_type.includes('ИЛ-1') ? 'ИЛ-1' : 'ВЛ-1'}.jpeg`)
      document.body.appendChild(link);
      const newTab = window.open(link.href, '_blank');
      if (!newTab) {
        dispatch(setSnack('warning',
          'Для открытия файла с отчетом разрешите открытие всплывающих окон в браузере для этого сайта.'))
      }
      dispatch(setForestUseReportAttr({sendReportUseForestLoading: false}))
    } catch (e) {
      dispatch(setForestUseReportAttr({sendReportUseForestLoading: false}))      
      // В ошибке нет сообщения от сервера.
      if (e.response.status === 403) {
        dispatch(setSnack('error', "Нельзя создать больше 10 отчетов за месяц."))
      } else {
        handleErrors(dispatch, e)
      }
    }
  }
}
