import React, {useEffect, useRef} from "react";
import {Card, ImageListItem, Typography, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {urlModify} from "../../../../../utils/ulrModify";
import {disableModeAndUndoChanges, setImage, setPhotoAttr,} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {imageStyle, leftBottomPhotoCard, leftTopPhotoCard, rightTopPhotoCard} from "../../../stockpilePageStyles";
import SortedButtons
  from "../../../../Main/RightPanel/RPCommonElements/Photos/PhotosListItem/SortedButtons/SortedButtons";
import {patchStockpileThunk} from "../../../../../redux/reducers/stockpilesReducer";


function StockpileImage({image, imagesList, setImagesList, index, sortMode, setSortMode, iAmEditor}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const isSelectedPhoto = image?.id === selectedPhoto?.id
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const originalImagesList = selectedStockpile?.images || []
  const selectedPhotoIndex = imagesList.indexOf(image)
  let imageUrl = urlModify(image.image_small)
  let imageVolume = image.figures.map(figure => figure.volume).reduce((accumulator, currentValue) =>
    accumulator + currentValue, 0).toFixed(2)
  const imageRef = useRef(null);

  useEffect(() => {
    if (isSelectedPhoto && imageRef.current) {
      imageRef.current.focus();
    }
  }, [isSelectedPhoto]);

  const selectPhotoHandler = () => {
    dispatch(disableModeAndUndoChanges(image))
    dispatch(setPhotoAttr({selectedPhoto: image, zoom: 100}))
  }

  function swapItemsInList(direction) {
    !sortMode && setSortMode(true)
    let newList = structuredClone(imagesList)
    newList = newList.map((image, i) => ({...image, position: i + 1}))
    const swapIndex = direction === 'left' ? selectedPhotoIndex - 1 : selectedPhotoIndex + 1;

    if (swapIndex < 0 || swapIndex >= newList.length) {
      return newList
    }

    [newList[selectedPhotoIndex], newList[swapIndex]] = [newList[swapIndex], newList[selectedPhotoIndex]];
    newList[selectedPhotoIndex].position = selectedPhotoIndex + 1
    newList[swapIndex].position = swapIndex + 1
    setImagesList(newList)
  }

  const undoButtonHandler = () => {
    setSortMode(false)
    setImagesList(originalImagesList)
  }

  const saveNewOrderButtonHandler = () => {
    setSortMode(false)
    dispatch(patchStockpileThunk(selectedStockpile.id, selectedStockpile.xplot, {images: imagesList}))
  }

  const onKeyDownHandler = (event) => {
    switch (event.code) {
      case 'KeyA':
        selectedPhotoIndex > 0 && swapItemsInList('left')
        break
      case 'KeyD':
        selectedPhotoIndex < imagesList.length - 1 && swapItemsInList('right')
        break
      case 'Escape':
        undoButtonHandler()
        break
      case 'Enter':
      case 'NumpadEnter':
        saveNewOrderButtonHandler()
        break
      case 'ArrowLeft':
        !sortMode && dispatch(setImage('prev'))
        break
      case 'ArrowRight':
        !sortMode && dispatch(setImage('next'))
        break
    }
  }

  return (
    <ImageListItem sx={{mr: 1, cursor: 'pointer', outline: 'none'}}
                   onClick={() => !sortMode && selectPhotoHandler()}
                   onKeyDown={onKeyDownHandler}
                   ref={imageRef}
                   tabIndex={0}
    >
      <Card sx={{...leftBottomPhotoCard, bgcolor: imageVolume > 0 ? "primary.light" : 'darkred',}}>
        <Typography sx={{fontSize: 12,}}
                    color={'white'}>{`${imageVolume || 0} м`}<sup>3</sup></Typography>
      </Card>
      <Card sx={{...leftTopPhotoCard, bgcolor: image.timbers_count > 0 ? "primary.light" : 'darkred',}}>
        <Typography sx={{fontSize: 12,}}
                    color={'white'}>{`${image.timbers_px_include.length} шт`}</Typography>
      </Card>
      <Card sx={rightTopPhotoCard}>
        <Typography sx={{fontSize: 12,}} color={'white'}>{index + 1}</Typography>
      </Card>
      {isSelectedPhoto && iAmEditor &&
        <SortedButtons imagesList={imagesList} sortMode={sortMode}
                       selectedPhotoIndex={selectedPhotoIndex}
                       swapItemsInList={swapItemsInList}
                       undoButtonHandler={undoButtonHandler}
                       saveNewOrderButtonHandler={saveNewOrderButtonHandler}/>
      }
      <img
        alt={'Фото'}
        style={{
          ...imageStyle,
          border: isSelectedPhoto ? 'solid 4px' : 'solid 1px',
          borderColor: isSelectedPhoto ? theme.palette.primary.main : theme.palette.primary.dark,
          opacity: (!isSelectedPhoto && sortMode) ? 0.6 : 1}}
        src={imageUrl}/>
    </ImageListItem>
  )
}

export default StockpileImage