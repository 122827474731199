import React from 'react';
import {IconButton} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveRulerButton from "./SaveRulerButton";
import {useDispatch, useSelector} from "react-redux";
import {
  disableModeAndUndoChanges,
  patchChangeRulerDataThunk,
  setPhotoAttr,
  undoSvgChanges
} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {toolbarIconsSize} from "../../../stockpilePageStyles";
import ToolbarBlockContainer from "../../ToolbarBlockContainer/ToolbarBlockContainer";
import {toolbarIconButtonStyle, toolbarIconStyle} from "../../toolbarStyles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {hideMapDialog, showMapDialog} from "../../../../Main/Map/Common/Dialog/DialogReducer";
import AddIcon from "@mui/icons-material/Add";

const EditRulerButtons = () => {
  let dispatch = useDispatch()
  const stockpilePhotoState = useSelector(state => state.stockpilePhotoReducer)
  const {selectedPhoto, mode, ruler, ruler_selected_index, creationMode} = stockpilePhotoState
  const isRulerSelected = ruler_selected_index !== null
  const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
  const undoButtonHandler = () => {
    dispatch(disableModeAndUndoChanges(selectedPhoto))
    // dispatch(setPhotoAttr({ruler_selected_index: null, mode: 'view', creationMode: null}))
  }

  const deletedRulerHandler = () => {
    dispatch(showMapDialog('warning', 'Удаление линейки', 'Вы уверены что хотите удалить ' +
      'ширину замера?', buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        dispatch(patchChangeRulerDataThunk(selectedPhoto, []))
      }
    }))
  }

  const createRulerHandler = () => {
    dispatch(setPhotoAttr({creationMode: 'ruler', ruler: [], ruler_selected_index: 0}))
  }

  return (
    <ToolbarBlockContainer title={'Ширина замера'}>
      <SaveRulerButton/>
      <IconButton
        sx={toolbarIconButtonStyle}
        title={'Удалить ширину замера'}
        disabled={!isRulerSelected || creationMode === 'ruler'}
        onClick={deletedRulerHandler}
      >
        <DeleteForeverIcon sx={toolbarIconStyle}/>
      </IconButton>
      {ruler_selected_index !== null ? (
        <IconButton
          sx={toolbarIconButtonStyle}
          title={'Отменить'}
          onClick={undoButtonHandler}
        >
          <RestartAltIcon sx={toolbarIconStyle}/>
        </IconButton>
      ) : (
        <IconButton
          sx={toolbarIconButtonStyle}
          title={'Задать ширину замера'}
          onClick={createRulerHandler}
        >
          <AddIcon sx={{...toolbarIconStyle, color: 'primary.main'}}/>
        </IconButton>
      )}
    </ToolbarBlockContainer>
  );
};

export default EditRulerButtons;