import Grid from "@mui/material/Grid";
import {Slider} from "@mui/material";
import {useDispatch} from "react-redux";
import {setTransportMonitoringInfo} from "../caPropertiesReducer";
import {getSpeciesThunk} from "./TransportMonitoringDataCommon";

export const TimeInterval = ({id, state}) => {
  const dateMin = state.date_min;
  const dateMax = state.date_max;
  const selectedDates = state.selected;
  const dispatch = useDispatch()

  function handleChange(event, newDates) {
    //dataTM.date.selected = newDates.map(date => new Date(date).getTime())
    //setRefresh(!refresh)
    dispatch(setTransportMonitoringInfo({selected: newDates.map(date => new Date(date).getTime())}))
  }

  function handleChangeCommitted() {
    //getSpeciesThunk(id, state.type, newDates[0], newDates[1])
    getSpeciesThunk(id, state.type, selectedDates[0], selectedDates[1])
  }

  function valueLabelFormat(value) {
    return new Date(value).toISOString().slice(0, 10)
  }

  return (
    <Grid p={1}>
      <Grid item pl={6} pr={8}>
        <Slider
          disabled={!dateMin || !dateMax || state.loading || state.loadingError}
          min={dateMin}
          max={dateMax || 1}
          value={selectedDates[0]?selectedDates:[0, 1]}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          step={86400000} // 1 day in milliseconds
        />
      </Grid>
    </Grid>
  )
}
