import {otherDataAPI} from "../../api/api";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_REFERENCE = "SET_REFERENCE";
const REFERENCE_LOADING = "REFERENCE_LOADING";
const REFERENCE_ERROR = "REFERENCE_ERROR";

const initialState = {
  referenceLoading: {status: false, reference: ''},
  referenceError: {status: false, message: ''},
  references: {
    regions: [],
    forestry: [],
    altForestry: [],
    districtForestry: [],
    altDistrictForestry: [],
    quarters: [],
    uniqueLocations: [],
    cuttingTypes: [],
    cuttingForms: [],
    categoryProtectForests: [],
    purposes: [],
    nameForestObjects: [],
    governmentAgencies: [],
    declarantPersons: [],
    typeUseForests: [],
    preparatoryLoggingWorks: [],
    mainLoggingWorks: [],
    finalLoggingWorks: [],
    widthApiaries: [],
    measures: [],
    length: [],
    rates: [],
    species: [],
    varieties: [],
  },
}

// общий редьюсер для работы со справочниками
export const referencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFERENCE: {
      return {
        ...state,
        references: {...state.references, ...action.reference},
      };
    }
    case REFERENCE_LOADING: {
      return {...state, referenceLoading: {status: action.status, reference: action.reference}}
    }
    case REFERENCE_ERROR: {
      return {...state, referenceError: {status: action.status, message: action.message},}
    }
    default:
      return state;
  }}

export const setReference = (reference) => ({type: SET_REFERENCE, reference});
export const isReferenceLoading = (status, reference) => ({type: REFERENCE_LOADING, status, reference});
//export const isReferenceError = (status, message) => ({type: REFERENCE_ERROR, status, message});

export const getReferenceThunk = (part, data= null) => {
  return async (dispatch) => {
    dispatch(isReferenceLoading(true, part))
    let res;
    try {
      switch (part) {
        case "regions":
          res = await otherDataAPI.references.getRegions();
          break;
        case "forestry":
          res = await otherDataAPI.references.getForestry(data);
          break;
        case "altForestry":
          res = await otherDataAPI.references.getAlternativeForestriesByRegionId(data);
          break;
        case "districtForestry":
          res = await otherDataAPI.references.getDistrictForestry(data);
          break;
        case "altDistrictForestry":
          res = await otherDataAPI.references.getAlternativeDistrictForestryByAltForestryId(data);
          break;
        case "uniqueLocations":
          res = await otherDataAPI.references.getUniqueLocations(data);
          break;
        case "cuttingTypes":
          res = await otherDataAPI.references.getCuttingTypes();
          break;
        case "categoryProtectForests":
          res = await otherDataAPI.references.getCategoryProtectForests();
          break;
        case "nameForestObjects":
          res = await otherDataAPI.references.getNameForestObjects();
          break;
        case "governmentAgencies":
          res = await otherDataAPI.references.getGovernmentAgencies(data);
          break;
        case "declarantPersons":
          res = await otherDataAPI.references.getDeclarantPersons();
          break;
        case "typeUseForests":
          res = await otherDataAPI.references.getTypeUseForests();
          break;
        case "measures":
          res = await otherDataAPI.references.getMeasures();
          break;
        case "species":
          res = await otherDataAPI.references.getSpecies();
          break;
        case "length":
          res = await otherDataAPI.references.getSpeciesLength();
          break;
        case "varieties":
          res = await otherDataAPI.references.getVarieties();
          break;
      }
      dispatch(setReference({[part]: res.data}));
      dispatch(isReferenceLoading(false, ''))
    } catch (e) {
      dispatch(isReferenceLoading(false, ''))
      handleErrors(dispatch, e)
    }
  };
};
