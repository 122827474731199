import {
  caCreatorString,
  caDeclarationVolumeString,
  caExportPercentString,
  caExportVolumeString,
  caHarvestVolumeString,
  caLastTeamString,
  caMeasurementVolumeString,
  caPlanVolumeString,
  caResidualVolumeString,
  caValuePercentString,
  caValueString,
  caWorkEndString,
  caWorkStartString,
  cubicMeterString,
  meterCubicHectareString
} from "../../Map/Common/Strings_RU";

export const propertiesMap = {
  'ПланВырубки': {name: caPlanVolumeString,  suffix: cubicMeterString},
  'НачалоРазработки': {name: caWorkStartString,  suffix: ''},
  'ОбъемПоДекларации': {name: caDeclarationVolumeString, suffix: cubicMeterString},
  'ОбъемПоЗаготовке': {name: caHarvestVolumeString, suffix: cubicMeterString},
  'ОбъемПоЗамерам': {name: caMeasurementVolumeString, suffix: cubicMeterString},
  'ОбъемПоВывозке': {name: caExportVolumeString, suffix: cubicMeterString},
  'ОбъемОстаток': {name: caResidualVolumeString, suffix: cubicMeterString},
  'ОкончаниеРазработки': {name: caWorkEndString, suffix: ''},
  'Отвел': {name: caCreatorString, suffix: ''},
  'Показатели': {name: caValueString, suffix: meterCubicHectareString},
  'ПоказателиПроц': {name: caValuePercentString, suffix: '%'},
  'ПоследняяБригада': {name: caLastTeamString, suffix: ''},
  'ПроцентВывозки': {name: caExportPercentString, suffix: '%'},
}

export const properties1CMap = {
  'ОбъемПоВывозке1С': {name: caExportVolumeString + ' 1С',  suffix: cubicMeterString},
  'ОбъемПоЗаготовке1С': {name: caHarvestVolumeString + ' 1С',  suffix: cubicMeterString},
  'ПоказателиПроц1С': {name: caValuePercentString + ' 1С', suffix: '%'},
}
