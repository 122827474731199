import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import './TableRowStyles.css';
import {Loading} from "../../../../../../../Main/Map/Common/Loading";
import {changeOrgPageAttrs} from "../../../../../../organizationPageReducer";
import {otherDataAPI} from "../../../../../../../../api/api";
import {handleErrors} from "../../../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {ErrorAlert} from "../../../../../../../Common/ErrorAlert";
import {loadingErrorWithRepeatText} from "../../../../../../../Main/Map/Common/Strings_RU";

const headers = [
  "",
  "Договор аренды",
  "Обычные",
  "Вывозка"
]
const headerKeys = [
  "",
  "number",
  "show_ready_roads",
  "show_removal_roads",
]
const HeaderCell = ({header, onHeaderChange, allChecked}) => {
  return (
    <TableCell>
      {header === "Договор аренды" || header === "" ? header : (
        <label>
          <Checkbox
            checked={allChecked}
            onChange={() => onHeaderChange(header)}
          />
          {header}
        </label>
      )}
    </TableCell>
  )
}

const ThirdTabAccessRoads = ({user}) => {
  const dispatch = useDispatch();
  const roadItems = useSelector(state => state.organizationPageReducer.allRoads)
  const itemsLoading = useSelector(state => state.organizationPageReducer.itemsLoading)
  const itemsLoadingError = useSelector(state => state.organizationPageReducer.itemsLoadingError)

  useEffect(() => {
    if (!roadItems && !itemsLoading && !itemsLoadingError) {
      dispatch(changeOrgPageAttrs({itemsLoading: true}))
      otherDataAPI.organizationUsers.getAllAllowedRentalContractRoads(user.id)
        .then(res => {
          dispatch(changeOrgPageAttrs({
            allRoads: res.data["rental_contracts_roads"],
            oldRoads: structuredClone(res.data["rental_contracts_roads"]),
            itemsLoading: false,
            itemsLoadingError: false,
          }))
        })
        .catch(err => {
          handleErrors(dispatch, err)
          dispatch(changeOrgPageAttrs({itemsLoading: false, itemsLoadingError: true, selectedItems3: null}))
        })
    }
  }, [itemsLoading, itemsLoadingError])

  function isRowChecked(row) {
    return headerKeys.slice(2).every(key => row[key])
  }

  function handleRowCheckboxChange(rowIndex) {
    const row = roadItems[rowIndex];
    const allChecked = !headerKeys.slice(2).every(key => row[key])
    const updatedData = roadItems.map((r, i) =>
      i === rowIndex
        ? {
          ...r,
          ...headerKeys.slice(2).reduce((acc, key) => {
            acc[key] = allChecked;
            return acc;
          }, {})
        }
        : r
    )
    dispatch(changeOrgPageAttrs({changesInDialog: 2, allRoads: [...updatedData]}))
  }

  function handleHeaderChange(header) {
    const key = headerKeys[headers.indexOf(header)]
    const allChecked = !roadItems.every(row => row[key])
    const updatedData = roadItems.map(row => ({
      ...row,
      [key]: allChecked
    }))
    dispatch(changeOrgPageAttrs({changesInDialog: 2, allRoads: [...updatedData]}))
  }

  function handleCheckboxChange(rowIndex, columnIndex) {
    const key = headerKeys[columnIndex];
    const updatedData = roadItems.map((row, i) => i === rowIndex ? {
      ...row,
      [key]: !row[key]
    } : row)
    dispatch(changeOrgPageAttrs({changesInDialog: 2, allRoads: [...updatedData]}))
  }

  if (itemsLoading) {
    return <Loading/>
  }

  if (itemsLoadingError) {
    return (
      <ErrorAlert style={{mt: 2}} text={loadingErrorWithRepeatText} onClick={() => {
        dispatch(changeOrgPageAttrs({itemsLoading: false, itemsLoadingError: false, allRoads: null}))
      }}
      />
    )
  }

  return (
    <>
      {roadItems?.length > 0
        ?
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <HeaderCell
                  key={index}
                  header={header}
                  onHeaderChange={handleHeaderChange}
                  allChecked={roadItems.length > 0 && roadItems.every(row => row[headerKeys[index]])}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {roadItems.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="hoverTableRow">
                <TableCell>
                  <Checkbox
                    checked={isRowChecked(row)}
                    onChange={() => handleRowCheckboxChange(rowIndex)}
                  />
                </TableCell>
                {headerKeys.slice(1).map((key, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {cellIndex === 0 ? row[key] : (
                      <Checkbox
                        checked={row[key]}
                        onChange={() => handleCheckboxChange(rowIndex, cellIndex + 1)}
                      />
                    )}
                  </TableCell>
                ))}

              </TableRow>
            ))}
          </TableBody>
        </Table>
        :
        <Alert severity={'info'} sx={{mt: 2}}>Договоры аренды отсутствуют</Alert>
      }
    </>
  )
}

export default ThirdTabAccessRoads;
