import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Collapse, Typography} from "@mui/material";
import {blockTitle} from "../../rightPanelStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ShortInfo from "../ShortInfo/ShortInfo";
import {propertiesMap} from "../../RPRoadData/roadMapData";
import {useSelector} from "react-redux";

const ShortInfoBlock = () => {
  const properties_1c = useSelector(state => state.rightPanelReducer.plotData?.properties_1c) || null;
  const [open, setOpen] = useState(false);

  const openDataHandler = () => {
    setOpen(prev => !prev)
  }

  return (
    <>
      <Box sx={{display: 'flex', justifyContent: 'flex-start', cursor: 'pointer'}} onClick={openDataHandler}>
        <Typography style={blockTitle}>Справочные данные</Typography>
        {open ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ShortInfo properties_1c={properties_1c} properties1CMap={propertiesMap}/>
      </Collapse>
    </>
  )
}

export default ShortInfoBlock;
