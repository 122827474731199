import React from 'react';
import {useSelector} from "react-redux";

const NewPlot = ({coordCorrection}) => {
  const newPlotPx = useSelector(state => state.forestUseReportReducer.newPlotPx)
  return (
    <>
      <polygon
        i={'newPlotPolygon_none'}
        points={newPlotPx.map((point) =>
          `${coordCorrection(point.x)}, ${coordCorrection(point.y)}`).join(' ')}
        fill={"#f44336"}
        strokeOpacity={1}
        fillOpacity={0.2}
        stroke={'#f44336'}
        strokeWidth={4}
      />
      {newPlotPx.map((point, i) =>
        <circle
          i={`newPlotPoint_${i}`}
          key={i}
          cx={coordCorrection(point.x)}
          cy={coordCorrection(point.y)}
          r={6}
          fill={"#f44336"}
          opacity={1}
        />
      )}
    </>
  );
};

export default NewPlot;