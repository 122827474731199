import {otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {setPhotoAttr} from "./stockpilePhotoReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";
import {createCAStockpile, deleteCAStockpile} from "../../components/Main/LeftPanel/Context/CAs/stockpiles";

const  SET_STOCKPILES_ATTR = 'SET_STOCKPILES_ATTR'
const  ADD_STOCKPILE_IN_PLOTS_LIST = 'ADD_STOCKPILE_IN_PLOTS_LIST'
const  DELETE_STOCKPILE_FROM_PLOTS_LIST = 'DELETE_STOCKPILE_FROM_PLOTS_LIST'
const  UPDATE_STOCKPILE_IN_PLOTS_LIST = 'UPDATE_STOCKPILE_IN_PLOTS_LIST'
const  DELETE_PHOTO_FROM_STOCKPILE = 'DELETE_PHOTO_FROM_STOCKPILE'
const  SET_PHOTOS_TO_STOCKPILE = 'SET_PHOTOS_TO_STOCKPILE'
const  REPLACE_PHOTO_IN_STOCKPILE = 'REPLACE_PHOTO_IN_STOCKPILE'
const  REPLACE_PLOT_IN_PLOTS_LIST = 'REPLACE_PLOT_IN_PLOTS_LIST'

let initialState = {
  //plots data
  plotsStockpiles: [],
  selectedPlot: null,
  plotsFilters: { //параметры для группировки делян
    owner: {status: false, name: 'Владелец'},
    rental_contract: {status: false, name: 'Рабочая область'},
    declaration: {status: false, name: 'Декларация'},
    status: {status: false, name: 'Статус деляны'}
  },

  //stockpiles data
  stockpiles: [],
  selectedStockpile: null,
  isCreateStockpile: {status: false, plotId: null},
  isUpdateStockpile: {status: false, stockpileId: null},
  stockpileDataLoading: false,

  //photos
  savePhotosLoading: false
}

// редьюсер для работы с данными на странице "замер штабелей"
export const stockpilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STOCKPILES_ATTR: {
      return {...state, ...action.payload};
    }
    case ADD_STOCKPILE_IN_PLOTS_LIST: {
      return {...state, plotsStockpiles: state.plotsStockpiles.map(plot => {
        if (plot.id !== action.plotId) {
          return plot
        } else {
          return {...plot, stockpiles: [...plot.stockpiles, action.stockpile]}
        }
        })};
    }
    case DELETE_STOCKPILE_FROM_PLOTS_LIST: {
      return {...state, plotsStockpiles: state.plotsStockpiles.map(plot => {
          if (plot.id !== action.plotId) {
            return plot
          } else {
            return {...plot, stockpiles: plot.stockpiles.filter(item => item.id !== action.stockpileId)}
          }
        })};
    }
    case UPDATE_STOCKPILE_IN_PLOTS_LIST: {
      return {...state,
        plotsStockpiles: state.plotsStockpiles.map(plot =>{
          if (action.plotId !== plot.id) {
            return plot
          } else {
            return {...plot,
              stockpiles: plot.stockpiles.map(stockpile => {
                if (action.stockpile.id !== stockpile.id) {
                  return stockpile
                } else {
                  return action.stockpile
                }
              })}
          }
        })
      }
    }
    case DELETE_PHOTO_FROM_STOCKPILE: {
      const images = state.selectedStockpile.images
      const newPhotosList = images.filter(image => image.id !== action.photoId)
      return {...state,
        selectedStockpile: {
        ...state.selectedStockpile,
          images: newPhotosList},
      }
    }
    case SET_PHOTOS_TO_STOCKPILE: {
      return {...state,
        selectedStockpile: {...state.selectedStockpile, images: [...state.selectedStockpile.images, ...action.photos]}
      }
    }
    case REPLACE_PHOTO_IN_STOCKPILE: {
      return {...state,
        selectedStockpile: {...state.selectedStockpile,
          images: state.selectedStockpile.images.map(image => {
            if (action.photo.id !== image.id) {
              return image
            } else {
              return action.photo
            }
          })}
      }
    }
    case REPLACE_PLOT_IN_PLOTS_LIST: {
      return {...state,
        plotsStockpiles: state.plotsStockpiles.map(plot => {
          if (plot.id !== action.plot.id) {
            return plot
          }
          return action.plot
        })
      }
    }
    default: return state
  }}

export const setStockpilesAttr = (payload) => ({type: SET_STOCKPILES_ATTR, payload});
export const replacePlotInPlotsList = (plot) => ({type: REPLACE_PLOT_IN_PLOTS_LIST, plot});
export const addStockpileInPlotsList = (stockpile, plotId) => ({type: ADD_STOCKPILE_IN_PLOTS_LIST, stockpile, plotId});
export const deleteStockpileFromPlotsList = (stockpileId, plotId) => ({type: DELETE_STOCKPILE_FROM_PLOTS_LIST, stockpileId, plotId});
export const updateStockpileInPlotsList = (stockpile, plotId) => ({type: UPDATE_STOCKPILE_IN_PLOTS_LIST, plotId, stockpile});
export const deletePhotoFromSelectedStockpile = (photoId) => ({type: DELETE_PHOTO_FROM_STOCKPILE, photoId});
export const setPhotosToSelectedStockpile = (photos) => ({type: SET_PHOTOS_TO_STOCKPILE, photos});

export const replacePhotoInSelectedStockpile = (photo) => ({type: REPLACE_PHOTO_IN_STOCKPILE, photo});

export const getPlotsStockpilesThunk = () => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.stockpiles.getPlotsStockpiles()
      dispatch(setStockpilesAttr({plotsStockpiles: res.data}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const patchPlotsStockpileThunk = (plotId, data) => {
  return async (dispatch, getState) => {
    try {
      let res = await otherDataAPI.stockpiles.patchPlotsStockpile(plotId, data)
      let updatedPlot = res.data
      dispatch(replacePlotInPlotsList(updatedPlot))
      dispatch(setStockpilesAttr({selectedPlot: {...getState().stockpilesReducer.selectedPlot, ...data}}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
export const getStockpileThunk = (stockpileId) => {
  return async (dispatch) => {
    try {
      dispatch(setStockpilesAttr({stockpileDataLoading: true}))
      let res = await otherDataAPI.stockpiles.getStockpile(stockpileId)
      dispatch(setStockpilesAttr({selectedStockpile: res.data}))
      if (res.data.images.length) {
        let firstImage =  res.data.images[0]
        dispatch(setPhotoAttr({selectedPhoto: firstImage}))
      }
      dispatch(setStockpilesAttr({stockpileDataLoading: false}))
    } catch (e) {
      dispatch(setStockpilesAttr({stockpileDataLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const createStockpileThunk = (data, plot) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.stockpiles.createStockpile(data)
      createCAStockpile(plot.id, res.data)
      dispatch(addStockpileInPlotsList(res.data, data.xplot))
      dispatch(setStockpilesAttr({isCreateStockpile: {status: false, plotId: null}}))
      dispatch(setSnack('success', "Штабель создан"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const patchStockpileThunk = (stockpileId, plotId, data) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.stockpiles.patchStockpile(stockpileId, data)
      dispatch(updateStockpileInPlotsList(res.data, plotId))
      dispatch(setStockpilesAttr({isUpdateStockpile: {status: false, stockpileId: null}}))
      dispatch(setSnack('success', "Штабель изменен"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const deleteStockpileThunk = (stockpileId, plotId) => {
  return async (dispatch, getState) => {
    try {
      await otherDataAPI.stockpiles.deleteStockpile(stockpileId)
      deleteCAStockpile(plotId, stockpileId)
      dispatch(deleteStockpileFromPlotsList(stockpileId, plotId))
      if(getState().stockpilesReducer.selectedStockpile?.id === stockpileId) {
        dispatch(setPhotoAttr({selectedPhoto: null}))
        dispatch(setStockpilesAttr({selectedStockpile: null}))
      }
      dispatch(setSnack('success', "Штабель удален"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const uploadPhotosThunk = (files, stockpileId) => {
  return async (dispatch) => {
    try {
      dispatch(setStockpilesAttr({savePhotosLoading: true}))
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append(`images`, files[i])
      }
      formData.append('stockpile_id', stockpileId)
      let res = await otherDataAPI.stockpiles.savePhotos(formData)
      dispatch(setSnack('success', "Фотографии загружены"))
      dispatch(setStockpilesAttr({savePhotosLoading: false, selectedStockpile: res.data}))
    } catch (e) {
      dispatch(setStockpilesAttr({savePhotosLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const deletePhotoThunk = (photoId, images) => {
  return async (dispatch) => {
    try {
      await otherDataAPI.stockpiles.deletePhoto(photoId)
      dispatch(deletePhotoFromSelectedStockpile(photoId))
      const deletedPhotoIndex = images.findIndex(image => image.id === photoId)
      let newSelectedPhotoIndex;
      if (images.length === 1) {
        newSelectedPhotoIndex = null
      } else if (deletedPhotoIndex === images.length - 1) {
        newSelectedPhotoIndex = deletedPhotoIndex - 1
      } else {
        newSelectedPhotoIndex = deletedPhotoIndex + 1
      }
      let newSelectedPhoto = newSelectedPhotoIndex !== null ? images[newSelectedPhotoIndex] : null
      dispatch(setPhotoAttr({selectedPhoto: newSelectedPhoto}))
      dispatch(setSnack('success', "Фотография удалена"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const deleteAllPhotosThunk = (stockpile) => {
  return async (dispatch, getState) => {
    try {
      const selectedStockpile = getState().stockpilesReducer.selectedStockpile
      await otherDataAPI.stockpiles.deleteAllPhotos(stockpile.id)
      let newStockpile = {...stockpile, images: []}
      dispatch(updateStockpileInPlotsList(newStockpile))
      if (selectedStockpile.id === stockpile.id) {
        dispatch(setStockpilesAttr({selectedStockpile: newStockpile}))
        dispatch(setPhotoAttr({selectedPhoto: null}))
      }
      dispatch(setSnack('success', "Фотографии удалены"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
