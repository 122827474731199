import DynamicFeedSharpIcon from '@mui/icons-material/DynamicFeedSharp';
import {IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import {overlayButtonStyle} from "../../Map/Common/Styles";
import {showBaseLayersWindows} from "./BaseLayersReducer";
import {useDispatch, useSelector} from "react-redux";
import {baseLayersTitleString} from "../../Map/Common/Strings_RU";
import {hideAllOverlayWindows} from "../../Map/Common/MiscFunction";

export function BaseLayersButton() {
  const state = useSelector(state => state.baseLayersReducer)
  const dispatch = useDispatch()

  function buttonClickHandler() {
    dispatch(hideAllOverlayWindows())
    dispatch(showBaseLayersWindows(!state.visible))
  }

  return (
    <Box style={overlayButtonStyle} id={'layersButton'}>
      <IconButton onClick={buttonClickHandler} style={{padding: 5}} title={baseLayersTitleString}>
        <DynamicFeedSharpIcon fontSize={'small'}/>
      </IconButton>
    </Box>
  )
}
