import {useDispatch, useSelector} from "react-redux";
import ModalPage from "../../../../Common/ModalPage";
import {
  caDeclarationVolumeString,
  caExportVolumeString,
  caHarvestVolumeString,
  caLastComplexNameString,
  caLastTeamString,
  caMeasurementVolumeString,
  caPlanVolumeString,
  infoText,
  saveString
} from "../../../Map/Common/Strings_RU";
import React, {useEffect, useState} from "react";
import {MenuItem, Modal, Select, Stack, TextField} from "@mui/material";
import {getCuttingAreas} from "../../../LeftPanel/Context/CAs/CuttingsAreas";
import {checkForFloat} from "../../../Map/Common/MiscFunction";
import {patchPlotInfoDataThunk, setRightPanelAttr} from "../../../../../redux/reducers/rightPanelReducer";

function CADataDialogWindow({plotData, currentPlotStatus}) { //Какошко данных деляны, введенных вручную

  const dispatch = useDispatch()
  const {properties_1c: properties1c, status, id} = plotData || {}
  const [caStatus, setCaStatus] = useState(status ? status : '')
  const [caPlanVolume, setCAPlanVolume] = useState({value: properties1c['ПланВырубки'] || 0, error: false})
  const [caDeclarationVolume, setCADeclarationVolume] = useState({value: properties1c['ОбъемПоДекларации'] || 0, error: false})
  const [caLZK, setCALZK] = useState({value: properties1c['ПоследняяБригада'] || '', error: false})
  const [caHarvestVolume, setCAHarvestVolume] = useState({value: properties1c['ОбъемПоЗаготовке'] || 0, eroor: false})
  const [caMeasuringVolume, setCAMeasuringVolume] = useState({value: properties1c['ОбъемПоЗамеру'] || 0, error: false})
  const [caExportingVolume, setCAExportingVolume] = useState({value: properties1c['ОбъемПоВывозке'] || 0, error: false})
  const [complex, setComplex] = useState({value: properties1c['АббревиатураТехники'] || '', error: false})

  const statuses = getCuttingAreas().defaultStatusFilters;

  useEffect(() => {
    setCaStatus(currentPlotStatus)
  }, [])

  function saveData() {
    const planVolume = checkForFloat(caPlanVolume.value)
    if (isNaN(planVolume)) {
      setCAPlanVolume({value: caPlanVolume.value, error: true})
      return;
    }
    const declarationVolume = checkForFloat(caDeclarationVolume.value)
    if (declarationVolume && isNaN(declarationVolume)) {
      setCADeclarationVolume({value: caDeclarationVolume.value, error: true})
      return;
    }
    const harvestVolume = checkForFloat(caHarvestVolume.value)
    if (harvestVolume && isNaN(harvestVolume)) {
      setCAHarvestVolume({value: caHarvestVolume.value, error: true})
      return;
    }
    const measuringVolume = checkForFloat(caMeasuringVolume.value)
    if (measuringVolume && isNaN(measuringVolume)) {
      setCAMeasuringVolume({value: caMeasuringVolume.value, error: true})
      return;
    }
    const exportingVolume = checkForFloat(caExportingVolume.value)
    if (exportingVolume && isNaN(exportingVolume)) {
      setCAExportingVolume({value: caExportingVolume.value, error: true})
      return;
    }
    if (complex.value.length > 20) {
      setComplex({value: complex.value, error: true})
      return;
    }

    const json = {
      status: caStatus,
      'ПланВырубки': planVolume,
      'ОбъемПоДекларации': declarationVolume,
      'ПоследняяБригада': caLZK.value,
      'ОбъемПоЗаготовке': harvestVolume,
      'ОбъемПоЗамеру': measuringVolume,
      'ОбъемПоВывозке': exportingVolume,
      'АббревиатураТехники': complex.value,
    }
    dispatch(patchPlotInfoDataThunk(id, json))
  }

  const statusIndex = statuses.indexOf(caStatus)

  let ind = 0;
  return (
    <ModalPage
      title={infoText}
      handleClose={() => dispatch(setRightPanelAttr({showCADataDialogWindow: false}))}
      actionButtonName={saveString}
      actionButtonHandler={saveData}
    >
      <Stack direction={'column'} style={{marginTop: '1vh', marginBottom: '1vh'}} spacing={2}>
        <Select
          variant={'outlined'}
          size={'small'}
          labelId="ca_data_status_label_id"
          value={caStatus}
          onChange={(e) => setCaStatus(e.target.value)}
        >
          {statuses.map(status => {
            return <MenuItem value={status} key={ind++}>{status}</MenuItem>
          })}
        </Select>
        <TextField
          size={'small'}
          label={caPlanVolumeString + ' м³'}
          autoComplete='off'
          error={caPlanVolume.error}
          value={caPlanVolume.value}
          onChange={(e) => setCAPlanVolume({value: e.target.value, error: false})}
        />
        <TextField
          disabled={!(statusIndex > 0)}
          size={'small'}
          label={caDeclarationVolumeString + ' м³'}
          autoComplete='off'
          error={caDeclarationVolume.error}
          value={caDeclarationVolume.value}
          onChange={(e) => setCADeclarationVolume({value: e.target.value, error: false})}
        />
        <TextField
          disabled={!(statusIndex > 1)}
          size={'small'}
          label={caLastTeamString}
          autoComplete='off'
          value={caLZK.value}
          onChange={(e) => setCALZK({value: e.target.value, error: false})}
        />
        <TextField
          disabled={!(statusIndex > 1)}
          size={'small'}
          label={caLastComplexNameString}
          autoComplete='off'
          error={complex.error}
          value={complex.value}
          inputProps={{ maxLength: 20 }}
          onChange={(e) => setComplex({value: e.target.value, eror: false})}
        />
        <TextField
          disabled={!(statusIndex > 1)}
          size={'small'}
          label={caHarvestVolumeString + ' м³'}
          autoComplete='off'
          error={caHarvestVolume.error}
          value={caHarvestVolume.value}
          onChange={(e) => setCAHarvestVolume({value: e.target.value, error: false})}
        />
        <TextField
          disabled={!(statusIndex > 2)}
          size={'small'}
          label={caMeasurementVolumeString + ' м³'}
          autoComplete='off'
          error={caMeasuringVolume.error}
          value={caMeasuringVolume.value}
          onChange={(e) => setCAMeasuringVolume({value: e.target.value, error: false})}
        />
        <TextField
          disabled={!(statusIndex > 3)}
          size={'small'}
          label={caExportVolumeString + ' м³'}
          autoComplete='off'
          error={caExportingVolume.error}
          value={caExportingVolume.value}
          onChange={(e) => setCAExportingVolume({value: e.target.value, error: false})}
        />
      </Stack>
    </ModalPage>
  )
}

export function CADataDialog() {
  const plotData = useSelector(state => state.rightPanelReducer.plotData)
  const selectedElement = useSelector(state => state.rightPanelReducer.selectedElement)
  const showCADataDialogWindow = useSelector(state => state.rightPanelReducer.showCADataDialogWindow)
  const CADataDialogWindowTransition = React.forwardRef(() =>
    <CADataDialogWindow plotData={plotData} currentPlotStatus={selectedElement?.status}/>)

  if (!plotData || !selectedElement) return null;

  return (
    <Modal open={showCADataDialogWindow}>
      <CADataDialogWindowTransition/>
    </Modal>
  )
}
