import {dataAPI, otherDataAPI} from "../../api/api";
import {cannotIDo, getTariff, updateTariffOrgInfoLimits} from "../Main/Map/Common/tariffs";
import {handleErrors} from "../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {dispatch} from "../Common/misc_functions";
import {changeOrgPageAttrs, defaultState, defaulUserData} from "./organizationPageReducer";
import {setSnack} from "../Main/Map/Common/Dialog/Snack/snackReducer";
import {changeWorkspaceIsOK, creatingWorkspaceIsOK, savingSuccess} from "../Main/Map/Common/Strings_RU";
import {addWorkspaceEverywere, renameWorkspaceEverywere} from "../Main/LeftPanel/Context/Wokspaces/workspaces";
import {hideMapDialog, showMapDialog} from "../Main/Map/Common/Dialog/DialogReducer";
import {urlGenerator} from "../../utils/urlGenerator";
import {getMap1} from "../Main/Map/GlobalObjects";

export const casStatusesMap = {
  show_archive_plots: "В архиве",
  show_closed_plots: "Закрыта",
  show_declared_plots: "Задекларирована",
  show_developed_plots: "Разработана",
  show_development_plots: "В разработке",
  show_removal_plots: "Идет вывозка",
  show_reserved_plots: "Отведена",
}

export function getOrganizationData() {
  dispatch(changeOrgPageAttrs({
    organizationData: null,
    organizationDataLoading: true,
    organizationDataLoadingError: true
  }))
  otherDataAPI.organization.getOrganizationData().then(res => {
    updateTariffOrgInfoLimits(res.data)
    dispatch(changeOrgPageAttrs({
      organizationData: res.data,
      organizationDataLoading: false,
      organizationDataLoadingError: false
    }))
  })
    .catch(err => {
      handleErrors(dispatch, err)
      dispatch(changeOrgPageAttrs({
        organizationData: null,
        organizationDataLoading: false,
        organizationDataLoadingError: true
      }))
    })
}

export function getWorkspaces() {
  dispatch(changeOrgPageAttrs({workspacesData: null, workspacesDataLoading: true, workspacesDataLoadingError: false}))
  otherDataAPI.workspaces.getOrganizationWorkspaces().then(res => {
    dispatch(changeOrgPageAttrs({
      workspacesData: res.data,
      workspacesDataLoading: false,
      workspacesDataLoadingError: false
    }))
  }).catch(() => {
    dispatch(changeOrgPageAttrs({workspacesData: null, workspacesDataLoading: false, workspacesDataLoadingError: true}))
  })
}

export function getEmployees() {
  dispatch(changeOrgPageAttrs({employeesData: null, employeesDataLoading: true, employeesDataLoadingError: false}))
  otherDataAPI.organization.getOrganizationUsers().then(res => {
    dispatch(changeOrgPageAttrs({
      employeesData: res.data,
      employeesDataLoading: false,
      employeesDataLoadingError: false
    }))
  }).catch(() => {
    dispatch(changeOrgPageAttrs({employeesData: null, employeesDataLoading: false, employeesDataLoadingError: true}))
  })
}

export function createWorkspace(name) {
  const workspaces = window.store.getState().organizationPageReducer.workspacesData;
  dataAPI.workspaces.create({number: name}).then(res => {
    dispatch(changeOrgPageAttrs({
      workspacesData: [...workspaces, res.data],
      selectedWorkspace: null,
      createWorkspaceMode: false,
    }))
    addWorkspaceEverywere(res.data)
    dispatch(setSnack('success', creatingWorkspaceIsOK))
  })
    .catch(err => {
      handleErrors(dispatch, err)
    })
}

export function renameWorkspace(id, data) {
  const workspaces = window.store.getState().organizationPageReducer.workspacesData;
  const ind = workspaces.findIndex(workspace => workspace.id === id)
  dataAPI.workspaces.patch(data, id).then(res => {
    if (ind !== -1) {
      const workspace = workspaces[ind]
      if (data.number) {
        workspace.number = data.number;
      }
    }
    renameWorkspaceEverywere(res.data)
    dispatch(setSnack('success', changeWorkspaceIsOK))
  })
    .catch(err => {
      handleErrors(dispatch, err)
    })
}

export async function changeUsersInWorkspace(id, users) {
  const json = users.map(user => user.id)
  const res = await otherDataAPI.workspaces.addUsersInWorkspaces(id, json)
  const workspaces = window.store.getState().organizationPageReducer.workspacesData;
  const workspace = workspaces.find(workspace => workspace.id === id)

  if (workspace) { //change OP workspaces
    workspace.users = res.data;
    dispatch(changeOrgPageAttrs({workspacesData: [...workspaces]}))
  }
  return res;
}

//Component functions
export function addAllUsersInWorkspacePermissionaly(workspace, organizationUsers) {
  const iAMOwner = !cannotIDo.ownerAction()
  const myID = getTariff().userID;

  workspace.users = organizationUsers.filter(user => {
    if (iAMOwner || !user.is_admin) {
      return true;
    } else {
      return workspace.users.find(u => u.id === user.id) || user.id === myID;
    }
  })
}

export function deleteAllUsersFromWorkspacePermissionally(workspace) {
  const myID = getTariff().userID;
  workspace.users = workspace.users.filter(user => user.is_admin && user.id !== myID)
}

export function treeButtonDialog(header, text, callback) {
  const buttons = {
    'Сохранить': {color: 'primary'},
    "Не сохранять": {color: 'error'},
    "Отмена": {color: 'warning'}
  }
  const dispatch = window.elz_dispatch;
  dispatch(showMapDialog('warning', header, text, buttons, (value) => {
    dispatch(hideMapDialog())
    callback(value)
  }))
}

export function createUser(data) {
  otherDataAPI.organizationUsers.addUserToOrganization(data).then(res => {
    const employees = window.store.getState().organizationPageReducer.employeesData;
    employees.push(res.data)
    dispatch(changeOrgPageAttrs({employeesData: [...employees], createUserMode: false, userData: {...defaulUserData}}))
    dispatch(setSnack("success", "Пользователь создан"))
  }).catch(err => handleErrors(dispatch, err))
}

export function updateUser(data) {
  otherDataAPI.organizationUsers.updateUserData(data).then(() => {
    const employees = window.store.getState().organizationPageReducer.employeesData;
    let userIndex = employees.findIndex(user => user.id === data.id)
    if (userIndex !== -1) {
      employees[userIndex] = {...employees[userIndex], ...data}
      //dispatch(changeOrgPageAttrs({employeesData: [...employees]}))
      dispatch(changeOrgPageAttrs({userData: null, createUserMode: false}))
      dispatch(setSnack('success', 'Пользователь изменен'))
    }
  }).catch(err => handleErrors(dispatch, err))
}

export function deleteUser(id) {
  otherDataAPI.organizationUsers.deleteUserFromOrganization(id).then(() => {
    const employees = window.store.getState().organizationPageReducer.employeesData;
    const userIndex = employees.findIndex(user => user.id === id)
    employees.splice(userIndex, 1)
    dispatch(changeOrgPageAttrs({employeesData: [...employees]}))
    dispatch(setSnack('success', 'Пользователь удален.'))
  }).catch(err => handleErrors(dispatch, err))
}

export function updateUserWorkspaces(user) {
  const data = window.store.getState().organizationPageReducer.allWorkspaces;
  const arr = data.filter(wp => wp.checked).map(wp => wp.id)
  const json = {"rental_contracts_to_allow": arr}
  dispatch(changeOrgPageAttrs({dialogSaving: true}))
  otherDataAPI.organizationUsers.setAllAllowRentalContract(user.id, json).then(() => {
    const myID = getTariff().userID;
    const workspaces = window.store.getState().organizationPageReducer.workspacesData;
    const userObj = window.store.getState().organizationPageReducer.employeesData.find(u => u.id === user.id)

    if (user.id === myID) {
      addGlobalToMeChanged()
    }

    workspaces.forEach(wp => {
      const ind = wp.users.findIndex(u => u.id === userObj.id)
      if (arr.indexOf(wp.id) === -1) {
        if (ind !== -1) {
          wp.users.splice(ind, 1)
        }
      } else {
        if (ind === -1) {
          wp.users.push(userObj)
        }
      }
    })
    dispatch(changeOrgPageAttrs({
      workspacesData: [...workspaces],
      dialogSaving: false,
      changesInDialog: null,
      allCas: null,
      oldCas: null,
      allRoads: null,
      oldRoads: null,
    }))
    dispatch(setSnack('success', 'Доступ к договорам сохранен'))
  }).catch(err => {
    handleErrors(dispatch, err)
    dispatch(changeOrgPageAttrs({dialogSaving: false}))
  })
}

export function updateUserCAsStatuses(user) {
  const data = window.store.getState().organizationPageReducer.allCas;
  dispatch(changeOrgPageAttrs({dialogSaving: true}))
  otherDataAPI.organizationUsers.setAllAllowRentalContractPlots(user.id, {rental_contracts_plots: data})
    .then(() => {
      const myID = getTariff().userID;
      if (user.id === myID) {
        addGlobalToMeChanged()
      }
      dispatch(changeOrgPageAttrs({dialogSaving: false, oldCas: structuredClone(data), changesInDialog: null}))
      dispatch(setSnack('success', savingSuccess))
    })
    .catch(err => {
      dispatch(changeOrgPageAttrs({dialogSaving: false}))
      handleErrors(dispatch, err)
    })
}

export function updateUserRoadsAccess(user) {
  const data = window.store.getState().organizationPageReducer.allRoads;
  dispatch(changeOrgPageAttrs({dialogSaving: true}))
  otherDataAPI.organizationUsers.setAllAllowRentalContractRoads(user.id, {rental_contracts_roads: data})
    .then(() => {
      const myID = getTariff().userID;
      if (user.id === myID) {
        addGlobalToMeChanged()
      }
      dispatch(changeOrgPageAttrs({dialogSaving: false, oldRoads: structuredClone(data), changesInDialog: null}))
      dispatch(setSnack('success', savingSuccess))
    })
    .catch(err => {
      dispatch(changeOrgPageAttrs({dialogSaving: false}))
      handleErrors(dispatch, err)
    })
}

export function modulesSave(userId) {
  const modules = window.store.getState().organizationPageReducer.allModules;
  const arr = modules.map(module => {
    return {id: module.id, access: module.access, write: module.write}
  })
  dispatch(changeOrgPageAttrs({dialogSaving: true}))
  otherDataAPI.organizationUsers.setModulesAccessInfo(userId, arr).then(() => {
    const myID = getTariff().userID;
    if (userId === myID) {
      addGlobalToMeChanged()
    }
    dispatch(changeOrgPageAttrs({changesInDialog: null, oldModules: structuredClone(modules), dialogSaving: false}))
    dispatch(setSnack('success', 'Доступ к модулям успешно сохранен.'))
  }).catch(err => {
    dispatch(changeOrgPageAttrs({dialogSaving: false}))
    handleErrors(dispatch, err)
  })
}

//Me Changed function
export function addWorkspaceInMeChanged(id) {
  const meChanged = structuredClone(window.store.getState().organizationPageReducer.meChanged)
  const ind = meChanged.workspaces.findIndex(item => item.id === id)
  if (ind === -1) {
    meChanged.workspaces.push({type: 'add', id: id})
    dispatch(changeOrgPageAttrs({meChanged: {...meChanged}}))
  } else {
    if (meChanged.workspaces[ind].type === 'delete') {
      meChanged.workspaces.splice(ind, 1)
      dispatch(changeOrgPageAttrs({meChanged: {...meChanged}}))
    }
  }
}

export function removeWorkspaceInMeChanged(id) {
  const meChanged = structuredClone(window.store.getState().organizationPageReducer.meChanged)
  const ind = meChanged.workspaces.findIndex(item => item.id === id)
  if (ind === -1) {
    meChanged.workspaces.push({type: 'delete', id: id})
    dispatch(changeOrgPageAttrs({meChanged: {...meChanged}}))
  } else {
    if (meChanged.workspaces[ind].type === 'add') {
      meChanged.workspaces.splice(ind, 1)
      dispatch(changeOrgPageAttrs({meChanged: {...meChanged}}))
    }
  }
}

export function addWorkspaceCAsStatusesToMeChanged(id) {
  const meChanged = structuredClone(window.store.getState().organizationPageReducer.meChanged)
  const ind = meChanged.cas.findIndex(item => item.id === id)
  if (ind === -1) {
    meChanged.cas.push({type: 'change', id: id})
    dispatch(changeOrgPageAttrs({meChanged: {...meChanged}}))
  }
}

export function addGlobalToMeChanged() {
  const meChanged = structuredClone(window.store.getState().organizationPageReducer.meChanged)
  meChanged.global = true;
  dispatch(changeOrgPageAttrs({meChanged: {...meChanged}}))
}

//OP exit handler
export function OPExitHandler(navigate) {
  const state = window.store.getState().organizationPageReducer;
  const meChanged = state.meChanged;
  const saving = state.globalChanged || state.dialogSaving;
  if (getMap1() && (saving || meChanged.cas.length || meChanged.workspaces.length || meChanged.global)) {
    window.location.replace(window.location.origin + '/' + urlGenerator().main)
  } else {
    navigate(urlGenerator().main)
  }
  dispatch(changeOrgPageAttrs(structuredClone(defaultState)))
}
