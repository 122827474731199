
export function getSentinelDate(strangeDate) {
  let date = structuredClone(strangeDate)
  if (date['$d'])
    date = date['$d']
  if (date instanceof Date) {
    let month = date.getMonth()+1;
    if (month < 10)
      month = '0' + month;
    let day = date.getDate();
    if (day < 10)
      day = '0' + day;
    return `${date.getFullYear()}-${month}-${day}`
  }
  return null;
}

export function localizeDateTimeFormat(date, time) {
  const dat = new Date(date)
  return dat.toLocaleDateString('ru-RU')+' '+time;
}

export function removeTime(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
}

export function formatDateFromObjToStr(date) { //from Date to dd.mm.yyyy
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}.${month}.${year}`;
}

export function formatDateFromStrToObj(date) { //from dd.mm.yyyy to new date
  const [day, month, year] = date.split(".");
  return new Date(year, month - 1, day);
}

export function timestampToStandardDate(timestamp) {//timestamp to YYYY-MM-DD
  const date = new Date(timestamp)
  return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export function getTMDatesURLPart(dateMin, dateMax) {//timestamps to get reqyest url part
  if (!dateMin || !dateMax)
    return '';
  return `?date_start=${timestampToStandardDate(dateMin)}&date_finish=${timestampToStandardDate(dateMax)}`;
}

export function getPrevMonthDates() {
  const now = new Date()
  now.setDate(0)
  const last = now;
  const first = new Date(last)
  first.setDate(1)
  return [first, last]
}

export function getCurrentMonthDates() {
  const now = new Date()
  const last = new Date(now.getFullYear(), now.getMonth() + 1, 0)
  now.setDate(1)
  return [now, last]
}

export function getPrevMonthDateString() {
  const dates = getPrevMonthDates()
  return `${formatDateFromObjToStr(dates[0])}-${formatDateFromObjToStr(dates[1])}`;
}

export function getCurrentMonthDateString() {
  const dates = getCurrentMonthDates()
  return `${formatDateFromObjToStr(dates[0])}-${formatDateFromObjToStr(dates[1])}`;
}

export function getCurrentDateString() {
  return formatDateFromObjToStr(new Date())
}
