import React, {useState} from 'react';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  TableCell,
  Typography,
  InputAdornment,
  useTheme
} from "@mui/material";
import {getReferenceThunk} from "../../../../../../redux/reducers/referencesReducer";
import {useDispatch, useSelector} from "react-redux";

const FigureParameterAutocomplete = ({rowData, onChangeAutocompleteHandler}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const references = useSelector(state => state.referencesReducer.references)
  const referenceLoading = useSelector(state => state.referencesReducer.referenceLoading)
  const onOpenHandler = () => {
    !references[rowData.referenceName].length &&  dispatch(getReferenceThunk(rowData.referenceName))
  }
  return (
      <Autocomplete
        disablePortal
        size={"small"}
        options={references[rowData.referenceName] || []}
        value={rowData.value || null}
        onOpen={onOpenHandler}
        isOptionEqualToValue={(option, value) =>
          option?.name === value?.name
        }
        getOptionLabel={(option) => option?.name || ''}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                style: {padding : '0', color: rowData.isStateData && theme.palette.primary.light},
                endAdornment: (
                  <InputAdornment position='end'>
                    {referenceLoading.status && referenceLoading.reference
                    === rowData.referenceName ? <CircularProgress sx={{color: 'primary.dark'}} size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position='start'>
                    {rowData.label === 'Длина' && <Typography>м</Typography>}
                  </InputAdornment>
                )
              }}
            />
          )
        }}
        onChange={(e, value) => onChangeAutocompleteHandler(rowData.label, value)}
      />
  );
};

export default FigureParameterAutocomplete;