import React, {useEffect} from 'react';
import {Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import {tableTextStyle} from "../../../rightbarStyles";
import FigureParameterTextField from "../FigureParameterTextField/FigureParameterTextField";
import FigureParameterAutocomplete from "../FigureParameterAutocomplete/FigureParameterAutocomplete";
import {useDispatch, useSelector} from "react-redux";
import {setPhotoAttr} from "../../../../../../redux/reducers/stockpilePhotoReducer";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import {toolbarIconStyle} from "../../../../Toolbar/toolbarStyles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


const SelectedFigureDataTable = ({selectedFigure}) => {
  const dispatch = useDispatch()
  const figureParameters = useSelector(state => state.stockpilePhotoReducer.figureParameters)

  useEffect(() => {
    dispatch(setPhotoAttr({
      figureParameters: {
        volume: selectedFigure?.volume || '',
        species: selectedFigure?.species || figureParameters.species,
        length: selectedFigure?.length || figureParameters.length,
        variety: selectedFigure?.variety || figureParameters.variety,
        rate: figureParameters.rate,
      }
    }))
  }, [selectedFigure])

  const tableRowsData = [
    {label: 'Объем', value: figureParameters.volume, inputType: 'textField',},
    {label: 'Порода', value: figureParameters.species, inputType: 'autocomplete', referenceName: 'species',
      isStateData: !(!!selectedFigure?.species)},
    {label: 'Длина(м)', value: figureParameters.length, inputType: 'autocomplete', referenceName: 'length',
      isStateData: !(!!selectedFigure?.length)},
    {label: 'Сортимент', value: figureParameters.variety, inputType: 'autocomplete', referenceName: 'varieties',
      isStateData: !(!!selectedFigure?.variety)},
    {label: 'Коэффициент', value: figureParameters.rate, inputType: 'textField',
      inputProps: {max: 1, min: 0, step: 0.01}, isStateData: !(!!selectedFigure?.rate)}
  ]

  const onChangeAutocompleteHandler = (rowLabel, rowValue) => {
    switch (rowLabel) {
      case 'Порода':
        dispatch(setPhotoAttr({figureParameters: {...figureParameters, species: rowValue}}))
        break
      case 'Длина(м)':
        dispatch(setPhotoAttr({figureParameters: {...figureParameters, length: rowValue}}))
        break
      case 'Сортимент':
        dispatch(setPhotoAttr({figureParameters: {...figureParameters, variety: rowValue}}))
        break
      case 'Коэффициент':
        if (+rowValue <= 1) {
          dispatch(setPhotoAttr({figureParameters: {...figureParameters, rate: rowValue}}))
        }
        break
    }
  }

  return (
    <TableContainer sx={{width: '100%'}}>
      <Table size={"small"} sx={{p: 0, [`& .${tableCellClasses.root}`]: {borderBottom: "none"}, width: '100%'}}>
        <TableBody>
          {tableRowsData.map(rowData => (
            <TableRow key={rowData.label}>
              <TableCell sx={{display: 'flex', alignItems: 'end', width: '30%'}}>
                {rowData.label === 'Коэффициент' &&
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography sx={tableTextStyle}>{`${rowData.label} `}</Typography>
                    <Tooltip title={<Typography sx={{fontSize: 16}}>Отношение площади древесины к площади
                      области штабеля на фото</Typography>}>
                      <InfoOutlinedIcon sx={{color: 'primary.light', fontWeight: 300, fontSize: 18, ml: 1}}/>
                    </Tooltip>
                  </Stack>
                }
                {rowData.label === 'Объем' &&
                  <>
                    <Typography sx={tableTextStyle}>Объем </Typography>
                    <Typography sx={tableTextStyle}>(м<sup>3</sup>)</Typography>
                  </>
                }
                {(rowData.label !== 'Объем' && rowData.label !== 'Коэффициент') &&
                  <Typography sx={tableTextStyle}>{`${rowData.label} `}</Typography>
                }
              </TableCell>
              <TableCell sx={{width: '70%'}}>
              {rowData.inputType === 'textField'
                && (
                  <FigureParameterTextField rowData={rowData}
                                            onChangeAutocompleteHandler={onChangeAutocompleteHandler}/>
                )
              }
              {rowData.inputType === 'autocomplete'
                && (
                    <FigureParameterAutocomplete rowData={rowData}
                                                 onChangeAutocompleteHandler={onChangeAutocompleteHandler}/>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectedFigureDataTable;