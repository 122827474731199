import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import {Loading} from "../../../../../../../Main/Map/Common/Loading";
import {otherDataAPI} from "../../../../../../../../api/api";
import {handleErrors} from "../../../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {useDispatch, useSelector} from "react-redux";
import {ErrorAlert} from "../../../../../../../Common/ErrorAlert";
import {changeOrgPageAttrs} from "../../../../../../organizationPageReducer";

export function FourthTabAccessModules({user}) {
  const modules = useSelector(state => state.organizationPageReducer.allModules)
  const [loading, setLoading] = useState({loading: false, error: false})
  const dispatch = useDispatch()
  const userIsReadOnly = !user.is_editor;
  const notWritableModules = ['transport_monitoring', '1c_sync', '1c_reports']

  useEffect(() => {
    if (!modules && !loading.loading && !loading.error) {
      setLoading({...loading, loading: true})
      otherDataAPI.organizationUsers.getModulesAccessInfo(user.id).then(res => {
        dispatch(changeOrgPageAttrs({allModules: res.data.data, oldModules: structuredClone(res.data.data)}))
        setLoading({loading: false, error: false})
      }).catch(err => {
        setLoading({loading: false, error: true})
        handleErrors(dispatch, err)
      })
    }
  }, [loading])

  function handleSetAllAccess() {
    const allAccess = !modules.every(item => item.access)
    Object.values(modules).forEach(item => {
      item.access = allAccess;
      if (!item.access)
        item.write = false;
    })
    dispatch(changeOrgPageAttrs({allModules: [...modules], changesInDialog: 3}))
  }

  function handleSetAllWritable() {
    const arr = [...modules]
    const allWrite = !modules.every(item => item.write)
    Object.values(arr).forEach(item => {
      item.write = allWrite;
      if (item.write && notWritableModules.indexOf(item['modules_permissions']['slug']) === -1) {
        item.access = true;
      }
    })
    dispatch(changeOrgPageAttrs({allModules: arr, changesInDialog: 3}))
  }

  function accessItemClick(module) {
    module.access = !module.access;
    if (!module.access)
      module.write = false;
    dispatch(changeOrgPageAttrs({allModules: [...modules], changesInDialog: 3}))
  }

  function writableItemClick(module) {
    module.write = !module.write;
    if (module.write)
      module.access = true;
    dispatch(changeOrgPageAttrs({allModules: [...modules], changesInDialog: 3}))
  }

  if (loading.loading)
    return <Loading/>

  if (loading.error)
    return (
      <ErrorAlert
        style={{mt: 2}} text={'Произошла ошибка при загрузке списка модулей.'}
        onClick={() => {
          dispatch(changeOrgPageAttrs({allModules: null}))
          setLoading({loading: false, error: false})
        }}
      />
    )

  if (!modules)
    return null;

  let ind = 0;
  return (
    <Table>
      <TableHead style={{userSelect: 'none'}}>
        <TableRow>
          <TableCell>
            Модуль
          </TableCell>
          <TableCell>
            <label>
              <Checkbox
                checked={modules.every(item => item.access)}
                onChange={handleSetAllAccess}
              />
              Просмотр
            </label>
          </TableCell>
          <TableCell>
            <label>
              <Checkbox
                disabled={userIsReadOnly}
                checked={modules.every(item => {
                  return notWritableModules.indexOf(item['modules_permissions']['slug']) !== -1 || item.write;
                })}
                onChange={handleSetAllWritable}
              />
              Редактирование
            </label>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {modules.map(module =>
          <TableRow key={ind++} className="hoverTableRow">
            <TableCell>
              {module["modules_permissions"].name}
            </TableCell>
            <TableCell>
              <Checkbox
                checked={module.access}
                onChange={() => accessItemClick(module)}
              />
            </TableCell>
            <TableCell>
              {!notWritableModules.find(item => module['modules_permissions']['slug'] === item) &&
                <Checkbox
                  disabled={userIsReadOnly}
                  checked={module.write}
                  onChange={() => writableItemClick(module)}
                />
              }
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
