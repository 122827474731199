
export const toolbarContainer = {
  height: '80%',
  width: '70vw',
  alignItems: 'center',
  justifyContent: 'flex-start'}


export const toolbarDividerStyle = {height: 'inherit', m: 0.5}

export const ToolbarBlockContainerStyle = {
  height: '100%',
  ml: 0.5,
  alignItems: 'center',
  justifyContent: 'center'}

export const toolbarTextStyle = {
  fontWeight: 'bold', fontSize: 13, color: '#616161', whiteSpace: 'nowrap'
}

export const toolbarIconButtonStyle = {p: 0, pr: 0.3, pl: 0.3}

export const toolbarIconStyle = {fontSize: 20}
export const toolbarButtonStyle = {p: 0, pl: 0.5, pr: 0.5, width: '100px'}

/*export const toolbarCustomSvgIconButtonStyle = {width: 38, color: 'primary.dark', p: 0, pr: 1.5,
  backgroundColor: 'transparent', "&:hover": {backgroundColor: "#FFF"}}*/

export const toolbarLayersFormStyle = {m: 0, p: 0}
export const toolbarLayersBlockCheckboxStyle = {p:0}

export const toolbarZoomSliderStyle = {p: 0, width: '5vw'}

export const layerCheckboxesText = {fontSize: 14, whiteSpace: 'nowrap'}