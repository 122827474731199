import React from 'react';
import {List, ListItem} from "@mui/material";
import {useSelector} from "react-redux";
import {plotsTreePaddingLeft, treeListItemStyle} from "../../../../../leftBarStyles";
import NewStockpileBlock from "../../../../../NewStockpileBlock/NewStockpileBlock";
import StockpileItem from "./StockpileItem/StockpileItem";


const StockpilesList = ({plot, iAmEditor}) => {

  const isCreateStockpile = useSelector(state => state.stockpilesReducer.isCreateStockpile)
  if (!plot) return ''

  const stockpiles = plot.stockpiles;
  return (
    <List disablePadding sx={{width: '100%'}}>
      {isCreateStockpile.status && isCreateStockpile.plotId === plot.id &&
        <ListItem style={{...treeListItemStyle, paddingLeft: plotsTreePaddingLeft}}>
          <NewStockpileBlock plot={plot}/>
        </ListItem>
      }
      {stockpiles.map((stockpile, i) => (
        <StockpileItem stockpile={stockpile} plot={plot} key={i} iAmEditor={iAmEditor}/>
      ))}
    </List>
  );
};

export default StockpilesList;
