import {
  Alert,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getCuttingAreas, loadCuttingAreas} from "./CuttingsAreas";
import {getMap1} from "../../../Map/GlobalObjects";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RectangleIcon from '@mui/icons-material/Rectangle';
import CheckIcon from '@mui/icons-material/Check';
import {refresh as refreshFilters} from '../../../Map/Filters/FiltersReducer.js'
import {isOdd} from "../../../Map/Common/MiscFunction";
import {
  defaultPaddingStep,
  projectsTreeListItemStyle,
  treeItemButtonIconSize,
  treeItemButtonStyle
} from "../../../Map/Common/Styles";
import {CAContextMenu} from "./CAContextMenu";
import {
  casLoadingErrorString,
  caString,
  filtersString,
  grouppingString,
  listString,
  noCAsInSearchResults,
  rebootString
} from "../../../Map/Common/Strings_RU";
import {refreshSearchResults} from "./SearchResultsReducer";
import CloseIcon from '@mui/icons-material/Close';
import {setLeftDrawerCurTab} from "../../LeftDrawerReducer";
import {Loading} from "../../../Map/Common/Loading";
import {FiltersControl} from "./FiltersControl";
import {CASelectionTree} from "./Selections/CASelectionTree";
import {loadRightPanelData} from "../../../RightPanel/right_panel";
import {flyToPolygon} from "../../../Map/Common/fly";
import {useState} from "react";
import {ReactComponent as MachineryIcon} from "../../../Map/Common/svg/machinery.svg";
import {DeclarationLoadingButton} from "./DeclarationLoading/DeclarationLoadingButton";

function SearchResultsSettingButton(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const cuttingAreas = getCuttingAreas();
  const dispatch = useDispatch()

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleItemClick = (item) => {
    const groups = cuttingAreas.structuredTreeGroups;
    let array = [...groups];
    const ind = array.indexOf(item)
    if (ind > -1) {
      array.splice(ind, 1)
    } else {
      const ind2 = cuttingAreas.groups.indexOf(item);
      const lastItem = array[array.length - 1]
      if (ind2 > cuttingAreas.groups.indexOf(lastItem)) {
        array.push(item)
      } else {
        const newArr = []
        cuttingAreas.groups.map(name => {
          if (groups.indexOf(name) !== -1)
            newArr.push(name)
          if (name === item)
            newArr.push(item)
        })
        array = newArr;
      }
    }
    cuttingAreas.structuredTreeGroups = array;
    dispatch(refreshFilters())
    setRefresh(!refresh)
  }

  return (
    <>
      <Button
        color={'info'}
        style={{textTransform: 'none',}}
        size={'small'}
        disabled={props.items.length === 0}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Typography style={{marginLeft: '0.2vw', marginTop: '2px'}}>{grouppingString}</Typography>
        <ArrowDropDownIcon fontSize={'small'}/>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {cuttingAreas.groups.map(item => (
          <MenuItem onClick={() => handleItemClick(item)} value={item.title} key={item} disableRipple>
            <ListItemIcon>
              {cuttingAreas.structuredTreeGroups.indexOf(item) > -1 &&
                <CheckIcon/>
              }
            </ListItemIcon>
            {cuttingAreas.filtersNamesMap[item]}
          </MenuItem>
        ))}
        <MenuItem disabled={true} disableRipple>
          <ListItemIcon>
            <CheckIcon/>
          </ListItemIcon>
          {caString}
        </MenuItem>
      </Menu>
    </>
  )
}

const machineryIconBorderded = {
  border: '1px dashed grey',
  borderRadius: '50%',
}

const machineryIconStyle = {
  boxSizing: 'border-box',
  padding: '2px',
  height: '24px',
  width: '24px',
  paddingLeft: '1px',
  fill: 'gray',
}

const twentyFour = {
  height: '24px',
  width: '24px',
}

export const CASTree = (props) => { // дерево делянов, оно же список. TODO Не оптизизированно!
  const state = useSelector(state => state.searchResultsReducer)
  const items = state?.searchResults;
  const dispatch = useDispatch()
  const map = getMap1()
  const cas = getCuttingAreas()
  const [curTab, setCurTab] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const readOnly = props.readOnly;

  let ind = 0;
  const array = [];

  function addCANode(layer, padding) { //Добавить деляну в массив
    const ca_props = layer.elz_properties;
    const ca_style = getCuttingAreas().styles[ca_props.status]
    let machineryStyle = {}
    if (layer.elz_properties.status === 'В разработке') {
      if (!layer.elz_mon) {
        machineryStyle = machineryIconStyle;
      } else {
        machineryStyle = {...machineryIconStyle, ...machineryIconBorderded}
      }
    }
    array.push(
      <ListItem
        id={`ca_tree_item_${layer.elz_properties.id}`}
        key={ind++}
        disablePadding
        disableGutters
      >
        <ListItemButton
          disableGutters
          disableRipple
          dense={true}
          style={{
            paddingLeft: padding * defaultPaddingStep + 2,
          }}
          selected={cas.selectedCA === layer}
          onClick={() => {
            cas.selectedCA = layer;
            loadRightPanelData('Plot', layer, layer.elz_properties.id, false)
            flyToPolygon(layer.getLatLngs())
            setRefresh(refresh + 1)
          }}
        >
          {/*<Box
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            key={ind++}
            style={{width: '100%', position: 'relative', paddingLeft: (padding)*defaultPaddingStep}}
          >*/}
          <Box sx={twentyFour} alignItems={'center'} justifyContent={'center'}>
            {layer.elz_properties.status === 'В разработке' &&
              <MachineryIcon style={machineryStyle}/>
            }
          </Box>
          <RectangleIcon
            fontSize={'small'}
            style={{
              stroke: ca_style.color ? ca_style.color : 'green',
              strokeWidth: 2,
              fill: ca_style.fillColor,
              fillOpacity: ca_style.fillOpacity,
              paddingRight: '5px',
              paddingLeft: '5px',
            }}
          />
          <Typography
            variant={'subtitle1'}
            style={{
              fontStyle: 'italic',
              fontWeight: '400',
              overflowWrap: 'anywhere',
              margin: 0,
              flex: 1,
            }}>
            {ca_props.name}
          </Typography>
          {!readOnly &&
            <CAContextMenu ca={layer}/>
          }
        </ListItemButton>
      </ListItem>
    )
  }

  function getAllChildFromNode(name, parentNode, padding = -1) { // получить все ноды родителя
    const cas = getCuttingAreas()

    //Folder
    if (name)
      array.push(
        <ListItem
          key={ind++}
          disablePadding
          style={{
            ...projectsTreeListItemStyle,
            paddingLeft: padding * (defaultPaddingStep - 6),
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          <Stack direction={'column'} style={{width: '100%'}}>
            <Stack direction={'row'}>
              <Stack
                flexDirection={'row'}
                alignItems={'center'}
                onClick={() => {
                  parentNode.show = !parentNode.show;
                  dispatch(refreshSearchResults())
                }}
              >
                <IconButton
                  disableRipple
                  style={treeItemButtonStyle}
                >
                  {parentNode.show ? <ArrowDropDownIcon style={treeItemButtonIconSize}/> :
                    <ArrowRightIcon style={treeItemButtonIconSize}/>}
                </IconButton>
                <Typography
                  variant={'subtitle1'}
                  style={{
                    color: isOdd(padding) ? 'green' : '#545454',
                    fontWeight: '600',
                    padding: 0,
                  }}>{`${name.name ? name.name : name} ${name === 'Нет' ? `(${cas.filtersNamesMap[parentNode.grType]})` : ''}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </ListItem>
      )

    //CAs
    if (Array.isArray(parentNode.data) && parentNode.show)
      parentNode.data.map(ca => {
        addCANode(ca, padding || 1)
        return null;
      })

    //Children if folder
    if (parentNode.data)
      Object.values(parentNode.data).map(node => {
        if (parentNode.show && !Array.isArray(parentNode.data))
          getAllChildFromNode(node.name, node, padding + 1)
      })

    //Empty group list
    if (Object.keys(parentNode.data).length === 0) {
      cas.group.eachLayer(ca => {
        addCANode(ca, padding + 1)
      })
    }
  }

  function getAllListItemFromNode(node) {
    getAllChildFromNode("", node)
    return array;
  }

  if (!cas)
    return null;

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flex: '1 0 100%'}} id={'plotsBlock'}>
      <Stack flexDirection={'row'} justifyContent="space-between" alignItems="center">
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Tabs value={curTab} onChange={(e, value) => setCurTab(value)} centered>
            <Tab label={listString} value={0}/>
            <Tab label={filtersString} value={1}/>
          </Tabs>
          {/*<IconButton
            sx={{...pageTitleStyle}}
            onClick={() => {
              if (curTab === 0)
                dispatch(setIntroTourAttr({...plotsIntroTour, status: true}, 'plotsIntroTour'))
              else
                dispatch(setIntroTourAttr({...filtersIntroTour, status: true}, 'filtersIntroTour'))
            }}
            title={infoButtonTittleString}>
            <ErrorOutlineOutlinedIcon sx={{fontSize: 20}}/>
          </IconButton>*/}
        </Stack>
        <IconButton
          onClick={() => {
            dispatch(setLeftDrawerCurTab(null))
          }}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      {curTab === 0 &&
        <>
          {!readOnly && <DeclarationLoadingButton/>}
          {items.length
            ?
            <>
              <CASelectionTree/>
              <Divider sx={{mt: '1vh'}}/>
              <Stack direction={'row'} alignItems={'center'}>
                <SearchResultsSettingButton items={items}/>
              </Stack>
              <List dense={true} style={{width: '100%', overflow: 'auto', flex: '1 1 0'}}>
                {getAllListItemFromNode(getCuttingAreas().structuredTree2)}
              </List>
            </>
            :
            cas.loadingError
              ?
              <Box sx={{mb: 1}}>
                <Alert severity={'error'} icon={false}>
                  <Typography fontSize={'small'} alignItems={'center'}>
                    {casLoadingErrorString}
                  </Typography>
                  <Button
                    sx={{mt: 1}}
                    size={'small'}
                    fullWidth
                    variant={'outlined'}
                    onClick={() => {
                      loadCuttingAreas(map)
                      dispatch(refreshSearchResults())
                    }}
                  >
                    {rebootString}
                  </Button>
                </Alert>
              </Box>
              :
              cas.loading
                ?
                <Loading/>
                :
                <Alert severity={'info'} style={{marginTop: '1vh'}}>{noCAsInSearchResults}</Alert>
          }
        </>
      }
      {curTab === 1 &&
        <FiltersControl index={'filters'}/>
      }
      {/*<IntroTour tourData={plotsIntroTour} tourName={'plotsIntroTour'}/>
      <IntroTour tourData={filtersIntroTour} tourName={'filtersIntroTour'}/>*/}
    </Box>
  )
}
