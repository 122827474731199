import {pageTitleStyle} from "./Styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";
import Stack from "@mui/material/Stack";

// Заголовок страницы + кнопка возврата на главную
export const TitleCommonBlock = ({title, id, closeButtonHandler}) => {
  const navigate = useNavigate()

  return (
    <Stack direction={'row'} id={id} style={{flex: 0}}>
      <IconButton
        sx={{...pageTitleStyle}}
        onClick={() => {
          if (closeButtonHandler) {
            closeButtonHandler(navigate)
          }
        }}
        title={'На главную'}>
        <ArrowBackIcon/>
      </IconButton>
      <Typography sx={pageTitleStyle}>{title}</Typography>
    </Stack>
  )
}
