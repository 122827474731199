import {
  addCutPoint,
  addOutlinePoint,
  addPointOnLine,
  setPhotoAttr
} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {checkPointOnPolygonLine} from "../../../../../svgComponentsHelpersFunctions/checkPointOnPolygonLine";

export const outlineModeClickHandler =
  (currentPosition, svgElementName, svgElementIndex, stockpilePhotoState, dispatch) => {
    const {outline_selected_index, outlines, cuttingMode, creationMode, selectedPhoto} = stockpilePhotoState
    const clickOnOutline = svgElementName === 'outline'
    const isSelectedFigure = outline_selected_index !== null
    const clickOnAnotherFigure = clickOnOutline && svgElementIndex !== outline_selected_index
    const selectedFigure = selectedPhoto.figures[svgElementIndex]
    const selectedOutline = outlines[outline_selected_index]

    if (cuttingMode) {
      dispatch(addCutPoint(currentPosition))
      return;
    }

    if (isSelectedFigure) {
      if (clickOnAnotherFigure) {
        creationMode !== 'outline' &&  setSelectedFigure(dispatch, svgElementIndex, selectedFigure)
        return
      } else {
        dispatch(addOutlinePoint(currentPosition))
        return
      }
    } else {
      if (clickOnOutline) {
        setSelectedFigure(dispatch, svgElementIndex, selectedFigure)
        return
      }
    }
  }

export const setSelectedFigure = (dispatch, svgElementIndex, selectedFigure) => {
  dispatch(setPhotoAttr({
    outline_selected_index: svgElementIndex,
    outline_viewed_index: null,
    outline_selected_point_index: 0
  }))
}
