
const defaultState = {
  loading: false,
  loadingError: false,
  loaded: true,
  reactArray: [], //массив элементов для отображения в реахте
  drawingEnable: false,
  selectedNode: null, //выбранная нода в реахте
}

export function changeRoadsAttr(payload) {
  return {
    type: 'ROADS_SET_ATTR',
    payload: payload,
  }
}

export function refreshRoadsTree() {
  return {
    type: 'REFRESH_ROADS_TREE',
  }
}

/**
 * Редусер дерева дорог. Не хранит данные.
 * @param state {object} - state
 * @param action {type: string, payload: {}} - экшен
 * @returns {object}
 */
export function roadsReducer(state = defaultState, action) {
  switch (action.type) {
    case 'ROADS_SET_ATTR':
      return {...state, ...action.payload}
    case 'REFRESH_ROADS_TREE':
      return {...state}
    default:
      return state;
  }
}
