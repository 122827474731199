const defaultTMState = {
    show: false, //скрыто/показано
    loading: false, //грузиццо
    loadingError: 0, //0 - нет ошибок, 404 - нет данных, 403 - нет доступа, другое - ошибка загрузки
    productionShow: false, //скрыта/показана талбичка
    id: null, //текущая деляна (только для проверки при загрузке)
    type: 'volumes_by_species', //тип талбички (не точков)
    date_min: 0, //начальная дата
    date_max: 0, //конечная дата
    selected: [0, 0] //выбранные даты
}

const defaultState = {
    show: false,
    loading: false,
    wasLoading: false,
    curCALayer: null,
    system1cShow: false,
    maxiFleetShow: false,

    // === Данные с машин заготовки ===
    tm: {
        show: false, //скрыто/показано
        loading: false, //грузиццо
        loadingError: 0, //0 - нет ошибок, 404 - нет данных, 403 - нет доступа, другое - ошибка загрузки
        productionShow: false, //скрыта/показана талбичка
        id: null, //текущая деляна
        type: 'volumes_by_species', //тип талбички (не точков)
        date_min: 0, //начальная дата
        date_max: 0, //конечная дата
        selected: [0, 0] //выбранные даты
    }
}

export function resetCAPropsWindow() { //закрыть окно
    return {
        type: 'MAP_CA_PROPS_WINDOW_RESET',
    }
}

export function showCAProperiesDialog(caLayer) { //показать окно
    return {
        type: 'MAP_CA_PROPS_WINDOW_SHOW',
        layer: caLayer,
    }
}

export function caProperiesDialogSetCommonShow(show) { //показать/скрыть общие свойства
    return {
        type: 'MAP_CA_PROPS_WINDOW_SET_COMMON_SHOW',
        show: show,
    }
}

export function caProperiesDialogSet1CShow(show) { //показать/скрыть инфу из properties_1c
    return {
        type: 'MAP_CA_PROPS_WINDOW_SET_1C_SHOW',
        show: show,
    }
}

export function setTransportMonitoringInfo(info) {
    return {
        type: 'SET_TRANSPORT_MONITORING_INFO',
        data: info,
    }
}

export function caPropertiesReducer(state = defaultState, action) { //редусер какошка подробной инфы о деляне
    switch (action.type) {
        case 'MAP_CA_PROPS_WINDOW_RESET':
            return {...defaultState}
        case 'MAP_CA_PROPS_WINDOW_SHOW':
            return {...defaultState, show: true, curCALayer: action.layer}
        case 'MAP_CA_PROPS_WINDOW_SET_COMMON_SHOW':
            return {...state, commonShow: action.show}
        case 'MAP_CA_PROPS_WINDOW_SET_1C_SHOW':
            return {...state, system1cShow: action.show}
        case 'MAP_HIDE_ALL_OVERLAY_WINDOW': //срабатывает при закрытии всех окон оверлея
            return {...defaultState}
        case 'SET_TRANSPORT_MONITORING_INFO':
            return {...state, tm: {...state.tm, ...action.data}}
        case 'MAP_CA_PROPERTIES_RESET_TM_DATA':
            return {...state, tm: {...defaultTMState}}
        default:
            return state;
    }
}
