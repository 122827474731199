import {ListItemButton, Stack, Typography} from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

/**
 * Компонент, представляющий отдельный элемент списка нарушений.
 *
 * @component
 * @param {object} violation - Информация о нарушении.
 * @param {Function} onItemClick - Функция, вызываемая при клике на элемент списка.
 * @param {Function} onItemDeleteClick - Функция, вызываемая при клике на кнопку удаления элемента.
 * @param {boolean} isSelected - Флаг, указывающий, выбран ли текущий элемент списка.
 */
const ViolationItem = ({violation, onItemClick, onItemDeleteClick, isSelected}) => {

    const handleItemClick = () => {
        onItemClick(violation)
    }

    const handleItemDeleteClick = () => {
        onItemDeleteClick(violation.id)
    }

    return (<ListItemButton
            disableRipple
            style={{
                backgroundColor: isSelected ? "#e0e0e0" : "transparent", cursor: "pointer",
            }}
            onClick={handleItemClick}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                style={{
                    width: "100%", userSelect: "none",
                }}
            >
                <ReportProblemOutlinedIcon
                    style={{
                        color: "#B00000", fontSize: 20, paddingRight: 10
                    }}
                />
                <Typography>{violation.name}</Typography>
            </Stack>
            <DeleteOutlineOutlinedIcon
                size={"small"}
                style={{color: "#B00000"}}
                onClick={handleItemDeleteClick}
            />
        </ListItemButton>)
};

export default ViolationItem;
