import Dialog from "@mui/material/Dialog";
import {DialogContent, DialogTitle, IconButton, Stack, TextField, Typography} from "@mui/material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {setSnack} from "../../../../../../../Main/Map/Common/Dialog/Snack/snackReducer";
import {useDispatch} from "react-redux";

export function UserPasswordInfoDialog({data, onClose}) {
  const dispatch = useDispatch()

  function showCopyError() {
    dispatch(setSnack('error', 'Копирование запрещено политикой безопасности браузера.'))
  }

  function copyToClipbord() {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(data)
        .then(() => dispatch(setSnack("success", "Данные скопированы в буфер обмена.")))
        .catch(() => showCopyError())
    } else {
      showCopyError()
    }
  }

  return (
    <Dialog open={true}>
      <DialogTitle>
        <Stack direction={'row'}>
          <span style={{flex: 1}}>Пароль успешно изменен</span>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon fontSize={'small'} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={1} alignItems={'flex-end'}>
          <Typography>Скопируйте информацию, чтобы отправить новый пароль сотруднику</Typography>
          <div style={{width: '100%', position: 'relative'}}>
            <TextField
              multiline
              maxRows={8}
              value={data}
              style={{width: '100%', position: 'relative'}}
              inputProps={
                {readOnly: true, style: {fontSize: '95%'}}
              }
            />
            <div style={{position: 'absolute', bottom: 10, right: 10}}>
              <IconButton onClick={copyToClipbord} title={'Скопировать всё'}>
                <ContentCopyOutlinedIcon fontSize={'small'}/>
              </IconButton>
            </div>
          </div>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
