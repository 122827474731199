import {otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {
  createParamsString,
  formatHarvestingData,
  formatRemovalData, updateOffset, validateHarvestingData, validateRemovalData
} from "../../components/OperationalReport/ORhelperFunctions";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_OPERATIONAL_REPORT_ATTR = 'SET_OPERATIONAL_REPORT_ATTR'
const REPLACE_MACHINE = 'REPLACE_MACHINE'
const REMOVE_MACHINE = 'REMOVE_MACHINE'
const ADD_CREATED_HARVESTING_DATA = 'ADD_CREATED_HARVESTING_DATA'
const ADD_MORE_HARVESTING_DATA = 'ADD_MORE_HARVESTING_DATA'
const DELETE_HARVESTING_DATA = 'DELETE_HARVESTING_DATA'
const REPLACE_HARVESTING_DATA = 'REPLACE_HARVESTING_DATA'
const ADD_CREATED_REMOVAL_DATA = 'ADD_CREATED_REMOVAL_DATA'
const ADD_MORE_REMOVAL_DATA = 'ADD_MORE_REMOVAL_DATA'
const DELETE_REMOVAL_DATA = 'DELETE_REMOVAL_DATA'
const REPLACE_REMOVAL_DATA = 'REPLACE_REMOVAL_DATA'
const SET_FILTERS_DATA = 'SET_FILTERS_DATA'
const REMOVE_FILTER = 'REMOVE_FILTER'
const UPDATE_NEXT_PATH = 'UPDATE_NEXT_PATH'

const initialState = {
  harvestingData: null,
  isHarvestingDataLoading: false,
  isMoreHarvestingDataLoading: false,
  removalData: null,
  isRemovalDataLoading: false,
  isMoreRemovalDataLoading: false,
  machines: null,
  plots: null,
  stores: null,
  harvestingTableFilters: {},
  removalTableFilters: {},

  showMachinesModal: {status: false, dataType: null},
}

// редьюсер для страницы оперативного учета
export const operationalReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPERATIONAL_REPORT_ATTR:
      return {
        ...state, ...action.payload
      }
    case REPLACE_MACHINE:
      return {
        ...state, machines: state.machines.map(machine => {
          return (machine.id !== action.payload.id)
            ? machine
            : action.payload
        })
      }
    case REMOVE_MACHINE:
      return {
        ...state, machines: state.machines.filter(machine => machine.id !== action.machineId)
      }
    case ADD_CREATED_HARVESTING_DATA:
      return {
        ...state, harvestingData: {
          ...state.harvestingData,
          results: {
            all_volume: state.harvestingData.results.all_volume + action.payload.volume,
            result: [action.payload, ...state.harvestingData.results.result]
          }
        }
      }
    case ADD_MORE_HARVESTING_DATA:
      return {
        ...state, harvestingData: {
          ...state.harvestingData,
          next: action.data.next,
          results: {
            ...state.harvestingData.results,
            result: [...state.harvestingData.results.result, ...action.data.results.result]
          }
        }
      }
    case DELETE_HARVESTING_DATA:
      return {
        ...state, harvestingData: {
          ...state.harvestingData,
          results: {
            all_volume: state.harvestingData.results.all_volume - action.volume,
            result: state.harvestingData.results.result.filter(row => row.id !== action.rowId)
          }
        }
      }
    case REPLACE_HARVESTING_DATA:
      return {
        ...state, harvestingData: {
          ...state.harvestingData,
          results: {
            ...state.harvestingData.results,
            result: state.harvestingData.results.result.map(row => {
              return (row.id !== action.payload.id)
                ? row
                : action.payload
            })
          }
        }
      }
    case ADD_CREATED_REMOVAL_DATA:
      return {
        ...state, removalData: {
          ...state.removalData,
          results: {
            all_volume: state.removalData.results.all_volume + action.payload.volume,
            result: [action.payload, ...state.removalData.results.result]
          }
        }
      }
    case ADD_MORE_REMOVAL_DATA:
      return {
        ...state, removalData: {
          ...state.removalData,
          next: action.data.next,
          results: {
            ...state.removalData.results,
            result: [...state.removalData.results.result, ...action.data.results.result]
          }
        }
      }
    case DELETE_REMOVAL_DATA:
      return {
        ...state, removalData: {
          ...state.removalData,
          results: {
            all_volume: state.removalData.results.all_volume - action.volume,
            result: state.removalData.results.result.filter(row => row.id !== action.rowId)
          }
        }
      }
    case REPLACE_REMOVAL_DATA:
      return {
        ...state, removalData: {
          ...state.removalData,
          results: {
            ...state.removalData.results,
            result: state.removalData.results.result.map(row => {
              return (row.id !== action.payload.id)
                ? row
                : action.payload
            })
          }
        }
      }
    case SET_FILTERS_DATA:
      if (action.dataType === 'harvestingData') {
        return {
          ...state, harvestingTableFilters: {...state.harvestingTableFilters, ...action.data}
        }
      }
      if (action.dataType === 'removalData') {
        return {
          ...state, removalTableFilters: {...state.removalTableFilters, ...action.data}
        }
      }
      return {
        ...state
      }
    case REMOVE_FILTER:
      if (action.dataType === 'harvestingData') {
        const {[action.filterName]: deleted, ...newFilterObject} = state.harvestingTableFilters;
        return {
          ...state, harvestingTableFilters: newFilterObject
        }
      }
      if (action.dataType === 'removalData') {
        const {[action.filterName]: deleted, ...newFilterObject} = state.removalTableFilters;
        return {
          ...state, removalTableFilters: newFilterObject
        }
      }
      return {
        ...state
      }
    case UPDATE_NEXT_PATH:
      return {
        ...state, [action.dataType]: {...state[action.dataType], next: action.path}
      }
    default:
      return state
  }
}

export const setOpertaionalReportAttr = (payload) => ({type: SET_OPERATIONAL_REPORT_ATTR, payload})
export const replaceMachine = (payload) => ({type: REPLACE_MACHINE, payload})
export const removeMachine = (machineId) => ({type: REMOVE_MACHINE, machineId})
export const addHarvestingData = (payload) => ({type: ADD_CREATED_HARVESTING_DATA, payload})
export const addMoreHarvestingData = (data) => ({type: ADD_MORE_HARVESTING_DATA, data})
export const replaceHarvestingData = (payload) => ({type: REPLACE_HARVESTING_DATA, payload})
export const deleteHarvestingData = (rowId, volume) => ({type: DELETE_HARVESTING_DATA, rowId, volume})
export const addRemovalData = (payload) => ({type: ADD_CREATED_REMOVAL_DATA, payload})
export const addMoreRemovalData = (data) => ({type: ADD_MORE_REMOVAL_DATA, data})
export const replaceRemovalData = (payload) => ({type: REPLACE_REMOVAL_DATA, payload})
export const deleteRemovalData = (rowId, volume) => ({type: DELETE_REMOVAL_DATA, rowId, volume})
export const setFiltersData = (data, dataType) => ({type: SET_FILTERS_DATA, data, dataType})
export const removeFilter = (filterName, dataType) => ({type: REMOVE_FILTER, filterName, dataType})
export const updateNextPath = (path, dataType) => ({type: UPDATE_NEXT_PATH, path, dataType})


export const getHarvestingDataThunk = () => {
  return async (dispatch) => {
    try {
      dispatch(setOpertaionalReportAttr({isHarvestingDataLoading: true}))
      let res = await otherDataAPI.operationalReport.harvesting.getHarvestingData()
      dispatch(setOpertaionalReportAttr({harvestingData: res.data, isHarvestingDataLoading: false}))
    } catch (e) {
      dispatch(setOpertaionalReportAttr({isHarvestingDataLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const getFilteredHarvestingDataThunk = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setOpertaionalReportAttr({isHarvestingDataLoading: true}))
      let filters = getState().operationalReportReducer.harvestingTableFilters
      let paramsStr = createParamsString(filters)
      let res = await otherDataAPI.operationalReport.harvesting.getFilteredHarvestingData(paramsStr)
      dispatch(setOpertaionalReportAttr({harvestingData: res.data, isHarvestingDataLoading: false}))
    } catch (e) {
      dispatch(setOpertaionalReportAttr({isHarvestingDataLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const getMoreHarvestingDataThunk = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setOpertaionalReportAttr({isMoreHarvestingDataLoading: true}))
      let harvestingData = getState().operationalReportReducer.harvestingData
      let nextPath = harvestingData.next
      //const index = nextPath.indexOf('v1');
      //const formattedNextPath = nextPath.substring(index + 2, nextPath.length);
      //let res = await otherDataAPI.operationalReport.harvesting.getMoreHarvestingData(formattedNextPath)
      let res = await otherDataAPI.operationalReport.harvesting.getMoreHarvestingData(nextPath)
      dispatch(addMoreHarvestingData(res.data))
      dispatch(setOpertaionalReportAttr({isMoreHarvestingDataLoading: false}))
    } catch (e) {
      dispatch(setOpertaionalReportAttr({isMoreHarvestingDataLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const saveHarvestingDataThunk = (data) => {
  return async (dispatch, getState) => {
    let formattedData = formatHarvestingData(data)
    if (!validateHarvestingData(formattedData)) {
      dispatch(setSnack('error', 'Поля "Техника" и "Деляна" являются обязательными для заполнения'))
      return ''
    }
    try {
      let res = await otherDataAPI.operationalReport.harvesting.saveHarvestingData(formattedData)
      dispatch(addHarvestingData(res.data))
      let newNextPath = updateOffset(getState().operationalReportReducer.harvestingData?.next, 1)
      newNextPath && dispatch(updateNextPath(newNextPath, 'harvestingData'))
      dispatch(setSnack('success', 'Данные успешно сохранены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const patchHarvestingDataThunk = (data, setEditableRowIndex = null) => {
  return async (dispatch) => {
    let formattedData = formatHarvestingData(data)
    if (!validateHarvestingData(formattedData)) {
      dispatch(setSnack('error', 'Поля "Техника" и "Деляна" являются обязательными для заполнения'))
      return ''
    }
    try {
      let res = await otherDataAPI.operationalReport.harvesting.patchHarvestingData(formattedData)
      dispatch(replaceHarvestingData(res.data))
      dispatch(setSnack('success', 'Данные успешно обновлены'))
      setEditableRowIndex && setEditableRowIndex(null)
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const deleteHarvestingDataThunk = (rowId, rowVolume) => {
  return async (dispatch, getState) => {
    try {
      await otherDataAPI.operationalReport.harvesting.deleteHarvestingData(rowId)
      dispatch(deleteHarvestingData(rowId, rowVolume))
      let newNextPath = updateOffset(getState().operationalReportReducer.harvestingData?.next, -1)
      newNextPath && dispatch(updateNextPath(newNextPath, 'harvestingData'))
      dispatch(setSnack('success', 'Данные успешно удалены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const getRemovalDataThunk = () => {
  return async (dispatch) => {
    try {
      dispatch(setOpertaionalReportAttr({isRemovalDataLoading: true}))
      let res = await otherDataAPI.operationalReport.removal.getRemovalData()
      dispatch(setOpertaionalReportAttr({removalData: res.data, isRemovalDataLoading: false}))
    } catch (e) {
      dispatch(setOpertaionalReportAttr({isRemovalDataLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const getMoreRemovalDataThunk = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setOpertaionalReportAttr({isMoreRemovalDataLoading: true}))
      let removalData = getState().operationalReportReducer.removalData
      let nextPath = removalData.next
      //const index = nextPath?.indexOf('v1');
      //const formattedNextPath = nextPath.substring(index + 2, nextPath.length);
      let res = await otherDataAPI.operationalReport.removal.getMoreRemovalData(nextPath)
      dispatch(addMoreRemovalData(res.data))
      dispatch(setOpertaionalReportAttr({isMoreRemovalDataLoading: false}))
    } catch (e) {
      dispatch(setOpertaionalReportAttr({isMoreRemovalDataLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const getFilteredRemovalDataThunk = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setOpertaionalReportAttr({isRemovalDataLoading: true}))
      let filters = getState().operationalReportReducer.removalTableFilters
      let paramsStr = createParamsString(filters)
      let res = await otherDataAPI.operationalReport.removal.getFilteredRemovalData(paramsStr)
      dispatch(setOpertaionalReportAttr({removalData: res.data, isRemovalDataLoading: false}))
    } catch (e) {
      dispatch(setOpertaionalReportAttr({isRemovalDataLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const saveRemovalDataThunk = (data) => {
  return async (dispatch, getState) => {
    let formattedData = formatRemovalData(data)
    if (!validateRemovalData(formattedData)) {
      dispatch(setSnack('error',
        'Поля "Пункт вывозки", "Пункт доставки" и "Техника" являются обязательными для заполнения'))
      return ''
    }
    try {
      let res = await otherDataAPI.operationalReport.removal.saveRemovalData(formattedData)
      dispatch(addRemovalData(res.data))
      let newNextPath = updateOffset(getState().operationalReportReducer.removalData?.next, 1)
      newNextPath && dispatch(updateNextPath(newNextPath, 'removalData'))
      dispatch(setSnack('success', 'Данные успешно сохранены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const patchRemovalDataThunk = (data, setEditableRowIndex = null) => {
  return async (dispatch) => {
    let formattedData = formatRemovalData(data)
    if (!validateRemovalData(formattedData)) {
      dispatch(setSnack('error',
        'Поля "Пункт вывозки", "Пункт доставки" и "Техника" являются обязательными для заполнения'))
      return ''
    }
    try {
      let res = await otherDataAPI.operationalReport.removal.patchRemovalData(formattedData)
      dispatch(replaceRemovalData(res.data))
      dispatch(setSnack('success', 'Данные успешно обновлены'))
      setEditableRowIndex && setEditableRowIndex(null)
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const deleteRemovalDataThunk = (rowId, rowVolume) => {
  return async (dispatch, getState) => {
    try {
      await otherDataAPI.operationalReport.removal.deleteRemovalData(rowId)
      dispatch(deleteRemovalData(rowId, rowVolume))
      let newNextPath = updateOffset(getState().operationalReportReducer.removalData?.next, -1)
      newNextPath && dispatch(updateNextPath(newNextPath, 'removalData'))
      dispatch(setSnack('success', 'Данные успешно удалены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const getMachinesThunk = () => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.operationalReport.machines.getMachines()
      dispatch(setOpertaionalReportAttr({machines: res.data}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const createMachineThunk = (machineData, setNewMachine) => {
  return async (dispatch, getState) => {
    try {
      let res = await otherDataAPI.operationalReport.machines.createMachine(machineData)
      let machinesList = getState().operationalReportReducer.machines
      dispatch(setOpertaionalReportAttr({machines: [res.data, ...machinesList]}))
      setNewMachine && setNewMachine({name: '', number: ''})
      dispatch(setSnack('success', "Новая машина создана"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const updateMachineThunk = (machineData, setEditMachine) => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.operationalReport.machines.updateMachine(machineData)
      dispatch(replaceMachine(res.data))
      setEditMachine && setEditMachine(null)
      dispatch(setSnack('success', "Данные машины обновлены"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const deleteMachineThunk = (machineId) => {
  return async (dispatch) => {
    try {
      await otherDataAPI.operationalReport.machines.deleteMachine(machineId)
      dispatch(removeMachine(machineId))
      dispatch(setSnack('success', "Машина удалена"))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const getORPlots = () => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.operationalReport.getOperationalReportPlots()
      dispatch(setOpertaionalReportAttr({plots: res.data}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const getStoresThunk = () => {
  return async (dispatch) => {
    try {
      let res = await otherDataAPI.operationalReport.getOperationalReportStores()
      dispatch(setOpertaionalReportAttr({stores: res.data}))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}
