import {IconButton, ListItem, ListItemButton, Stack, Typography, useTheme} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {getMap1} from "../../../Map/GlobalObjects";
import {setSettingWasChanged} from "../../../Map/Common/userSettings";
import {getMiscLayer, getMiscLayers} from "./MiscLayersCommon";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {getBlindMiscLayers} from "../../../Map/Blind/BlindMiscLayersCommon";
import {blindRefresh} from "../../../Map/Blind/BlindReducer";
import CloseIcon from '@mui/icons-material/Close';
import {setLeftDrawerCurTab} from "../../LeftDrawerReducer";
import {getLeftDrawerButtons} from "../../LeftDrawer";
import {useNavigate} from "react-router";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {urlGenerator} from "../../../../../utils/urlGenerator";
import {
  defaultPaddingStep,
  miscLayersHeaderStyle,
  projectsTreeListItemStyle,
  treeItemButtonIconSize,
  treeItemButtonStyle
} from "../../../Map/Common/Styles";
import {
  miscLayerHaveNoStyles,
  miscLayersHeaderString, miscLayerSwitchStyle,
  planetablesEditForbiddenWithLiteString,
  planetablesViewForbiddenWithLiteString,
} from "../../../Map/Common/Strings_RU";
import {cannotIDo} from "../../../Map/Common/tariffs";
import {LockedIcon} from "../../../Map/Common/LockedIcon";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';

export const MiscLayerChooser = (props) => { //список всяких слоев
  const map = getMap1()
  const blindState = useSelector(state => state.blindReducer)
  const layers = blindState.left ? getMiscLayers() : getBlindMiscLayers()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const readOnly = cannotIDo.editorAction()

  const changeLayers = (index) => {
    switch (cannotIDo.viewMiscLayer(index)) {
      case 0:
        const la = layers[index];
        la.show = !la.show;
        if (la.show) {
          la.l = getMiscLayer(index, blindState.left)
          map.elz_zoom.addLayer(la.l, la.minZoom, la.maxZoom)
        } else {
          if (la.l) {
            map.elz_zoom.removeLayer(la.l)
            la.l = null;
          }
        }
        setSettingWasChanged()
        dispatch(blindRefresh())
        break;
      default:
        dispatch(setSnack('warning', planetablesViewForbiddenWithLiteString))
        break;
    }
  }

  const changeStyle = (index) => {
    if (layers[index].styles) {
      const obj = layers[index]
      if (obj.style === 'light') {
        obj.style = 'dark';
      } else {
        obj.style = 'light';
      }
      const lay = getMiscLayer(index, blindState.left)
      if (obj.l) {
        map.elz_zoom.removeLayer(obj.l)
        obj.l = null;
        if (obj.show) {
          map.elz_zoom.addLayer(lay, obj.minZoom, obj.maxZoom)
          obj.l = lay;
        }
      }
      dispatch(blindRefresh())
    } else
    {
      dispatch(setSnack('info', miscLayerHaveNoStyles))
    }
    setSettingWasChanged()
  }

  return (
    <div style={{userSelect: 'none'}}>
      <Stack flexDirection={'row'} justifyContent="space-between" alignItems="center">
        <Typography align={'center'}>{getLeftDrawerButtons()[props.index].name}</Typography>
        <IconButton
          onClick={() => {
            dispatch(setLeftDrawerCurTab(null))
          }}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      <Typography style={miscLayersHeaderStyle}>{miscLayersHeaderString}</Typography>
      {Object.keys(layers).map((index) => {
        return (
          <Stack key={index} direction={'row'} alignItems={'center'}>
            <ListItem
              disableGutters
              disablePadding
              id={index}
            >
              <ListItemButton
                onClick={() => changeLayers(index)}
                disableRipple
                disabled={index === 'planetablets' && (!cannotIDo.expiredTariffActions() || !!cannotIDo.lightTariffActions())}
                style={{
                  ...projectsTreeListItemStyle,
                  paddingLeft: defaultPaddingStep / 2,
                }}
              >
                <LockedIcon
                  locked={cannotIDo.viewMiscLayer(index) !== 0}
                  style={{
                    color: layers[index].show ? theme.palette.primary.main : theme.palette.text.disabled,
                    height: 20
                  }}
                  lockIconRight={-3}
                >
                  {!layers[index].show
                    ?
                    <VisibilityOffOutlinedIcon fontSize={'small'}/>
                    :
                    <VisibilityOutlinedIcon fontSize={'small'}/>
                  }
                </LockedIcon>
                <LockedIcon
                  locked={false}
                  style={{height: 20}}>
                  <IconButton
                    title={miscLayerSwitchStyle}
                    disableRipple
                    onClick={(e) => {
                      e.stopPropagation()
                      changeStyle(index)
                    }}
                  >
                    <WbIncandescentIcon fontSize={'small'} color={!layers[index].styles || layers[index].style==='dark'?'disabled':'primary'} />
                  </IconButton>
                </LockedIcon>
                <Typography style={{flex: 1}}>{layers[index].name}</Typography>
              </ListItemButton>
              {index === 'planetablets' && !readOnly && !cannotIDo.lightTariffActions() &&
                <IconButton
                  disableRipple
                  title={'Редактор планшетов'}
                  style={treeItemButtonStyle}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (cannotIDo.editMiscLayer(index) === 0) {
                      e.stopPropagation()
                      navigate(urlGenerator().planetables)
                    } else
                      dispatch(setSnack('warning', planetablesEditForbiddenWithLiteString))
                  }}>
                  <EditOutlinedIcon style={treeItemButtonIconSize}/>
                </IconButton>
              }
            </ListItem>
          </Stack>
        )
      })}
    </div>
  )
}
