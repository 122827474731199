import Cookies from "js-cookie";

class TokenService {
  getLocalRefreshToken() {
    return Cookies.get("refresh");
  }
  getLocalAccessToken() {
    return localStorage.getItem("access");
  }
  updateLocalAccessToken(token) {
    localStorage.setItem("access", token);
  }
  setUser(tokens) {
    localStorage.setItem("access", tokens.token);
    Cookies.set('refresh', tokens.refresh)
  }
  setCookiesAccept(status) {
    localStorage.setItem("cookies_accept", status);
  }
  getCookiesAccept() {
    return localStorage.getItem("cookies_accept");
  }
  removeRefreshToken() {
    Cookies.remove('refresh')
  }
  removeAccessToken() {
    localStorage.removeItem('access')
  }
}
export default new TokenService();
