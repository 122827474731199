import {Link, Typography} from "@mui/material";
import {TitleCommonBlock} from "../Common/TitleCommonBlock";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AboutWeb from "./AboutWeb/AboutWeb";
import AboutMobile from "./AboutMobile/AboutMobile";
import {appVersion} from "../Main/Map/GlobalObjects";
import {urlGenerator} from "../../utils/urlGenerator";

const AboutPage = () => {
  //const navigate = useNavigate()
  const webFunctions = [
    {text: 'Создавать на карте пользовательские фигуры (полигоны и линии), с возможностью преобразования их в деляны и дороги.'},
    {text: 'Загружать на карту координаты из gpx-файлов.'},
    {text: 'Создавать на карте деляны и дороги, а также привязывать к ним необходимые для работы данные.'},
    {text: 'Добавлять сторонних пользователей или ваших сотрудников в организацию для совместной работы.'},
  ]
  const mobileFunctions = [
    {text: 'Добавлять фигуры (полигоны, линии).'},
    {text: 'Записывать маршруты.'},
    {text: 'Загружать пользовательские геоданные.'},
    {text: 'Сохранять области карты для офлайн использования.'},
    {text: 'Устанавливать метки с описанием и иконками.'},
  ]

  function goToBack(navigate) {
    navigate(urlGenerator().main)
  }

  return (
    <Box sx={{height: '90vh', overflow: 'auto'}}>
      <TitleCommonBlock title={`О приложении (версия ${appVersion})`} closeButtonHandler={goToBack}/>
      <Stack spacing={2}>
        <Box sx={{px: 4}}>
          <Typography variant={'h5'} fontWeight={500}>Все необходимые данные на одной карте</Typography>
        </Box>
        <Stack direction='row' sx={{px: 4}} spacing={1}>
          <Typography>Delyana - это программный комплекс состоящий из мобильного (
            <Link sx={{cursor: 'pointer', pl: 1, pr: 1}}
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=ru.elzforesttech.gisapp', '_blank')}
            >{'Google Play'}</Link>
            ,
            <Link sx={{cursor: 'pointer', pl: 1, pr: 1}}
                  onClick={() => window.open('https://apps.apple.com/us/app/gisapp/id6449449078', '_blank')}
            >{'App Store'}</Link>
            ) и веб-приложения
          </Typography>
        </Stack>
        <AboutWeb webFunctions={webFunctions}/>
        <AboutMobile mobileFunctions={mobileFunctions}/>
      </Stack>
    </Box>
  )
}

export default AboutPage;
