import Box from "@mui/material/Box";
import {Collapse} from "@mui/material";
import {SentinelUsersTable} from "./SentinelUsersTable";
import {blockContainerStyle} from "../organizationPageStyles";
import {BottomBlockTitle} from "../BottomBlock/BottomBlockTitle";

function SentinelUsers({openedBlock, setOpenedBlock}) {
  return (
    <Box sx={{...blockContainerStyle}} id={'sentinel-users'}>
      <BottomBlockTitle openedBlock={openedBlock} setOpenedBlock={setOpenedBlock} blockName={'SentinelUsers'}
                        title={'SENTINEL'}/>
      <Collapse in={openedBlock === 'SentinelUsers'} timeout="auto" unmountOnExit>
        <SentinelUsersTable />
      </Collapse>
    </Box>
  )
}

export default SentinelUsers;
