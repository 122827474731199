import React from 'react';
import {useSelector} from "react-redux";
import {Stack, Typography} from "@mui/material";
import {rightBarTitleStyle} from "../../rightbarStyles";
import ViewedFigureDataTable from "./ViewedFigureDataTable/ViewedFigureDataTable";
import SelectedFigureDataTable from "./SelectedFigureDataTable/SelectedFigureDataTable";


const FigureData = () => {
  const stockpilePhotoState = useSelector(state => state.stockpilePhotoReducer)
  const {outline_viewed_index, selectedPhoto, outline_selected_index} = stockpilePhotoState
  const selectedFigure = outline_selected_index !== null ? selectedPhoto.figures[outline_selected_index] : null
  const viewedFigure = outline_viewed_index !== null ? selectedPhoto.figures[outline_viewed_index] : null

  return (
    <>
      <Stack direction={'column'} alignItems={'center'} spacing={1} justifyContent={'center'}>
        <Stack direction={'row'} alignItems={'center'} spacing={1} justifyContent={'center'} >
          <Typography sx={rightBarTitleStyle}>Область</Typography>
        </Stack>
        {viewedFigure &&
          <ViewedFigureDataTable viewedFigure={viewedFigure} />
        }
        {outline_selected_index !== null &&
          <SelectedFigureDataTable selectedFigure={selectedFigure}/>
        }
      </Stack>
    </>
  );
};

export default FigureData;