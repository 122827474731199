import {Box, IconButton} from "@mui/material";
import {hideMapDialog, showMapDialog} from "../../../../Main/Map/Common/Dialog/DialogReducer";
import {useDispatch} from "react-redux";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";
import {deleteWorkspaceByID} from "../../../../Main/LeftPanel/Context/Wokspaces/workspaces";
import {changeOrgPageAttrs} from "../../../organizationPageReducer";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";

const ButtonsBlock = ({workspace, setOpen}) => {
  const dispatch = useDispatch()
  const deleteDialogButtons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}

  function deleteWorkspaceHandler() {
    const isAdmin = workspace.users.find(user => user.is_admin)
    if (!isAdmin) {
      dispatch(showMapDialog('warning', 'Удаление рабочей области',
        "Вы уверены что хотите удалить рабочую область? ", deleteDialogButtons,
        (e) => {
          dispatch(hideMapDialog())
          if (e === 'Да') {
            const workspaces = window.store.getState().organizationPageReducer.workspacesData;
            const ind = workspaces.findIndex(wp => wp === workspace)
            if (ind !== -1) {

              deleteWorkspaceByID(workspace.id)
                .then(() => {
                  workspaces.splice(ind, 1)
                  dispatch(changeOrgPageAttrs({workspacesData: workspaces}))
                  dispatch(setSnack('success', 'Рабочяа область удалена'))
                })
                .catch(err => handleErrors(dispatch, err))
            } else {
              dispatch(setSnack('error', 'Произошла ошибка при удалении рабочей области. Обновите страницу и попробуйте еще раз.'))
            }
          }
        }))
    } else {
      dispatch(setSnack('warning', 'Вы не можете удалить рабочую область, в которой есть администраторы.'))
    }
  }

  return (
    <Box sx={{display: 'flex'}}>
      <IconButton
        title={'Изменить настройки доступа'}
        onClick={() => setOpen(true)}
      >
        <AdminPanelSettingsIcon color={'primary'}/>
      </IconButton>
      <IconButton
        onClick={deleteWorkspaceHandler}
        title={'Удалить рабочую область'}>
        <DeleteOutlineOutlinedIcon color={'error'}/>
      </IconButton>
    </Box>
  )
}

export default ButtonsBlock;
