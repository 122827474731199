import React from 'react';
import {ListItem, Typography} from "@mui/material";
import {
  planetablesTextColor, planetablesTreeLeftPaddingStep, planetablesTreelistItemStyle, treeItemPlanetableText
} from "../../../../PlanetabesPageSyles";
import PlanetableListItemContextMenu from "../PlanetableListItemContextMenu/PlanetableListItemContextMenu";
import {useSelector} from "react-redux";

const PlanetableItem = ({item, handleOnPlanetableClick, readOnly}) => {
  const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)

  return (
    <ul style={{margin: 0, padding: 0}}>
      <ListItem key={item.id}
                style={{
                  ...planetablesTreelistItemStyle,
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: planetablesTreeLeftPaddingStep,
                }}
                onClick={(e) => handleOnPlanetableClick(e, item)}>
        <Typography style={{
          color: selectedPlanetable?.id === item.id ? 'primary.main' : planetablesTextColor,
          ...treeItemPlanetableText,
        }}>
          {item.name}
        </Typography>
        {selectedPlanetable?.id === item.id &&
          <>{!readOnly && <PlanetableListItemContextMenu/>}</>
        }
      </ListItem>
    </ul>
  );
};

export default PlanetableItem;