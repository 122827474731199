import React, {useEffect, useLayoutEffect, useState} from 'react';
import {IconButton, TableCell, TableRow, Typography} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch, useSelector} from "react-redux";
import {
  getMachinesThunk,
  getORPlots, getStoresThunk,
  patchHarvestingDataThunk,
  patchRemovalDataThunk,
  saveHarvestingDataThunk,
  saveRemovalDataThunk
} from "../../../../../../redux/reducers/operationalReportReducer";
import {getReferenceThunk} from "../../../../../../redux/reducers/referencesReducer";
import ORDatePicker from "../../../Forms/OperationalReportDatePicker/ORDatePicker";
import ORAutocomplete from "../../../Forms/OperationalReportAutocomplete/ORAutocomplete";
import ORTextField from "../../../Forms/OperationalReportTextField/ORTextField";
import {getValueFromRowByColumn} from "../../../../ORhelperFunctions";
import {formatDateFromStrToObj} from "../../../../../Main/Map/Common/DateTimeFunctions";

const TableForms = ({columns, isNewRow, setIsNewRow, setEditableRowIndex,  dataType,
                                editableRowIndex = null, tableData, isCopyRow}) => {
  const dispatch = useDispatch();
  const machines = useSelector(state => state.operationalReportReducer.machines) || [];
  const species = useSelector(state => state.referencesReducer.references.species) || [];
  const plots = useSelector(state => state.operationalReportReducer.plots) || [];
  const stores = useSelector(state => state.operationalReportReducer.stores) || [];
  const currentUser = useSelector(state => state.userReducer.userInfo)
  const {first_name, last_name, patronymic, id} = currentUser

  // создаем новую строку с полями по умолчанию: operator - текущий юзер, date - текущая дата
  const [rowData, setRowData] = useState({
    operator: {first_name, last_name, patronymic, id},
    date: new Date()
  })

  useEffect(() => {
    dispatch(getMachinesThunk());
    !species.length && dispatch(getReferenceThunk('species'));
    dispatch(getORPlots());
    dataType === 'removalData' && dispatch(getStoresThunk())
  }, []);


  // если есть редактируемая строка, то помещаем ее в newRowData
  useLayoutEffect(() => {
    if (editableRowIndex !== null && (!isNewRow || isCopyRow)) {
      let row = tableData[editableRowIndex]
      row && setRowData({
        ...row,
        date: formatDateFromStrToObj(row.date),
      })
    }
  }, []
  )

  const getOptionsByColumnName = (columnName) => {
    let formattedPlots = plots.filter(plot => plot.name).map(plot => ({...plot, type: 'plot'}))
    let formattedStores = stores.map(store => ({...store, type: 'store'}))
    switch (columnName) {
      case 'machine':
        return (dataType === 'harvestingData')
          ? machines.filter(machine => machine.is_for_harvesting)
          : machines.filter(machine => !machine.is_for_harvesting)
      case 'specie':
        return species
      case 'plot':
        return formattedPlots
      case 'point_from':
      case 'point_to':
        return [...formattedPlots, ...formattedStores]
    }
  }

  const undoCreateMode = () => {
    setIsNewRow && setIsNewRow(false)
    setEditableRowIndex && setEditableRowIndex(null)
  }

  const createNewRow = () => {
    dataType === 'harvestingData' && dispatch(saveHarvestingDataThunk(rowData))
    dataType === 'removalData' && dispatch(saveRemovalDataThunk(rowData))

  }

  const updateRow = () => {
    dataType === 'harvestingData' && dispatch(patchHarvestingDataThunk(rowData, setEditableRowIndex))
    dataType === 'removalData' && dispatch(patchRemovalDataThunk(rowData, setEditableRowIndex))
  }

  const keyDownHandler = (e) => {
    if (e.code === 'Escape') {
      undoCreateMode()
    }
  }


  return (
    <TableRow onKeyDown={keyDownHandler}>
      {columns.map((column, i) => (
        <TableCell key={i}>
          {column.componentType === 'DatePicker' &&
            <ORDatePicker columnName={column.name}
                          setRowData={setRowData}
                          rowData={rowData}/>}
          {column.componentType === 'Autocomplete' &&
            <ORAutocomplete options={getOptionsByColumnName(column.name)}
                            setRowData={setRowData}
                            rowData={rowData}
                            columnName={column.name}/>}
          {column.componentType === 'TextField' &&
            <ORTextField column={column} setRowData={setRowData} rowData={rowData}/>}
          {column.componentType === 'Typography' &&
            <Typography>
              {rowData[column.name] ? getValueFromRowByColumn(column.name, rowData[column.name]) : ''}
            </Typography>}
        </TableCell>
      ))}
      <TableCell>
        <IconButton title={'Сохранить'} onClick={() =>
          (editableRowIndex === null || isCopyRow )? createNewRow() : updateRow()}>
          <DoneIcon sx={{color: 'primary.main'}}/>
        </IconButton>
        <IconButton onClick={undoCreateMode} title={'Отменить'}>
          <CloseIcon sx={{color: '#A44646'}}/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
  }
;

export default TableForms;