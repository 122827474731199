export const urlGenerator = () => {
  return {
    other: '/',
    main: "/app",
    login: "/app/login/",
    signUp: "/app/sign_up_user/",
    newOrganizationPage: '/app/organization_info',
    paymentPage: "/app/organization/payment_page",
    feedbackForm: "/app/feedback_form",
    about: "/app/about",
    planetables: "/app/planetables",
    stockpiles: "/app/stockpiles",
    operationalReport: "/app/operational_report"
  }
}
