const SET_PLANETABLES_GROUPING_ATTR = 'SET_PLANETABLES_GROUPING_ATTR'


let initialState = {
  selectedGroups: {
    rental_contract: true,
    region: false,
    forestry: false,
    district_forestry: false
  },
}

// редьюсер группировки планшетов
export const planetablesGroupingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANETABLES_GROUPING_ATTR: {
      return {...state, ...action.payload};
    }
    default:
      return state
  }
}

//action creators
export const setPlanetablesGroupingAttr = (payload) => ({type: SET_PLANETABLES_GROUPING_ATTR, payload});
