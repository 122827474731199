import React from 'react';
import {ImageListItem} from "@mui/material";
import {urlModify} from "../../../../../../utils/ulrModify";
import {useDispatch} from "react-redux";
import {setRightPanelAttr} from "../../../../../../redux/reducers/rightPanelReducer";
import CloseIcon from "@mui/icons-material/Close";
import {
  deletePhotoButtonOnThumbnail,
  numberOnthumbnailPhoto,
  thumbnailPhoto
} from "../../../rightPanelStyles";
import {hideMapDialog, showMapDialog} from "../../../../Map/Common/Dialog/DialogReducer";

const PhotosListItem = ({photo, index, objectId, deletePhotoThunk, readOnly}) => {
  const {image_small} = photo
  const dispatch = useDispatch()
  let formattedPhotoUrl = urlModify(image_small)
  const deleteDialogButtons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}

  const selectPhotoHandler = () => {
    dispatch(setRightPanelAttr({fullPhotoModal: {status: true, selectedPhoto: photo, position: index + 1}}))
  }

  const deletePhotoHandler = (e) => {
    e.stopPropagation()
    dispatch(showMapDialog('warning', "Удаление фотографии",
      "Вы уверены что хотите удалить выбранную фотографию?", deleteDialogButtons,
      (e) => {
        dispatch(hideMapDialog())
        if (e === 'Да') {
          dispatch(deletePhotoThunk(photo.id, objectId))
        }
      }))
  }

  return (
    <ImageListItem onClick={selectPhotoHandler} style={{cursor: 'pointer'}}>
      <img
        alt={`Фото ${index+1}`}
        style={thumbnailPhoto}
        src={formattedPhotoUrl}
      />
      <div style={numberOnthumbnailPhoto}>
        {index + 1}
      </div>
      {!readOnly &&
        <div style={deletePhotoButtonOnThumbnail} onClick={deletePhotoHandler}>
          <CloseIcon style={{fontSize: '14px'}}/>
        </div>
      }
    </ImageListItem>
  )
}

export default PhotosListItem;
