import React from 'react';
import {IconButton} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import {toolbarIconButtonStyle, toolbarIconStyle} from "../../../toolbarStyles";
import {useDispatch, useSelector} from "react-redux";
import {recognizePhotoThunk} from "../../../../../../redux/reducers/stockpilePhotoReducer";
import {hideMapDialog, showMapDialog} from "../../../../../Main/Map/Common/Dialog/DialogReducer";
import {deletePhotoThunk} from "../../../../../../redux/reducers/stockpilesReducer";
import FullScreenLoading from "../../../../../Common/FullScreenLoading/FullScreenLoading";

const RecognitionPhotoButton = () => {
  const dispatch = useDispatch()
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  const recognizePhotoLoading = useSelector(state => state.stockpilePhotoReducer.recognizePhotoLoading)

  const recognizePhotoButtonHandler = () => {
    const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
    dispatch(showMapDialog('warning', 'Распознание фотографии', 'После распознания фото, ' +
      'все текущие данные будут утеряны. Хотите продолжить?', buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        dispatch(recognizePhotoThunk(selectedPhoto.id))
      }
    }))

  }

  return (
    <>
      <IconButton title={'Распознать фото'}
                  sx={toolbarIconButtonStyle}
                  disableRipple
                  size={'small'}
                  onClick={recognizePhotoButtonHandler}
      >
        <PreviewIcon sx={toolbarIconStyle}/>
      </IconButton>
      {recognizePhotoLoading &&
        (
          <FullScreenLoading/>
        )
      }
    </>

  );
};

export default RecognitionPhotoButton;