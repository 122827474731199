
const defaultState = {
  open: false,
  snackType: 'success',
  message: '',
  timeout: 6000,
}

export function setSnack(snackType = 'success', message = '', timeout = 6000) {
  return {
    type: 'MAP_SNACK_SHOW',
    snackType: snackType,
    message: message,
    timeout: timeout,
  }
}

export function hideSnack() {
  return {
    type: 'MAP_HIDE_SNACK',
  }
}

export function snackReducer(state = defaultState, action) {
  switch (action.type) {
    case 'MAP_SNACK_SHOW':
      return {...state, open: true, snackType: action.snackType, message: action.message, timeout: action.timeout}
    case 'MAP_HIDE_SNACK':
      return {...defaultState}
    default:
      return state;
  }
}
