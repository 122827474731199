import {Divider, IconButton, Modal, Stack} from "@mui/material";
import {
  getProjects,
  isShapeNode,
  newFeatureTemplate,
} from "./ProjectsCommon";
import FolderIcon from "@mui/icons-material/Folder";
import {forwardRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {refreshProjects} from "./projectsReducer";
import UploadGpxModal from "../../AddGpxModal/UploadGpxModal";
import AddIcon from '@mui/icons-material/Add';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import {treeItemButtonStyle} from "../../../Map/Common/Styles";
import {
  createWialonDataString,
  createWorkspaceString,
  projectCreateFolderString,
  tarifLimitExited, topPanelBlock1String, topPanelBlock4String
} from "../../../Map/Common/Strings_RU";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {cannotIDo} from "../../../Map/Common/tariffs";
import ToolbarBlockContainer from "../../../../Stockpiles/Toolbar/ToolbarBlockContainer/ToolbarBlockContainer";
import CreateWialonDataDialog from "../../CreateWialonModal/CreateWialonModal";

export function ProjectsToolPanel({state}) {

  const dispatch = useDispatch()
  const projects = getProjects()
  const plotData = useSelector(state => state.plotDataReducer)
  const {showUploadGpxModal} = plotData
  const TransitionUploadGpxModal = forwardRef(() => <UploadGpxModal/>)

  // Локальное состояние отображения диалога для формирования Wialon данных.
  const [isShowCreateWialonDataDialog, setShowCreateWialonDataDialog] = useState(false)

  function startNewFolder() {
    const newFolder = structuredClone(newFeatureTemplate)
    newFolder.type = 'folder';
    newFolder.edit = 1;
    newFolder.parentNode = projects.selectedNode;
    newFolder.parentNode.show = true;
    newFolder.parentNode.data.splice(0, 0, newFolder)
    projects.editingNode = newFolder;
    projects.isChanges = true;
    dispatch(refreshProjects())
  }

  function startNewWorkspace() {
    if (cannotIDo.createWorkspace(dispatch) === 0) {
      const newWorkspace = structuredClone(newFeatureTemplate)
      newWorkspace.type = 'workspace';
      newWorkspace.edit = 3;
      newWorkspace.parentNode = projects;
      projects.data.push(newWorkspace)
      projects.editingNode = newWorkspace;
      projects.isChanges = true;
    } else
      dispatch(setSnack('warning', tarifLimitExited))
    dispatch(refreshProjects())
  }

  if (!projects || !state)
    return null;

  let selected = projects.selectedNode;

  if (selected && isShapeNode(selected))
    selected = selected.parentNode;

  const createf = selected && !isShapeNode(selected.type) && selected.id;

  /** 
   * Обработка закрытия диалога с формированием
   * данных для системы Wialon. Меняет локальное состояние диалога.
   * 
   * @param {boolean} value - Состояние диалога (открыт/закрыт).
   */
  function closeCallback(value){
    setShowCreateWialonDataDialog(value)
  }

  // Устанавливает состояние окна выгрузки Wialon в отображение.
  function openCreateWialonDataDialog(){
    setShowCreateWialonDataDialog(true)
  }

  return (
    <>
      <Stack direction={'row'} spacing={1} flexWrap={'nowrap'} style={{marginBottom: '1vh'}}>
        <ToolbarBlockContainer title={topPanelBlock1String}>
          {!cannotIDo.adminAction() && <IconButton
            style={treeItemButtonStyle}
            title={createWorkspaceString}
            id={'createWorkspaceButton'}
            onClick={() => {
              startNewWorkspace()
            }}>
            <AddIcon color={'primary'} fontSize={'small'}/>
          </IconButton>
          }
          <IconButton
            style={treeItemButtonStyle}
            title={projectCreateFolderString}
            id={'createNewFolderButton'}
            onClick={startNewFolder} disabled={!createf || state.drawMode !== null}>
            <FolderIcon color={createf && !state.drawMode ? 'primary' : 'disabled'} fontSize={'small'}/>
          </IconButton>
        </ToolbarBlockContainer>

        <Divider orientation={'vertical'} flexItem/>

        {!cannotIDo.adminAction() &&
          <ToolbarBlockContainer title={topPanelBlock4String}>
            <IconButton
              style={treeItemButtonStyle}
              title={createWialonDataString}
              onClick={openCreateWialonDataDialog}>
              <BrowserUpdatedIcon color={'primary'} fontSize={'small'}/>
            </IconButton>
        </ToolbarBlockContainer>
        }
      </Stack>
      <Modal open={showUploadGpxModal}>
        <TransitionUploadGpxModal/>
      </Modal>
      <CreateWialonDataDialog isOpen={isShowCreateWialonDataDialog} closeCallback={closeCallback} />
    </>
  )
}
