import {useSelector} from "react-redux";
import RPShapeData from "./RPShapeData/RPShapeData";
import Head from "./Head/Head";
import {Alert, Typography, Box} from "@mui/material";
import RpPillarData from "./RPPillarData/RPPillarData";
import RpRoadData from "./RPRoadData/RPRoadData";
import RpPlotData from "./RPPlotData/RPPlotData";
import RpViolationData from "./RPViolationData/RPViolationData";
import RPWarehouseData from "./RPWarehouseData/RPWarehouseData";
import {isShapeNode} from "../LeftPanel/Context/Projects/ProjectsCommon";
import {Loading} from "../Map/Common/Loading";

export default function RightPanel() {
  const rightPanelVisible = useSelector(state => state.rightPanelReducer.rightPanelVisible)
  const selectedElement = useSelector(state => state.rightPanelReducer.selectedElement) || {}
  const loading = useSelector(state => state.rightPanelReducer.rightPanelDataLoading)
  const loadingError = useSelector(state => state.rightPanelReducer.rightPanelDataLoadingError)
  const name = selectedElement.name || 'Информация';
  const type = selectedElement?.type;

  if (!rightPanelVisible) return '';

  return (
    <Box className={'right-panel-style'}>
      <Head name={name} />
      <div className={'right-panel-body'}>
      {loading
        ?
          <Loading />
        :
        <>
        {loadingError
          ?
          <Alert severity={'error'} sx={{width: '90%'}}>
            <Typography variant={'body2'}>Произошла ошибка, при загрузке информации. Попробуйте еще раз.</Typography>
          </Alert>
          :
          <>
            {isShapeNode(selectedElement) && <RPShapeData/>}
            {type === 'Pillar' && <RpPillarData pillarId={selectedElement.id}/>}
            {type === 'Road' && <RpRoadData/>}
            {type === 'Plot' && <RpPlotData />}
            {type === 'Violation' && <RpViolationData violationId={selectedElement.id}/>}
            {type === 'Warehouse' && <RPWarehouseData warehouseId={selectedElement.id}/>}
            {!type &&
              <Alert severity={'info'} sx={{width: '90%'}}>
                <Typography variant={'body2'}>Выберите объект в левой панели или на карте.</Typography>
              </Alert>
            }
          </>
        }
        </>
      }
      </div>
    </Box>
  )
}
