import {List, ListItem, Typography, useTheme} from "@mui/material";
import {
  createFeatureOnServer,
  createFolderOnServer,
  createWorkspaceOnServer,
  getProjects,
  isShapeNode,
  newFolder,
  patchFeatureOnServer,
  renameFolderOnServer,
  renameWorkspaceOnServer,
  setTreeNodeVisibleStatus,
  setTreeNodeVisibleStatusOnServer,
} from "./ProjectsCommon";
import {useDispatch} from "react-redux";
import {refreshProjects} from "./projectsReducer";
import {dataAPI} from "../../../../../api/api";
import {errorString} from "../../../Map/Common/Strings_RU";
import {nodeTypographyStyle} from "../../../Map/Common/Styles";
import {cannotIDo} from "../../../Map/Common/tariffs";
import WorkspaceTreeItem from "./WorkspaceTreeItem/WorkspaceTreeItem";
import ShapeTreeItem from "./ShapeTreeItem/ShapeTreeItem";
import FolderTreeItem from "./FolderTreeItem/FolderTreeItem";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";

let ind = 0; //TODO убрать каку Как?

export function ProjectTreeNode(props) {
  const node = props.node;
  const readOnly = props.readOnly;

  if (!node) {
    console.error('Error in node ', node)
    return null;
  }
  const padding = props.padding;
  const notSaved = node.edit === 0 && (node.new || node.changed)
  const dispatch = useDispatch()
  const theme = useTheme()
  const projects = getProjects()

  function collapseNode(node) {
    node.show = !node.show;
    projects.selectedNode = node;
    if (!node.new && !cannotIDo.editorAction()) {
      const json = {is_opened_web: node.show}
      if (node.type === 'workspace' && !cannotIDo.adminAction())
        dataAPI.workspaces.patch(json, node.id).then(() => {
        }).catch(err => {
          handleErrors(dispatch, err)
        })
      else if (node.type === 'folder' && !cannotIDo.editorAction())
        dataAPI.projects.folders.patch(node.id, json).then(() => {
        }).catch(err => {
          handleErrors(dispatch, err)
        })
    }
  }

  function cancelEdit() { //not for workspace
    const projects = getProjects()
    if (projects.editingNode.edit === 1) {
      projects.selectedNode.data.splice(0, 1)
    } else if (projects.editingNode.edit === 3) {
      projects.data.pop()
    }
    projects.editingNode.edit = 0;
    projects.editingNode = null;
  }

  function eyeClickHandler(node) {
    setTreeNodeVisibleStatus(node, !node.visible).then(() => {
        if (node.id && !cannotIDo.editorAction())
          setTreeNodeVisibleStatusOnServer(node, node.visible).then(() => {
          }).catch(err => {
            console.error(err)
          })
      }
    )
  }

  function renameNode(node, value) {
    const projects = getProjects()
    if (value && value !== projects.editingNode.name) {
      node.name = value;
      node.edit = 0;
      if (isShapeNode(node) && !node.new) {
        patchFeatureOnServer(node)
      } else if (node.type === 'folder') {
        if (node.new) {
          node.changed = false;
        } else {
          renameFolderOnServer(node)
        }
      } else if (node.type === 'workspace') {
        if (node.new) {
          node.changed = false;
        } else {
          renameWorkspaceOnServer(node, dispatch)
        }
      }
    }
  }

  function createWorkspace(node, value) {
    const projects = getProjects()
    if (value) {
      node.name = value;
      node.show = false;
      node.perimeter = {}
      createWorkspaceOnServer(node)
    } else {
      projects.data.pop()
    }
  }

  function endEditing(editValue) {
    const projects = getProjects()
    const node = projects.editingNode;
    if (!node)
      return
    switch (node.edit) { //1 - new folder, 2 - rename, 3 - new workspace
      case 1:
        newFolder(node, editValue)
        break;
      case 2:
        renameNode(node, editValue, dispatch)
        break;
      case 3:
        createWorkspace(node, editValue)
        break;
    }
    node.edit = 0;
    projects.editingNode = null;
    dispatch(refreshProjects())
  }

  function manuallySaving(node) {
    switch (node.type) {
      case 'workspace':
        if (node.new) {
          createWorkspaceOnServer(node, dispatch)
        } else if (node.changed) {
          renameWorkspaceOnServer(node, dispatch)
        }
        break;
      case 'folder':
        if (node.new) {
          createFolderOnServer(node)
        } else if (node.changed) {
          renameFolderOnServer(node)
        }
        break;
      default:
        if (isShapeNode(node)) {
          if (node.new) {
            createFeatureOnServer(node)
          } else {
            patchFeatureOnServer(node)
          }
        }
    }
  }

  switch (node.type) {
    case 'workspace':
      return (
        <WorkspaceTreeItem key={ind++} node={node} collapseNode={collapseNode} eyeClickHandler={eyeClickHandler}
                           endEditing={endEditing} cancelEdit={cancelEdit} manuallySaving={manuallySaving}
        />
      )
    case 'Polygon':
    case 'Line':
    case 'Marker':
      return (
        <ShapeTreeItem key={ind++} node={node} eyeClickHandler={eyeClickHandler} endEditing={endEditing}
                       manuallySaving={manuallySaving} readOnly={readOnly} padding={padding}
        />
      )
    case 'folder':
      return (
        <FolderTreeItem key={ind++} node={node} padding={padding} readOnly={readOnly} collapseNode={collapseNode}
                        eyeClickHandler={eyeClickHandler} endEditing={endEditing} cancelEdit={cancelEdit}
                        manuallySaving={manuallySaving}/>
      )
    default:
      return (<ListItem key={ind++}>
        <Typography style={{...nodeTypographyStyle, color: notSaved ? '#B00000' : theme.palette.text.primary}}>{errorString}</Typography>
      </ListItem>)
  }
}

export function ProjectsTree2(props) {
  const projects = getProjects()
  const readOnly = props.readOnly;

  function getProjectsTreeFromNode(node, padding = 0) {
    const arra = [];
    arra.push(<ProjectTreeNode key={ind++} node={node} padding={padding} readOnly={readOnly}/>)

    if (node.show) {
      let counter = 0;

      /*if (node.type === 'workspace' && node.id) {
        //rent borders
        if (node.perimeter.polygons) {
          arra.push(<WorkspaceRentBorder key={ind++} workspace={node} />)
        }
      }*/

      while (counter < node.data.length) {
        const data = node.data[counter];
        const childrens = getProjectsTreeFromNode(data, padding + 1)
        if (childrens)
          childrens.map(child => {
            arra.push(child)
          })
        counter++;
      }
    }
    //3. возвращаем "типа дерево" или ничего
    if (arra.length)
      return arra;
    else
      return null;
  }
  return (
    <List id={'projects_tree'} style={{width: '100%', overflow: 'auto', flex: '1 0 0'}}>
      {projects.data.map(workspace => {
        return getProjectsTreeFromNode(workspace, 0)
      })}
    </List>
  )
}
