import {Table, TableBody, TableContainer} from "@mui/material";
import TableHeadComponent from "./TableComponents/TableHeadComponent";
import WorkspaceRow from "./TableComponents/WorkspaceRow";
import {useDispatch, useSelector} from "react-redux";
import WorkspaceForm from "./TableComponents/WorkspaceForm";
import CreateWorkspaceRow from "./TableComponents/CreateWorkspaceRow";
import {cannotIDo} from "../../../Main/Map/Common/tariffs";
import {ErrorAlert} from "../../../Common/ErrorAlert";
import {loadingErrorWithRepeatText} from "../../../Main/Map/Common/Strings_RU";
import {changeOrgPageAttrs} from "../../organizationPageReducer";
import {Loading} from "../../../Main/Map/Common/Loading";
import {useEffect} from "react";
import {getEmployees, getWorkspaces} from "../../org_page_api";
import {WorkspacePermissionsSettings} from "./WorkspacePermissionsSettings/WorkspacePermissionsSettings";

const WorkSpacesTable = () => {
  const workspaces = useSelector(state => state.organizationPageReducer.workspacesData)
  const workspacesError = useSelector(state => state.organizationPageReducer.workspacesDataLoadingError)
  const workspaceLoading = useSelector(state => state.organizationPageReducer.workspacesDataLoading)
  const employees = useSelector(state => state.organizationPageReducer.employeesData)
  const employeesLoading = useSelector(state => state.organizationPageReducer.employeesDataLoading)
  const employeesError = useSelector(state => state.organizationPageReducer.employeesDataLoadingError)
  const createWorkspaceMode = useSelector(state => state.organizationPageReducer.createWorkspaceMode)
  const wpDialogWorkspace = useSelector(state => state.organizationPageReducer.wpDialogWorkspace)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!workspaces && !workspaceLoading && !workspacesError) {
      getWorkspaces()
    }
    if (!employees && !employeesLoading && !employeesError) {
      getEmployees()
    }
  }, [workspaces, employees, workspaceLoading, employeesLoading, workspacesError, employeesError])

  const iAmOwner = !cannotIDo.ownerAction()
  const WorkSpacesTableColumns = [
    {name: 'order', label: '№', width: '5%'},
    {name: 'number', label: 'Наименование', width: '20%'},
    {name: 'created_at', label: 'Дата создания', width: '20%'},
    {name: 'users', label: 'Сотрудники', width: '45%'},
    {name: 'buttons', label: '', width: '10%'},
  ]

  if (workspaceLoading || employeesLoading) {
    return (
      <Loading/>
    )
  }

  if (workspacesError || employeesError) {
    return <ErrorAlert text={loadingErrorWithRepeatText} onClick={() => {
      let state = {}
      if (!workspaces) {
        state = {workspacesData: null, workspacesDataLoading: false, workspacesDataLoadingError: false}
      }
      if (!employees) {
        state = {...state, employeesData: null, employeesDataLoading: false, employeesDataLoadingError: false}
      }
      dispatch(changeOrgPageAttrs(state))
    }}
    />
  }

  if (!workspaces || !employees || cannotIDo.adminAction())
    return null;

  return (
    <>
      <TableContainer sx={{pb: 1}}>
        <Table size={"small"} sx={{maxWidth: '100%'}}>
          <TableHeadComponent WorkSpacesTableColumns={WorkSpacesTableColumns}/>
          <TableBody>
            {workspaces.map((workspace, i) =>
              <WorkspaceRow key={i}
                            workspace={workspace}
                            WorkSpacesTableColumns={WorkSpacesTableColumns}
                            index={i}
                            iAmOwner={iAmOwner}
              />
            )}
            {!createWorkspaceMode
              ? <CreateWorkspaceRow WorkSpacesTableColumns={WorkSpacesTableColumns}/>
              : <WorkspaceForm WorkSpacesTableColumns={WorkSpacesTableColumns}/>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {wpDialogWorkspace && <WorkspacePermissionsSettings/>}
    </>
  )
}

export default WorkSpacesTable;
