import {parseFloatString} from "./MiscFunction";

/*export function checkLatLngsArraySelfIntersections(latlngs) {
  const arr = latlngs.map(ll => [parseFloat(ll.lat), parseFloat(ll.lng)])
  arr.push([latlngs[0].lat, latlngs[0].lng])
  const poly = {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Polygon",
      "coordinates": [arr],
    }
  }
  const res = turf.kinks(poly)
  return !res.features.length;
}*/

export function detectIdenticalLatLngs(array) { //возвращает индексы первого совпадения координат
  let ind = 0;
  while(ind<array.length) {
    const ll = array[ind]
    let inde = ind+1;
    while(inde < array.length) {
      const lll = array[inde]
      if (ll.lat === lll.lat && ll.lng === lll.lng) {
        return([ind, inde])
      }
      inde++;
    }
    ind++;
  }
  return [];
}

/*export function removeRepitedPoint(array) {
  if (array.length < 2)
    return array;
  const result = []
  let ind = 0;
  while (ind < array.length-2) {
    const ll = array[ind]
    const lll = array[ind+1]
    if (ll.lat !== lll.lat && ll.lng !== lll.lng)
      result.push(ll)
    ind++;
  }
  result.push(array[array.length-1])
  return result;
}*/

/**
 * Парсит координаты в строке. Координаты задаются через пробел.
 * @param str - строка с двумя числами, разделенными пробелом
 * @return {{lat, lng}} - свойство установлено в NaN если не распарсилось, float - если все ок
 */
export function parseCoordsFromString(str) {
  const spl = str.split(' ')
  const res = {lat: NaN, lng: NaN}
  if (spl.length === 2) {
    const lat = parseFloatString(spl[0])
    const lng = parseFloatString(spl[1])
    if (!isNaN(lat))
      res.lat = lat;
    if (!isNaN(lng))
      res.lng = lng;
  }
  return res;
}

/**
 * Проверяет latLng на валиднось чисел.
 * @param obj {{lat, lng}} - объект для проверки
 * @return {{lat, lng}} - свойство установлено в NaN если значение некорректно, float - если верно
 */
export function validateLatLng(obj) {
  const res = {lat: NaN, lng: NaN}
  if (!isNaN(obj.lat) && obj.lat <= 83 && obj.lat >= -83)
    res.lat = obj.lat;
  if (!isNaN(obj.lng) && obj.lng <= 180 && obj.lng >= -180)
    res.lng = obj.lng;
  return res;
}
