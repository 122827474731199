import {Box, Button, Divider, Stack} from "@mui/material";
import Comment from "../RPCommonElements/Comment/Comment";
import LastChange from "../RPCommonElements/LastChange/LastChange";
import {useSelector} from "react-redux";
import {savePillarCommentThunk} from "../../../../redux/reducers/rightPanelReducer";
import React from "react";
import {cannotIDo} from "../../Map/Common/tariffs";
import {savePillarOnServer, searchPillarLayerByID} from "../../LeftPanel/Context/Infrastructure/Pillars/pillars";
import {dispatch} from "../../../Common/misc_functions";
import {setSnack} from "../../Map/Common/Dialog/Snack/snackReducer";
import {changePillarsDialogAttr} from "../../LeftPanel/Context/Infrastructure/Pillars/pillarsReducer";
import {
  showCoordinatesEditor
} from "../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {flyToPoint} from "../../Map/Common/fly";
import {clearCoordinatesDialogInfo} from "../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";

const RpPillarData = () => {
  const pillarData = useSelector(state => state.rightPanelReducer.pillarData)
  const {comment: commentObj, id: pillarDataId} = pillarData || {};

  if (!pillarData) return null;

  function showCoordinatesEditorWindow() {
    const layer = searchPillarLayerByID(pillarDataId)
    if (layer) {
      dispatch(showCoordinatesEditor(
        [layer.getLatLng()],
        'Marker',
        (latlng) => {
          layer.setLatLng(latlng[0])
          if (layer.elz_blind_layer)
            layer.elz_blind_layer.setLatLng(latlng[0])
          savePillarOnServer(layer)
          flyToPoint(layer.getLatLng(), true, 12)
          clearCoordinatesDialogInfo()
        },
        layer.elz_properties.name,
      ))
    }
  }

  function showEditorDialog() {
    const layer = searchPillarLayerByID(pillarDataId)
    if (layer) {
      dispatch(changePillarsDialogAttr({
        name: layer.elz_properties.name,
        coordinates: layer.getLatLng(),
        quarters: layer.elz_properties.x_quarters,
        workspace: layer.elz_parent_node,
        shape: layer,
        editMode: true,
      }))
    } else {
      dispatch(setSnack('error', 'Ошибка: столб не найден.'))
    }
  }

  return (
    <div>
      <Box sx={{height: '100%', overflowY: 'auto'}}>
          <Stack spacing={1} sx={{pt: 1, pb: 1}}>
            <Stack direction={'row'} gap={1}>
              {!cannotIDo.editPillars() &&
                <Button size={'small'} variant={'outlined'} fullWidth onClick={showEditorDialog}>
                  Изменить
                </Button>
              }
              <Button size={'small'} variant={'outlined'} fullWidth onClick={showCoordinatesEditorWindow}>
                Координаты
              </Button>
            </Stack>
            <Comment commentObj={commentObj} objectId={pillarDataId} saveCommentThunk={savePillarCommentThunk}/>
            <Divider orientation={"horizontal"}/>
            <LastChange objectData={pillarData}/>
          </Stack>
      </Box>
    </div>
  )
}

export default RpPillarData;
