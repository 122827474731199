import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {Alert, FormControl, Grid, InputLabel, MenuItem, Select, TextField,} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {getWialonThunk} from "../Context/Projects/projectsReducer";
import dayjs from "dayjs";
import {LoadingButton} from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

/**
 * Компонент, представляющий функционал по выгрузке геоданных в Wialon.
 *
 * @component
 * @param {boolean} isOpen - Состояние диалога (открыт/закрыт).
 * @param {Function} closeCallback - Функция, вызываемая при закрытии диалога, чтобы обработать закрытие.
 */
const CreateWialonDataDialog = ({isOpen, closeCallback}) => {
    const dispatch = useDispatch();
    // Даты для выгрузки.
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const isLoading = useSelector((state) => state.projectsReducer.isLoading);

    // Тип объекта выгрузки.
    const [selectedType, setSelectedType] = useState("");

    // Сообщение для уведомления.
    const [errorMessages, setErrorMessages] = useState([]);

    // Условие для проверки полей.
    const [validation, setValidation] = useState({
        startDate: true,
        endDate: true,
        selectedType: true,
    });

    // Типы объектов.
    const ObjectTypes = {
        roads: {value: "roads", label: "Дороги"},
        plots: {value: "plots", label: "Деляны"},
        warehouses: {value: "warehouses", label: "Склады"},
    };

    // Обработка закрытия диалога выгрузки.
    const handleClose = () => {
        closeCallback(false);
    };

    // Обработка изменения типа объекта выгрузки.
    const handleChange = (event) => {
        setSelectedType(event.target.value);
        setValidation((prevValidation) => ({
            ...prevValidation,
            selectedType: event.target.value !== "",
        }));
    };

    // Обработка изменения начальной даты.
    const handleStartDateChange = (date) => {
        setStartDate(date);
        setValidation((prevValidation) => ({
            ...prevValidation,
            startDate: date !== null && endDate !== null ? date <= endDate : true,
        }));
    };

    // Обработка изменения конечной даты.
    const handleEndDateChange = (date) => {
        setEndDate(date);
        setValidation((prevValidation) => ({
            ...prevValidation,
            endDate: date !== null && startDate !== null ? date >= startDate : true,
        }));
    };

    // Проверка условий выгрузки и обработка.
    const handleExport = () => {
        const newErrorMessages = [];

        if (!startDate) {
            newErrorMessages.push("Не выбрана начальная дата.");
        }

        if (!endDate) {
            newErrorMessages.push("Не выбрана конечная дата.");
        }

        if (selectedType === "") {
            newErrorMessages.push("Не выбран объект выгрузки.");
        }

        setErrorMessages(newErrorMessages);

        if (newErrorMessages.length === 0) {
            if (
                validation.startDate &&
                validation.endDate &&
                validation.selectedType
            ) {
                // Форматируем даты в нужный вид перед отправкой.
                const formattedStartDate = startDate
                    ? dayjs(startDate).format("YYYY-MM-DD")
                    : null;
                const formattedEndDate = endDate
                    ? dayjs(endDate).format("YYYY-MM-DD")
                    : null;

                // Здесь вы можете использовать полученные значения для отправки на сервер или их дальнейшей обработки.
                const data = {
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

                // Выгрузка данных.
                dispatch(getWialonThunk(data, selectedType));
            }
        }
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    Создание выгрузки для Wialon
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="ru-RU"
                            >
                                <DatePicker
                                    inputFormat="DD.MM.YYYY"
                                    size="small"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="С"
                                            error={!validation.startDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={4}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="ru-RU"
                            >
                                <DatePicker
                                    inputFormat="DD.MM.YYYY"
                                    size="small"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="До"
                                            error={!validation.endDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel
                                    id="dropdown-header"
                                    sx={{whiteSpace: "nowrap", marginBottom: "5px"}}
                                >
                                    Объект
                                </InputLabel>
                                <Select
                                    labelId="dropdown-header"
                                    id="dropdown"
                                    value={selectedType}
                                    onChange={handleChange}
                                    label="Объект"
                                    error={!validation.selectedType}
                                >
                                    <MenuItem value="" disabled>
                                        Выберите из списка
                                    </MenuItem>
                                    {Object.values(ObjectTypes).map((objType) => (
                                        <MenuItem key={objType.value} value={objType.value}>
                                            {objType.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {errorMessages.length > 0 && (
                        <Alert severity="error" sx={{marginTop: "10px"}}>
                            <Typography color="inherit">
                                <strong>Заполнены не все поля:</strong>
                            </Typography>
                            {errorMessages.map((message, index) => (
                                <Typography key={index} color="inherit">
                                    &bull; {message}
                                </Typography>
                            ))}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions sx={{m: 1, p: 0}}>
                    <LoadingButton
                        variant="outlined"
                        autoFocus
                        onClick={handleExport}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Выгрузить
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default CreateWialonDataDialog;
