//https://firefox-source-docs.mozilla.org/performance/scroll-linked_effects.html
import {
  addRoadBlindLayer,
  createRoadLayer,
  getRoads,
  loadRoads,
  removeRoadBlindLayer,
  resetRoadStyle,
  saveRoadOnServer,
  selectedRoadStyle,
  setRoadLayerStyle,
  stopEditing,
} from "./roads";
import {Loading} from "../../../Map/Common/Loading";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Divider, IconButton, ListItem, ListItemButton, Stack, Typography, useTheme} from "@mui/material";
import {setLeftDrawerCurTab} from "../../LeftDrawerReducer";
import CloseIcon from "@mui/icons-material/Close";
import {projectsTreeListItemStyle, treeItemButtonIconSize, treeItemButtonStyle} from "../../../Map/Common/Styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import {changeRoadsAttr, refreshRoadsTree} from "./roadsReducer";
import {FTextField} from "../Projects/FTextFiled";
import {ReactComponent as RoadIcon} from '../../../Map/Common/svg/road.svg';
import {flyToPolygon} from "../../../Map/Common/fly";
import {loadRightPanelData} from "../../../RightPanel/right_panel";
import {Virtuoso} from "react-virtuoso";
import {useEffect, useRef} from "react";
import ToolbarBlockContainer from "../../../../Stockpiles/Toolbar/ToolbarBlockContainer/ToolbarBlockContainer";
import Box from "@mui/material/Box";
import {resetAllDrawing} from "../../../Map/Common/Drawing/drawing";
import {drawStyle} from "../Projects/ProjectsCommon";
import {getDrawLayerGroup, getMap1} from "../../../Map/GlobalObjects";
import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined';
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {cannotIDo} from "../../../Map/Common/tariffs";
import {RoadContextMenu} from "./RoadContextMenu";
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import {showCoordinatesEditor} from "../../../Map/Common/Dialog/CoordinatesEditor/coordinatesEditorReducer";
import {clearCoordinatesDialogInfo} from "../../../Map/Common/Dialog/CoordinatesEditor/CoordinatesEditor";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import {closeMapPopup} from "../../../Map/MapPopup/mapPopup";
import {dispatch} from "../../../../Common/misc_functions";
import {setMapHighlightedItem} from "../../../Map/ELZMapCP";

let scroll = null;
export function getScroll() {
  return scroll;
}

/**
 * Компонент воркспейса
 * @param node - нода воркспейса из roads
 * @param collapseNode - колбек свертывания/разворачивания ноды
 * @param left - положение переключателя шторки
 * @returns {JSX.Element}
 */
function WorkspaceRow({node, collapseNode, left}) {
  const selectedNode = useSelector(state => state.roadsReducer.selectedNode)
  const iconColor = node.data.length === 0?'disabled':'primary';

  return (
    <ListItem
      disablePadding
      disableGutters
    >
      <ListItemButton
        disableRipple
        disableGutters
        style={{...projectsTreeListItemStyle}}
        onClick={() => {
          collapseNode(node)
          dispatch(changeRoadsAttr({selectedNode: node}))
        }}
        selected={selectedNode === node}
      >
        <IconButton disableRipple style={treeItemButtonStyle} onClick={collapseNode}>
          {node.collapsed
            ?
            <ArrowDropDownIcon style={treeItemButtonIconSize}/>
            :
            <ArrowRightIcon style={treeItemButtonIconSize}/>
          }
        </IconButton>
        {node.is_mobile
          ? <SendToMobileOutlinedIcon fontSize={'small'} color={iconColor}/>
          : <PeopleAltOutlinedIcon fontSize={'small'} color={iconColor}/>
        }
        <IconButton disableRipple style={treeItemButtonStyle} onClick={(event) => {
          event.stopPropagation()
          showHideAllWorkspaceRoads(node, left ? !node.visible : !node.blind_visible, left)
        }}>
          {(left && node.visible) || (!left && node.blind_visible)
            ?
            <VisibilityOutlinedIcon color={'primary'} fontSize={'small'}
                                    style={{...treeItemButtonIconSize, fill: null}}/>
            :
            <VisibilityOffOutlinedIcon fontSize={'small'} style={treeItemButtonIconSize}/>
          }
        </IconButton>
        <Typography style={{paddingLeft: '5px', flex: 1, fontWeight: 600}}>{node.name}</Typography>
      </ListItemButton>
    </ListItem>
  )
}

/**
 * Компонент дороги
 * @param layer {object} - leaflet слой дороги
 * @param showHideLayer {function} - колбек щелчка по глазу
 * @param readOnly {boolean} - режим только для чтения
 * @param left {boolean} - положение переключателя шторки
 * @returns {JSX.Element}
 */
function RoadRow ({layer, showHideLayer, readOnly, left}) {

  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedNode = useSelector(state => state.roadsReducer.selectedNode)

  return (
    <ListItem style={{paddingLeft: '24px'}} disablePadding>
      <ListItemButton
        disableGutters
        disableRipple
        sx={{...projectsTreeListItemStyle}}
        onClick={(e) => {
          e.stopPropagation()
          flyToPolygon(layer.getLatLngs(), false)
          loadRightPanelData('Road', layer, layer.elz_properties.id, false)
          if (!layer.elz_oldstyle) {
            setRoadLayerStyle(layer, selectedRoadStyle, true)
            setMapHighlightedItem({
              resetStyleFunction: () => resetRoadStyle(layer),
            })
          }
          dispatch(changeRoadsAttr({selectedNode: layer}))
        }}
        selected={selectedNode === layer}
      >
        <RoadIcon style={{...treeItemButtonIconSize, fill: theme.palette.warning.main}}/>
        <IconButton disableRipple style={treeItemButtonStyle} onClick={(event) => {
          event.stopPropagation()
          if (!layer.elz_editlayer) {
            showHideLayer(layer)
          } else {
            dispatch(setSnack('info', 'Сначала завершите редактирование дороги.'))
          }
        }}>
          {(left && !layer.elz_hide) || (!left && layer.elz_blind_layer)
            ?
            <VisibilityOutlinedIcon color={'primary'} fontSize={'small'}
                                    style={{...treeItemButtonIconSize, fill: null}}/>
            :
            <VisibilityOffOutlinedIcon fontSize={'small'} style={treeItemButtonIconSize}/>}
        </IconButton>
        {!layer.elz_rename
          ?
          <>
            <Typography sx={{paddingLeft: '5px', flex: 1}}>
              {layer.elz_properties.name}
            </Typography>
            {!readOnly &&
              <>
                {layer.elz_saving
                  ?
                  <IconButton
                    disabled={!layer.elz_saving_error}
                    color={layer.elz_saving_error ? 'error' : 'disabled'}
                    style={{...treeItemButtonStyle, padding: 0}}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      dispatch(refreshRoadsTree())
                      saveRoadOnServer(layer)
                    }}
                  >
                    <SaveOutlinedIcon fontSize={'small'}/>
                  </IconButton>
                  :
                  !layer.elz_editlayer
                    ?
                    <RoadContextMenu layer={layer}/>
                    :
                    <IconButton
                      style={{...treeItemButtonStyle, padding: 0}}
                      size={'small'}
                      color={'primary'}
                      title={'Завершить редактирование'}
                      onClick={(event) => {
                        event.stopPropagation()
                        closeMapPopup()
                        stopEditing(layer.elz_editlayer)
                      }}
                    >
                      <DoneOutlinedIcon fontSize={'small'}/>
                    </IconButton>
                }
              </>
            }
          </>
          :
          <FTextField
            _dispatch={dispatch}
            _endEditing={(value) => {
              if (layer.elz_properties.name !== value) {
                layer.elz_properties.name = value;
                saveRoadOnServer(layer)
              } else {
                delete layer.elz_rename;
                dispatch(refreshRoadsTree())
              }
              //убираем фокус после нажатия enter, чтобы не оставался фон
              document.activeElement.blur()
            }}
            _cancelEdit={() => {
              delete layer.elz_rename;
              dispatch(refreshRoadsTree())
            }}
            value={layer.elz_properties.name}
            style={{paddingLeft: '5px'}}
          />
        }
      </ListItemButton>
    </ListItem>
  )
}

/**
 * Компонент панели с кнопками
 * @param startRoadDrawing
 * @param undoLastAction
 * @param createFromCoordinates
 * @param state
 * @returns {JSX.Element}
 * @constructor
 */
function Toolbar({startRoadDrawing, undoLastAction, createFromCoordinates, state}) {
  //const markersCount = getMap1().pm.Draw["Line"]._markers?.length;
  return (
    <Stack direction={'row'} sx={{marginTop: '1vh', marginBottom: '1vh'}} gap={1}>
      <ToolbarBlockContainer title={'Создать'}>
        <IconButton
          title={'Создать дорогу рисованием на карте'}
          onClick={startRoadDrawing}
          style={treeItemButtonStyle}
          color={state.drawingEnable ? 'warning' : 'primary'}
        >
          <AddRoadOutlinedIcon fontSize={'small'}/>
        </IconButton>
        <IconButton title={'Отменить последнее действие'}
                    style={treeItemButtonStyle}
                    onClick={undoLastAction}
                    disabled={!state.drawingEnable}
                    color={state.drawingEnable ? 'primary' : 'disabled'}
        >
          <UndoOutlinedIcon fontSize={'small'}/>
        </IconButton>
        <IconButton title={'Создать дорогу по координатам'}
                    style={treeItemButtonStyle}
                    onClick={createFromCoordinates}
                    disabled={state.drawingEnable}
                    color={!state.drawingEnable ? 'primary' : 'disabled'}
        >
          <RoomOutlinedIcon fontSize={'small'}/>
        </IconButton>
      </ToolbarBlockContainer>
      <Divider orientation={'vertical'}/>
    </Stack>
  )
}

export function collapseWPInReactArray(reactArray, node) {
  const ind = reactArray.findIndex(wp => wp === node)
  if (ind !== -1) {
    node.collapsed = !node.collapsed;
    if (node.collapsed) {
      const arr = node.data.map(layer => layer)
      reactArray.splice(ind + 1, 0, ...arr)
    } else {
      let end = ind + 1;
      while (end < reactArray.length && !reactArray[end].type) {
        end++;
      }
      end--;
      reactArray.splice(ind + 1, end - ind)
    }
  }
}

/**
 * Сворачивает/разворачиват воркспейс в reactArray
 * @param node - нода воркспейса
 */
export function collapseRoadNode(node) {
  const reactArray = window.store.getState()["roadsReducer"]["reactArray"];
  collapseWPInReactArray(reactArray, node)
}

/**
 * Обновляет reactArray для указанного воркспейса
 * @param workspaceNode {object}
 */
export function updateReactWorkspace(workspaceNode) {
  const reactArray = window.store.getState().roadsReducer["reactArray"];
  const index = reactArray.findIndex(wp => wp === workspaceNode) //индекс воркспейса в массиве реакта
  if (index !== -1) {
    //удаляем содержимое воркспейса
    let end = index + 1;
    while (end < reactArray.length && !reactArray[end].type) {
      end++;
    }
    end--;
    reactArray.splice(index + 1, end - index)
    //пересоздаем сожержимое воркспейса, если нужно
    if (workspaceNode.collapsed) {
      const arr = workspaceNode.data.map(layer => layer)
      reactArray.splice(index + 1, 0, ...arr)
    }
  }
}

/**
 * Показывает/скрывает все дороги указанного воркспейса
 * @param workspaceNode {object} - нода воркспейса
 * @param visible {boolean} - скрыть или показать
 * @param left {boolean} - положение переключателя шторки
 */
function showHideAllWorkspaceRoads(workspaceNode, visible, left = true) {

  if (workspaceNode) {
    if (left) {
      workspaceNode.visible = visible;
    } else {
      workspaceNode.blind_visible = visible;
    }
    workspaceNode.data.map(road => {
      if (left) {
        if (workspaceNode.visible) {//show left
          if (!workspaceNode.group.hasLayer(road)) {
            delete road.elz_hide;
            workspaceNode.group.addLayer(road)
          }
        } else {//hide left
          if (workspaceNode.group.hasLayer(road)) {
            road.elz_hide = true;
            workspaceNode.group.removeLayer(road)
          }
        }
      } else {
        if (workspaceNode.blind_visible) { //show right
          if (!road.elz_blind_layer)
            addRoadBlindLayer(road)
        } else { //hide right
          if (road.elz_blind_layer)
            removeRoadBlindLayer(road)
        }
      }
    })
    dispatch(refreshRoadsTree())
  }
}

/**
 * Показать/скрыть слой
 * @param layer {object} - слой
 */
function showHideLayer(layer) {
  const left = window.store.getState()["blindReducer"].left;
  if (left) {
    const group = layer.elz_parent_node.group;
    if (group.hasLayer(layer)) {
      layer.elz_hide = true;
      group.removeLayer(layer)
    } else {
      delete layer.elz_hide;
      group.addLayer(layer)
    }
  } else {
    if (layer.elz_blind_layer) {
      removeRoadBlindLayer(layer, layer.elz_parent_node)
      layer.elz_blind_layer = null;
    } else {
      addRoadBlindLayer(layer, layer.elz_parent_node)
    }
  }
  const dispatch = window.elz_dispatch;
  dispatch(refreshRoadsTree())
}

export function deleteRoadsWorkspaceFromReactArray(workspaceID) {
  const reactArray = window.store.getState()["roadsReducer"]["reactArray"]
  if (reactArray) {
    //Идентификация чрез id безопасна, т.к. у leaflet слоёв нет id
    const wpIndex = reactArray.findIndex(wp => wp.id === workspaceID)
    if (wpIndex !== -1) {
      let end = wpIndex + 1;
      while (end < reactArray.length && !reactArray[end].id) {
        end++;
      }
      reactArray.splice(wpIndex, end - wpIndex)
      dispatch(refreshRoadsTree())
    }
  }
}

/**
 * Дерево дорог
 * @returns {JSX.Element|null}
 */
export function RoadsTree() {
  const state = useSelector(state => state.roadsReducer)
  const left = useSelector(state => state.blindReducer.left)
  const reactArray = state.reactArray;
  const dispatch = useDispatch()
  const map = getMap1()
  const readOnly = !!cannotIDo.editRoads()
  const virtuoso = useRef(null)

  useEffect(() => {
    if (virtuoso.current) {
      scroll = virtuoso.current.scrollToIndex;
    }
    return () => {scroll = null}
  })

  if (!reactArray)
    return null;

  function createLine(e) {
    const workspace = state.selectedNode.elz_parent_node || state.selectedNode;
    const workspaceNode = getRoads().roadsByWorkspaces[workspace.id]
    const drawingGroup = getDrawLayerGroup()
    drawingGroup.removeLayer(e.layer)
    resetAllDrawing()
    createRoadLayer(e.layer, workspaceNode)
  }

  function startRoadDrawing() {
    if (!state.selectedNode) {
      dispatch(setSnack('error', 'Выберите рабочую область для сохранения дороги'))
      return;
    }
    resetAllDrawing(dispatch)
    if (!state.drawingEnable) {
      let workspace = state.selectedNode.elz_parent_node || state.selectedNode;
      if (!workspace.collapsed) {
        collapseRoadNode(workspace)
      }
      map.pm.enableDraw('Line', drawStyle)
      //map.once('pm:drawend', pmDrawEnd)
      map.on('pm:create', createLine)
      dispatch(changeRoadsAttr({drawingEnable: true}))
    }
  }

  function undoLastAction() {
    if (map.pm.Draw["Line"]._markers.length > 1)
      map.pm.Draw["Line"]["_removeLastVertex"]()
    else
      startRoadDrawing()
    dispatch(refreshRoadsTree())
  }

  function createFromCoordinates() {
    if (!state.selectedNode) {
      dispatch(setSnack('error', 'Выберите рабочую область для сохранения дороги'))
      return;
    }
    dispatch(showCoordinatesEditor([], 'Line', (res) => {
      const arr = res.map(coo => new L.LatLng(coo.lat, coo.lng))
      const layer = L.polyline(arr)
      // _leaflet_id появляется только после добавления слоя на карту. Выглядит криво, но работает :)
      map.addLayer(layer)
      map.removeLayer(layer)
      if (layer) {
        const workspace = state.selectedNode.elz_parent_node || state.selectedNode;
        const workspaceNode = getRoads().roadsByWorkspaces[workspace.id]
        createRoadLayer(layer, workspaceNode)
        clearCoordinatesDialogInfo()
        flyToPolygon(layer.getLatLngs(), true, true)
      }
    }))
  }

  const Item = (item) => {
    switch (reactArray[item].type) {
      case "road_group":
        return <WorkspaceRow key={item} node={reactArray[item]} collapseNode={collapseRoadNode} left={left}/>
      default:
        return <RoadRow key={item} layer={reactArray[item]} showHideLayer={showHideLayer} readOnly={readOnly} left={left}/>
    }
  }

  return (
    <Stack direction={'column'} sx={{height: '100%', overflow: 'hidden'}}>
      <Stack flexDirection={'row'} justifyContent="space-between" alignItems="center">
        <Typography align={'center'}>Дороги</Typography>
        <IconButton
          onClick={() => {
            dispatch(setLeftDrawerCurTab(null))
          }}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      {state.loading && <Loading/>}
      {state.loadingError &&
        <Stack gap={2}>
          <Alert severity={'error'} style={{marginTop: '1vh'}}>
            Произошла ошибка при загрузке дорог. Нажмите кнопку ниже, чтобы попробовать еще раз.
          </Alert>
          <Button
            fullWidth
            variant={'outlined'}
            onClick={loadRoads}
          >
            Повторить
          </Button>
        </Stack>
      }
      {!state.loading && !state.loadingError && reactArray && reactArray.length &&
        <>
          {!cannotIDo.editRoads() &&
            <>
              <Toolbar startRoadDrawing={startRoadDrawing} undoLastAction={undoLastAction}
                       createFromCoordinates={createFromCoordinates} state={state}
              />
              <Divider/>
            </>
          }
          <Box sx={{flex: 1, paddingTop: '1vh', boxSizing: 'border-box'}}>
            <Virtuoso
              ref={virtuoso}
              totalCount={reactArray.length}
              itemContent={(item) => Item(item)}
              skipAnimationFrameInResizeObserver
            />
            {/*<div style={scrollDivStyle}>
              {reactArray.map((item, index) => Item(index))}
            </div>*/}
          </Box>
        </>
      }
    </Stack>
  )
}
