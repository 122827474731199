export let figureCenterPointCalc = (outline) => {
  let min_x = outline.map(point => point.x).sort((a, b) => {
    return a - b
  })[0]
  let max_x = outline.map(point => point.x).sort((a, b) => {
    return a - b
  })[outline.length - 1]
  let min_y = outline.map(point => point.y).sort((a, b) => {
    return a - b
  })[0]
  let max_y = outline.map(point => point.y).sort((a, b) => {
    return a - b
  })[outline.length - 1]
  return {x: (min_x + max_x) / 2, y: (min_y + max_y) / 2}
}