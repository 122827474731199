import Stack from "@mui/material/Stack";
import {CircularProgress, Typography} from "@mui/material";
import {loadingString} from "./Strings_RU";

/**
 *
 * @param text - текст слева
 * @param size - размер
 * @param style {*} - дополнительные стили css
 * @returns {JSX.Element}
 */
export function Loading({text=loadingString, size=15, style = {paddingLeft: '5px', paddingRight: `${size/2*1.5}px`}}) {
  return (
    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} style={style}>
      <CircularProgress size={size} />
      {text && <Typography sx={{pl: '0.5vw', pt: '3px', fontSize: size}}>{text}</Typography>}
    </Stack>
  )
}
