import React from "react";
import Stack from "@mui/material/Stack";
import {MeasuringButton} from "./Measuring/MeasuringButton";
import {InfrastructureViewButton} from "./InfrastructureView/InfrastructureViewButton";

export function TopRightOverlayPane() {

  const panelStyle = {
    position: 'absolute',
    right: '1vh',
    top: '1vh',
  }

  return (
    <Stack
      id={'top_right_overlay'}
      flexDirection={'column'}
      spacing={0.3}
      alignItems={'flex-end'}
      style={panelStyle}
    >
        <MeasuringButton/>
        <InfrastructureViewButton/>
    </Stack>
  )
}
