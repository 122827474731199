import React from 'react';
import {Stack, Typography} from "@mui/material";
import ObjectData from "./ObjectData/ObjectData";
import StockpileData from "./StockpileData/StockpileData";
import {useSelector} from "react-redux";
import {rightbarBlockStyle, rightBarTitleStyle} from "./rightbarStyles";

const Rightbar = () => {
  const selectedStockpile = useSelector(state => state.stockpilesReducer.selectedStockpile)
  const selectedPhoto = useSelector(state => state.stockpilePhotoReducer.selectedPhoto)
  return (
    <Stack justifyContent={'space-between'} alignItems={'center'} sx={{width: '100%', height: '100%'}}>
      <Stack sx={{...rightbarBlockStyle, height: '60%'}} alignItems={'center'}>
        {selectedPhoto &&
          <ObjectData/>
        }
      </Stack>
      <Stack sx={{...rightbarBlockStyle, height: '40%'}} alignItems={'center'}>
        {selectedStockpile &&
          <>
            <Typography sx={rightBarTitleStyle}>Информация по штабелю</Typography>
            <StockpileData selectedPhoto={selectedPhoto}/>
          </>
        }
      </Stack>
    </Stack>
  );
};

export default Rightbar;