import React from 'react';
import {IconButton, ListItem, ListItemButton, Stack, Typography, useTheme} from "@mui/material";
import {
  defaultPaddingStep, folderTypographyStyle, fTextFieldStyle,
  projectsTreeListItemStyle,
  treeItemButtonIconSize,
  treeItemButtonStyle
} from "../../../../Map/Common/Styles";
import {refreshProjects} from "../projectsReducer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FolderIcon from "@mui/icons-material/Folder";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {FTextField} from "../FTextFiled";
import {saveChangeString} from "../../../../Map/Common/Strings_RU";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {ProjectsItemContextMenu} from "../ProjectsItemContextMenu";
import {useDispatch} from "react-redux";
import {getProjects} from "../ProjectsCommon";

const FolderTreeItem = ({node, padding, collapseNode, eyeClickHandler, endEditing, cancelEdit,
                          manuallySaving, readOnly}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const projects = getProjects()
  const notSaved = node.edit === 0 && (node.new || node.changed)
  return (
    <ListItem
      disableGutters
      disablePadding
      style={{paddingLeft: defaultPaddingStep * padding}}
    >
      <ListItemButton
        disableRipple
        style={{...projectsTreeListItemStyle}}
        selected = {node === projects.selectedNode}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'left'}
          style={{
            width: '100%',
            userSelect: 'none',
          }}
        >
          <IconButton
            disableRipple
            style={treeItemButtonStyle}
            onClick={() => {
              collapseNode(node);
              dispatch(refreshProjects())
            }}>
            {node.show ? <ArrowDropDownIcon style={treeItemButtonIconSize}/> :
              <ArrowRightIcon style={treeItemButtonIconSize}/>}
          </IconButton>
          <IconButton
            disableRipple
            style={treeItemButtonStyle}
            onClick={() => {
              collapseNode(node);
              dispatch(refreshProjects())
            }}
          >
            <FolderIcon fontSize={'small'} style={{...treeItemButtonIconSize, fill: theme.palette.primary.main}}/>
          </IconButton>
          <IconButton
            style={treeItemButtonStyle}
            onClick={() => {
              eyeClickHandler(node);
              dispatch(refreshProjects())
            }}>
            {node.visible
              ?
              <VisibilityOutlinedIcon color={'primary'} fontSize={'small'}
                                      style={{...treeItemButtonIconSize, fill: null}}/>
              :
              <VisibilityOffOutlinedIcon fontSize={'small'} style={{...treeItemButtonIconSize}}/>}
          </IconButton>
          {!node.edit ?
            <Typography
              style={{...folderTypographyStyle, color: notSaved ? '#B00000' : theme.palette.text.primary}}
              onClick={() => {
                collapseNode(node)
                dispatch(refreshProjects())
              }}>{node.name ? node.name : 'Без названия'}
            </Typography> :
            <FTextField
              _dispatch={dispatch}
              _endEditing={(e) => {
                endEditing(e, dispatch);
                dispatch(refreshProjects())
              }}
              _cancelEdit={(e) => {
                cancelEdit(e);
                dispatch(refreshProjects())
              }}
              value={node.name}
              style={fTextFieldStyle}
            />
          }
          {notSaved &&
            <IconButton
              title={saveChangeString}
              disableRipple
              style={treeItemButtonStyle}
              onClick={() => manuallySaving(node)}
            >
              <SaveOutlinedIcon fontSize={'small'}/>
            </IconButton>
          }
          {!readOnly && <ProjectsItemContextMenu node={node}/>}
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default FolderTreeItem;
