import {useEffect, useState} from 'react';
import {MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {patchRoadDataThunk} from "../../../../../redux/reducers/rightPanelReducer";

const statuses = ["Готова к вывозке", "Идет вывозка"]

const RoadStatus = ({readOnly}) => {
  const currentStatus = useSelector(state => state.rightPanelReducer.roadData?.status)
  const roadId = useSelector(state => state.rightPanelReducer.roadData?.id)
  const dispatch = useDispatch()
  const [selectedStatus, setSelectedStatus] = useState('')

  useEffect(() => {
    currentStatus && setSelectedStatus(currentStatus)
  }, [currentStatus])

  const changeRoadStatusHandler = (e) => {
    dispatch(patchRoadDataThunk({status: e.target.value}, roadId))
  }

  return (
    <>
      <Select
        disabled={readOnly}
        size={'small'}
        value={selectedStatus}
        onChange={changeRoadStatusHandler}
      >
        {statuses.map((status, i) =>  <MenuItem key={i} value={status}>{status}</MenuItem>)}
      </Select>
    </>
  )
}

export default RoadStatus;
