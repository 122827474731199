import {otherDataAPI} from "../../api/api";
import {setSnack} from "../../components/Main/Map/Common/Dialog/Snack/snackReducer";
import {resetSvgFiguresState, setPlanetableSvgAttr} from "./planetableSvgReducer";
import {handleErrors} from "../commonReducerFunctions/ThunkErrorsHandler";

const SET_PLANETABLES_ATTR = 'SET_PLANETABLES_ATTR'
const SET_PLANETABLE = 'SET_PLANETABLE'
const DELETE_PLANETABLE = 'DELETE_PLANETABLE'
const REPLACE_PLANETABLE = 'REPLACE_PLANETABLE'

let initialState = {
  allPlanetables: [],
  planetableIsUploading: false,
  AltDistrictForestryWithQuartersList: null,
  showUploadPlanetableModal: {status: false, planetable: null, viewMode: false},

  cacheRegion: null,
  cacheForestry: null,
  cacheDistrictForestry: null,

  allPlanetablesLoading: false,
  allPlanetablesError: false,
  selectedPlanetableLoading: false,
  selectedPlanetableError: false,
  altDistrictForestryLoading: false

}

// редьюсер страницы добавления планшетов
export const planetablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANETABLES_ATTR: {
      return {...state,  ...action.payload};
    }
    case SET_PLANETABLE: {
      return {...state, allPlanetables: [...state.allPlanetables, action.payload]};
    }
    case DELETE_PLANETABLE: {
      return {...state, allPlanetables: state.allPlanetables.filter(item => item.id !== action.planetableId)};
    }
    case REPLACE_PLANETABLE: {
      return {...state, allPlanetables: state.allPlanetables.map(item => {
          return (action.planetable.id === item.id) ? action.planetable : item})
        };
    }
    default: return state
  }
}

//Action creators
export const setPlanetablesAttr = (payload) => ({type: SET_PLANETABLES_ATTR, payload});
export const setPlanetableInPlanetablesList = (payload) => ({type: SET_PLANETABLE, payload});
export const removePlanetableFromPlanetablesList = (planetableId) => ({type: DELETE_PLANETABLE, planetableId});
export const replacePlanetable = (planetable) => ({type: REPLACE_PLANETABLE, planetable});

//Thunks
export const getAllPlanetablesThunk = () => {
  return async (dispatch) => {
    try {
      dispatch(setPlanetablesAttr({allPlanetablesLoading: true}))
      let res = await otherDataAPI.planetable.getPlanetables()
      dispatch(setPlanetablesAttr({allPlanetables: res.data}))
      dispatch(setPlanetablesAttr({allPlanetablesLoading: false}))
    } catch (e) {
      dispatch(setPlanetablesAttr({allPlanetablesLoading: false, allPlanetablesError: true}))
      handleErrors(dispatch, e)
    }
  }
}

export const getPlanetableThunk = (planetableId) => {
  return async (dispatch) => {
    try {
      dispatch(setPlanetablesAttr({selectedPlanetableLoading: true}))
      let res = await otherDataAPI.planetable.getPlanetable(planetableId)
      dispatch(setPlanetableSvgAttr({selectedPlanetable: res.data}))
      dispatch(replacePlanetable(res.data))
      dispatch(setPlanetablesAttr({selectedPlanetableLoading: false}))
    } catch (e) {
      dispatch(setPlanetablesAttr({selectedPlanetableLoading: false, selectedPlanetableError: true}))
      handleErrors(dispatch, e)
    }
  }
}

export const uploadPlanetableThunk = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setPlanetablesAttr({planetableIsUploading: true}))
      const formData = new FormData();
      data.rental_contract && formData.append(`rental_contract`, data.rental_contract.id);
      data.district_forestry && formData.append("district_forestry", data.district_forestry.id);
      data.image && formData.append("image", data.image);
      data.scale && formData.append("scale", data.scale);
      data.name ? formData.append("name", data.name) : formData.append("name", data.image.name);
      data.magnetic_declination && formData.append("magnetic_declination", data.magnetic_declination);
      let res = await otherDataAPI.planetable.uploadPlanetable(formData)
      dispatch(setPlanetableInPlanetablesList(res.data))
      dispatch(setSnack('success', 'Планшет сохранен'))
      dispatch(setPlanetablesAttr({planetableIsUploading: false,
        showUploadPlanetableModal: {status: false, planetable: null, viewMode: false}}))
    } catch (e) {
      dispatch(setPlanetablesAttr({planetableIsUploading: false}))
      handleErrors(dispatch, e)
    }
  }
}

export const patchPlanetableThunk = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await otherDataAPI.planetable.patchPlanetable(data)
      let planetables = getState().planetablesReducer.allPlanetables
      let newPlanetablesList = planetables.map(item => ((item.id === res.data.id) ? res.data : item))
      dispatch(setPlanetablesAttr({
        allPlanetables: newPlanetablesList,
        showUploadPlanetableModal: {status: false, planetable: null, viewMode: false},
      }))
      dispatch(setPlanetableSvgAttr({
        selectedPlanetable: res.data
      }))
      dispatch(setSnack('success', 'Данные планшета изменены'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const deletePlanetableThunk = (planetableId) => {
  return async (dispatch, getState) => {
    try {
      let res = await otherDataAPI.planetable.deletePlanetable(planetableId)
      dispatch(removePlanetableFromPlanetablesList(planetableId))
      if (getState().planetableSvgReducer.selectedPlanetable.id === planetableId)  {
        dispatch(setPlanetableSvgAttr({selectedPlanetable: null}))
        dispatch(resetSvgFiguresState())
      }
      dispatch(setSnack('success', 'Планшет удален'))
    } catch (e) {
      handleErrors(dispatch, e)
    }
  }
}

export const getAltDistrictForestryWithQuartersListThunk = (altDistrictForestryId) => {
  return async (dispatch) => {
    try {
      dispatch(setPlanetablesAttr({altDistrictForestryLoading: true}))
      let res = await otherDataAPI.references.getAltDistrictForestryWithQuartersList(altDistrictForestryId)
      dispatch(setPlanetablesAttr({AltDistrictForestryWithQuartersList: res.data}))
      dispatch(setPlanetablesAttr({altDistrictForestryLoading: false}))
    } catch (e) {
      dispatch(setPlanetablesAttr({altDistrictForestryLoading: false}))
      handleErrors(dispatch, e)
    }
  }
}
