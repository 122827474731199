import {setPhotoAttr} from "../../../../../redux/reducers/stockpilePhotoReducer";
import {cropOutlinesByRulerWidth} from "./cropOutlineByRulerWidth";
import {cropTimbersByRulerWidth} from "./cropTimbersByRulerWidth";

export const rulerModeClickHandler = (currentPosition, svgElementName, svgElementIndex, dispatch,
                                      creationMode, ruler, outlines, selectedPhoto) => {
  if (svgElementName === 'ruler') {
    dispatch(setPhotoAttr({
      ruler_selected_index: 0,
    }))
  }
  if (svgElementName === 'rulerPoint') {
    dispatch(setPhotoAttr({
      ruler_selected_point_index: svgElementIndex,
    }))
  }
  if (creationMode === 'ruler') {
    ruler.length === 0 && dispatch(setPhotoAttr({ruler: [currentPosition]}))
    if (ruler.length === 1) {
      let orderingRuler = [...ruler, currentPosition]
      orderingRuler.sort((point1, point2) => point1.x - point2.x);
      dispatch(setPhotoAttr({ruler: orderingRuler}))
      cropOutlinesByRulerWidth(outlines, orderingRuler, dispatch, selectedPhoto)
      cropTimbersByRulerWidth(orderingRuler, dispatch, selectedPhoto)
    }
    ruler.length === 2 && dispatch(setPhotoAttr({ruler: [currentPosition]}))
  }
}