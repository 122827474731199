import React, {useState} from 'react';
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {treeItemButtonIconSize} from "../../../../../Main/Map/Common/Styles";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {deletePlanetableThunk, setPlanetablesAttr} from "../../../../../../redux/reducers/planetablesReducer";
import {useDispatch, useSelector} from "react-redux";
import {hideMapDialog, showMapDialog} from "../../../../../Main/Map/Common/Dialog/DialogReducer";
import {confirmDeletePlanetable, deletingPlanetable} from "../../../../../Main/Map/Common/Strings_RU";

const PlanetableListItemContextMenu = () => {
  const dispatch = useDispatch()
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuOpen = Boolean(menuAnchor);
  const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)
  const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}

  const deletePlanetableHandler = () => {
    dispatch(showMapDialog('warning', deletingPlanetable, confirmDeletePlanetable, buttons, (e) => {
      dispatch(hideMapDialog())
      if (e === 'Да') {
        dispatch(deletePlanetableThunk(selectedPlanetable.id))
      }
      setMenuAnchor(null)
    }))
  }

  const updatePlanetableHandler = () => {
    dispatch(setPlanetablesAttr({showUploadPlanetableModal: {status: true, planetable: selectedPlanetable,
        viewMode: false}}))
    setMenuAnchor(null)
  }

  return (
    <>
      <IconButton
        style={{padding: 0}}
        onClick={(e) => {
          setMenuAnchor(e.currentTarget)
        }}
      >
        <MoreVertIcon fontSize={'small'}/>
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <MenuItem
          onClick={() => {
            updatePlanetableHandler()
          }}
        >
          <EditOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>Редактировать</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            deletePlanetableHandler()
          }}
        >
          <DeleteOutlineOutlinedIcon size={'small'} style={{...treeItemButtonIconSize, paddingRight: 10}}/>
          <Typography>Удалить</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default PlanetableListItemContextMenu;