import React, {useEffect, useRef, useLayoutEffect} from 'react';
import {modalWindowStyle} from "../../../../../Common/Styles";
import ModalPage from "../../../../../Common/ModalPage";
import Box from "@mui/material/Box";
import {setRightPanelAttr} from "../../../../../../redux/reducers/rightPanelReducer";
import {useDispatch, useSelector} from "react-redux";
import {urlModify} from "../../../../../../utils/ulrModify";
import {IconButton} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {iconLeft, iconRight} from "../../../rightPanelStyles";
import DownloadIcon from "@mui/icons-material/Download";
import {saveImageLocally} from "../../../../../../utils/saveImageLocally";

const FullPhotoModal = ({name, photos}) => {
  const dispatch = useDispatch()
  const selectedPhoto = useSelector(state => state.rightPanelReducer.fullPhotoModal.selectedPhoto)
  const position = useSelector(state => state.rightPanelReducer.fullPhotoModal.position)
  const [scale, setScale] = React.useState(1)
  let formattedPhotoUrl = urlModify(selectedPhoto.image)
  const containerRef = useRef(null);


  // resizeObserver для изменения размера фотографии при изменении размера контейнера
  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container) {
      setScale(Math.min(container.clientWidth / selectedPhoto.image_width,
        container.clientHeight / selectedPhoto.image_height * 0.9));
      container.focus();
      const resizeObserver = new ResizeObserver(() => {
        setScale(Math.min(container.clientWidth / selectedPhoto.image_width,
          container.clientHeight / selectedPhoto.image_height * 0.9));
      });
      resizeObserver.observe(container);
      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, [selectedPhoto]);

  const handleClose = () => {
    dispatch(setRightPanelAttr({fullPhotoModal: {status: false, selectedPhoto: null, position: null,}}))
  }

  if (!selectedPhoto) return ''

  const changeSelectedPhoto = (direction) => {
    if (direction === 'right' && position < photos.length) {
      dispatch(setRightPanelAttr({
        fullPhotoModal: {
          status: true, selectedPhoto: photos[position],
          position: position + 1
        }
      }))
    }
    if (direction === 'left' && position > 1) {
      dispatch(setRightPanelAttr({
        fullPhotoModal: {
          status: true, selectedPhoto: photos[position - 2],
          position: position - 1
        }
      }))
    }
  }

  const keyDownHanlder = (e) => {
    if (e.code === 'KeyD') {
      changeSelectedPhoto('right')
    }
    if (e.code === 'KeyA') {
      changeSelectedPhoto('left')
    }
    if (e.code === 'Escape') {
      handleClose()
    }
  }

  return (
    <ModalPage
      title={`${name} (фото №${position})`}
      handleClose={handleClose}
      actionButtonRemove={true}
      customStyle={{...modalWindowStyle, width: '70vw', height: '80vh'}}
      titleIconButton={
      <IconButton onClick={() => saveImageLocally(formattedPhotoUrl)} title={'Скачать фото'}>
        <DownloadIcon/>
      </IconButton>
      }
    >
      <Box
        sx={{
          width: '100%', height: '100%', display: 'flex',
          alignItems: 'center', justifyContent: 'center', outline: 'none'
        }}
        ref={containerRef}
        id={'photoContainer'}
        onKeyDown={keyDownHanlder}
        tabIndex={0}
      >
        <img
          style={{height: selectedPhoto.image_height * scale, width: selectedPhoto.image_width * scale}}
          src={formattedPhotoUrl}
        />
        {position < photos.length &&
          <IconButton style={iconRight} title={'Следующее фото (D)'}
                      onClick={() => changeSelectedPhoto('right')}>
            <ChevronRightIcon style={{fontSize: 40}}/>
          </IconButton>
        }
        {position > 1 &&
          <IconButton style={iconLeft} title={'Предыдущее фото (A)'}
                      onClick={() => changeSelectedPhoto('left')}>
            <ChevronLeftIcon style={{fontSize: 40}}/>
          </IconButton>
        }
      </Box>
    </ModalPage>
  );
};

export default FullPhotoModal;
