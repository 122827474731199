import React from 'react';
import {Typography} from "@mui/material";
import {blockTitle} from "../../rightPanelStyles";

const LastChange = ({objectData}) => {
  const {created_at = '', created_by = '', updated_at = '', updated_by = ''} = objectData || {};
  return (
    <>
      <Typography style={blockTitle}>Данные о редактировании</Typography>
      <Typography sx={{fontSize: 14}}>{`Создан: UTC ${created_at || '-'} (${created_by || '-'})`}</Typography>
      <Typography sx={{fontSize: 14}}>{`Изменен: UTC ${updated_at || '-'} (${updated_by || '-'})`}</Typography>
    </>
  );
};

export default LastChange;