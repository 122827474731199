import React from 'react';
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import NorthIcon from "@mui/icons-material/North";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import WestIcon from "@mui/icons-material/West";
import SouthIcon from "@mui/icons-material/South";
import EastIcon from "@mui/icons-material/East";
import {IconButtonStyle, iconStyle} from "./WASDButtonsBlockStyles";

const IconButtonSpanStyle = {
  position: 'absolute',
  bottom: 7,
  left: 7,
  fontWeight: 'bold',
  fontSize: 12,
  transform: 'translate(-50%, 50%)',
}

const pressedButtonColor = '#e57373'

// блок кнопок qweasd для корректировки положения svg-фигур поверх изображения
const WASDButtonsBlock = ({svgElement, rotateButtonsHandler, arrowButtonsHandler, currentlyPressedButton,
                            leftPos, topPos}) => {
  return (
    <Stack style={{borderRadius: 10, dispay: 'flex', padding: 2,
      justifyContent: 'center', alignItems: 'center', marginBottom: 2, position: 'absolute', left: leftPos, top: topPos,
      transform: 'translate(-50%, 50%)', backgroundColor: 'rgba(255,255,255,0.8)', opacity: 0.8}}
           onClick={() => {
             svgElement.focus()
           }}
    >
      <Stack direction={'row'} style={{dispay: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <IconButton
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyQ' && pressedButtonColor}}
          color={'primary'}
          title={'Повернуть влево'}
          onClick={() => rotateButtonsHandler('left')}
        >
          <RotateLeftIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        Q
        </span>
        </IconButton>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyW' && pressedButtonColor}}
          onClick={() => arrowButtonsHandler('up')}
        >
          <NorthIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        W
        </span>
        </IconButton>
        <IconButton
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyE' && pressedButtonColor}}
          color={'primary'}
          title={'Повернуть вправо'}
          onClick={() => rotateButtonsHandler('right')}
        >
          <RotateRightIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        E
        </span>
        </IconButton>
      </Stack>
      <Stack direction={'row'} alignItems={'center'}>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyA' && pressedButtonColor}}
          onClick={() => arrowButtonsHandler('left')}
        >
          <WestIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        A
        </span>
        </IconButton>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyS' && pressedButtonColor}}
          onClick={() => arrowButtonsHandler('down')}
        >
          <SouthIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        S
        </span>
        </IconButton>
        <IconButton
          color={'primary'}
          style={{...IconButtonStyle, backgroundColor: currentlyPressedButton === 'KeyD' && pressedButtonColor}}
          onClick={() => arrowButtonsHandler('right')}
        >
          <EastIcon style={iconStyle}/>
          <span
            style={IconButtonSpanStyle}
          >
        D
        </span>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default WASDButtonsBlock;
