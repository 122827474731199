import React, {useState} from 'react';
import {Collapse, List, ListItem, Typography, useTheme} from "@mui/material";
import {PlanetablesTree} from "../PlanetablesTree";
import IconButton from "@mui/material/IconButton";
import {treeItemButtonIconSize, treeItemButtonStyle} from "../../../../Main/Map/Common/Styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Stack from "@mui/material/Stack";
import {useDispatch, useSelector} from "react-redux";
import {isOdd} from "../../../../Main/Map/Common/MiscFunction";
import {getPlanetableThunk} from "../../../../../redux/reducers/planetablesReducer";
import {resetSvgFiguresState, setPlanetableSvgAttr} from "../../../../../redux/reducers/planetableSvgReducer";
import {planetablesTextColor, planetablesTreeLeftPaddingStep, planetablesTreelistItemStyle, treeItemTitleText,
} from "../../../PlanetabesPageSyles";
import PlanetableItem from "./PlanetableItem/PlanetableItem";


// компонент в котором рекурсивно создаются элементы дерева сгруппированных планшетов
const PlanetablesTreeItem = ({uniqueValues, itemsList, field, restFields, step, readOnly}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)
    const selectedGroups = useSelector(state => state.planetablesGroupingReducer.selectedGroups)
    const selectedGroupslist = Object.keys(selectedGroups).filter(item => selectedGroups[item])
    const [openedItems, setOpenedItems] = useState({});

    const handleOnPlanetableClick = (e, planetable) => {
      e.stopPropagation()
      if (selectedPlanetable?.id !== planetable.id) {
        dispatch(getPlanetableThunk(planetable.id))
        dispatch(resetSvgFiguresState())
        dispatch(setPlanetableSvgAttr({mode: 'view'}))
      }
    };

    const listItemHandleClick = (e, value) => {
      e.stopPropagation()
      value in openedItems
        ? setOpenedItems(prev => ({...prev, [value]: {status: !prev[value].status}}))
        : setOpenedItems(prev => ({...prev, [value]: {status: true}}))
    };

    // Если это последнее поле для группировки, то выводим последнюю вкладку дерева и списки имен планшетов
    if (restFields.length === 0) {
      return (
        <List disablePadding >
          {uniqueValues.map((value) => {
            const filteredPlanetables = itemsList.filter((item) => item[field].name === value);
            if (filteredPlanetables.length === 0) {
              return null;
            }
            return (
              <ListItem key={value}
                        style={{...planetablesTreelistItemStyle,
                          paddingLeft: step === 1 ? 0 : planetablesTreeLeftPaddingStep}}
                        onClick={(e) => listItemHandleClick(e, value)}>
                <Stack direction={'row'} alignItems={'center'}>
                  <IconButton
                    disableRipple
                    style={treeItemButtonStyle}
                  >
                    {openedItems[value]?.status ? <ArrowDropDownIcon style={treeItemButtonIconSize}/> :
                      <ArrowRightIcon style={treeItemButtonIconSize}/>}
                  </IconButton>
                  <Typography
                    style={{color: isOdd(step) ? planetablesTextColor : theme.palette.primary.main,
                      ...treeItemTitleText}}>
                    {value}
                  </Typography>
                </Stack>
                <Collapse in={openedItems[value]?.status}
                          timeout="auto"
                          unmountOnExit
                          sx={{width: '100%'}}>
                  <List component="div" disablePadding >
                    {filteredPlanetables.map(item => (
                      <PlanetableItem key={item.id} item={item} handleOnPlanetableClick={handleOnPlanetableClick} readOnly={readOnly}/>
                    ))}
                  </List>
                </Collapse>
              </ListItem>
            );
          })}
        </List>
      )
    }

    // Если это не последнее поле для группировки, то рекурсивно создаем компоненты для каждого уникального значения поля
    return (
      <List disablePadding style={{width: '100%'}}>
        {uniqueValues.map((value) => {
          const filteredItems = itemsList.filter((items) => items[field].name === value);

          if (filteredItems.length === 0) {
            return null;
          }

          return (
            <ListItem key={value}
                      style={{...planetablesTreelistItemStyle,
                        paddingLeft: step === 1 ? 0 : planetablesTreeLeftPaddingStep}}
                      onClick={(e) => listItemHandleClick(e, value)}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <IconButton
                  disableRipple
                  style={treeItemButtonStyle}
                >
                  {openedItems[value]?.status
                    ? <ArrowDropDownIcon style={treeItemButtonIconSize}/>
                    : <ArrowRightIcon style={treeItemButtonIconSize}/>}
                </IconButton>
                <Typography
                  style={{color: isOdd(step) ? planetablesTextColor : theme.palette.primary.main,
                    ...treeItemTitleText}}>
                  {value}
                </Typography>
              </Stack>
              <Collapse in={openedItems[value]?.status} timeout="auto" unmountOnExit sx={{width: '100%'}}>
                <PlanetablesTree itemsList={filteredItems} fields={restFields} selectedGroupslist={selectedGroupslist}/>
              </Collapse>
            </ListItem>
          );
        })}
      </List>
    );
  }
;

export default PlanetablesTreeItem;