import {useDispatch, useSelector} from "react-redux";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  Stack,
} from "@mui/material";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {dataAPI} from "../../../../../../api/api";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";
import {
  cancelText,
  clearString,
  closeString,
  createString,
  loadingString,
  nameString,
  notFoundString,
  pillarCreatingString,
  pillarPatchingString,
  quarterSearchRadius,
  quartersLoadingErrorString,
  quartersString,
  saveString,
  workspaceString
} from "../../../../Map/Common/Strings_RU";
import {checkForFloat} from "../../../../Map/Common/MiscFunction";
import {
  addLayerToWorkspace,
  createPillarMarker,
  deletePillarFromMap,
  getPillarIndexInWorkspaceData,
  getPillars,
  updatePillarsReactWorkspace
} from "./pillars";
import {getDrawLayerGroup} from "../../../../Map/GlobalObjects";
import {loadRightPanelData} from "../../../../RightPanel/right_panel";
import {changePillarsDialogAttr, closePillarDialog, refreshPillarsTree} from "./pillarsReducer";
import {handleErrors} from "../../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {flyToPoint} from "../../../../Map/Common/fly";
import {FTextField} from "../../Projects/FTextFiled";

const distanceList = [200, 500, 700, 1000, 1500, 2000, 3000, 5000, 7000, 10000];

/**
 * Диалог создания/изменения столба
 * @returns {JSX.Element|null}
 * @constructor
 */
export function CreatePillarDialog() {
  const state = useSelector(state => state.pillarsReducer.pillarDialog)
  const editMode = state.editMode;
  const [workspaces, setWorkspaces] = useState(null)
  const dispatch = useDispatch()
  const pillars = getPillars()
  const quartersNotConverted = state.quarters.length && typeof state.quarters[0] === 'number';

  function closeDialog(event, reason) {
    if (reason === 'backdropClick')
      return;
    dispatch(closePillarDialog())
  }

  function permitCreating() { // создание/изменение столба
    const json = {
      name: state.name,
      rental_contract: state.workspace.id,
      geometry: {type: 'Point', coordinates: []}
    }
    json.geometry.coordinates = [state.coordinates.lng, state.coordinates.lat];
    json.x_quarters = state.quarters.map(quarter => quarter.id)
    if (!editMode) //создание
      dataAPI.pillars.create(json).then(res => {
        const lay = createPillarMarker(res.data, 'pillars', state.workspace)
        const ws = addLayerToWorkspace(res.data.rental_contract, lay)
        if (ws)
          ws.group.addLayer(lay)
        updatePillarsReactWorkspace(ws.id)
        if (state.shape) {
          state.shape.node.visible = false;
          getDrawLayerGroup().removeLayer(state.shape)
          if (state.shape.node.id) //если создано из маркера
            dataAPI.projects.shapes.patch(state.shape.node.id, {is_visible_web: false}).then().catch(err => console.error(err))
        }
        closeDialog()
        loadRightPanelData('Pillar', lay, lay.elz_properties.id)
        dispatch(setSnack('success', 'Квартальный столб создан'))
        dispatch(refreshPillarsTree())
        flyToPoint(lay.getLatLng(), true, 12)
      }).catch(err => {
        handleErrors(dispatch, err)
        dispatch(refreshPillarsTree())
      })
    else { //изменение
      dataAPI.pillars.patch(state.shape.elz_properties.id, json).then(res => {
        const oldLay = state.shape;
        const pane = oldLay.options.pane;
        const ind = getPillarIndexInWorkspaceData(oldLay)
        deletePillarFromMap(oldLay, false)

        const newWorkspace = pillars.pillarsByWorkspaces[res.data.rental_contract]
        const newLay = createPillarMarker(res.data, pane, newWorkspace)
        const workspace = oldLay.elz_parent_node;

        if (workspace === newWorkspace) { //workspace not changed
          workspace.data[ind] = newLay;
          workspace.group.addLayer(newLay)
        } else { //workspace changed
          workspace.data.splice(ind, 1)
          const ws = addLayerToWorkspace(newWorkspace.id, newLay)
          if (ws)
            ws.group.addLayer(newLay)
          newWorkspace.group.addLayer(newLay)
          updatePillarsReactWorkspace(newWorkspace.id)
        }
        const element = window.store.getState().rightPanelReducer["selectedElement"];
        if (element && element.id === newLay["elz_properties"].id)
          loadRightPanelData('Pillar', newLay, newLay["elz_properties"].id)
        closeDialog()
        updatePillarsReactWorkspace(workspace.id)
        dispatch(refreshPillarsTree())
        flyToPoint(newLay.getLatLng(), true, 12)
      }).catch(err => {
        handleErrors(dispatch, err)
      })
    }
  }

  useEffect(() => {
    if (state.coordinates?.lat && state.coordinates?.lng && !state.loading && !state.loaded && pillars && workspaces) {
      dispatch(changePillarsDialogAttr({loading: true, loaded: false}))
      const json = {
        geometry: {
          type: 'Point',
          coordinates: [state.coordinates.lng, state.coordinates.lat],
        },
        distance: state.distance,
      }
      if (!editMode || !quartersNotConverted) { //поиск кварталов по координате и дистанции
        dataAPI.miscGis.getQuartersByPoint(json).then(res => {
          dispatch(changePillarsDialogAttr({allQuarters: res.data, quarters: res.data, loaded: true, loading: false}))
        }).catch(err => {
          console.error(err)
          dispatch(changePillarsDialogAttr({loaded: true, loading: false}))
          dispatch(setSnack('error', quartersLoadingErrorString))
        })
      } else { //вызавается только один раз для конвертации списка id кварталов в список кварталов в режиме редактирования
        const json = {
          ids: state.quarters,
        }
        dataAPI.miscGis.getQuartersByIDs(json).then(res => {
          dispatch(changePillarsDialogAttr({allQuarters: res.data, quarters: res.data, loaded: true, loading: false}))
        }).catch(err => {
          console.error(err)
          dispatch(setSnack('error', quartersLoadingErrorString))
        })
      }
    }
  })

  useEffect(() => {
    if (!workspaces) {
      const arr = Object.values(pillars.pillarsByWorkspaces)
      setWorkspaces(arr)
    }
  }, [workspaces])

  if (!state.workspace || quartersNotConverted) {
    return null;
  }

  return (
    <Dialog open={true} onClose={closeDialog}>
      <DialogTitle>{editMode ? pillarPatchingString : pillarCreatingString}</DialogTitle>
      <DialogContent>
        <Stack direction={'column'} spacing={2} style={{width: '100%'}}>
          <Stack direction={'row'} spacing={1} style={{width: '100%'}}>
            <FTextField
              style={{flex: 0.5}}
              error={!Boolean(state.name)}
              label={nameString}
              value={state.name}
              _endEditing={(value) => {
                dispatch(changePillarsDialogAttr({name: value}))
              }}
              nofocus
            />
            <Autocomplete
              clearText={clearString}
              closeText={closeString}
              noOptionsText={notFoundString}
              style={{flex: 0.5}}
              options={workspaces}
              value={state.workspace}
              getOptionLabel={data => data.name}
              getOptionKey={option => option.id}
              renderInput={(params) =>
                <TextField {...params} variant="standard" label={workspaceString} placeholder="" size={'small'}/>
              }
              onChange={(v, r) => {
                dispatch(changePillarsDialogAttr({workspace: r}))
              }}
            />
          </Stack>
          <Stack direction={'row'} spacing={1} style={{width: '100%'}}>
            <FTextField
              style={{flex: 0.5}}
              nofocus
              error={!Boolean(state.coordinates.lat)}
              label={'Широта'}
              value={state.coordinates?.lat || ''}
              _endEditing={(value) => {
                const number = checkForFloat(value.toString())
                const coordinates = structuredClone(state.coordinates)
                if (number) {
                  if (coordinates.lat && coordinates.lng && coordinates.lat.toString() !== value) {
                    dispatch(changePillarsDialogAttr({allQuarters: [], loaded: false}))
                  }
                  coordinates.lat = number;
                } else {
                  coordinates.lat = '';
                }
                dispatch(changePillarsDialogAttr({coordinates: coordinates}))
              }}
            />
            <FTextField
              style={{flex: 0.5}}
              nofocus
              error={!Boolean(state.coordinates.lng)}
              label={'Долгота'}
              value={state.coordinates?.lng || ''}
              _endEditing={(value) => {
                const number = checkForFloat(value.toString())
                const coordinates = structuredClone(state.coordinates)
                if (number) {
                  if (coordinates.lat && coordinates.lng && coordinates.lng.toString() !== value) {
                    dispatch(changePillarsDialogAttr({allQuarters: [], loaded: false}))
                  }
                  coordinates.lng = number;
                } else {
                  coordinates.lng = '';
                }
                dispatch(changePillarsDialogAttr({coordinates: coordinates}))
              }}
            />
          </Stack>
          <Autocomplete
            style={{flex: 0.5}}
            multiple
            disableCloseOnSelect
            size={'small'}
            disabled={!state.loaded}
            options={state.allQuarters}
            loading={state.loading}
            loadingText={loadingString}
            value={state.quarters}
            clearText={clearString}
            closeText={closeString}
            noOptionsText={notFoundString}
            isOptionEqualToValue={(a, b) => a === b}
            input={<OutlinedInput label="Tag" size={'small'}/>}
            getOptionLabel={(data) => `кв. ${data.number}/${data['u_les_vo']}/${data['les_vo']}`}
            renderInput={(params) =>
              <TextField {...params} variant="standard" label={quartersString} placeholder="" size={'small'}/>
            }
            renderTags={(value, getTagProps) => {
              return value.map((val, index) =>
                      <Chip
                        label={`кв. ${value[index].number}`}
                        variant="outlined"
                        color={'primary'}
                        size={'small'}
                        style={{margin: 3}}
                        {...getTagProps({index})}
                      />
              )
            }}
            onChange={(e, value) => {
                dispatch(changePillarsDialogAttr({quarters: value}))
            }}
          />
          <Autocomplete
            freeSolo
            clearText={clearString}
            closeText={closeString}
            noOptionsText={notFoundString}
            style={{flex: 0.5}}
            renderInput={(params) => {
              return (
                <TextField {...params} variant="standard" label={quarterSearchRadius} placeholder="" size={'small'}/>
              )
            }}
            options={distanceList}
            value={state.distance}
            getOptionLabel={(value) => value.toString()}
            onChange={(e, value) => {
              dispatch(changePillarsDialogAttr({distance: value, loaded: false, allQuarters: []}))
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={permitCreating}
          variant={'outlined'}
          color={'warning'}
          disabled={
            !state.name ||
            !state.quarters?.length ||
            !state.coordinates.lat ||
            !state.coordinates.lng ||
            !state.workspace}
        >
          {editMode ? saveString : createString}
        </Button>
        <Button onClick={closeDialog} variant={'outlined'}>{cancelText}</Button>
      </DialogActions>
    </Dialog>
  )
}
