import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {useState} from "react";
import {Autocomplete, Checkbox, TextField, Typography} from "@mui/material";
import {cannotIDo} from "../../../../../../Main/Map/Common/tariffs";
import {OwnerCrownIcon} from "../OwnerCrownIcon";
import {dispatch} from "../../../../../../Common/misc_functions";
import {changeOrgPageAttrs} from "../../../../../organizationPageReducer";

function fixRoles(arr, user, changedRole) {
  const newArr = structuredClone(arr)
  const iAmAdmin = cannotIDo.ownerAction()
  const adminRole = newArr.find(item => item.id === 'is_admin')
  const editorRole = newArr.find(item => item.id === 'is_editor')
  const fullViewRole = newArr.find(item => item.id === 'is_full_access_user')
  const onlyMobileRole = newArr.find(item => item.id === 'only_mobile')

  switch (changedRole) {
    case 'is_admin':
      if (adminRole.checked) {
        editorRole.checked = true;
        fullViewRole.checked = true;
        onlyMobileRole.checked = false;
      }
      break;
    case 'is_editor':
      if (editorRole.checked) {
        fullViewRole.checked = true;
        onlyMobileRole.checked = false;
      } else {
        adminRole.checked = false;
      }
      break;
    case 'is_full_access_user':
      console.log(fullViewRole)
      if (fullViewRole.checked) {
        onlyMobileRole.checked = false;
      } else {
        editorRole.checked = false;
        adminRole.checked = false;
      }
      break;
    case 'only_mobile':
      if (onlyMobileRole.checked) {
        adminRole.checked = false;
        editorRole.checked = false;
        fullViewRole.checked = false;
      }
      break;
  }
  if (iAmAdmin) { //менять роль админ можно только владельцу
    adminRole.disabled = true;
  }
  return newArr;
}

export function RolesList({userData}) {
  const iAmOwner = !cannotIDo.ownerAction()
  const [roles, setRoles] = useState(
    [
      {id: 'is_admin', name: 'Админ', checked: userData.is_admin, disabled: !iAmOwner},
      {id: 'is_editor', name: 'Редактор', checked: userData.is_editor, disabled: false},
      {id: 'is_full_access_user', name: 'Полный просмотр', checked: userData.is_full_access_user, disabled: false},
      {id: 'only_mobile', name: 'Только GisApp Mobile', checked: userData.only_mobile, disabled: false},
      {id: 'deny_access', name: 'Нет доступа', checked: userData.deny_access, disabled: false},
    ]
  )
  const icon = <CheckBoxOutlineBlankIcon sx={{fontSize: 14}}/>
  const checkedIcon = <CheckBoxIcon sx={{fontSize: 14}}/>

  function handleRoleChange(e, selectedItems) {
    let updatedRoles = roles.map((role) => {
      return {...role, checked: selectedItems.findIndex(item => item.id === role.id) !== -1}
    })

    let changedItem;
    roles.forEach(role => { //ищем в старом массиве
      const sRole = selectedItems.find(r => r.id === role.id)
      const newChecked = Boolean(sRole)
      const oldChecked = role.checked;
      if (newChecked !== oldChecked) changedItem = role;
    })
    updatedRoles = fixRoles(updatedRoles, userData, changedItem?.id)
    const newUserData = {...window.store.getState().organizationPageReducer.userData}
    updatedRoles.forEach(role => {
      newUserData[role.id] = role.checked;
    })
    setRoles(updatedRoles) //ставим роли в менюшке
    dispatch(changeOrgPageAttrs({userData: newUserData}))
  }

  if (userData.is_owner) {
    return <OwnerCrownIcon/>
  }

  const initialValue = roles.filter((role) => role.checked)

  return (
    <Autocomplete
      multiple
      options={roles}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={initialValue}
      getOptionDisabled={(option) => option.disabled}
      onChange={handleRoleChange}
      renderOption={(props, option) => (
        <li {...props}>
          <Checkbox
            size={'small'}
            sx={{p: 0}}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={option.checked}
          />
          <Typography sx={{fontSize: 13}}>
            {option.name}
          </Typography>

        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Роль" variant="standard" size="small"/>
      )}
    />
  )
}
