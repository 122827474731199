import React from 'react';
import {IconButton, TableCell, TableRow} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {getValueFromRowByColumn} from "../../../../ORhelperFunctions";
import {deleteHarvestingDataThunk, deleteRemovalDataThunk} from "../../../../../../redux/reducers/operationalReportReducer";
import {useDispatch} from "react-redux";
import {hideMapDialog, showMapDialog} from "../../../../../Main/Map/Common/Dialog/DialogReducer";

const TableRowComponent = ({ tableColumns, row, setEditableRowIndex, rowIndex, dataType, setCopyRowIndex = null, iAmEditor}) => {
  const dispatch = useDispatch()

  const deleteDialogButtons = {'Да': {color: 'warning'}, 'Нет': {color: 'success'}}
  const editButtonHandler = () => {
    setEditableRowIndex(() => rowIndex);
  }

  const copyButtonHandler = () => {
    setCopyRowIndex && setCopyRowIndex(rowIndex)
  }

  const deleteRowButtonHandler = () => {
    dispatch(showMapDialog('warning', 'Удаление данных', "Вы уверены что " +
      "хотите удалить строку из таблицы? ", deleteDialogButtons,
      (e) => {
        dispatch(hideMapDialog())
        if (e === 'Да') {
          dataType === 'harvestingData'
            ? dispatch(deleteHarvestingDataThunk(row.id, row.volume))
            : dispatch(deleteRemovalDataThunk(row.id, row.volume))
        }
      }))
  }

  return (
    <TableRow>
      {tableColumns.map((column) => (
        <TableCell key={`${column.name}-${rowIndex}`}>
          {getValueFromRowByColumn(column.name, row[column.name], rowIndex)}
        </TableCell>
      ))}
        <TableCell sx={{ display: 'flex' }}>
          {iAmEditor
            ?
              <>
                <IconButton onClick={copyButtonHandler}>
                  <ContentCopyIcon size={'small'} />
                </IconButton>
                <IconButton onClick={editButtonHandler}>
                  <EditOutlinedIcon size={'small'} />
                </IconButton>
                <IconButton onClick={deleteRowButtonHandler}>
                  <DeleteOutlineOutlinedIcon size={'small'} />
                </IconButton>
              </>
            :
              <>&nbsp;</>
          }
        </TableCell>
    </TableRow>
  )
}

export default TableRowComponent;
