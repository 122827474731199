import {dataAPI} from "../../../../../api/api";
import {refreshProjects} from "./projectsReducer";
import {getDrawLayerGroup, getMap1} from "../../../Map/GlobalObjects";
import {
  createCAForbiddenForUserTypeString,
  createCAForbiddenString,
  createWarehouseForbiddenForUserTypeString,
  createWarehouseForbiddenString,
  creatingWorkspaceIsOK,
  deleteShapeString,
  deleteShapeText,
  deletingError,
  deletingSuccess,
  settingsSavedString,
} from "../../../Map/Common/Strings_RU";
import {setPlotDataAttr} from "../../../../../redux/reducers/plotDataReducer";
import {createRoadLayer, createRoadsWorkspaceIfNotExists} from "../Roads/roads";
import {setSnack} from "../../../Map/Common/Dialog/Snack/snackReducer";
import {hideMapDialog, showMapDialog} from "../../../Map/Common/Dialog/DialogReducer";
import L from "leaflet";
import {markersIconsList} from "../../../Map/Common/svg/Svgs";
import {geoJSONdefaultPrecision} from "../../../Map/Common/Defaults";
import {setFeatureInfoEnabled} from "../../../Map/Common/getFeatureInfo";
import {getPillarWorkspaceNodeByID} from "../Infrastructure/Pillars/pillars";
import {getShapePopup, prepareToOpenPopup} from "../../../Map/MapPopup/popupFunctions";
import {cannotIDo} from "../../../Map/Common/tariffs";
import {getUserSettings} from "../../../Map/Common/userSettings";
import {djangoErrorsParse} from "../../../../../utils/djangoErrorsParse";
import {closeMapPopup, showMapPopup} from "../../../Map/MapPopup/mapPopup";
import {loadRightPanelData} from "../../../RightPanel/right_panel";
import {resetRightPanelData} from "../../../../../redux/reducers/rightPanelReducer";
import {handleErrors} from "../../../../../redux/commonReducerFunctions/ThunkErrorsHandler";
import {addWorkspaceEverywere, renameWorkspaceEverywere} from "../Wokspaces/workspaces";
import {changePillarsDialogAttr} from "../Infrastructure/Pillars/pillarsReducer";
import {dispatch} from "../../../../Common/misc_functions";

/*
Типы нодов:
root - корневая нода (проекты)
workspace - рабочая область
folder - папка
Marker - маркер
Polygon - полигон
Line - полилиния
pillars - столбы
*/

export const projectsTemplate = {
  type: 'root',
  show: true,
  data: [],
  selectedNode: null,
  editingNode: null,
  isChanges: false,
  loading: false,
  isLoading: false,
  error: null,
}
let projects = null;
export const newFeatureTemplate = {
  name: '',
  type: '',
  data: [],
  show: true,
  edit: 0,
  changed: false,
}
export const defaultShapeColor = '#3388FF';
export const defaultShapeWeight = 3;
export const drawStyle = {
  //snappable: true,
  //snapDistance: 10,
  tooltips: false,
  templineStyle: {color: defaultShapeColor},
  hintlineStyle: {color: defaultShapeColor},
  continueDrawing: false,
}
const defaultShapeProperties = {
  color: defaultShapeColor,
  weight: defaultShapeWeight,
  markerColor: defaultShapeColor,
  markerSymbol: 'LOCATION_ICON',
}
const shapeOptions = {
  pane: 'shapes',
}
export const rentBorderLayerOpts = {
  pane: 'perimeters',
  color: '#042e7a',
  weight: 4,
  opacity: .8,
  interactive: false,
  pmIgnore: true,
}

//PROJECTS
export function resetProjects() {
  getMap1().pm.disableDraw()
  const drawLayersGroup = getDrawLayerGroup()
  drawLayersGroup.eachLayer(layer => {
    layer.off('click')
    layer.off('pm:dragstart')
    drawLayersGroup.removeLayer(layer)
  })
  projects = null;
}

export function getProjects() {
  return projects;
}

export function setProjects(prj) {
  resetProjects()
  projects = prj;
}

//WORKSPACES
export function createWorkspaceOnServer(node) { //Создает воркспейс на сервере (из ноды)
  const dispatch = window.elz_dispatch;
  dataAPI.workspaces.create({number: node.name}).then(res => {
    node.id = res.data.id;
    node.new = false;
    node.changed = false;
    addWorkspaceEverywere(res.data)
    dispatch(setSnack('success', creatingWorkspaceIsOK))
    dispatch(refreshProjects())
  }).catch(err => {
    console.error(err)
    node.new = true;
    node.changed = false;
    handleErrors(dispatch, err)
    dispatch(refreshProjects())
  })
}

export function renameWorkspaceOnServer(node) {
  const dispatch = window.elz_dispatch;
  dataAPI.workspaces.patch({number: node.name}, node.id).then((res) => {
    node.changed = false;
    renameWorkspaceEverywere(res.data)
    dispatch(setSnack('success', settingsSavedString))
    dispatch(refreshProjects())
  }).catch((err) => {
    node.changed = true;
    handleErrors(dispatch, err)
    dispatch(refreshProjects())
  })
}

export function addWorkspaceNodeIfNeeded(id, name = '', putToTop = false) { //создает ноду воркспейса или возвращает существующую
  if (projects) {
    const wnode = getWorkspaceNodeByID(id)
    if (wnode)
      return wnode;
    let newF = structuredClone(newFeatureTemplate)
    newF.type = 'workspace';
    newF.id = id;
    newF.parentNode = projects;
    newF.new = false;
    newF.show = false;
    newF.name = name;
    //newF.rentData = rentData;
    newF.perimeter = {}
    if (putToTop)
      projects.data.unshift(newF)
    else
      projects.data.push(newF)
    return newF;
  } else {
    return null;
  }
}

//FOLDERS
export function newFolder(node, value) {
  const projects = getProjects()
  if (value) {
    node.name = value;
    node.edit = 0;
    if (node.parentNode.id) {
      createFolderOnServer(node)
    } else {
      node.new = true;
      projects.isChanges = true;
    }
  } else {
    projects.selectedNode.data.splice(0, 1)
  }
}

export function renameFolderOnServer(node) {
  const dispatch = window.elz_dispatch;
  const json = {name: node.name}
  dataAPI.projects.folders.patch(node.id, json).then(() => {
    node.changed = false;
    dispatch(refreshProjects())
  }).catch(err => {
    node.changed = true;
    console.error(err)
    handleErrors(dispatch, err)
    dispatch(refreshProjects())
  })
}

export function renameWorkspaceByID(id, name) {
  if (projects) {
    const node = getWorkspaceNodeByID(id)
    if (node) {
      node.name = name;
    }
    dispatch(refreshProjects())
  }
}

function createFolderNodeFromFeature(feature, parent, readOnly) {
  const node = structuredClone(newFeatureTemplate)
  node.name = feature.name;
  node.type = 'folder';
  node.new = false;
  node.changed = false;
  node.parentNode = parent;
  node.id = feature.id;
  node.data = []
  node.edit = 0;
  if (readOnly) {
    node.show = false;
    node.visible = false;
  } else {
    node.show = Boolean(feature?.is_opened_web)
    node.visible = Boolean(feature?.is_visible_web)
  }
  feature.children.map(childFeature => {
    const child_node = createFolderNodeFromFeature(childFeature, node)
    if (child_node)
      node.data.push(child_node)
  })
  if (feature['geo_features'])
    addShapesToNode(feature['geo_features'], node)
  return node;
}

export function createFolderOnServer(node) {
  const dispatch = window.elz_dispatch;
  const json = {
    name: node.name,
    rental_contract: (node.parentNode.type === 'workspace') ? getNodeWorkspace(node)['id'] : null,
    parent: (node.parentNode.type === 'workspace') ? null : node.parentNode.id,
  }
  dataAPI.projects.folders.create(json).then(res => {
    node.id = res.data.id;
    node.new = false;
    dispatch(refreshProjects())
  }).catch(err => {
    console.error(err)
    node.new = true;
    projects.isChanges = true;
    handleErrors(dispatch, err)
    dispatch(refreshProjects())
  })
}

//SHAPES
export function svgMarker(color, cursor, name) {
  let iconName = name;
  if (!iconName)
    iconName = 'LOCATION_ICON';
  if (!markersIconsList[iconName])
    iconName = 'LOCATION_ICON';
  let viewBox = markersIconsList[iconName].viewBox;
  if (!viewBox)
    viewBox = "0 96 960 960";
  const svg = `<div style="cursor: ${cursor}; pointer-events: none"><svg class="map_svg_marker" ` +
    `xmlns="http://www.w3.org/2000/svg" height="24" width="24" ` +
    `preserveAspectRatio="none" viewBox="${viewBox}" fill="${color}">` +
    markersIconsList[iconName].content +
    '</svg></div>';
  return L.divIcon({
    html: svg,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    className: 'no-back-no-border',
  })
}

export function createNewMarker(point, color, symbol = 'LOCATION_ICON', cursor = 'pointer') {
  /*  if (!icon)
      icon = 'LOCATION_ICON';*/
  return L.marker(point, {
    icon: svgMarker(color, cursor, symbol),
    bubblingMouseEvents: true,
    ...shapeOptions,
  })
}

export function createNewLine(coords, properties) {
  let color = properties.color;
  if (!color)
    color = defaultShapeColor;
  let weight = properties.weight;
  if (!weight)
    weight = defaultShapeWeight;
  else
    if (typeof weight === 'string' || weight instanceof String) {
      weight = parseFloat(weight)
    }
  return L.polyline(coords, {color: color, weight: weight, ...shapeOptions})
}

export function createNewPolygon(coords, properties) {
  let color = properties.color;
  if (!color)
    color = defaultShapeColor;
  let weight = properties.weight;
  if (!weight)
    weight = defaultShapeWeight;
  else if (typeof weight === 'string' || weight instanceof String)
    weight = parseFloat(weight)
  return L.polygon(coords, {color: color, weight: weight, ...shapeOptions})
}

/**
 * Добавляет рекурсивно все видимые шейпы на карту. Не трогает глаз.
 * @param node - нода
 */
export function showAllVisibleShapesInNode(node) {
  const drawLayersGroup = getDrawLayerGroup()
  if (isShapeNode(node)) {
    if (node.visible)
      drawLayersGroup.addLayer(node.layer, shapeOptions)
  }
  node.data.map(child => {
    showAllVisibleShapesInNode(child)
  })
}

export function cloneShape(shape) {
  switch (shape.node.type) {
    case 'Polygon':
      return createNewPolygon(shape.getLatLngs(), shape.options)
    case 'Line':
      return createNewLine(shape.getLatLngs(), shape.options)
    case 'Marker':
      return createNewMarker(shape.getLatLng(), shape.elz_properties.markerColor, shape.elz_properties.markerSymbol)
  }
  return null;
}

export function createFeatureOnServer(node) {
  const dispatch = window.elz_dispatch;
  const parent = node.parentNode;
  const json = getJsonFeature(node)
  if (parent.type === 'workspace')
    json.rental_contract = parent.id;
  else
    json.folder = parent.id;
  node.saving = true;
  dataAPI.projects.shapes.createFeatureInCollection(json).then(res => {
    node.new = false;
    node.layer.elz_properties = res.data.properties;
    node.id = res.data.properties.id;
    if (node.saving) {
      delete node.saving;
    }
    loadRightPanelData('Shape', node, node.id)
    dispatch(refreshProjects())
  }).catch(err => {
    node.layer.elz_properties = structuredClone(defaultShapeProperties);
    node.new = true;
    handleErrors(dispatch, err)
    dispatch(refreshProjects())
  })
  setTimeout(() => {
    if (!node.id) {
      dispatch(refreshProjects())
    }
  }, 300)
}

export function patchFeatureOnServer(node) {
  const dispatch = window.elz_dispatch;
  const json = getJsonFeature(node)
  node.saving = true;
  dataAPI.projects.shapes.patch(node.id, json).then(() => {
    node.changed = false;
    delete node.saving;
    //правая панель
    const rpState = window.store.getState()["rightPanelReducer"]
    const sl = rpState["selectedElement"];
    if (node.id === sl?.id) {
      loadRightPanelData('Shape', node, node.id)
    }
    dispatch(refreshProjects())
  }).catch(err => {
    node.changed = true;
    handleErrors(dispatch, err)
    dispatch(refreshProjects())
  })
}

export function recreateMarker(node, color, symbol, cursor = 'pointer') {
  const drawLayersGroup = getDrawLayerGroup()
  const lay = node.layer;
  lay.off('click')
  lay.off('pm:dragstart')
  const marker = createNewMarker(lay.getLatLng(), color, symbol, cursor)
  marker.elz_properties = node.layer.elz_properties; //old marker properties
  marker.elz_properties.markerColor = color;
  marker.elz_properties.markerSymbol = symbol;
  drawLayersGroup.removeLayer(node.layer)
  marker.node = node;
  node.layer = marker;
  drawLayersGroup.addLayer(marker, shapeOptions)
  marker.on('click', shapeClickHandler)
  marker.on('pm:dragstart', () => {
    marker.elz_dragging = true
  })
  return marker;
}

/**
 * Обработчик нажатия на фигуру на карте.
 * @param e - leflet event
 */
export const shapeClickHandler = (e) => {
  if (!prepareToOpenPopup(e))
    return;
  const dispatch = window.elz_dispatch;
  const lay = e.target;
  const ctrlKey = e.originalEvent.ctrlKey;
  if (!ctrlKey) { //popup
    if (lay.elz_dragging) {
      delete lay.elz_dragging
    } else {
      setFeatureInfoEnabled(false)
      const drawLayersGroup = getDrawLayerGroup()
      const node = lay.node;
      const interactive = {}

      if (node.type !== 'Marker') {
        interactive.uf_edit_button = {
          type: 'click',
          f: () => {
            const dispatch = window.elz_dispatch;
            lay.pm.disableRotate()
            lay.pm.disableLayerDrag()
            if (lay.pm.enabled()) {
              lay.pm.disable()
              lay.elz_emode = null;
              if (node.parentNode.id && !node.new)
                patchFeatureOnServer(node)
            } else {
              lay.pm.enable()
              lay.elz_emode = 'edit';
            }
            closeMapPopup()
            dispatch(refreshProjects())
          }
        }
        interactive.uf_rotate_button = {
          type: 'click',
          f: () => {
            const dispatch = window.elz_dispatch;
            lay.pm.disable()
            lay.pm.disableLayerDrag()
            if (lay.pm.rotateEnabled()) {
              lay.pm.disableRotate()
              lay.elz_emode = null;
              if (node.parentNode.id && !node.new)
                patchFeatureOnServer(node)
            } else {
              lay.pm.enableRotate()
              lay.elz_emode = 'rotate'
            }
            closeMapPopup()
            dispatch(refreshProjects())
          }
        }
      }
      interactive.uf_move_button = {
        type: 'click',
        f: () => {
          const dispatch = window.elz_dispatch;
          lay.pm.disable()
          lay.pm.disableRotate()
          if (lay.pm.layerDragEnabled()) {
            lay.pm.disableLayerDrag()
            lay.elz_emode = null;
            if (node.type === 'Marker')
              recreateMarker(node, node.layer.elz_properties.markerColor, node.layer.elz_properties['markerSymbol'], 'pointer')
            if (node.parentNode.id && !node.new) {
              patchFeatureOnServer(node)
            }
          } else {
            if (node.type === 'Marker') {
              const marker = recreateMarker(node, node.layer.elz_properties.markerColor, node.layer.elz_properties['markerSymbol'], 'move')
              marker.pm.enableLayerDrag()
              marker.elz_emode = 'move';
            } else {
              lay.pm.enableLayerDrag()
              lay.elz_emode = 'move';
            }
          }
          closeMapPopup()
          dispatch(refreshProjects())
        }
      }
      interactive.uf_erase_button = {
        type: 'click',
        f: () => {
          closeMapPopup()
          const buttons = {'Да': {color: 'warning'}, 'Нет': {color: 'primary'}}
          const dispatch = window.elz_dispatch;
          dispatch(showMapDialog('warning', deleteShapeString, deleteShapeText.replace('__SHAPENAME__', node.name), buttons, (result) => {
            dispatch(hideMapDialog())
            if (result === 'Да') {
              deleteNode(node)
              dispatch(refreshProjects())
            }
          }))
        }
      }
      if (node.type === 'Line') {
        interactive.uf_create_road_button = {
          type: 'click',
          f: () => {
            closeMapPopup()
            const workspaceNode = getNodeWorkspace(node)
            const roadWorkspace = createRoadsWorkspaceIfNotExists(workspaceNode.id, workspaceNode.name, workspaceNode.isMobile, true)
            hideShapeByNode(node)
            /*if (drawLayersGroup.hasLayer(node.layer)) {//скрыть шейп
              node.visible = false;
              drawLayersGroup.removeLayer(node.layer)
            }*/
            createRoadLayer(node.layer, roadWorkspace)
            dispatch(refreshProjects())
          }
        }
      }
      else
      if (node.type === 'Polygon') {
        interactive.uf_create_cutting_area_button = {
          type: 'click',
          f: () => {
            const dispatch = window.elz_dispatch;
            switch (cannotIDo.createCA()) {
              case 0:
                closeMapPopup()
                dispatch(setPlotDataAttr({showCreatePlotModal: true, selectedProjectTreeNode: node}))
                break;
              case 1:
                dispatch(setSnack('warning', createCAForbiddenString))
                break;
              case 2:
                dispatch(setSnack('warning', createCAForbiddenForUserTypeString))
                break;
            }
          }
        }
        interactive.uf_create_warehouse_button = {
          type: 'click',
          f: () => {
            const dispatch = window.elz_dispatch;
            switch (cannotIDo.createCA()) {
              case 0:
                closeMapPopup()
                dispatch(setPlotDataAttr({showCreateWarehouseModal: true, selectedProjectTreeNode: node}))
                break;
              case 1:
                dispatch(setSnack('warning', createWarehouseForbiddenString))
                break;
              case 2:
                dispatch(setSnack('warning', createWarehouseForbiddenForUserTypeString))
                break;
            }
          }
        }
      }
      else if (node.type === 'Marker') {
        const workspace = getPillarWorkspaceNodeByID(getNodeWorkspace(node).id)
        interactive.uf_create_pillar_button = {
          type: 'click',
          f: () => {
            closeMapPopup()
            dispatch(changePillarsDialogAttr({
              name: node.name,
              coordinates: node.layer.getLatLng(),
              quarters: [],
              workspace: workspace,
              shape: node.layer,
              editMode: false,
            }))
          }
        }
      }
      interactive.uf_eye_button = {
        type: 'click',
        f: () => {
          node.visible = false;
          drawLayersGroup.removeLayer(node.layer)
          closeMapPopup()
          setTreeNodeVisibleStatus(node, false).then(() => {
              if (!node.new)
                setTreeNodeVisibleStatusOnServer(node, false).then().catch(err => {
                  console.error(err)
                })
            }
          )
          const dispatch = window.elz_dispatch;
          dispatch(refreshProjects())
        }
      }
      interactive.popup_feas_color_chooser = {
        type: 'change',
        f: (e) => {
          const color = e.target.value;
          if (node.type !== 'Marker') {
            if (!node.layer["elz_selected"])
              node.layer.setStyle({color: color, fillColor: color})
            else
              node.layer.elz_old_style = {color: color, fillColor: color}
            node.layer.elz_properties.color = color;
            node.layer.elz_properties.fillColor = color;
          } else {
            recreateMarker(node, color, node.layer.elz_properties['markerSymbol'])
          }
          if (node.parentNode.id && !node.new) {
            patchFeatureOnServer(node)
          }
        }
      }
      if (node.type !== 'Marker')
        interactive.popup_feas_weight_chooser = {
          type: 'change',
          f: (e => {
            const dispatch = window.elz_dispatch;
            const we = e.target.value;
            node.layer.setStyle({weight: we})
            if (node.parentNode.id && !node.new) {
              patchFeatureOnServer(node)
              dispatch(refreshProjects())
            }
          })
        }
      else
        interactive.popup_feas_icon_chooser = {
          type: 'click',
          f: (e => {
            const symbol = e.target.value;
            recreateMarker(node, node.layer.elz_properties.markerColor, symbol)
            if (node.parentNode.id && !node.new) {
              patchFeatureOnServer(node)
              dispatch(refreshProjects())
            }
          })
        }
      /*interactive.uf_coordinates_button = {
        type: 'click',
        f: (() => {
          closeMapPopup()
          let latlngs;
          if (node.type === 'Polygon')
            latlngs = node.layer.getLatLngs()[0]
          else
            if (node.type === 'Line')
              latlngs = node.layer.getLatLngs()
            else
              latlngs = [node.layer.getLatLng()]
          dispatch(showCoordinatesEditor(
            latlngs,
            node.type,
            (latlngs) => {
            const json = {
              geometry: {
                type: node.layer.toGeoJSON(geoJSONdefaultPrecision).geometry.type,
                coordinates: [[]],
              },
            }
            latlngs.map((coo) => {
              json.geometry.coordinates[0].push([coo.lng, coo.lat]);
            })
            if (node.type === 'Polygon')
              json.geometry.coordinates[0].push([latlngs[0].lng, latlngs[0].lat]);
            else
              if (node.type === 'Marker')
                json.geometry.coordinates = json.geometry.coordinates[0][0]
              else
                json.geometry.coordinates = json.geometry.coordinates[0]
            dataAPI.projects.shapes.patch(node.id, json).then(() => {
              if (node.type === 'Marker') {
                node.layer.setLatLng(latlngs[0])
              }
              else
                node.layer.setLatLngs(latlngs)
              clearCoordinatesDialogInfo()
              dispatch(setSnack('success', coordinatesChangeSuccess))
              flyToShape(node.layer, node.type, false)
            }).catch(err => {
              handleErrors(dispatch, err)
            })
          },
            node.name,
          ))
        })
      }*/
      if (lay.elz_properties?.id) {
        loadRightPanelData(node.type, node, node.id, false)
      }
      const html = getShapePopup(e.target, interactive)
      showMapPopup(e.latlng, html, interactive)
    }
  }
}

function createNodeFromFeature(feature, parentNode, readOnly = false) {
  const node = structuredClone(newFeatureTemplate)
  if (!feature.geometry) {
    feature.geometry = feature['geom'];
  }
  let layer = null;
  let coords = null;
  switch (feature.geometry.type) {
    case 'MultiLineString':
      node.type = 'Line';
      coords = feature.geometry.coordinates[0].map(point => [point[1], point[0]])
      layer = createNewLine(coords, feature.properties)
      break;
    case 'LineString':
      node.type = 'Line';
      coords = feature.geometry.coordinates.map(point => [point[1], point[0]])
      layer = createNewLine(coords, feature.properties)
      break;
    case 'Polygon':
      node.type = 'Polygon';
      coords = feature.geometry.coordinates[0].map(point => [point[1], point[0]])
      coords.pop()
      layer = createNewPolygon(coords, feature.properties)
      break;
    case 'Point':
      node.type = 'Marker';
      coords = feature.geometry.coordinates;
      let color = feature.properties.markerColor;
      if (!color)
        color = defaultShapeColor;
      let symbol = feature.properties.markerSymbol;
      if (!symbol)
        symbol = 'LOCATION_ICON';
      layer = createNewMarker([coords[1], coords[0]], color, symbol)
      layer.elz_properties = feature.properties;
      layer.elz_properties.markerColor = color;
      layer.elz_properties.markerSymbol = symbol;
      break;
  }
  if (layer) {
    layer.node = node;
    layer.elz_properties = feature.properties;
    layer.on('click', shapeClickHandler)
    layer.on('pm:dragstart', () => {
      layer.elz_dragging = true;
    })
  }
  let id = feature.properties.id;
  if (typeof id === 'string')
    id = parseInt(id)
  if (id)
    node.id = feature.properties.id;
  node.layer = layer;
  node.parentNode = parentNode;
  node.properties = feature.properties;
  node.name = feature.properties.name;
  node.onMap = false;
  node.new = false;
  node.changed = false;
  if (readOnly)
    node.visible = false;
  else {
    const visible = feature.properties.is_visible_web; //тут может прилететь строка! хз откуда и почему
    if (typeof visible === 'string' || visible instanceof String) {
      node.visible = visible.toUpperCase() === 'TRUE';
    } else
      node.visible = feature.properties.is_visible_web;
  }
  return node;
}

export function showAllNodes(node) { //Раскрыть все ноды от текущей вверх
  if (node.type === 'root')
    return
  if (node.type === 'folder') {
    node.show = true;
    showAllNodes(node.parentNode)
  }
}

export function deleteTreeNode(node) { //Удаляет ноду из дерева рекурсивно, включая слои на карте
  const drawLayersGroup = getDrawLayerGroup()
  node.data.map(child => {
    if (isShapeNode(child)) {
      child.layer.off('click')
      child.layer.off('pm:dragstart')
      drawLayersGroup.removeLayer(child.layer)
    } else
      deleteTreeNode(child)
  })
  if (isShapeNode(node)) {
    if (node.layer["elz_selected"]) {

    }
    node.layer.off('click')
    node.layer.off('pm:dragstart')
    drawLayersGroup.removeLayer(node.layer)
  }
  const ind = getNodeIndexInParentData(node)
  if (ind !== -1)
    node.parentNode.data.splice(ind, 1)
}

export function getNodeIndexInParentData(node) {
  for (let i = 0; i < node.parentNode.data.length; i++) {
    if (node.parentNode.data[i] === node)
      return i;
  }
  return -1;
}

export function deleteNode(node) { //Удаляет ноду в дереве и на сервере
  const dispatch = window.elz_dispatch;
  if (projects.selectedNode === node)
    projects.selectedNode = null;
  if (node.type === 'folder') {
    if (!node.new) { //если это загруженный каталог, работаем в асинхроне
      dataAPI.projects.folders.delete(node.id).then(() => {
        deleteTreeNode(node)
        dispatch(refreshProjects())
        dispatch(setSnack('info', deletingSuccess))
      }).catch(err => {
        handleErrors(dispatch, err)
        dispatch(refreshProjects())
      })
    } else {//это новая коллекция, просто удаляем
      deleteTreeNode(node)
    }
  } else { //это шейп
    if (isShapeNode(node)) {
      if (!node.new) {//если это загруженнй шейп, работаем в асинхроне
        dataAPI.projects.shapes.delete(node.id).then(() => {
          deleteTreeNode(node)
          dispatch(refreshProjects())
          dispatch(setSnack('info', deletingSuccess))
        }).catch(err => {
          console.error(err.message)
          dispatch(setSnack('error', err.response?.data ? djangoErrorsParse(err.response.data) : deletingError))
          dispatch(refreshProjects())
        })
      } else {//это новый шейп, просто удаляем
        deleteTreeNode(node)

        dispatch(refreshProjects())
      }
    }
  }
  const element = window.store.getState()["rightPanelReducer"]["selectedElement"]
  if (element && element.id === node.id)
    dispatch(resetRightPanelData())
  dispatch(refreshProjects())
}

export function createShapeFromGPX(res, collection, gpxname) {
  const dispatch = window.elz_dispatch;
  const json = [];
  if (res.geometry.type === 'MultiPoint') {
    const drawLayersGroup = getDrawLayerGroup()
    let number = 1;
    res.geometry.coordinates.map(point => {
      const fea = {
        geometry: {coordinates: point, type: 'Point',},
        properties: {name: `Точка №${number++}`, color: '#FF0000'},
      }
      fea.type = 'Feature';
      if (collection.type === 'folder')
        fea.folder = collection.id;
      else
        fea.rental_contract = getNodeWorkspace(collection).id;
      fea.is_visible_web = true;
      json.push(fea)
    })
    if (json.length) {
      dataAPI.projects.shapes.createMultiShapesOnServer(json).then(res => {
        res.data.map(fea => {
          const node = createNodeFromFeature(fea, collection)
          node.properties = structuredClone(res.properties);
          collection.data.push(node)
          drawLayersGroup.addLayer(node.layer, shapeOptions)
        })
        dispatch(refreshProjects())
      }).catch(err => {
        handleErrors(dispatch, err)
      })
    }
  } else {
    res.properties = {}
    res.properties.color = defaultShapeColor;
    res.properties.weight = defaultShapeWeight;
    res.properties.name = `Файл ${gpxname}`;
    const node = createNodeFromFeature(res, collection)
    if (node) {
      node.name = res.properties.name;
      node.visible = true;
      collection.data.push(node)
      node.show = true;
      if (node.layer)
        getDrawLayerGroup().addLayer(node.layer, shapeOptions)
      projects.selectedNode = node;
      if (!collection.new)
        createFeatureOnServer(node)
    }
  }
  dispatch(refreshProjects())
}

export function getJsonFeature(node) {
  const jFea = node.layer.toGeoJSON(geoJSONdefaultPrecision)
  if (node.type === 'Marker') {
    jFea.properties.markerColor = node.layer.elz_properties.markerColor;
    jFea.properties.markerSymbol = node.layer.elz_properties.markerSymbol;
  } else {
    if (!node.layer["elz_selected"]) {
      jFea.properties.color = node.layer.options.color;
      jFea.properties.fillColor = node.layer.options.fillColor;
    } else {
      jFea.properties.color = node.layer.elz_old_style.color;
      jFea.properties.fillColor = node.layer.elz_old_style.fillColor;
    }
  }
  jFea.properties.name = node.name;
  jFea.is_visible_web = node.visible;
  if (node.type !== 'Marker' && node.layer.options.weight)
    jFea.properties.weight = node.layer.options.weight.toString()
  return jFea;
}

function addShapesToNode(features, node, readOnly) {
  const drawLayersGroup = getDrawLayerGroup()
  const visi = window.store.getState()["leftDrawerReducer"].curTab === 'projects';
  features.map(fea => {
    const shapeNode = createNodeFromFeature(fea, node, readOnly)
    if (shapeNode) {
      node.data.push(shapeNode)
      if (shapeNode.visible && visi)
        drawLayersGroup.addLayer(shapeNode.layer, shapeOptions)
    }
  })
}

export async function setTreeNodeVisibleStatus(node, visible) {
  if (isContainerNode(node)) {
    node.data.map(child => setTreeNodeVisibleStatus(child, visible))
  } else {
    const drawLayersGroup = getDrawLayerGroup()
    if (visible) {
      if (!drawLayersGroup.hasLayer(node.layer))
        drawLayersGroup.addLayer(node.layer, shapeOptions)
    } else {
      drawLayersGroup.removeLayer(node.layer)
    }
  }
  node.visible = visible;
}

export async function setTreeNodeVisibleStatusOnServer(node, visible) {
  const json = {is_visible_web: visible}
  if (node.type === 'folder' && !cannotIDo.editorAction()) {
    return dataAPI.projects.folders.patch(node.id, json)
  } else if (isShapeNode(node) && !cannotIDo.editorAction()) {
    return dataAPI.projects.shapes.patch(node.id, json)
  } else if (node.type === 'workspace' && !cannotIDo.adminAction()) {
    return dataAPI.workspaces.patch(json, node.id)
  }
}

/**
 * Удаляет все шейпы с карты. Не трогает ноды и глаза.
 */
export function removeAllProjectDrawLayers() {
  const drawLayerGroup = getDrawLayerGroup()
  drawLayerGroup.eachLayer(layer => {
    if (isShapeNode(layer.node)) {
      drawLayerGroup.removeLayer(layer)
    }
  })
}

/**
 * Показывает/скрывает все шепы на крате
 * @param show {boolean} - true - показать, иначе - скрыть
 */
export function showHideAllShapes(show) {
  if (show) {
    projects.data.map(workspace => {
      showAllVisibleShapesInNode(workspace)
    })
  } else {
    removeAllProjectDrawLayers()
  }
}

export function initProjects() {
  const dispatch = window.elz_dispatch;
  const readOnly = !!cannotIDo.editorAction()
  projects = structuredClone(projectsTemplate)
  projects.loading = true;
  setProjects(projects)
  dataAPI.projects.getAll().then(res => {
    if (getProjects()) { //здесь getProjects(), а не projects
      res.data.map(workspace => {
        //workspaces
        const newF = addWorkspaceNodeIfNeeded(workspace.id, workspace.number, workspace['is_mobile'])
        newF.isMobile = workspace['is_mobile'];
        newF.perimeter = createRentPerimeterLayerNode(workspace["perimeter_multipolygon"])
        if (workspace["perimeter_multipolygon"]) { //Rent borders
          if (workspace.perimeter_rental_contract_visible_web)
            createRentPerimetersLayers(newF.perimeter, true)
          if (getUserSettings().blind.enabled && workspace.perimeter_rental_contract_blind_visible_web)
            createRentPerimetersLayers(newF.perimeter, false)
        }
        if (readOnly) {
          newF.visible = false;
          newF.show = false;
        } else {
          newF.visible = Boolean(workspace.is_visible_web)
          newF.show = Boolean(workspace.is_opened_web)
        }
        //user features
        if (workspace["geo_collections_base"]) {
          workspace["geo_collections_base"].children.map(data => { //folders
            const node = createFolderNodeFromFeature(data, newF, readOnly)
            if (node)
              newF.data.push(node)
          })
          if (workspace["geo_collections_base"]['geo_features']) {
            addShapesToNode(workspace["geo_collections_base"]["geo_features"], newF, readOnly)
          }
        }
      })
      projects.loading = false;
      projects.isLoading = true;
      dispatch(refreshProjects())
    }
  }).catch(err => {
    console.error(err)
    projects.loading = false;
    projects.isLoading = false;
    projects.error = err.message;
    dispatch(refreshProjects())
  })
  dispatch(refreshProjects())
}

//RENT BORDERS
export function removeAllRentBordersLayersAndCrearData() { //Удаляет все слои (для выхода)
  const map = getMap1()
  projects.data.map(workspace => {
    const perimeter = workspace.perimeter;
    if (perimeter.layers)
      map.removeLayer(perimeter.layers)
    if (perimeter.blindlayers)
      map.removeLayer(perimeter.blindlayers)
    perimeter.layers = null;
    perimeter.blindlayers = null;
    if (perimeter.blindLayersShow)
      delete perimeter.blindLayersShow;
    perimeter.polygons = null;
  })
}

export function removeAllRentBlindLayers() { //удаляет все слои шторки (для закрытия шторки)
  const map = getMap1()
  projects.data.map(workspace => {
    const perimeter = workspace.perimeter;
    perimeter.blindLayersShow = perimeter.blindlayers !== null;
    if (perimeter.blindlayers) {
      map.removeLayer(perimeter.blindlayers)
      perimeter.blindlayers = null;
    }
  })
}

export function addAllPerimetersBlindLayers() { //добавляет слои в шторку (для открытия шторки)
  projects.data.map(workspace => {
    const perimeter = workspace.perimeter;
    if (perimeter.blindLayersShow) {
      createRentPerimetersLayers(workspace.perimeter, false)
    }
  })
}

export function createRentPerimetersLayers(node, left) { //добавляет слой границ на карту, left - левая/нелевая панель
  const map = getMap1()
  if (left) {
    if (node.layers)
      map.removeLayer(node.layers)
    node.layers = L.layerGroup()
  } else {
    if (node.blindlayers)
      map.removeLayer(node.blindlayers)
    node.blindlayers = L.layerGroup()
  }
  if (node.polygons)
    node.polygons.coordinates.map(polygon => {
      const coords = polygon.map(coo => coo.map(c => [c[1], c[0]]))
      if (left)
        node.layers.addLayer(L.polyline(coords, rentBorderLayerOpts))
      else
        node.blindlayers.addLayer(L.polyline(coords, {...rentBorderLayerOpts, pane: 'blind_perimeters'}))
    })
  if (left)
    map.addLayer(node.layers)
  else
    map.addLayer(node.blindlayers)
}

export function createRentPerimeterLayerNode(multipolygon) { //создает ноду workspace.perimeter
  return {layers: null, blindlayers: null, polygons: multipolygon ? {...multipolygon} : null}
}

export function refreshRentPerimeters(node, multipolygon, show) { //создает/обновляет слои по данным полигона (для создания/изменения периметров)
  deleteRentPrimeters(node, false, true)
  deleteRentPrimeters(node, true, false)
  node.perimeter = createRentPerimeterLayerNode(multipolygon)
  if (show) {
    createRentPerimetersLayers(node.perimeter, true)
    if (window.store.getState()["blindReducer"].enabled)
      createRentPerimetersLayers(node.perimeter, false)
  }
}

export function deleteRentPrimeters(workspace, deletePolygon = false, left) { //удаляет слой (для удаления периметра)
  const perimeter = workspace?.perimeter;
  if (perimeter) {
    if (left) {
      if (perimeter.layers) {
        getMap1().removeLayer(perimeter.layers)
        perimeter.layers = null;
      }
    } else {
      if (perimeter.blindlayers) {
        getMap1().removeLayer(perimeter.blindlayers)
        perimeter.blindlayers = null;
      }
    }
    if (deletePolygon) {
      perimeter.layers = null;
      perimeter.blindlayers = null;
      workspace.perimeter.polygons = null;
    }
  }
}

/**
 * Скрывает шейп по node (закрывает глаз в списке и удаляет слой с карты)
 * @param node - нода шейпа
 */
export function hideShapeByNode(node) {
  const drawLayersGroup = getDrawLayerGroup()
  if (drawLayersGroup.hasLayer(node.layer)) {
    node.visible = false;
    drawLayersGroup.removeLayer(node.layer)
    if (node.id) {
      dataAPI.projects.shapes
        .patch(node.id, {is_visible_web: false})
        .then(() => {
        })
        .catch((err) => console.error(err));
    }
  }
}

//TREE FUNCTION
export function isShapeNode(node) {
  if (!node)
    return false;
  return ['Polygon', 'Line', 'Marker'].indexOf(node.type) !== -1;
}

export function isContainerNode(node) {
  return node.type === 'workspace' || node.type === 'folder';
}

export function getNodeWorkspace(node) {
  if (node.type === 'workspace')
    return node;
  else if (node.parentNode)
    return getNodeWorkspace(node.parentNode)
  else
    return null;
}

export function getAllNodesListFromContainer(node, arra) {
  node.data.map(child => {
    if (isContainerNode(child))
      getAllNodesListFromContainer(child, arra)
    else
      arra.push(child)
  })
}

//Возвращает ноду воркспейса по ID или null
export function getWorkspaceNodeByID(id) {
  let res = null;
  if (projects) {
    projects.data.forEach(node => {
      if (node.id === id)
        res = node;
    })
  }
  return res;
}
