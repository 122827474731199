import Box from "@mui/material/Box";
import {Collapse} from "@mui/material";
import EmployeesTable from "./EmployeesTable/EmployeesTable";
import {blockContainerStyle} from "../organizationPageStyles";
import {BottomBlockTitle} from "../BottomBlock/BottomBlockTitle";

const Employees = ({openedBlock, setOpenedBlock}) => {
  return (
    <Box sx={{...blockContainerStyle}} id={'employees'}>
      <BottomBlockTitle openedBlock={openedBlock} setOpenedBlock={setOpenedBlock} blockName={'Employees'}
                        title={'СПИСОК СОТРУДНИКОВ'}/>
      <Collapse in={openedBlock === 'Employees'} timeout="auto" unmountOnExit>
        <EmployeesTable/>
      </Collapse>
    </Box>
  )
}

export default Employees;
