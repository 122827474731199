import React from 'react';
import Stack from "@mui/material/Stack";
import ModesButtons from "./ModesButtons/ModesButtons";
import ZoomSlider from "./ZoomSlider/ZoomSlider";
import {planetablesToolbarContainer} from "../PlanetabesPageSyles";
import {useTheme} from "@mui/material";

const PlanetableToolbar = ({iAmEditor}) => {
  const theme = useTheme()

  return (
    <Stack direction={'row'} spacing={3} id={'toolbar'}
           sx={{...planetablesToolbarContainer, borderBottom: `1px solid ${theme.palette.primary.main}`}}>
      <ModesButtons iAmEditor={iAmEditor}/>
      <ZoomSlider/>
    </Stack>
  )
}

export default PlanetableToolbar;
