import Button from "@mui/material/Button";
import {useRef} from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const UploadGpxForm = ({gpxFile, setGpxFile}) => {

  const hiddenFileInput = useRef()

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleInputChange = event => {
    const fileUploaded = event.target.files[0];
    setGpxFile(fileUploaded)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          autoFocus
          label={'Имя файла'}
          value={gpxFile?.name || ''}
          disabled={true}
          variant={'outlined'}
          size={'small'}
        />
      </Grid>
      <Grid item xs={6}>
        <Button sx={{height: 40}} variant="outlined" fullWidth onClick={handleClick}>
          {'Загрузить GPX'}
        </Button>
        <input hidden accept="*/*" type="file"
               ref={hiddenFileInput}
               onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  )
}

export default UploadGpxForm;
