import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {createStockpileThunk, setStockpilesAttr} from "../../../../redux/reducers/stockpilesReducer";
import {useDispatch} from "react-redux";
import {IconButton, Stack} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const NewStockpileBlock = ({plot}) => {
  const dispatch = useDispatch()
  const [stockpileName, setStockpileName] = useState('')

  const saveStockpileHandler = (e) => {
    e.stopPropagation()
    dispatch(createStockpileThunk({name: stockpileName, xplot: plot.id}, plot))
  }

  const onChangeInputHandler = (e) => {
    setStockpileName(e.target.value)
  }
  const undoButtonHandler = (e) => {
    e.stopPropagation()
    dispatch(setStockpilesAttr({isCreateStockpile: {status: false, plotId: null}}))
  }
  return (
    <Stack direction={'row'} sx={{width: '100%'}}>
      <TextField
        sx={{pr: 5}}
        fullWidth
        autoFocus
        value={stockpileName}
        inputProps={{ maxLength: 30,}}
        variant={'standard'}
        size={'small'}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onChangeInputHandler(e)}
        onKeyDown={(e) => {
          (e.code === 'Enter' || e.code === 'NumpadEnter') && saveStockpileHandler(e)
          e.code === 'Escape' && undoButtonHandler(e)
        }}
      />
      <IconButton
        sx={{p:0}}
        color={'primary'}
        title={'Создать штабель'}
        onClick={saveStockpileHandler}
      >
        <DoneIcon sx={{color: 'primary.main'}} title={'Сохранить'}/>
      </IconButton>
      <IconButton
        sx={{p:0}}
        title={'Отменить'}
        color={'primary'}
        onClick={(e) => undoButtonHandler(e)}
      >
        <CloseIcon sx={{color: '#A44646'}}/>
      </IconButton>
    </Stack>
  );
};

export default NewStockpileBlock;