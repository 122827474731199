import React, {useState} from 'react';
import {Collapse, List, ListItem, Typography, useTheme} from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {treeItemButtonIconSize, treeItemButtonStyle} from "../../../../Main/Map/Common/Styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {isOdd} from "../../../../Main/Map/Common/MiscFunction";
import PlotsStockpilesTree, {getFieldName} from "../PlotsStockpilesTree";
import PlotsList from "./PlotsList/PlotsList";
import {leftBarTextStyle, plotsTreePaddingLeft, treeListItemStyle} from "../../leftBarStyles";

const PlotsStockpilesTreeItem = ({uniqueValues, plotsList, field, restFields, step, iAmEditor}) => {
  const theme = useTheme()
  const [openedItems, setOpenedItems] = useState({});
  const listItemHandleClick = (e, value) => {
    e.stopPropagation()
    value in openedItems
      ? setOpenedItems(prev => ({...prev, [value]: {status: !prev[value].status}}))
      : setOpenedItems(prev => ({...prev, [value]: {status: true}}))
  }

  // Если это последнее поле, то выводим последний фильтр и списки имен делян
  if (restFields.length === 0) {
    return (
      <List disablePadding sx={{width: '100%'}}>
        {uniqueValues.map((value, i) => {
          const groupingPlots = plotsList.filter((plot) => getFieldName(plot, field) === value);
          if (groupingPlots.length === 0) {
            return null;
          }
          return (
            <ListItem key={i}
                      style={{...treeListItemStyle, paddingLeft: step === 1 ? 0 : plotsTreePaddingLeft}}
                      onClick={(e) => listItemHandleClick(e, value)}>
              <Stack direction={'row'} alignItems={'center'}>
                <IconButton
                  disableRipple
                  style={treeItemButtonStyle}
                >
                  {openedItems[value]?.status
                    ?
                      <ArrowDropDownIcon style={treeItemButtonIconSize}/>
                    :
                      <ArrowRightIcon style={treeItemButtonIconSize}/>
                  }
                </IconButton>
                <Typography
                  style={{...leftBarTextStyle, color: !isOdd(step) ? theme.palette.primary.main : '#545454'}}>
                  {value || 'Значение не задано'}
                </Typography>
              </Stack>
              <Collapse in={openedItems[value]?.status} timeout="auto" unmountOnExit sx={{width: '100%'}}>
                <PlotsList plotsList={groupingPlots} iAmEditor={iAmEditor}/>
              </Collapse>
            </ListItem>
          )
        })}
      </List>
    )
  }

  // Если это не последнее поле, то рекурсивно создаем компоненты для каждого уникального значения поля
  return (
    <List disablePadding style={{width: '100%'}}>
      {uniqueValues.map((value, i) => {
        const groupingPlots = plotsList.filter((item) => getFieldName(item, field) === value);
        if (groupingPlots.length === 0) {
          return null;
        }

        return (
          <ListItem key={i}
                    style={{...treeListItemStyle, paddingLeft: step === 1 ? 0 : plotsTreePaddingLeft}}
                    onClick={(e) => listItemHandleClick(e, value)}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <IconButton
                disableRipple
                style={treeItemButtonStyle}
              >
                {openedItems[value]?.status ? <ArrowDropDownIcon style={treeItemButtonIconSize}/> :
                  <ArrowRightIcon style={treeItemButtonIconSize}/>}
              </IconButton>
              <Typography
                style={{...leftBarTextStyle, color: !isOdd(step) ? theme.palette.primary.main : '#545454'}}>
                {value || 'Значение не задано'}
              </Typography>
            </Stack>
            <Collapse in={openedItems[value]?.status} timeout="auto" unmountOnExit sx={{width: '100%'}}>
              <PlotsStockpilesTree itemsList={groupingPlots} fields={restFields}/>
            </Collapse>
          </ListItem>
        );
      })}
    </List>
  );
};

export default PlotsStockpilesTreeItem;