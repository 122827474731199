import {IconButton, Stack, TableCell, TableRow, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import UsersInWorkspacesEditable from "./UsersInWorkspacesEditable";
import ButtonsBlock from "./ButtonsBlock";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {changeOrgPageAttrs} from "../../../organizationPageReducer";
import {FTextField} from "../../../../Main/LeftPanel/Context/Projects/FTextFiled";
import {renameWorkspace} from "../../../org_page_api";
import {setSnack} from "../../../../Main/Map/Common/Dialog/Snack/snackReducer";

const ColumnComponent = ({columnName, workspace, editHandler, index, setOpen, iAmOwner}) => {
  switch (columnName) {
    case 'order':
      return index + 1
    case 'number':
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <Typography>{workspace[columnName]}</Typography>
          <IconButton title={'Изменить наименование'} onClick={editHandler}>
            <EditOutlinedIcon sx={{fontSize: 18}}/>
          </IconButton>
        </Stack>
      )
    case 'created_at':
      return workspace[columnName]
    case 'users':
      return <UsersInWorkspacesEditable workspace={workspace} iAmOwner={iAmOwner}/>
    case 'buttons':
      return <ButtonsBlock workspace={workspace} setOpen={setOpen}/>
  }
}

const WorkspaceRow = ({workspace, index, WorkSpacesTableColumns, iAmOwner}) => {
  const dispatch = useDispatch()
  const selectedWorkspace = useSelector(state => state.organizationPageReducer.selectedWorkspace)
  const isSelectedWorkspace = selectedWorkspace?.id === workspace?.id;

  function updateWorkspaceHandler(name) {
    if (name !== workspace.number) {
      workspace.number = name;
      renameWorkspace(workspace.id, {number: name})
    }
    dispatch(changeOrgPageAttrs({createWorkspaceMode: false, selectedWorkspace: null}))
  }

  const selectWorkspaceHandler = () => {
    dispatch(changeOrgPageAttrs({createWorkspaceMode: false, selectedWorkspace: workspace}))
  }

  return (
    <TableRow>
      {WorkSpacesTableColumns.map((column) =>
        <TableCell key={column.name} align="left">
          {column.name === 'number' && isSelectedWorkspace
            ?
              <Stack direction={'row'} alignItems={'center'}>
                <FTextField
                  value={workspace.number}
                  _endEditing={(value) => {
                    if (value) {
                      updateWorkspaceHandler(value)
                    } else {
                      dispatch(setSnack('error', 'Наименование не может быть пустым'))
                      dispatch(changeOrgPageAttrs({createWorkspaceMode: false, selectedWorkspace: null}))
                    }
                  }}
                  _cancelEdit={() => {
                    dispatch(changeOrgPageAttrs({createWorkspaceMode: false, selectedWorkspace: null}))
                  }}
                />
              </Stack>
            :
              <ColumnComponent
                columnName={column.name}
                workspace={workspace}
                editHandler={selectWorkspaceHandler}
                index={index}
                setOpen={() =>
                  dispatch(changeOrgPageAttrs({
                    wpDialogWorkspace: workspace,
                    selectedWorkspace: null
                  }))}
                iAmOwner={iAmOwner}
              />
          }
        </TableCell>
      )}
    </TableRow>
  )
}

export default WorkspaceRow;
