import {ListItemIcon, MenuItem} from "@mui/material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import Typography from "@mui/material/Typography";

const TutorialMenuItem = ({handleClose}) => {
  return (
    <>
      <MenuItem onClick={() => {
        window.open('https://www.youtube.com/@delyana-app', '_blank')
        handleClose()
      }}>
        <ListItemIcon>
          <YouTubeIcon fontSize="small" />
        </ListItemIcon>
        <Typography>Обучающие видео</Typography>
      </MenuItem>
    </>
  )
}

export default TutorialMenuItem;
