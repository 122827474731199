import {setPlanetableSvgAttr} from "../../../../redux/reducers/planetableSvgReducer";

// Зум картинки с учетом положения курсора
export let scrollZoom = (e, dispatch, zoom) => {
  const ele = document.getElementById('svg-container')
  const rect = ele.getBoundingClientRect()
  // Расчет позиции мыши относительно блока
  const mouseX = e.clientX - rect.left;
  const mouseY = e.clientY - rect.top;
  // Расчет начала масштабирования на основе положения мыши
  const offsetX = mouseX / rect.width;
  const offsetY = mouseY / rect.height;
  // Рачет изменения по offsetX и offsetY
  const zoomOriginX = offsetX * 100
  const zoomOriginY = offsetY * 100
  if (e.nativeEvent.deltaY > 0 && zoom > 100) {
    ele.scrollTop -= zoomOriginY
    ele.scrollLeft -= zoomOriginX
    dispatch(setPlanetableSvgAttr({zoom: zoom - 10}))
  } else if (e.nativeEvent.deltaY < 0) {
    ele.scrollTop += zoomOriginY
    ele.scrollLeft += zoomOriginX
    dispatch(setPlanetableSvgAttr({zoom: zoom + 10}))
  }
}

// вспомогательная функция для реализации drag'n'drop. Сохраняет в стейт стартовую точку
export const setStartDragImagePoint = (e, setDragMode, setDragStartingPoint) => {
  setDragMode('image')
  const ele = document.getElementById('svg-container')
  setDragStartingPoint({
    scrollLeft: ele.scrollLeft,
    scrollTop: ele.scrollTop,
    clientX: e.clientX,
    clientY: e.clientY,
  })
}

// завершение всех событий drag'n'drop
export const endAllDragEvents = (setDragMode,  setDragStartingPoint, setCursorType) => {
  setDragMode(null)
  setDragStartingPoint(
    {
      scrollLeft: null,
      scrollTop: null,
      clientX: null,
      clientY: null,}
  )
}
