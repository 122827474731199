import React from 'react';
import {Box, Button} from "@mui/material";

const ButtonsBlock = ({undoClickHandler, applyClickHandler}) => {

  return (
    <Box sx={{display: 'flex', gap: 1, alignItems: 'center', height: '20%', p: 1}}>
      <Button variant={'contained'} size={'small'} onClick={applyClickHandler}>
        ОК
      </Button>
      <Button variant={'outlined'} size={'small'} onClick={undoClickHandler}>
        Отмена
      </Button>
    </Box>
  );
};

export default ButtonsBlock;