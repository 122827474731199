import {ListItemIcon, MenuItem} from "@mui/material";
import {urlGenerator} from "../../../../utils/urlGenerator";
import InfoIcon from '@mui/icons-material/Info';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";

const AboutPageMenuItem = ({handleClose}) => {
  const navigate = useNavigate()
  return (
    <>
      <MenuItem onClick={() => {
        navigate(urlGenerator().about)
        handleClose()
      }}>
        <ListItemIcon>
          <InfoIcon fontSize="small" />
        </ListItemIcon>
        <Typography>О приложении</Typography>
      </MenuItem>
    </>
  )
}

export default AboutPageMenuItem;
