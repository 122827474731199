import React from "react";
import {Stack, Typography,} from "@mui/material";

import {blockTitle} from "../rightPanelStyles";

const WarehouseProperties = ({shapeData}) => {
    return (
        <>
            <Typography style={blockTitle}>Справочные данные</Typography>
            <Stack spacing={2} sx={{wordWrap: 'break-word'}}>
                <Typography>Адрес: {shapeData?.address ? shapeData?.address : "-"}</Typography>
                <Typography>Договор аренды: {shapeData?.rental_contract ? shapeData?.rental_contract?.number : "-"}</Typography>
            </Stack>
        </>
    );
};

export default WarehouseProperties;
