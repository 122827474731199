import {Autocomplete, TextField} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useState} from "react";
import {commonNetwokError, emptyString, loadingString} from "../../../../Map/Common/Strings_RU";
import {useDispatch} from "react-redux";
import {setSnack} from "../../../../Map/Common/Dialog/Snack/snackReducer";

export function WSPAutocomplete({disabled, style, label, value, dataAPIFunction, dataAPIFunctionParams, callback}) {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false)
  const loading = open && options.length === 0;
  const dispatch = useDispatch()

  function loadData() {
    dataAPIFunction(dataAPIFunctionParams).then(res => {
      setOptions([...res.data])
    }).catch(err => {
      console.error(err)
      setOptions([]);
      dispatch(setSnack('error', commonNetwokError))
    })
  }

  return (
    <Autocomplete
      disabled={disabled}
      size={'small'}
      style={style}
      onChange={(event, newValue) => {
        callback(newValue)
      }}
      onReset={() => {
        callback(null)
      }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => {setOpen(true); loadData()}}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || ''}
      options={options}
      value={value}
      loading={loading}
      loadingText={loadingString}
      noOptionsText={emptyString}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) =>
        (<TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />)
      }
    />
  )
}
