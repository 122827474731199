export const saveImageLocally = (imageUrl) => {
  const fullFileName = imageUrl.split('/').pop()
  const link = document.createElement('a');
  link.href = imageUrl;
  link.target = "_blank";
  link.setAttribute('download', fullFileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
