
const defaultState = {
  show: false,
  items: [],
  drawing: false,
}

export function caSelectionRefresh() { //обновление компонента
  return {
    type: 'MAP_CASELECTIONS_REFRESH',
  }
}

export function caSelectionSetItems(itemsArray) { // вотнуть элементы
  return {
    type: 'MAP_CASELECTION_SET_ITEMS',
    itemsArray: itemsArray,
  }
}

export function caSetSelectionTreeShow(show) { // открыть/закрыть
  return {
    type: 'MAP_CASELECTIONS_SET_SHOW',
    show: show,
  }
}

export function setSelectionDrawingMode(mode) { //вкл/выкл рисования полигона
  return {
    type: 'MAP_SELECTION_SET_DRAWING_MODE',
    mode: mode,
  }
}

export function caSelectionsReducer(state = defaultState, action) { //редусер для выбранных делян
  switch(action.type) {
    case 'MAP_CASELECTIONS_REFRESH':
      return {...state}
    case 'MAP_CASELECTIONS_SET_SHOW':
      return {...state, show: action.show}
    case 'MAP_CASELECTIONS_SET_ITEM_SHOW':
      return {...state, showItems: {...state.showItems, [action.itemType]: action.show}}
    case 'MAP_CASELECTIONS_SHOW_SELECTIONS_NODE':
      return {...state, show: true, showItems: {...state.showItems, [action.nodeType]: true}}
    case 'MAP_CASELECTION_SET_ITEMS':
      return {...state, items: action.itemsArray}
    case 'MAP_SELECTION_SET_DRAWING_MODE':
      return {...state, drawing: action.mode}
    default:
      return state;
  }
}
