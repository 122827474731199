import React, {useEffect} from 'react';
import {Stack, TextField, Typography} from "@mui/material";
import {blockTitle} from "../../rightPanelStyles";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {cannotIDo} from "../../../Map/Common/tariffs";

const Comment = ({commentObj, objectId, saveCommentThunk}) => {
  const dispatch = useDispatch()
  const [commentText, setCommentText] = React.useState(commentObj?.text || '')
  const {created_at = '', created_by = '', updated_at = '', updated_by = ''} = commentObj || {}
  const iAmReadOnly = !!cannotIDo.editorAction()

  useEffect(() => {
    setCommentText(commentObj?.text || '')
  }, [commentObj])

  const changeCommentHandler = (e) => {
    setCommentText(e.target.value)
  }

  const undoChanges = () => {
    setCommentText(commentObj?.text || '')
  }

  const saveCommentHandler = () => {
    saveCommentThunk && dispatch(saveCommentThunk(commentText, objectId))
  }

  return (
    <>
      <Typography style={blockTitle}>Комментарий</Typography>
      {!iAmReadOnly
        ?
          <TextField
            size={'small'}
            multiline
            value={commentText}
            maxRows={4}
            inputProps={{maxLength: 255}}
            onChange={changeCommentHandler}
          />
        :
         <Typography>{commentText || 'Нет комментария.'}</Typography>
      }
      {commentObj
      ?
          <>
            <Typography sx={{fontSize: 12, color: 'grey'}}>
              {`Создан: UTC ${created_at} (${created_by})`}
            </Typography>
            <Typography sx={{fontSize: 12, color: 'grey'}}>
              {`Изменен: UTC ${updated_at} (${updated_by})`}
            </Typography>
          </>
      :
        ''
      }
      {!iAmReadOnly &&
        <Stack direction={'row'} spacing={2} sx={{pt: .5, pb: .5}}>
          <Button size={'small'} variant={'contained'} onClick={saveCommentHandler}>Сохранить</Button>
          {commentObj?.text !== commentText
            ? <Button size={'small'} variant={'outlined'} onClick={undoChanges}>Отменить</Button>
            : ''
          }
        </Stack>
      }
    </>
  );
};

export default Comment;
