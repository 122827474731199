import {TitleCommonBlock} from "../Common/TitleCommonBlock";
import PlanetablesGrouping from "./PlanetablesFilter/PlanetablesGrouping";
import {Modal, Stack, useTheme} from "@mui/material";
import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import {useDispatch, useSelector} from "react-redux";
import UploadPlanetableModal from "./UploadPlanetableModal/UploadPlanetableModal";
import PlanetableToolbar from "./PlanetableToolbar/PlanetableToolbar";
import LoadLayerModal from "./LoadLayerModal/LoadLayerModal";
import {getAllPlanetablesThunk} from "../../redux/reducers/planetablesReducer";
import IntroTour from "../IntroTourCommon/IntroTour";
import {setPlanetableSvgAttr} from "../../redux/reducers/planetableSvgReducer";
import PlanetableSvgContainer from "./PlanetableSvg/PlanetableSvgContainer";
import FullScreenLoading from "../Common/FullScreenLoading/FullScreenLoading";
import {planetablesGroupingContainer, planetablesMainBlockContainer} from "./PlanetabesPageSyles";
import {cannotIDo} from "../Main/Map/Common/tariffs";
import {forwardRef, useEffect} from "react";
import {urlGenerator} from "../../utils/urlGenerator";

const Planetables = () => {
  const dispatch = useDispatch()
  const iAmEditor = !cannotIDo.writePlanetabletsModule() && !cannotIDo.editorAction()
  const selectedPlanetable = useSelector(state => state.planetableSvgReducer.selectedPlanetable)
  const allPlanetables = useSelector(state => state.planetablesReducer.allPlanetables)
  const showUploadPlanetableModal = useSelector(state => state.planetablesReducer.showUploadPlanetableModal)
  const showLoadLayerModal = useSelector(state => state.planetableSvgReducer.showLoadLayerModal)
  const layerOperationsLoading = useSelector(state => state.planetableSvgReducer.layerOperationsLoading)
  const planeTableIntroTour = useSelector(state => state.introToursReducer.planeTableIntroTour)
  const TransitionUploadPlanetableModal = forwardRef(() => <UploadPlanetableModal iAmEditor={iAmEditor}/>)
  const TransitionLoadLayerModal = forwardRef(() => <LoadLayerModal/>)
  const theme = useTheme()

  useEffect(() => {
    !allPlanetables.length && dispatch(getAllPlanetablesThunk())
    selectedPlanetable && dispatch(setPlanetableSvgAttr({selectedPlanetable: null}))
  }, [])

  const borderString = `1px solid ${theme.palette.primary.main}`;

  function goToBack(navigate) {
    navigate(urlGenerator().main)
  }

  return (
    <>
      <Stack style={{height: '100%'}}>
        <TitleCommonBlock title={'Редактор планшетов'} id={'planetables'} closeButtonHandler={goToBack}/>
        <div style={{flex: 1}}>
          <ReflexContainer orientation={'vertical'}>
            <ReflexElement flex={0.25} style={{...planetablesGroupingContainer, border: borderString}}>
              <PlanetablesGrouping iAmEditor={iAmEditor}/>
            </ReflexElement>
            <ReflexSplitter style={{border: 0, width: 5, backgroundColor: 'transparent'}}/>
            <ReflexElement
              style={{...planetablesMainBlockContainer, border: borderString, boxSizing: 'border-box'}}
              flex={0.75}
            >
              <Stack style={{height: '100%'}}>
                <PlanetableToolbar iAmEditor={iAmEditor}/>
                <div style={{flex: 1, position: 'relative'}}>
                  <div style={{position: 'absolute', left: 0, right: 0, bottom: 0, top: 0}}>
                    <PlanetableSvgContainer/>
                  </div>
                </div>
              </Stack>
            </ReflexElement>
          </ReflexContainer>
        </div>
      </Stack>
      <Modal open={showUploadPlanetableModal.status}>
        <TransitionUploadPlanetableModal/>
      </Modal>
      <Modal open={showLoadLayerModal}>
        <TransitionLoadLayerModal/>
      </Modal>
      <IntroTour tourData={planeTableIntroTour} tourName={'planeTableIntroTour'}/>
      {layerOperationsLoading &&
        <FullScreenLoading/>
      }
    </>
  )
}

export default Planetables;
