export const tariffRules = [
/*  {
    id: 1,
    "Название тарифа": "ЛАЙТ",
    "Роли: Админ / Просмотр": "1 / 0",
    "Стандартная карта и спутники, кроме Sentinel": true,
    "Спутник Sentinel": false,
    "Подключение внешних слоев для отображения на карте": false,
    "Просмотр слоя планшетов": false,
    "Редактор планшетов": false,
    "Создание объектов инфраструктуры на карте местности": true,
    "Создание объекта деляны со специальным функционалом": false,
    "Рабочие области (договоры аренды)": "1",
    "Работа со списком делян": false,
    "Подготовка приложений к отраслевым отчетам": false,
    "Многопользовательский режим": false,
    "Синхронизация данных с мобильными устройствами": true,
    "Вывод на печать": false,
    "Возможность расширения функционала системы": false,
    "СТОИМОСТЬ ПРИ ОПЛАТЕ ЗА ГОД": "2 750 руб"
  },*/
  {
    id: 2,
    "Название тарифа": "БАЗОВЫЙ",
    "Роли: Админ / Просмотр": "1 / 4",
    "Стандартная карта и спутники, кроме Sentinel": true,
    "Спутник Sentinel": true,
    "Подключение внешних слоев для отображения на карте": false,
    "Просмотр слоя планшетов": true,
    "Редактор планшетов": true,
    "Создание объектов инфраструктуры на карте местности": true,
    "Создание объекта деляны со специальным функционалом": true,
    "Рабочие области (договоры аренды)": "1",
    "Работа со списком делян": true,
    "Подготовка приложений к отраслевым отчетам": true,
    "Многопользовательский режим": true,
    "Синхронизация данных с мобильными устройствами": true,
    "Вывод на печать": true,
    "Возможность расширения функционала системы": false,
    "СТОИМОСТЬ ПРИ ОПЛАТЕ ЗА ГОД": "45 000 руб"
  },
  {
    id: 3,
    "Название тарифа": "ПРОФ",
    "Роли: Админ / Просмотр": "2 / 8",
    "Стандартная карта и спутники, кроме Sentinel": true,
    "Спутник Sentinel": true,
    "Подключение внешних слоев для отображения на карте": true,
    "Просмотр слоя планшетов": true,
    "Редактор планшетов": true,
    "Создание объектов инфраструктуры на карте местности": true,
    "Создание объекта деляны со специальным функционалом": true,
    "Рабочие области (договоры аренды)": "3",
    "Работа со списком делян": true,
    "Подготовка приложений к отраслевым отчетам": true,
    "Многопользовательский режим": true,
    "Синхронизация данных с мобильными устройствами": true,
    "Вывод на печать": true,
    "Возможность расширения функционала системы": false,
    "СТОИМОСТЬ ПРИ ОПЛАТЕ ЗА ГОД": "65 000 руб"
  },
  {
    id: 4,
    "Название тарифа": "ПРОФ+",
    "Роли: Админ / Просмотр": "4 / 16",
    "Стандартная карта и спутники, кроме Sentinel": true,
    "Спутник Sentinel": true,
    "Подключение внешних слоев для отображения на карте": true,
    "Просмотр слоя планшетов": true,
    "Редактор планшетов": true,
    "Создание объектов инфраструктуры на карте местности": true,
    "Создание объекта деляны со специальным функционалом": true,
    "Рабочие области (договоры аренды)": "Без ограничений",
    "Работа со списком делян": true,
    "Подготовка приложений к отраслевым отчетам": true,
    "Многопользовательский режим": true,
    "Синхронизация данных с мобильными устройствами": true,
    "Вывод на печать": true,
    "Возможность расширения функционала системы": "Индивидуальный расчет стоимости по запросу",
    "СТОИМОСТЬ ПРИ ОПЛАТЕ ЗА ГОД": "100 000 руб"
  }
]
